<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <!-- Header -->
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode == "edit" ? "Edit" : "Create" }} Operator Role</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <!-- Body -->
      <div class="modal-body">
        <!-- Name -->
        <div class="col-12 form-group row pr-0">
          <label class="col-2 col-form-label">Name <span class="text-danger">*</span> </label>
          <input type="text" formControlName="name" min="1" class="col-10 form-control" />
        </div>
        <!-- Status -->
        <div class="col-12 form-group row pr-0">
          <label class="col-2 col-form-label">Status <span class="text-danger">*</span> </label>
          <select formControlName="status" class="col-10 form-control">
            <option *ngFor="let value of dropdown.statuses; let i = index" [value]="i">{{ value }}</option>
          </select>
        </div>
        <!-- Remarks -->
        <div class="col-12 form-group row pr-0">
          <label class="col-2 col-form-label">Remarks </label>
          <input type="text" formControlName="remarks" min="1" class="col-10 form-control" />
        </div>

        <!-- Permissions Div -->
        <div class="col-12 form-group mb-0" formGroupName="permissions">
          <div class="card p-3">
            <!-- Button & Search Input Group -->
            <div class="row">
              <div class="col-9">
                <button
                  class="btn btn-success btn-icon-sm mr-2"
                  (click)="selectDeselectAllPermissions(true)"
                >
                  <i class="fas fa-check"></i>{{ 'Select All' | translate }}
                </button>
                <button
                  class="btn btn-danger btn-icon-sm"
                  (click)="selectDeselectAllPermissions(false)"
                >
                  <i class="fas fa-times"></i>{{ 'Deselect All' | translate }}
                </button>
              </div>

              <div class="col-3 input-group-append search-filter h-100 w-100">
                <kt-dropdown-wo-lazyload
                  [form]="searchForm"
                  [dropdownList]="searchElementsData"
                  [dropdownSettings]="searchFilterDropdownSettings"
                  formName="search"
                  [selectionAttributes]="'id'"
                  style="width: 100%"
                ></kt-dropdown-wo-lazyload>
                <button class="btn btn-brand btn-icon-sm btn-search" (click)="onSearch()"><i class="fas fa-search"></i></button>
              </div>
            </div>

            <!-- Permissions Layers -->
            <div class="row mt-4">
              <!-- Left -->
              <div class="col-6">
                <application-role-permission-layer
                  [sectionPermissions]="data.sectionPermissions | slice : 0 : halfIndexOfSP"
                  [permissionsBySection]="permissionsBySection"
                  [sectionPanelOpenState]="sectionPanelOpenState"
                  [form]="form" 
                  (permissionChange)="permissionChangeListener($event)"
                  (permissionChangeAll)="permissionChangeAllListener($event)"
                ></application-role-permission-layer>
              </div>

              <!-- Right -->
              <div class="col-6">
                <application-role-permission-layer
                  [sectionPermissions]="data.sectionPermissions | slice : halfIndexOfSP"
                  [permissionsBySection]="permissionsBySection"
                  [sectionPanelOpenState]="sectionPanelOpenState"
                  [form]="form" 
                  (permissionChange)="permissionChangeListener($event)"
                  (permissionChangeAll)="permissionChangeAllListener($event)"
                ></application-role-permission-layer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button *ngIf="data.mode === 'create' ? canCreateApplicationRole : canEditApplicationRole" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
<!-- Message Dialog -->
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
