<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <!-- Filter -->
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
              <form class="row align-items-center" [formGroup]="form">
              <!-- Filter first row -->
              <div class="col-12 row pr-0">
                <!-- Username -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Username:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                </div>
                <!-- Group -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label class="mb-0">Group</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="member_group_id" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.groups | async">
                      {{ value.name }}
                    </option>
                  </select>
                </div>
                <!-- Reason -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Reason:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="reason" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.reason ; let i = index">
                      {{ value.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 row pr-0">
                <!-- Status -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="d-flex flex-wrap status-filter-pt">
                    <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.statuses ; let i = index">
                      <input type="checkbox" [checked]="item.checked" (change)="onSearchStatus($event, vipVerificationStatus[item.name], i)" class="custom-control-input" [id]="item.name">
                      <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <div class="dropdown dropdown-inline">
          <button class="btn btn-brand btn-icon-sm" *ngIf="canCreateVip" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
        </div>
      </div>
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                  <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                  <th class="sort-enabled" (click)="onSortColumn('level')" [ngClass]="sortingConfig.level" [class.sort-selected]="sortingSelection.sort_by === 'level'">Level</th>
                  <th class="sort-enabled text-center" (click)="onSortColumn('currency')" [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">Currency</th>
                  <th class="sort-enabled text-center" (click)="onSortColumn('total_deposits')" [ngClass]="sortingConfig.total_deposits" [class.sort-selected]="sortingSelection.sort_by === 'total_deposits'">Accumulated Deposits</th>
                  <th class="sort-enabled" (click)="onSortColumn('reason')" [ngClass]="sortingConfig.reason" [class.sort-selected]="sortingSelection.sort_by === 'reason'">Reason</th>
                  <th class="sort-enabled" (click)="onSortColumn('remarks')" [ngClass]="sortingConfig.remarks" [class.sort-selected]="sortingSelection.sort_by === 'remarks'">Remarks</th>
                  <th class="sort-enabled text-center" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                  <th class="sort-enabled date-column" (click)="onSortColumn('created_by')" [ngClass]="sortingConfig.created_by" [class.sort-selected]="sortingSelection.sort_by === 'created_by'">Created by</th>
                  <th class="sort-enabled date-column" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated by</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody *ngIf="(members$ | async) as rows">
                <tr *ngFor="let row of rows">
                  <td>{{ row.id }}</td>
                  <td><a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog('member-information', row.member_account_id)">{{ row.username }} </a></td>
                  <td class="level">{{ row.level }}</td>
                  <td class="text-center">{{ row.currency_code }}</td>
                  <td class="text-right">{{ row.total_deposits | number : '1.2-2' }}</td>
                  <td>{{ vipVerificationReason[row.reason] }}</td>
                  <td>{{ row.remarks }}</td>
                  <td class="text-center"><span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide"
                      [ngClass]="'kt-badge--' + row.status"> {{ vipVerificationStatus[row.status] }}</span></td>
                  <td>
                    {{ row.created_by ? row.created_by : '-' }} <br />
                    {{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '' }}
                  </td>
                  <td>
                    {{ row.updated_by ? row.updated_by : '-' }} <br />
                    {{ row.updated_at ? (row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '' }}
                </td>
                  <td class="text-center">
                    <button class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" matTooltip="Approve" (click)="onOpenDialog('approve', row)" *ngIf="row.status == 0 && canApproveVip"><i class="fas fa-check"></i></button>
                    <button class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" matTooltip="Reject" (click)="onOpenDialog('reject', row)" *ngIf="row.status == 0 && canRejectVip"><i class="fas fa-ban"></i></button>
                    <button class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" matTooltip="Edit" (click)="onOpenDialog('edit', row)" *ngIf="row.status != 0 && canEditVip" [disabled]="row.status == 2"><i class="fas fa-edit"></i></button>
                  </td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true"
              [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total"
              (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kt-swal-alert [message]="messages$ | async" (confirmed)="onSubmit()"></kt-swal-alert>
