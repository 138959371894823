<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <form class="align-items-center" [formGroup]="form">
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="currency_id">
                                        <option value="all">All</option>
                                        <ng-container *ngFor="let currency of dropdown.currencies">
                                            <option [value]="currency.id">{{ currency.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Code:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="code" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Bank Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="bank_type">
                                        <option value="all">All</option>
                                        <ng-container *ngFor="let type of dropdown.bankType">
                                            <option [value]="type.id">{{ type.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="type">
                                        <ng-container *ngFor="let type of dropdown.types">
                                            <option [value]="type.id">{{ type.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Filter Action -->
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canCreateBankSetting" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>
            <!-- Table -->
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th rowspan="2">ID</th>
                                    <th class="text-center" rowspan="2">Currency</th>
                                    <th class="text-center" rowspan="2">Code</th>
                                    <th rowspan="2">Name</th>
                                    <th rowspan="2">Bank Type</th>
                                    <th rowspan="2">Type</th>
                                    <th class="text-center" rowspan="2">Status</th>
                                    <th class="text-center" colspan="2">Preview</th>
                                    <th class="text-center" rowspan="2">Action</th>
                                </tr>
                                <tr>
                                    <th class="text-center">Desktop</th>
                                    <th class="text-center">Mobile</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of bankSettings$;let i = index">
                                    <td>{{ row.id }}</td>
                                    <td class="text-center">{{ onExtractData(row.currencies) }}</td>
                                    <td class="text-center">{{ row.code}}</td>
                                    <td>{{ row.name }}</td>
                                    <td>{{ bankTypes[row.bank_type] }}</td>
                                    <td>{{ findType(row.currencies[0].type).name }}</td>
                                    <td class="text-center">
                                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ statuses[row.status] }}</span>
                                    </td>
                                    <td class="preview-img text-center">
                                        <ng-container *ngIf="row.desktop_image !== null; else noImg">
                                            <img [src]="row.desktop_image" (error)="onDesktopImgError($event, i)">
                                            <p id="desktop-image-{{i}}"></p>
                                        </ng-container>
                                        <ng-template #noImg>
                                           <span>-</span>
                                        </ng-template>
                                    </td>
                                    <td class="preview-img text-center">
                                        <ng-container *ngIf="row.mobile_image !== null; else noImg">
                                            <img [src]="row.mobile_image" (error)="onMobileImgError($event, i)">
                                            <p id="mobile-image-{{i}}"></p>
                                        </ng-container>
                                        <ng-template #noImg>
                                            <span>-</span>
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                      <button *ngIf="canUpdateBankSetting" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-edit"></i></button>
                                      <button *ngIf="canEditMaintenanceHour" matTooltip="Maintenance Hours" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('editMaintenanceHours', row)"><i class="fa fa-clock"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="bankSettings$.length" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
