import { MessageTemplateEditComponent } from './message-template/dialogs/message-template-edit.component';
import { MessageTemplateComponent } from './message-template/message-template.component';
import { MemberLogsComponent } from './member-logs/member-logs.component';
import { RoleEditDialogComponent } from './roles/dialogs/role-edit.component';
import { OperatorDialogComponent } from './operators/dialogs/operator-profile.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OperatorComponent } from './operators/operator.component';
import { SuperuserReportsWinloseComponent } from './subcomponents/superuser-reports-winlose/superuser-reports-winlose.component';
import { SuperuserReportsWinloseFullComponent } from './subcomponents/superuser-reports-winlose-full/superuser-reports-winlose-full.component';
import { SuperuserReportsMemberinoutComponent } from './subcomponents/superuser-reports-memberinout/superuser-reports-memberinout.component';
import { SuperuserReportsMemberaccountComponent } from './subcomponents/superuser-reports-memberaccount/superuser-reports-memberaccount.component';
import { SuperuserCashflowComponent } from './subcomponents/superuser-cashflow/superuser-cashflow.component';
import { RoleComponent } from './roles/role.component';
import { OperatorLogsComponent } from './operator-logs/operator-logs.component';
import { SystemErrorLogComponent } from './system-error-logs/system-error-logs.component';
import { SystemMaintenanceComponent } from './system-maintenance/system-maintenance.component';
import { SystemMaintenanceEditComponent } from './system-maintenance/dialog/system-maintenance-edit.component';
import { IpWhitelistingComponent } from './ip-whitelisting/ip-whitelisting.component';
import { IpWhitelistingEditComponent } from './ip-whitelisting/dialog/ip-whitelisting-edit.component';
import { SystemSettingComponent } from './system-setting/system-setting.component';
import { ApiKeySettingComponent } from './api-key-setting/api-key-setting.component';
import { ApiKeySettingEditComponent } from './api-key-setting/dialog/api-key-setting-edit.component';

import { SiteDomainComponent } from './site-domain/site-domain.component';
import { SiteDomainEditComponent } from './site-domain/dialog/site-domain-edit.component';
import { AuditsComponent } from './audits/audits.component';
import { ApplicationPermissionsComponent } from './application-permissions/application-permissions.component';
import { ApplicationPermissionEditDialogComponent } from './application-permissions/dialogs/application-permission-edit.component';
import { ApplicationRoleComponent } from './application-roles/application-role.component';
import { ApplicationRoleEditDialogComponent } from './application-roles/dialogs/application-role-edit.component';
import { ApplicationRolePermissionLayerComponent } from './application-roles/permission-layer/application-role-permission-layer.component';

const routes: Routes = [
  {
    path: 'system-error-logs',
    component: SystemErrorLogComponent
  },
  {
    path: 'operator-logs',
    component: OperatorLogsComponent
  },
  {
    path: 'member-logs',
    component: MemberLogsComponent
  },
  {
    path: 'audits',
    component: AuditsComponent
  },
  {
    path: 'system-maintenance',
    component: SystemMaintenanceComponent
  },
  {
    path: 'system-setting',
    component: SystemSettingComponent
  },
  {
    path: 'api-key-setting',
    component: ApiKeySettingComponent
  },
  {
    path: 'message-template',
    component: MessageTemplateComponent
  },
  {
    path: 'operators',
    component: OperatorComponent
  },
  {
    path: 'operator-roles',
    component: RoleComponent
  },
  {
    path: 'ip-whitelisting',
    component: IpWhitelistingComponent
  },
  {
    path: 'site-domain',
    component: SiteDomainComponent
  },
  {
    path: 'reports',
    children: [
      {
        path: 'winlose-simple',
        component: SuperuserReportsWinloseComponent
      },
      {
        path: 'winlose-full',
        component: SuperuserReportsWinloseFullComponent
      },
      {
        path: 'member-inout',
        component: SuperuserReportsMemberinoutComponent
      },
      {
        path: 'member-account',
        component: SuperuserReportsMemberaccountComponent
      }
    ]
  },
  {
    path: 'cashflow',
    component: SuperuserCashflowComponent
  },
  {
    path: 'application-permissions',
    component: ApplicationPermissionsComponent
  },
  {
    path: 'application-roles',
    component: ApplicationRoleComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  entryComponents: [
    OperatorDialogComponent,
    MessageTemplateEditComponent,
    RoleEditDialogComponent,
    SystemMaintenanceEditComponent,
    IpWhitelistingEditComponent,
    ApiKeySettingEditComponent,
    SiteDomainEditComponent,
    ApplicationPermissionEditDialogComponent,
    ApplicationRoleEditDialogComponent,
    ApplicationRolePermissionLayerComponent,
  ]
})
export class SuperuserRoutingModule { }
