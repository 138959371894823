<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">

              <div class="col-12 row pr-0 mb-2">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>ID:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" (wheel)="false" (input)="sanitizeId($event)" formControlName="id" placeholder="Search" class="form-control" [attr.disabled]="interactiveInput.id === false ? 'disabled': null" (input)="onInteractiveSearchFilter($event)">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Currency:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="currency_id" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                        {{ value.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Member Group:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="group_id" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="value.id" *ngFor="let value of memberGroup">
                        {{ value.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="status" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                        {{ value }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-12 row pr-0 mb-2">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Username:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control" [attr.disabled]="interactiveInput.username === false ? 'disabled': null" (input)="onInteractiveSearchFilter($event)">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Name:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="name" (input)="toLowerCaseInput('name', $event)" placeholder="Search" class="form-control" [attr.disabled]="interactiveInput.name === false ? 'disabled': null" (input)="onInteractiveSearchFilter($event)">
                </div>
                <!-- <div class="col-md-1 kt-form__label col-form-label">
                  <label>Password:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="password" (input)="toLowerCaseInput('password', $event)" placeholder="Search" class="form-control" [attr.disabled]="interactiveInput.password === false ? 'disabled': null" (input)="onInteractiveSearchFilter($event)">
                </div> -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Email:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="email" (input)="toLowerCaseInput('email', $event)" placeholder="Search" class="form-control" [attr.disabled]="interactiveInput.email === false ? 'disabled': null" (input)="onInteractiveSearchFilter($event)">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Fingerprint:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="fingerprint_id" placeholder="Search" class="form-control" [attr.disabled]="interactiveInput.fingerprint_id === false ? 'disabled': null" (input)="onInteractiveSearchFilter($event)">
                </div>
              </div>

              <div class="col-12 row pr-0 mb-2">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>IP Address:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="last_ip_address" placeholder="Search" class="form-control" [attr.disabled]="interactiveInput.last_ip_address === false ? 'disabled': null" (input)="onInteractiveSearchFilter($event)">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Risk Type:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="risk_type" class="form-control" [attr.disabled]="interactiveInput.risk_type === false ? 'disabled': null">
                    <option value="And">AND Condition</option>
                    <option value="Or">OR Condition</option>
                  </select>
                </div>
                <div class="col-md-2 kt-form__control">
                  <div class="custom-dropdown" [ngClass]="{ 'disabled': !enableRiskDropdown }">
                    <ngx-dropdown-treeview [config]="config" [items]="riskTypeItems" [buttonClass]="'treeview-outline-color'" (selectedChange)="onTreeviewChange($event)"></ngx-dropdown-treeview>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="kt-section col-12 row mb-3 mt-3 d-flex justify-content-between">
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit(false)">
          </kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <div class="dropdown dropdown-inline">
          <button class="btn btn-warning btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="loading">
            <i [class]="exportBtnLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export
          </button>
        </div>
      </div>
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="sort-enabled" (click)="onSortColumn('member_id')" [ngClass]="sortingConfig.member_id" [class.sort-selected]="sortingSelection.sort_by === 'member_id'">ID</th>
                  <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                  <th class="sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                  <th class="sort-enabled text-center" (click)="onSortColumn('currency')" [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">Currency</th>
                  <th class="sort-enabled" (click)="onSortColumn('member_group')" [ngClass]="sortingConfig.member_group" [class.sort-selected]="sortingSelection.sort_by === 'member_group'">Member Group</th>
                  <!-- <th class="sort-enabled" (click)="onSortColumn('password')" [ngClass]="sortingConfig.password" [class.sort-selected]="sortingSelection.sort_by === 'password'">Password</th> -->
                  <th class="sort-enabled" (click)="onSortColumn('email')" [ngClass]="sortingConfig.email" [class.sort-selected]="sortingSelection.sort_by === 'email'">Email</th>
                  <th class="sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                  <th class="sort-enabled" (click)="onSortColumn('registration_created_at')" [ngClass]="sortingConfig.registration_created_at" [class.sort-selected]="sortingSelection.sort_by === 'registration_created_at'">Register Info</th>
                  <th class="sort-enabled" (click)="onSortColumn('last_login_updated_at')" [ngClass]="sortingConfig.last_login_updated_at" [class.sort-selected]="sortingSelection.sort_by === 'last_login_updated_at'">Last Login Info</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of pagedata">
                  <td class="text-center">{{ row.member_id }}</td>
                  <td class="text-center" class="username-col">
                    <ng-container *ngIf="row.member_id != '' && row.member_id != '-'; else elseBlock">
                      <a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold username-content" (click)="onOpenDialog('member-information', row.member_id)">{{ row.username }}</a>
                      <div class="suspicious-ribbon" *ngIf="sqsEnable ? row.label_exist : row.suspicious">
                        <i  class="fa fa-exclamation"></i>
                      </div>
                      <i (click)="onViewMoreRemarks(row, sqsEnable)" *ngIf="sqsEnable ? row.remark_exist : row.remarks !== null" class="far fa-comment-dots d-flex align-items-center pointer remark-icon" matTooltip="Remarks"></i>
                    </ng-container>
                    <ng-template #elseBlock>
                      {{ row.username }}
                    </ng-template>
                  </td>
                  <td class="text-center">{{ row.name }}</td>
                  <td class="text-center">{{ row.currency }}</td>
                  <td class="text-center">{{ row.member_group | titlecase }}</td>
                  <!-- <td class="text-center">{{ row.password }}</td> -->
                  <td class="text-center">{{ row.email }}</td>
                  <td class="text-center">
                    <div class="btn-group dropdown">
                      <button type="button" class="btn btn-pill btn-sm dropdown-base" [ngClass]="'kt-badge--' + row.status">{{ row.status === 2 ? ('Suspended' | translate) : status[row.status] | translate }}</button>
                    </div>
                  </td>
                  <td class="text-center">
                    {{ row.registration_created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                    <span *ngIf="row.registration_ip !== null && row.registration_ip !== ''"><br>{{ row.registration_ip }}</span>
                    <span *ngIf="row.registration_fingerprint_id !== null && row.registration_fingerprint_id !== ''"><br>{{ row.registration_fingerprint_id }}</span>
                    <span *ngIf="row.registration_domain !== null && row.registration_domain !== ''"><br><a [href]="row.registration_domain" target="_blank">{{ row.registration_domain }}</a></span>
                    <br>{{ row.locale }}
                  </td>
                  <td class="text-center">
                    <ng-container *ngIf="row.last_login_ip_address !== null && row.last_login_ip_address !== ''">{{ row.last_login_updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</ng-container>
                    <span *ngIf="row.last_login_ip_address !== null && row.last_login_ip_address !== ''"><br>{{ row.last_login_ip_address }}</span>
                    <i *ngIf="row.last_login_ip_address !== null && row.last_login_ip_address !== ''" class="fas fa-check-circle icon-success pl-1" [matTooltip]="row.last_login_status == 1 ? ('Success Login' | translate) : row.last_login_status == 0 ? ('Failed to Login' | translate) :('Unknown' | translate) " [ngClass]="row.last_login_status == 1 ? 'fas fa-check-circle icon-success' : row.last_login_status == 0 ? 'fas fa-times-circle icon-failed' : 'fas fa-question-circle icon-unknown'"></i>
                    <span *ngIf="row.last_fingerprint_id !== null && row.last_fingerprint_id !== ''"><br>{{ row.last_fingerprint_id }}</span>
                    <span *ngIf="row.last_login_domain !== null && row.last_login_domain !== ''"><br><a [href]="row.last_login_domain" target="_blank">{{ row.last_login_domain }}</a></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="loading && !exportBtnLoading" style="text-align: center;">Loading more...</div>
            <div *ngIf="!loadmore" style="text-align: center;">No more data available</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <kt-swal-alert [message]="messages$ | async" (confirmed)="loadingBar.complete()"></kt-swal-alert>