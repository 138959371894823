<div class="kt-form kt-form--label-right">
    <div class="row align-items-center">
        <div class="col-xl-12 order-2 order-xl-1">
            <form class="align-items-center" [formGroup]="form">
                <div class="row mb-2">
                    <div class="col-md-1 kt-form__label col-form-label">
                        <label>Section Name:</label>
                    </div>
                    <div class="col-md-2 kt-form__control">
                        <input type="text" formControlName="section_name" placeholder="Search" class="form-control">
                    </div>                    
                    <div class="col-md-1 kt-form__label col-form-label">
                        <label>Status:</label>
                    </div>
                    <div class="col-md-2 kt-form__control">
                        <select class="form-control" formControlName="status">
                            <option value="all">All</option>
                                <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">{{ value }}</option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Action Button -->
<div class="kt-section col-12 row d-flex justify-content-between mb-3">
    <div class="dropdown dropdown-inline">
        <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
        <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>                
    </div>
    <button *ngIf="canCreateIcon" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
</div>            


<div class="kt-section">
    <div class="kt-section__content">
        <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
                <thead>
                    <tr class="text-top">
                        <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                        <th class="sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                        <th class="sort-enabled" (click)="onSortColumn('section_id')" [ngClass]="sortingConfig.section_id" [class.sort-selected]="sortingSelection.sort_by === 'section_id'">Section Name</th>
                        <th>Merchant</th>
                        <th>Preview</th>
                        <th class="sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                        <th class="text-center">Action</th>
                    </tr>
                </thead>
                <tbody *ngIf="(iconlibrary$ | async) as rows">
                    <tr *ngFor="let row of rows">  
                        <td style="white-space:pre-wrap; word-wrap:break-word">{{ row.id }}</td>
                        <td style="white-space:pre-wrap; word-wrap:break-word">{{ row.name }}</td>
                        <td style="white-space:pre-wrap; word-wrap:break-word">{{ row.section_name }}</td>
                        <td style="white-space:pre-wrap; word-wrap:break-word">{{ row.merchants }}</td>
                        <td class="kt-align-center"><img [src]="row.icon_path" alt="" class="preview"></td>
                        <td class="text-center">
                            <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ row.status_name }}</span>
                        </td>
                        <td class="text-center">
                            <button *ngIf="canEditIcon" title="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-edit"></i></button>
                        </td>
                    </tr>
                    <!-- <kt-fallback-row [collection]="rows"></kt-fallback-row> -->
                </tbody>
                <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
        </div>
        <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination
                [pageSize]="pageSize"
                [(page)]="page"
                [maxSize]="maxSize"
                [directionLinks]="true"
                [boundaryLinks]="true"
                [rotate]="true"
                [collectionSize]="pagination.total"
                (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
                <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                    <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                </select>
                <span class="pagination__desc">
                    Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                </span>
            </div>
          </div>
    </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onReload()"></kt-swal-alert>