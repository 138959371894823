<div
  class="modal-dialog view-receipt-modal modal-dialog-scrollable m-0 scrollable-dialog"
>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">View Receipt ({{ data.deposit.id }})</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <ngb-carousel *ngIf="data.deposit.receipts.length > 0">
        <ng-template
          ngbSlide
          *ngFor="let receipt of data.deposit.receipts; let i = index"
        >
          <div class="picsum-img-wrapper">
            <div style="min-height: inherit">
              <object
                [data]="receipt.content | safe : 'resourceUrl'"
                [ngStyle]="{
                  height: checkMedia(receipt.content) == 'pdf' ? '100%' : 'auto',
                  'max-height': '95%'
                }"
              ></object>
              <p class="receipt-desc">{{ receipt.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}</p>
            </div>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="onCloseDialog($event)"
      >
        <i class="fas fa-ban"></i>Close
      </button>
    </div>
  </div>
</div>
