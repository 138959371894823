<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Code:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="code" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Message:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="message" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Section:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="section" class="form-control">
                                    <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.section | async">
                                        {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                <label>Status :</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                  <select formControlName="status" class="form-control">
                                    <option value="all"> All </option>
                                    <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                                        {{ value }}
                                    </option>
                                  </select>
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Type :</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <div class="d-flex flex-wrap status-filter-pt">
                                        <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.type; let i = index">
                                            <input type="checkbox" [checked]="item.checked" (change)="onCheckBoxFilter($event, item.id, 'type', i)" class="custom-control-input" [id]="item.name">
                                            <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Locale :</label>
                                </div>
                                <div class="col-md-8 kt-form__control">
                                    <div class="d-flex flex-wrap status-filter-pt">
                                        <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.locales; let i = index">
                                            <input type="checkbox" [checked]="item.checked" (change)="onCheckBoxFilter($event, item.id, 'locale', i)" class="custom-control-input" [id]="item.code">
                                            <label class="custom-control-label text-nowrap" [for]="item.code">{{ item.code }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canCreateMessageTemplate" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                              <tr>
                                <th>Section</th>
                                <th class="text-center">Code</th>
                                <th class="text-center">Type</th>
                                <th class="text-center">Locales</th>
                                <th class="text-center">Actions</th>
                              </tr>
                            </thead>
                            <tbody *ngIf="(messageTemplates$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.section_name }}</td>
                                    <td>{{ row.code | uppercase }}</td>
                                    <td class="text-center">{{ row.type_name }}</td>
                                    <td class="text-center">{{ row.locales }}</td>
                                    <td class="text-center actions-column">
                                    <button *ngIf="canViewMessageTemplate" matTooltip="Settings" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"  (click)="onOpenDialog('edit', row)"><i class="fas fa-cog"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                      <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="pagination.total"
                        (pageChange)="onViewPageBy(page)">
                      </ngb-pagination>
                      <div class="kt-pagination__toolbar">
                        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                        </select>
                        <span class="pagination__desc">
                          Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                        </span>
                      </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  </div>
