import { RecoveryPromotionEditDialogComponent } from './dialogs/recovery-promotion-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { tap, catchError } from 'rxjs/operators';
import { RecoveryPromotionEntityService } from './services/recovery-promotion-entity.service';
import { RecoveryPromotionDataService } from './services/recovery-promotion-data.service';
import { Pagination } from '@core/models/pagination.model';
import { RecoveryPromotion } from '@core/models/recovery-promotion.model';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { SubheaderService } from '@core/_base/layout';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import Swal from 'sweetalert2';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-recovery-promotion',
  templateUrl: './recovery-promotion.component.html',
  styleUrls: ['./recovery-promotion.component.scss']
})
export class RecoveryPromotionComponent implements OnInit, OnDestroy {

  recoveryPromotions$: Observable<RecoveryPromotion[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };
  buttonLoading = false;

  // permissions
  canCreateRecoveryPromotion: boolean;
  canEditRecoveryPromotion: boolean;
  canDuplicateRecoveryPromotion: boolean;
  canUpdateRecoveryPromotionStatus: boolean;

  messages$ = this.recoveryPromotionDataService.messages$;
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    private recoveryPromotionDataService: RecoveryPromotionDataService,
    private recoveryPromotionEntityService: RecoveryPromotionEntityService,
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.onReload();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateRecoveryPromotion = appPermissions.create_recovery_promotion;
      this.canEditRecoveryPromotion = appPermissions.edit_recovery_promotion;
      this.canDuplicateRecoveryPromotion = appPermissions.duplicate_recovery_promotion;
      this.canUpdateRecoveryPromotionStatus = appPermissions.update_recovery_promotion_status;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.recoveryPromotions$ = this.recoveryPromotionEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.recoveryPromotionDataService.pagination;

        this.loadingBar.complete();
      })
    );
  }

  onDuplicate(data: any) {
    this.buttonLoading = true;
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to duplicate ${data.name}?`,
      icon: 'info',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((resp) => {
      if (resp.value) {
        this.subscription = forkJoin([
          this.recoveryPromotionDataService.duplicate(data.id).pipe(
            tap(() => {
              this.buttonLoading = false;
            }),
            catchError((error) => {
              this.buttonLoading = false;
              throw error;
            })
          ),
          this.recoveryPromotionDataService.messages$
        ]).subscribe();
      } else {
        this.buttonLoading = false;
      }
    });
  }

  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(mode: string, row?: RecoveryPromotion) {
    if (mode === 'edit') {
      this.openDialogBy(RecoveryPromotionEditDialogComponent, { mode: 'edit', row: row });
    } else {
      this.openDialogBy(RecoveryPromotionEditDialogComponent, { mode: 'create' });
    }
  }

  onToggleStatus(row: any, evt) {
    if (!this.canEditRecoveryPromotion && !this.canUpdateRecoveryPromotionStatus) {
      evt.preventDefault();
      Swal.fire('Permission Denied', 'You are not allowed to update recovery promotion status', 'error');
      return;
    }

    const data = {
      id: row.id,
      status: row.status === 0 ? 1 : 0
    };
    this.subscription = this.recoveryPromotionDataService.toggleStatus(row.id, data).subscribe(() =>
      this.messages$ = this.recoveryPromotionDataService.messages$
    );
  }

  onReload() {
    this.onViewPageBy(this.page).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, row?: any}) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        mode: data.mode,
        row: data.row
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true){
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

}
