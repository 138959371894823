<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header" style="display: inline">
            <div>
                <h5 class="modal-title" style="display: inline">Total Member Registration By Affiliate - {{ totalMember }} </h5>
            </div>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <div class="col-12">
                <div class="kt-section mb-0">
                    <div class="kt-section__content">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover table-striped">
                                <thead>
                                        <tr>
                                            <ng-container >
                                                <th class="text-center">Register Date</th>
                                                <th class="text-center">Currency</th>
                                                <th class="text-center">Member Login</th>
                                                <th class="text-center">Member Name</th>
                                            </ng-container>
                                        </tr>
                                </thead>
                                <tbody *ngIf="affiliateMember$?.length > 0">
                                    <tr *ngFor="let row of affiliateMember$;">
                                        <ng-container>
                                            <td class="text-center align-middle">{{ row.register_date | timezoneDate: 'YYYY-MM-DD HH:mm'}}</td>
                                            <td class="text-center align-middle">{{ row.currency_code }}</td>
                                            <td class="text-center align-middle">{{ row.username }}</td>
                                            <td class="text-center align-middle">{{ row.name }}</td>
                                        </ng-container>
                                    </tr>
                                    <kt-fallback-row [collection]="affiliateMember$"></kt-fallback-row>
                                </tbody>
                            </table>
                        </div>
                        <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                            <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="getAffiliateMemberData(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        </div>
    </div>
</div>