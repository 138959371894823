<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <div class="col-12 row mb-2 pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label class="mb-0">Telemarketer:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8" style="padding:0" [form]='form'
                    [dropdownList]='telemarketerDropdownList' [dropdownSettings]='telemarketerDropdownSettings'
                    [formName]="'telemarketer_id'" [selectionAttributes]="'id'"
                    [selectedItems]='telemarketerSelectedItems'>
                  </kt-dropdown-wo-lazyload>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Currency:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select class="form-control" formControlName="currency_id">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <ng-container *ngFor="let value of dropdown.currencies | async">
                      <option [value]="value.id">{{ value.name }}</option>
                    </ng-container>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label class="mb-0">Year:<span class="text-danger">*</span></label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" class="form-control" placeholder="Search" formControlName="year">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label class="mb-0">Month:<span class="text-danger">*</span></label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" class="form-control" placeholder="Search" formControlName="month">
                </div>
              </div>

              <div class="col-12 row mb-2 pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Timezone' }}:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <kt-dropdown-wo-lazyload
                      #timezone
                      (click)="onChangeTimezone(timezone.selectedItems)"
                      [form] = 'form'
                      [dropdownList] = 'dropdown.timezones'
                      [dropdownSettings] = 'timezoneDropdownSettings'
                      [formName] = "'timezone'"
                      [selectionAttributes] = "'timezone'"
                      [selectedItems] = 'timezoneSelectedItems'>
                  </kt-dropdown-wo-lazyload>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
        <div>
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading||!form.valid"
            (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <button *ngIf="(access$ | async).export" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button"
          [disabled]="loading"><i
            [class]="loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
      </div>
      <!-- Table -->
      <div class="kt-section ">
        <div class="kt-section__content">
          
          <div class="text-center">
            <div class="d-inline-block">
              <div class="tier-area">
                <div class="box-tier tier-0-color"></div> Tier 0
              </div>
            </div>
            <div class="d-inline-block">
              <div class="tier-area">
                <div class="box-tier tier-1-color"></div> Tier 1
              </div>
            </div>
            <div class="d-inline-block">
              <div class="tier-area">
                <div class="box-tier tier-2-color"></div> Tier 2
              </div>
            </div>
            <div class="d-inline-block">
              <div class="tier-area">
                <div class="box-tier tier-3-color"></div> Tier 3
              </div>
            </div>
          </div>
          
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="text-center" rowspan="2">Region</th>
                  <th class="text-center" rowspan="2">Merchant</th>
                  <th class="text-center sort-enabled" rowspan="2" (click)="onSortColumn('headcount')"
                    [ngClass]="sortingConfig.headcount"
                    [class.sort-selected]="sortingSelection.sort_by === 'headcount'">Headcount
                  </th>
                  <th class="text-center sort-enabled" rowspan="2" (click)="onSortColumn('total_leads_assgined')"
                    [ngClass]="sortingConfig.total_leads_assgined"
                    [class.sort-selected]="sortingSelection.sort_by === 'total_leads_assgined'">Total Leads Assigned</th>
                  <th class="text-center sort-enabled" rowspan="2" (click)="onSortColumn('fresh_leads')"
                    [ngClass]="sortingConfig.fresh_leads"
                    [class.sort-selected]="sortingSelection.sort_by === 'fresh_leads'">
                    Fresh Leads</th>
                  <th class="text-center sort-enabled" rowspan="2" (click)="onSortColumn('old_leads')"
                    [ngClass]="sortingConfig.old_leads"
                    [class.sort-selected]="sortingSelection.sort_by === 'old_leads'">
                    Old Leads</th>
                  <th class="text-center sort-enabled" rowspan="2" (click)="onSortColumn('non_member_leads')"
                    [ngClass]="sortingConfig.non_member_leads"
                    [class.sort-selected]="sortingSelection.sort_by === 'non_member_leads'">
                    Non Member Leads</th>
                  <th class="text-center" colspan="3">
                    Conversion Rate</th>
                  <th class="text-center" colspan="8">
                    Count of FTD Collected</th>
                  <th class="text-center sort-enabled" rowspan="2" colspan="2" (click)="onSortColumn('deposit_value')"
                    [ngClass]="sortingConfig.deposit_value"
                    [class.sort-selected]="sortingSelection.sort_by === 'deposit_value'">
                    Total Deposit Amount</th>
                  <th class="text-center sort-enabled" rowspan="2" (click)="onSortColumn('commissionable_depositors')"
                    [ngClass]="sortingConfig.commissionable_depositors"
                    [class.sort-selected]="sortingSelection.sort_by === 'commissionable_depositors'">
                    Count of FTD Commissionable Depositors</th>
                  <th class="text-center sort-enabled" rowspan="2" (click)="onSortColumn('comm_conversion_rate')"
                    [ngClass]="sortingConfig.comm_conversion_rate"
                    [class.sort-selected]="sortingSelection.sort_by === 'comm_conversion_rate'">Commisionable Conversion
                    Rate (%)
                  </th>
                  <th class="text-center sort-enabled" rowspan="2" (click)="onSortColumn('total_calls')"
                    [ngClass]="sortingConfig.total_calls"
                    [class.sort-selected]="sortingSelection.sort_by === 'total_calls'">Total Calls Made in a Month by
                    Total Headcounts</th>
                  <th class="text-center sort-enabled" rowspan="2" (click)="onSortColumn('avg_calls')"
                    [ngClass]="sortingConfig.avg_calls"
                    [class.sort-selected]="sortingSelection.sort_by === 'avg_calls'">Number of Calls per Day (Average)
                  </th>
                  <th class="text-center sort-enabled" rowspan="2" (click)="onSortColumn('max_calls')"
                    [ngClass]="sortingConfig.max_calls"
                    [class.sort-selected]="sortingSelection.sort_by === 'max_calls'">Highest Number of Calls in a Day
                  </th>
                  <th class="text-center sort-enabled" rowspan="2" (click)="onSortColumn('min_calls')"
                    [ngClass]="sortingConfig.min_calls"
                    [class.sort-selected]="sortingSelection.sort_by === 'min_calls'">Lowest Number of Calls in a Day
                  </th>
                </tr>
                <tr>
                  <th class="text-center">Fresh Leads</th>
                  <th class="text-center">Old Leads</th>
                  <th class="text-center">Non Member Leads</th>

                  <th class="text-center sort-enabled" colspan="2" (click)="onSortColumn('total_deposits')"
                    [ngClass]="sortingConfig.total_deposits"
                    [class.sort-selected]="sortingSelection.sort_by === 'total_deposits'">Total
                  </th>

                  <th class="text-center sort-enabled" colspan="2" (click)="onSortColumn('fresh_leads_deposits')"
                    [ngClass]="sortingConfig.fresh_leads_deposits"
                    [class.sort-selected]="sortingSelection.sort_by === 'fresh_leads_deposits'">Fresh Leads
                  </th>

                  <th class="text-center sort-enabled" colspan="2" (click)="onSortColumn('old_leads_deposits')"
                    [ngClass]="sortingConfig.old_leads_deposits"
                    [class.sort-selected]="sortingSelection.sort_by === 'old_leads_deposits'">Old Leads
                  </th>

                  <th class="text-center sort-enabled" colspan="2" (click)="onSortColumn('non_member_leads_deposits')"
                    [ngClass]="sortingConfig.non_member_leads_deposits"
                    [class.sort-selected]="sortingSelection.sort_by === 'non_member_leads_deposits'">Non Member Leads
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="(salesConversionReport$ | async) as rows">
                <ng-container *ngFor="let row of rows; let i = index">
                  <tr [ngClass]="i % 2 === 0 ? 'even' : 'odd'">
                    <td class="text-center align-middle" rowspan="4">{{ row.country_code }}</td>
                    <td class="text-center align-middle" rowspan="4">{{ row.name }}</td>
                    <td class="text-center align-middle" rowspan="4">
                      <span [ngClass]="row.headcount > 0 ? 'details text-primary font-weight-bold' : ''">
                        <ng-container *ngIf="row.headcount > 0 else Else">
                          <a (click)="onOpenDialog(row)">
                            {{ row.headcount }}
                          </a>
                        </ng-container>
                        <ng-template #Else>
                          {{ row.headcount }}
                        </ng-template>
                      </span>
                    </td>
                    <td class="text-center align-middle" rowspan="4">{{ row.total_leads_assgined }}</td>
                    <td class="text-center align-middle" rowspan="4">{{ row.fresh_leads }}</td>
                    <td class="text-center align-middle" rowspan="4">{{ row.old_leads }}</td>
                    <td class="text-center align-middle" rowspan="4">{{ row.non_member_leads }}</td>
                    <td class="text-center td-tier-0">{{ row.tier_0_fresh_leads_conversion_rate }}</td>
                    <td class="text-center td-tier-0">{{ row.tier_0_old_leads_conversion_rate }}</td>
                    <td class="text-center td-tier-0">{{ row.tier_0_non_member_leads_conversion_rate }}</td>
                    <td class="text-center align-middle" rowspan="4">{{ row.total_deposits }}</td>
                    <td class="text-center td-tier-0">{{ row.tier_0_total_deposits }}</td>
                    <td class="text-center align-middle" rowspan="4">{{ row.fresh_leads_deposits }}</td>
                    <td class="text-center td-tier-0">{{ row.tier_0_fresh_leads_deposits }}</td>
                    <td class="text-center align-middle" rowspan="4">{{ row.old_leads_deposits }}</td>
                    <td class="text-center td-tier-0">{{ row.tier_0_old_leads_deposits }}</td>
                    <td class="text-center align-middle" rowspan="4">{{ row.non_member_leads_deposits }}</td>
                    <td class="text-center td-tier-0">{{ row.tier_0_non_member_leads_deposits }}</td>
                    <td class="text-center align-middle" rowspan="4">{{ row.deposit_value > 0 ? (row.deposit_value | number : '1.2-2') : row.deposit_value }}</td>
                    <td class="text-center td-tier-0">{{ row.deposit_value_tier_0 > 0 ? (row.deposit_value_tier_0 | number : '1.2-2') : row.deposit_value_tier_0 }}</td>
                    <td class="text-center align-middle" rowspan="4">{{ row.commissionable_depositors }}</td>
                    <td class="text-center align-middle" rowspan="4">{{ showCommConversionRate(row.comm_conversion_rate) }}</td>
                    <td class="text-center align-middle" rowspan="4">{{ row.total_calls }}</td>
                    <td class="text-center align-middle" rowspan="4">{{ row.avg_calls }}</td>
                    <td class="text-center align-middle" rowspan="4">{{ row.max_calls }}</td>
                    <td class="text-center align-middle" rowspan="4">{{ row.min_calls }}</td>
                  </tr>
                  <tr>
                    <td class="text-center td-tier-1">{{ row.tier_1_fresh_leads_conversion_rate }}</td>
                    <td class="text-center td-tier-1">{{ row.tier_1_old_leads_conversion_rate }}</td>
                    <td class="text-center td-tier-1">{{ row.tier_1_non_member_leads_conversion_rate }}</td>
                    <td class="text-center td-tier-1">{{ row.tier_1_total_deposits }}</td>
                    <td class="text-center td-tier-1">{{ row.tier_1_fresh_leads_deposits }}</td>
                    <td class="text-center td-tier-1">{{ row.tier_1_old_leads_deposits }}</td>
                    <td class="text-center td-tier-1">{{ row.tier_1_non_member_leads_deposits }}</td>
                    <td class="text-center td-tier-1">{{ row.deposit_value_tier_1 > 0 ? (row.deposit_value_tier_1 | number : '1.2-2') : row.deposit_value_tier_1 }}</td>
                  </tr>
                  <tr>
                    <td class="text-center td-tier-2">{{ row.tier_2_fresh_leads_conversion_rate }}</td>
                    <td class="text-center td-tier-2">{{ row.tier_2_old_leads_conversion_rate }}</td>
                    <td class="text-center td-tier-2">{{ row.tier_2_non_member_leads_conversion_rate }}</td>
                    <td class="text-center td-tier-2">{{ row.tier_2_total_deposits }}</td>
                    <td class="text-center td-tier-2">{{ row.tier_2_fresh_leads_deposits }}</td>
                    <td class="text-center td-tier-2">{{ row.tier_2_old_leads_deposits }}</td>
                    <td class="text-center td-tier-2">{{ row.tier_2_non_member_leads_deposits }}</td>
                    <td class="text-center td-tier-2">{{ row.deposit_value_tier_2 > 0 ? (row.deposit_value_tier_2 | number : '1.2-2') : row.deposit_value_tier_2 }}</td>
                  </tr>
                  <tr>
                    <td class="text-center td-tier-3">{{ row.tier_3_fresh_leads_conversion_rate }}</td>
                    <td class="text-center td-tier-3">{{ row.tier_3_old_leads_conversion_rate }}</td>
                    <td class="text-center td-tier-3">{{ row.tier_3_non_member_leads_conversion_rate }}</td>
                    <td class="text-center td-tier-3">{{ row.tier_3_total_deposits }}</td>
                    <td class="text-center td-tier-3">{{ row.tier_3_fresh_leads_deposits }}</td>
                    <td class="text-center td-tier-3">{{ row.tier_3_old_leads_deposits }}</td>
                    <td class="text-center td-tier-3">{{ row.tier_3_non_member_leads_deposits }}</td>
                    <td class="text-center td-tier-3">{{ row.deposit_value_tier_3 > 0 ? (row.deposit_value_tier_3 | number : '1.2-2') : row.deposit_value_tier_3 }}</td>
                  </tr>
                </ng-container>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold">
              </tbody>
            </table>
          </div>
          <!--Notes-->
          <div class="col-xl-12 summary-wrapper mt-3">
            <div class="row">
                <div class="col-12 pl-0 pr-0">
                    <label>Fresh Leads: Leads that were assigned and signed up < 90 days</label>
                </div>
            </div>
            <div class="row">
                <div class="col-12 pl-0 pr-0">
                    <label>Old Leads: Leads that were assigned and signed up >= 90 days</label>
                </div>
            </div>
            <div class="row">
                <div class="col-12 pl-0 pr-0">
                    <label>Non Member Leads: Leads that were assigned and imported via external source (CSV files)</label>
                </div>
            </div>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true"
              [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total"
              (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                Showing {{pagination.from}} to {{pagination.to}} of
                {{ pagination.total | number : '1.0' }} records
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
