
<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div  class="kt-portlet kt-portlet--mobile">
      <div  class="kt-portlet__body">
        <div class="kt-form kt-form--label-right  kt-margin-b-10">
            <div class="row align-items-center">
                <div class="col-xl-12 order-2 order-xl-1">
                  <mat-tab-group animationDuration="0ms" (selectedTabChange)="onSelectTab()">
                    <ng-container>
                      <mat-tab label="Send Message">
                        <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                        <!-- <b *ngIf="sendMessageUseNewPermissions ? !canSendMessage : (!sendMessagePermission || !allMessagesPermission)" class="text-danger row col mb-3">No Permission to Send This Message.</b> -->
                        <b *ngIf="!canSendMessage" class="text-danger row col mb-3">No Permission to Send This Message.</b>
                        <form class="row align-items-center" [formGroup]="sendMessageForm">
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group" *ngIf="file == undefined">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Member <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <kt-member-dropdown
                                  class="dropdown-maxheight"
                                  [form]="sendMessageForm"
                                  [dropdownSettings]='sendMessageMemberDropdownSettings'
                                  [formName]="'member_account_id'"
                                  [selectionAttributes]="'id'"
                                  [selectedItems]="selectedMembers"
                                  (selectedItemsChanged)="onMemberChanged($event)"
                                  [ngClass]="{'is-invalid': checkValidation && selectedMembers.length === 0 && file === undefined}"
                                >
                                </kt-member-dropdown>
                                <p class="selected-member-wrapper">
                                  <small *ngFor="let item of selectedMembers" class="selected-member bg-info">
                                    {{ item.username }}
                                    <span class="bg-danger remove" (click)="onRemoveMember(item.id, 'sendMessageForm')">x</span>
                                  </small>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Subject <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <input type="text" formControlName="title" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && sendMessageForm.controls.title.errors }" [attr.disabled]="(sendMessageUseNewPermissions ? !canSendMessage : (!sendMessagePermission || !allMessagesPermission))? 'disabled' : null"> -->
                                <input type="text" formControlName="title" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && sendMessageForm.controls.title.errors }" [attr.disabled]="!canSendMessage ? 'disabled' : null">
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group" *ngIf="sendMessageForm.get('member_account_id').value == null || sendMessageForm.get('member_account_id').value?.length == 0">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Recipients <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <label class="btn btn-default btn-sm float-left mb-0 mr-4" [ngClass]="{'is-invalid': checkValidation && file === undefined && selectedMembers.length === 0,'disabled' : (sendMessageUseNewPermissions ? !canSendMessage : (!sendMessagePermission || !allMessagesPermission)) }"> -->
                                <label class="btn btn-default btn-sm float-left mb-0 mr-4" [ngClass]="{'is-invalid': checkValidation && file === undefined && selectedMembers.length === 0,'disabled' : !canSendMessage }">
                                  <span>Choose file</span>
                                  <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                  <!-- <input type="file" accept=".csv" hidden (change)="onUploadFile($event, 'sendMessageForm')" #myFileInput [attr.disabled]="(sendMessageUseNewPermissions ? !canSendMessage : (!sendMessagePermission || !allMessagesPermission)) ? 'disabled' : null"> -->
                                  <input type="file" accept=".csv" hidden (change)="onUploadFile($event, 'sendMessageForm')" #myFileInput [attr.disabled]="!canSendMessage ? 'disabled' : null">
                                </label>
                                <div class="kt-form__label col-form-label" *ngIf="fileName !== null">
                                  <label>{{ fileName }}
                                    <span class="ml-2 btn btn-link" (click)="onRemoveFile('sendMessageForm')">x</span></label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Message Template</label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <kt-dropdown-wo-lazyload
                                  [form] = 'sendMessageForm'
                                  [dropdownList] = 'messageTemplateDropdownList'
                                  [dropdownSettings] = 'sendMessageTemplateDropdownSettings'
                                  [formName] = "'message_template'"
                                  [selectionAttributes] = "'message'"
                                  [selectedItems]="selectedMessageTemplate"
                                  [isMessageTemplate]="true"
                                  (selectedItemsChanged)="onMessageTemplateChanged($event, 'messageForm')">
                                </kt-dropdown-wo-lazyload>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Message <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <ckeditor *ngIf="sendMessageUseNewPermissions ? canSendMessage : (sendMessagePermission && allMessagesPermission)" [config]="editorConfig" [editor]="editor" formControlName="content" [ngClass]="{ 'is-invalid':checkValidation && sendMessageForm.controls.content.errors }"></ckeditor>
                                <textarea *ngIf="sendMessageUseNewPermissions ? !canSendMessage : (!sendMessagePermission || !allMessagesPermission)" type="text" rows="3" class="form-control" [attr.disabled]="!canSendMessage ? 'disabled' : null"></textarea> -->

                                <ckeditor *ngIf="canSendMessage" [config]="editorConfig" [editor]="editor" formControlName="content" [ngClass]="{ 'is-invalid':checkValidation && sendMessageForm.controls.content.errors }"></ckeditor>
                                <textarea *ngIf="!canSendMessage" type="text" rows="3" class="form-control" [attr.disabled]="!canSendMessage ? 'disabled' : null"></textarea>
                                <span>{{ genericnotice }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 form-group d-flex flex-row-reverse mb-0">
                            <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                            <!-- <kt-submit-button [buttonLoading]="buttonLoading" *ngIf="sendMessageUseNewPermissions ? canSendMessage : (allMessagesPermission && sendMessagePermission)" (confirmed)="onSend('message')"></kt-submit-button> -->
                            <kt-submit-button [buttonLoading]="buttonLoading" *ngIf="canSendMessage" (confirmed)="onSend('message')"></kt-submit-button>
                          </div>
                        </form>
                      </mat-tab>
                      <mat-tab label="Send SMS">
                        <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                        <!-- <b *ngIf="sendMessageUseNewPermissions ? !canSendSMS : (!sendMessagePermission || !allSMSPermission)" class="text-danger row col mb-3">No Permission to Send This Message.</b> -->
                        <b *ngIf="!canSendSMS" class="text-danger row col mb-3">No Permission to Send This Message.</b>
                        <form class="row align-items-center" [formGroup]="sendSMSForm">
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>SMS Provider <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <select class="form-control" formControlName="sms_provider" [ngClass]="{ 'is-invalid':checkValidation && sendSMSForm.controls.sms_provider.errors }" [attr.disabled]="(sendMessageUseNewPermissions ? !canSendSMS : (!sendMessagePermission || !allSMSPermission)) ? 'disabled' : null"> -->
                                <select class="form-control" formControlName="sms_provider" [ngClass]="{ 'is-invalid':checkValidation && sendSMSForm.controls.sms_provider.errors }" [attr.disabled]="!canSendSMS ? 'disabled' : null">
                                  <option [value]="null" [disabled]="true">Please Select</option>
                                  <ng-container *ngFor="let value of dropdown.smsProvider | async">
                                      <option [value]="value.id">{{ value.name }}</option>
                                  </ng-container>
                              </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group" *ngIf="sendSMSForm.value.send_to_custom === null || sendSMSForm.value.send_to_custom === ''">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Member <span class="text-danger" *ngIf="sendSMSForm.value.send_to_custom === null || sendSMSForm.value.send_to_custom === ''">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <input hidden="true" [value]="(reloadMember$ | async)">
                                <kt-member-dropdown class="dropdown-maxheight col-8" style="padding:0" [form]="sendSMSForm" [dropdownSettings]='smsMemberDropdownSettings'
                                [formName]="'send_to'" [selectionAttributes]="'id'" [ngClass]="{'is-invalid': checkValidation && sendSMSForm.controls.send_to.errors }"></kt-member-dropdown>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group" *ngIf="sendSMSForm.value.send_to === null">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Send to <span class="text-danger" *ngIf="sendSMSForm.value.send_to === null">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <textarea type="text" placeholder="<phone number>, <phone number>..." formControlName="send_to_custom" rows="3" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && sendSMSForm.controls.send_to_custom.errors }" [attr.disabled]="(sendMessageUseNewPermissions ? !canSendSMS : (!sendMessagePermission || !allSMSPermission)) ? 'disabled' : null"></textarea> -->
                                <textarea type="text" placeholder="<phone number>, <phone number>..." formControlName="send_to_custom" rows="3" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && sendSMSForm.controls.send_to_custom.errors }" [attr.disabled]="!canSendSMS ? 'disabled' : null"></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group" *ngIf="sendSMSForm.value.send_to === null">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Country <span class="text-danger" *ngIf="sendSMSForm.value.send_to === null">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <select class="form-control" formControlName="country_code" [ngClass]="{ 'is-invalid':checkValidation && sendSMSForm.controls.country_code.errors }" [attr.disabled]="(sendMessageUseNewPermissions ? !canSendSMS : (!sendMessagePermission || !allSMSPermission)) ? 'disabled' : null"> -->
                                <select class="form-control" formControlName="country_code" [ngClass]="{ 'is-invalid':checkValidation && sendSMSForm.controls.country_code.errors }" [attr.disabled]="!canSendSMS ? 'disabled' : null">
                                  <option *ngFor="let value of dropdown.countries" [value]="value">{{ value }}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Subject <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <input type="text" formControlName="subject" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && sendSMSForm.controls.subject.errors }" [attr.disabled]="(sendMessageUseNewPermissions ? !canSendSMS : (!sendMessagePermission || !allSMSPermission)) ? 'disabled' : null"> -->
                                <input type="text" formControlName="subject" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && sendSMSForm.controls.subject.errors }" [attr.disabled]="!canSendSMS ? 'disabled' : null">
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Message Template</label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <kt-dropdown-wo-lazyload
                                  [form] = 'sendSMSForm'
                                  [dropdownList] = 'smsMessageTemplateDropdownList'
                                  [dropdownSettings] = 'smsMessageTemplateDropdownSettings'
                                  [formName] = "'message_template'"
                                  [selectionAttributes] = "'message'"
                                  [selectedItems]="selectedMessageTemplate"
                                  [isSMSMessageTemplate]="true"
                                  (selectedItemsChanged)="onMessageTemplateChanged($event, 'smsForm')">
                                </kt-dropdown-wo-lazyload>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Message <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <textarea type="text" formControlName="message" rows="3" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && sendSMSForm.controls.message.errors }" [attr.disabled]="(sendMessageUseNewPermissions ? !canSendSMS : (!sendMessagePermission || !allSMSPermission)) ? 'disabled' : null"></textarea> -->
                                <textarea type="text" formControlName="message" rows="3" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && sendSMSForm.controls.message.errors }" [attr.disabled]="!canSendSMS ? 'disabled' : null"></textarea>
                                <span>{{ genericnotice }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 form-group d-flex flex-row-reverse mb-0">
                            <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                            <!-- <kt-submit-button [buttonLoading]="buttonLoading" *ngIf="sendMessageUseNewPermissions ? canSendSMS : (allSMSPermission && sendMessagePermission)" (confirmed)="onSend()"></kt-submit-button> -->
                            <kt-submit-button [buttonLoading]="buttonLoading" *ngIf="canSendSMS" (confirmed)="onSend()"></kt-submit-button>
                          </div>
                        </form>
                      </mat-tab>
                      <mat-tab label="Send Campaign SMS" id="campaign-tab">
                        <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                        <!-- <b *ngIf="sendMessageUseNewPermissions ? !canSendCampaignSMS : (!sendMessagePermission || !allSMSPermission)" class="text-danger row col mb-3">No Permission to Send This Message.</b> -->
                        <b *ngIf="!canSendCampaignSMS" class="text-danger row col mb-3">No Permission to Send This Message.</b>
                        <form class="row align-items-center" [formGroup]="formBulk" class="campaign-tab">
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Currency <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <select formControlName="currency_id" class="form-control" (change)="onSelectCurrency($event)" [ngClass]="{ 'is-invalid':checkValidation && formBulk.controls.currency_id.errors }" [attr.disabled]="(sendMessageUseNewPermissions ? !canSendCampaignSMS : (!sendMessagePermission || !allSMSPermission)) ? 'disabled' : null"> -->
                                <select formControlName="currency_id" class="form-control" (change)="onSelectCurrency($event)" [ngClass]="{ 'is-invalid':checkValidation && formBulk.controls.currency_id.errors }" [attr.disabled]="!canSendCampaignSMS ? 'disabled' : null">
                                    <option value="null" disabled hidden> Please Select </option>
                                    <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                        {{ value.name }}
                                    </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>SMS Provider <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <select class="form-control" formControlName="sms_provider" [ngClass]="{ 'is-invalid':checkValidation && formBulk.controls.sms_provider.errors }" [attr.disabled]="(sendMessageUseNewPermissions ? !canSendCampaignSMS : (!sendMessagePermission || !allSMSPermission)) ? 'disabled' : null"> -->
                                <select class="form-control" formControlName="sms_provider" [ngClass]="{ 'is-invalid':checkValidation && formBulk.controls.sms_provider.errors }" [attr.disabled]="!canSendCampaignSMS ? 'disabled' : null">
                                  <option [value]="null" [disabled]="true">Please Select</option>
                                  <ng-container *ngFor="let value of dropdown.campaignSMSProvider | async">
                                      <option [value]="value.id">{{ value.name }}</option>
                                  </ng-container>
                              </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Execution Time (From-To)</label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <div class="input-group date">
                                  <input type="text" class="form-control" placeholder="HH:mm:ss - HH:mm:ss"
                                      (change)="onDateRange($event)" formControlName="defaultDate"
                                      [timePicker]="true" [timePickerSeconds]="true"
                                       [locale]="{format: 'HH:mm:ss'}"
                                      [timePicker24Hour]="true" [showDropdowns]="false"
                                  ngxDaterangepickerMd/>
                                  <span class="input-group-append">
                                      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Deduplicate <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <div class="custom-control custom-checkbox form-control border-0">
                                  <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                  <!-- <input type="checkbox" formControlName="deduplicate" class="custom-control-input" id="deduplicate" [ngClass]="{ 'is-invalid':checkValidation && formBulk.controls.deduplicate.errors }" [attr.disabled]="(sendMessageUseNewPermissions ? !canSendCampaignSMS : (!sendMessagePermission || !allSMSPermission)) ? 'disabled' : null"> -->
                                  <input type="checkbox" formControlName="deduplicate" class="custom-control-input" id="deduplicate" [ngClass]="{ 'is-invalid':checkValidation && formBulk.controls.deduplicate.errors }" [attr.disabled]="!canSendCampaignSMS ? 'disabled' : null">
                                  <label class="custom-control-label" for="deduplicate"></label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Promotion</label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <kt-dropdown-wo-lazyload *ngIf="rewardsDropdown.length > 0" [form]='formBulk' [dropdownList]='rewardsDropdown' [dropdownSettings]='rewardsDropdownSettings' [formName]="'promotion_id'" [selectionAttributes]="'id'">
                                </kt-dropdown-wo-lazyload>
                                <div class="spinner-wrapper" *ngIf="loading | async">
                                  <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Recipients <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <label class="btn btn-default btn-sm float-left mb-0 mr-4" [ngClass]="{'is-invalid': checkValidation && bulkFile === undefined, 'disabled' : (sendMessageUseNewPermissions ? !canSendCampaignSMS : (!sendMessagePermission || !allSMSPermission)) }"> -->
                                <label class="btn btn-default btn-sm float-left mb-0 mr-4" [ngClass]="{'is-invalid': checkValidation && bulkFile === undefined, 'disabled' : !canSendCampaignSMS }">
                                  <span>Choose file</span>
                                  <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                  <!-- <input type="file" accept=".csv" hidden (change)="onUploadFile($event, 'formBulk')" [attr.disabled]="(sendMessageUseNewPermissions ? !canSendCampaignSMS : (!sendMessagePermission || !allSMSPermission)) ? 'disabled' : null" #myFileInput> -->
                                  <input type="file" accept=".csv" hidden (change)="onUploadFile($event, 'formBulk')" [attr.disabled]="!canSendCampaignSMS ? 'disabled' : null" #myFileInput>
                                </label>
                                <div class="kt-form__label col-form-label" *ngIf="bulkFileName !== null">
                                  <label>{{ bulkFileName }}
                                    <span class="ml-2 btn btn-link" (click)="onRemoveFile('formBulk')">x</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Subject <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <input type="text" formControlName="subject" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && formBulk.controls.subject.errors }" [attr.disabled]="(sendMessageUseNewPermissions ? !canSendCampaignSMS : (!sendMessagePermission || !allSMSPermission) ) ? 'disabled' : null"> -->
                                <input type="text" formControlName="subject" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && formBulk.controls.subject.errors }" [attr.disabled]="!canSendCampaignSMS ? 'disabled' : null">
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Message Template</label>
                              </div>
                              <div class="col-10 kt-form__control campaign-template">
                                <kt-dropdown-wo-lazyload id="campaign-dropdown-template"
                                  [form] = 'sendMessageForm'
                                  [dropdownList] = 'smsMessageTemplateDropdownList'
                                  [dropdownSettings] = 'smsMessageTemplateDropdownSettings'
                                  [formName] = "'message_template'"
                                  [selectionAttributes] = "'message'"
                                  [selectedItems]="selectedMessageTemplate"
                                  [isSMSMessageTemplate]="true"
                                  (selectedItemsChanged)="onMessageTemplateChanged($event, 'bulkSmsForm')">
                                </kt-dropdown-wo-lazyload>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Message <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <textarea type="text" formControlName="message_content" rows="3" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && formBulk.controls.message_content.errors }" [attr.disabled]="(sendMessageUseNewPermissions ? !canSendCampaignSMS : (!sendMessagePermission || !allSMSPermission)) ? 'disabled' : null"></textarea> -->
                                <textarea type="text" formControlName="message_content" rows="3" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && formBulk.controls.message_content.errors }" [attr.disabled]="!canSendCampaignSMS ? 'disabled' : null"></textarea>
                                <span>Message template variable not supported in this feature.</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 form-group d-flex flex-row-reverse mb-0">
                            <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                            <!-- <kt-submit-button [buttonLoading]="buttonLoading" *ngIf="sendMessageUseNewPermissions ? canSendCampaignSMS : (allSMSPermission && sendMessagePermission)" (confirmed)="onSend('bulk')"></kt-submit-button> -->
                            <kt-submit-button [buttonLoading]="buttonLoading" *ngIf="canSendCampaignSMS" (confirmed)="onSend('bulk')"></kt-submit-button>
                          </div>
                        </form>
                      </mat-tab>
                      <mat-tab label="Send Webpush">
                        <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                        <!-- <b *ngIf="sendMessageUseNewPermissions ? !canSendWebpush : (!sendMessagePermission || !webPushPermission)" class="text-danger row col mb-3">No Permission to Send This Message.</b> -->
                        <b *ngIf="!canSendWebpush" class="text-danger row col mb-3">No Permission to Send This Message.</b>
                        <form class="row align-items-center" [formGroup]="sendWebPushForm">
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group" *ngIf="webpushFile == undefined">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Member <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <kt-member-dropdown
                                  class="dropdown-maxheight"
                                  [form]="sendWebPushForm"
                                  [dropdownSettings]='webPushMemberDropdownSettings'
                                  [formName]="'member_account_id'"
                                  [selectionAttributes]="'id'"
                                  [selectedItems]="selectedMembersWebpush"
                                  (selectedItemsChanged)="onMemberChanged($event, 'sendWebPushForm')"
                                  [ngClass]="{'is-invalid': checkValidation && selectedMembersWebpush.length === 0 && webpushFile === undefined}">
                                </kt-member-dropdown>
                                <p class="selected-member-wrapper">
                                  <small *ngFor="let item of selectedMembersWebpush" class="selected-member bg-info">
                                    {{ item.username }}
                                    <span class="bg-danger remove" (click)="onRemoveMember(item.id, 'sendWebPushForm')">x</span>
                                  </small>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Subject <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <input type="text" formControlName="title" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && sendWebPushForm.controls.title.errors }" [attr.disabled]="(sendMessageUseNewPermissions ? !canSendWebpush : (!sendMessagePermission || !webPushPermission)) ? 'disabled' : null"> -->
                                <input type="text" formControlName="title" class="form-control" [ngClass]="{ 'is-invalid':checkValidation && sendWebPushForm.controls.title.errors }" [attr.disabled]="!canSendWebpush ? 'disabled' : null">
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group" *ngIf="sendWebPushForm.get('member_account_id').value == null || sendWebPushForm.get('member_account_id').value?.length == 0">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Recipients <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <label class="btn btn-default btn-sm float-left mb-0 mr-4" [ngClass]="{'is-invalid': checkValidation && webpushFile === undefined && selectedMembers.length === 0, 'disabled' : (sendMessageUseNewPermissions ? !canSendWebpush : (!sendMessagePermission || !webPushPermission)) }"> -->
                                <label class="btn btn-default btn-sm float-left mb-0 mr-4" [ngClass]="{'is-invalid': checkValidation && webpushFile === undefined && selectedMembers.length === 0, 'disabled' : !canSendWebpush }">
                                  <span>Choose file</span>
                                  <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                  <!-- <input type="file" accept=".csv" hidden (change)="onUploadFile($event, 'webPushForm')" [attr.disabled]="(sendMessageUseNewPermissions ? !canSendWebpush : (!sendMessagePermission || !webPushPermission)) ? 'disabled' : null" #myFileInput> -->
                                  <input type="file" accept=".csv" hidden (change)="onUploadFile($event, 'webPushForm')" [attr.disabled]="!canSendWebpush ? 'disabled' : null" #myFileInput>
                                </label>
                                <div class="kt-form__label col-form-label" *ngIf="webpushFileName !== null">
                                  <label>{{ webpushFileName }}
                                    <span class="ml-2 btn btn-link" (click)="onRemoveFile('webPushForm')">x</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Message Template</label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <kt-dropdown-wo-lazyload
                                  [form] = 'sendWebPushForm'
                                  [dropdownList] = 'messageTemplateDropdownList'
                                  [dropdownSettings] = 'webPushTemplateDropdownSettings'
                                  [formName] = "'message_template'"
                                  [selectionAttributes] = "'message'"
                                  [selectedItems]="selectedMessageTemplate"
                                  [isMessageTemplate]="true"
                                  (selectedItemsChanged)="onMessageTemplateChanged($event, 'webPushForm')">
                                </kt-dropdown-wo-lazyload>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 kt-margin-b-20-tablet-and-mobile form-group">
                            <div class="kt-form__group--inline row">
                              <div class="col-2 kt-form__label col-form-label">
                                <label>Message <span class="text-danger">*</span></label>
                              </div>
                              <div class="col-10 kt-form__control">
                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                <!-- <ckeditor *ngIf="sendMessageUseNewPermissions ? canSendWebpush : (sendMessagePermission && webPushPermission)" [config]="editorConfig" [editor]="editor" formControlName="content" [ngClass]="{ 'is-invalid':checkValidation && sendWebPushForm.controls.content.errors }"></ckeditor>
                                <textarea *ngIf="sendMessageUseNewPermissions ?!canSendWebpush : (!sendMessagePermission || !webPushPermission)" type="text" rows="3" class="form-control" [attr.disabled]="!canSendWebpush ? 'disabled' : null"></textarea> -->

                                <ckeditor *ngIf="canSendWebpush" [config]="editorConfig" [editor]="editor" formControlName="content" [ngClass]="{ 'is-invalid':checkValidation && sendWebPushForm.controls.content.errors }"></ckeditor>
                                <textarea *ngIf="!canSendWebpush" type="text" rows="3" class="form-control" [attr.disabled]="!canSendWebpush ? 'disabled' : null"></textarea>
                                <span>{{ genericnotice }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 form-group d-flex flex-row-reverse mb-0">
                            <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                            <!-- <kt-submit-button [buttonLoading]="buttonLoading" *ngIf="sendMessageUseNewPermissions ? canSendWebpush : (webPushPermission && sendMessagePermission)" (confirmed)="onSend('webpush')"></kt-submit-button> -->
                            <kt-submit-button [buttonLoading]="buttonLoading" *ngIf="canSendWebpush" (confirmed)="onSend('webpush')"></kt-submit-button>
                          </div>
                        </form>
                      </mat-tab>

                    </ng-container>
                  </mat-tab-group>
                </div>
            </div>
        </div>
      </div>
  </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
