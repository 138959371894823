import { AuthHttpService } from '@core/services/auth-http.service';
// Angular or Core
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
// Service
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MemberDataService } from './../../general/members/services/member-data.service';
import { MemberOnlineDataService } from './services/member-online-data.service';
// Model
import { MemberOnline } from '@core/models/member-online.model';
import { Pagination } from '@core/models/pagination.model';
// Component
import { MemberInformationDialogComponent } from './../../general/members/dialogs/member-information/member-information.component';
// Third Party
import moment from 'moment';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-member-online',
  templateUrl: './member-online.component.html',
  styleUrls: ['./member-online.component.scss']
})
export class MemberOnlineComponent implements OnInit, OnDestroy {

  form: FormGroup;

  messages2$ = new Subject<any[]>();

  pageSize = 30;
  page = 1;
  maxSize = 5;
  pagination: Pagination;
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
    groups: this.dropdownHttpService.groups,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies'))
  };

  dropdownSettings = {};

  params = ``;

  memberOnline$: Observable<MemberOnline>;

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '1.5';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'name': 'desc',
    'currency_code': 'desc',
    'member_group_name': 'desc',
    'agent_username': 'desc',
    'last_activity': 'desc',
    'online_time': 'desc',
    'last_login_time': 'desc',
    'affiliate_username': 'desc'
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;

  // permissions
  canViewMemberDialog: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private memberOnlineDataService: MemberOnlineDataService,
    private memberDataService: MemberDataService,
    private loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    public dialog: MatDialog,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));

    this.pagination = this.memberOnlineDataService.pagination;
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.member_online_view_member_dialog;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.page = 1;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
      })
    ).subscribe(() => {
      this.onViewPageBy();
    });
  }

  onClear() {
    this.clearBtnLoading = true;
    this.page = 1;
    this.eventEmitterService.onClearMemberSearch();
    this.formInit();
    this.onSubmit(true);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.memberOnline$ = this.memberOnlineDataService.getMemberOnline(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap((res: any) => {
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.dataLength = res.length;
        this.pagination = this.memberOnlineDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  onOpenDialog(type: string, memberId: number) {
    if (type === 'member-information' && !this.canViewMemberDialog) {
      this.messages2$.next(['You have no permission to view member dialog']);
      return;
    }

    if (memberId) {
      const member = this.memberDataService.getById(memberId, `?start_date=${moment(this.transactionHttpService.getYesterday().from).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
      this.subscription = member.pipe(
        tap((res) => {
          if (res) {
            this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' });
          }
        })
      ).subscribe();
    }
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      name: new FormControl(null),
      agent_id: new FormControl(null),
      affiliate_username: new FormControl(null),
      currency_id: new FormControl('all'),
      group_id: new FormControl('all')
    });
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '1500px',
      height: '80vh',
      autoFocus: false,
      data
    });
  }

}
