<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog d-block">
    <form class="kt-form" [formGroup]="form">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">{{ data.mode === 'create' ? 'Create' : 'Edit'}} Recovery Promotion</h5>
              <span class="modal-x-button" (click)="onCloseDialog()">
                  <i class="fa fa-times" aria-hidden="true"></i>
              </span>
          </div>
          <div class="modal-body">
            <div class="row">
                <div class="col-md-12 form-group">
                    <label class="control-label">Name <span class="text-danger">*</span></label>
                    <input type="text" formControlName="name" class="col-12 form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label class="control-label">Days of Inactive <span class="text-danger">*</span></label>
                    <input type="number" (wheel)="false" formControlName="inactivity" min="1" class="col-12 form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label class="control-label">Promotion Code </label>
                    <kt-dropdown-wo-lazyload
                    [form] = 'form'
                    [dropdownList] = 'promotionCodeDropdownList'
                    [dropdownSettings] = 'promotionCodeDropdownSettings'
                    [formName] = "'promotion_id'"
                    [selectionAttributes] = "'id'"
                    [selectedItems] = 'promotionCodeSelectedItems'>
                </kt-dropdown-wo-lazyload>
                </div>
                <div class="col-md-12 form-group">
                    <label class="control-label">Reward Description </label>
                    <input type="text" formControlName="description" class="col-12 form-control">
                </div>
                <div class="col-md-12 form-group">
                    <label class="control-label">Depositor </label>
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" formControlName="depositor" (change)="onDepositor($event)">
                    </div>
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col">
                <button *ngIf="canViewRecoveryCurrency && data.mode === 'edit'" type="button" class="btn btn-brand" (click)="onOpenDialog()"><i class="fa fa-plus" aria-hidden="true"></i>Recovery Currency</button>
                <button *ngIf="canViewRecoveryMessages && data.mode === 'edit'" type="button" class="btn btn-brand ml-2" (click)="onRecoveryMessagesDialog()"><i class="fa fa-plus" aria-hidden="true"></i>Recovery Messages</button>
            </div>
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
            <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
          </div>
      </div>
    </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
