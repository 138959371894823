import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, Subscription, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { IconLibraryDataService } from './services/icon-library-data.service';
import { IconLibraryEditDialogComponent } from './dialogs/icon-library-edit.component';
import { IconLibrary } from '@core/models/icon-library.model';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-icon-library',
  templateUrl: './icon-library.component.html',
  styleUrls: ['./icon-library.component.scss']
})
export class IconLibraryComponent implements OnInit, OnDestroy {

  messages$ = this.iconLibraryDataService.messages$;
  iconlibrary$: Observable<IconLibrary[]>;

  form: FormGroup;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    merchant: this.dropdownHttpService.merchantSites,
    perPage: this.dropdownHttpService.perPage
  };

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;

  sortingStorageName = 'sortingConfig';
  sortingConfig = {
    'id': 'desc',
    'name': 'desc',
    'section_id': 'desc',
    'status': 'desc',
    'merchant_id': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  // permissions
  canCreateIcon: boolean;
  canEditIcon: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private iconLibraryDataService: IconLibraryDataService,
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateIcon = appPermissions.create_icon;
      this.canEditIcon = appPermissions.edit_icon;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();

    return this.iconlibrary$ = this.iconLibraryDataService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.iconLibraryDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onReload() {
    this.onViewPageBy();
  }

  onOpenDialog(type: string, rows?: any) { 
    switch (type) {
      case 'edit':
        const footerIconData = this.iconLibraryDataService.showFooterIcon(rows.id);
        this.subscription = footerIconData.pipe(
          tap((res) => {
            this.openDialogBy(IconLibraryEditDialogComponent, { mode: 'edit', rows: res });
          })
        ).subscribe();
        break;
      case 'create':
        this.openDialogBy(IconLibraryEditDialogComponent, { mode: 'create' });
        break;
    }
  }

  private openDialogBy(componentRef: any, data?: { mode?: string, rows?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        rows: data.rows,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page);
      }
    });
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    this.loadingBar.start();
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data: any) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        
        return this.iconlibrary$ = this.iconLibraryDataService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.page = 1;
            this.pagination = this.iconLibraryDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }  

  private formInit() {
    this.form = new FormGroup({
      section_name: new FormControl(null),
      status: new FormControl('all'),
      merchant_id: new FormControl('all'),
    });
  }  

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  } 

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }
}
