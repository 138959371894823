import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable()
export class EventEmitterService {

  invokeClearMemberSearch = new EventEmitter();
  memberSearchSubsVar: Subscription;

  invokeClearMerchantAccountSearch = new EventEmitter();
  merchantAccountSubsVar: Subscription;

  updateSelectedReceipt = new EventEmitter();
  selectedReceiptSubsVar: Subscription;

  updateRewardSettings = new EventEmitter();
  updateRewardSettingsVar: Subscription;

  openNotificationMenu = new EventEmitter();
  openNotificationMenuVar: Subscription;

  listenNotificationWebsocket = new EventEmitter();
  listenNotificationWebsocketVar: Subscription;

  detectIdenticalSettings = new EventEmitter();
  detectIdenticalSettingsVar: Subscription;

  updateTimezone = new EventEmitter();
  callUpdateTimezone: Subscription;

  notificationRedirect = new EventEmitter();
  listenNotificationRedirect: Subscription;

  addTransaction = new EventEmitter();
  addTransactionCrypto = new EventEmitter();

  memberInformationChangeTab = new EventEmitter();
  memberInformationChangeTabVar: Subscription;

  withdrawalUpdate = new EventEmitter();
  withdrawalUpdateEmmiter: Subscription;
  
  updateDepositTabBadge = new EventEmitter();

  constructor() { }

  onListenNotificationWebsocket() {
    this.listenNotificationWebsocket.emit();
  }

  onOpenNotificationMenu() {
    this.openNotificationMenu.emit();
  }

  onClearMemberSearch() {
    this.invokeClearMemberSearch.emit();
  }

  onClearMerchantAccountSearch() {
    this.invokeClearMerchantAccountSearch.emit();
  }

  onUpdateSelectedReceipt(data: any) {
    this.updateSelectedReceipt.emit(data);
  }

  onUpdateRewardSettings(data: any) {
    this.updateRewardSettings.emit(data);
  }

  onDetectIdenticalSettings(data: any) {
    this.detectIdenticalSettings.emit(data);
  }

  onUpdateTimezone() {
    this.updateTimezone.emit();
  }

  onNotificationRedirect(data: any) {
    this.notificationRedirect.emit(data);
  }

  onAddTransaction(withdraw: any, action: any, is_crypto: boolean) {
    if( is_crypto ) {
      this.addTransactionCrypto.emit({withdraw, action});
    } else {
      this.addTransaction.emit({withdraw, action});
    }
  }

  onUpdateWithdrawal(withdrawal: any) {
    this.withdrawalUpdate.emit(withdrawal);
  }

  onUpdateDepositResult(data: any) {
    this.updateDepositTabBadge.emit(data);
  }

}
