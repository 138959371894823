<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <!-- Header -->
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? ('Edit' | translate) : 'Create' | translate }} {{ 'Telemarketer Settings' | translate }}</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-md-6 form-group">
            <label class="control-label">{{ 'FTD Conversion Period (Days)' | translate }} <span class="text-danger">* </span>
              <i class="fas fa-exclamation-circle" 
                matTooltip="Number of days required for a telemarketer's leads to make their first-time deposit (FTD)." 
                matTooltipClass="custom-tooltip" 
                matTooltipHideDelay="0"
                matTooltipPosition="right"></i>
            </label>
            <input type="number" (wheel)="false" formControlName="ftd_target_day" min="0" class="form-control">
          </div>
          <div class="col-md-6 form-group">
            <label class="control-label">{{ 'Lead Validity (Days)' | translate }} <span class="text-danger">* </span>
              <i class="fas fa-exclamation-circle" 
                matTooltip="Number of days that determine the lead's initial expiry date when a lead is assigned to a telemarketer." 
                matTooltipClass="custom-tooltip" 
                matTooltipHideDelay="0"
                matTooltipPosition="right"></i>
            </label>
            <input type="number" (wheel)="false" formControlName="validity_day" min="0" class="form-control">
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 form-group">
            <label class="control-label">{{ 'Follow Up Extension (Days)' | translate }} <span class="text-danger">* </span>
              <i class="fas fa-exclamation-circle" 
                 matTooltip="Number of days extended on the lead's expiry date when a telemarketer follows up on their leads by making calls or updating remarks." 
                 matTooltipClass="custom-tooltip" 
                 matTooltipHideDelay="0"
                 matTooltipPosition="right"></i>
            </label>
            <input type="number" (wheel)="false" formControlName="follow_up_day" min="0" class="form-control">
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>{{ 'Close' | translate }}</button>
        <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
      </div>
    </div>
  </form>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event, true)"></kt-swal-alert>
