<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Remark History</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
            <div class="pt-0 pb-0">
                <div id="kt_table_users_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive padding">
                        <table
                            class="table align-middle fs-6 dataTable no-footer table-bordered table-hover table-striped">
                            <thead>
                                <tr class="fw-bolder fs-7 gs-0">
                                    <th class="text-center" tabindex="0" width="70">ID</th>
                                    <th class="" tabindex="0" width="400">Remark</th>
                                    <th class="text-center" tabindex="0">Created By</th>
                                    <th class="text-center" tabindex="0">Evidence</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(remark$ | async) as rows" class="text-gray-600 fw-bold">
                                <tr *ngFor="let row of rows; let i = index" [class]="i % 2 == 0 ? 'odd' : 'even'">
                                    <td class="text-center">{{ row.id }}</td>
                                    <td class="">{{ row.value }}</td>
                                    <td class="text-center">
                                        {{ row.created_by ? row.created_by : '-' }}<br/>
                                        {{ (row.created_at | timezoneDate:'YYYY-MM-DD HH:mm') }}
                                    </td>
                                    <td class="text-center">
                                         <!-- Show Evidence Button -->
                                         <button *ngIf="row.remark_asset.length > 0" matTooltip="Show Evidence" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog(row.remark_asset)" >
                                            <span class="text-collapsed"><i class="fas fa-images"></i></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="remarkLength" class="text-gray-600 fw-bold">
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <div class="col-12 p-0 card rounded-0 mb-4" *ngIf="canEditLeadJustification && this.data.leadJustification">
                <form class="kt-form w-webkit-fill-available" [formGroup]="form">
                    <div class="card-header">
                    Justification
                    </div>
                    <section class="col-12 p-4">
                        <div class="row border-left">
                            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-top border-right border-bottom p-3">
                                <span class="kt-font-bold">Remark <span class="text-danger">*</span></span>
                            </div>
                            <div class="input-group col-lg-9 col-md-9 col-sm-6 col-xs-12 align-items-center border-top border-right border-bottom p-3">
                                <input type="text" formControlName="remarks" class="form-control">
                            </div>
                        </div>
                        <div class="border-0 d-flex p-1 ml-n3 mr-n3 mt-2 justify-content-between">
                            <button class="btn btn-danger" [disabled]="!this.form.valid || buttonLoading" (click)="onSave(data.leadID, 'reject')"><i [class]="buttonLoading ? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fa fa-ban'"></i>Reject</button>
                            <button class="btn btn-success" [disabled]="!this.form.valid || buttonLoading" (click)="onSave(data.leadID, 'approve')"><i [class]="buttonLoading ? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Approve</button>    
                        </div> 
                    </section>
                </form>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog($event)"><i class="fas fa-ban"></i>Close</button>
        </div>
      </div>
  </div>