import { RebateHistoryComponent } from './rebate-history/rebate-history.component';
import { RebateReleasesComponent } from './rebate-releases/rebate-releases.component';
import { RebatesComponent } from './rebates/rebates.component';
import { Routes, RouterModule} from '@angular/router';
import { NgModule } from '@angular/core';
import { RebateEditDialogComponent } from './rebates/dialogs/rebate-edit.component';
import { RebateGenerateDialogComponent } from './rebate-releases/dialogs/rebate-generate.component';
import { NewRebateReleasesComponent } from './new-rebate-releases/new-rebate-releases.component';
import { NewRebateGenerateDialogComponent } from './new-rebate-releases/dialogs/rebate-generate/new-rebate-generate.component';
import { RebateUpdateSettingDialogComponent } from './new-rebate-releases/dialogs/rebate-update-setting/rebate-update-setting.component';
import { RebateDetailsDialogComponent } from './new-rebate-releases/dialogs/rebate-details/rebate-details.component';
import { RebateSettingDetailsDialogComponent } from './new-rebate-releases/dialogs/rebate-setting-details/rebate-setting-details.component';
import { PromoTurnoverDetailsDialogComponent } from './rebate-releases/dialogs/promo-turnover-details.component';
import { TargetAmountDetailsDialogComponent } from './rebate-releases/dialogs/target-amount-details.component';

const routes: Routes = [
  {
    path: 'releases',
    component: RebateReleasesComponent
  },
  {
    path: 'new-releases',
    component: NewRebateReleasesComponent
  },
  {
    path: 'member-history',
    component: RebateHistoryComponent
  },
  {
    path: 'settings',
    component: RebatesComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  entryComponents: [
    RebateEditDialogComponent,
    RebateGenerateDialogComponent,
    NewRebateGenerateDialogComponent,
    RebateUpdateSettingDialogComponent,
    RebateDetailsDialogComponent,
    RebateSettingDetailsDialogComponent,
    PromoTurnoverDetailsDialogComponent,
    TargetAmountDetailsDialogComponent
  ]
})

export class RebatesRoutingModule { }
