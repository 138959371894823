<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <!-- Filter -->
      <div class="kt-form kt-form--label-right">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <div class="col-12 row mb-2 pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Currency:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select class="form-control" formControlName="currency_id">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <ng-container *ngFor="let value of dropdown.currencies">
                      <option [value]="value.id">{{ value.name }}</option>
                    </ng-container>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Game Provider:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select class="form-control" formControlName="game_provider_code">
                    <option [value]="0" *ngIf="!form.get('daily').value"> All </option>
                    <ng-container *ngFor="let value of dropdown.gameProviders | async">
                      <option [value]="value.code">{{ value.code }} - {{ value.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="col-12 row mb-2 pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Date/Time:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Timezone' }}:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <kt-dropdown-wo-lazyload
                      #timezone
                      (click)="onChangeTimezone(timezone.selectedItems)"
                      [form] = 'form'
                      [dropdownList] = 'dropdown.timezones'
                      [dropdownSettings] = 'timezoneDropdownSettings'
                      [formName] = "'timezone'"
                      [selectionAttributes] = "'timezone'"
                      [selectedItems] = 'timezoneSelectedItems'>
                  </kt-dropdown-wo-lazyload>
                </div>
              </div>
              <div class="col-12 row mb-1">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Daily:</label>
                </div>
                <div class="col-md-2 kt-form__control dailycheckbox">
                  <label><input (click)="checkDaily()" type="checkbox" formControlName="daily"> </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Action Button -->
      <div class="kt-section col-12 row d-flex justify-content-between mb-3">
        <div>
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <button *ngIf="canExportGameProviderReport" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="button_loading"><i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
      </div>
      <div class="kt-section col-12 row d-flex mb-3">
        Last Bet Log Sync At <span class="font-weight-bold">{{ lastBetLogSyncAt ? lastBetLogSyncAt : '-'}}</span>
      </div>
      <!-- Content -->
      <div class="kt-section">
        <div class="kt-section__content">
          <!-- Not daily -->
          <ng-container *ngIf="!this.daily">
            <!-- Table -->
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-striped">
                <!-- Header -->
                <thead>
                  <tr>
                    <th class="text-center" rowspan="2">Currency</th>
                    <th class="text-center" rowspan="2">Game Provider</th>
                    <th class="text-center" rowspan="2">Total Player</th>
                    <th class="text-center" colspan="4">Transfer</th>
                    <th class="text-center" colspan="9">Bets</th>
                    <th class="text-center" colspan="3">Promotion</th>
                    <th class="text-center" rowspan="2">Total W/L</th>
                  </tr>
                  <tr>
                    <th class="text-center">Total In<br>Count</th>
                    <th class="text-center">Total In<br>Amount</th>
                    <th class="text-center">Total Out<br>Count</th>
                    <th class="text-center">Total Out<br>Amount</th>
                    <th class="text-center">Total Bet<br>Count</th>
                    <th class="text-center">Total Bet<br>Amount</th>
                    <th class="text-center">Total Valid<br>Bet Amount</th>
                    <th class="text-center">Player W/L</th>
                    <th class="text-center">Total <br />Jackpot Contribution</th>
                    <th class="text-center">Total <br />Jackpot Win</th>
                    <th class="text-center">Nett <br />Jackpot</th>
                    <th class="text-center">Game Bet <br />Amount</th>
                    <th class="text-center">Gross Game Revenue <br />(GGR)</th>
                    <th class="text-center">Total Bonus</th>
                    <th class="text-center">Total Cancelled Bonus</th>
                    <th class="text-center">Total Rebate</th>
                  </tr>
                </thead>
                <!-- Body -->
                <tbody *ngIf="(gameProviderReport$ | async) as rows">
                  <tr *ngFor="let row of rows">
                    <td class="text-center">{{ row.currency_code }}</td>
                    <td class="text-center"><a class="text-primary" (click)="onOpenDialog(row)">{{ row.game_provider }} </a></td>
                    <td class="text-right">{{ row.total_player | number : '1.0-0'}}</td>
                    <td class="text-right">{{ row.total_number_of_transfer_in | number : '1.0-0' }}</td>
                    <td class="text-right">{{ row.total_transfer_in_amount | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_number_of_transfer_out | number : '1.0-0' }}</td>
                    <td class="text-right">{{ row.total_transfer_out_amount | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_bet_count | number : '1.0-0' }}</td>
                    <td class="text-right">{{ row.total_bet_amount | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_valid_bet_amount | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.win_lose | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_jackpot_contribution | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_jackpot_win | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.nett_jackpot | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.game_bet_amount | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.gross_game_revenue | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_bonus | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_cancelled_bonus | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_rebate | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_win_lose | number : '1.2-2'}}</td>
                  </tr>
                </tbody>
                <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
              </table>
            </div>
            <!-- Pagination -->
            <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
              <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
              </ngb-pagination>
              <div class="kt-pagination__toolbar">
                <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                  <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                </select>
                <span class="pagination__desc">
                  Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                </span>
              </div>
            </div>
          </ng-container>

          <!-- Daily -->
          <ng-container *ngIf="this.daily">
            <!-- Table -->
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-striped">
                <!-- Header -->
                <thead>
                  <tr>
                    <th class="text-center" rowspan="2">Currency</th>
                    <th class="date-column" class="left-center" rowspan="2">Date</th>
                    <th class="text-center" rowspan="2">Game Provider</th>
                    <th class="text-center" rowspan="2">Total Player</th>
                    <th class="text-center" colspan="4">Transfer</th>
                    <th class="text-center" colspan="9">Bets</th>
                    <th class="text-center" colspan="3">Promotion</th>
                    <th class="text-center" rowspan="2">Total W/L</th>
                  </tr>
                  <tr>
                    <th class="text-center">Total In<br>Count</th>
                    <th class="text-center">Total In<br>Amount</th>
                    <th class="text-center">Total Out<br>Count</th>
                    <th class="text-center">Total Out<br>Amount</th>
                    <th class="text-center">Total Bet<br>Count</th>
                    <th class="text-center">Total Bet<br>Amount</th>
                    <th class="text-center">Total Valid<br>Bet Amount</th>
                    <th class="text-center">Player W/L</th>
                    <th class="text-center">Total <br />Jackpot Contribution</th>
                    <th class="text-center">Total <br />Jackpot Win</th>
                    <th class="text-center">Nett <br />Jackpot</th>
                    <th class="text-center">Game Bet <br />Amount</th>
                    <th class="text-center">Gross Game Revenue <br />(GGR)</th>
                    <th class="text-center">Total Bonus</th>
                    <th class="text-center">Total Cancelled Bonus</th>
                    <th class="text-center">Total Rebate</th>
                  </tr>
                </thead>
                <!-- Body -->
                <tbody *ngIf="(gameProviderDailyReport$ | async) as rows">
                  <tr *ngFor="let row of rows">
                    <td class="text-center">{{ row.currency_code }}</td>
                    <td class="left-center text-nowrap">{{ row.summary_date | timezoneDate: 'YYYY-MM-DD' }}</td>
                    <td class="text-center"><a class="text-primary" (click)="onOpenDialog(row)">{{ row.game_provider }} </a></td>
                    <td class="text-right">{{ row.total_player | number : '1.0-0'}}</td>
                    <td class="text-right">{{ row.total_number_of_transfer_in | number : '1.0-0' }}</td>
                    <td class="text-right">{{ row.total_transfer_in_amount | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_number_of_transfer_out | number : '1.0-0' }}</td>
                    <td class="text-right">{{ row.total_transfer_out_amount | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_bet_count | number : '1.0-0' }}</td>
                    <td class="text-right">{{ row.total_bet_amount | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_valid_bet_amount | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.win_lose | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_jackpot_contribution | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_jackpot_win | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.nett_jackpot | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.game_bet_amount | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.gross_game_revenue | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_bonus | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_cancelled_bonus | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_rebate | number : '1.2-2'}}</td>
                    <td class="text-right">{{ row.total_win_lose | number : '1.2-2'}}</td>
                  </tr>
                </tbody>
                <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
              </table>
            </div>
            <!-- Pagination -->
            <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
              <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
              </ngb-pagination>
              <div class="kt-pagination__toolbar">
                <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                  <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                </select>
                <span class="pagination__desc">
                  Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                </span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
<kt-swal-alert [message]="messages2$ | async"></kt-swal-alert>
