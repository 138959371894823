
import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';
import Echo from 'laravel-echo';
import { WithdrawDataService } from '../services/withdraw-data.service';

@Component({
  selector: 'withdrawal-wrapper',
  templateUrl: './withdrawal-wrapper.component.html',
  styleUrls: ['./withdrawal-wrapper.component.scss'],
})
export class WithdrawalWrapperComponent implements OnInit {
  cryptoCount = 0;
  bankingCount = 0;
  echo: Echo;

  // permissions
  canViewBankingWithdrawals: boolean;
  canViewCryptoWithdrawals: boolean;

  private subscription: Subscription[] = [];

  constructor(
    private eventEmitter: EventEmitterService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
    private withdrawDataService: WithdrawDataService,
  ) {
    const SocketIoClient = require('socket.io-client');
    const socketHost: string = environment.socketHost;
    const user_token = JSON.parse(localStorage.getItem('user_token'));
    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        client: SocketIoClient,
        host: socketHost,
        auth: {
          headers: {
              'access-token': user_token.access_token,
              'token-selector': user_token.plaintext_token,
              'X-User-Model': 'bo'
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  ngOnInit() {

    // Permission
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewBankingWithdrawals = appPermissions.view_banking_withdrawals;
      this.canViewCryptoWithdrawals = appPermissions.view_crypto_withdrawals;

      this.cdr.detectChanges();
    });
    this.subscription.push(apSub);

    // Withdrawal count event listener
    const listener = (res) => {
      this.bankingCount = res.banking;
      this.cryptoCount = res.crypto;
      this.cdr.detectChanges();
    };
    this.echo.channel('withdraw-count-channel').listen('.WithdrawCountEvent', listener);

    this.withdrawDataService.triggerWithdrawCount().subscribe();
  }

  ngOnDestroy() {
    this.subscription.map(sub => sub.unsubscribe);
  }
}
