import { Component, OnInit, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { MemberTrace } from '@core/models/member-trace.model';
import { Pagination } from '@core/models/pagination.model';
import { Dropdown } from '@core/models/dropdown.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { interval, Observable, of, Subscription, throwError } from 'rxjs';
import { exhaustMap, map, tap, catchError } from 'rxjs/operators';
import { AuthHttpService } from './../../../../../core/services/auth-http.service';
import { MemberTraceDataService } from './services/member-trace-data.service';
import { MemberTraceEntityService } from './services/member-trace-entity.service';
import { DropdownTreeviewComponent , TreeviewConfig, TreeviewItem, TreeviewModule } from 'ngx-treeview';
import { MemberDataService } from './../../general/members/services/member-data.service';
import { MemberInformationDialogComponent } from './../../general/members//dialogs/member-information/member-information.component';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { MatDialog } from '@angular/material/dialog';
import { Status } from '@core/enums/status.enum';
import { LoginStatus } from '@core/enums/login-status.enum';
import { environment } from '@env/environment';
import { MemberLabelComponent } from '@views/pages/apps/general/members/dialogs/member-label/member-label.component';
import { MemberRemarksListComponent } from '@shared/member-remarks-list/member-remarks-list.component';

@Component({
  selector: 'kt-member-trace',
  templateUrl: './member-trace.component.html',
  styleUrls: ['./member-trace.component.scss']
})
export class MemberTraceComponent implements OnInit {
  @ViewChild(DropdownTreeviewComponent, { static: false }) dropdownTreeviewComponent: DropdownTreeviewComponent;
  messages$ = this.memberTraceDataService.messages$;
  form: FormGroup;
  pagination: Pagination;
  pageSize = 50;
  page = 1;
  maxSize = 5;
  rerunLength = 10;
  rerunAttempt = false;
  params = '';
  dropdown = {
    perPage: this.dropdownHttpService.perPage,
    groups: this.dropdownHttpService.groups,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: this.dropdownHttpService.memberStatuses,
  };
  dropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'username',
    labelKey: 'username',
    lazyLoading: true,
    noDataLabel: ''
  };
  memberTrace$: Observable<MemberTrace[]>;

  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '1.6';
  sortingConfig = {
    'member_id': 'desc',
    'username': 'asc',
    'name': 'desc',
    'currency': 'desc',
    'member_group': 'desc',
    'password': 'desc',
    'email': 'desc',
    'status': 'desc',
    'registration_created_at': 'desc',
    'last_login_updated_at': 'desc'
  };
  sortingSelection = {
    'sort_by': 'username',
    'sort_order': 'asc',
  };
  selfSortColumns = ['member_id', 'currency', 'member_group', 'status'];

  loading = false;
  loadmore = false;
  pagedata = [];
  clearBtnLoading = false;
  searchBtnLoading = false;
  exportBtnLoading = false;
  dataLength: number;
  interactiveInput: any = {
    id: true,
    username: true,
    name: true,
    password: true,
    email: true,
    last_ip_address: true,
    fingerprint_id: true,
    risk_type: true,
  };
  memberGroup: Dropdown[];
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 400
  });
  riskTypeItems = [
    new TreeviewItem({text: 'Same Name', value: 'name', checked: false}),
    new TreeviewItem({text: 'Same Password', value: 'password', checked: false}),
    new TreeviewItem({text: 'Same Email', value: 'email', checked: false}),
    new TreeviewItem({text: 'Same IP Address', value: 'ip', checked: false}),
    new TreeviewItem({text: 'Same Fingerprint', value: 'fingerprint', checked: false})
  ];
  enableRiskDropdown: boolean = true;
  selectedItem: any;
  status = Status;
  loginstatus = LoginStatus;
  sqsEnable: boolean = environment.sqsEnable;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  private subscription = new Subscription();

  constructor(
    private dropdownHttpService: DropdownHttpService,
    public loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private memberTraceDataService: MemberTraceDataService,
    private memberTraceEntityService: MemberTraceEntityService,
    private authHttpService: AuthHttpService,
    private memberDataService: MemberDataService,
    private transactionHttpService: TransactionHttpService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));
    this.formInit();
    this.memberGroupsInit();
  }

  private memberGroupsInit() {
    this.dropdown.groups.pipe(
      tap(groups => this.memberGroup = groups)
    ).subscribe();
  }

  setInteractiveInput(value: boolean, targetFields?: any) {
    if( targetFields !== undefined && targetFields.length > 0 ) {
      targetFields.forEach(item => {
        this.interactiveInput[item] = value;
        if( item == 'risk_type' ) {
          this.enableRiskDropdown = value;
        }
      });
    } else {
      for (const key in this.interactiveInput) {
        this.interactiveInput[key] = value;
      }
      this.enableRiskDropdown = value;
    }
  }

  onInteractiveSearchFilter(event: any) {
    let inputName = event.target.getAttribute('formControlName');
    switch( inputName ) {
      case 'id':
        if( this.form.value.id == '' ) {
          if( this.form.value.risk_type_attr.length == 0 ) {
            this.setInteractiveInput(true);
          } else {
            this.setInteractiveInput(true, ['username']);
          }
        } else {
          this.setInteractiveInput(false, ['username', 'name', 'password', 'email', 'last_ip_address', 'fingerprint_id']);
        }
        break;
      case 'username':
        if( this.form.value.username == '' ) {
          if( this.form.value.risk_type_attr.length == 0 ) {
            this.setInteractiveInput(true);
          } else {
            this.setInteractiveInput(true, ['id']);
          }
        } else {
          this.setInteractiveInput(false, ['id', 'name', 'password', 'email', 'last_ip_address', 'fingerprint_id']);
        }
        break;
      case 'name':
      case 'password':
      case 'email':
      case 'last_ip_address':
      case 'fingerprint_id':
        if( this.form.value.name == '' && this.form.value.password == '' && this.form.value.email == '' && this.form.value.last_ip_address == '' && this.form.value.fingerprint_id == '' ) {
          this.setInteractiveInput(true);
        } else {
          this.setInteractiveInput(false, ['id', 'username', 'risk_type']);
        }
        break;
    }
  }

  sanitizeId(event: any) {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, '');
    event.target.value = inputValue;
    this.form.value.id = inputValue;
  }

  onTreeviewChange(event: Event) {
    this.form.patchValue({
      risk_type_attr: event
    });
    if( this.form.value.risk_type_attr.length == 0 ) {
      if( this.form.value.id != '' ) {
        this.setInteractiveInput(false, ['username']);
      } else if( this.form.value.username != '' ) {
        this.setInteractiveInput(false, ['id']);
      } else {
        this.setInteractiveInput(true);
      }
    } else {
      this.setInteractiveInput(false, ['name', 'password', 'email', 'last_ip_address', 'fingerprint_id']);
    }
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }

    // Load Data
    this.onSubmit(false);
  }

  isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

  onClear() {
    this.eventEmitterService.onClearMemberSearch();
    this.setInteractiveInput(true);
    this.formInit();
    this.onSubmit(false, true);
    this.onClearRiskTypeFilter();
  }

  onClearRiskTypeFilter() {
    this.riskTypeItems.forEach(item => {
      item.checked = false;
    });
    this.dropdownTreeviewComponent.selectedChange.emit([]);
    this.dropdownTreeviewComponent.buttonLabel = 'Select options';
  }

  onSubmit(scrollload?: boolean, clearSearch?: boolean) {
    if( clearSearch ) {
      this.clearBtnLoading = true;
    } else {
      this.searchBtnLoading = true;
    }
    this.loading = true;
    this.page++;

    if (!scrollload) {
      this.page = 1;
      this.params = this.getSearchParams();
      this.loadmore = true;
      this.pagedata = [];
      this.rerunAttempt = true;
    }

    let queryparam = `?page=${this.page}&perPage=${this.pageSize}&${this.params}`;
    this.memberTraceDataService.getWithQuery(queryparam)
      .pipe(
        catchError((error) => {
          this.loading = false;
          this.loadmore = false;
          this.searchBtnLoading = false;
          this.clearBtnLoading = false;
          this.cdr.detectChanges();

          // Throw the error as usual
          throw error;
        })
      )
      .subscribe((res: any) => {
        this.loading = false;
        this.searchBtnLoading = false;
        this.clearBtnLoading = false;
        if( res && res.success == true ) {
          if( res.data.rows && res.data.rows.length > 0 ) {
            this.pagedata = [...this.pagedata, ...res.data.rows];
          }
          if( res.data.paginations && res.data.paginations[0] && this.page >= res.data.paginations[0].last_page ) {
            this.loadmore = false;
          }
          if( this.loadmore && ((this.rerunAttempt && this.pagedata.length < this.rerunLength) || (res.data.rows.length <= 0)) ) {
            this.onSubmit(true);
          }
        } else {
          this.loadmore = false;
        }
        this.sortData();
        this.cdr.detectChanges();
      });
  }

  private getSearchParams(isExport?: boolean): string {
    const formValues = {
      ...this.form.value,
      ...this.sortingSelection
    };

    const filteredFormValues = this.filterFormFields(formValues);
    const searchParams = new URLSearchParams(filteredFormValues);

    return searchParams.toString();
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key] === true ? 1 : formData[key] === false ? 0 : formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(''),
      currency_id: new FormControl('all'),
      group_id: new FormControl('all'),
      status: new FormControl('all'),
      username: new FormControl(''),
      name: new FormControl(''),
      password: new FormControl(''),
      email: new FormControl(''),
      last_ip_address: new FormControl(''),
      fingerprint_id: new FormControl(''),
      risk_type: new FormControl('And'),
      risk_type_attr: new FormControl([]),
    });
  }

  onOpenDialog(type: string, memberId?: number) {
    const member = this.memberDataService.getById(memberId, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
    this.subscription = member.pipe(
      tap((res) => {
        if (res) {
          this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' });
        }
      })
    ).subscribe();
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any, isAdmin?: boolean }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'member-information' ? '1500px' : '800px',
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      data,
      autoFocus: data.mode === 'member-information' ? false : true
    });
    if (data.mode !== 'member-information') {
      dialogRef.afterClosed().subscribe((result) => {
        // if (result === true) {
        //   this.onViewPageBy(this.page).subscribe();
        // }
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    const documentHeight = Math.max(
      document.body.scrollHeight || 0,
      document.body.offsetHeight || 0,
      document.documentElement.scrollHeight || 0,
      document.documentElement.offsetHeight || 0
    );
    const scrollbottom = (window.innerHeight + window.scrollY) * 1.1 >= documentHeight;

    if (this.loadmore && !this.loading && scrollbottom) {
      this.onSubmit(true);
    }
  }

  sortData() {
    let order_type = this.sortingSelection.sort_order;
    let column = this.sortingSelection.sort_by;
    if( !this.selfSortColumns.includes(column) ) {
      return;
    }

    // Use the Array.prototype.sort() method to sort the data array
    this.pagedata = this.pagedata.sort((a, b) => {
      let comparison = 0;

      // Compare based on the specified column
      if (a[column] > b[column]) {
        comparison = 1;
      } else if (a[column] < b[column]) {
        comparison = -1;
      }

      // Adjust the comparison based on the order type
      if (order_type === 'desc') {
        comparison *= -1; // Reverse the comparison for descending order
      }

      return comparison;
    });

  }

  onViewMoreRemarks(row: any, sqsEnable?: boolean) {
    if (sqsEnable) {
      row.id = row.member_id;
      this.dialog.open(MemberLabelComponent, {
        width: '1000px',
        data: {
          member: row
        }
      });
    } else {
      this.memberDataService.getRemarksHistory(row.member_id).subscribe(res => {
        this.dialog.open(MemberRemarksListComponent, {
          width: '800px',
          data: {
            remarks: res
          }
        });
      });
    }
  }

  onExport() {
    this.loading = true;
    this.exportBtnLoading = true;
    this.params = this.getSearchParams();
    let queryparam = `?${this.params}`;
    this.memberTraceDataService.export(queryparam)
      .pipe(
        catchError((error) => {
          this.loading = false;
          this.exportBtnLoading = false;
          this.cdr.detectChanges();

          // Throw the error as usual
          throw error;
        })
      )
      .subscribe((res: any) => {
        this.loading = false;
        this.exportBtnLoading = false;
        console.log(res);
        this.cdr.detectChanges();
      });
  }
      
}
