<div class="table-responsive mb-2">
  <table class="table table-bordered table-hover table-striped">
    <thead>
        <tr>
            <th>ID</th>
            <th class="date-column">Apply Date</th>
            <th>Game Provider</th>
            <th>Promo Code</th>
            <th class="text-center pr-4">Status</th>
            <th class="date-column">Start Date Time</th>
            <th class="date-column">End Date Time</th>
            <th class="text-center">Amount</th>
            <th class="text-center">Target Amount</th>
            <th class="text-center">Current W/L</th>
            <th class="text-center">Rebate Amount</th>
            <th class="text-center pl-3 pr-3">Transfer Status</th>
            <th>Remarks</th>
            <th>Updated by</th>
            <th>Actions</th>
        </tr>
    </thead>
    <tbody *ngIf="(memberPromotions$ | async) as rows">
      <tr *ngFor="let row of rows">
          <td>{{ row.id }}</td>
          <td>{{ isValidDate(row.created_at) ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</td>
          <td>{{ row.game_provider_code }}</td>
          <td>{{ row.promo_code }}</td>
          <td class="text-center">
            <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ promotionTransferStatus[row.status] }}</span>
          </td>
          <td>{{ isValidDate(row.start_datetime) ? (row.start_datetime | timezoneDate: 'YYYY-MM-DD HH:mm') : '' }}</td>
          <td>{{ isValidDate(row.end_datetime) ? (row.end_datetime | timezoneDate: 'YYYY-MM-DD HH:mm') : '' }}</td>
          <td class="text-right">{{ row.code }} {{ row.amount | number : '1.2-2' }}<br/>
              <span class="tranfer-green-text">
                  {{ row.bonus_amount < 0 ? '-' : '+'}}
                  {{ row.code }} {{ row.bonus_amount | number : '1.2-2' }}
              </span>
          </td>
          <td class="text-right">
            {{ row.accumulated_target_amount }} / {{ row.target_amount | number : '1.2-2' }}
            <br/>({{ row.target_type | titlecase }})
          </td>
          <td class="text-right">{{ row.currency_code + ' ' + row.accumulated_win_loss  }}</td>
          <td class="text-right">0.00</td>
          <td class="text-center">
            <i class="fas fa-sign-in-alt m-1" matTooltip="Limit Transfer In - On/Off" [ngClass]="row.limit_transfer_in > 0 ? 'tranfer-status-green' : 'tranfer-status-red'"></i>
            <i class="fas fa-sign-out-alt m-1" matTooltip="Limit Transfer Out - On/Off" [ngClass]="row.limit_transfer_out > 0 ? 'tranfer-status-green' : 'tranfer-status-red'"></i>
            <i class="fas fa-lock m-1" matTooltip="Auto Unlock - On/Off" [ngClass]="row.auto_unlock > 0 ? 'tranfer-status-green' : 'tranfer-status-red'"></i>
          </td>
          <td>{{ row.remarks }}</td>
          <td>
            {{ row.updated_by ? row.updated_by : 'System'}}
            <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
            <ng-template #updatedDateTime>
              {{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss'}}
            </ng-template>
          </td>
          <td class="text-center">
            <button *ngIf="canViewPromotionHistoryLog" matTooltip="History" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onShowHistory(row.id)"><i class="fas fa-history"></i></button>
          </td>
        </tr>
        <kt-fallback-row [collection]="rows"></kt-fallback-row>
    </tbody>
  </table>
</div>
<div class="kt-pagination kt-pagination--brand mt-2 mb-4" *ngIf="pagination !== undefined">
  <ngb-pagination
    [pageSize]="pageSize"
    [(page)]="page"
    [maxSize]="maxSize"
    [directionLinks]="true"
    [boundaryLinks]="true"
    [rotate]="true"
    [collectionSize]="pagination.total"
    (pageChange)="onViewPageBy(page)">
  </ngb-pagination>
  <div class="kt-pagination__toolbar">
    <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
        <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
    </select>
    <span class="pagination__desc">
      Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
    </span>
  </div>
</div>
