import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Promotion } from '@core/models/promotion.model';
import { RecoveryMessage } from '@core/models/recovery-message.model';

@Injectable()
export class RecoveryMessageEntityService extends EntityCollectionServiceBase<RecoveryMessage> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
      super('RecoveryPromotionMessages', serviceElementsFactory);
  }
}
