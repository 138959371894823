import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AllLeads } from '@core/models/all-leads.model';
import { LeadRemark } from '@core/models/lead-remark.model';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';
import { Dropdown } from '@core/models/dropdown.model';
import { LeadStatistic } from '@core/models/lead-statistic.model';

@Injectable()
export class AllLeadsDataService extends DefaultDataService<AllLeads> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  data$ = new Subject<any[]>();

  totalAssignedLeads = 0;
  totalUnassignedLeads = 0;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('AllLeads', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<AllLeads[]> {
    return this.http.get<ApiResponse>(`/telemarketer${pageParam}`).pipe(
      tap(res => {
        this.paginationInit(res);
        if (res.data.summary !== undefined) {
          this.totalAssignedLeads = res.data.summary.No_of_assigned_leads;
          this.totalUnassignedLeads = res.data.summary.No_of_unassigned_fresh_leads;
        }
      }),
      map(res => {
        Object.keys(res.data.rows).forEach(key => {
          res.data.rows[key].custom = res.data.rows[key].id + ' - ';
          if (res.data.rows[key].username != null) {
            res.data.rows[key].custom += res.data.rows[key].username.toUpperCase();
          }
          res.data.rows[key].custom += + ' - ';
          if (res.data.rows[key].name != null) {
            res.data.rows[key].custom += res.data.rows[key].name.toUpperCase();
          }
        });
        return res.data.rows;
      })
    );
  }

  export(data) {
    return this.http.post<ApiResponse>(`/telemarketer/assignlead`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  exportReport(pageParam: string) {
    return this.http.get<ApiResponse>(`/telemarketer/exportalllead${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  updateStatus(id: number, status: number) {
    return this.http.put<ApiResponse>(`/member/statusupdate?id=${id}&status=${status}`, {}).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  import(data: FormData) {
    return this.http.post<ApiResponse>(`/telemarketer/importcsv`, data).pipe(
      tap(res => {
        res.data['custom_leads_import_swal'] = true;
        this.messages$.next(res.message);

        // For failed records
        this.data$.next(res.data);
      })
    );
  }

  importValidate(data: FormData) {
    return this.http.post<ApiResponse>(`/telemarketer/importcsvvalidate`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getTelemarketerList(params: string): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/telemarketerlist${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  getLeadStatistic(params: string): Observable<LeadStatistic> {
    return this.http.get<ApiResponse>(`/telemarketer/leadstatistic${params}`).pipe(
      map(res => res.data.rows)
      );
    }

  getLeadSetting(): Observable<any> {
    return this.http.get<ApiResponse>(`/lead-setting/get`).pipe(
      map(res => res.data)
    );
  }

  createLeadSetting(data: FormData) {
    return this.http.post<ApiResponse>(`/lead-setting/create`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getAffiliateLeadSetting(): Observable<any> {
    return this.http.get<ApiResponse>(`/affiliate-lead-setting`).pipe(
      map(res => res.data.rows)
    );
  }

  createAffiliateLeadSetting(data: FormData) {
    return this.http.post<ApiResponse>(`/affiliate-lead-setting/create`, data).pipe(
      tap(res => {
        res.data['custom_affiliate_swal'] = true;
        this.messages$.next(res.message);

        // For failed records
        this.data$.next(res.data);
      })
    );
  }

  clearAffiliateLeadSetting(id, data = []) {
    return this.http.post<ApiResponse>(`/affiliate-lead-setting/remove/${id}`, data).pipe(
      tap(res => {
        res.data = res.data == null ? res.data = {'custom_affiliate_swal': true} : null;
        this.messages$.next(res.message);

        // For custom alert swal
        this.data$.next(res.data);
      })
    );
  }

  clearAllAffiliateLeadSetting(data) {
    return this.http.post<ApiResponse>(`/affiliate-lead-setting/remove-all`, data).pipe(
      tap(res => {
        res.data = res.data == null ? res.data = {'custom_affiliate_swal': true} : null;
        this.messages$.next(res.message);

        // For custom alert swal
        this.data$.next(res.data);
      })
    );
  }

  updateRemark(data, lead_id) {
    return this.http.put<ApiResponse>(`/telemarketer/remarks/${lead_id}`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  updateJustificationRemarks(data, lead_id) {
    return this.http.put<ApiResponse>(`/telemarketer/justification-remarks/${lead_id}`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getRemarkById(id: number): Observable<{rows: LeadRemark[]}> {
    return this.http.get<ApiResponse>(`/telemarketer/leadremarks/${id}?paginate=false`).pipe(
      tap(res => this.paginationInit(res.data.paginations)),
      map(res => res.data)
    )
  }

  updateLeadJustificationStatus(id: number, data) {
    return this.http.put<ApiResponse>(`/telemarketer/statusupdate/${id}`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getTelemarketerUnusedLeads(telemarketer_id: number) {
    return this.http.get<ApiResponse>(`/telemarketer/telemarketerunuseleads?telemarketer_id=${telemarketer_id}`).pipe(
      map(res => res.data)
    )
  }

  reassignLead(data) {
    return this.http.post<ApiResponse>(`/telemarketer/reassignleads`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  undoLead(data) {
    return this.http.post<ApiResponse>(`/telemarketer/undoleads`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

  resetData() {
    this.data$.next(null);
  }
}
