<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="currency_id">
                                        <option value="all" selected>All</option>
                                        <ng-container *ngFor="let value of dropdown.currencies">
                                            <option [value]="value.id">{{ value.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)"
                                        formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true"
                                        [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                        [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Timezone' }}:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                        #timezone
                                        (click)="onChangeTimezone(timezone.selectedItems)"
                                        [form] = 'form'
                                        [dropdownList] = 'dropdown.timezones'
                                        [dropdownSettings] = 'timezoneDropdownSettings'
                                        [formName] = "'timezone'"
                                        [selectionAttributes] = "'timezone'"
                                        [selectedItems] = 'timezoneSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                            </div>
                            <div class="col-12 row mb-1">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Daily:</label>
                                </div>
                                <div class="col-md-2 kt-form__control dailycheckbox">
                                    <label><input (change)="setDaily()" type="checkbox" formControlName="daily"> </label>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Action Button -->
            <div class="kt-section col-12 row mb-3 mt-2 d-flex justify-content-between">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canExportCompanyReport" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="button_loading">
                    <i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export
                </button>
            </div>
            <div class="kt-section col-12 row d-flex mb-3">
                Last Bet Log Sync At <span class="font-weight-bold">{{ lastBetLogSyncAt ? lastBetLogSyncAt : '-'}}</span>
            </div>
            <!-- Table -->
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped" >
                        <thead>
                            <tr>
                                <th class="text-center" rowspan="2">Currency</th>
                                <th class="date-column text-center" rowspan="2" *ngIf="this.form.get('daily').value === 1">Date</th>
                                <th class="text-center" rowspan="2">Total Registers</th>
                                <th class="text-center" colspan="5">Deposit</th>
                                <th class="text-center" colspan="2">Withdrawal</th>
                                <!-- <th class="text-center" colspan="2">Adjustments</th> -->
                                <th class="text-center" colspan="10">Bets</th>
                                <th class="text-center" colspan="3">Promotion</th>
                                <th class="text-center" colspan="2">Company Processing Fee</th>
                                <th class="text-center" rowspan="2">Company W/L</th>
                            </tr>
                            <tr>
                            <th class="text-center">Unique Depositor</th>
                            <th class="text-center">FTD Count</th>
                            <th class="text-center">FTD Amount</th>
                            <th class="text-center">Total Deposit<br/>Count</th>
                            <th class="text-center">Total Deposit<br/>Amount</th>
                            <th class="text-center">Total Withdrawal<br/>Count</th>
                            <th class="text-center">Total Withdrawal<br/>Amount</th>

                            <!-- <th class="text-center">#Adj</th>
                            <th class="text-center">Total</th> -->
                            <th class="text-center">Unique Player</th>
                            <th class="text-center">Total Bet Count</th>
                            <th class="text-center">Total Bet Amount</th>
                            <th class="text-center">Total Valid Bet Amount</th>
                            <th class="text-center">Player W/L</th>
                            <th class="text-center">Total <br />Jackpot Contribution</th>
                            <th class="text-center">Total <br />Jackpot Win</th>
                            <th class="text-center">Nett <br />Jackpot</th>
                            <th class="text-center">Game Bet <br />Amount</th>
                            <th class="text-center">Gross Game Revenue <br />(GGR)</th>
                            <th class="text-center">Total Bonus</th>
                            <th class="text-center">Total Cancelled Bonus</th>
                            <th class="text-center">Total Rebate</th>
                            <th class="text-center">Total Deposit Processing Fee</th>
                            <th class="text-center">Total Withdrawal Processing Fee</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="(companyReport$ | async) as rows">
                            <tr *ngFor="let row of rows">
                                <td class="text-center">{{ row.currency_code }}</td>
                                <td class="text-center" *ngIf="this.form.get('daily').value === 1"> {{ timezoneDateWithTimezone(row.date, this.form.value.timezone, 'YYYY-MM-DD') }} </td>
                                <td class="text-right">{{ row.total_registers | number : '1.0-0'}}</td>
                                <td class="text-right">{{ row.unique_depositor | number : '1.0-0'}}</td>
                                <td class="text-right">{{ row.total_first_deposit | number : '1.0-0'}}</td>
                                <td class="text-right">{{ row.total_first_deposit_amount | number : '1.2-2'}}</td>
                                <td class="text-right">{{ row.deposit_total | number : '1.0-0'}}</td>
                                <td class="text-right">{{ row.deposit_total_amount | number : '1.2-2'}}</td>
                                <td class="text-right">{{ row.withdraw_total | number : '1.0-0'}}</td>
                                <td class="text-right">{{ row.withdraw_total_amount | number : '1.2-2'}}</td>
                                <!-- <td class="text-center">{{ row.adj }}</td>
                                <td class="text-right">{{ row.total_adj }}</td> -->
                                <td class="text-right">{{ row.unique_player | number : '1.0-0'}}</td>
                                <td class="text-right">{{ row.total_bet_count | number : '1.0-0'}}</td>
                                <td class="text-right">{{ row.total_bet_amount | number : '1.2-2'}}</td>
                                <td class="text-right">{{ row.total_valid_bet_amount | number : '1.2-2'}}</td>
                                <td class="text-right">{{ row.total_win_lose | number : '1.2-2'}}</td>
                                <td class="text-right">{{ row.total_jackpot_contribution | number : '1.2-2'}}</td>
                                <td class="text-right">{{ row.total_jackpot_win | number : '1.2-2'}}</td>
                                <td class="text-right">{{ row.nett_jackpot | number : '1.2-2'}}</td>
                                <td class="text-right">{{ row.game_bet_amount | number : '1.2-2'}}</td>
                                <td class="text-right">{{ row.gross_game_revenue | number : '1.2-2'}}</td>
                                <td class="text-right">{{ row.total_bonus | number : '1.2-2'}}</td>
                                <td class="text-right">{{ row.total_cancelled_bonus | number : '1.2-2'}}</td>
                                <td class="text-right">{{ row.total_rebate_amount | number : '1.2-2'}}</td>
                                <td class="text-right">{{ row.total_company_deposit_processing_fee | number : '1.2-2'}}</td>
                                <td class="text-right">{{ row.total_company_withdrawal_processing_fee | number : '1.2-2'}}</td>
                                <td class="text-right">{{ row.company_win_loss | number : '1.2-2'}}</td>
                                
                            </tr>
                        </tbody>
                        <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="pagination.total"
                        (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                        </select>
                        <span class="pagination__desc">
                            Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
