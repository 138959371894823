<div class="kt-form w-webkit-fill-available" *ngIf="dropdown.locales.length > 0">
  <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body">
        <form [formGroup]="form">
          <div class="row align-items-center">
            <div class="col-xl-12 order-2 order-xl-1">
              <div class="row mb-3">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Platform' | translate }}:</label>
                </div>
                <div class="col-md-11 kt-form__control dropdown dropdown-inline platform-container">
                    <button class="platform-select"
                      [ngClass]="selectedPlatform == 1 ? 'platform-selected' : ''" type="submit"
                      (click)="onSelectPlatform(1)">{{ 'Desktop' | translate }}</button>
                    <button class="platform-select"
                      [ngClass]="selectedPlatform == 2 ? 'platform-selected' : ''" type="submit"
                      (click)="onSelectPlatform(2)">{{ 'Mobile' | translate }}</button>
                </div>
              </div>
            </div>
            <div class="col-xl-12 order-2 order-xl-1">
              <div class="row mb-3">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Status' | translate }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="status" class="form-control">
                    <option value="all"> {{'All' | translate }} </option>
                    <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">
                      {{ value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Locale' | translate }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="settings_locale_id" class="form-control">
                    <option value="all"> {{'All' | translate }} </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.locales">
                      {{ value.code }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Name' }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="name" placeholder="{{ 'Search' | translate }}"
                    class="form-control">
                </div>
              </div>
            </div>

          </div>
        </form>
        <div class="kt-section col-12 row d-flex justify-content-between mb-0 mt-1">
          <div class="dropdown dropdown-inline">
            <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading"
              (search)="onSubmitClick()"></kt-search-button>
            <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading"
              (clear)="onSubmitClick(true)"></kt-clear-button>
          </div>
        </div>
      </div>
    </div>
    <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body pt-12 pb-0">
          <div class="kt-section">
            <div class="kt-section__content">
              <div class="table-responsive">
                <table class="table table-bordered table-hover table-striped table-sortable">
                  <thead>
                    <tr>
                      <th width="5" class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id"
                        [class.sort-selected]="sortingSelection.sort_by === 'id'">{{ 'Id' | translate | uppercase }}
                      </th>
                      <th class="pl-1" style="min-width: 200px;">
                        {{ 'Locale' | translate | uppercase }}
                        <div class="dropdown selected-category-container ml-1" *ngIf="displayLocale != null">
                          <button class="dropdown-toggle d-flex justify-content-center dropdown-box" type="button"
                            data-toggle="dropdown" style="margin: 0 auto">
                            <p class="mr-1">{{ displayLocale['code'] | uppercase }} </p>
                            <i class="fa fa-sort-down"></i>
                          </button>
                          <ul class="dropdown-menu dropdown-box-open">
                            <li *ngFor="let value of dropdown.locales" class="selected-category-container2 ml-0"
                              (click)="changeLocale(value)">
                              {{ value.code | uppercase }}
                            </li>
                          </ul>
                        </div>
                      </th>
                      <th class="sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name"
                        [class.sort-selected]="sortingSelection.sort_by === 'name'">{{ 'Name' | translate |
                        uppercase }}</th>
                      <th>{{ 'Link' | translate | uppercase }}</th>
                      <th width="100" class="sort-enabled" (click)="onSortColumn('position')" [ngClass]="sortingConfig.position"
                        [class.sort-selected]="sortingSelection.sort_by === 'position'">{{ 'Position' | translate |
                        uppercase }}</th>
                      <th width="100">{{ 'Status' | translate | uppercase }}</th>
                      <th class="text-center pr-13" width="100">{{ 'Actions' | translate | uppercase }}</th>
                    </tr>
                    <tr *ngIf="canCreateMenuBar && (!mode || mode != 'create')">
                      <th colspan="12" class="pr-0 pl-0">
                        <div class="content-container text-center" (click)="expandRowCreate('create')">
                          <span><i class="fas fa-plus mr-2"></i>Create New Content</span>
                        </div>
                      </th>
                    </tr>
                    <tr *ngIf="mode == 'create'">
                      <th colspan="12" class="pr-0 pl-0">
                        <ng-container *ngIf="!mode else contents"></ng-container>
                      </th>
                    </tr>
                  </thead>
                  <tbody *ngIf="(menuBar$ | async) as rows">
                    <ng-container *ngFor="let row of rows">
                      <tr [ngClass]="{ 'subMenu': row.sub_menu }">
                        <td [style]="mode == 'edit' && menuBar.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          <span>{{ row.id }}</span>
                        </td>
                        <td [style]="mode == 'edit' && menuBar.id == row.id ? 'border-bottom: 0 !important;' : ''" width="1" class="pr-5 adjustable-td">
                          <div class="locale-list">
                            {{ row.locale_list }}
                          </div>
                        </td>
                        <td [style]="mode == 'edit' && menuBar.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          {{ displayLocaleName(row) }}
                        </td>
                        <td class="script-content" [style]="mode == 'edit' && menuBar.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          {{ row.link ? row.link : '-' }}
                        </td>
                        <td class="script-content" [style]="mode == 'edit' && menuBar.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          {{ displayLocalePosition(row) }}
                        </td>
                        <td [style]="mode == 'edit' && menuBar.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          <label class="switch">
                            <input [disabled]="!canEditMenuBar && !canUpdateMenuBarStatus" type="checkbox" id="togBtn" [checked]="row.status == 1"
                              (change)="onChangeStatus($event, row)">
                            <div class="slider round">
                              <span class="on">ON</span>
                              <span class="off">OFF</span>
                            </div>
                          </label>
                        </td>
                        <td [style]="mode == 'edit' && menuBar.id == row.id ? 'border-bottom: 0 !important;' : ''"
                          class="text-center actions-column">
                          <ng-container *ngIf="canViewMenuBarDetails">
                            <button *ngIf="row?.sub_menu" class="btn btn-elevate btn-sm btn-icon btn-icon-sm mr-2 btn-expand"><i [ngClass]="row.id == subMenu?.id ? 'fa fa-minus' : 'fa fa-plus' " (click)="expandRowView(row)"></i></button>
                            <button class="add-sub-button mr-2" *ngIf="selectedPlatform == 2 && row.sub != 1" matTooltip="{{ 'Add Sub Menu' | translate }}" (click)="expandRowCreate('createSub', null ,row )"> Add Sub</button>
                            <button matTooltip="{{ 'Edit' | translate }}"
                              class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-edit"
                              (click)="expandRowEdit('edit', row)"><i class="fas fa-cog"></i></button>
                          </ng-container>
                        </td>
                      </tr>
                      <tr *ngIf="mode != 'create' && menuBar?.id == row.id">
                        <td colspan="12" class="p-0" style="border-bottom: 0 !important;">
                          <ng-container *ngIf="!mode else contents"></ng-container>
                        </td>
                      </tr>
                      <ng-container *ngIf="showSub && subMenu.id == row.id">
                        <ng-container *ngIf="!showSub else subMenuContents"></ng-container>
                      </ng-container>
                    </ng-container>
                  </tbody>
                  <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength"
                    class="text-gray-600 fw-bold table-handler"></tbody>
                </table>
              </div>
              <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true"
                  [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total"
                  (pageChange)="onViewPageBy(page)">
                </ngb-pagination>
                <div class="kt-pagination__toolbar">
                  <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                    class="form-control kt-font-brand page-size">
                    <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                  </select>
                  <span class="pagination__desc">
                    Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #subMenuContents>
  <ng-container *ngFor="let subMenu of subMenu?.sub_menu">
    <tr class="subMenu">
      <td [style]="mode == 'edit' && menuBar.id == subMenu.id ? 'border-bottom: 0 !important;' : ''">
        <span>{{ subMenu.id }}</span>
      </td>
      <td [style]="mode == 'edit' && menuBar.id == subMenu.id ? 'border-bottom: 0 !important;' : ''" width="1" class="pr-5 adjustable-td">
        <div class="locale-list">
          {{ subMenu.locale_list }}
        </div>
      </td>
      <td [style]="mode == 'edit' && menuBar.id == subMenu.id ? 'border-bottom: 0 !important;' : ''">
        {{ displayLocaleName(subMenu) }}
      </td>
      <td class="script-content" [style]="mode == 'edit' && menuBar.id == subMenu.id ? 'border-bottom: 0 !important;' : ''">
        {{ subMenu.link ? subMenu.link : '-' }}
      </td>
      <td class="script-content" [style]="mode == 'edit' && menuBar.id == subMenu.id ? 'border-bottom: 0 !important;' : ''">
        {{ displayLocalePosition(subMenu) }}
      </td>
      <td [style]="mode == 'edit' && menuBar.id == subMenu.id ? 'border-bottom: 0 !important;' : ''">
        <label class="switch">
          <input [disabled]="!canEditMenuBar && !canUpdateMenuBarStatus" type="checkbox" id="togBtn" [checked]="subMenu.status == 1"
            (change)="onChangeStatus($event, subMenu)">
          <div class="slider round">
            <span class="on">ON</span>
            <span class="off">OFF</span>
          </div>
        </label>
      </td>
      <td [style]="mode == 'edit' && menuBar.id == subMenu.id ? 'border-bottom: 0 !important;' : ''"
        class="text-center actions-column">
        <button *ngIf="canViewMenuBarDetails" matTooltip="{{ 'View' | translate }}"
          class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-edit"
          (click)="expandRowEdit('edit', subMenu)"><i class="fas fa-cog"></i></button>
      </td>
    </tr>
    <tr *ngIf="mode != 'create' && menuBar?.id == subMenu.id">
      <td colspan="12" class="p-0" style="border-bottom: 0 !important;">
        <ng-container *ngIf="!mode else contents"></ng-container>
      </td>
    </tr>
  </ng-container>
</ng-template>



<ng-template #contents>
  <form [formGroup]="formContent">
    <div class="create-content-container" *ngIf="dropdown.locales.length > 0">
      <div class="row col-12 p-0 m-0">
        <div class="col-12 mb-2" *ngIf="mode == 'create'">
          <span class="header">Create New Menu</span>
        </div>
        <div class="col-12 mb-2" *ngIf="mode == 'createSub'">
          <span class="header">Create New Sub Menu</span>
        </div>
        <div *ngIf="selectedSVG" class="col-12 mb-2">
          <div class="col-3 form-group p-0 d-flex justify-content-center">
            <span class="svg-content"  [innerHTML]="displaySVG(selectedSVG)"></span>
          </div>
        </div>
        <div class="col-12 p-0"  *ngIf="selectedPlatform == 2 && mode != 'createSub' && mode != 'editSub'">
          <div class="form-group col-3 mb-3" *ngIf="selectedPlatform == 2 && mode != 'createSub' && mode != 'editSub' ">
            <label>{{ "SVG Icon" | translate }}: <span class="text-danger">*</span></label>
            <select class="form-control" formControlName="svg_icon_id" [(ngModel)]="selectedSVG">
              <option [value]="null" disabled>{{ "Please Select" | translate }}</option>
              <option *ngFor="let value of this.dropdown.svgIcons" [ngValue]="value.id" [selected]="selectedSVG === value.id">{{ value.name }}</option>
            </select>
          </div>
        </div>
        <!-- Link Type -->
        <div class="form-group col-7 mb-3 pr-0">
          <div class="row">
            <label class="col-12">{{ 'Link' }}:
              <span class="text-danger"  *ngIf="( selectedPlatform == 1 || mode=='createSub' || mode=='editSub')">*</span>
              <i class="fa fa-info-circle ml-1" placement="right" [ngbTooltip]="linkToolTip" tooltipClass="tooltip-class" container="body">
              </i></label>
              <div class="col-4 pr-0">
                <select style="border-radius:4px 0px 0px 4px" class="form-control link-type-style" (change)="onChangeLinkFormat($event)" formControlName="link_type">
                  <ng-container *ngFor="let item of dropdown.linkTypes">
                    <option *ngIf="item.id == 1 || item.id == 2" [value]="item.id">{{ item.name }}</option>
                  </ng-container>
                </select>
              </div>
              <!-- Internal/ External Link -->
              <div class="col-8 pl-0">
                <input style="border-radius:0px 4px 4px 0px" type="text" class="form-control link-style" formControlName="link">
                <span *ngIf="showUsableLink" class="header-hint">
                  <div *ngFor="let usableLink of usableLinks; let last = last" class="p-inline">
                    <p class="p-inline" (click)="addUsableLink(usableLink)">{{ usableLink }}</p>
                  <div *ngIf="!last" class="p-inline">
                    <p class="p-inline p-display">&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  </div>
                  </div>
                </span>
                <span *ngIf="!showUsableLink" class="header-hint">
                  <div *ngFor="let usableLink of usableLinksMemberinfo; let last = last" class="p-inline">
                    <p class="p-inline" (click)="addUsableLink(usableLink)">{{ usableLink }}</p>
                  <div *ngIf="!last" class="p-inline">
                    <p class="p-inline p-display">&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  </div>
                  </div>
                </span>
                <ng-container *ngIf="this.formContent.controls.link.errors?.pattern">
                  <div class="text-danger p-0 mb-3 font-weight-normal"><p>{{ "You may only enter a link that starts with '/' or '#/popup/'" | translate }}</p></div>
                </ng-container>
                <ng-container *ngIf="!checkLinkValidity()">
                  <div class="text-danger p-0 mb-3 font-weight-normal"><p>{{ "Link with '/member' is only allowed when 'Only display after login' is ticked" | translate }}</p></div>
                </ng-container>
            </div>
          </div>
        </div>
        <div class="form-group col-5">
        </div>
        <ng-template #linkToolTip>
          <div class="text-left">
            Internal Link: A hyperlink that points to the other pages on your website. <br>
            Examples:<br>
            /promotion?code=CODE0001<br>
            /referral<br>
            <br><br>
            External Link: A hyperlink that points to the target page on other websites.<br>
            Examples:<br>
            /external?link=https://example.com/mobileapp.apk<br>
            /external?link=https://google.com<br>
            /external?link=https://example.com?member_id=:member_id<br>
          </div>
        </ng-template>
        <div class="form-group col-2">
          <div class="mt-2">
            <input type="checkbox" formControlName="new" id="new">
            <label class="ml-2" for="new">{{ 'New' | translate }}</label>
          </div>
        </div>
        <div class="form-group col-3">
          <div class="mt-2">
            <input type="checkbox" formControlName="after_login" id="after_login">
            <label class="ml-2" for="after_login">{{ 'Only display after login' | translate }}</label>
          </div>
        </div>
      </div>

      <hr>
      <mat-tab-group animationDuration="0ms" class="form-group mb-0 mat-tab-header-pagination-controls-enabled" formGroupName="contents"
        [selectedIndex]="localeIndex()">
        <ng-container *ngIf="dropdown.locales.length > 0">
          <mat-tab *ngFor="let value of dropdown.locales; let i = index" [formGroupName]="value.id">
            <ng-template mat-tab-label>
              <span class="mat-tab">
                {{ value.code }}
                <div *ngIf="checkContent(value)" class="icon" (click)="clearContent(value)">
                  <i class="fas fa-times"></i>
                </div>
              </span>
            </ng-template>
            <div class="row col-12 p-0 m-0 mb-3">
              <div class="form-group col-12 mb-0 p-0">
                <label>{{ 'Name' | translate }}: <span class="text-danger">*</span> </label>
                <input type="text" formControlName="name" class="form-control">
              </div>
              <div class="form-group col-12 mb-0 mt-2 p-0">
                <label>{{ 'Position' | translate }}: <span class="text-danger">*</span></label>
                <input type="number" min="1" (wheel)="false" step="1" formControlName="position" class="form-control">
              </div>
            </div>

          </mat-tab>
        </ng-container>
      </mat-tab-group>
      <div class="modal-footer">
        <table class="info mr-3" *ngIf="mode == 'edit'">
          <tr>
            <td class="header-hint">
              Updated by: {{ menuBar.updated_by ? menuBar.updated_by + '&nbsp;' : 'System&nbsp;' }}
            </td>
            <td class="header-hint">
              <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="tooltip-class">{{ menuBar.updated_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #updatedDateTime>
                {{ menuBar.updated_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
            </td>
          </tr>
          <tr>
            <td class="header-hint">
              Created by: {{ menuBar.created_by ? menuBar.created_by + '&nbsp;' : 'System&nbsp;' }}
            </td>
            <td class="header-hint">
              <span placement="top" [ngbTooltip]="createdDateTime" container="body" class="tooltip-class">{{ menuBar.created_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #createdDateTime>
                {{ menuBar.created_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
            </td>
          </tr>
        </table>
        <button type="button" class="btn btn-danger m-0 mr-2"
          (click)="mode == 'create' ? expandRowCreate('', true) : expandRowEdit('', null, true)">{{ 'Cancel' | translate
          }}</button>
        <button *ngIf="((mode == 'create' || mode == 'createSub') && canCreateMenuBar) || (mode != 'create' && canEditMenuBar)" type="button" class="btn btn-success" [disabled]="isEmptyContent||!formContent.valid|| !checkLinkValidity()"
          (click)="onSave()">{{ mode != null && (mode == 'create' || mode == 'createSub') ? 'Create' : 'Update' | translate }}</button>
      </div>
    </div>
  </form>
</ng-template>
<kt-swal-alert [message]="messages$ | async" (confirmed)="reload()"></kt-swal-alert>
