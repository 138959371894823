<div class="kt-form w-webkit-fill-available" *ngIf="dropdown.locales.length > 0">
  <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">

    <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body">
        <form [formGroup]="form">
          <div class="row align-items-center">
            <div class="col-xl-12 order-2 order-xl-1">
              <div class="row mb-3">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Date' }}:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="input-group date">
                    <input type="hidden" formControlName="start_date">
                    <input type="hidden" formControlName="end_date">
                    <input type="text" class="form-control" placeholder="{{ 'Search' }}" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" ngxDaterangepickerMd />
                    <span class="input-group-append">
                      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 order-2 order-xl-1">
              <div class="row mb-3">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Status' }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="status" class="form-control">
                    <option value="all"> {{'All' }} </option>
                    <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">
                      {{ value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Locale' }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="locale_id" class="form-control">
                    <option value="all"> {{'All' }} </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.locales">
                      {{ value.code }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Label' }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="label" placeholder="{{ 'Search' }}" class="form-control">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Session'}}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="session" class="form-control">
                    <option value="all">{{ 'All Session' }}</option>
                    <option [value]="value.id" *ngFor="let value of dropdown.sessions; let i = index">
                      {{ value.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="kt-section col-12 row d-flex justify-content-between mb-0 mt-1">
          <div class="dropdown dropdown-inline">
            <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmitClick()"></kt-search-button>
            <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onSubmitClick(true)"></kt-clear-button>
          </div>
        </div>
      </div>
    </div>
    <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body pt-12 pb-0">
          <div class="kt-section">
            <div class="kt-section__content">
              <div class="table-responsive">
                <table class="table table-bordered table-hover table-striped table-sortable">
                  <thead>
                    <tr>
                      <th width="5" class="sort-enabled pointer" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">{{ 'Id' | uppercase }}</th>
                      <th width="5" class="pr-0">{{ 'Locale' | uppercase }}
                      </th>
                      <th width="5" class="pl-1 pr-5">
                        <div class="dropdown selected-category-container ml-1" *ngIf="displayLocale != null">
                          <button class="dropdown-toggle d-flex justify-content-center dropdown-box" type="button" data-toggle="dropdown" style="margin: 0 auto">
                            <p class="mr-1">{{ displayLocale['code'] | uppercase }} </p>
                            <i class="fa fa-sort-down"></i>
                          </button>
                          <ul class="dropdown-menu dropdown-box-open">
                            <li *ngFor="let value of dropdown.locales" class="selected-category-container2 ml-0" (click)="changeLocale(value)">
                              {{ value.code | uppercase }}
                            </li>
                          </ul>
                        </div>
                      </th>
                      <th>{{ 'Label' | uppercase }}</th>
                      <th width="500">{{ 'Content' | uppercase }}</th>
                      <th>{{ 'Session' | uppercase }}</th>
                      <th class="sort-enabled pointer" (click)="onSortColumn('position')" [ngClass]="sortingConfig.position" [class.sort-selected]="sortingSelection.sort_by === 'position'">{{ 'Position' | uppercase }}</th>
                      <th class="sort-enabled pointer" (click)="onSortColumn('start_date')" [ngClass]="sortingConfig.start_date" [class.sort-selected]="sortingSelection.sort_by === 'start_date'">{{ 'Start Date' | uppercase }}</th>
                      <th class="sort-enabled pointer" (click)="onSortColumn('end_date')" [ngClass]="sortingConfig.end_date" [class.sort-selected]="sortingSelection.sort_by === 'end_date'">{{ 'End Date' | uppercase }}</th>
                      <th>{{ 'Status' | uppercase }}</th>
                      <th class="text-center pr-13">{{ 'Actions' | uppercase }}</th>
                    </tr>
                    <tr *ngIf="canCreateNewMarquee && (!mode || mode != 'create')">
                      <th colspan="12" class="pr-0 pl-0">
                        <div class="content-container text-center" (click)="expandRowCreate('create')">
                          <span><i class="fas fa-plus mr-2"></i>Create New Content</span>
                        </div>
                      </th>
                    </tr>
                    <tr *ngIf="mode == 'create'">
                      <th colspan="12" class="pr-0 pl-0">
                        <ng-container *ngIf="!mode else contents"></ng-container>
                      </th>
                    </tr>
                  </thead>
                  <tbody *ngIf="(marquee$ | async) as rows">
                    <ng-container *ngFor="let row of rows">
                      <tr>
                        <td [style]="mode == 'edit' && marquee.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.id }}</td>
                        <td [style]="mode == 'edit' && marquee.id == row.id ? 'border-bottom: 0 !important;' : ''" colspan="2" width="1" class="pr-5">
                          <div style="width: 100px;">
                            {{ row.locale_list }}
                          </div>
                        </td>
                        <td [style]="mode == 'edit' && marquee.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.label }}</td>
                        <td [style]="mode == 'edit' && marquee.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ displayLocaleContent(row) }}</td>
                        <td [style]="mode == 'edit' && marquee.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.session_name }}</td>
                        <td [style]="mode == 'edit' && marquee.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.position }}</td>
                        <td [style]="mode == 'edit' && marquee.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.start_date | timezoneDate:'YYYY-MM-DD HH:mm' }}</td>
                        <td [style]="mode == 'edit' && marquee.id == row.id ? 'border-bottom: 0 !important;' : ''">{{ row.end_date !== null ? (row.end_date | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }}</td>
                        <td [style]="mode == 'edit' && marquee.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          <label class="switch">
                            <input [disabled]="!canEditMarquee && !canUpdateMarqueeStatus" type="checkbox" id="togBtn" [checked]="row.status == 1" (change)="onChangeStatus($event, row)">
                            <div class="slider round">
                              <span class="on">ON</span>
                              <span class="off">OFF</span>
                            </div>
                          </label>
                        </td>
                        <td [style]="mode == 'edit' && marquee.id == row.id ? 'border-bottom: 0 !important;' : ''" class="text-center actions-column">
                          <button *ngIf="canViewMarqueeDetails" matTooltip="{{ 'View' }}" class="btn btn-elevate btn-sm btn-icon btn-icon-sm" (click)="expandRowEdit('edit', row)"><i class="fas fa-cog"></i></button>
                        </td>
                      </tr>
                      <tr *ngIf="mode == 'edit' && marquee.id == row.id">
                        <td colspan="12" class="pr-0 pl-0 pt-0 pb-0" style="border-bottom: 0 !important;">
                          <ng-container *ngIf="!mode else contents"></ng-container>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                  <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold table-handler"></tbody>
                </table>
              </div>
              <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                </ngb-pagination>
                <div class="kt-pagination__toolbar">
                  <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                    <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                  </select>
                  <span class="pagination__desc">
                    Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #contents>
  <form [formGroup]="formContent">
    <div class="create-content-container" *ngIf="dropdown.locales.length > 0">
      <div class="row col-12 p-0 m-0">
        <div class="col-12 mb-2" *ngIf="mode == 'create'">
          <span class="header">Create New Marquee</span>
        </div>
        <div class="form-group col-3 mb-3">
          <label>{{ 'Label' }}: <span class="text-danger">*</span></label>
          <input type="text" formControlName="label" class="form-control">
        </div>
        <div class="form-group col-3 mb-3">
          <label>{{ 'Start Date' }}: <span class="text-danger">*</span></label>
          <div class="input-group date">
            <input class="hidden" [owlDateTime]="dateStartRef" [ngModel]="dateTimeStack?.start_date" [ngModelOptions]="{standalone: true}">
            <input type="text" formControlName="start_date" class="form-control">
            <span class="input-group-append" [owlDateTimeTrigger]="dateStartRef">
              <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
            </span>
            <owl-date-time #dateStartRef></owl-date-time>
          </div>
        </div>
        <div class="form-group col-3 mb-3">
          <label>{{ 'End Date' }}: <span class="text-danger"></span></label>
          <div class="input-group date">
            <input class="hidden" [owlDateTime]="dateEndRef" [ngModel]="dateTimeStack?.end_date" [ngModelOptions]="{standalone: true}">
            <input type="text" formControlName="end_date" class="form-control">
            <span class="input-group-append" [owlDateTimeTrigger]="dateEndRef">
              <span class="input-group-text pointer-calendar"><i class="far fa-calendar-alt"></i></span>
            </span>
            <owl-date-time #dateEndRef></owl-date-time>
          </div>
        </div>
        <div class="form-group col-3 mb-3">
          <label>{{ 'Position' }}: <span class="text-danger">*</span></label>
          <input type="number" min="1" (wheel)="false" step="1" formControlName="position" class="form-control">
        </div>
      </div>
      <div class="row col-12 p-0 m-0">
        <div class="form-group col-3 mb-3" *ngIf="mode == 'create'">
          <label>{{ 'Status' }}: <span class="text-danger">*</span></label>
          <select formControlName="status" class="form-control">
            <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">
              {{ value }}
            </option>
          </select>
        </div>
        <div class="form-group col-3 mb-0">
          <label>{{ 'Session' }}: <span class="text-danger">*</span></label>
          <select formControlName="session" class="form-control">
            <option [value]="value.id" *ngFor="let value of dropdown.sessions; let i = index">
              {{ value.name }}
            </option>
          </select>
        </div>
      </div>
      <hr>
      <mat-tab-group animationDuration="0ms" class="form-group mb-0 mat-tab-header-pagination-controls-enabled" formGroupName="contents" [selectedIndex]="localeIndex()">
        <ng-container *ngIf="dropdown.locales.length > 0">
          <mat-tab *ngFor="let value of dropdown.locales; let i = index" [formGroupName]="value.id">
            <ng-template mat-tab-label>
              <span class="mat-tab">
                {{ value.code }}
                <div *ngIf="checkContent(value)" class="icon" (click)="clearContent(value)">
                  <i class="fas fa-times"></i>
                </div>
              </span>
            </ng-template>
            <div class="row col-12 p-0 m-0">
              <div class="form-group col-12 mb-0 p-0">
                <label>{{ 'Content' }}: </label>
                <input type="text" formControlName="content" class="form-control">
              </div>
            </div>
            <div class="col-12 p-0 mb-2">
              <span class="header-hint">Usable variables:
                <div *ngFor="let usableVariable of usableVariables; let last = last" class="p-inline">
                  <p class="p-inline" (click)="addVariable(usableVariable, value.id)">{{ usableVariable }}</p>
                  <div *ngIf="!last" class="p-inline">
                    <p class="p-inline p-display">, </p>
                  </div>
                </div>
              </span>
            </div>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
      <div class="modal-footer">
        <table class="info mr-3" *ngIf="mode == 'edit'">
          <tr>
            <td class="header-hint">
              Updated by: {{ marquee.updated_by ? marquee.updated_by + '&nbsp;' : '-&nbsp;' }}
            </td>
            <td class="header-hint">
              {{ marquee.updated_at | timezoneDate:'YYYY-MM-DD HH:mm'}}
            </td>
          </tr>
          <tr>
            <td class="header-hint">
              Created by: {{ marquee.created_by ? marquee.created_by + '&nbsp;' : '-&nbsp;' }}
            </td>
            <td class="header-hint">
              {{ marquee.created_at | timezoneDate:'YYYY-MM-DD HH:mm'}}
            </td>
          </tr>
        </table>
        <button type="button" class="btn btn-danger m-0 mr-2" (click)="mode == 'create' ? expandRowCreate('', true) : expandRowEdit('', null, true)">{{ 'Cancel' }}</button>
        <button *ngIf="(mode == 'create' && canCreateNewMarquee) || (mode == 'edit' && canEditMarquee)" type="button" class="btn btn-success m-0" [disabled]="isEmptyContent || !formContent.valid" (click)="onSave()">{{ mode != null && mode == 'create' ? 'Create' : 'Update' }}</button>
      </div>
    </div>
  </form>
</ng-template>

<kt-swal-alert [message]="messages$ | async" (confirmed)="reload()"></kt-swal-alert>
