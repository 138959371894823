<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row pr-0">
                                <!-- Username -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>
                                <!-- Agent -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Agent:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <kt-member-dropdown class="dropdown-maxheight" [form]="form" [isAgent]="true"
                                        [dropdownSettings]='dropdownSettings' [formName]="'agent_id'"
                                        [selectionAttributes]="'id'"></kt-member-dropdown>
                                </div>
                                <!-- Name -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                                <!-- Affiliate -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Affiliate:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <input type="text" formControlName="affiliate_username" placeholder="Search" class="form-control">
                                </div>
                            </div>
                            <div class="col-12 row pr-0 mt-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Group:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="group_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.groups | async">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Filter Action -->
            <div class="kt-section col-12 row mb-3 mt-3">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
            </div>
            <!-- Table -->
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="sort-enabled" (click)="onSortColumn('member_id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                    <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                                    <th class="sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('currency_code')" [ngClass]="sortingConfig.currency_code" [class.sort-selected]="sortingSelection.sort_by === 'currency_code'">Currency</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('member_group_name')" [ngClass]="sortingConfig.member_group_name" [class.sort-selected]="sortingSelection.sort_by === 'member_group_name'">Group</th>
                                    <th class="sort-enabled" (click)="onSortColumn('agent_username')" [ngClass]="sortingConfig.agent_username" [class.sort-selected]="sortingSelection.sort_by === 'agent_username'">Agent</th>
                                    <th class="sort-enabled" (click)="onSortColumn('affiliate_username')" [ngClass]="sortingConfig.affiliate_username" [class.sort-selected]="sortingSelection.sort_by === 'affiliate_username'">Affiliate</th>
                                    <th>Login Info</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('last_activity')" [ngClass]="sortingConfig.last_activity" [class.sort-selected]="sortingSelection.sort_by === 'last_activity'">Last Activity</th>
                                    <th class="sort-enabled" (click)="onSortColumn('online_time')" [ngClass]="sortingConfig.online_time" [class.sort-selected]="sortingSelection.sort_by === 'online_time'">Online Time</th>
                                    <th class="date-column" (click)="onSortColumn('last_login_time')" [ngClass]="sortingConfig.last_login_time" [class.sort-selected]="sortingSelection.sort_by === 'last_login_time'">Last Login Time</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(memberOnline$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.id ? row.id : '-' }}</td>
                                    <td><a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog('member-information', row.id)"> {{ row.username }} </a></td>
                                    <td>{{ row.name ? row.name : '-' }}</td>
                                    <td class="text-center">{{ row.currency_code ? row.currency_code : '-' }}</td>
                                    <td class="text-center">{{ row.member_group_name ? row.member_group_name : '-' }}</td>
                                    <td>{{ row.agent_username ? row.agent_username : '-' }}</td>
                                    <td>{{ row.affiliate_username ? row.affiliate_username : '-' }}</td>
                                    <td>{{ row.last_login_time ? (row.last_login_time | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }} <br>
                                        {{ row.last_login_domain ? row.last_login_domain : '' }} <br>
                                        {{ row.last_login_ip ? row.last_login_ip : '' }}
                                    </td>
                                    <td>{{ row.last_activity !== '0000-00-00 00:00:00' ? (row.last_activity | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }}
                                    </td>
                                    <td>{{ row.online_time ? row.online_time : '-' }}</td>
                                    <td>{{ row.last_login_time ? (row.last_login_time | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '-' }}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                            [directionLinks]="true" [boundaryLinks]="true" [rotate]="true"
                            [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                                class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of
                                {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- message for permission error -->
<kt-swal-alert [message]="messages2$ | async" [icon]="'error'"></kt-swal-alert>