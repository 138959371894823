import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Status } from '@core/enums/status.enum';
import { Member } from '@core/models/member.model';
import { Wallet } from '@core/models/wallet.model';
import { environment } from '@env/environment';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { MemberDataService } from '../../../../services/member-data.service';
import { MemberLabelComponent } from '../../../member-label/member-label.component';
import { MemberRemarksListComponent } from '@shared/member-remarks-list/member-remarks-list.component';
import { EventEmitterService } from '@core/services/event-emitter.service';
import * as moment from 'moment-timezone';
import { now } from 'moment';
import { map, tap, exhaustMap, catchError, switchMap, finalize } from 'rxjs/operators';
import { AppPermissionService } from '@core/services/app-permission.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss'],
})
export class BasicInfoComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  @ViewChild('memberBasicInfoExpansionPanel') memberBasicInfoExpansionPanel: MatExpansionPanel;
  @ViewChild('walletInfoExpansionPanel') walletInfoExpansionPanel: MatExpansionPanel;
  @ViewChild('accountInfoExpansionPanel') accountInfoExpansionPanel: MatExpansionPanel;
  @ViewChild('depositExpansionPanel') depositExpansionPanel: MatExpansionPanel;
  @ViewChild('withdrawalExpansionPanel') withdrawalExpansionPanel: MatExpansionPanel;
  @ViewChild('transferExpansionPanel') transferExpansionPanel: MatExpansionPanel;
  @ViewChild('processingFeesExpansionPanel') processingFeesExpansionPanel: MatExpansionPanel;
  @ViewChild('gameExpansionPanel') gameExpansionPanel: MatExpansionPanel;
  @ViewChild('bonusExpansionPanel') bonusExpansionPanel: MatExpansionPanel;
  @ViewChild('revenueExpansionPanel') revenueExpansionPanel: MatExpansionPanel;

  @Input()
  member: Member;

  @Input()
  mainWallet$: Observable<Wallet>;

  @Input()
  gameWallet: number;

  form: FormGroup;
  searchForm: FormGroup;

  status = Status;

  sqsEnable: boolean = environment.sqsEnable;

  basicInfoActiveTab: string = 'Member Info';
  searchFilterDropdownSettings: any = {};
  searchElementsData: {
    id: string,
    description: string,
    subtab: 'Member Info' | 'Member Statistics',
    section: string
  }[] = [];
  clearBlinkTimeout: any;

  ranges = this.transactionHttpService.ranges;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  defDateTime: { from: string, to: string } = this.transactionHttpService.getToday();
  activeDateTimeShortcutBtn = 'today';
  dateTimeShortcutBtns = [
    { key: 'today', label: 'Today' },
    { key: 'last-24-hours', label: 'Last 24 Hours' },
    { key: 'yesterday', label: 'Yesterday' },
    { key: 'this-week', label: 'This Week' },
    { key: 'last-week', label: 'Last Week' },
    { key: 'past-7-days', label: 'Past 7 Days' },
    { key: 'this-month', label: 'This Month' },
    { key: 'last-month', label: 'Last Month' },
    { key: 'past-30-days', label: 'Past 30 Days' },
    { key: 'custom-range', label: 'Custom Range' }
  ]

  loading = false;
  button_loading = false;
  searchBtnLoading = false;
  memberBetInfo: any;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  params = ``;

  displayVIPProgressBar: boolean = true;
  depositProgressPercentage;
  depositProgressBarWidth;

  canViewMemberInfo = false;
  canViewMemberStatistics = false;

  private subscriptions = new Subscription();

  lastBetLogSyncAt = null;

  constructor(
    private transactionHttpService: TransactionHttpService,
    private dialog: MatDialog,
    private memberDataService: MemberDataService,
    private eventEmitter: EventEmitterService,
    private appPermissionService: AppPermissionService,
  ) {
    this.searchFilterDropdownSettings = {
      singleSelection: true,
      text: 'Search',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'description',
      noDataLabel: '',
      showCheckbox: false
    };
  }

  ngOnInit(): void {
    this.formInit();
    this.calculateVIPProgress();
    const apSub = this.appPermissionService.getAppPermissions().subscribe((appPermissions) => {
      this.canViewMemberInfo = appPermissions.view_member_info;
      this.canViewMemberStatistics = appPermissions.view_member_statistics;
      this.basicInfoActiveTab = this.canViewMemberInfo ? 'Member Info' : 'Member Statistics';
      this.onSubmit();
      this.updateSeatchElementsData();
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateSeatchElementsData();
    }, 1000)
  }

  updateSeatchElementsData() {
    this.searchElementsData = [];
    document.querySelectorAll('[data-desc]').forEach((element: HTMLElement) => {
      if ((this.canViewMemberInfo && element.dataset.subtab === 'Member Info') ||  (this.canViewMemberStatistics && element.dataset.subtab === 'Member Statistics')) {
        this.searchElementsData.push({
          id: element.id,
          description: element.dataset.desc + ` (${element.dataset.subtab})`,
          subtab: element.dataset.subtab as 'Member Info' | 'Member Statistics',
          section: element.dataset.section
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSelectBasicInfoTab(tab: 'Member Info' | 'Member Statistics') {
    this.clearAllBlinkingClass();
    this.basicInfoActiveTab = tab;
  }

  onSearch() {
    this.clearAllBlinkingClass();
    const searchValue = this.searchForm.value.search,
      elementData = this.searchElementsData.find(x => x.id == searchValue),
      toggleClass = () => {
        document.getElementById(searchValue).classList.toggle('search-blink');
        document.getElementById(searchValue).scrollIntoView({ block: 'center', behavior: 'smooth' });
        this.clearBlinkTimeout = setTimeout(() => {
          if (document.getElementById(searchValue)?.classList.contains('search-blink'))
            document.getElementById(searchValue)?.classList.toggle('search-blink');
          clearTimeout(this.clearBlinkTimeout);
        }, 7500);
      };

    if (searchValue != null && searchValue.length > 0) {
      switch (elementData.section) {
        case 'memberBasicInfoExpansionPanel':
          this.memberBasicInfoExpansionPanel.open();
          break;
        case 'walletInfoExpansionPanel':
          this.walletInfoExpansionPanel.open();
          break;
        case 'accountInfoExpansionPanel':
          this.accountInfoExpansionPanel.open();
          break;
        case 'depositExpansionPanel':
          this.depositExpansionPanel.open();
          break;
        case 'withdrawalExpansionPanel':
          this.withdrawalExpansionPanel.open();
          break;
        case 'transferExpansionPanel':
          this.transferExpansionPanel.open();
          break;
        case 'processingFeesExpansionPanel':
          this.processingFeesExpansionPanel.open();
          break;
        case 'gameExpansionPanel':
          this.gameExpansionPanel.open();
          break;
        case 'bonusExpansionPanel':
          this.bonusExpansionPanel.open();
          break;
        case 'revenueExpansionPanel':
          this.revenueExpansionPanel.open();
          break;
      }

      if (elementData.subtab != this.basicInfoActiveTab) {
        this.onSelectBasicInfoTab(elementData.subtab);
        setTimeout(() => {
          toggleClass();
        }, 100);
      } else {
        toggleClass();
      }
    }
  }

  onDateRange(event: any) {
    if (event && event.startDate && event.endDate) {
      this.form.patchValue({
        start_date: event.startDate._d !== null ? event.startDate._d : null,
        end_date: event.endDate._d !== null ? event.endDate._d : null
      });
    } else {
      this.form.patchValue({
        start_date: null,
        end_date: null
      });
    }
    // Once user click on apply after select a range of datetime, it is consider as custom range
    this.activeDateTimeShortcutBtn = 'custom-range';
  }

  onClearDate() {
    this.form.patchValue({
      start_date: null,
      end_date: null,
      defaultDate: null
    });
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onDateTimeShortcut(type: string) {
    let dateTime: { from: string, to: string };

    this.activeDateTimeShortcutBtn = type;
    switch (type) {
      case 'today':
        dateTime = this.transactionHttpService.getToday();
        break;
      case 'last-24-hours':
        dateTime = this.transactionHttpService.getLast24Hours();
        break;
      case 'yesterday':
        dateTime = this.transactionHttpService.getYesterday();
        break;
      case 'this-week':
        dateTime = this.transactionHttpService.getThisWeek();
        break;
      case 'last-week':
        dateTime = this.transactionHttpService.getLastWeek();
        break;
      case 'past-7-days':
        dateTime = this.transactionHttpService.getLast7Days();
        break;
      case 'this-month':
        dateTime = this.transactionHttpService.getThisMonth();
        break;
      case 'last-month':
        dateTime = this.transactionHttpService.getLastMonth();
        break;
      case 'past-30-days':
        dateTime = this.transactionHttpService.getLast30Days();
        break;
      case 'custom-range':
        this.pickerDirective.open();
        break;
    }
    if (type != 'custom-range') {
      this.form.patchValue({
        start_date: dateTime.from,
        end_date: dateTime.to,
        defaultDate: {
          startDate: dateTime.from,
          endDate: dateTime.to
        }
      });
      this.onSubmit()
    }
  }

  onViewMoreRemarks() {
    if (this.sqsEnable) {
      this.dialog.open(MemberLabelComponent, {
        width: '1000px',
        data: {
          member: this.member
        }
      });
    } else {
      this.memberDataService.getRemarksHistory(this.member.id).subscribe(res => {
        this.dialog.open(MemberRemarksListComponent, {
          width: '800px',
          data: {
            remarks: res
          }
        });
      });
    }
  }

  setVIPUsername() {
    localStorage.setItem('vip_username', this.member.username)
  }

  onNavigateToWalletTab(){
    this.eventEmitter.memberInformationChangeTab.emit(1);
  }

  private formInit() {
    this.form = new FormGroup({
      start_date: new FormControl(this.defDateTime.from, [Validators.required]),
      end_date: new FormControl(this.defDateTime.to, [Validators.required]),
      defaultDate: new FormControl({
        startDate: this.defDateTime.from,
        endDate: this.defDateTime.to
      }),
    })

    this.searchForm = new FormGroup({
      search: new FormControl(null)
    })
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach((key) => {
      if (
        formData[key] !== '' &&
        formData[key] !== null &&
        formData[key] !== undefined &&
        key !== 'defaultDate' &&
        formData[key] !== 'all'
      ) {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private clearAllBlinkingClass() {
    clearTimeout(this.clearBlinkTimeout);
    // Remove blinking effect when switching tab if available
    document.querySelectorAll('.search-blink').forEach((element: Element) => {
      document.getElementById(element.id).classList.toggle('search-blink');
    });
  }

  private calculateVIPProgress() {
    let depositTarget: boolean;
    let total_deposits = parseFloat(this.member.total_deposits);
    let target_accumulated_deposit = parseFloat(this.member.target_accumulated_deposit);

    //If the member has reached highest vip level or there is no next vip level, hide display bar
    //The returned target_accumulated_deposit will be '-' if the member has reached highest vip level
    if (isNaN(target_accumulated_deposit)) {
      this.displayVIPProgressBar = false;
      return;
    }

    depositTarget = total_deposits >= target_accumulated_deposit;
    depositTarget ? this.member.vip_progress = 1 : this.member.vip_progress = 0;
    this.depositProgressPercentage = Math.round(total_deposits / target_accumulated_deposit * 100);
    this.depositProgressBarWidth = this.depositProgressPercentage + '%';
  }

  onSubmit() {
    if (!this.canViewMemberStatistics) {
      return;
    }
    this.params = '';
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = true;
    delete this.form.value.defaultDate;

    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        this.params = '?';
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          this.params = this.params + Object.keys(data).map(key => key + '=' + data[key]).join('&');
        }

        return this.memberDataService.getBetInformation(this.member.id, this.params + '&type=cms').pipe(
          tap(cmsData => {
            this.memberBetInfo = cmsData;
          }),
          switchMap(() => {
            return this.memberDataService.getBetInformation(this.member.id, this.params + '&type=gsys').pipe(
              tap(gsysData => {
                this.memberBetInfo = { ...this.memberBetInfo, ...gsysData };
              }),
              catchError(() => {
                return of({});
              })
            );
          }),
          finalize(() => {
            this.button_loading = false;
            this.searchBtnLoading = false;
            this.getLastBetLogSyncAt();
          })
        );
      }),
    ).subscribe();
  }

  getLastBetLogSyncAt(){
    var userTimeZone = JSON.parse(localStorage.getItem('user_data')).timezone;
    var timeInterved = 5; // 5 minutes 
    const d = new Date();
    let minutes = d.getMinutes();
    const intMinutes =  minutes/timeInterved;
    const roundedMinute = (minutes/timeInterved).toFixed(0);
    var minus;
    if( Number(intMinutes) < Number(roundedMinute) || Number(intMinutes) == Number(roundedMinute)){
      if(Number(roundedMinute) > 0) minus = 1;
      else minus = -1;
    }
    else if( Number(intMinutes) > Number(roundedMinute)){
      minus = 0;
    }
    var  minutesConverted5min = ( Number(roundedMinute) - minus )  * timeInterved;
    var newMinutes;
    if(minutesConverted5min < 10){
      newMinutes = '0'+minutesConverted5min.toString();
    }else{
      newMinutes = minutesConverted5min.toString();
    }

    var timeNow =  DateTime.now().setZone(userTimeZone).toFormat('yyyy-MM-dd HH:'+newMinutes+':00 (ZZ)'); 
    this.lastBetLogSyncAt = timeNow.replace('(', '(GMT ');
  }
}
