import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MemberBankAccountStatus } from '@core/enums/member-bank-account-status.enum';
import { MemberBankAccountEditDialogComponent } from './member-bank-account-edit/member-bank-account-edit.component';
import { tap } from 'rxjs/operators';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { Pagination } from '@core/models/pagination.model';
import { MemberBankAccountHttpService } from '@core/services/member-bank-account-http.service';
import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MemberBankAccount } from '@core/models/member-bank-account.model';
import { MatDialog } from '@angular/material/dialog';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AuthData } from '@core/models/auth-data';
import { loggedUser } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import Swal from 'sweetalert2';
import { CCIDLookupDialogComponent } from '../../../ccid-lookup/ccid-lookup.component';
import { MemberCryptoAccountEditDialogComponent } from './member-crypto-account-edit/member-crypto-account-edit.component';
import { MemberCryptoWallet } from '@core/models/member-crypto-wallet.mode';
import { MemberCryptoWalletHttpService } from '@core/services/member-crypto-wallet-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { MemberBankAccountVerificationDetailsComponent } from '../../../member-bank-account-verification/dialogs/member-bank-account-verification-details/member-bank-account-verification-details.component';
import { MemberBankAccountVerificationDataService } from '../../../member-bank-account-verification/services/member-bank-account-verification-data.service';
import { ReuploadDocumentDialogComponent } from './reupload-document/reupload-document.component'; 

// TODO: uncomment if R&P 2.0 is up
// import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-member-bank-account',
  templateUrl: './member-bank-account.component.html',
  styleUrls: ['./member-bank-account.component.scss']
})
export class MemberBankAccountComponent implements OnInit, OnDestroy {

  @Input() isNeedPagination = true;
  @Input() member: any;
  messages$ = this.memberBankAccountHttpService.messages$;
  cryptoMessages$ = this.memberCryptoWalletHttpService.messages$;
  memberbankAccount$: Observable<MemberBankAccount[]>;
  membercryptoWallet$: Observable<MemberCryptoWallet[]>;
  perPageDropdown = this.dropdownHttpService.perPage;

  currentUserId: number;
  currentOperatorUsername: string;
  loggedUser$: Observable<AuthData>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  payNowParams = [{param: 'virtual_payment_address', label: 'Virtual Payment Address'},
                  {param: 'unique_entity_name', label: 'Unique Entity Name'},
                  {param: 'nric_fin', label: 'NRIC/FIN'},
                  {param: 'mobile_number', label: 'Mobile Number'}];

  status = MemberBankAccountStatus;
  // hasPermission: boolean = JSON.parse(localStorage.getItem('user_access'))[1][1]['edit'];

  cryptoPagination: Pagination;
  cryptoPageSize = 30;
  cryptoPage = 1;
  cryptoMaxSize = 5;
  cryptoParams = '';

  // permissions
  canCreateMemberBankAccount: boolean;
  canUpdateBankAccountStatus: boolean;
  canEditMemberBankAccount: boolean;
  canViewMemberCryptoAccounts: boolean;
  canCreateMemberCryptoAccount: boolean;
  canUpdateBankCryptoStatus: boolean;
  canEditMemberCryptoAccount: boolean;

  isSuperAdmin = false;
  userData = JSON.parse(localStorage.getItem('user_data'));

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private memberBankAccountHttpService: MemberBankAccountHttpService,
    private memberCryptoWalletHttpService: MemberCryptoWalletHttpService,
    public dialog: MatDialog,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>,
    private memberBankAccountVerificationDataService: MemberBankAccountVerificationDataService,
  ) { }

  ngOnInit(){
    this.onViewPageBy();
    this.checkNewRole();

    this.onViewCryptoPageBy();

    const loggedUserStoreSub = this.store.pipe(select(loggedUser)).subscribe((res) => {
      this.currentUserId = res.id;
      this.currentOperatorUsername = res.username;
    });

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateMemberBankAccount = appPermissions.create_member_bank_account;
      this.canUpdateBankAccountStatus = appPermissions.update_bank_account_status;
      this.canEditMemberBankAccount = appPermissions.edit_member_bank_account;
      this.canViewMemberCryptoAccounts = appPermissions.view_member_crypto_accounts;
      this.canUpdateBankCryptoStatus = appPermissions.update_bank_crypto_status;
      this.canCreateMemberCryptoAccount = appPermissions.create_member_crypto_account;
      this.canEditMemberCryptoAccount = appPermissions.edit_member_crypto_account;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(loggedUserStoreSub);
    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  checkNewRole() {
    if( this.userData && this.userData.application_role_id && this.userData.application_role_id  == 1 ) {
      this.isSuperAdmin = true;
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    const paginationQuery = `&paginate=true&page=${page}&perPage=${pageSize}${params}`;

    return this.memberbankAccount$ = this.memberBankAccountHttpService.getWithQuery(`?member_account_id=${this.member.id}${paginationQuery}`).pipe(
      tap(res => {
        this.pagination = this.memberBankAccountHttpService.pagination;

        this.loadingBar.complete();
      })
    );
  }
  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onViewCryptoPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.cryptoPageSize;
    params = this.cryptoParams ? `&${this.cryptoParams}` : '';
    this.loadingBar.start();
    const paginationQuery = `&paginate=true&page=${page}&perPage=${pageSize}${params}`;

    return this.membercryptoWallet$ = this.memberCryptoWalletHttpService.getWithQuery(`?member_account_id=${this.member.id}${paginationQuery}`).pipe(
      tap(res => {
        this.cryptoPagination = this.memberCryptoWalletHttpService.pagination;

        this.loadingBar.complete();
      })
    );
  }
  onPerCryptoPage(size: Event){
    this.cryptoPage = 1;
    this.cryptoPageSize = +(size.target as HTMLSelectElement).value;
    this.onViewCryptoPageBy(this.cryptoPage, this.cryptoPageSize, this.cryptoParams);
  }

  onOpenDialog(type: string, data?: any) {
    if (type == 'create') {
      this.openDialogBy(MemberBankAccountEditDialogComponent, { member: this.member, mode: type });
    } else if (type == 'edit') {
      const member = this.memberBankAccountHttpService.getById(data);
      this.subscription = member.pipe(
        tap( (res) => {
          if(res) {
            this.openDialogBy(MemberBankAccountEditDialogComponent, { member: this.member, bankAccount: res, mode: type });
          }
        })
      )
      .subscribe();
    }  else if (type == 'member-bank-account-verification') {
      this.subscription = this.memberBankAccountVerificationDataService.getById(data.verification_id).pipe(
        tap((res) => {
          if (res) {
            const showDialog = (takeover = false) => {
              if (takeover) {
                const data = {
                  id: res.id,
                  takeover: takeover,
                  triggerMessage: false
                };

                this.memberBankAccountVerificationDataService.updateVerification(data.id, data).subscribe();
                this.openDialogBy(MemberBankAccountVerificationDetailsComponent, { mode: type, memberVerification: res, newHandler: this.currentOperatorUsername });
              } else {
                this.openDialogBy(MemberBankAccountVerificationDetailsComponent, { mode: type, memberVerification: res });
              }
            };

            if (res.bo_user_id > 0 && (res.bo_user_id !== this.currentUserId) && (res.status === 3)) {
              Swal.fire({
                title: 'Someone is on the same verification row!',
                text: 'Do you want to take over?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                reverseButtons: true,
              }).then((resp) => {
                if (resp.value) {
                  return showDialog(true);
                }
              });
            } else {
              return showDialog();
            }
          }
        })
      ).subscribe();
    }  else if (type == 'reupload-document') {
      this.openDialogBy(ReuploadDocumentDialogComponent, { bankAccount: data, mode: type });
    }
  }

  onOpenCryptoDialog(type: string, cryptoAccountId?: number) {
    if(cryptoAccountId){
      const member = this.memberCryptoWalletHttpService.getById(cryptoAccountId);
      this.subscription = member.pipe(
        tap( (res) => {
          if(res) {
            this.openDialogBy(MemberCryptoAccountEditDialogComponent, { member: this.member, cryptoAccount: res, mode: type });
          }
        })
      )
      .subscribe();
    }else{
      this.openDialogBy(MemberCryptoAccountEditDialogComponent, { member: this.member, mode: type });
    }
  }

  onChangeStatus(memberBank: MemberBankAccount){
    const data = {
      id: memberBank.id,
      member_account_id: this.member.id,
      account_number: memberBank.account_number,
      bank_type: memberBank.bank_type,
      status: memberBank.status === 1 ? 0 : 1,
      update_status_only: 1
    };
    this.subscription = this.memberBankAccountHttpService.update(data).pipe(
      tap((res: any) => {
      this.messages$.next([...res.message]);
    })
    ).subscribe();
  }

  onChangeCryptoStatus(memberCrypto: MemberCryptoWallet) {
    const data = {
      id: memberCrypto.id,
      member_account_id: this.member.id,
      crypto_token_id: memberCrypto.crypto_token_id,
      status: memberCrypto.status === 1 ? 0 : 1,
    };
    this.subscription = this.memberCryptoWalletHttpService.update(data).pipe(
      tap((res: any) => {
      this.cryptoMessages$.next([...res.message]);
    })
    ).subscribe();
  }

  onLookup(accountNumber: string) {
    this.dialog.open(CCIDLookupDialogComponent, {
      width: '800px',
      data: {
        accountNumber: accountNumber
      }
    });
  }

  onCloseDialog(event?: Event) {
    this.onViewPageBy(this.page).subscribe();
  }

  onCloseCryptoDialog(event?: Event) {
    this.onViewCryptoPageBy(this.cryptoPage).subscribe();
  }

  onPayNowBankName(data: any) {
    let accName = null;
    this.payNowParams.map(res => {
      if ( data[res.param] !== ''){
        accName = res.label;
      }
    });
    return accName;
  }

  onPayNowBankNumber(data: any) {
    let accNumber = null;
    this.payNowParams.map(res => {
      if ( data[res.param] !== ''){
        accNumber = data[res.param];
      }
    });
    return accNumber;
  }

  private openDialogBy(componentRef: any, data?: { member?: any, bankAccount?: any, cryptoAccount?: any, mode: string, memberVerification?: any, newHandler?: string, refreshAfterCloseDialog?: boolean}) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode == 'member-bank-account-verification' ? '1200px' : '800px',
      data: {
        member: data.member,
        bankAccount: data.bankAccount,
        memberVerification: data.memberVerification,
        newHandler: data.newHandler,
        cryptoAccount: data.cryptoAccount,
        mode: data.mode
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result === true){
        this.onViewPageBy(this.page);
        this.onViewCryptoPageBy(this.cryptoPage);
      }
    });
  }
}
