import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of, forkJoin, Subscription } from 'rxjs';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Campaign } from '@core/models/campaign.model';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { Pagination } from '@core/models/pagination.model';
import * as moment from 'moment-timezone';
import { CampaignEntityService } from '../services/campaign-entity.service';
import { CampaignDataService } from '../services/campaign-data.service';
import { tap, map, exhaustMap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MemberEntityService } from '@views/pages/apps/general/members/services/member-entity.service';
import { MemberRewardsDialogComponent } from '@views/pages/apps/general/members/dialogs/member-rewards/member-rewards.component';
import { MatDialog } from '@angular/material/dialog';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-campaign-member-list',
  templateUrl: './campaign-member-list.component.html',
  styleUrls: ['./campaign-member-list.component.scss']
})
export class CampaignMemberListComponent implements OnInit, OnDestroy {
  form: FormGroup;
  campaignMemberList$: any;
  params = '';
  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;
  campaign_data:any;
  datetime_dropdown = [
    {name : 'Registration Date',     value : 'registered_at' },
    {name : 'Promotion Claimed At',  value : 'promo_claimed_at' },
    {name : 'Promotion Created At',  value : 'promo_created_at' },
  ];

  default_sort_by = 'id';
  default_sort_order = 'desc';

  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'registration_date': 'desc',
    'promo_code': 'desc',
    'claimed_at': 'desc',
    'expiry_date': 'desc',
    'created_at': 'desc',
  };

  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order,
  };

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  // permissions
  canAssignReward: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { campaign: Campaign, mode: string, campaignID: number },
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private campaignDataService: CampaignDataService,
    private campaignEntityService: CampaignEntityService,
    public dialogRef: MatDialogRef<CampaignMemberListComponent>,
    private loadingBar: LoadingBarService,
    private memberService: MemberEntityService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit(): void {
    this.formInit();
    this.campaign_data = this.campaign_data ?? '';
    this.campaignEntityService.getByKey(this.data.campaignID).pipe(
      tap( (res) => {
        this.campaign_data = res;
    })).subscribe();
    this.pagination = this.campaignDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.onSubmit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canAssignReward = appPermissions.campaigns_assign_reward;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onClearDate() {
    if (this.form.value.start_date !== null){
      this.form.patchValue({defaultDate: null});
    }
  }
  onDateRange(event: any) {
    if (event){
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }
  onSubmit(clearSearch?: boolean){
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        data['campaign_id'] = this.data.campaignID;
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.campaignMemberList$ = this.campaignDataService.getCampaignMember(`?perPage=${this.pageSize}${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.campaignDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }
  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      start_date: null,
      end_date: null,
      id: null,
      username: null,
      defaultDate: null,
    });
    this.onSubmit(true);
  }
  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }
  onOpenDialog(type: string, memberId?: number) {
    if(type === 'assignRewards_campaign'){
      const member = this.memberService.getByKey(memberId);
      this.subscription = member.pipe(
        tap((res) => {
          if (res) {
            this.openDialogBy(MemberRewardsDialogComponent, { member: res, mode: type, campaign: this.campaign_data });

          }
        })
      )
      .subscribe();
    }
  }
  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.campaignMemberList$ = this.campaignDataService.getCampaignMember(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.dataLength = res.length;
        this.pagination = this.campaignDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any, campaign?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      height: 'auto',
      data,
    });
      dialogRef.afterClosed().subscribe(() => {
        this.onViewPageBy(this.page).subscribe();
      });
  }
  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all' && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }
  private formInit() {
    this.form = new FormGroup({
      datetime_type: new FormControl(''),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      id: new FormControl(null),
      username: new FormControl(null),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
    });
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }
}
