import { PdfDialogComponent } from '@shared/pdf-dialog/pdf-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Lightbox } from 'ngx-lightbox';
import { UploadHttpService } from '@core/services/upload-http.service';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { catchError, tap } from 'rxjs/operators';
import { MerchantBank } from '@core/models/merchant-bank.model';
import { MerchantBankDataService } from '@views/pages/apps/settings/merchant-banks/services/merchant-bank-data.service';
import { Subscription } from 'rxjs';
import { WithdrawMultiBankTransaction } from '@core/models/withdraw-multi.model';
import { WithdrawDataService } from '../../services/withdraw-data.service';
import Swal from 'sweetalert2';
import { AppPermissionService } from '@core/services/app-permission.service';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'kt-withdrawal-multi',
  templateUrl: './withdrawal-multi.component.html',
  styleUrls: ['./withdrawal-multi.component.scss']
})
export class WithdrawalMultiDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  banks = new FormArray([]);

  @Input()
  status: number;

  @Input()
  purposeId: number;

  @Input()
  id: number;

  @Input()
  currencyId: number;

  @Input()
  confirmedAmount: number;

  @Input()
  withdrawalDetails: any;

  @Input()
  withdrawalBankType: any;

  @Input()
  bankTransactions: [{
    amount: string,
    id: number,
    merchant_bank: string;
    merchant_bank_account: string;
    merchant_bank_id: number;
    processing_fee: number;
    member_processing_fee: number;
    receipt_image: string,
    transaction_remarks: string;
    status?: number;
    status_name?: string;
    remarks?: string;
    cr_withdrawal_link?: string;
  }];

  @Output()
  changed = new EventEmitter<{ payload: WithdrawMultiBankTransaction }>();

  @Output()
  update = new EventEmitter<{ payload: WithdrawMultiBankTransaction }>();

  @Output()
  payButtonExist = new EventEmitter<boolean>();

  merchantBanks: MerchantBank[] = [];

  bankaccountDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'labelKey',
    labelKey: 'labelKey',
    noDataLabel: '',
    showCheckbox: false,
    disabled: false,
  };
  newTransaction = new FormArray([]); // For show loading, preview, and check is PDF only.
  showPayButton = false;
  selectedMerchantBank = [];
  buttonLoading = false;
  isClicked = false;
  messages2$ = this.withdrawDataService.messages2$;

  isWPPSelected = false;

  isPaymentGatewaySelected = false;

  minAmountText = [];
  maxAmountText = [];
  showMinAmount = [];
  showMaxAmount = [];

  showTotalProcessingFeeError = [];
  totalProcessingFeeErrorText = [];
  showProcessingFeeError = [];
  processingFeeErrorText = [];
  cryptoWithdrawalLink = '';
  cryptoWithdrawalLinkClicked = false;

  // Permissions
  canEditWithdrawals: boolean;
  canUploadWithdrawalReceipt: boolean;
  canViewWithdrawalAmount: boolean;

  private sum = 0;
  private touchedAmountSum = 0;
  private touchedAmountIndexes = [];
  private subscription: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private merchantBankDataService: MerchantBankDataService,
    private uploadService: UploadHttpService,
    private sanitizer: DomSanitizer,
    private lightbox: Lightbox,
    private withdrawDataService: WithdrawDataService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
    private cdr: ChangeDetectorRef,
    private decimalPipe: DecimalPipe
  ) { }

  ngOnInit() {
    this.isPaymentGatewaySelected = this.withdrawalBankType == 'crypto' ? true : false;
    this.formInit();
    this.formChanged();
    this.subscription.push(this.getMerchantBanks().subscribe());

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditWithdrawals = this.withdrawalBankType == 'crypto' ? appPermissions.edit_crypto_withdrawals : appPermissions.edit_withdrawals;
      this.canUploadWithdrawalReceipt = this.withdrawalBankType == 'crypto' ? false : appPermissions.upload_withdrawal_receipt;
      this.canViewWithdrawalAmount = this.withdrawalBankType == 'crypto' ? appPermissions.view_crypto_withdrawal_amount : appPermissions.view_banking_withdrawal_amount;
      this.cdr.detectChanges();
    });

    this.subscription.push(apSub);
  }

  ngOnDestroy() {
    this.subscription.map(sub => sub.unsubscribe);
  }

  onAddTransaction() {
    if( this.merchantBanks.length <= 0 ) {
      return;
    }

    // Crypto can only have 1 instance
    let canAddTransaction = true;
    if( this.withdrawalBankType == 'crypto' ) {
      this.bankTransactions.forEach(item => {
        if (item.status == 2) {
          canAddTransaction = false;
          this.cryptoWithdrawalLink = item.cr_withdrawal_link || '';

          if( this.banks.length <= 0 ) {
            const group = new FormGroup({
              id: new FormControl(item.id),
              amount: new FormControl(item.amount, [Validators.required, Validators.pattern('^(?=.*[1-9])[0-9]+(\.[0-9]+)?$')]),
              processing_fee: new FormControl(item.processing_fee, [Validators.min(0)]),
              remarks: new FormControl(item.remarks),
              receipt_image: new FormControl(item.receipt_image),
              payee_ifsc_code: new FormControl(null),
              total_processing_fee: new FormControl(0),
              member_processing_fee: new FormControl(0),
            });
            this.banks.push(group);
            this.selectedMerchantBank[this.banks.length - 1] = this.merchantBanks.filter(row => row.id === item.merchant_bank_id);
            this.autoCalculateSumAmount();
          }
          this.form.disable();
          this.bankaccountDropdownSettings = {...this.bankaccountDropdownSettings, disabled: true};
        }
      });
    }
    if( !canAddTransaction ) {
      return;
    }

    // Amount Part
    let finalAmount = this.confirmedAmount;
    this.bankTransactions.forEach(item => {
      if (item.status == 1) {
        finalAmount -= +item.amount;
      }
    })
    this.banks.value.forEach(item => {
      finalAmount -= +item.amount;
    })
    // restrict 2 decimal places for amount value
    finalAmount = Number(Number(finalAmount.toString()).toFixed(2));
    const group = new FormGroup({
      id: new FormControl(null),
      amount: new FormControl(finalAmount, [Validators.required, Validators.pattern('^(?=.*[1-9])[0-9]+(\.[0-9]+)?$')]),
      processing_fee: new FormControl(0.00, [Validators.min(0)]),
      remarks: new FormControl(null),
      receipt_image: new FormControl(null),
      payee_ifsc_code: new FormControl(null),
      total_processing_fee: new FormControl(0),
      member_processing_fee: new FormControl(0),
    });
    this.banks.push(group);
    this.selectedMerchantBank[this.banks.length - 1] = [];
    this.autoCalculateSumAmount();

    // Receipt Part
    const transactionGroup = new FormGroup({
      loading: new FormControl(false),
      url: new FormControl(''),
      isPDF: new FormControl(false),
    });
    this.newTransaction.push(transactionGroup);
  }

  onRemoveItem(index: number) {
    this.newTransaction.removeAt(index);
    this.selectedMerchantBank.splice(index, 1);
    this.showPayButton = false;
    //pass value to withdrawal-edit component
    this.payButtonExist.emit(this.showPayButton);

    this.isWPPSelected = false;
    this.isPaymentGatewaySelected = false;
    this.selectedMerchantBank.forEach(item => {
      if (item[0]?.bank_type_name == 'Payment Gateway') {
        this.showPayButton = true;
        //pass value to withdrawal-edit component
        this.payButtonExist.emit(this.showPayButton);

        this.isPaymentGatewaySelected = true;
      } else {
        this.showPayButton = false;
        //pass value to withdrawal-edit component
        this.payButtonExist.emit(this.showPayButton);

        this.isPaymentGatewaySelected = false;
      }
      if (item[0]?.bank_code == 'WPP') {
        this.isWPPSelected = true;
      }
    })
    this.banks.removeAt(index);
    if (this.banks.controls.length === 0) {
      this.touchedAmountIndexes = [];
      this.touchedAmountSum = 0;
      this.sum = 0;
    }
    this.cryptoWithdrawalLink = '';
    this.form.enable();
    this.autoCalculateSumAmount();
  }

  onSelectMerchantBank(event: any, index: number) {
    this.selectedMerchantBank[index] = event;
    if (this.selectedMerchantBank[index].length > 0) {
      // If cannot get the min_transaction_withdrawal_limit and max_transaction_withdrawal_limit, use merchant bank value instead
      const minWithdrawalLimit = event[0].min_transaction_withdrawal_limit ? event[0].min_transaction_withdrawal_limit : event[0].min_withdrawal_per_transaction;
      const maxWithdrawalLimit = event[0].max_transaction_withdrawal_limit ? event[0].max_transaction_withdrawal_limit : event[0].max_withdrawal_per_transaction;
      const currency = event[0].currency;
      this.determineAmountDisplay(+this.banks.value[index].amount, +minWithdrawalLimit, +maxWithdrawalLimit, index, currency);
    } else {
      this.showMinAmount[index] = false;
      this.showMaxAmount[index] = false;
    }
    if (event[0]?.bank_type_name === 'Payment Gateway') {
      this.showPayButton = true;
      //pass value to withdrawal-edit component
      this.payButtonExist.emit(this.showPayButton);

      this.isPaymentGatewaySelected = true;
      if (event[0].bank_code == 'WPP') {
        this.isWPPSelected = true;
      }
    } else {
      this.showPayButton = false;
      //pass value to withdrawal-edit component
      this.payButtonExist.emit(this.showPayButton);

      this.isPaymentGatewaySelected = false;
    }
  }

  determineAmountDisplay(amount: number, minWithdrawalLimit: number, maxWithdrawalLimit: number, index: number, currencyTxt: any) {
    const currency = currencyTxt;
    this.showMinAmount[index] = false;
    this.showMaxAmount[index] = false;

    if (minWithdrawalLimit > 0 && maxWithdrawalLimit > 0) {
      this.minAmountText[index] = currency + ' ' + minWithdrawalLimit;
      this.maxAmountText[index] = currency + ' ' + maxWithdrawalLimit;
      if (amount < minWithdrawalLimit || amount > maxWithdrawalLimit) {
        this.showMinAmount[index] = true;
        this.showMaxAmount[index] = true;
      }
    } else if (minWithdrawalLimit == 0 && maxWithdrawalLimit > 0) {
      if (amount > maxWithdrawalLimit) {
        this.maxAmountText[index] = currency + ' ' + maxWithdrawalLimit;
        this.showMaxAmount[index] = true;
      }
    } else if (minWithdrawalLimit > 0 && maxWithdrawalLimit == 0) {
      if (amount < minWithdrawalLimit) {
        this.minAmountText[index] = currency + ' ' + minWithdrawalLimit;
        this.showMinAmount[index] = true;
      }
    }
  }

  onPay(index: number) {
    if (this.withdrawalBankType == 'crypto' && this.cryptoWithdrawalLink) {
      Swal.fire({
        title: 'Coin2Pay Withdrawal Link has been generated and clicked before!',
        text: 'Do you want to generate a new link?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.createPayout(index, true);
        }
      });
    } else {
      this.createPayout(index);
    }
    return;
  }

  createPayout(index, isRegenerate? :boolean) {
    if (this.withdrawalBankType == 'crypto') {
      this.bankaccountDropdownSettings = {...this.bankaccountDropdownSettings, disabled: true};
    }
    this.buttonLoading = true;
    this.form.disable();
    const data: any = {
      withdraw_id: this.id,
      amount: this.form.value.merchant_banks[index].amount,
      member_account_id: this.withdrawalDetails.member_id,
      bank_account_id: this.withdrawalDetails.bank_account_id,
      merchant_bank_id: this.selectedMerchantBank[index][0].id,
      payee_ifsc_code: this.form.value.merchant_banks[index].payee_ifsc_code,
      is_crypto: this.withdrawalBankType == 'crypto' ? 1 : 0
    };
    if( isRegenerate ) {
      data.bank_transaction_id = this.bankTransactions[index].id;
    }
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    this.withdrawDataService.createPayout(data).pipe(
      tap((res: any) => {
        this.buttonLoading = false;
        this.update.emit(this.form.value);
        if (this.withdrawalBankType == 'crypto') {
          if( res.rows && res.rows.data && res.rows.data.url ) {
            this.cryptoWithdrawalLink = res.rows.data.url || '';
            this.cryptoWithdrawalLinkClicked = false;
          }
        } else {
          this.onRemoveItem(index);
          this.form.enable();
        }
      }),
      catchError((error) => {
        this.form.enable();
        this.buttonLoading = false;
        if (this.withdrawalBankType == 'crypto') {
          this.bankaccountDropdownSettings = {...this.bankaccountDropdownSettings, disabled: false};
        }
        throw error;
      })
    ).subscribe();
  }

  onRedirectCryptoWithdrawalUrl() {
    this.cryptoWithdrawalLinkClicked = true;
    window.open(this.cryptoWithdrawalLink, '_blank').focus();
  }

  onCheckPayoutStatus(bankTransactionId: number, index: number) {
    this.isClicked = true;
    this.withdrawDataService.getPayoutStatus(bankTransactionId).subscribe(res => {
      this.bankTransactions = JSON.parse(JSON.stringify(this.bankTransactions)); //prevent read only error
      this.bankTransactions[index].status = res.status;
      this.bankTransactions[index].status_name = res.status_name;
      this.isClicked = false;
    });
  }

  onChangeAmount(event: Event, index: number) {
    const amount = +(event.target as HTMLInputElement).value;
    const merchantBank = this.selectedMerchantBank[index];
    if (merchantBank.length > 0) {
      // If cannot get the min_transaction_withdrawal_limit and max_transaction_withdrawal_limit, use merchant bank value instead
      const minWithdrawalLimit = merchantBank[0].min_transaction_withdrawal_limit ? merchantBank[0].min_transaction_withdrawal_limit : merchantBank[0].min_withdrawal_per_transaction;
      const maxWithdrawalLimit = merchantBank[0].max_transaction_withdrawal_limit ? merchantBank[0].max_transaction_withdrawal_limit : merchantBank[0].max_withdrawal_per_transaction;
      const currency = merchantBank[0].currency;
      this.determineAmountDisplay(amount, +minWithdrawalLimit, +maxWithdrawalLimit, index, currency);
    } else {
      this.showMinAmount[index] = false;
      this.showMaxAmount[index] = false;
    }
    this.touchedAmountIndexes.push(index);
    this.touchedAmountIndexes = [...new Set(this.touchedAmountIndexes)];
    this.autoCalculateSumAmount();
  }

  checkIfPDF(src: string) {
    return src.indexOf('.pdf') >= 0;
  }

  formatPDFSrc(src: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(src);
  }

  onUploadFile(event: any, index: number) {
    this.newTransaction.value[index].loading = true;
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('type', 'receipts');
    this.uploadService.upload(formData).pipe(
      tap((res: any) => {
        this.newTransaction.value[index].isPDF = res[0].indexOf('.pdf') >= 0;
        this.newTransaction.value[index].url = res[0].indexOf('.pdf') >= 0 ? this.sanitizer.bypassSecurityTrustResourceUrl(res[0]) : res[0];
        this.form.value.merchant_banks[index].receipt_image = res[0];
        this.form.setValue({
          ...this.form.value
        });
        this.form.markAllAsTouched();
        this.newTransaction.value[index].loading = false;
      }),
      catchError(err => {
        this.newTransaction.value[index].loading = false;
        throw err;
      })
    ).subscribe();
  }

  openReceipt(path: any) {
    if (path.indexOf('.pdf') >= 0) {
      this.dialog.open(PdfDialogComponent, {
        width: '800px',
        data: {
          pdfSrc: path
        },
      });
    } else {
      const receipt = [{
        src: path,
        caption: 'Withdraw Receipt',
        thumb: path
      }];

      this.lightbox.open(receipt, 0, { centerVertically: true, disableScrolling: true, fitImageInViewPort: true, fadeDuration: 0.01, resizeDuration: 0.01 });
    }
  }

  onRemoveReceipt(index: number) {
    this.form.value.merchant_banks[index].receipt_image = null;
    this.newTransaction.value[index].url = '';
  }

  private autoCalculateSumAmount() {

    // Reset
    this.sum = 0;
    this.touchedAmountSum = 0;
    let balancer = 0;

    // Auto calculate
    this.banks.controls.forEach((control, index) => {

      const amount = +control.value[`amount`];
      if (amount !== null) {
        this.sum += amount;
      }

      if (this.isControlTouched(index)) {
        this.touchedAmountSum += amount;
      } else {
        balancer = +((this.confirmedAmount - this.touchedAmountSum) / +(this.banks.controls.length - this.touchedAmountIndexes.length)).toFixed(5);
        if (balancer <= 0 || this.touchedAmountSum >= this.confirmedAmount) {
          balancer = 0;
          control.patchValue({ amount: balancer });
        }
      }

    });

    // Recalculate agains against custom input
    // if(this.touchedAmountIndexes.length > 0) {
    //   const bankControls = this.banks.controls
    //   bankControls.map((el, i) => {

    //     if(!this.touchedAmountIndexes.includes(i)) {
    //       balancer = (this.confirmedAmount - this.touchedAmountSum) / (bankControls.length - this.touchedAmountIndexes.length);
    //       if( balancer <= 0) {
    //         balancer = 0;
    //       }
    //       el.patchValue({ amount: balancer });
    //     }
    //   });
    // }
  }

  private isControlTouched(index: number) {
    return this.touchedAmountIndexes.includes(index);
  }

  private formChanged() {
    this.subscription.push(this.form.valueChanges.pipe(
      tap(() => this.changed.emit(this.form.value))
    ).subscribe());

  }

  private getMerchantBanks() {
    const is_crypto = this.withdrawalBankType == 'crypto' ? 1 : 0;
    return this.merchantBankDataService.getWithQuery(`?purpose=${this.purposeId}&currency_id=${this.currencyId}&status[0]=1&status[1]=2&member_id=${this.withdrawalDetails.member_id}&is_crypto=${is_crypto}`).pipe(
      tap(res => {
        res.forEach(item => {
          item['labelKey'] = item.bank_code + ' - ' + item.account_name + ' - ' + item.account_number;
        })
        this.merchantBanks = res
      })
    );
  }

  private formInit() {
    this.form = this.fb.group({
      withdraw_id: new FormControl(this.id),
      merchant_banks: this.banks
    })
  }

  /**
   * The function onChangeTotalProcessingFee checks if the total processing fee entered is valid and
   * updates the error message accordingly.
   * @param {Event} event - The event parameter is an object that represents the event that triggered
   * the onChangeTotalProcessingFee function. It could be an input change event, for example, when the
   * user types in a value in an input field.
   * @param {number} index - The index parameter is a number that represents the index of an element in
   * an array. It is used to access and manipulate specific elements in the array. In this case, it is
   * used to access and update the values in the showTotalProcessingFeeError and
   * totalProcessingFeeErrorText arrays.
   */
  onChangeTotalProcessingFee(event: Event, index: number) {
    const totalProcessingFee = +(event.target as HTMLInputElement).value;
    const form = this.banks.value[index];
    if (totalProcessingFee >= +form.amount) {
      this.showTotalProcessingFeeError[index] = true;
      this.totalProcessingFeeErrorText[index] = 'Total should be less than the transaction amount';
    } else if (totalProcessingFee < 0) {
      this.showTotalProcessingFeeError[index] = true;
      this.totalProcessingFeeErrorText[index] = 'Total should be greater or equal to 0';
    } else {
      this.showTotalProcessingFeeError[index] = false;
      this.totalProcessingFeeErrorText[index] = '';
    }
    this.onChangeProcessingFee(index);
  }
  /**
   * The function `onChangeProcessingFee` checks if the sum of `processingFee` and
   * `memberProcessingFee` is greater than `total_processing_fee` and updates the error message
   * accordingly.
   * @param {number} index - The index parameter is a number that represents the index of the bank in
   * the banks array. It is used to access the specific bank object in the array.
   * @param {Event} [event] - The `event` parameter is an optional parameter of type `Event`. It
   * represents the event that triggered the change in the processing fee. It can be used to access
   * information about the event, such as the target element or the event type.
   */
  onChangeProcessingFee(index: number, event?: Event) {
    const form = this.banks.value[index];
    const processingFee = +form.processing_fee;;
    const memberProcessingFee = +form.member_processing_fee;
    if ((processingFee + memberProcessingFee) > +form.total_processing_fee) {
      this.showProcessingFeeError[index] = true;
      this.processingFeeErrorText[index] = 'Company + Player should not be greater than Total';
    } else {
      this.showProcessingFeeError[index] = false;
      this.processingFeeErrorText[index] = '';
    }
  }

  canShowAmount(amount: number, format: string) {
    let retAmount = '***';
    if( this.canViewWithdrawalAmount ) {
      retAmount = this.decimalPipe.transform(amount, format);
    }
    return retAmount;
  }

}
