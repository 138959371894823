<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row pr-0 mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Domain' }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                  <input type="text" formControlName="domain" placeholder="{{ 'Search' }}" class="form-control">
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Type' }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="type" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="item.id" *ngFor="let item of dropdown.type">{{ item.name }}</option>
                                    </select>
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Device Type' }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="device_type" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="item.id" *ngFor="let item of dropdown.deviceType">{{ item.name }}</option>
                                    </select>
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Status' }}:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="status" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">{{ value }}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Filter Action -->
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canCreateSiteDomain" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>{{ 'Create' }}</button>
            </div>
            <!-- Table -->
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">{{ 'ID' }}</th>
                                    <th class="sort-enabled" (click)="onSortColumn('domain')" [ngClass]="sortingConfig.domain" [class.sort-selected]="sortingSelection.sort_by === 'domain'">{{ 'Domain' }}</th>
                                    <th class="sort-enabled" (click)="onSortColumn('type')" [ngClass]="sortingConfig.type" [class.sort-selected]="sortingSelection.sort_by === 'type'">{{ 'Type' }}</th>
                                    <th class="sort-enabled" (click)="onSortColumn('device_type')" [ngClass]="sortingConfig.device_type" [class.sort-selected]="sortingSelection.sort_by === 'device_type'">{{ 'Device Type' }}</th>
                                    <th class="sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">{{ 'Status' }}</th>
                                    <th class="text-left">{{ 'Updated By' }}</th>
                                    <th class="text-center">{{ 'Action' }}</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(siteDomain$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.id ? row.id: '-' }}</td>
                                    <td class="text-left">{{ row.domain ? row.domain: '-' }}</td>
                                    <td class="text-left">{{ row.type_name ? row.type_name: '-' }}</td>
                                    <td class="text-left">{{ row.device_type_name ? row.device_type_name: '-' }}</td>
                                    <td class="text-center">
                                        <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ row.status_name }}</span>
                                    </td>
                                    <td class="text-left">
                                        {{ row.updated_by }}<br />
                                        <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        <button *ngIf="canEditSiteDomain" matTooltip="{{ 'Edit' }}" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-edit"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                            [directionLinks]="true" [boundaryLinks]="true" [rotate]="true"
                            [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                {{ 'Showing' }} {{pagination.from}} {{ 'to' }} {{pagination.to}} {{ 'of' }} {{ pagination.total | number : '1.0' }} {{ 'records' }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>

