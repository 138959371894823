import { WithdrawDataService } from './../../views/pages/apps/general/withdrawals/services/withdraw-data.service';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { AdjustWallet } from '@core/models/adjust-wallet.model';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { WithdrawEntityService } from './../../views/pages/apps/general/withdrawals/services/withdraw-entity.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { Dropdown } from '@core/models/dropdown.model';
import moment from 'moment';
import { DepositEntityService } from '@views/pages/apps/general/deposits/services/deposit-entity.service';

@Injectable()
export class TransactionHttpService {

  currentUserId = JSON.parse(localStorage.getItem('user_data')) ? JSON.parse(localStorage.getItem('user_data')).id : 0; // TODO: Refine and should not be in localStorage
  messages$ = new Subject<any[]>();
  takeoverBy = {
    userId: null
  };

  dateTimePickerLocale = {format: 'YYYY-MM-DD HH:mm:ss', firstDay: 1};

  @Output() confirmed = new EventEmitter<boolean>();
  dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';
  dateFormat = this.dateTimeFormat.split(' ')[0];
  timeFormat = this.dateTimeFormat.split(' ')[1];
  ranges: any = {
    Today: [this.getToday().from, this.getToday().to],
    'Last 24 Hours': [this.getLast24Hours().from, this.getLast24Hours().to],
    Yesterday: [this.getYesterday().from, this.getYesterday().to],
    'This Week': [this.getThisWeek().from, this.getThisWeek().to],
    'Last Week': [this.getLastWeek().from, this.getLastWeek().to],
    'Last 7 Days': [this.getLast7Days().from, this.getLast7Days().to],
    'This Month': [this.getThisMonth().from, this.getThisMonth().to],
    'Last Month': [this.getLastMonth().from, this.getLastMonth().to],
    'Last 30 Days': [this.getLast30Days().from, this.getLast30Days().to]
  };

  constructor(
    private http: HttpClient,
    private withdrawEntityService: WithdrawEntityService,
    private depositEntityService: DepositEntityService,
    private withdrawDataService: WithdrawDataService,
    private datePipe: DatePipe
  ) { }

  adjustWallet(memberId, data: AdjustWallet) {
    return this.http.put<ApiResponse>(`/member/${memberId}/main-wallet`, data);
  }

  getTransactionType() {
    return this.http.get<ApiResponse>(`/transaction/types`).pipe(
      map(res => res.data)
    );
  }

  getPaymentMethods(type: number, is_crypto: number): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/paymentmethod?type=${type}&is_crypto=${is_crypto}`).pipe(
      map(res => res.data.rows)
    );
  }

  getToday() {
    return {
      from: this.datePipe.transform(moment(), this.dateFormat) + ` ` + this.datePipe.transform(moment().startOf('days'), this.timeFormat),
      to: this.datePipe.transform(moment(), this.dateFormat) + ` ` + this.datePipe.transform(moment().endOf('days'), this.timeFormat)
    };
  }

  getLastHours() {
    return {
      from: this.datePipe.transform(moment().subtract(1, 'hour'), this.dateTimeFormat),
      to: this.datePipe.transform(moment(), this.dateTimeFormat)
    };
  }

  getLast24Hours() {
    return {
      from: this.datePipe.transform(moment().subtract(1, 'days'), this.dateTimeFormat),
      to: this.datePipe.transform(moment(), this.dateTimeFormat)
    };
  }

  getYesterday() {
    return {
      from: this.datePipe.transform(moment().subtract(1, 'days'), this.dateFormat) + ` ` + this.datePipe.transform(moment().startOf('days'), this.timeFormat),
      to: this.datePipe.transform(moment().subtract(1, 'days'), this.dateFormat) + ` ` + this.datePipe.transform(moment().endOf('days'), this.timeFormat)
    };
  }

  getThisWeek() {
    return {
      from: this.datePipe.transform(moment().subtract(1, 'days').weekday(0).add(1, 'weeks').startOf('isoWeek'), this.dateTimeFormat),
      to: this.datePipe.transform(moment().subtract(1, 'days').weekday(0).add(1, 'weeks').endOf('isoWeek'), this.dateTimeFormat)
    };
  }

  getWeekFromToday() {
    return {
      from: this.datePipe.transform(moment().subtract(1, 'weeks').startOf('isoWeek').add(1, 'days'), this.dateTimeFormat),
      to: this.datePipe.transform(moment().endOf('isoWeek').add(1, 'days'), this.dateTimeFormat)
    };
  }

  getLastWeek() {
    return {
      from: this.datePipe.transform(moment().subtract(1, 'days').weekday(0).startOf('isoWeek'), this.dateTimeFormat),
      to: this.datePipe.transform(moment().subtract(1, 'days').weekday(0).endOf('isoWeek'), this.dateTimeFormat)
    };
  }

  getThisMonth() {
    return {
      from: this.datePipe.transform(moment().startOf('month'), this.dateTimeFormat),
      to: this.datePipe.transform(moment().endOf('month'), this.dateTimeFormat)
    };
  }

  getLastMonth() {
    return {
      from: this.datePipe.transform(moment().startOf('month').subtract(1, 'month'), this.dateTimeFormat),
      to: this.datePipe.transform(moment().subtract(1, 'month').endOf('month'), this.dateTimeFormat)
    };
  }

  getLast7Days() {
    return {
      from: this.datePipe.transform(moment().subtract(7, 'days'), this.dateFormat) + ` ` + this.datePipe.transform(moment().startOf('days'), this.timeFormat),
      to: this.datePipe.transform(moment().subtract(1, 'days'), this.dateFormat) + ` ` + this.datePipe.transform(moment().endOf('days'), this.timeFormat)
    };
  }

  getLast30Days() {
    return {
      from: this.datePipe.transform(moment().subtract(30, 'days'), this.dateFormat) + ` ` + this.datePipe.transform(moment().startOf('days'), this.timeFormat),
      to: this.datePipe.transform(moment().subtract(1, 'days'), this.dateFormat) + ` ` + this.datePipe.transform(moment().endOf('days'), this.timeFormat)
    };
  }

  updateDateRange() {
    return {
      Today: [this.getToday().from, this.getToday().to],
      'Last 24 Hours': [this.getLast24Hours().from, this.getLast24Hours().to],
      Yesterday: [this.getYesterday().from, this.getYesterday().to],
      'This Week': [this.getThisWeek().from, this.getThisWeek().to],
      'Last Week': [this.getLastWeek().from, this.getLastWeek().to],
      'Last 7 Days': [this.getLast7Days().from, this.getLast7Days().to],
      'This Month': [this.getThisMonth().from, this.getThisMonth().to],
      'Last Month': [this.getLastMonth().from, this.getLastMonth().to],
      'Last 30 Days': [this.getLast30Days().from, this.getLast30Days().to]
    };
  }

  getDateBrowseReceipt() {
    return {
      from: this.datePipe.transform(moment().subtract(1, 'days'), this.dateFormat) + ` ` + this.datePipe.transform(moment().startOf('days'), this.timeFormat),
      to: this.datePipe.transform(moment(), this.dateFormat) + ` ` + this.datePipe.transform(moment().endOf('days'), this.timeFormat)
    };
  }

  checkCurrentUser(dbFirstData: any, type: string, dbLatestData: any, inputedData: any, dialogRef: any, transaction: string) {
    this.takeoverBy.userId = dbLatestData.bo_user_id;
    if (this.takeoverBy.userId !== this.currentUserId) {
      Swal.fire({
        title: `Someone is on the same ${transaction}!`,
        text: 'Do you to take over?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes and Reload',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          const newUserData = {
            id: dbFirstData.id,
            status: dbLatestData.status === 1 || 4 ? 2 : 1
          };
          this.withdrawEntityService.update(newUserData).subscribe((res: any) => {
            this.withdrawDataService.messages$.next(res.message);
          });
        }
      });
    } else {
      this.updateTransaction(inputedData, type, dialogRef, transaction);
      return this.messages$;
    }
  }

  updateUser(transaction: string, res: any) {
    let updatedWithdrawal = null;
    const data = {
      id: res.id,
      status: res.status === 4 ? 4 : 3
    };
    return updatedWithdrawal = this.withdrawDataService.updateCurrentWithdraw(data);
  }

    updateDepositUser(transaction: string, res: any){
      const data = {
        id: res.id,
        status: 3
      };
      if (transaction === 'deposit') {
        this.depositEntityService.update(data);
        this.depositEntityService.updateOneInCache(data);
      }
    }

  private updateTransaction(data: any, type: string, dialogRef: any, transaction: string) {
    const action = type === 'approve' ? 'approved' : 'rejected';

    Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to ${type} this ${transaction}?`,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        if (transaction === 'withdrawal') {
          this.withdrawEntityService.update(data).pipe(
            tap((row: any) => {
              return this.messages$.next(row.message);
              dialogRef.close();
            })
          ).subscribe();
          this.withdrawEntityService.updateOneInCache(data);
        }
      }
    });
  }
}
