import { EventEmitterService } from '@core/services/event-emitter.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';

import { Component, OnInit, AfterViewInit, QueryList, ViewChildren, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { map, tap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { BankReceipt } from '@core/models/bank-receipt.model';
import { BankReceiptStatus } from '@core/enums/bank-receipt.enum';
import { BankReceiptDataService } from '../../services/bank-receipt-data.service';
import { BankReceiptEntityService } from '../../services/bank-receipt-entity.service';
import { Deposit } from '@core/models/deposit.model';
import { MerchantBank } from '@core/models/merchant-bank.model';
import { DepositDataService } from '@views/pages/apps/general/deposits/services/deposit-data.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import moment from 'moment';

@Component({
  selector: 'kt-bank-receipt-browser',
  templateUrl: './bank-receipt-browser.component.html',
  styleUrls: ['./bank-receipt-browser.component.scss']
})
export class BankReceiptBrowserDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChildren(OwlDateTimeInputDirective) datePicker: QueryList<OwlDateTimeInputDirective<any>>;
  form: FormGroup;
  status = BankReceiptStatus;
  bankReceipts$: Observable<BankReceipt[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = ``;
  loaded: boolean;
  messages$ = this.depositDataService.messages$;
  localGMT = (new Date().toString().split('GMT')[1]).split(' (')[0];
  perPageDropdown = this.dropdownHttpService.perPage;
  dateTimeStack = {
    start_date: null,
    end_date: null
  };
  invalid_receipt = false;

  private subscription = new Subscription();
  private datePickerSubscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { merchantBankDetails: MerchantBank, depositDetails: Deposit },
    private loadingBar: LoadingBarService,
    public dialogRef: MatDialogRef<BankReceiptBrowserDialogComponent>,
    private datePipe: DatePipe,
    private transactionHttpService: TransactionHttpService,
    private depositDataService: DepositDataService,
    private bankReceiptDataService: BankReceiptDataService,
    private bankReceiptEntityService: BankReceiptEntityService,
    private dropdownHttpService: DropdownHttpService,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit() {
    this.dateTimeStack = {
      start_date: new Date(this.transactionHttpService.getDateBrowseReceipt().from),
      end_date: new Date(this.transactionHttpService.getDateBrowseReceipt().to)
    };
    this.formInit();
    this.pagination = this.bankReceiptDataService.pagination;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.datePickerSubscription = forkJoin([
      this.buildDatePicker(0, 'start_date'),
      this.buildDatePicker(1, 'end_date')
    ]).subscribe();
  }

  onBrowse() {
    this.onSubmit();
  }

  onSelectReceipt(receipt: BankReceipt) {
    const depositDetails = this.data.depositDetails;

    if (depositDetails.currency !== receipt.currency) {
      this.invalid_receipt = true;
      this.messages$.next(['Invalid Bank Receipt']);
      return;
    }

    const bankReceipt = {
      remarks: depositDetails.remarks ? depositDetails.remarks : '-',
      status: depositDetails.status,
      bank_receipt_id: receipt.id,
    };
    sessionStorage.setItem('bank_receipt_id', receipt.id.toString());
    sessionStorage.setItem('bank_receipt_currency', receipt.currency);
    this.bankReceiptDataService.getReceipt(receipt.id).pipe(
      tap(res => {
        this.eventEmitterService.onUpdateSelectedReceipt(res);
        this.bankReceiptDataService.selectedReceipt$.next(res);
      }),
    ).subscribe();
    this.dialogRef.close();
  }

  onCloseDialog(event?: Event) {
    this.invalid_receipt ?
      this.invalid_receipt = false :
      this.dialogRef.close();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.bankReceipts$ = this.bankReceiptDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.bankReceiptDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }
  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.toArray()[index].valueChange.pipe(
      map(res => this.datePipe.transform(res, this.transactionHttpService.dateTimeFormat)),
      tap(date => {
        this.form.patchValue({ [formKey]: date });
      })
    );
  }

  private onSubmit() {
    this.loaded = false;
    let data = { ...this.form.value };
    data.start_date = moment(this.form.value.start_date).utc().format('YYYY-MM-DD HH:mm:00');
    data.end_date = moment(this.form.value.end_date).utc().format('YYYY-MM-DD HH:mm:59');
    data.transaction_type = 1;
    data.status = 1;
    data.account_name = this.data.merchantBankDetails.account_name;
    data.account_number = this.data.merchantBankDetails.account_number;
    // data.credit = this.data.depositDetails.confirmed_amount;
    data = this.filterFormFields(data);
    this.params = Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&');
    data = Object.keys(data).map(key => key + '=' + encodeURIComponent(data[key])).join('&');
    this.bankReceipts$ = this.bankReceiptDataService.getWithQuery(`?${data}`).pipe(
      tap(res => {
        this.loaded = true;
        this.pagination = this.bankReceiptDataService.pagination;
      })
    );
    // this.subscription = this.bankReceipts$.subscribe();
  }

  private formInit() {
    const merchantAccountName = this.data.merchantBankDetails.account_name;
    const merchantAccountNumber = this.data.merchantBankDetails.account_number;
    const merchantBankName = this.data.merchantBankDetails.bank_name;
    const depositAmount = this.data.depositDetails.confirmed_amount;
    const { from, to } = this.transactionHttpService.getDateBrowseReceipt();
    this.form = new FormGroup({
      bank_name: new FormControl(merchantBankName, [Validators.required]),
      account_name: new FormControl(merchantAccountName, [Validators.required]),
      account_number: new FormControl(merchantAccountNumber, [Validators.required]),
      keyword: new FormControl(null),
      credit: new FormControl(depositAmount, [Validators.required]),
      date_time_type: new FormControl(1),
      start_date: new FormControl(from, [Validators.required]),
      end_date: new FormControl(to, [Validators.required]),
    });
    this.onSubmit();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null) ? fields[key] = formData[key] : key);
    return fields;
  }
}
