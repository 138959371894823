<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right mb-2">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Content Code:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="code" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Promo Code:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="promo_code" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Content Categories:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="category_id">
                                        <option value="all">All</option>
                                        <option [value]="value.id" *ngFor="let value of dropdownCategory">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="status">
                                        <option value="all"> All </option>
                                        <ng-container *ngFor="let item of status | keyvalue">
                                            <option [value]="item.value" *ngIf="!(+item.key >= 0)">{{ item.key }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Locale:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="language">
                                        <option value="all">All</option>
                                        <option [value]="value.id" *ngFor="let value of dropdownLocales">
                                            {{ value.code }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Type' | translate }}:</label>
                                </div>
                                <div class="col-2 kt-form__control">
                                    <select class="form-control" formControlName="type">
                                        <option [value]="value.id" *ngFor="let value of dropdown.contentType">
                                            {{ value.name | translate }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Date/Time' | translate }}:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <select  class="form-control" formControlName="date_type">
                                                <ng-container *ngFor="let value of dropdown.dateTypes">
                                                    <option [value]="value.value">{{ value.name | translate }}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true" [opens]="'left'"
                                            [alwaysShowCalendars]="true" [ranges]="ranges"
                                            [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                            [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                        ngxDaterangepickerMd/>
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Title:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="title" placeholder="Search" class="form-control">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canCreatePromotionContent" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th class="text-center">Content Code</th>
                                    <th class="text-center">Promo Code</th>
                                    <th>Content Categories</th>
                                    <th>Type</th>
                                    <th>Member Visibility</th>
                                    <th>Position</th>
                                    <th>Applications</th>
                                    <th>Locales</th>
                                    <th>Title</th>
                                    <th class="text-center">Status</th>
                                    <th class="date-column">Created By</th>
                                    <th class="date-column">Updated By</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(promotionContents$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.id }}</td>
                                    <td class="text-center">{{ row.code }}</td>
                                    <td class="text-center">{{ row.promotion_code }}</td>
                                    <td>
                                        <!-- <span *ngFor="let id of row.category_id; let i = index">
                                            <span *ngIf="i > 0">, </span>
                                            <br *ngIf="i !== 0 && i % 3 === 0">
                                            <span>{{ categoriesDropdownList[id] | uppercase }}</span>
                                        </span> FOR FUTURE REFERENCE -->
                                        {{ row.categories }}
                                    </td>
                                    <td>{{ row.content_type | titlecase }}</td>
                                    <td>{{ row.member_visibility_name }}</td>
                                    <td>{{ row.position }}</td>
                                    <td><a (click)='onPromotionApplication(row)' class="text-primary">{{ row.applications }}</a></td>
                                    <td>{{ row.locales }}</td>
                                    <td>
                                        <span *ngIf="ind > 0 && ind !== (row.rewards.length - 1)">,</span>
                                        <ng-container *ngIf="row.title != null else elseIsNull" >
                                            {{ row.title }}<br/>
                                        </ng-container>
                                        <ng-template #elseIsNull>
                                            -
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                    <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                                    </td>
                                    <td>
                                        {{ row.created_by ? row.created_by : 'System' }}<br/>
                                        <span placement="top" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        {{ row.updated_by ? row.updated_by : 'System' }}<br/>
                                        <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        <button *ngIf="canEditPromotionContent" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)" [disabled]="(disabledEdit$ | async)"><i class="fas fa-edit"></i></button>
                                        <button *ngIf="canCheckMemberEligibility && row.promotion_code && row.promotion_code.length > 0" matTooltip="Check Member Eligible" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('promo-eligible', row.id)"><i class="fas fa-stethoscope"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                        </div>
                </div>
            </div>
      </div>
  </div>
</div>
