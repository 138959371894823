<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter Form -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Category:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="category_code" class="form-control">
                                        <option value="all"> All </option>
                                        <option *ngFor="let value of dropdown.categories | async" [value]="value.code">
                                            {{ value.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control">
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="type" class="form-control">
                                        <option value="all"> All </option>
                                        <ng-container *ngFor="let item of dropdown.type| keyvalue">
                                            <option [value]="item.key" *ngIf="+item.key >= 0">{{ item.value }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Action Button -->
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <!-- <div class="dropdown dropdown-inline">
                </div> -->
            </div>

            <!-- Table -->
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <!-- Header -->
                            <thead>
                                <tr>
                                    <th class="sort-enabled" rowspan="2" (click)="onSortColumn('code')" [ngClass]="sortingConfig.code" [class.sort-selected]="sortingSelection.sort_by === 'code'">Code</th>
                                    <th class="sort-enabled" rowspan="2" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                                    <th rowspan="2">Type</th>
                                    <th rowspan="2">Supported Target Type</th>
                                    <th rowspan="2">Category</th>
                                    <th rowspan="2">Currency</th>
                                    <th class="text-center" colspan="4">Maintenance</th>
                                    <th rowspan="2">Status</th>
                                    <th class="sort-enabled" rowspan="2" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                                    <th rowspan="2" class="text-center" *ngIf="AccessQuickLinkSetting">Actions</th>
                                </tr>
                                <tr>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('maintain_from')" [ngClass]="sortingConfig.maintain_from" [class.sort-selected]="sortingSelection.sort_by === 'maintain_from'">From</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('maintain_to')" [ngClass]="sortingConfig.maintain_to" [class.sort-selected]="sortingSelection.sort_by === 'maintain_to'">To</th>
                                    <th>Status</th>
                                    <th>Remarks</th>
                                </tr>
                            </thead>
                            <!-- Body -->
                            <tbody *ngIf="gameProviders$ | async as games">
                                <ng-container *ngFor="let row of games; let i = index">
                                    <tr [ngClass]="i % 2 === 0 ? 'even' : 'odd'">
                                        <td [attr.rowspan]="row.categories.length" class="text-center">{{ row.code }}
                                        </td>
                                        <td [attr.rowspan]="row.categories.length" class="text-center">{{ row.name }}
                                        </td>
                                        <td [attr.rowspan]="row.categories.length" class="text-center">{{ getTypeCode(row.type) }}
                                        </td>
                                        <td [attr.rowspan]="row.categories.length" class="text-center">{{ row.supported_target_type_name }}
                                        </td>
                                        <td class="text-center">
                                            <ng-container *ngIf="canViewMiniGamesList; else elseBlock">
                                                <a  matTooltip="View Mini Games"
                                                    [routerLink]="onSetLink(row.categories[0].code, row.code)"
                                                    (click)="onSetSession(row.categories[0].code, row.code, row.name)">
                                                    {{ row.categories[0].category }}&nbsp;
                                                </a>
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                <span>{{ row.categories[0].category }}&nbsp;</span>
                                            </ng-template>
                                        </td>
                                        <td [attr.rowspan]="row.categories.length" class="text-center">{{ row.currency_code }}
                                        </td>
                                        <td class="text-center">
                                            {{ row.categories[0].maintain_from ? (row.categories[0].maintain_from | timezoneDate: 'YYYY-MM-DD HH:mm') : '-'}}
                                        </td>
                                        <td class="text-center">
                                            {{ row.categories[0].maintain_to ? (row.categories[0].maintain_to | timezoneDate: 'YYYY-MM-DD HH:mm') : '-'}}
                                        </td>
                                        <td class="text-center">
                                            <span
                                                [class]="'kt-badge kt-badge--inline kt-badge--pill kt-badge--wide kt-badge--'+getColorStatusCode(row.categories[0].status)">
                                                {{ row.categories[0].status ? row.categories[0].status : '-' }}
                                            </span>
                                        </td>
                                        <td class="text-center">
                                            {{ row.categories[0].remarks ? row.categories[0].remarks : '-' }}
                                        </td>
                                        <td [attr.rowspan]="row.categories.length" class="text-center">
                                            <div *ngIf="canEditGameProvider || canUpdateGameProviderStatus" class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" (click)="onToggleStatus(row)" [id]="row.id" [checked]="row.status === 1 ? true : false">
                                                <label class="custom-control-label" [for]="row.id"></label>
                                            </div>
                                        </td>
                                        <td>
                                            {{ row.updated_by ? row.updated_by : 'System' }} <br />
                                            <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                            <ng-template #updatedDateTime>
                                                {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                            </ng-template>
                                        </td>
                                        <td *ngIf="AccessQuickLinkSetting" [attr.rowspan]="row.categories.length" class="text-center text-middle">
                                            <button *ngIf="canViewGameProviderDetails" matTooltip="Game Provider Settings"
                                                class="col-6 btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"
                                                (click)="onOpenDialog('edit-maintenance', row)"><i
                                                    class="fas fa-cog"></i></button>

                                            <button *ngIf="canEditPosition" matTooltip="Edit Position"
                                                class="col-6 btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"
                                                (click)="onOpenDialog('edit-position', row)"><i
                                                    class="fas fa-sort"></i></button>
                                            
                                            <button matTooltip="Quick Link Settings"
                                            class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"
                                            (click)="onOpenDialog('quick-link-settings', row)" *ngIf="AccessQuickLinkSetting"><i
                                                class="fas fa-cogs"></i></button>
                                        </td>
                                    </tr>
                                    <!-- If game provider have multiple categories -->
                                    <ng-container *ngIf="row.categories.length > 1">
                                        <tr *ngFor="let category of row.categories | slice:1"
                                            [ngClass]="i % 2 === 0 ? 'even' : 'odd'">
                                            <td class="text-center">
                                                <ng-container *ngIf="canViewMiniGamesList; else elseBlock">
                                                    <a matTooltip="View Mini Games"
                                                        [routerLink]="onSetLink(category.code, row.code)"
                                                        (click)="onSetSession(category.code, row.code, row.name)">
                                                        {{ category.category }}&nbsp;</a>
                                                </ng-container>
                                                <ng-template #elseBlock>
                                                    <span>{{ category.category }}&nbsp;</span>
                                                </ng-template>
                                            </td>
                                            <td class="text-center">
                                                {{ category.maintain_from ? (category.maintain_from | timezoneDate: 'YYYY-MM-DD HH:mm') : '-'}}
                                            </td>
                                            <td class="text-center">
                                                {{ category.maintain_to ? (category.maintain_to | timezoneDate: 'YYYY-MM-DD HH:mm') : '-'}}
                                            </td>
                                            <td class="text-center">
                                                <span
                                                    [class]="'kt-badge kt-badge--inline kt-badge--pill kt-badge--wide kt-badge--'+getColorStatusCode(category.status)">
                                                    {{ category.status ? category.status : '-' }}
                                                </span>
                                            </td>
                                            <td class="text-center">
                                                {{ category.remarks ? category.remarks : '-'}}
                                            </td>
                                            <td>
                                                {{ row.updated_by ? row.updated_by : 'System' }} <br />
                                                <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                                <ng-template #updatedDateTime>
                                                    {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                                </ng-template>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <!-- Pagination -->
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                            [directionLinks]="true" [boundaryLinks]="true" [rotate]="true"
                            [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                                class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number :
                                '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="reload()"></kt-swal-alert>
