import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LeadRemarks } from '@core/models/lead-remarks.model';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LeadRemarksDataService } from './services/lead-remarks-data.service';
import { LeadRemarksEntityService } from './services/lead-remarks-entity.service';
import { TimezoneDatePipe } from '@core/pipes/timezone-date.pipe';
import { ShowEvidenceComponent } from '../my-leads/dialogs/show-evidence/show-evidence.component';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  templateUrl: './lead-remarks.component.html',
  styleUrls: ['./lead-remarks.component.scss']
})
export class LeadRemarksComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;

  dropdown = {
    timezones: this.dropdownHttpService.timezones,
    currencies: this.dropdownHttpService.currencies,
    telemarketer: this.dropdownHttpService.telemarketer,
    perPage: this.dropdownHttpService.perPage,
  };

  messages$ = this.leadRemarksDataService.messages$;

  LeadRemarks$: Observable<LeadRemarks[]>;
  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  buttonLoading = false;

  pagination: Pagination;
  maxSize = 5;
  pageSize = 30;
  page = 1;
  params = '';

  currentContact = '';
  selectedContactType = 'Mobile';

  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;

  telemarketerList = [];
  telemarketerDropdownSettings = {
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown',
    primaryKey: 'username',
    labelKey: 'username',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false
  };
  selectedTelemarketer = [];

  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneslist = JSON.parse(localStorage.getItem('timezones'));
  timezonesDropdownSettings = {
    singleSelection: true,
    text: 'Please Select',
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    classes: 'dropdown clear-all-disabled',
    maxHeight: 150,
    primaryKey: 'timezone',
    labelKey: 'offset',
    noDataLabel: '',
    showCheckbox: false
  };
  timezonesDropdownList = this.timezoneslist;
  timezonesSelectedItems = [];
  selectedTimezone: any;

  default_sort_by = 'lead_id';
  default_sort_order = 'desc';

  sortingConfig = {
    'lead_id': 'desc',
    'currency': 'desc',
    'telemarketer': 'desc',
    'username': 'desc',
    'name': 'desc',
    'mobile': 'desc',
    'remark': 'desc',
    'remark_date': 'desc',
    'registration_created_at': 'desc',
  };

  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order
  };

  dataLength: number;
  access$ = this.authHttpService.getUserAccess(13, 'All Leads');
  isAdmin: boolean = JSON.parse(localStorage.getItem('user_data')).is_admin;

  // permissions
  // leadRemarksUseNewPermissions: boolean;
  canExportLeadRemarks: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private refreshSubcription = new Subscription();

  constructor(
    public loadingBar: LoadingBarService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private leadRemarksDataService: LeadRemarksDataService,
    private dropdownHttpService: DropdownHttpService,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private transactionHttpService: TransactionHttpService,
    private timeZoneDate: TimezoneDatePipe,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.pagination = this.leadRemarksDataService.pagination;

    this.dropdownHttpService.telemarketer.subscribe(
      res => {
        this.telemarketerList = res;
      }
    )

    this.ranges = this.transactionHttpService.ranges;
    this.timezonesSelectedItems = [this.timezonesDropdownList.find(x => x.timezone === this.timezone)];
    this.selectedTimezone = this.timezonesSelectedItems;

    // this.leadRemarksUseNewPermissions = this.appPermissionService.isSectionUsingNewPermissions('lead_remarks');
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canExportLeadRemarks = appPermissions.export_lead_remarks;
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onSortColumn(property: string) {

    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        tap((data) => {
          if (data['start_date_time']) {
            data['start_date_time'] = moment(data['start_date_time']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
            data['end_date_time'] = moment(data['end_date_time']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          }

          this.params = Object.keys(data)
            .map((key) => key + "=" + data[key])
            .join("&");
          this.sortingConfig[this.sortingSelection.sort_by] = 'desc';
          this.sortingSelection.sort_by = this.default_sort_by;
          this.sortingSelection.sort_order = this.default_sort_order;
          this.sortingConfig[this.default_sort_by] = this.default_sort_order;
          this.page = 1;
          this.onViewPageBy();
        })
      )
      .subscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : "";
    this.loadingBar.start();
    return (this.LeadRemarks$ = this.leadRemarksDataService
      .getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`)
      .pipe(
        tap(res => {
          this.dataLength = res.length;
          this.pagination = this.leadRemarksDataService.pagination;
          this.loadingBar.complete();
          this.onSearch = true;
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
        }),
        catchError((err) => {
          this.dataLength = 0;
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
          this.loadingBar.complete();
          return throwError(err);
        })
      ));

  }

  onExport() {
    this.buttonLoading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(data['timezone'], true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        data['paginate'] = false;

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start(); var filename = 'LeadRemarks_' + moment(this.form.get('start_date_time').value).format('YYYYMMDD') + '-' + moment(this.form.get('end_date_time').value).format('YYYYMMDD') + '.xlsx';
        return this.leadRemarksDataService.export(`?${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.buttonLoading = false;
          }),
          catchError((err) => {
            this.buttonLoading = false;
            this.loadingBar.complete();
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.selectedTelemarketer = [];
    this.selectedContactType = 'Mobile';
    this.form.patchValue({
      telemarketer: null,
      username: null,
      mobile: null,
      email: null,
      currency_id: 'all',
      registration_site: null,
      date_type: 'registration_date',
      start_date_time: null,
      end_date_time: null,
      defaultDate: null,
      keyword: null,
      lead_id: null,
      timezone: this.timezone,
    })
    this.onSubmit(true);
  }

  onContactType(label: string) {
    this.selectedContactType = label;
    this.contactChanges();
    this.cdr.detectChanges();
  }

  onContact(event: Event) {
    this.currentContact = (event.target as HTMLInputElement).value;
    this.contactChanges();
    this.cdr.detectChanges();
  }

  private contactChanges() {
    if (this.selectedContactType !== '' || this.selectedContactType !== null) {

      if (this.selectedContactType === 'Mobile') {
        this.form.patchValue({
          [this.selectedContactType.toLocaleLowerCase()]: this.currentContact,
          email: null,
        });
      }
      if (this.selectedContactType === 'Email') {
        this.form.patchValue({
          mobile: null,
          [this.selectedContactType.toLocaleLowerCase()]: this.currentContact,
        });
      }
    }
  }

  onClearDate() {
    if (this.form.value.start_date_time !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private formInit() {
    this.form = new FormGroup({
      telemarketer: new FormControl(null),
      username: new FormControl(null),
      mobile: new FormControl(null),
      email: new FormControl(null),
      currency_id: new FormControl('all'),
      registration_site: new FormControl(null),
      date_type: new FormControl('registration_date'),
      start_date_time: new FormControl(null),
      end_date_time: new FormControl(null),
      defaultDate: new FormControl(null),
      keyword: new FormControl(null),
      lead_id: new FormControl(null),
      timezone: new FormControl(this.timezone),
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && formData[key] !== false && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  onChangeTimezone(item: any) {
    // To disable deselect option on dropdown, must remain one option on dropdown
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezonesSelectedItems = this.timezonesDropdownList.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }

  timezoneDateWithTimezone(date: any, timezone: any, format: any) {
    return this.timeZoneDate.transformWithTimezone(date, timezone, format);
  }

  getUrl(site: string) {
    let url = new URL(site);
    return url.host;
  }

  urlIsValid(url: string) {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  }

  onOpenDialog(data?: any) {
    this.openDialogBy(ShowEvidenceComponent, { evidences: data });
  }

  private openDialogBy(componentRef: any, data?: { evidences?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      height: 'auto',
      data: {
        evidences: data.evidences
      },
      autoFocus: false
    });
  }

}
