<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <!-- Action Title -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Action Title:</label>
                                </div>
                                <div class="col-md-3 kt-form__control">
                                    <input type="text" formControlName="action_title" placeholder="Search" class="form-control">
                                </div>
                                <!-- Display Title -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Display Title:</label>
                                </div>
                                <div class="col-md-3 kt-form__control">
                                    <input type="text" formControlName="display_title" placeholder="Search" class="form-control">
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <!-- Section -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Section:</label>
                                </div>
                                <div class="col-md-3 kt-form__control">
                                    <select formControlName="section_id" class="form-control" (change)="onSectionChange()">
                                        <option value="null"> Please Select </option>
                                        <option *ngFor="let section of allSection" [value]="section.id">{{ section.name }}</option>
                                    </select>
                                </div>
                                <!-- Sub Section -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Sub Section:</label>
                                </div>
                                <div class="col-md-2 kt-form__control pr-0">
                                    <select class="form-control" [disabled]="subSectionLvlOne.length == 0" (change)="onSubSectionChange(1, $event)">
                                        <option value="null"> Please Select </option>
                                        <option *ngFor="let section of subSectionLvlOne" [value]="section.id">{{ section.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-2 kt-form__control pl-0">
                                    <select class="form-control" [disabled]="subSectionLvlTwo.length == 0" (change)="onSubSectionChange(2, $event)">
                                        <option value="null"> Please Select </option>
                                        <option *ngFor="let section of subSectionLvlTwo" [value]="section.id">{{ section.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingSelection.sort_by == 'id' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">ID</th>
                                    <th class="sort-enabled" (click)="onSortColumn('action_title')" [ngClass]="sortingSelection.sort_by == 'action_title' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Action Title</th>
                                    <th class="sort-enabled" (click)="onSortColumn('display_title')" [ngClass]="sortingSelection.sort_by == 'display_title' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Display Title</th>
                                    <th class="sort-enabled text-center" (click)="onSortColumn('description')" [ngClass]="sortingSelection.sort_by == 'description' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Description</th>
                                    <th class="sort-enabled text-center" (click)="onSortColumn('section')" [ngClass]="sortingSelection.sort_by == 'section' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Section</th>
                                    <th class="sort-enabled text-center" (click)="onSortColumn('created_by')" [ngClass]="sortingSelection.sort_by == 'created_by' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Created By</th>
                                    <th class="sort-enabled text-center" (click)="onSortColumn('updated_by')" [ngClass]="sortingSelection.sort_by == 'updated_by' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Updated By</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of permissions">
                                    <td>{{ row.id }}</td>
                                    <td>{{ row.action_title }}</td>
                                    <td>{{ row.display_title }}</td>
                                    <td>{{ row.description == '' ? '-' : row.description }}</td>
                                    <td>
                                        <ng-container *ngFor="let section of row.sections">
                                            {{ section }}<br>
                                        </ng-container>
                                    </td>
                                    <td>
                                        {{ row.created_by == '' ? 'System' : row.created_by }}<br>
                                        <span placement="top" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        {{ row.updated_by == '' ? 'System' : row.updated_by }}<br>
                                        <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        <button *ngIf="canEditPermission" matTooltip="Edit Permissions" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog(row.id)"><i class="fas fa-edit"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
