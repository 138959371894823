import { RecoveryPromotion } from '@core/models/recovery-promotion.model';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Pagination } from '@core/models/pagination.model';

@Injectable()
export class RecoveryPromotionDataService extends DefaultDataService<RecoveryPromotion> {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('RecoveryPromotion', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<RecoveryPromotion[]> {
    return this.http.get<ApiResponse>('/recoverypromotion/settings').pipe(
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<RecoveryPromotion[]>{
    return this.http.get<ApiResponse>(`/recoverypromotion/settings${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(recoveryPromotion: RecoveryPromotion): Observable<RecoveryPromotion>{
    return this.http.post<ApiResponse>(`/recoverypromotion/settings`, recoveryPromotion).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  updateCategory(id: number, recoveryPromotion: RecoveryPromotion){
    return this.http.put<ApiResponse>(`/recoverypromotion/settings/${id}`, recoveryPromotion).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  toggleStatus(id: number, param: any) {
    return this.http.put<ApiResponse>(`/recoverypromotion/settings/statusupdate?id=${id}&status=${param.status}`, param).pipe(
      tap(res =>  this.messages$.next(res.message))
    );
  }

  duplicate(id: number) {
    return this.http.post<ApiResponse>(`/recoverypromotion/settings/duplicate?id=${id}`, null).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

}
