<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right mb-2">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Tag Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select  class="form-control" formControlName="status">
                                      <option [value]="null" [disabled]="true">Please Select</option>
                                      <option value="all">All</option>
                                      <ng-container *ngFor="let value of dropdown.statuses; let i = index;">
                                        <option [value]="i">{{ value }}</option>
                                      </ng-container>
                                    </select>
                                  </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <div class="dropdown dropdown-inline">
                    <button *ngIf="canCreateGameTag" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')">
                        <i class="fas fa-plus"></i>Create
                    </button>
                </div>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="text-left sort-enabled" (click)="onSortColumn('name')"
                                    [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Tag Name</th>
                                    <th class="text-left sort-enabled" (click)="onSortColumn('position')"
                                    [ngClass]="sortingConfig.position" [class.sort-selected]="sortingSelection.sort_by === 'position'">Position</th>
                                    <th class="text-center">Status</th>
                                    <th class="text-left sort-enabled" (click)="onSortColumn('created_at')"
                                    [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created By</th>
                                    <th class="text-left sort-enabled" (click)="onSortColumn('updated_at')"
                                    [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated By</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(gameTags$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td class="text-left">{{ row.name }}</td>
                                    <td class="text-left">{{ row.position }}</td>
                                    <td class="text-center">
                                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ row.status_name }}</span>
                                    </td>
                                    <td>
                                      {{ row.created_by ? row.created_by : 'System' }} <br />
                                      <span placement="top" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | date:'yyyy-MM-dd HH:mm' }}</span>
                                      <ng-template #createdDateTime>
                                        {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                      </ng-template>
                                    </td>
                                    <td>
                                        {{ row.updated_by ? row.updated_by : 'System' }} <br />
                                        <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | date:'yyyy-MM-dd HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                  </td>
                                    <td class="text-center text-middle">
                                        <button *ngIf="canEditGameTag" title="Edit"
                                            class="col-6 btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"
                                            (click)="onOpenDialog('edit', row.id)">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                        <button *ngIf="canViewGameTagDetails" title="Assign Games" 
                                            class="col-6 btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"
                                            [routerLink]="onSetLink(row.id)" (click)="onSetSession(row.id)">
                                            <i class="fas fa-gamepad"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="pagination.total"
                        (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                            Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
