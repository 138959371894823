// Angular
import { ChangeDetectorRef,ElementRef, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LeadRemark } from "@core/models/lead-remark.model";
// Service
import { AllLeadsDataService } from '../../services/all-leads-data.service';
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { Subscription } from 'rxjs';
import { ShowEvidenceComponent } from '../../../my-leads/dialogs/show-evidence/show-evidence.component';  
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AppPermissionService } from "@core/services/app-permission.service";

@Component({
  //selector: 'app-lead-remark',
  templateUrl: './show-remark.component.html',
  styleUrls: ['./show-remark.component.scss'],
})
export class ShowRemarkComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;

  remark$: Observable<LeadRemark[]>;
  messages$ = this.AllLeadsDataService.messages$;
  loading = false;
  remarkLength = 0;

  form: FormGroup;
  buttonLoading = false;
  refreshStatus: boolean;

  // permissions
  canEditLeadJustification: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { leadID: any, leadJustification?: any },
    public dialogRef: MatDialogRef<ShowRemarkComponent>,
    private AllLeadsDataService: AllLeadsDataService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.onReload();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canEditLeadJustification = appPermissions.edit_lead_justification;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onReload() {
    const param = this.data.leadJustification ? this.data.leadID.lead_id : this.data.leadID;
    this.remark$ = this.AllLeadsDataService.getRemarkById(param).pipe(
      tap(res => {
        this.loading = false;
        this.remarkLength = res.rows.length;
      }),
      map(res => res.rows),
      catchError(error => {
        this.loading = false;
        this.remarkLength = 0
        // This detect changes is needed to update the table handler
        this.cdr.detectChanges();
        throw (error);
      })
    )
  }

  onOpenDialog(data?: any) {
    this.openDialogBy(ShowEvidenceComponent, { evidences: data });
  }

  
  private openDialogBy(componentRef: any, data?: { evidences?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      height: 'auto',
      data: {
        evidences: data.evidences
      },
      autoFocus: false
    });    
  }

  onSave(lead: any, type: string) {
    if (this.form.valid) {
      this.buttonLoading = true;
      const data = {
        ...this.form.value,
        status: (type === 'approve') ? 0 : 7, // 0:Closed, 7:Reject Justification
      };

      const updateStatus = () => {
        this.subscription = this.AllLeadsDataService.updateLeadJustificationStatus(lead.lead_id, data).pipe(
          tap((res: any) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.dialogRef.close(true);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
      };
      
      if (lead.lead_deposit < lead.lead_target_amount && type === 'approve') {
        Swal.fire({
          title: '<div class="text-center">Confirmation</div>',
          html: '<div class="text-center">Member lead has not achieved the target deposit amount. Are you sure you want to approve this justification?<div><small>Target Amount Progression: ' + lead.total_deposit + '</small></div></div>',
          showCancelButton: true,
          showConfirmButton: true,
          reverseButtons: true,
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Yes',
          icon: 'warning',
          confirmButtonColor: '#0abb87',
          cancelButtonColor: '#d33',
        }).then(result => {
          if (result.value) {
            if (type === 'approve') {
              updateStatus();
            } 
          }
          this.buttonLoading = false;
        });
      } else {
        updateStatus();
      }
      this.refreshStatus = true;
    }
  }
 
  private formInit() {
    this.form = new FormGroup({
      remarks: new FormControl(null, [Validators.required]),
    });
  }
}
