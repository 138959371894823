<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form" *ngIf="data.mode !== 'reset'; else passwordForm">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Operator</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-6 form-group required">
                    <label class="control-label">Role (Version 1.0) <span class="text-danger">*</span></label>
                    <kt-dropdown-wo-lazyload
                      [form] = 'form'
                      [dropdownList] = 'rolesDropdownList'
                      [dropdownSettings] = 'rolesDropdownSettings'
                      [formName] = "'role_id'"
                      [selectionAttributes] = "'id'"
                      [selectedItems] = 'rolesSelectedItems'
                      (selectedItemsChanged) = 'onRoleChange($event)'>
                    </kt-dropdown-wo-lazyload>
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">New Role (Version 2.0) <span *ngIf="form.get('authorized_role_version').value == 2" class="text-danger">*</span></label>
                    <kt-dropdown-wo-lazyload
                      [form] = 'form'
                      [dropdownList] = 'applicationRolesDropdownList'
                      [dropdownSettings] = 'applicationRolesDropdownSettings'
                      [formName] = "'application_role_id'"
                      [selectionAttributes] = "'id'"
                      [selectedItems] = 'applicationRolesSelectedItems'>
                    </kt-dropdown-wo-lazyload>
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Authorized Role Version <span class="text-danger">*</span></label>
                    <select
                      formControlName="authorized_role_version"
                      class="form-control"
                      (change)="onAuthorizedRoleVersionSelectChange($event)"
                    >
                      <option [value]="null" disabled>Please Select</option>
                      <option *ngFor="let value of authorizedRoleVersions;" [value]="value.id">{{ value.name }}</option>
                    </select>
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Currency <span class="text-danger">*</span></label>
                    <kt-dropdown-wo-lazyload
                      [form] = 'form'
                      [dropdownList] = 'dropdown.currencies'
                      [dropdownSettings] = 'currenciesDropdownSettings'
                      [formName] = "'currency_ids'"
                      [selectionAttributes] = "'id'"
                      [selectedItems] = 'currenciesSelectedItems'>
                    </kt-dropdown-wo-lazyload>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Timezone <span class="text-danger">*</span></label>
                  <kt-dropdown-wo-lazyload
                    [form] = 'form'
                    [dropdownList] = 'dropdown.timezones'
                    [dropdownSettings] = 'timezonesDropdownSettings'
                    [formName] = "'timezone'"
                    [selectionAttributes] = "'timezone'"
                    [selectedItems] = 'timezonesSelectedItems'>
                  </kt-dropdown-wo-lazyload>
              </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Username <span class="text-danger">*</span></label>
                    <input formControlName="username" (input)="toLowerCaseInput('username', $event)" type="text" class="col-12 form-control" autocomplete="new-username"/>
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Password <span class="text-danger" *ngIf="data.mode == 'create'">*</span></label>
                    <div class="input-group">
                      <input (keyup)="onValidPassword()" [type]="passwordInput.type" formControlName="password"  class="form-control" autocomplete="new-password"/>
                      <div class="input-group-append">
                          <button [disabled]="data.mode === 'edit' && !canResetOperatorPassword" type="button" (click)="onPasswordMask()" class="input-group-text btn btn-sm btn-icon btn-icon-sm">
                              <i [class]="passwordInput.icon"></i>
                          </button>
                      </div>
                    </div>
                    <div class="w-100 d-flex align-items-center">
                      <input type="checkbox" formControlName="force_change_password" id="force_change_password">
                      &nbsp;<label class="m-0" for="force_change_password">Require password reset on next login</label>&nbsp;
                      <i class="fas fa-info-circle" style="color:#000000; margin-bottom: -0.5%;" matTooltip="Check this box to force user to reset their password upon their next login. This will expired the password you set now."></i>
                    </div>
                    <label *ngIf="!passwordInput.isValidPassword">
                      <mat-error>
                        <strong>Password must be at least 8 characters long, contain uppercase and lowercase letters, a number, and a special character from the allowed set: {{ '! @ # $ % ^ & * ( ) _ + - = { } [ ] | \ : ; "'}} ' {{ '< > , . ? / ~ `' }}</strong>
                      </mat-error>
                    </label>
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Name <span class="text-danger">*</span></label>
                    <input formControlName="name"  type="text" class="col-12 form-control" autocomplete="new-name" />
                </div>
                <div class="col-md-6 form-group">
                    <label class="control-label">Status <span class="text-danger">*</span></label>
                    <select formControlName="status" class="form-control">
                        <option *ngFor="let value of statusesDropdownList; let i = index" [value]="i">
                            {{ value }}</option>
                    </select>
                </div>
                <!-- Mobile Access -->
                <div class="col-md-6 form-group">
                  <label class="control-label">Phone Number Access<span class="text-danger">*</span></label>
                  <select formControlName="view_masked_mobile" class="form-control">
                      <option *ngFor="let value of mobileAccessDropDownList; let i = index" [value]="i">
                          {{ value | translate }}</option>
                  </select>
                </div>
                <!-- Email Access -->
                <div class="col-md-6 form-group">
                  <label class="control-label">Email Access<span class="text-danger">*</span></label>
                  <select formControlName="view_masked_email" class="form-control">
                      <option *ngFor="let value of emailAccessDropDownList; let i = index" [value]="i">
                          {{ value | translate }}</option>
                  </select>
                </div>
                <!-- Two Factor Authentication -->
                <div class="col-md-6 form-group">
                  <label class="control-label">2FA<span class="text-danger">*</span></label>
                  <select formControlName="two_factor_authentication" class="form-control">
                      <option *ngFor="let value of twoFADropDownList; let i = index" [value]="i">
                          {{ value }}</option>
                  </select>
                </div>
                <ng-container *ngIf="telemarketer">
                  <div class="col-md-12 form-group">
                    <hr>
                  </div>
                  <div class="col-md-6 form-group">
                      <label class="control-label">Accordia Username</label>
                      <input formControlName="accordia_username" type="text" class="col-12 form-control"/>
                  </div>
                  <div class="col-md-6 form-group">
                      <label class="control-label">Accordia Password</label>
                      <div class="input-group">
                        <input [type]="passwordAccordiaInput.type" formControlName="accordia_password"  class="form-control" autocomplete="new-password"/>
                        <div class="input-group-append">
                            <button type="button" (click)="onPasswordAccordiaMask()" class="input-group-text btn btn-sm btn-icon btn-icon-sm">
                                <i [class]="passwordAccordiaInput.icon"></i>
                            </button>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-6 form-group">
                      <label class="control-label">EXT</label>
                      <input formControlName="accordia_ext" type="text" class="col-12 form-control"/>
                  </div>
                </ng-container>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button *ngIf="data.mode === 'edit' ? canEditOperator : canCreateOperator" [isDisabled]="!form.valid || !passwordInput.isValidPassword" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.operator, data.mode)"></kt-submit-button>
        </div>
    </div>
  </form>
</div>

<ng-template #passwordForm>
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Reset Password</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 form-group">
                    <label class="control-label">Operator's Slack Email</label>
                    <input formControlName="email" type="email" class="col-12 form-control">
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button *ngIf="canResetOperatorPassword" [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.operator, data.mode)"></kt-submit-button>
        </div>
    </div>
  </form>
</ng-template>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
