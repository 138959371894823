import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AccountManagementDataService } from '../../services/account-management-data.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { AuthHttpService } from '@core/services/auth-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  templateUrl: './assign-lead-dialog.component.html',
  styleUrls: ['./assign-lead-dialog.component.scss']
})
export class AssignLeadDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  form: FormGroup;
  availableLead = 0;
  accountManagerDropdownSettings = {};
  accountManagerDropdownList = [];
  accountManagerSelectedItems = [];
  private subscription = new Subscription();
  private subscriptions = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  availableLeads = 0;
  getLeadsList = false;

  access$ = this.authHttpService.getUserAccess(13, 'All Leads');
  // permissions
  canAssignLead: boolean;
  params = '';

  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    accountManager: this.dropdownHttpService.accountManager,
    developmentStage: this.dropdownHttpService.developmentStage,
    normalMemberRecoveryType: this.dropdownHttpService.normalMemberRecoveryType,
    vipMemberRecoveryType: this.dropdownHttpService.vipMemberRecoveryType,
    memberRecoveryInactiveDays: this.dropdownHttpService.memberRecoveryInactiveDays,
  };
  min_inactive_days_allowed: any;
  max_inactive_days_allowed: any;

  constructor(
    public dialogRef: MatDialogRef<AssignLeadDialogComponent>,
    private cdr: ChangeDetectorRef,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private dropdownHttpService: DropdownHttpService,
    private AccountManagementDataService: AccountManagementDataService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    if (this.dropdown.currencies.length === 0) {
      this.dropdownHttpService.currencies.subscribe(res => {
        this.dropdown.currencies = res;
      });
    }
    this.formInit();

    // Set min and max validator for inactive days
    this.buttonLoading = true;
    this.AccountManagementDataService.getKpiSettings(this.form.value.development_stage, this.form.value.lead_stage).pipe(
      tap((res: any) => {
        this.min_inactive_days_allowed = res.rows.min_inactive_days;
        this.max_inactive_days_allowed = res.rows.max_inactive_days;
        this.form.controls['min_inactive_days'].setValidators([Validators.min(res.rows.min_inactive_days)]);
        this.form.controls['max_inactive_days'].setValidators([Validators.max(res.rows.max_inactive_days)]);
        this.buttonLoading = false;
        this.form.setErrors(null);
        this.cdr.detectChanges();
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        this.cdr.detectChanges();
        throw error;
      })
    ).subscribe();

    this.accountManagerDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      maxHeight: 200,
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canAssignLead = appPermissions.account_management_assign_leads;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onAssignLead() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });
    const data = {
      ...this.form.value
    };
    Object.keys(data).forEach((key) => ( (data[key] == null || data[key] === '' || data[key] < 1 )) && delete data[key]);

    if (data['normal_leads'] === undefined) delete data['min_inactive_days'];
    if (data['normal_leads'] === undefined) delete data['max_inactive_days'];

    this.subscription = this.AccountManagementDataService.assignLead(data).pipe(
      tap(() => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        this.dialogRef.close(true);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onChangeCurrency(value: any) {
    this.form.patchValue({
      settings_currency_id: value,
      account_manager_id: null,
      normal_leads: null,
      vip_leads: null,
      min_inactive_days: this.min_inactive_days_allowed,
      max_inactive_days: this.max_inactive_days_allowed,
    });
    this.eventEmitterService.onClearMerchantAccountSearch();
    this.accountManagerSelectedItems = [];
    this.availableLeads = 0;
    this.getLeadsList = false;
    this.buttonLoading = true;
    this.cdr.detectChanges();
    this.accountManagerDropdownList = [];
    if (this.form.value.development_stage == 1) {
      this.AccountManagementDataService.getNormalAccountManager(`?settings_currency_id=${value}`).subscribe(res => {
        this.accountManagerDropdownList = res;
        this.buttonLoading = false;
        this.cdr.detectChanges();
      });
    } 
    
    if (this.form.value.development_stage == 2){
      this.AccountManagementDataService.getVipAccountManager(`?settings_currency_id=${value}`).subscribe(res => {
        this.accountManagerDropdownList = res;
        this.buttonLoading = false;
        this.cdr.detectChanges();
      });
    }
  }

  onChangeMemberRecovery(value: any) {
    if (this.form.controls['development_stage'].value == 1 && this.form.controls['member_recovery_type'].value == 1) {
      this.form.controls['min_inactive_days'].enable();
      this.form.controls['max_inactive_days'].enable();
    }else {
      this.form.controls['min_inactive_days'].disable();
      this.form.controls['max_inactive_days'].disable();
    }

    this.form.patchValue({
      normal_leads: null,
      vip_leads: null,
    });
    this.availableLeads = 0;
    this.getLeadsList = false;
    if (this.form.controls['account_manager_id'].value != null && this.form.controls['account_manager_id'].value.length > 0) {
      if (this.form.controls['development_stage'].value == 1 && this.form.controls['member_recovery_type'].value == 2 || this.form.controls['development_stage'].value == 2) {
        this.getLeadStatistic();
      }
    }

  }

  onChangeDevelopmentStage(value: any) {
    this.form.patchValue({
      account_manager_id: null,
      normal_leads: null,
      vip_leads: null,
    });
    this.availableLeads = 0;
    this.getLeadsList = false;
    this.buttonLoading = true;
    this.eventEmitterService.onClearMerchantAccountSearch();
    this.accountManagerSelectedItems = [];
    this.accountManagerDropdownList = [];
    if (value == 1) {
      this.AccountManagementDataService.getNormalAccountManager(`?settings_currency_id=${this.form.controls['settings_currency_id'].value}`).subscribe(res => {
        this.accountManagerDropdownList = res;
        this.buttonLoading = false;
        this.cdr.detectChanges();
      });
    } 
    
    if (value == 2){
      this.AccountManagementDataService.getVipAccountManager(`?settings_currency_id=${this.form.controls['settings_currency_id'].value}`).subscribe(res => {
        this.accountManagerDropdownList = res;
        this.buttonLoading = false;
        this.cdr.detectChanges();
      });
    }
    
  }

  getLeadStatistic() {

    this.buttonLoading = true;

    const data = {
      ...this.form.value,
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    const parameters = this.params ? `&${this.params}` : '';
    this.AccountManagementDataService.getLeadStatistic(`?${parameters}`
    ).subscribe(res => {
      this.form.patchValue({
        normal_leads: null,
        vip_leads: null,
      });
      this.availableLeads = res.length;
      this.getLeadsList = true;
      this.buttonLoading = false;
      this.cdr.detectChanges();
    });
  }

  onAccountManagerIdChanges() {
    const accountManagerID = this.form.get('account_manager_id').value;

    if (accountManagerID !== null && accountManagerID.length > 0) {
      if (this.form.controls['development_stage'].value == 1 && this.form.controls['member_recovery_type'].value == 2 || this.form.controls['development_stage'].value == 2){
        this.getLeadStatistic();
      }
    }else{
      this.availableLeads = 0;
      this.getLeadsList = false;
      this.cdr.detectChanges();
    }
  }

  private formInit() {

    this.form = new FormGroup({
      settings_currency_id: new FormControl(null),
      development_stage: new FormControl(1, [Validators.required]),
      account_manager_id: new FormControl(null, [Validators.required]),
      member_recovery_type: new FormControl(1, [Validators.required]),
      normal_leads: new FormControl(null),
      min_inactive_days: new FormControl(7),
      max_inactive_days: new FormControl(30),
      vip_leads: new FormControl(null)
    });

    this.form.controls['account_manager_id'].valueChanges.subscribe((val) => {
      if (val == null) {
        this.availableLeads = 0;
        this.getLeadsList = false;
        this.cdr.detectChanges();
      }
    });

    this.form.get('normal_leads').valueChanges.subscribe(val => {
      if (val) {
        this.form.patchValue({
          normal_leads: Math.round(val),
        });
        this.cdr.detectChanges();
      }
    });

    this.form.get('vip_leads').valueChanges.subscribe(val => {
      if (val) {
        this.form.patchValue({
          vip_leads: Math.round(val),
        });
        this.cdr.detectChanges();
      }
    });

    this.form.get('min_inactive_days').valueChanges.subscribe(val => {
      if (val) {
        this.form.patchValue({
          normal_leads: null,
        });
        this.availableLeads = 0;
        this.cdr.detectChanges();
      }
    });

    this.form.get('max_inactive_days').valueChanges.subscribe(val => {
      if (val) {
        this.form.patchValue({
          normal_leads: null,
        });
        this.availableLeads = 0;
        this.cdr.detectChanges();
      }
    });
  }

  checkAssignLead(){
    var leadsSelected = false;

    if (this.form.value.development_stage == 1) {
      if(this.availableLeads > 0 && this.form.value.normal_leads > 0){
        leadsSelected = true;
      }
    } else {
      if(this.availableLeads > 0 && this.form.value.vip_leads > 0){
        leadsSelected = true;
      }
    }

    return leadsSelected;
  }

  onSearchInactivceAvailableLeads() {
    this.getLeadStatistic();
  }

  onClear() {
    this.form.patchValue({
      min_inactive_days: this.min_inactive_days_allowed,
      max_inactive_days: this.max_inactive_days_allowed,
    });
    this.getLeadStatistic();
  }

  searchBtnDisabled() {
    if (this.form.get('settings_currency_id').value != null && this.form.get('account_manager_id').value != null) {
      return false
    }

    return true;
  }
}
