import { Status } from '@core/enums/status.enum';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SubheaderService } from '@core/_base/layout';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { AuthHttpService } from '@core/services/auth-http.service';
import * as moment from 'moment-timezone';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { PromotionStatisticEntityService } from './services/promotion-statistic-entity.service';
import { PromotionStatisticDataService } from './services/promotion-statistic-data.service';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PromotionStatistic } from '@core/models/promotion-statistic.model';
import { Pagination } from '@core/models/pagination.model';
import { exhaustMap, map, tap, catchError } from 'rxjs/operators';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-promotion-statistic',
  templateUrl: './promotion-statistic.component.html',
  styleUrls: ['./promotion-statistic.component.scss']
})
export class PromotionStatisticComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    statuses:  this.dropdownHttpService.statuses,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage
  };
  messages$ = this.promotionStatisticDataService.messages$;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  status = Status;
  ranges: any;
  promotion$: any;
  promotionStat$: Observable<PromotionStatistic[]>;
  dropdownSettings = {};
  from = this.transactionHttpService.getLast24Hours().from;
  to = this.transactionHttpService.getLast24Hours().to;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  access$ = this.authHttpService.getUserAccess(3, 'Promotion Statistics');

  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  buttonLoading = false;
  dataLength = 0;

  // permissions
  // promotionStatisticsUseNewPermissions: boolean;
  canExportPromotionStatistics: boolean;

  private subscriptions = new Subscription();

  params = `start_date_time=${moment(this.from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date_time=${moment(this.to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}`;
  constructor(
    private loadingBar: LoadingBarService,
    private subheaderService: SubheaderService,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    private eventEmitterService: EventEmitterService,
    private promotionStatisticEntityService: PromotionStatisticEntityService,
    private promotionStatisticDataService: PromotionStatisticDataService,
    private currencyHttpService: CurrencyHttpService,
    private authHttpService: AuthHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.pagination = this.promotionStatisticDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.setCurrencyDropdown();
    this.onViewPageBy(this.page).subscribe();
    this.formInit();

    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
   
    // this.promotionStatisticsUseNewPermissions = this.appPermissionService.isSectionUsingNewPermissions('promotion_statistics');
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canExportPromotionStatistics = appPermissions.export_promotion_statistics;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onDateRange(event: any) {
    if (event){
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.form.patchValue({
      code: null,
      currency_id: 'all',
      status: 'all',
      start_date_time: this.from,
      end_date_time: this.to,
      defaultDate: {
        startDate: this.from,
        endDate: this.to
      }
    });
    this.onSubmit(true);
  }

  onExport() {
    this.buttonLoading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        //convert time to utc format
        this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `${this.params}` : '';
        this.loadingBar.start();
        const dateTitle = this.form.get('start_date_time').value !== null ?
          '(' + this.convertDateTime(this.form.get('start_date_time').value) + ' To ' +
          this.convertDateTime(this.form.get('end_date_time').value) + ')' : '';
        const filename = `PromotionStatistics_${dateTitle}.xlsx`;
        return this.promotionStatisticDataService.export(`?${parameters}`).pipe(
          tap(res => {
            this.messages$ = this.promotionStatisticDataService.messages$;
            this.loadingBar.complete();
            this.buttonLoading = false;
          }),
          catchError(err => {
            this.loadingBar.complete();
            this.buttonLoading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    delete this.form.value.defaultDate;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.promotionStat$ = this.promotionStatisticEntityService.getWithQuery(`?perPage=${this.pageSize}${parameters}&paginate=true`).pipe(
          tap(res => {
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.promotion$ = this.promotionStatisticDataService.promoStat;
            this.pagination = this.promotionStatisticDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.promotionStat$ = this.promotionStatisticEntityService.getWithQuery(`?page=${page}&paginate=true&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.promotion$ = this.promotionStatisticDataService.promoStat;
        this.pagination = this.promotionStatisticDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      code: new FormControl(null),
      currency_id: new FormControl('all'),
      status: new FormControl('all'),
      start_date_time: new FormControl(this.from, [Validators.required]),
      end_date_time: new FormControl(this.to, [Validators.required]),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      })
    });
  }

  // private generateSortingParam() {
  //   const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
  //   return sortingParams;
  // }

  private convertDateTime(dateTime: any) {
    return moment(dateTime).utc().format('YYYY-MM-DD');
  }
}
