import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RewardStatus } from '@core/enums/reward.enum';
import { Pagination } from '@core/models/pagination.model';
import { PlayerReward } from '@core/models/player-reward.model';
import { Rewards } from '@core/models/rewards.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { RewardsHttpService } from '@core/services/rewards-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { MemberInformationDialogComponent } from '../members/dialogs/member-information/member-information.component';
import { MemberDataService } from '../members/services/member-data.service';
import { MemberRewardsDialogComponent } from './../members/dialogs/member-rewards/member-rewards.component';
import { RewardDetailsDialogComponent } from './dialogs/reward-details/reward-details.component';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { ImportRewardsDialogComponent } from './dialogs/import-rewards/import-rewards.component';
import { RewardMemberEligibleDialogComponent } from './dialogs/reward-member-eligible/reward-member-eligible.component';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { specialPermissions } from '@core/store/auth/auth.selectors';

@Component({
  selector: 'kt-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class RewardsComponent implements OnInit, OnDestroy {
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  form: FormGroup;
  dropdownSettings = {};
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    perPage: this.dropdownHttpService.perPage
  };

  status = RewardStatus
  statusColor = {
    0: 12,
    1: 1,
    2: 6,
    3: 7,
    4: 8,
  };

  rewards$: Observable<PlayerReward[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  ranges: any;
  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '3.7';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'promo_code': 'desc',
    'currency_code': 'desc',
    'credit': 'desc',
    'status': 'desc',
    'claimed_at': 'desc',
    'expiry_date': 'desc',
    'remarks': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc'
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;

  isAdminUser = JSON.parse(localStorage.getItem('user_data')).is_admin;
  // userPermissionImport = this.isAdminUser === 1 ? true : (localStorage.getItem('user_permissions') !== null ? JSON.parse(localStorage.getItem('user_permissions')).import_reward : true);
  userPermissions$ = this.store.pipe(select(specialPermissions));

  // permissions
  // rewardsUseNewPermissions: boolean;
  canViewMemberDialog: boolean;
  canAssignReward: boolean;
  canEditReward: boolean;
  canImportMemberRewards: boolean;

  constructor(
    public dialog: MatDialog,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private transactionHttpService: TransactionHttpService,
    private memberDataService: MemberDataService,
    private rewardsHttpService: RewardsHttpService,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
    private store: Store<AppState>,
  ) { }

  private datePickerSubscription = new Subscription();
  private subscription = new Subscription();
  private subscriptions = new Subscription();

  ngOnInit() {
    this.setCurrencyDropdown();
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));
    this.formInit();
    this.pagination = this.rewardsHttpService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.dropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    // this.rewardsUseNewPermissions = this.appPermissionService.isSectionUsingNewPermissions('rewards');
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.rewards_view_member_dialog;
      this.canAssignReward = appPermissions.assign_rewards;
      this.canEditReward = appPermissions.edit_reward;
      this.canImportMemberRewards = appPermissions.import_member_rewards;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.datePickerSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.rewards$ = this.rewardsHttpService.getPlayerRewards(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.clearBtnLoading = false;
        this.searchBtnLoading = false;
        this.dataLength = res.length;
        this.pagination = this.rewardsHttpService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, data?: number | Rewards) {
    switch (type) {
      case 'member-information':
        if (!this.canViewMemberDialog) {
          Swal.fire('Permission Error', 'You do not have permission to view member information', 'error');
          return;
        }

        const member = this.memberDataService.getById(+data, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
        this.subscription = member.pipe(
          tap((res) => {
            if (res) {
              this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' });
            }
          })
        )
          .subscribe();
        break;

      case 'edit':
        this.openDialogBy(RewardDetailsDialogComponent, {
          reward: (data as Rewards), mode: type
        });
        break;
      case 'create':
        this.openDialogBy(MemberRewardsDialogComponent, { member: null, mode: 'bulk' });
        break;
      case 'import':
        this.openDialogBy(ImportRewardsDialogComponent, { member: null, mode: null });
        break;
      case 'promo-eligible':
        this.openDialogBy(RewardMemberEligibleDialogComponent, { reward: (data as Rewards), mode: type });
        break;
    }

  }


  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data) => {
        if (data['start_date_time']) {
          data['start_date_time'] = moment(data['start_date_time']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date_time'] = moment(data['end_date_time']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.page = 1;
        this.onViewPageBy();
      })
    ).subscribe();
  }

  onClearDate() {
    if (this.form.value.start_date_time !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date_time: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date_time: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.eventEmitterService.onClearMemberSearch();
    this.form.patchValue({
      username: null,
      status: 'all',
      start_date_time: null,
      end_date_time: null,
      promo_code: null,
      currency_id: 'all',
      defaultDate: null
    });
    this.onSubmit(true);
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }
    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any, isAdmin?: boolean, reward?: Rewards }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'member-information' || 'promo-eligible' ? '1500px' : '800px',
      height: data.mode === 'member-information' ? '80vh' : 'auto',
      data,
      autoFocus: data.mode === 'member-information' ? false : true
    });
    if (data.mode !== 'member-information' && data.mode !== 'promo-eligible') {
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this.onViewPageBy(this.page);
        }
      });
    }
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      status: new FormControl(window.history.state.status !== undefined ? window.history.state.status : 'all'),
      start_date_time: new FormControl(null),
      end_date_time: new FormControl(null),
      promo_code: new FormControl(null),
      currency_id: new FormControl('all'),
      defaultDate: new FormControl(null) // Do not remove: For Clearing The Range
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private isValidDate(date: Date | string) {
    if (moment(date).isValid()) {
      return true;
    }
    return false;
  }

}
