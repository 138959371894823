<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <!-- Telemarketer -->
                                <!-- <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Telemarketer:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="agent_username" class="form-control">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <option [value]="value.username" *ngFor="let value of dropdown.telemarketer| async">
                                            {{ value.username }}
                                        </option>
                                    </select>
                                </div> -->
                <!-- Telemarketer -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Telemarketer:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="telemarketer" placeholder="Search" class="form-control">
                </div>

                <!-- Username -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Username:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)"
                    placeholder="Search" class="form-control">
                </div>

                <!-- Contact -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Contact: </label>
                </div>
                <div class="col-md-4 kt-form__control">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <select class="form-control" (change)="onContactType($event.target.value)">
                        <option value="Mobile" selected>Mobile</option>
                        <option value="Email">Email</option>
                      </select>
                    </div>
                    <input type="text" (change)="onContact($event)" placeholder="Search" class="form-control"
                      [disabled]="(selectedContactType === '' && selectedContactType === null)" #contactRef>
                    <input type="hidden" formControlName="mobile" />
                    <input type="hidden" formControlName="email" />
                  </div>
                </div>
              </div>
              <div class="col-12 row mb-2 pr-0">
                <!-- Source Type -->
                <div class="col-md-1 kt-form__label col-form-label">
                    <label class="mb-0">Source Type:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                    <select class="form-control" formControlName="source_type" (change)="onSelectSourceType($event)">
                        <option [value]="null">Please Select</option>
                        <ng-container *ngFor="let value of dropdown.sourceTypes">
                            <option [value]="value.id">{{ value.name }}</option>
                        </ng-container>
                    </select>
                </div>
                <!-- Source Account -->
                <div class="col-md-1 kt-form__label col-form-label">
                    <label class="mb-0">Source Account:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                    <input type="text" formControlName="source_account" placeholder="Search" class="form-control">
                </div>
                <!-- Campaign Name -->
                <div class="col-md-1 kt-form__label col-form-label">
                    <label class="mb-0">Campaign Name:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                    <input type="text" formControlName="campaign_name" placeholder="Search" class="form-control">
                </div>
                <!-- Campaign Code -->
                <div class="col-md-1 kt-form__label col-form-label">
                    <label class="mb-0">Campaign Code:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                    <input type="text" formControlName="campaign_code" placeholder="Search" class="form-control">
                </div>
              </div>
              <div class="col-12 row pr-0 mb-2">
                <!-- Currency -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Currency:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="settings_currency_id" class="form-control">
                    <option value="all"> All </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                      {{ value.name }}
                    </option>
                  </select>
                </div>

                <!-- Tier -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Tier:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="tier" class="form-control">
                    <option value="all"> All </option>
                    <ng-container *ngFor="let item of tiers | keyvalue">
                      <option [value]="item.key" *ngIf="+item.key >= 0">{{ item.value }}</option>
                    </ng-container>
                  </select>
                </div>

                <!-- Date Type & Date Range -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Date: </label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <select class="form-control" formControlName="date_type">
                        <option value="lead_provision_date" selected>Lead Provision Date</option>
                        <option value="ftd_date">FTD Date</option>
                        <option value="completed_date">Completed Date</option>
                        <option value="last_updated_date">Last Updated Date</option>
                      </select>
                    </div>
                    <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)"
                      formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true"
                      [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true"
                      [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                      ngxDaterangepickerMd />
                    <span class="input-group-append">
                      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12 row pr-0">
                <!-- Status -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Tier Status:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="d-flex flex-wrap status-filter-pt">
                    <div class="custom-control custom-checkbox mr-3"
                      *ngFor="let item of dropdown.tier_statuses ; let i = index">
                      <input type="checkbox" [checked]="item.checked"
                        (change)="onChangeTierStatus($event, item.value, i)" class="custom-control-input"
                        [id]="item.name">
                      <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                    <label>{{ 'Timezone' }}:</label>
                  </div>
                  <div class="col-md-5 kt-form__control">
                    <kt-dropdown-wo-lazyload #timezone (click)="onChangeTimezone(timezone.selectedItems)" [form]='form'
                      [dropdownList]='dropdown.timezones' [dropdownSettings]='timezoneDropdownSettings'
                      [formName]="'timezone'" [selectionAttributes]="'timezone'" [selectedItems]='timezoneSelectedItems'>
                    </kt-dropdown-wo-lazyload>
                  </div>
              </div>

              <div class="col-12 row pr-0">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="d-flex flex-wrap status-filter-pt">
                    <div class="custom-control custom-checkbox mr-3"
                      *ngFor="let item of dropdown.statuses ; let i = index">
                      <input type="checkbox" [checked]="item.checked" (change)="onChangeStatus($event, item.value, i)"
                        class="custom-control-input" [id]="item.name">
                      <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
        <div>
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading"
            (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"
            (click)="contactRef.value = ''"></kt-clear-button>
        </div>
        <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
        <!-- <button *ngIf="telemarketerReportUseNewPermissions ? canExportTelemarketerReport : (access$ | async).export" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" -->
        <button *ngIf="canExportTelemarketerReport" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button"
          [disabled]="buttonLoading"><i
            [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
      </div>
      <!-- Table -->
      <div class="kt-section ">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('telemarketer')"
                    [ngClass]="sortingConfig.telemarketer"
                    [class.sort-selected]="sortingSelection.sort_by === 'telemarketer'">Telemarketer</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('lead_id')"
                    [ngClass]="sortingConfig.lead_id" [class.sort-selected]="sortingSelection.sort_by === 'lead_id'">
                    Lead ID</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('username')"
                    [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">
                    Username</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('mobile')" [ngClass]="sortingConfig.mobile"
                    [class.sort-selected]="sortingSelection.sort_by === 'mobile'">Mobile</th>
                  <th colspan="3" class="text-center">Traffic Source</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('lead_provision_date')"
                    [ngClass]="sortingConfig.lead_provision_date"
                    [class.sort-selected]="sortingSelection.sort_by === 'lead_provision_date'">Lead Provision Date</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('FTD_id')" [ngClass]="sortingConfig.FTD_id"
                    [class.sort-selected]="sortingSelection.sort_by === 'FTD_id'">FTD ID</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('FTD_date')"
                    [ngClass]="sortingConfig.FTD_date" [class.sort-selected]="sortingSelection.sort_by === 'FTD_date'">
                    FTD Date</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('currency')"
                    [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">
                    Currency</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('FTD_amount')"
                    [ngClass]="sortingConfig.FTD_amount"
                    [class.sort-selected]="sortingSelection.sort_by === 'FTD_amount'">FTD Amount</th>
                  <th rowspan="2" class="text-center">Total Deposit in 30 Days</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('first_call_time')"
                    [ngClass]="sortingConfig.first_call_time"
                    [class.sort-selected]="sortingSelection.sort_by === 'first_call_time'">First Call Time</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('last_call_time')"
                    [ngClass]="sortingConfig.last_call_time"
                    [class.sort-selected]="sortingSelection.sort_by === 'last_call_time'">Last Call Time</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('tier')" [ngClass]="sortingConfig.tier"
                    [class.sort-selected]="sortingSelection.sort_by === 'tier'">Tier</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('tier_status')"
                    [ngClass]="sortingConfig.tier_status"
                    [class.sort-selected]="sortingSelection.sort_by === 'tier_status'">Tier Status</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status"
                    [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                  <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('completed_date')"
                    [ngClass]="sortingConfig.completed_date"
                    [class.sort-selected]="sortingSelection.sort_by === 'completed_date'">Completed Date</th>
                  <th rowspan="2" class="date-column sort-enabled" (click)="onSortColumn('updated_at')"
                    [ngClass]="sortingConfig.updated_at"
                    [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">{{ 'Last Updated Date' }}</th>
                </tr>
                <tr>
                  <th class="text-center sort-enabled" (click)="onSortColumn('source_type')" [ngClass]="sortingConfig.source_type" [class.sort-selected]="sortingSelection.sort_by === 'source_type'">Source Type</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('source_account')" [ngClass]="sortingConfig.source_account" [class.sort-selected]="sortingSelection.sort_by === 'source_account'">Source Account</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('campaign')" [ngClass]="sortingConfig.campaign" [class.sort-selected]="sortingSelection.sort_by === 'campaign'">Campaign</th>
                </tr>
              </thead>
              <tbody *ngIf="(telemarketerReport$ | async) as rows">
                <tr *ngFor="let row of rows">
                  <td>{{ row.telemarketer }}</td>
                  <td>{{ row.lead_id }}</td>
                  <td [ngClass]="row.dummy == 1 ? 'text-dummy-unclickable' : 'text-primary'">{{ row.username }}</td>
                  <td>{{ row.mobile }}</td>
                  <td>{{ row.source_type }}</td>
                  <td>{{ row.source_account }}</td>
                  <td class="col-max-width">{{ row.campaign }}</td>
                  <td class="text-center date-column">{{ timezoneDateWithTimezone(row.lead_provision_date, this.form.value.timezone, 'YYYY-MM-DD HH:mm') }}</td>
                  <td class="text-center">
                    <ng-container *ngIf="canViewDepositDetails else otherRole">
                      <a class="text-primary font-weight-bold" (click)="onOpenDialog(row.FTD_id)">{{ row.FTD_id }}</a>
                    </ng-container>
                    <ng-template #otherRole>
                      {{ row.FTD_id }}
                    </ng-template>
                  </td>
                  <td class="text-center date-column">{{ row.FTD_date ? timezoneDateWithTimezone(row.FTD_date, this.form.value.timezone, 'YYYY-MM-DD HH:mm')  : '-' }}</td>
                  <td class="text-center">{{ row.currency }}</td>
                  <td class="text-right">{{ row.FTD_amount ? (row.FTD_amount | number:'1.2-2') : '-' }}</td>
                  <td class="text-right">{{ row.total_deposit_within_FTD_plus_30_days ?
                    (row.total_deposit_within_FTD_plus_30_days | number:'1.2-2') : '-' }}</td>
                  <td class="text-center date-column">{{ row.first_call_time ? timezoneDateWithTimezone(row.first_call_time, this.form.value.timezone, 'YYYY-MM-DD HH:mm') : '-' }}</td>
                  <td class="text-center date-column">{{ row.last_call_time ? timezoneDateWithTimezone(row.last_call_time, this.form.value.timezone, 'YYYY-MM-DD HH:mm') : '-' }}</td>
                  <td class="text-center">Tier {{ row.tier }}</td>
                  <td class="text-center">
                    <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide kt-badge-tier-status"
                    [ngClass]="{'kt-badge--on-going' : row.lead_tier_status_name == 'On Going', 'kt-badge--ended' : row.lead_tier_status_name == 'Ended'}"> {{ row.lead_tier_status_name }}</span>
                  </td>
                  <td class="text-center">
                    <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide"
                      [ngClass]="'kt-badge--' + row.status"> {{ row.lead_status_name }}</span>
                  </td>
                  <td class="text-center date-column">{{ row.completed_date ? timezoneDateWithTimezone(row.completed_date, this.form.value.timezone, 'YYYY-MM-DD HH:mm') : '-' }}</td>
                  <td class="text-center">
                    {{ row.updated_by ? row.updated_by : '-' }}<br/>
                    {{ timezoneDateWithTimezone(row.updated_at, this.form.value.timezone, 'YYYY-MM-DD HH:mm') }}
                  </td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold">
              </tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true"
              [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total"
              (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                Showing {{pagination.from}} to {{pagination.to}} of
                {{ pagination.total | number : '1.0' }} records
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
