import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, Subscription, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LoadingBarService } from '@ngx-loading-bar/core';
import moment from 'moment';
import { Status } from '@core/enums/status.enum';
import { QuickLink } from '@core/models/quick-link.model';
import { QuickLinkDataService } from './services/quick-link-data.service';
import { QuickLinkEditDialogComponent } from './dialogs/quick-link-edit.component';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-quick-link',
  templateUrl: './quick-link.component.html',
  styleUrls: ['./quick-link.component.scss']
})
export class QuickLinkComponent implements OnInit, OnDestroy {

  messages$ = this.quickLinkDataService.messages$;
  quicklink$: Observable<QuickLink[]>;

  form: FormGroup;
  dropdown = {
    quicklinkStatus: this.dropdownHttpService.statuses,
    merchant: this.dropdownHttpService.merchantSites,
    perPage: this.dropdownHttpService.perPage
  };
  status = Status;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength = 0;
  
  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '15.3.3';
  sortingConfig = {
    'id': 'desc',
    'name': 'desc',
    'section_name': 'desc',
    'link': 'desc',
    'status': 'desc'
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  // permissions
  canCreateQuickLink: boolean;
  canViewQuickLinkDetails: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    private loadingBar: LoadingBarService,
    private quickLinkDataService: QuickLinkDataService,
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.pagination = this.quickLinkDataService.pagination;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateQuickLink = appPermissions.create_quick_link;
      this.canViewQuickLinkDetails = appPermissions.view_quick_link_details;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    this.loadingBar.start();
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    
    return this.quicklink$ = this.quickLinkDataService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.dataLength = res.length;
        this.pagination = this.quickLinkDataService.pagination;
        this.loadingBar.complete();
        this.loading = false;
      })
    );
  }

  onReload() {
    this.onSubmit();
  }

  onOpenDialog(type: string, rows?: any) { 
    switch (type) {
      case 'edit':
        const quicklinkData = this.quickLinkDataService.getById(rows.id);
        this.subscription = quicklinkData.pipe(
          tap((res) => {
            this.openDialogBy(QuickLinkEditDialogComponent, { mode: 'edit', rows: res });
          })
        ).subscribe();
        break;
      case 'create':
        this.openDialogBy(QuickLinkEditDialogComponent, { mode: 'create' });
        break;
    }
  }

  private openDialogBy(componentRef: any, data?: { mode?: string, rows?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        rows: data.rows,
        mode: data.mode
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true){
        this.onViewPageBy(this.page);
      }
    });
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    this.loadingBar.start();
    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap((data: any) => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';

        this.quicklink$ = this.quickLinkDataService.getWithQuery(`?perPage=${this.pageSize}&${this.generateSortingParam()}${parameters}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.quickLinkDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private formInit() {
    this.form = new FormGroup({
      section_name: new FormControl(null),
      merchant_id: new FormControl('all'),
      status: new FormControl('all')
    });
  }  

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = key === 'start_date' || key === 'end_date' ? moment(formData[key]).utc().format('YYYY-MM-DD HH:mm:ss') : formData[key];
      }
    });
    return fields;
  }  

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }
}
