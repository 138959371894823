import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Pagination } from '@core/models/pagination.model';
import { Injectable } from '@angular/core';
import { Deposit } from '@core/models/deposit.model';
import { MerchantBank } from '@core/models/merchant-bank.model';

@Injectable()
export class DepositDataService extends DefaultDataService<Deposit>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
      super('Deposit', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<Deposit[]> {
    return this.http.get<ApiResponse>('/deposit').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(deposit: Deposit): Observable<Deposit>{
    return this.http.post<ApiResponse>(`/deposit`, deposit).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/deposit/${entity.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  updateRemarks(deposit: any) {
    return this.http.put<ApiResponse>(`/deposit/remarks/${deposit.id}`, deposit).pipe(
      tap( res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/deposit/${entity.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }


  getWithQuery(pageParam: string): Observable<Deposit[]>{
    return this.http.get<ApiResponse>(`/deposit${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  getById(id: number): Observable<Deposit>{
    return this.http.get<ApiResponse>(`/deposit/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  getBankReceiptId(id: number): Observable<number>{
    return this.http.get<ApiResponse>(`/deposit/${id}`).pipe(
      map(res => res.data.rows.bank_receipt_id)
    );
  }

  exportDeposit(pageParam: string) {
    return this.http.get<ApiResponse>(`/deposit/export${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  requestReceipt(params) {
    return this.http.put<ApiResponse>('/deposit/receipt/request', params).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  uploadReceipt(params) {
    return this.http.post<ApiResponse>('/deposit/receipt/upload', params).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  getReceipt(id: number) {
    return this.http.get<ApiResponse>(`/deposit/receipt/${id}`).pipe(
      map(res => res.data)
    );
  }

  getRemarksHistory(deposit_id: number) {
    return this.http.get<ApiResponse>(`/deposit/remarks/${deposit_id}`).pipe(
      tap(
        // res => this.messages$.next(res.message)
      )
    );
  }

  triggerDepositCount() {
    return this.http.post<ApiResponse>(`/deposit/counter`, []).pipe(
      tap(
        // res => this.messages$.next(res.message)
      )
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }
}
