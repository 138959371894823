<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <form class="align-items-center" [formGroup]="form">
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies"> {{ value.name }} </option>
                                    </select>
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group date">
                                        <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true"
                                            [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="d-flex flex-wrap status-filter-pt">
                                        <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.statuses; let i = index">
                                            <input type="checkbox" [checked]="item.checked" (change)="onSearchStatus($event, item.value, i)" class="custom-control-input" [id]="item.name">
                                            <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!--Action Button-->
            <div class="kt-section col-12 row mb-3 mt-1">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
            </div>

            <!--Listing-->
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Parent</th>
                                    <th>Username</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Group</th>
                                    <th class="text-center">Registration Info</th>
                                    <th class="text-center">Status</th>
                                    <th>Remarks</th>
                                    <th class="text-center">Updated By</th>
                                    <th class="text-center">Actions</th>
                                </tr>

                            </thead>
                            <tbody *ngIf="affiliateApproval$.length > 0">
                                <tr *ngFor="let row of affiliateApproval$">
                                    <td>{{ row.id }}</td>
                                    <td>{{ row.parent_username === null ? '-' : row.parent_username }}</td>
                                    <td>
                                        <a class="text-primary font-weight-bold" (click)="onOpenDialog('information', row)">
                                            {{ row.username }}
                                        </a>
                                    </td>
                                    <td>{{ row.name }}</td>
                                    <td>{{ row.email }}</td>
                                    <td>{{ row.mobile }}</td>
                                    <td>{{ row.affiliate_group }}</td>
                                    <td>
                                        <span class="no-wrap">{{ row.register_date | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span><br>
                                        <span placement="top" [ngbTooltip]="row.registration_ip" container="body" *ngIf="row.registration_ip" class="text-ellipsis"> {{ row.registration_ip }}</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ row.status_name }}</span>
                                    </td>
                                    <td>{{ row.remarks === '' ? '-' : row.remarks }}</td>
                                    <td>
                                        {{ row.updated_by }}<br>
                                        <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                        </ng-template>
                                    </td>
                                    <td class="text-center actions-column">
                                        <button matTooltip="Approve" [disabled]="row.status === 1" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('approve', row)"><i class="fas fa-check"></i></button>
                                        <button matTooltip="Reject" [disabled]="row.status === 1" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('reject', row)"><i class="fas fa-ban"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                            [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
