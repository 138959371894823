import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Subscription, forkJoin } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AffiliateGroupsDataService } from '../../services/affiliate-groups-data.service';
import { AllAffiliatesDataService } from '../../../all-affiliates/services/all-affiliates-data.service';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import Swal from "sweetalert2";
import { TranslateService } from '@ngx-translate/core';
import { Pagination } from '@core/models/pagination.model';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogDataService } from '@views/pages/apps/settings/announcements/dialog/services/dialog-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { AffiliateGroupsDialogBulkAddComponent } from './dialogs/affiliate-groups-dialog-bulk-add.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'kt-affiliate-groups-edit',
  templateUrl: './affiliate-groups-edit.component.html',
  styleUrls: ['./affiliate-groups-edit.component.scss']
})
export class AffiliateGroupsEditDialogComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChildren('tab') tabs: QueryList<ElementRef>;
  form: FormGroup;
  searchForm: FormGroup;
  limitForm: FormGroup;
  visibilityForm: FormGroup;
  dialogPopupVisibilityForm: FormGroup;
  dialogPopupVisibilityArrayForm: FormGroup;

  messages$ = this.affiliateGroupsDataService.messages$;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    memberGroups: this.dropdownHttpService.groups,
    statuses: this.dropdownHttpService.statuses,
    groupTypes: this.dropdownHttpService.affiliateGroupTypeStatuses,
    locales: [],
    perPage: this.dropdownHttpService.perPage,
    partnerNetwork: this.dropdownHttpService.affiliatePartnerNetwork,
  }
  buttonLoading = false;

  affiliateDropdownList: any = [];
  affiliateDropdownListSettings: any;
  affiliateSelectedList = [];

  affiliateDialogDropdownListSettings: any;
  affiliateDialogSelectedList = [];

  affiliateDialogListDropdownListSettings: any;
  affiliateDialogListDropdownList = [];
  affiliateDialogListSelectedItems = [];

  currentActiveTab = 0;
  initDialog: any;
  initDialogPopupVisibility: any;
  initAffiliateSelectedList: any;
  initAffiliateVisibility: any;
  dialogPopupVisibility$ = [];
  isOnAddClicked = false;
  checkValidation = false;
  loading = false;
  searchBtnLoading = false;
  clearBtnLoading = false;
  dataLength: number;
  pagination: Pagination;
  pageSize = 10;
  page = 1;
  maxSize = 5;
  params = '';
  popupDropdownList = [];
  popupDropdownListAll = [];
  backUpPopupDialogArr: any;
  previousDialogPopupVisibilityList = null;
  isTriggerSwal = true;
  clonedData: any;

  // permissions
  canViewDialog: boolean;
  canEditDialog: boolean;
  canBulkAddDialog: boolean;
  canAddDialog: boolean;
  canDeleteDialog: boolean;

  roleVersion = JSON.parse(localStorage.getItem('user_data')).authorized_role_version;

  tabList = [
    {id: 0, tab: 'Affiliate Group Settings'},
    {id: 1, tab: 'Affiliate Referral Settings'},
    {id: 2, tab: 'Affiliate Dialog Popup Settings'},
  ];

  popupDialogLoading = true;
  private subscriptions = new Subscription();
  private subscription = new Subscription();
  private dialogSubscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      mode: string, 
      groups: any, 
      visibilitySettings: { affiliate_group_id: number, affiliate_id: number[], column_visibility: number },
      dialogPopupVisibilitySettings: { affiliate_group_id: number, affiliate_id: number[], dialog_popup_column_visibility: number },
    },
    public dialogRef: MatDialogRef<AffiliateGroupsEditDialogComponent>,
    private affiliateGroupsDataService: AffiliateGroupsDataService,
    private dropdownHttpService: DropdownHttpService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private loadingBar: LoadingBarService,
    private dialogDataService: DialogDataService,
    private appPermissionService: AppPermissionService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    if (this.data.mode == 'edit') {
      this.clonedData = Object.assign({}, this.data);
    }

    this.dropdownHttpService.locales.subscribe(res => {
      this.dropdown.locales = res;
    });

    if (this.dropdown.currencies.length === 0) {
      this.dropdownHttpService.currencies.subscribe(res => {
        this.dropdown.currencies = res;
      });
    }
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewDialog = this.roleVersion == 2 ? appPermissions.view_affiliate_dialog_popup : true;
      this.canEditDialog = this.roleVersion == 2 ? appPermissions.edit_affiliate_dialog_popup : true;
      this.canBulkAddDialog = this.roleVersion == 2 ? appPermissions.bulk_add_affiliate_dialog_popup : true;
      this.canAddDialog = this.roleVersion == 2 ? appPermissions.add_affiliate_dialog_popup : true;
      this.canDeleteDialog = this.roleVersion == 2 ? appPermissions.delete_affiliate_dialog_popup : true;
    });

    this.subscriptions.add(apSub);
  }

  onCloseDialog(value = false, closeDialog = false) {
    if (closeDialog || this.data.mode == 'create') {
      if (this.checkupdate()) {
        const result = Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Do you want to save the changes of ' + this.tabList.find(x => x.id == this.currentActiveTab).tab + '?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('No'),
          confirmButtonText: this.translateService.instant('Yes'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.onSave(this.currentActiveTab);
            this.dialogRef.close(value);
          } else if (result.isDenied) {
            this.dialogRef.close(value);
          }
        });
      }
      else {
        this.dialogRef.close(value);
      }
    }
  }

  onSave(currentActiveTab?: any) {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    const data = {
      id: this.data.groups ? this.data.groups.id : null,
      ...this.form.value,
      default: this.form.value.default ? 1 : 0,
    };

    const limitData = {
      ...this.limitForm.value,
    };

    const visibilityData = {
      column_visibility: this.visibilityForm.get('toggle').value ? +this.visibilityForm.get('column_visibility').value : 0,
    }
    if (this.visibilityForm.get('toggle').value && +this.visibilityForm.get('column_visibility').value > 1) {
      visibilityData['affiliate_id'] = this.visibilityForm.get('affiliate_id').value;
    }

    const dialogPopupVisibilityData = {
      dialog_popup_column_visibility: this.dialogPopupVisibilityForm.get('dialog_toggle').value ? +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value : 0,
    }
    if (this.dialogPopupVisibilityForm.get('dialog_toggle').value && +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value > 1) {
      dialogPopupVisibilityData['affiliate_id'] = this.dialogPopupVisibilityForm.get('dialog_affiliate_id').value;
    }

    Object.keys(limitData).forEach((key) => (limitData[key] == null || limitData[key] === '') && delete limitData[key]);
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    if (this.data.mode === 'create') {
      this.form.setErrors({ 'invalid': true });
      this.subscription = this.affiliateGroupsDataService.add(data).pipe(
        tap((res: any) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else {
      if (currentActiveTab == 0) { // Affiliate Group Settings
        this.form.setErrors({ 'invalid': true });
        this.subscription = forkJoin([
          this.affiliateGroupsDataService.updateGroup(this.data.groups.id, data),
          this.affiliateGroupsDataService.updateWithdrawlimit(limitData)
        ]).pipe(
          tap(() => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.limitForm.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.limitForm.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.data.groups = {
            default: data['default'],
            id: data['id'],
            member_group_id: data['member_group_id'],
            name: data['name'],
            remarks: data['remarks'],
            settings_currency_id: data['currency_id'],
            status: data['status'],
            type: data['type'],
            partner_network: data['partner_network'],
            limit: {
              daily_max: limitData['daily_max'],
              daily_max_count: limitData['daily_max_count'],
              id: limitData['id'],
              max: limitData['max'],
              min: limitData['min'],
              setting_currency_id: limitData['setting_currency_id'],
            }
          }
          this.formInit();
        });
      }
      else if (currentActiveTab == 1) { // Affiliate Referral Settings
        this.visibilityForm.setErrors({ 'invalid': true });
        this.subscription = forkJoin([
          this.affiliateGroupsDataService.updateReferralVisibilitySettings(this.data.groups.id, visibilityData)
        ]).pipe(
          tap(() => {
            this.buttonLoading = false;
            this.visibilityForm.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.visibilityForm.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.data.visibilitySettings = {
            affiliate_group_id: this.data.groups.id,
            column_visibility: visibilityData['column_visibility'],
            affiliate_id: visibilityData['affiliate_id'] != undefined ? visibilityData['affiliate_id'] : this.data.visibilitySettings.affiliate_id,
          }
          this.formInit();
        });
      }
      else if (currentActiveTab == 2) {  // Affiliate Dialog Popup Settings
        this.dialogPopupVisibilityArr.controls.forEach((control, index)=> {
          if (control.enabled && !this.validationDialogList(index)) {
            const dialogListData = control.value.mode === 'create' ? this.filterFormFields(control.value) : control.value;
            dialogListData['affiliate_group_id'] = this.data.groups.id;

            this.subscription = forkJoin([
              this.affiliateGroupsDataService.createUpdateDialogPopupVisibilityList(control.value.mode, dialogListData),
            ]).pipe(
              tap(() => {
                this.form.setErrors(null);
              }),
              catchError((error) => {
                this.form.setErrors(null);
                throw error;
              })
            ).subscribe(res => {
              this.formInit();
            });
          }
          else {
            this.buttonLoading = false;
          }
        });
       
        this.dialogPopupVisibilityForm.setErrors({ 'invalid': true });
        this.subscription = forkJoin([
          this.affiliateGroupsDataService.updateDialogPopupVisibilitySettings(this.data.groups.id, dialogPopupVisibilityData)
        ]).pipe(
          tap(() => {
            this.buttonLoading = false;
            this.dialogPopupVisibilityForm.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.dialogPopupVisibilityForm.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.data.dialogPopupVisibilitySettings = {
            ...this.data.dialogPopupVisibilitySettings,
            dialog_popup_column_visibility: dialogPopupVisibilityData['dialog_popup_column_visibility'],
            affiliate_id: dialogPopupVisibilityData['affiliate_id'] != undefined ? dialogPopupVisibilityData['affiliate_id'] : this.data.dialogPopupVisibilitySettings.affiliate_id,
          }

          this.clonedData.dialogPopupVisibilitySettings = {
            ...this.clonedData.dialogPopupVisibilitySettings,
            dialog_popup_column_visibility: dialogPopupVisibilityData['dialog_popup_column_visibility'],
            affiliate_id: dialogPopupVisibilityData['affiliate_id'] != undefined ? dialogPopupVisibilityData['affiliate_id'] : this.data.dialogPopupVisibilitySettings.affiliate_id,
          }
          this.formInit();
        });
      }
    }
  }

  onLimitByCurrency() {
    if (this.data.mode === 'edit') {
      this.limitForm.patchValue({ setting_currency_id: this.form.value.currency_id });
    }
  }

  private formInit() {
    if (this.data.mode == 'edit') {
      this.allAffiliatesDataService.getWithQuery(`?affiliate_group_id=${this.data.groups.id}&paginate=false`).subscribe(res => {
        this.affiliateDropdownList = res;
        this.affiliateSelectedList = res.filter(x => this.data.visibilitySettings.affiliate_id.includes(x.id));
        this.affiliateDialogSelectedList = res.filter(x => this.data.dialogPopupVisibilitySettings.affiliate_id.includes(x.id));

        if (this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 1 || this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 3) {
          this.affiliateDialogListDropdownList = this.affiliateDropdownList;
        }
        else {
          this.affiliateDialogListDropdownList = this.affiliateDropdownList.filter(x => this.data.dialogPopupVisibilitySettings.affiliate_id.includes(x.id));
        }
      });
      this.affiliateDropdownListSettings = {
        autoPosition: false,
        singleSelection: false,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'username',
        noDataLabel: '',
        showCheckbox: false,
        disabled: this.data.visibilitySettings.column_visibility < 2
      };

      this.affiliateDialogDropdownListSettings = {
        autoPosition: false,
        singleSelection: false,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'username',
        noDataLabel: '',
        showCheckbox: false,
      };

      this.affiliateDialogListDropdownListSettings = {
        autoPosition: true,
        singleSelection: false,
        text: 'Please Select',
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        classes: 'dropdown',
        maxHeight: 200, //'auto',
        primaryKey: 'id',
        labelKey: 'username',
        noDataLabel: '',
        showCheckbox: false,
      };
    }

    let name = null;
    let currencyId = 0;
    let memberGroup = 0;
    let type = 1;
    let partner_network = 1;
    let isDefault = null;
    let status = null;
    let remarks = null;

    // withdraw limit
    let limitId = null;
    let min = null;
    let max = null;
    let dailyMax = null;
    let dailyMaxCount = null;
    let limitSettingCurrencyId = null;

    // Visibility settings
    let toggle: boolean = false;
    let column_visibility: number = null;
    let affiliate_id: number[] = [];

    let dialog_toggle: boolean = false;
    let dialog_popup_column_visibility: number = null;
    let dialog_affiliate_id: number[] = [];
    

    if (this.data.mode === 'edit') {
      name = this.data.groups.name;
      currencyId = this.data.groups.settings_currency_id;
      memberGroup = this.data.groups.member_group_id;
      type = this.data.groups.type;
      partner_network = this.data.groups.partner_network;
      isDefault = this.data.groups.default;
      status = this.data.groups.status;
      remarks = this.data.groups.remarks;

      // withdraw limit
      limitId = this.data.groups.limit.id;
      min = this.data.groups.limit.min;
      max = this.data.groups.limit.max;
      dailyMax = this.data.groups.limit.daily_max;
      dailyMaxCount = this.data.groups.limit.daily_max_count;
      limitSettingCurrencyId = this.data.groups.limit.setting_currency_id;

      // Visibility setting
      column_visibility = this.data.visibilitySettings.column_visibility == 0 ? null : this.data.visibilitySettings.column_visibility;
      toggle = column_visibility == null ? false : true;
      affiliate_id = this.data.visibilitySettings.affiliate_id;

      // Dialog Popup Visibility setting
      dialog_popup_column_visibility = this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility == 0 ? null : this.data.dialogPopupVisibilitySettings.dialog_popup_column_visibility;
      dialog_toggle = dialog_popup_column_visibility == null ? false : true;
      dialog_affiliate_id = this.data.dialogPopupVisibilitySettings.affiliate_id;
    }

    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      currency_id: new FormControl(currencyId, [Validators.required, Validators.min(1)]),
      member_group_id: new FormControl(memberGroup, [Validators.required, Validators.min(1)]),
      type: new FormControl(type, [Validators.required, Validators.min(1)]),
      partner_network: new FormControl(partner_network, [Validators.required, Validators.min(1)]),
      default: new FormControl(isDefault),
      status: new FormControl(status),
      remarks: new FormControl(remarks)
    });

    // withdraw limit
    this.limitForm = new FormGroup({
      id: new FormControl(limitId),
      min: new FormControl(min),
      max: new FormControl(max),
      daily_max: new FormControl(dailyMax),
      daily_max_count: new FormControl(dailyMaxCount),
      setting_currency_id: new FormControl(limitSettingCurrencyId),
    });

    // Visibility Settings
    this.visibilityForm = new FormGroup({
      toggle: new FormControl(toggle),
      column_visibility: new FormControl(column_visibility),
      affiliate_id: new FormControl(affiliate_id)
    })

    this.visibilityForm.get('toggle').valueChanges.subscribe(res => {
      res ? this.visibilityForm.get('column_visibility').setValidators([Validators.required]) : this.visibilityForm.get('column_visibility').clearValidators();
      this.visibilityForm.get('column_visibility').updateValueAndValidity();
      this.resetValue();
    })

    this.visibilityForm.get('column_visibility').valueChanges.subscribe(res => {
      res > 1 ? this.visibilityForm.get('affiliate_id').setValidators([Validators.required]) : this.visibilityForm.get('affiliate_id').clearValidators();
      this.visibilityForm.get('affiliate_id').updateValueAndValidity();

      this.affiliateDropdownListSettings = {
        ...this.affiliateDropdownListSettings,
        disabled: res > 1 ? false : true
      };
      this.cdr.detectChanges();
    });

    // Dialog Popup Visibility Settings
    this.dialogPopupVisibilityForm = new FormGroup({
      dialog_toggle: new FormControl(dialog_toggle),
      dialog_popup_column_visibility: new FormControl(dialog_popup_column_visibility),
      dialog_affiliate_id: new FormControl(dialog_affiliate_id)
    })

    this.dialogPopupVisibilityForm.get('dialog_toggle').valueChanges.subscribe(res => {
      res ? this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').setValidators([Validators.required]) : this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').clearValidators();
      this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').updateValueAndValidity();

      if (res) {
        this.dialogPopupVisibilityForm.patchValue({
          dialog_popup_column_visibility: 1,
        });
        
        this.dialogPopupInit();
      }
      else {
        this.previousDialogPopupVisibilityList = null;
      }
    })

    this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').valueChanges.subscribe(res => {
      let isShowDeleteDialogConfirmation = sessionStorage.getItem('isShowEditingAffiliateConfirmation');
      if ((isShowDeleteDialogConfirmation == null || isShowDeleteDialogConfirmation == 'true') && this.initAffiliateVisibility !== res && res != 1 && this.dialogPopupVisibilityArr.controls.length > 0) {
        const result = Swal.fire({
          title: '<div class="text-center">Confirmation</div>',
          html: '<div class="text-center">Unavailable affiliate(s) will be removed under<br><strong>Dialog Popup Visibility</strong><br><br>Do you want to continue?</div>',
          showDenyButton: true,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          denyButtonText: this.translateService.instant('No'),
          confirmButtonText: this.translateService.instant('Yes'),
          icon: 'warning',
          input: 'checkbox',
          inputPlaceholder: 'Do not show it again',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then((response) => {
          if (response.value && response.isConfirmed) {
            sessionStorage.setItem('isShowEditingAffiliateConfirmation', 'false');
            this.data.dialogPopupVisibilitySettings = {
              ...this.data.dialogPopupVisibilitySettings,
              dialog_popup_column_visibility: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility,
            }
            this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
            this.initAffiliateVisibility = res;
            this.cdr.detectChanges();
          }
          else if (!response.value && response.isConfirmed) {
            this.data.dialogPopupVisibilitySettings = {
              ...this.data.dialogPopupVisibilitySettings,
              dialog_popup_column_visibility: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility,
            }
            this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
            this.initAffiliateSelectedList = res;
            this.cdr.detectChanges();
          } else if (response.isDenied) {
            this.dialogPopupVisibilityForm.patchValue({
              dialog_popup_column_visibility: this.initAffiliateVisibility
            });
            this.cdr.detectChanges();
          }
        });
      }
      else {
        this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
        this.initAffiliateSelectedList = res;
        this.cdr.detectChanges();
      }
    });

    this.dialogPopupVisibilityForm.get('dialog_affiliate_id').valueChanges.subscribe(res => {
      setTimeout(() => {
        if (this.isTriggerSwal && this.initAffiliateSelectedList != JSON.stringify(this.affiliateDialogSelectedList.map(x => x.id)) &&
          JSON.parse(this.initAffiliateSelectedList).length > this.affiliateDialogSelectedList.length && this.dialogPopupVisibilityArr.controls.length > 0) {
          this.isTriggerSwal = false;

          let isShowDeleteDialogConfirmation = sessionStorage.getItem('isShowEditingAffiliateConfirmation');
          if (isShowDeleteDialogConfirmation == null || isShowDeleteDialogConfirmation == 'true') {
            const result = Swal.fire({
              title: '<div class="text-center">Confirmation</div>',
              html: '<div class="text-center">Unavailable affiliate(s) will be removed under<br><strong>Dialog Popup Visibility</strong><br><br>Do you want to continue?</div>',
              showDenyButton: true,
              showCloseButton: false,
              showCancelButton: false,
              showConfirmButton: true,
              reverseButtons: true,
              denyButtonText: this.translateService.instant('No'),
              confirmButtonText: this.translateService.instant('Yes'),
              icon: 'warning',
              input: 'checkbox',
              inputPlaceholder: 'Do not show it again',
              customClass: {
                denyButton: 'deny-button',
                confirmButton: 'confirm-button',
              }
            }).then((response) => {
              if (response.value && response.isConfirmed) {
                this.isTriggerSwal = true;
                sessionStorage.setItem('isShowEditingAffiliateConfirmation', 'false');
                this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
              }
              else if (!response.value && response.isConfirmed) {
                this.isTriggerSwal = true;
                this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
              } else if (response.isDenied) {
                this.isTriggerSwal = true;
                this.affiliateDialogSelectedList = this.affiliateDropdownList.filter(x => JSON.parse(this.initAffiliateSelectedList).includes(x.id));
                this.dialogPopupVisibilityForm.patchValue({
                  dialog_affiliate_id: JSON.parse(this.initAffiliateSelectedList)
                })
                this.cdr.detectChanges();
              }
            });
          }
          else {
            this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
          }
        }
        else {
          this.initAffiliateSelectedList = JSON.stringify(this.affiliateDialogSelectedList.map(x => x.id));
          if (this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == null || this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1) {
            const popIds = this.affiliateDropdownList.map(x => x.id);
            this.getDialogAffiliateList(popIds);
          }
          else {
            this.getDialogAffiliateList(res);
          }
        }
      }, 250);
    })
    
    this.dialogPopupInit();
    this.updateInitDialog(true);
    this.cdr.detectChanges();
  }

  updateDialogVisibilityValidator(res: any) {
    res > 1 ? this.dialogPopupVisibilityForm.get('dialog_affiliate_id').setValidators([Validators.required]) : this.dialogPopupVisibilityForm.get('dialog_affiliate_id').clearValidators();
    this.dialogPopupVisibilityForm.get('dialog_affiliate_id').updateValueAndValidity();

    this.affiliateDialogDropdownListSettings = {
      ...this.affiliateDialogDropdownListSettings,
      disabled: res > 1 ? false : true
    };

    this.dialogPopupVisibilityArr.controls.forEach((control, index)=> {
      if (res > 0 && (control.value.is_default == 1 || res == 2 || res == 3)) {
        control.patchValue({
          visibility: res,
        });
      }
    });
    this.initAffiliateVisibility = res;
    this.cdr.detectChanges();
  }

  getDialogAffiliateList(popIds: any) {
    if (this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 || this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 3) {
      this.affiliateDialogListDropdownList = this.affiliateDropdownList;
    }
    else {
      this.affiliateDialogListDropdownList = this.affiliateDropdownList.filter(x => popIds.includes(x.id));
    }

    this.dialogPopupVisibilityArr.controls.forEach((control, index)=> {
      if (control.value.is_default == 1) {
        this.affiliateDialogListSelectedItems[index] = control.value.visibility == 1 ? [] : this.affiliateDialogListDropdownList.filter(x => popIds.includes(x.id));
        control.patchValue({
          affiliate_id: control.value.visibility == 1 ? [] : this.affiliateDialogListSelectedItems[index].map(x => x.id),
        });
      }
      else {
        if (control.value.visibility == 2) {
          let affiliateArr = control.value.affiliate_id.filter(x => popIds.includes(x));
          this.affiliateDialogListSelectedItems[index] = this.affiliateDialogListDropdownList.filter(x => affiliateArr.includes(x.id));
          control.patchValue({
            affiliate_id: this.affiliateDialogListSelectedItems[index].length > 0 ? this.affiliateDialogListSelectedItems[index].map(x => x.id) : [],
          });
        }
      }
    });
    this.cdr.detectChanges();
  }

  private dialogPopupInit() {
    // Dialog Popup Visibility List Search Form
    if (this.searchForm == undefined) {
      this.searchForm = new FormGroup({
        code: new FormControl(null),
        locale_id: new FormControl('all'),
      });
    }

    // Dialog Popup Visibility List
    this.dialogPopupVisibilityArrayForm = this.fb.group({
      dialogPopupVisibility: new FormArray([]),
    });
    
    setTimeout(() => {
      if (this.currentActiveTab == 2) {
          this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
      }
    }, 250);
  }

  tabChanged(tab: any, event: any) {
    if (tab.selectedIndex != this.currentActiveTab) {
      if (this.checkupdate() && ((this.form.valid && this.currentActiveTab == 0) || (this.visibilityForm.valid && this.currentActiveTab == 1) || (this.dialogPopupVisibilityForm.valid && this.currentActiveTab == 2))) {
        tab.selectedIndex = this.currentActiveTab;
        const result = Swal.fire({
          title: '<div class="text-center">Unsaved Changes</div>',
          html: '<div class="text-center">Do you want to save the changes of ' + this.tabList.find(x => x.id == this.currentActiveTab).tab + '?</div>',
          showDenyButton: true,
          showCloseButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
          reverseButtons: true,
          denyButtonText: this.translateService.instant('No'),
          confirmButtonText: this.translateService.instant('Yes'),
          icon: 'warning',
          customClass: {
            denyButton: 'deny-button',
            confirmButton: 'confirm-button',
          }
        }).then(result => {
          if (result.isConfirmed) {
            this.onSave(this.currentActiveTab);
            this.currentActiveTab = this.tabList.find(x => x.tab == event.tab.textLabel).id;
            tab.selectedIndex = this.currentActiveTab;
            this.updateInitDialog(true);
            this.previousDialogPopupVisibilityList = null;
          } else if (result.isDenied) {
            this.data.dialogPopupVisibilitySettings = {
              ...this.data.dialogPopupVisibilitySettings,
              dialog_popup_column_visibility: this.clonedData.dialogPopupVisibilitySettings.dialog_popup_column_visibility,
            }
            this.formInit();
            this.currentActiveTab = this.tabList.find(x => x.tab == event.tab.textLabel).id;
            this.popupDialogLoading = this.currentActiveTab == 3 ? true : false;
            tab.selectedIndex = this.currentActiveTab;
            this.updateInitDialog(true);
          }
        });
      }
      else {
        this.currentActiveTab = this.tabList.find(x => x.tab == event.tab.textLabel).id;
        this.popupDialogLoading = this.currentActiveTab == 2 ? true : false;
        this.formInit();
      }
    }
  }

  checkupdate() {
    switch (this.currentActiveTab) {
      case 0: // Affiliate Group Settings
        return this.initDialog !== JSON.stringify({ ...this.form.value }) + JSON.stringify({ ...this.limitForm.value });
      case 1: // Affiliate Referral Settings
        return this.initDialog !== JSON.stringify({ ...this.visibilityForm.value });
      case 2: // Affiliate Dialog Popup Settings
        return this.initDialog !== JSON.stringify({ ...this.dialogPopupVisibilityForm.value }) || this.initDialogPopupVisibility != JSON.stringify({ ...this.dialogPopupVisibilityArr.value });
    }
  }

  updateInitDialog(isUpdateInitDialog = false) {
    switch (this.currentActiveTab) {
      case 0: // Affiliate Group Settings
        this.initDialog = JSON.stringify({ ...this.form.value }) + JSON.stringify({ ...this.limitForm.value });
        break;
      case 1: // Affiliate Referral Settings
        this.initDialog = JSON.stringify({ ...this.visibilityForm.value });
        break;
      case 2: // Affiliate Dialog Popup Settings 
        this.initDialog = isUpdateInitDialog ? JSON.stringify({ ...this.dialogPopupVisibilityForm.value }) : this.initDialog;
        this.initDialogPopupVisibility = JSON.stringify({ ...this.dialogPopupVisibilityArr.value });
        this.initAffiliateSelectedList = isUpdateInitDialog ? JSON.stringify(this.affiliateDialogSelectedList.map(x => x.id)) : this.initAffiliateSelectedList;
        this.initAffiliateVisibility = isUpdateInitDialog ? this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility : this.initAffiliateVisibility;
        break;
    }
  }

  getDialogPopupVisibility(page = 1, pageSize?: number, params?: string, clearSearch?: boolean) {
    this.resetValue();
    this.dialogSubscription.unsubscribe();
    this.popupDropdownList = [];
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    this.dialogSubscription = this.dialogDataService.getWithQuery(`?paginate=false&status=1&always_pop=0&affiliates_visibility=1&affiliate_group_id=${this.data.groups.id}`).subscribe(res => {
      let contents = [];
      res.map(function (elm) {
        elm.contents.forEach(v => contents.push(v));

        let localeTitle = contents.filter(x => x.title != null && x.popup_id == elm.id);
        localeTitle = localeTitle.sort(function(a, b) { 
          return a.locale_id - b.locale_id;
        });

        if (localeTitle.length > 0) {
          let title = (localeTitle[0]['title'].length > 22) ? localeTitle[0]['title'].slice(0, 22 - 1) + ' . . . ' : localeTitle[0]['title'];
          elm['labelKey'] = elm.code + ' (' + title + ')';
        }
        else {
          elm['labelKey'] = elm.code;
        }
      });

      this.popupDropdownList = this.popupDropdownList.concat(res);
      this.popupDropdownList = this.popupDropdownList.map((x) => {
        return {
          id: x.id,
          labelKey: x.labelKey,
          code: x.code,
          locale_name: x.locale_name.replace(',', ', '),
        }
      });

      this.dialogDataService.getWithQuery(`?paginate=false&status=1&always_pop=0&=1&affiliates_visibility=1`).subscribe(res => {
        let contents = [];
        res.map(function (elm) {
          elm.contents.forEach(v => contents.push(v));
  
          let localeTitle = contents.filter(x => x.title != null && x.popup_id == elm.id);
          localeTitle = localeTitle.sort(function(a, b) { 
            return a.locale_id - b.locale_id;
          });
  
          if (localeTitle.length > 0) {
            let title = (localeTitle[0]['title'].length > 22) ? localeTitle[0]['title'].slice(0, 22 - 1) + ' . . . ' : localeTitle[0]['title'];
            elm['labelKey'] = elm.code + ' (' + title + ')';
          }
          else {
            elm['labelKey'] = elm.code;
          }
        });
  
        this.popupDropdownListAll = this.popupDropdownListAll.concat(res);
        this.popupDropdownListAll = this.popupDropdownListAll.map((x) => {
          return {
            id: x.id,
            labelKey: x.labelKey,
            code: x.code,
            locale_name: x.locale_name.replace(',', ', '),
          }
        });

        const data = this.filterFormFields(this.searchForm.value);
        data['affiliate_group_id'] = this.data.dialogPopupVisibilitySettings.affiliate_group_id;
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.affiliateGroupsDataService.getDialogPopupVisibilityList(`?page=${page}&perPage=${this.pageSize}${parameters}`).subscribe(res => {
          this.dialogPopupVisibility$ = res;
          if (this.dialogPopupVisibility$.length > 0) {
            this.dialogPopupVisibility$.map(item => {
              this.onAddRow(false, item);
            });
          }
          this.dataLength = res.length;
          this.pagination = this.affiliateGroupsDataService.pagination;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
          this.loading = false;
          this.loadingBar.complete();
          this.popupDialogLoading = false;

          this.isOnAddClicked = false;
          this.updateInitDialog(false);
        });
      });
    });

    this.cdr.detectChanges();
  }

  onClear() {
    this.clearBtnLoading = true;
    this.searchForm.patchValue({
      code: null,
      locale_id: 'all'
    });
    this.getDialogPopupVisibility(this.page, this.pageSize, this.params, true);
  }
  
  get dialogPopupVisibilityArr(): FormArray {
    return this.dialogPopupVisibilityArrayForm.get('dialogPopupVisibility') as FormArray;
  }

  private resetValue() {
    this.dialogPopupVisibility$ = [];
    this.dialogPopupVisibilityArr.clear();
    this.cdr.detectChanges();
  }

  onAddRow(isNew = false, data?: any) {
    this.checkValidation = false;
    let popup_id = data !== undefined ? data.popup_id : null;
    let is_default = data !== undefined ? data.is_default : null;
    let visibility = data !== undefined ? (data.is_default ? this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility : data.visibility) : null;
    let affiliate_id = data !== undefined ? data.affiliate_id : [];

    const form = new FormGroup({
      id: new FormControl(data !== undefined ? data.id : null),
      popup_id: new FormControl(popup_id, [Validators.required]),
      is_default: new FormControl(is_default),
      visibility: new FormControl(isNew && this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility != 1 ? this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility : visibility ?? null, [Validators.required]),
      affiliate_id: new FormControl(affiliate_id),
      mode: new FormControl(data !== undefined ? 'edit' : 'create')
    });

    if (isNew) {
      this.dialogPopupVisibilityArr.controls.slice().reverse().forEach((control, index) => {
        const currentGroup = this.dialogPopupVisibilityArr.controls[index];
        this.dialogPopupVisibilityArr.insert(index+1, currentGroup)
        this.dialogPopupVisibilityArr.removeAt(index);
      })

      this.dialogPopupVisibilityArr.insert(0, form);
    } 
    else {
      this.dialogPopupVisibilityArr.push(form);
    }

    if (this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 || this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 3) {
      this.affiliateDialogListDropdownList = this.affiliateDropdownList;
    }
    else {
      this.affiliateDialogListDropdownList = this.affiliateDropdownList.filter(x => this.dialogPopupVisibilityForm.value.dialog_affiliate_id.includes(x.id));
    }
    
    let indexDialog = this.dialogPopupVisibilityArr.length - 1;
    if (data !== undefined) {
      this.popupDropdownList.push(this.popupDropdownListAll.filter(x => x.id == data.popup_id)[0]);
      if (is_default == 1) {
        this.affiliateDialogListSelectedItems[indexDialog] = this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 ? [] : this.affiliateDialogListDropdownList.filter(x => this.dialogPopupVisibilityForm.value.dialog_affiliate_id.includes(x.id));
        this.dialogPopupVisibilityArr.controls[indexDialog].patchValue({
          affiliate_id: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 ? [] : this.affiliateDialogListSelectedItems[indexDialog].map(x => x.id),
        });
      }
      else {
        this.affiliateDialogListSelectedItems[indexDialog] = this.affiliateDialogListDropdownList.filter(x => affiliate_id.includes(x.id));
        this.dialogPopupVisibilityArr.controls[indexDialog].patchValue({
          affiliate_id: this.affiliateDialogListSelectedItems[indexDialog].length > 0 ? this.affiliateDialogListSelectedItems[indexDialog].map(x => x.id) : [],
        });
      }

      if (this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility != 1) {
        this.dialogPopupVisibilityArr.controls[indexDialog].patchValue({
          visibility: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility,
        });
      }
      
      this.dialogPopupVisibilityArr.controls.forEach(control => {
        control.disable();
      });
    } else {
      this.affiliateDialogListSelectedItems.unshift([]);
      this.isOnAddClicked = true;
    }
  }

  enableEdit(index: number) {
    this.backUpPopupDialogArr = ({...this.dialogPopupVisibilityArr.controls[index].value});
    this.dialogPopupVisibilityArr.controls.forEach(control => {
      control.disable();
    });
    this.dialogPopupVisibilityArr.controls[index].enable();
    this.cdr.detectChanges();
  }
  
  onDismissRow(mode: string, index: number) {
    switch (mode) {
      case 'create':
        this.dialogPopupVisibilityArr.removeAt(index);
        this.affiliateDialogListSelectedItems.shift();
        this.isOnAddClicked = false;
        break;
      case 'edit':
        this.dialogPopupVisibilityArr.controls[index].patchValue({ 
          ...this.backUpPopupDialogArr,
        })
        if (this.backUpPopupDialogArr['visibility'] == 1) {
          this.affiliateDialogListSelectedItems[index] = [];
          this.dialogPopupVisibilityArr.controls[index].patchValue({ 
            affiliate_id: [],
          })
        }
        else {
          this.affiliateDialogListSelectedItems[index] = this.affiliateDialogListDropdownList.filter(x => this.backUpPopupDialogArr.affiliate_id.includes(x.id));
        }

        this.dialogPopupVisibilityArr.controls[index].disable();
        break;
    }
    this.cdr.detectChanges();
  }

  onPopupDialogSave(row: any, index?: number,) {
    this.form.setErrors({ 'invalid': true });
    this.checkValidation = true;
    const dialogListData = row.mode === 'create' ? this.filterFormFields(row) : row;
    dialogListData['affiliate_group_id'] = this.data.groups.id;

    const dialogPopupVisibilityData = {
      dialog_popup_column_visibility: this.dialogPopupVisibilityForm.get('dialog_toggle').value ? +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value : 0,
    }
    if (this.dialogPopupVisibilityForm.get('dialog_toggle').value && +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value > 1) {
      dialogPopupVisibilityData['affiliate_id'] = this.dialogPopupVisibilityForm.get('dialog_affiliate_id').value;
    }

    if (this.currentActiveTab == 2) {  // Affiliate Dialog Popup Settings
      this.subscription = forkJoin([
        this.affiliateGroupsDataService.createUpdateDialogPopupVisibilityList(row.mode, dialogListData),
        this.affiliateGroupsDataService.updateDialogPopupVisibilitySettings(this.data.groups.id, dialogPopupVisibilityData),
      ]).pipe(
        tap(() => {
          this.form.setErrors(null);
        }),
        catchError((error) => {
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe(res => {
        this.data.dialogPopupVisibilitySettings = {
          ...this.data.dialogPopupVisibilitySettings,
          dialog_popup_column_visibility: dialogPopupVisibilityData['dialog_popup_column_visibility'],
          affiliate_id: dialogPopupVisibilityData['affiliate_id'] != undefined ? dialogPopupVisibilityData['affiliate_id'] : this.data.dialogPopupVisibilitySettings.affiliate_id,
        }
        this.formInit();
      });
    }
  }

  onPopupDialogDelete(id: any) {
    Swal.fire({
      title: 'System Alert',
      html: '<div>Are you sure you want to delete?<br><p style="color: red">This action will remove the associated dialog popup from the affiliate campaign settings.</p></div>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      reverseButtons: true,
      allowOutsideClick: false
    }).then((response) => {
      if (response.isConfirmed) {
        this.form.setErrors({ 'invalid': true });
        this.checkValidation = true;
        this.buttonLoading = true;

        const dialogPopupVisibilityData = {
          dialog_popup_column_visibility: this.dialogPopupVisibilityForm.get('dialog_toggle').value ? +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value : 0,
        }
        
        if (this.dialogPopupVisibilityForm.get('dialog_toggle').value && +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value > 1) {
          dialogPopupVisibilityData['affiliate_id'] = this.dialogPopupVisibilityForm.get('dialog_affiliate_id').value;
        }

        this.subscription = forkJoin([
          this.affiliateGroupsDataService.updateDialogPopupVisibilitySettings(this.data.groups.id, dialogPopupVisibilityData),
          this.affiliateGroupsDataService.deleteDialogPopupVisibilityList(id)
        ]).pipe(
          tap(() => {
            this.buttonLoading = false;
            this.form.setErrors(null);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe(res => {
          this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
        });
      }
    });
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
  }

  onViewPageBy(page = 1, pageSize?: number) {
    this.page = page;
    this.pageSize = pageSize;
    this.getDialogPopupVisibility(this.page, this.pageSize, this.params);
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && key !== 'mode') ? fields[key] = formData[key] : key);
    return fields;
  }

  getLocaleName (index: number) {
    if (this.dialogPopupVisibilityArr.controls[index].value.popup_id == null) {
      return '-';
    }
    else {
      return this.popupDropdownList.find(x => x.id == this.dialogPopupVisibilityArr.controls[index].value.popup_id).locale_name;
    }
  }

  onSelectedAffiliateDialogListItems($event: any, index: any) {
    if ($event.length == 0) {
      this.affiliateDialogListSelectedItems[index] = [];
    }
    else {
      this.affiliateDialogListSelectedItems[index] = $event;
    }
  }

  getPopupDropdownList(popId: any) {
    let selectedPopup = [];
    this.dialogPopupVisibilityArr.controls.forEach((control, index)=> {
      if (control.value.popup_id !== null ) {
        selectedPopup.push(parseInt(control.value.popup_id));
      }
    });

    const index = selectedPopup.indexOf(parseInt(popId));
    if (index > -1) {
      selectedPopup.splice(index, 1);
    }

    return this.popupDropdownList.filter(x => !selectedPopup.includes(x.id));
  }

  validationDialogList(i?: any) {
    if (i != undefined) {
      const dialogArr = ({...this.dialogPopupVisibilityArr.controls[i].value});
      if (dialogArr.popup_id !== null && dialogArr.visibility !== null) {
        if (dialogArr.is_default) {
          return false;
        }
        else {
          return false;
        }
      }
      else {
        return true;
      }  
    }
    else {
      const statues = this.dialogPopupVisibilityArr.controls.map(x => x.enabled);
      if (statues.includes(true)) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  isPopupDialogDefault(i: any) {
    const isDefault = this.dialogPopupVisibilityArr.controls[i].value.is_default;
    if (this.dialogPopupVisibilityArr.controls[i].disabled) {
      return true;
    }
    else {
      if (isDefault == true || isDefault == 1) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  onChangePopupDialogDefault(event: any, i: any) {
    if (event.target.checked) {
      this.dialogPopupVisibilityArr.controls[i].patchValue({ 
        visibility: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility,
        affiliate_id: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 ? [] : this.dialogPopupVisibilityForm.value.dialog_affiliate_id,
      });
      this.affiliateDialogListSelectedItems[i] = this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 ? [] : this.affiliateDropdownList.filter(x => this.dialogPopupVisibilityForm.value.dialog_affiliate_id.includes(x.id));
      this.cdr.detectChanges();
    }
    else {
      if (this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1) {
        this.dialogPopupVisibilityArr.controls[i].patchValue({ 
          visibility: null,
          affiliate_id: [],
        });
      }
      else {
        this.dialogPopupVisibilityArr.controls[i].patchValue({ 
          visibility: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility,
          affiliate_id: [],
        });
      }
      this.affiliateDialogListSelectedItems[i] = [];
      this.cdr.detectChanges();
    }
  }

  checkValidationAffiliateDialogList(i?: any) {
    if (!this.loading) {
      if (i !== undefined) {
        if ((!this.dialogPopupVisibilityArr.controls[i].value.is_default && this.affiliateDialogListSelectedItems[i].length == 0) ||
          (this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility != 1 && this.dialogPopupVisibilityForm.value.dialog_affiliate_id.length == 0)) {
          return true;
        }
        else {
          return false;
        }
      }
      else {
        if (this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility != 1 && this.dialogPopupVisibilityForm.value.dialog_affiliate_id.length == 0) {
          return true;
        }
        else {
          return false;
        }
      }
    }
  }

  onChangePopupDialogVisbility(event: any, i: any) {
    if (event.target.value) {
      this.dialogPopupVisibilityArr.controls[i].patchValue({ 
        visibility: event.target.value,
      });

      if (event.target.value == 1) {
        this.dialogPopupVisibilityArr.controls[i].patchValue({ 
          affiliate_id: this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 ? [] : this.dialogPopupVisibilityForm.value.dialog_affiliate_id,
        });
  
        this.affiliateDialogListSelectedItems[i] = this.dialogPopupVisibilityForm.value.dialog_popup_column_visibility == 1 ? [] : this.affiliateDialogSelectedList;
      }
    }
    else {
      this.dialogPopupVisibilityArr.controls[i].patchValue({ 
        visibility: null,
      });
    }
  }

  onBulkAddDialog() {
    const dialogRef = this.dialog.open(AffiliateGroupsDialogBulkAddComponent, {
      width: '800px',
      data: {
        mode: this.data.mode,
        groups: this.data.groups,
        dialogPopupVisibilitySettings: this.dialogPopupVisibilityForm.value,
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        const dialogPopupVisibilityData = {
          dialog_popup_column_visibility: this.dialogPopupVisibilityForm.get('dialog_toggle').value ? +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value : 0,
        }
        if (this.dialogPopupVisibilityForm.get('dialog_toggle').value && +this.dialogPopupVisibilityForm.get('dialog_popup_column_visibility').value > 1) {
          dialogPopupVisibilityData['affiliate_id'] = this.dialogPopupVisibilityForm.get('dialog_affiliate_id').value;
        }
        
        this.data.dialogPopupVisibilitySettings = {
          ...this.data.dialogPopupVisibilitySettings,
          dialog_popup_column_visibility: dialogPopupVisibilityData['dialog_popup_column_visibility'],
          affiliate_id: dialogPopupVisibilityData['affiliate_id'] != undefined ? dialogPopupVisibilityData['affiliate_id'] : this.data.dialogPopupVisibilitySettings.affiliate_id,
        }
        this.formInit();
      }
    });
  }
}
