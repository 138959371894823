import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Pagination } from '@core/models/pagination.model';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MemberPromotionDataService } from '../../../member-promotions/services/member-promotion-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { PromotionTransferStatus } from '@core/enums/member-promotion-transfer-status.enum';

@Component({
  selector: 'member-promotion-history',
  templateUrl: './member-promotion-history.component.html',
  styleUrls: ['./member-promotion-history.component.scss']
})
export class MemberPromotionHistoryComponent implements OnInit {

  promotion_id: number;
  memberPromotionHistory$: Observable<any>;
  promotionTransferStatus = PromotionTransferStatus;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  loading = false;

  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { promotion_id: number },
    public dialogRef: MatDialogRef<MemberPromotionHistoryComponent>,
    private loadingBar: LoadingBarService,
    private memberPromotionDataService: MemberPromotionDataService,
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit(): void {
    this.promotion_id = this.data.promotion_id;
    this.onViewPageBy();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.memberPromotionHistory$ = this.memberPromotionDataService.getPromotionHistory(`?page=${this.page}&perPage=${this.pageSize}`, this.data.promotion_id).pipe(
      tap(res => {
        this.loading = false;
        this.pagination = this.memberPromotionDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

}