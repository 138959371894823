<!-- <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right  kt-margin-b-10">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0"> -->

                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row pr-0 mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Keywords:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="keyword" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control">
                                        <option value="all" >All</option>
                                        <option *ngFor="let value of dropdown.currencies" [value]="value.id">{{ value.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Purpose:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="purpose" class="form-control">
                                        <option value="all" >All</option>
                                        <option *ngFor="let value of dropdown.bankPurposes | async" [value]="value.id">{{ value.name }}</option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Bank Types:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="bank_type" class="form-control">
                                        <option value="all"> All </option>
                                        <ng-container *ngFor="let item of (type == 'crypto' ? cryptoBankTypes : bankPaymentBankTypes)">
                                            <option [value]="item.id">{{ item.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 row pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="d-flex flex-wrap status-filter-pt">
                                        <ng-container *ngFor="let item of dropdown.statuses | keyvalue">
                                            <div class="custom-control custom-checkbox mr-3" *ngIf="+item.key >= 0">
                                                <input type="checkbox" [checked]="onCheckBox(item.key)" (change)="onSearchStatus($event, item.key)" class="custom-control-input" [id]="item.value">
                                                <label class="custom-control-label text-nowrap" [for]="item.value">{{ item.value }}</label>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </form>
                    <!-- </div>
                </div>
            </div> -->

            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="isTypeCrypto() ? canCreateCryptoMerchantBank : canCreateMerchantBank" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="toggle-column-area" (click)="toggleExpandableRows(); onExpandCollpseAll()">
                                        <div id="accordion">
                                            <span data-toggle="collapse" [attr.data-target]="'#collapse-0'" [attr.aria-expanded]="isRowsExpanded ? true : false"></span>
                                        </div>
                                    </th>
                                    <th width="5%" class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                    <th class="text-center width-100 sort-enabled" style="max-width: 100px; word-break: keep-all;" (click)="onSortColumn('currency')" [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">Currency</th>
                                    <th class="width-100 sort-enabled" (click)="onSortColumn('bank_type')" [ngClass]="sortingConfig.bank_type" [class.sort-selected]="sortingSelection.sort_by === 'bank_type'">Type</th>
                                    <th width="9%" class="sort-enabled" (click)="onSortColumn('bank_name')" [ngClass]="sortingConfig.bank_name" [class.sort-selected]="sortingSelection.sort_by === 'bank_name'">
                                        {{ isTypeCrypto() ? 'Payment Gateway' : 'Bank / Payment Gateway' }}
                                    </th>
                                    <th width="180px" class="sort-enabled" (click)="onSortColumn('account_name')" [ngClass]="sortingConfig.account_name" [class.sort-selected]="sortingSelection.sort_by === 'account_name'">Account Name</th>
                                    <th class="sort-enabled" (click)="onSortColumn('account_number')" [ngClass]="sortingConfig.account_number" [class.sort-selected]="sortingSelection.sort_by === 'account_number'">
                                        {{ isTypeCrypto() ? 'Account Number / Wallet Address' : 'Account Number / QR' }}
                                    </th>
                                    <th width="8%" class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                                    <th width="110px" class="sort-enabled" (click)="onSortColumn('purpose')" [ngClass]="sortingConfig.purpose" [class.sort-selected]="sortingSelection.sort_by === 'purpose'">Purpose</th>
                                    <th width="5%" class="text-center">{{ 'Remarks' }}</th>
                                    <th [width]="isTypeCrypto() ? '8%' : '5%'" class="text-center sort-enabled" (click)="onSortColumn('balance')" [ngClass]="sortingConfig.balance" [class.sort-selected]="sortingSelection.sort_by === 'balance'">Balance</th>
                                    <th width="5%" class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(merchantBanks$ | async) as rows">
                                <ng-container *ngFor="let row of rows; let i = index">
                                    <tr>
                                        <td class="c-p" (click)="onExpandCollpse(row.id, i)"> {{ rowIcon[i] }} </td>
                                        <td class="c-p" (click)="onExpandCollpse(row.id, i)"> {{ row.id }} </td>
                                        <td class="text-center" (click)="onExpandCollpse(row.id, i)"> {{ row.currency }} </td>
                                        <td class="c-p" (click)="onExpandCollpse(row.id, i)">{{ row.type_name }}</td>
                                        <td class="c-p" (click)="onExpandCollpse(row.id, i)">
                                            {{ row.bank_name }}
                                            <!-- <ng-container *ngIf="+row.bank_payment_gateway.length > 0">
                                                <span *ngFor="let item of row.bank_payment_gateway">
                                                    <br> {{ item }}
                                                </span>
                                            </ng-container> -->
                                        </td>
                                        <td class="c-p" (click)="onExpandCollpse(row.id, i)"> {{ row.account_name }} </td>
                                        <ng-container *ngIf="row.bank_type == 3 && row.qr_image; else elseAccountNumber">
                                            <td class="c-p" (click)="onExpandCollpse(row.id, i)">
                                                <div class="image-wrapper">
                                                    <img class="img-fluid" alt="QR Image" [src]="row.qr_image" />
                                                </div>
                                            </td>
                                        </ng-container>
                                        <ng-template #elseAccountNumber>
                                            <td class="c-p" (click)="onExpandCollpse(row.id, i)"> {{ row.account_number }} </td>
                                        </ng-template>
                                        <td class="text-center">
                                            <!-- <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + (row.status === 2 ? '11' : row.status)"> {{ status[row.status] }}</span> -->
                                            <div class="btn-group dropdown">
                                                <button type="button" class="btn btn-pill btn-sm dropdown-base"  [ngClass]="'kt-badge--' + (row.status === 2 ? '11' : row.status)"> {{ status[row.status] }} </button>
                                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                                <!-- <ng-container *ngIf="merchantBanksUseNewPermissions ? (canUpdateMerchantBankStatus || canEditMerchantBank) : (access$ | async).edit"> -->
                                                <ng-container *ngIf="isTypeCrypto() ? (canUpdateCryptoMerchantAccountsStatus || canEditCryptoMerchantAccount) : (canUpdateMerchantBankStatus || canEditMerchantBank)">
                                                    <button type="button" class="btn btn-pill btn-sm dropdown-toggle" [ngClass]="'kt-badge--' + (row.status === 2 ? '11' : row.status)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fa fa-angle-down"></i>
                                                    </button>
                                                    <div class="dropdown-menu">
                                                        <ng-container *ngFor="let key of object.keys(status)">
                                                            <button class="dropdown-item" type="button" (click)="onChangeStatus(key, row)" *ngIf="+key >= 0">{{ status[key] }}</button>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </td>
                                        <td
                                            class="c-p"
                                            (click)="onExpandCollpse(row.id, i)">
                                            {{ row.method == 1 || row.method == 3
                                                ? purposeEnum[row.merchant_bank_transaction_configs[0].purpose]
                                                : getPurposeNameForPaymentGateway(row.merchant_bank_transaction_configs)
                                            }}
                                        </td>
                                        <td>
                                            <span *ngIf="row.remarks" class="text-primary font-weight-bold c-p" [matTooltip]="row.remarks" (click)="onOpenDialog('remarksHistory', row.id, 0)">
                                                {{ onEllipsisWord(row.remarks) }}
                                            </span>
                                            <span *ngIf="!row.remarks">-</span>
                                        </td>
                                        <td
                                            class="text-right c-p"
                                            [ngClass]="{'background-yellow': +row.balance >= +row.max_balance}"
                                            (click)="onExpandCollpse(row.id, i)"
                                        >
                                            <ng-container *ngIf="isTypeCrypto(); else elseBlock">
                                                {{ row.currency }} {{ row.balance | number : '1.2-2' }}
                                                <br/>
                                                USDT {{ row.balance_usdt | number : '1.2-2' }}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                {{ row.balance | number : '1.2-2' }}
                                            </ng-template>
                                            
                                        </td>
                                        <td class="text-center">
                                            <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                            <!-- <button matTooltip="Edit Contact" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id, row.currency)" *ngIf="merchantBanksUseNewPermissions ? canEditMerchantBank : (access$ | async).edit"><i  class="fas fa-edit"></i></button> -->
                                            <button
                                                *ngIf="isTypeCrypto() ? canEditCryptoMerchantAccount : canEditMerchantBank"
                                                [matTooltip]="isTypeCrypto() ? 'Edit Crypto Account Settings' : 'Edit Merchant Bank'"
                                                class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"
                                                (click)="onOpenDialog('edit', row.id, row.currency)"
                                            >
                                                <i class="fas fa-edit"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <!-- slider details -->
                                    <!-- <tr class="detail" [id]="'detail-' + row.id">
                                        <td class="p-0 br-h"></td>
                                        <ng-container *ngIf="(+row.purpose === 1 || +row.purpose === 2 || +row.purpose === 5); else elsePurpose">
                                            <ng-container *ngIf="+row.purpose === 5 || +row.purpose === 1">
                                                <td [attr.colspan]="+row.purpose === 1 ? '4' : '2'" class="p-0 b-h">
                                                    <div>
                                                        <strong>Daily Deposit ($):</strong>
                                                        <span [ngClass]="{'red-text': +(extractAmounts(row.daily_deposit_amount)[0]) >= +(extractAmounts(row.daily_deposit_amount)[1]) && +(extractAmounts(row.daily_deposit_amount)[0]) > 0}">
                                                            {{ +(extractAmounts(row.daily_deposit_amount)[0]) | number : '1.2-2' }}
                                                        </span> /
                                                        {{ +(extractAmounts(row.daily_deposit_amount)[1]) | number : '1.2-2' }} <br />
                                                        <strong>Daily Deposit (#):</strong>
                                                        <span [ngClass]="{'red-text': +(extractAmounts(row. daily_deposit_count)[0]) >= +(extractAmounts(row. daily_deposit_count)[1]) && +(extractAmounts(row.daily_deposit_count)[0]) > 0}">
                                                            {{ +(extractAmounts(row. daily_deposit_count)[0]) | number : '1.0' }}
                                                        </span> /
                                                        {{ +(extractAmounts(row. daily_deposit_count)[1]) | number : '1.0' }}
                                                    </div>
                                                </td>
                                            </ng-container>
                                            <ng-container *ngIf="+row.purpose === 5 || +row.purpose === 2">
                                                <td [attr.colspan]="+row.purpose === 2 ? '4' : '2'" class="p-0 b-h">
                                                    <div>
                                                        <strong>Daily Withdrawal ($):</strong>
                                                        <span [ngClass]="{'red-text': +(extractAmounts(row.daily_withdrawal_amount)[0]) >= +(extractAmounts(row.daily_withdrawal_amount)[1]) && +(extractAmounts(row.daily_withdrawal_amount)[0]) > 0}">
                                                            {{ +(extractAmounts(row.daily_withdrawal_amount)[0]) | number : '1.2-2' }}
                                                        </span> /
                                                        {{ +(extractAmounts(row.daily_withdrawal_amount)[1]) | number : '1.2-2' }} <br />
                                                        <strong>Daily Withdrawal (#):</strong>
                                                        <span [ngClass]="{'red-text': +(extractAmounts(row.daily_withdrawal_count)[0]) >= +(extractAmounts(row.daily_withdrawal_count)[1]) && +(extractAmounts(row.daily_withdrawal_count)[0]) > 0}">
                                                            {{ +(extractAmounts(row.daily_withdrawal_count)[0]) | number : '1.0' }}
                                                        </span> /
                                                        {{ +(extractAmounts(row.daily_withdrawal_count)[1]) | number : '1.0' }}
                                                    </div>
                                                </td>
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #elsePurpose>
                                            <td colspan="4" class="p-0 b-h"></td>
                                        </ng-template>
                                        <td class="p-0 position-r o-f-h b-h">
                                            <div>
                                                <strong>Created By:</strong> {{ row.created_by ? row.created_by : '-' }}<br />
                                                {{ row.created_at  | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                                            </div>
                                        </td>
                                        <td class="p-0 b-h">
                                            <div>
                                                <strong>Updated By:</strong> {{ row.updated_by }}<br />
                                                {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                                            </div>
                                        </td>
                                        <td colspan="2" class="p-0" style="width: 18rem">
                                            <div>
                                                <ng-container *ngIf="row.remarks; else elseTemplate">
                                                    <span placement="top" container="body">
                                                        <strong>Remarks: &nbsp;</strong>
                                                        <span role="button" [ngbTooltip]="row.remarks" (click)="onOpenDialog('remarksHistory', row.id)">{{ onEllipsisWord(row.remarks) }}</span>
                                                    </span>
                                                </ng-container>
                                                <ng-template #elseTemplate>
                                                    <strong>Remarks:</strong> -
                                                </ng-template>
                                            </div>
                                        </td>
                                        <td colspan="2" class="p-0 bl-h">
                                            <div>
                                                <strong>Min / Max Balance:</strong> <br />{{ row.min_balance | number : '1.2-2' }} /  {{ row.max_balance | number : '1.2-2' }}
                                            </div>
                                        </td>
                                    </tr> -->
                                    <!-- slider details -->
                                    <tr class="detail" [id]="'detail-' + row.id">
                                        <!-- PAYMENT GATEWAY -->
                                        <td colspan="16" *ngIf="row.method == 2">
                                            <ng-container *ngFor="let data of row.merchant_bank_transaction_configs; let id = index">
                                                <table class="inner-table">
                                                    <tr class="first-tr-d">
                                                        <td class="first-td" colspan="2">
                                                            <span class="label-td-text">Payment Processor : </span>
                                                            <span class="content-td-text">{{ data.payment_gateway_name }}</span>
                                                        </td>
                                                        <td class="second-td" colspan="2">
                                                            <span class="label-td-text">Purpose : </span>
                                                            <span class="content-td-text">{{ purposeEnum[data.purpose] }}</span>
                                                        </td>
                                                        <td class="third-td" colspan="2">
                                                            <div class="btn-group dropdown">
                                                                <button type="button" class="btn btn-pill btn-sm dropdown-base"  [ngClass]="'kt-badge--' + (data.status === 2 ? '11' : data.status)"> {{ status[data.status] }} </button>
                                                                <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                                                <!-- <ng-container *ngIf="merchantBanksUseNewPermissions ? canUpdateMerchantBankStatus : (access$ | async).edit"> -->
                                                                <ng-container *ngIf="(canUpdateMerchantBankStatus || canEditMerchantBank)">
                                                                    <button type="button" class="btn btn-pill btn-sm dropdown-toggle" [ngClass]="'kt-badge--' + (data.status === 2 ? '11' : data.status)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <i class="fa fa-angle-down"></i>
                                                                    </button>
                                                                    <div class="dropdown-menu">
                                                                        <ng-container *ngFor="let key of object.keys(status)">
                                                                            <button class="dropdown-item" type="button" (click)="onChangeStatus(key, data)" *ngIf="+key >= 0">{{ status[key] }}</button>
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!-- PURPOSE 1 OR PURPOSE 2 -->
                                                    <!-- PURPOSE 1 OR 2 FIRST ROW -->
                                                    <tr class="second-tr-d" *ngIf="data.purpose == 1 || data.purpose == 2">
                                                        <td *ngIf="data.purpose == 1">
                                                            <span class="inner-data-label">Daily Max Deposit ($):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_deposit_amount }}</span>
                                                            <br>
                                                            <span class="inner-data-label">Daily Max Deposit (#):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_deposit_count }}</span>
                                                        </td>
                                                        <td *ngIf="data.purpose == 2">
                                                            <span class="inner-data-label">Daily Max Withdrawal ($):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_withdrawal_amount }}</span>
                                                            <br>
                                                            <span class="inner-data-label">Daily Max Withdrawal (#):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_withdrawal_count }}</span>
                                                        </td>
                                                        <td *ngIf="data.purpose == 1">
                                                            <span class="inner-data-label">Min / Max Deposit per Transaction:</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.min_deposit_per_transaction | number:'1.2-2' }} / {{ data.max_deposit_per_transaction | number:'1.2-2' }}</span>
                                                        </td>
                                                        <td *ngIf="data.purpose == 2">
                                                            <span class="inner-data-label">Min / Max Withdrawal per Transaction:</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.min_withdrawal_per_transaction | number:'1.2-2' }} / {{ data.max_withdrawal_per_transaction | number:'1.2-2' }}</span>
                                                        </td>
                                                        <td>
                                                            <span class="inner-data-label">Min / Max Balance:</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.min_balance | number:'1.2-2' }} / {{ data.max_balance | number:'1.2-2' }}</span>
                                                        </td>
                                                        <!-- Deposit Processing Fee -->
                                                        <td *ngIf="data.purpose == 1">
                                                            <span class="inner-data-label">Deposit Processing Fee:</span>
                                                            <br>
                                                            <span class="inner-data-content break-space">{{ getDepositProcessingFee(data) }}</span>
                                                            <br>
                                                            <span class="inner-data-label">Absorption By:</span>
                                                            <br>
                                                            <ng-container *ngIf="data.deposit_absorb_processing_fee_type == 1">
                                                                <span class="inner-data-content">Company: {{ data.company_deposit_absorb_percentage | number:'1.2-2' }}%</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="data.deposit_absorb_processing_fee_type == 2">
                                                                <span class="inner-data-content">Player: {{ data.player_deposit_absorb_percentage | number:'1.2-2' }}%</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="data.deposit_absorb_processing_fee_type == 3">
                                                                <span class="inner-data-content">
                                                                    Company: {{ data.company_deposit_absorb_percentage | number:'1.2-2' }}%
                                                                    Player: {{ data.player_deposit_absorb_percentage | number:'1.2-2' }}%
                                                                </span>
                                                            </ng-container>
                                                        </td>
                                                        <!-- Withdrawal Processing Fee -->
                                                        <td *ngIf="data.purpose == 2">
                                                            <span class="inner-data-label">Withdrawal Processing Fee:</span>
                                                            <br>
                                                            <span class="inner-data-content break-space">{{ getWithdrawalProcessingFee(data) }}</span>
                                                            <br>
                                                            <span class="inner-data-label">Absorption By:</span>
                                                            <br>
                                                            <ng-container *ngIf="data.withdrawal_absorb_processing_fee_type == 1">
                                                                <span class="inner-data-content">Company: {{ data.company_withdrawal_absorb_percentage | number:'1.2-2' }}%</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="data.withdrawal_absorb_processing_fee_type == 2">
                                                                <span class="inner-data-content">Player: {{ data.player_withdrawal_absorb_percentage | number:'1.2-2' }}%</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="data.withdrawal_absorb_processing_fee_type == 3">
                                                                <span class="inner-data-content">
                                                                    Company: {{ data.company_withdrawal_absorb_percentage | number:'1.2-2' }}%
                                                                    Player: {{ data.player_withdrawal_absorb_percentage | number:'1.2-2' }}%
                                                                </span>
                                                            </ng-container>
                                                        </td>
                                                        <td>
                                                            <ng-container *ngIf="data?.remarks; else elseTemplate">
                                                                <span placement="top" container="body">
                                                                    <strong>Remarks: &nbsp;</strong>
                                                                    <span role="button" class="text-primary font-weight-bold" [matTooltip]="data.remarks" (click)="onOpenDialog('remarksHistory', row.id, data.id)">{{ onEllipsisWord(data.remarks) }}</span>
                                                                </span>
                                                            </ng-container>
                                                            <ng-template #elseTemplate>
                                                                <strong>Remarks:</strong> -
                                                            </ng-template>
                                                        </td>
                                                        <td>
                                                            <span class="inner-data-label">Position:</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.position }}</span>
                                                        </td>
                                                    </tr>
                                                    <!-- PURPOSE 3,4,5 -->
                                                    <!-- PURPOSE 3,4,5 FIRST ROW -->
                                                    <tr class="second-tr-d" *ngIf="data.purpose == 3 || data.purpose == 4 || data.purpose == 5">
                                                        <td>
                                                            <span class="inner-data-label">Daily Max Deposit ($):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_deposit_amount }}</span>
                                                            <br>
                                                            <span class="inner-data-label">Daily Max Deposit (#):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_deposit_count }}</span>
                                                        </td>
                                                        <td>
                                                            <span class="inner-data-label">Daily Max Withdrawal ($):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_withdrawal_amount }}</span>
                                                            <br>
                                                            <span class="inner-data-label">Daily Max Withdrawal (#):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_withdrawal_count }}</span>
                                                        </td>
                                                        <td>
                                                            <span class="inner-data-label">Min / Max Deposit per Transaction:</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.min_deposit_per_transaction | number:'1.2-2' }} / {{ data.max_deposit_per_transaction | number:'1.2-2' }}</span>
                                                        </td>
                                                        <td>
                                                            <span class="inner-data-label">Min / Max Withdrawal per Transaction:</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.min_withdrawal_per_transaction | number:'1.2-2' }} / {{ data.max_withdrawal_per_transaction | number:'1.2-2' }}</span>
                                                        </td>
                                                        <td>
                                                            <span class="inner-data-label">Min / Max Balance:</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.min_balance | number:'1.2-2' }} / {{ data.max_balance | number:'1.2-2' }}</span>
                                                        </td>
                                                        <!-- Deposit Processing Fee -->
                                                        <td>
                                                            <span class="inner-data-label">Deposit Processing Fee:</span>
                                                            <br>
                                                            <span class="inner-data-content break-space">{{ getDepositProcessingFee(data) }}</span>
                                                            <br>
                                                            <span class="inner-data-label">Absorption By:</span>
                                                            <br>
                                                            <ng-container *ngIf="data.deposit_absorb_processing_fee_type == 1">
                                                                <span class="inner-data-content">Company: {{ data.company_deposit_absorb_percentage | number:'1.2-2' }}%</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="data.deposit_absorb_processing_fee_type == 2">
                                                                <span class="inner-data-content">Player: {{ data.player_deposit_absorb_percentage | number:'1.2-2' }}%</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="data.deposit_absorb_processing_fee_type == 3">
                                                                <span class="inner-data-content">
                                                                    Company: {{ data.company_deposit_absorb_percentage | number:'1.2-2' }}%
                                                                    Player: {{ data.player_deposit_absorb_percentage | number:'1.2-2' }}%
                                                                </span>
                                                            </ng-container>
                                                        </td>
                                                    </tr>
                                                    <!-- PURPOSE 3,4,5 SECOND ROW -->
                                                    <tr class="second-tr-d" *ngIf="data.purpose == 3 || data.purpose == 4 || data.purpose == 5">
                                                        <!-- Withdrawal Processing Fee -->
                                                        <td>
                                                            <span class="inner-data-label">Withdrawal Processing Fee:</span>
                                                            <br>
                                                            <span class="inner-data-content break-space">{{ getWithdrawalProcessingFee(data) }}</span>
                                                            <br>
                                                            <span class="inner-data-label">Absorption By:</span>
                                                            <br>
                                                            <ng-container *ngIf="data.withdrawal_absorb_processing_fee_type == 1">
                                                                <span class="inner-data-content">Company: {{ data.company_withdrawal_absorb_percentage | number:'1.2-2' }}%</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="data.withdrawal_absorb_processing_fee_type == 2">
                                                                <span class="inner-data-content">Player: {{ data.player_withdrawal_absorb_percentage | number:'1.2-2' }}%</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="data.withdrawal_absorb_processing_fee_type == 3">
                                                                <span class="inner-data-content">
                                                                    Company: {{ data.company_withdrawal_absorb_percentage | number:'1.2-2' }}%
                                                                    Player: {{ data.player_withdrawal_absorb_percentage | number:'1.2-2' }}%
                                                                </span>
                                                            </ng-container>
                                                        </td>
                                                        <td>
                                                            <ng-container *ngIf="data?.remarks; else elseTemplate">
                                                                <span placement="top" container="body">
                                                                    <strong>Remarks: &nbsp;</strong>
                                                                    <span role="button" class="text-primary font-weight-bold" [matTooltip]="data.remarks" (click)="onOpenDialog('remarksHistory', row.id, data.id)">{{ onEllipsisWord(data.remarks) }}</span>
                                                                </span>
                                                            </ng-container>
                                                            <ng-template #elseTemplate>
                                                                <strong>Remarks:</strong> -
                                                            </ng-template>
                                                        </td>
                                                        <td colspan="4">
                                                            <span class="inner-data-label">Position:</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.position }}</span>
                                                        </td>
                                                    </tr>
                                                    <tr class="last-tr-d">
                                                        <td class="second-td" colspan="6">
                                                            <span class="label-td-text">Created By : </span>
                                                            <span class="content-td-text pr-3">{{ row.created_by ? row.created_by : '-' }} {{ '(' }} {{ row.created_at ? (row.created_at | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }} {{ ')' }}</span>
                                                            <span class="label-td-text">Updated By : </span>
                                                            <span class="content-td-text">{{ row.updated_by ? row.updated_by : '-' }} {{ '(' }} {{ row.updated_at ? (row.updated_at | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }} {{ ')' }}</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </ng-container>
                                        </td>
                                        <!-- BANK -->
                                        <td colspan="16" *ngIf="row.method == 1 || row.method == 3">
                                            <table class="inner-table">
                                                <tr class="first-tr-d">
                                                    <td class="first-td" colspan="2">
                                                        <span class="label-td-text">Bank : </span>
                                                        <span class="content-td-text">{{ row.bank_name }}</span>
                                                    </td>
                                                    <td class="second-td" colspan="2">
                                                        <span class="label-td-text">Purpose : </span>
                                                        <span class="content-td-text">{{ purposeEnum[row.merchant_bank_transaction_configs[0].purpose] }}</span>
                                                    </td>
                                                    <td class="third-td" colspan="2">
                                                    </td>
                                                </tr>
                                                <!-- PURPOSE 1 OR PURPOSE 2 -->
                                                <!-- PURPOSE 1 OR 2 FIRST ROW -->
                                                <tr class="second-tr-d" *ngIf="row.merchant_bank_transaction_configs[0].purpose == 1 || row.merchant_bank_transaction_configs[0].purpose == 2">
                                                    <td *ngIf="row.merchant_bank_transaction_configs[0].purpose == 1">
                                                        <span class="inner-data-label">Daily Max Deposit($):</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.daily_deposit_amount }}</span>
                                                        <br>
                                                        <span class="inner-data-label">Daily Max Deposit (#):</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.daily_deposit_count }}</span>
                                                    </td>
                                                    <td *ngIf="row.merchant_bank_transaction_configs[0].purpose == 2">
                                                        <span class="inner-data-label">Daily Max Withdrawal ($):</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.daily_withdrawal_amount }}</span>
                                                        <br>
                                                        <span class="inner-data-label">Daily Max Withdrawal (#):</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.daily_withdrawal_count }}</span>
                                                    </td>
                                                    <td *ngIf="row.merchant_bank_transaction_configs[0].purpose == 1">
                                                        <span class="inner-data-label">Min / Max Deposit per Transaction:</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.merchant_bank_transaction_configs[0].min_deposit_per_transaction | number:'1.2-2' }} / {{ row.merchant_bank_transaction_configs[0].max_deposit_per_transaction | number:'1.2-2' }}</span>
                                                    </td>
                                                    <td *ngIf="row.merchant_bank_transaction_configs[0].purpose == 2">
                                                        <span class="inner-data-label">Min / Max Withdrawal per Transaction:</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.merchant_bank_transaction_configs[0].min_withdrawal_per_transaction | number:'1.2-2' }} / {{ row.merchant_bank_transaction_configs[0].max_withdrawal_per_transaction | number:'1.2-2' }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="inner-data-label">Min / Max Balance:</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.merchant_bank_transaction_configs[0].min_balance | number:'1.2-2' }} / {{ row.merchant_bank_transaction_configs[0].max_balance | number:'1.2-2' }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="inner-data-label">Bot Config:</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ 'Username' }}: {{ row.username }}</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ 'Password' }}: {{ row.password }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="inner-data-label">Position:</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.merchant_bank_transaction_configs[0].position }}</span>
                                                    </td>
                                                </tr>
                                                <!-- PURPOSE 3,4,5 -->
                                                <!-- PURPOSE 3,4,5 FIRST ROW-->
                                                <tr class="second-tr-d" *ngIf="row.merchant_bank_transaction_configs[0].purpose == 3 || row.merchant_bank_transaction_configs[0].purpose == 4 || row.merchant_bank_transaction_configs[0].purpose == 5">
                                                    <td>
                                                        <span class="inner-data-label">Daily Max Deposit ($):</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.daily_deposit_amount }}</span>
                                                        <br>
                                                        <span class="inner-data-label">Daily Max Deposit (#):</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.daily_deposit_count }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="inner-data-label">Daily Max Withdrawal ($):</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.daily_withdrawal_amount }}</span>
                                                        <br>
                                                        <span class="inner-data-label">Daily Max Withdrawal (#):</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.daily_withdrawal_count }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="inner-data-label">Min / Max Deposit per Transaction:</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.merchant_bank_transaction_configs[0].min_deposit_per_transaction | number:'1.2-2' }} / {{ row.merchant_bank_transaction_configs[0].max_deposit_per_transaction | number:'1.2-2' }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="inner-data-label">Min / Max Withdrawal per Transaction:</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.merchant_bank_transaction_configs[0].min_withdrawal_per_transaction | number:'1.2-2' }} / {{ row.merchant_bank_transaction_configs[0].max_withdrawal_per_transaction | number:'1.2-2' }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="inner-data-label">Min / Max Balance</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.merchant_bank_transaction_configs[0].min_balance | number:'1.2-2' }} / {{ row.merchant_bank_transaction_configs[0].max_balance | number:'1.2-2' }}</span>
                                                    </td>
                                                    <td>
                                                        <span class="inner-data-label">Bot Config:</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ 'Username' }}: {{ row.merchant_bank_transaction_configs[0].username }}</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ 'Password' }}: {{ row.merchant_bank_transaction_configs[0].password }}</span>
                                                    </td>
                                                </tr>
                                                <!-- PURPOSE 3,4,5 SECOND ROW -->
                                                <tr class="second-tr-d" *ngIf="row.merchant_bank_transaction_configs[0].purpose == 3 || row.merchant_bank_transaction_configs[0].purpose == 4 || row.merchant_bank_transaction_configs[0].purpose == 5">
                                                    <td colspan="4">
                                                        <span class="inner-data-label">Position:</span>
                                                        <br>
                                                        <span class="inner-data-content">{{ row.merchant_bank_transaction_configs[0].position }}</span>
                                                    </td>
                                                </tr>
                                                <tr class="last-tr-d">
                                                    <td class="second-td" colspan="6">
                                                        <span class="label-td-text">Created By : </span>
                                                        <span class="content-td-text pr-3">{{ row.created_by ? row.created_by : '-' }} {{ '(' }} {{ row.created_at ? (row.created_at | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }} {{ ')' }}</span>
                                                        <span class="label-td-text">Updated By : </span>
                                                        <span class="content-td-text">{{ row.updated_by ? row.updated_by : '-' }} {{ '(' }} {{ row.updated_at ? (row.updated_at | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }} {{ ')' }}</span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>

                                        <!-- CRYPTO -->
                                        <td colspan="16" *ngIf="row.method == 4">
                                            <ng-container *ngFor="let data of row.merchant_bank_transaction_configs; let id = index">
                                                <table class="inner-table">
                                                    <tr class="first-tr-d">
                                                        <td class="first-td" colspan="2">
                                                            <span class="label-td-text">Payment Processor : </span>
                                                            <span class="content-td-text">{{ data.payment_gateway_name }}</span>
                                                        </td>
                                                        <td class="second-td" colspan="2">
                                                            <span class="label-td-text">Purpose : </span>
                                                            <span class="content-td-text">{{ purposeEnum[data.purpose] }}</span>
                                                        </td>
                                                        <td class="third-td" colspan="2">
                                                            <div class="btn-group dropdown">
                                                                <button type="button" class="btn btn-pill btn-sm dropdown-base"  [ngClass]="'kt-badge--' + (data.status === 2 ? '11' : data.status)"> {{ status[data.status] }} </button>
                                                                <ng-container *ngIf="(canUpdateCryptoMerchantAccountsStatus || canEditCryptoMerchantAccount)">
                                                                    <button type="button" class="btn btn-pill btn-sm dropdown-toggle" [ngClass]="'kt-badge--' + (data.status === 2 ? '11' : data.status)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <i class="fa fa-angle-down"></i>
                                                                    </button>
                                                                    <div class="dropdown-menu">
                                                                        <ng-container *ngFor="let key of object.keys(status)">
                                                                            <button class="dropdown-item" type="button" (click)="onChangeStatus(key, data)" *ngIf="+key >= 0">{{ status[key] }}</button>
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <!-- First row -->
                                                    <tr class="second-tr-d font-weight-bold" style="background-color: #f4fdf4;"><td colspan="12">Currency: {{ row.currency }}</td></tr>
                                                    <tr class="second-tr-d" style="background-color: #f4fdf4;">
                                                        <td *ngIf="data.purpose != 2">
                                                            <span class="inner-data-label">Daily Max Deposit ($):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_deposit_amount }}</span>
                                                            <br>
                                                            <span class="inner-data-label">Daily Max Deposit (#):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_deposit_count }}</span>
                                                        </td>
                                                        <td *ngIf="data.purpose != 1">
                                                            <span class="inner-data-label">Daily Max Withdrawal ($):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_withdrawal_amount }}</span>
                                                            <br>
                                                            <span class="inner-data-label">Daily Max Withdrawal (#):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_withdrawal_count }}</span>
                                                        </td>
                                                        <td *ngIf="data.purpose != 2">
                                                            <span class="inner-data-label">Min / Max Deposit per Transaction:</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.min_deposit_per_transaction | number:'1.2-2' }} / {{ data.max_deposit_per_transaction | number:'1.2-2' }}</span>
                                                        </td>
                                                        <td *ngIf="data.purpose != 1">
                                                            <span class="inner-data-label">Min / Max Withdrawal per Transaction:</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.min_withdrawal_per_transaction | number:'1.2-2' }} / {{ data.max_withdrawal_per_transaction | number:'1.2-2' }}</span>
                                                        </td>
                                                        <td colspan="12">
                                                            <span class="inner-data-label">Min / Max Balance:</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.min_balance | number:'1.2-2' }} / {{ data.max_balance | number:'1.2-2' }}</span>
                                                        </td>
                                                    </tr>

                                                    <!-- Second row -->
                                                    <tr class="second-tr-d font-weight-bold" style="background-color: #f0f8ff;"><td colspan="12">Currency: USDT</td></tr>
                                                    <tr class="second-tr-d" style="background-color: #f0f8ff;">
                                                        <td *ngIf="data.purpose != 2">
                                                            <span class="inner-data-label">Daily Max Deposit ($):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_deposit_amount_usdt }}</span>
                                                            <br>
                                                            <span class="inner-data-label">Daily Max Deposit (#):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_deposit_count }}</span>
                                                        </td>
                                                        <td *ngIf="data.purpose != 1">
                                                            <span class="inner-data-label">Daily Max Withdrawal ($):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_withdrawal_amount_usdt }}</span>
                                                            <br>
                                                            <span class="inner-data-label">Daily Max Withdrawal (#):</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.daily_withdrawal_count }}</span>
                                                        </td>
                                                        <td *ngIf="data.purpose != 2">
                                                            <span class="inner-data-label">Min / Max Deposit per Transaction:</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.min_deposit_per_transaction_usdt | number:'1.2-2' }} / {{ data.max_deposit_per_transaction_usdt | number:'1.2-2' }}</span>
                                                        </td>
                                                        <td *ngIf="data.purpose != 1">
                                                            <span class="inner-data-label">Min / Max Withdrawal per Transaction:</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.min_withdrawal_per_transaction_usdt | number:'1.2-2' }} / {{ data.max_withdrawal_per_transaction_usdt | number:'1.2-2' }}</span>
                                                        </td>
                                                        <td colspan="12">
                                                            <span class="inner-data-label">Min / Max Balance:</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.min_balance_usdt | number:'1.2-2' }} / {{ data.max_balance_usdt | number:'1.2-2' }}</span>
                                                        </td>
                                                    </tr>

                                                    <!-- third row -->
                                                    <tr class="second-tr-d">
                                                        <td *ngIf="data.purpose != 2">
                                                            <span class="inner-data-label">Deposit Processing Fee:</span>
                                                            <br>
                                                            <span class="inner-data-content break-space">{{ getDepositProcessingFee(data) }}</span>
                                                            <br>
                                                            <span class="inner-data-label">Absorption By:</span>
                                                            <br>
                                                            <ng-container *ngIf="data.deposit_absorb_processing_fee_type == 1">
                                                                <span class="inner-data-content">Company: {{ data.company_deposit_absorb_percentage | number:'1.2-2' }}%</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="data.deposit_absorb_processing_fee_type == 2">
                                                                <span class="inner-data-content">Player: {{ data.player_deposit_absorb_percentage | number:'1.2-2' }}%</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="data.deposit_absorb_processing_fee_type == 3">
                                                                <span class="inner-data-content">
                                                                    Company: {{ data.company_deposit_absorb_percentage | number:'1.2-2' }}% 
                                                                    Player: {{ data.player_deposit_absorb_percentage | number:'1.2-2' }}%
                                                                </span>
                                                            </ng-container>
                                                        </td>
                                                        <!-- Withdrawal Processing Fee -->
                                                        <td *ngIf="data.purpose != 1">
                                                            <span class="inner-data-label">Withdrawal Processing Fee:</span>
                                                            <br>
                                                            <span class="inner-data-content break-space">{{ getWithdrawalProcessingFee(data) }}</span>
                                                            <br>
                                                            <span class="inner-data-label">Absorption By:</span>
                                                            <br>
                                                            <ng-container *ngIf="data.withdrawal_absorb_processing_fee_type == 1">
                                                                <span class="inner-data-content">Company: {{ data.company_withdrawal_absorb_percentage | number:'1.2-2' }}%</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="data.withdrawal_absorb_processing_fee_type == 2">
                                                                <span class="inner-data-content">Player: {{ data.player_withdrawal_absorb_percentage | number:'1.2-2' }}%</span>
                                                            </ng-container>
                                                            <ng-container *ngIf="data.withdrawal_absorb_processing_fee_type == 3">
                                                                <span class="inner-data-content">
                                                                    Company: {{ data.company_withdrawal_absorb_percentage | number:'1.2-2' }}% 
                                                                    Player: {{ data.player_withdrawal_absorb_percentage | number:'1.2-2' }}%
                                                                </span>
                                                            </ng-container>
                                                        </td>
                                                        <td>
                                                            <ng-container *ngIf="data?.remarks; else elseTemplate">
                                                                <span placement="top" container="body">
                                                                    <strong>Remarks: &nbsp;</strong>
                                                                    <span role="button" class="text-primary font-weight-bold" [matTooltip]="data.remarks" (click)="onOpenDialog('remarksHistory', row.id, data.id)">{{ onEllipsisWord(data.remarks) }}</span>
                                                                </span>
                                                            </ng-container>
                                                            <ng-template #elseTemplate>
                                                                <strong>Remarks:</strong> -
                                                            </ng-template>
                                                        </td>
                                                        <td>
                                                            <span class="inner-data-label">Position:</span>
                                                            <br>
                                                            <span class="inner-data-content">{{ data.position }}</span>
                                                        </td>
                                                    </tr>
                                                    <tr class="last-tr-d">
                                                        <td class="second-td" colspan="6">
                                                            <span class="label-td-text">Created By : </span>
                                                            <span class="content-td-text pr-3">{{ row.created_by ? row.created_by : '-' }} {{ '(' }} {{ row.created_at ? (row.created_at | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }} {{ ')' }}</span>
                                                            <span class="label-td-text">Updated By : </span>
                                                            <span class="content-td-text">{{ row.updated_by ? row.updated_by : '-' }} {{ '(' }} {{ row.updated_at ? (row.updated_at | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }} {{ ')' }}</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </ng-container>
                                        </td>

                                    </tr>
                                </ng-container>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        <!-- </div>
    </div>
</div> -->
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
