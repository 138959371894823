<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ data.mode === 'edit'? 'Edit' : 'Create' }} Member Bank Account</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body">
          <div class="col-12 form-group row pr-0">
              <label class="col-4 col-form-label">Member</label>
              <select class="form-control col-8" disabled>
                <option [value]="null">{{ data.member.id }} - {{ data.member.name }}</option>
            </select>
          </div>
          <div class="col-12 form-group row pr-0">
              <label class="col-4 col-form-label">Currency</label>
              <label class="col-8 col-form-label">{{ data.member.currency_code }}</label>
          </div>
          <div class="col-12 form-group row pr-0">
            <label class="col-4 col-form-label">Bank Type<span class="text-danger">*</span></label>
            <select class="form-control col-8" formControlName="bank_type" (change)="onBankType($event)">
              <option [value]="null" [disabled]="true">Please Select</option>
              <ng-container *ngFor="let type of dropdown.bankType">
                <option [value]="type.id"> {{ type.name }}</option>
              </ng-container>
            </select>
          </div>
          <div class="col-12 form-group row pr-0">
              <label class="col-4 col-form-label">Bank <span class="text-danger">*</span></label>
              <kt-dropdown-wo-lazyload
                id="bankDropdown"
                class="col-8"
                style="padding:0"
                [form] = 'form'
                [dropdownList] = 'filteredBankDropdownList'
                [dropdownSettings] = 'bankDropdownSettings'
                [formName] = "'bank_id'"
                [selectionAttributes] = "'id'"
                [selectedItems] = 'bankSelectedItems'
                (selectedItemsChanged)="onBankChanged($event)"
                >
              </kt-dropdown-wo-lazyload>
          </div>
          <ng-container *ngIf="selectedBank === 'paynow'">
            <div class="col-12 form-group row pr-0">
                <label class="col-4 col-form-label">Mobile Number</label>
                <input type="text" (keyup)="onValidatePayNowData()" pattern="[0-9]*" (wheel)="false" formControlName="mobile_number" class="col-8 form-control" (keypress)="numberOnly($event)">
            </div>
            <div class="col-12 form-group row pr-0">
                <label class="col-4 col-form-label">NRIC/FIN</label>
                <input type="text" (keyup)="onValidatePayNowData()" formControlName="nric_fin" class="col-8 form-control">
            </div>
            <div class="col-12 form-group row pr-0">
                <label class="col-4 col-form-label">UEN</label>
                <input type="text" (keyup)="onValidatePayNowData()" formControlName="unique_entity_name" class="col-8 form-control">
            </div>
            <div class="col-12 form-group row pr-0">
                <label class="col-4 col-form-label">VPA</label>
                <input type="text" (keyup)="onValidatePayNowData()" formControlName="virtual_payment_address" class="col-8 form-control">
            </div>
          </ng-container>
          <div class="col-12 form-group row pr-0" *ngIf="selectedBank == 'others'">
            <label class="col-4 col-form-label">Bank Name <span class="text-danger">*</span></label>
            <input type="text" formControlName="bank_name" class="col-8 form-control">
          </div>
          <ng-container *ngIf="(selectedBank !== 'paynow' && selectedBank !== '') || data.member.currency_code !== 'SGD'">
            <div class="col-12 form-group row pr-0">
                <label class="col-4 col-form-label">Account Name <span class="text-danger">*</span></label>
                <input type="text" formControlName="account_name" class="col-8 form-control">
            </div>
            <div class="col-12 form-group row pr-0">
                <label class="col-4 col-form-label">{{ selectedBankType === 'Ewallet'? 'Ewallet' : 'Account' }} Number <span class="text-danger">*</span></label>
                <input type="text" pattern="[0-9]*" (wheel)="false" formControlName="account_number" class="col-8 form-control">
            </div>
          </ng-container>
          <div class="col-12 form-group row pr-0" formArrayName="document" *ngIf="selectedBankType === 'Ewallet' && data.member.currency_code == 'IDR'">
            <label class="col-4 col-form-label">Upload Document <span class="text-danger">*</span></label>
            <div class="col-8 input-wrapper pl-0 pr-0" >
              <div class="d-flex mt-2" *ngFor="let document of form.get('document').controls; let i = index">
                <label class="btn btn-default btn-sm image-button">
                  <span>Browse</span>
                  <input class="form-control" type="file" hidden accept="image/*,video/*,.pdf" (change)="onUploadFile($event,i)">
                </label>
                <span class="form-control filename" style="cursor: pointer;" (click)="onOpenDialog('show-document', { value: document.value })">{{ fileName[i] ? fileName[i] : 'Choose Files' }}</span>
                <button class="btn btn-remove" *ngIf="!isFileUploading[i]" (click)="removeDocument(i)" > <span>x</span></button>
                <div class="spinner-wrapper d-flex align-items-center" *ngIf="isFileUploading[i]">
                  <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
                </div>
              </div>
              <button class="btn btn-warning mt-4" (click)="addDocument()"><i class="fa fa-plus" aria-hidden="true"></i> Add </button>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button [isDisabled]="!form.valid || !isPaynowDataValid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
        </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
