import { AllAffiliatesDataService } from '../../services/all-affiliates-data.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import moment from 'moment';
import { Subscription, forkJoin } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Pagination } from '@core/models/pagination.model';

@Component({
  selector: 'kt-affiliate-member',
  templateUrl: './affiliate-member.component.html',
  styleUrls: ['./affiliate-member.component.scss']
})
export class AffiliateMemberComponent implements OnInit {

  pageSize = 30;
  page = 1;
  params = `affiliate_id=${this.data.affiliate.id}`;
  perPageDropdown = this.dropdownHttpService.perPage;
  pagination: Pagination;
  maxSize = 5;
  totalMember = 0;
  form: FormGroup;
  button_loading = false;
  searchBtnLoading = false;
  affiliateMember$: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { affiliate: any },
    private allAffiliatesDataService: AllAffiliatesDataService,
    public dialogRef: MatDialogRef<AffiliateMemberComponent>,
    private dropdownHttpService: DropdownHttpService,
  ) { }

  ngOnInit() {
    this.getAffiliateMemberData();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  private getAffiliateMemberData(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.button_loading = true;
    this.affiliateMember$ = [];

    this.allAffiliatesDataService.getMembers(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.allAffiliatesDataService.pagination;
        this.totalMember = this.pagination.total;
        this.affiliateMember$ = res;
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.getAffiliateMemberData(this.page, this.pageSize, this.params);
  }
}
