<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <form class="align-items-center" [formGroup]="form">
                            <!-- First Row -->
                            <div class="row mb-2">
                                <!-- ID -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>ID:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <input type="text" placeholder="Search" class="form-control" formControlName="id">
                                </div>
                                <!-- Keyword -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Keyword:</label>
                                </div>
                                <div class="col-md-2 kt-form__control ">
                                    <input type="text" placeholder="Search" class="form-control" formControlName="keyword">
                                </div>
                                <!-- Currency -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="currency_id">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <option value="all">All</option>
                                        <ng-container *ngFor="let value of dropdown.currencies">
                                            <option [value]="value.id">{{ value.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <!-- Status -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select class="form-control" formControlName="status">
                                        <ng-container *ngFor="let status of dropdown.bankReceiptStatuses">
                                            <option [value]="status.value">{{ status.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <!-- Second Row -->
                            <div class="row mb-2">
                                <!-- Receipt Type -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Receipt Type:</label>
                                </div>
                                <div class="col-md-5 kt-form__control d-flex">
                                    <select class="form-control w-50 mr-1" formControlName="type">
                                        <ng-container *ngFor="let type of dropdown.bankReceiptTypes">
                                            <option [value]="type.value">{{ type.name }}</option>
                                        </ng-container>
                                    </select>
                                    <select class="form-control w-50" formControlName="transaction_type">
                                        <ng-container *ngFor="let transactionType of dropdown.bankReceiptTransactionTypes">
                                            <option [value]="transactionType.value">{{ transactionType.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                                <!-- Bank Account -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Bank Account:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                        [form] = 'form'
                                        [dropdownList] = 'bankaccountDropdownList'
                                        [dropdownSettings] = 'bankaccountDropdownSettings'
                                        [formName] = "'merchant_bank_id'"
                                        [selectionAttributes] = "'id'"
                                        [selectedItems] = 'bankaccountSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                            </div>
                            <!-- Third Row -->
                            <div class="row mb-2">
                                <!-- Date Time -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-7 kt-form__control d-flex">
                                    <select class="form-control mr-1" style="width: 50%;" formControlName="date_time_type">
                                        <ng-container *ngFor="let dateTimeType of dropdown.bankReceiptDateTimeTypes">
                                            <option [value]="dateTimeType.value">{{ dateTimeType.name }}</option>
                                        </ng-container>
                                    </select>
                                    <div class="input-group date">
                                        <input type="text" class="form-control" style="width: 50%;" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true"
                                            [alwaysShowCalendars]="true" [ranges]="ranges"
                                            [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                            [timePicker24Hour]="true" [showDropdowns]="true" [opens]="'left'" (click)="updateDateRange()"
                                            ngxDaterangepickerMd/>
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Action Button -->
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canCreateBankReceipt" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>
            <!-- Table -->
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingSelection.sort_by == 'id' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">ID</th>
                                    <th class="sort-enabled" (click)="onSortColumn('bank_account')" [ngClass]="sortingSelection.sort_by == 'bank_account' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Bank Account</th>
                                    <th class="sort-enabled" (click)="onSortColumn('receipt_type')" [ngClass]="sortingSelection.sort_by == 'receipt_type' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Receipt Type</th>
                                    <th class="sort-enabled" (click)="onSortColumn('desc')" [ngClass]="sortingSelection.sort_by == 'desc' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Description</th>
                                    <th class="sort-enabled info-column" (click)="onSortColumn('date_time')" [ngClass]="sortingSelection.sort_by == 'date_time' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Transaction Timestamp</th>
                                    <th class="sort-enabled text-center" (click)="onSortColumn('currency')" [ngClass]="sortingSelection.sort_by == 'currency' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Currency</th>
                                    <th class="sort-enabled text-center" (click)="onSortColumn('amount')" [ngClass]="sortingSelection.sort_by == 'amount' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Amount</th>
                                    <th class="sort-enabled text-center" (click)="onSortColumn('status')" [ngClass]="sortingSelection.sort_by == 'status' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Status</th>
                                    <th class="sort-enabled" (click)="onSortColumn('remark')" [ngClass]="sortingSelection.sort_by == 'remark' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Remarks</th>
                                    <th class="sort-enabled info-column" (click)="onSortColumn('created_at')" [ngClass]="sortingSelection.sort_by == 'created_at' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Scanned / Created By</th>
                                    <th class="sort-enabled info-column" (click)="onSortColumn('updated_at')" [ngClass]="sortingSelection.sort_by == 'updated_at' ? 'sort-selected ' + sortingSelection.sort_order : 'asc'">Updated By</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(bankReceipts$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.id }}</td>
                                    <td>{{ row.bank_account }}</td>
                                    <td>{{ row.type == 1 ? 'Bot' : 'Manual' }} - {{ row.transaction_type }}</td>
                                    <td>{{ row.desc }}</td>
                                    <td>{{ row.bank_time | dateTimeConversion: row.currency }} </td>
                                    <td class="text-center">{{ row.currency }}</td>
                                    <td class="text-right">
                                        {{ +row.credit === 0 ? '' : row.credit | number : '1.2-2' }}
                                        {{ +row.debit === 0  ? '' : row.debit  | number : '1.2-2' }}
                                    </td>
                                    <td class="text-center">
                                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                                    </td>
                                    <td>
                                        <a class="text-primary font-weight-bold" (click)="onOpenRemarks(row)">
                                            {{ row.remarks === 'null'? '-' :  row.remarks }}
                                        </a>
                                    </td>
                                    <td>
                                        {{ row.created_by == null ? 'System' : row.created_by}}<br>
                                        <span placement="top" [ngbTooltip]="createdDateTime" container="body" class="text-ellipsis">{{ row.created_at | dateTimeConversion: row.currency : 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #createdDateTime>
                                            {{ row.created_at  | dateTimeConversion: row.currency }}
                                        </ng-template>
                                    </td>
                                    <td>
                                        {{ row.updated_by == null ? 'System' : row.updated_by}}<br>
                                        <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ row.updated_at | dateTimeConversion: row.currency : 'YYYY-MM-DD HH:mm' }}</span>
                                        <ng-template #updatedDateTime>
                                            {{ row.updated_at  | dateTimeConversion: row.currency }}
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        <button matTooltip="Edit" *ngIf="row.status == 1 && row.type != 1 && canEditBankReceipt" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-edit"></i></button>
                                        <button matTooltip="Mark as Used/Read Only" *ngIf="row.status != 5 && canMarkReceiptAsUsedReadOnly" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('read only', row)"><i class="fas fa-eye"></i></button>
                                        <button matTooltip="Reject" *ngIf="row.status != 4 && canRejectReceipts" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('reject', row)"><i class="fas fa-ban"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
