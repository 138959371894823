import { Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AllLeadsDataService } from '../../services/all-leads-data.service';
import { UploadHttpService } from '@core/services/upload-http.service';

@Component({
  templateUrl: './request-justification.component.html',
  styleUrls: ['./request-justification.component.scss']
})
export class RequestJustificationDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;

  form: FormGroup;
  private subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  messages$ = this.AllLeadsDataService.messages$;

  fileName: Array<String> = [];
  isFileUploading: Array<boolean> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { leadID: number },
    public dialogRef: MatDialogRef<RequestJustificationDialogComponent>,
    private AllLeadsDataService: AllLeadsDataService,
    private uploadService: UploadHttpService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });

    const formData = {
      ...this.form.value
    };
    Object.keys(formData).forEach((key) => (formData[key] == null || formData[key] === '' || formData[key] < 1) && delete formData[key]);
    this.subscription = this.AllLeadsDataService.updateJustificationRemarks(formData, this.data.leadID).pipe(
      tap((res) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        this.dialogRef.close(true);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  get evidence() {
    return this.form.controls["evidence"] as FormArray;
  }

  addEvidence() {
    const evidence = new FormControl('', [Validators.required]);
    this.evidence.push(evidence)
  }

  removeEvidence(index: number) {
    this.evidence.removeAt(index);
    this.fileName.splice(index, 1)

  }

  onUploadFile(event: any, index) {
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('type', 'uploads');

    this.isFileUploading[index] = true;
    this.uploadService.upload(formData).pipe(
      tap(res => {
        (this.form.get("evidence") as FormArray).controls[index].patchValue(res[0])
        this.fileName[index] = event.target.files[0].name;
        this.isFileUploading[index] = false;
      }),
      catchError((error) => {
        this.isFileUploading[index] = false;
        throw error;
      })
    ).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      remarks: new FormControl(null, [Validators.required]),
      evidence: new FormArray([new FormControl('', [Validators.required])], [Validators.required])
    });
  }

}
