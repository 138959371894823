import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { SmsLog } from '@core/models/sms-log.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { MemberInformationDialogComponent } from '../../general/members/dialogs/member-information/member-information.component';
import { AffiliateInformationComponent } from '../../general/all-affiliates/dialogs/affiliate-information/affiliate-information.component';
import { MemberDataService } from '../../general/members/services/member-data.service';
import { ProvidersDataService } from '../providers/service/providers-data.service';
import { LogsDataService } from './../service/logs-data.service';
import { LogsEntityService } from './../service/logs-entity.service';
import { AllAffiliatesDataService } from '../../general/all-affiliates/services/all-affiliates-data.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'kt-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit, OnDestroy {

  form: FormGroup;
  logs$: Observable<SmsLog[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: this.dropdownHttpService.smsLogsStatuses,
    purposes: this.dropdownHttpService.smsPurposes,
    perPage: this.dropdownHttpService.perPage,
    smsProvider: [],
  };
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  searchProvider = [];
  selectedCurrency = { name: 'all' };
  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '11.2';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'content': 'desc',
    'sms_provider_name': 'desc',
    'status': 'desc',
    'purpose_id': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;

  defDateTime = this.transactionHttpService.getToday();
  dateLimit: any;

  // permissions
  canViewMemberDialog: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    private transactionHttpService: TransactionHttpService,
    private loadingBar: LoadingBarService,
    private logsEntityService: LogsEntityService,
    private logsDataService: LogsDataService,
    private memberDataService: MemberDataService,
    private dropdownHttpService: DropdownHttpService,
    private providersDataService: ProvidersDataService,
    private authHttpService: AuthHttpService,
    private allAffiliatesDataService: AllAffiliatesDataService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));
    this.formInit();
    this.pagination = this.logsDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;
    this.setProvider();
    this.setCurrencyDropdown();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.all_smses_view_member_dialog;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.logs$ = this.logsEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}&${this.generateSortingParam()}${params}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.logsDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({
        start_date: this.defDateTime.from,
        end_date: this.defDateTime.to,
        defaultDate: {
          startDate: this.defDateTime.from,
          endDate: this.defDateTime.to
        }
      });
    }
  }

  selectStartDate(event: any) { // get the total days within 3 months from selected start date
    if (event) {
      const minDate = moment(event);
      const maxDate = moment(event).add(3,'months');
      this.dateLimit = maxDate.diff(minDate, 'days');
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.searchProvider = [];
    this.form.patchValue({
      username: null,
      name: null,
      currency_id: 'all',
      status: 'all',
      purpose_id: 'all',
      sms_provider_id: this.searchProvider,
      keyword: null,
      start_date: this.defDateTime.from,
      end_date: this.defDateTime.to,
      defaultDate: {
        startDate: this.defDateTime.from,
        endDate: this.defDateTime.to
      }
    });
    this.dropdown.smsProvider.forEach(item => {
      this.searchProvider.push(item.id);
      item.checked = true;
    })
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        const providerParams = this.generateCheckboxParams();
        const paramsRef = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        this.params = paramsRef.replace(providerParams.oldCheckboxParams, providerParams.newCheckboxParams);
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.logs$ = this.logsEntityService.getWithQuery(`?perPage=${this.pageSize}${parameters}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.logsDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  onSearchProvider(event: any, id: number, ind?: number) {
    const position = this.searchProvider.indexOf(id);
    if (event.target.checked) {
      this.searchProvider.push(id);
      this.dropdown.smsProvider[ind].checked = true;
    } else {
      this.searchProvider.splice(position, 1);
      this.dropdown.smsProvider[ind].checked = false;
    }
    this.form.patchValue({ sms_provider_id: this.searchProvider });
  }

  onOpenDialog(type: string, accountid?: number) {
    switch (type) {
      case 'member-information':
        if (!this.canViewMemberDialog) {
          Swal.fire('Permission Error', 'You do not have permission to view member dialog', 'error');
          return;
        }

        const member = this.memberDataService.getById(accountid, `?start_date=${moment(this.transactionHttpService.getYesterday().from).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
        this.subscription = member.pipe(
          tap((res) => {
            if (res) {
              this.openDialogBy(MemberInformationDialogComponent, { member: res, mode: 'member-information' });
            }
          })
        )
          .subscribe();
        break;
      case 'affiliate-information':
        const affiliate = this.allAffiliatesDataService.getById(accountid);
        this.subscription = affiliate.pipe(
          tap((res: any) => {
            if (res) {
              this.openDialogBy(AffiliateInformationComponent, { affiliate: res, mode: type });
            }
          })
        ).subscribe();
        break;
    }

  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }
    // User selection
    this.sortingSelection.sort_by = property;

    if (this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }
    // Load Data
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onCurrencyChange() {
    this.selectedCurrency = this.dropdown.currencies.find(x => x.id == this.form.value.currency_id);
    if (this.selectedCurrency === undefined) {
      this.selectedCurrency = { name: 'all' };
    }
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  private openDialogBy(componentRef: any, data?: { affiliate?: any, member?: any, mode?: any, isAdmin?: boolean }) {
    if (data.mode == 'affiliate-information') {
      data.mode = 'information';
    }

    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'member-information' || data.mode === 'information' ? '1500px' : '800px',
      height: data.mode === 'member-information' || data.mode === 'information' ? '80vh' : 'auto',
      data,
      autoFocus: data.mode === 'member-information' || data.mode === 'information' ? false : true
    });
  }

  private setProvider() {
    this.providersDataService.getWithQuery(`?status=1`).subscribe(res => {
      res.map(row => {
        this.searchProvider.push(row.id);
        this.dropdown.smsProvider.push({
          id: row.id,
          name: row.name,
          checked: true
        })
      });
    });
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private generateCheckboxParams() {
    return {
      newCheckboxParams: Object.keys(this.searchProvider).map(key => `sms_provider_id[${key}]=${this.searchProvider[key]}`).join('&'),
      oldCheckboxParams: 'sms_provider_id=' + Object.keys(this.searchProvider).map(key => this.searchProvider[key]).join(','),
    }
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all' && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      username: new FormControl(null),
      sms_provider_id: new FormControl(this.searchProvider),
      currency_id: new FormControl('all'),
      status: new FormControl('all'),
      purpose_id: new FormControl('all'),
      keyword: new FormControl(null),
      start_date: new FormControl(this.defDateTime.from),
      end_date: new FormControl(this.defDateTime.to),
      defaultDate: new FormControl({
        startDate: this.defDateTime.from,
        endDate: this.defDateTime.to
      }) // Do not remove: For Clearing The Range
    });
  }

}
