<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body">
        <div class="kt-form kt-form--label-right">
            <div class="row align-items-center">
                <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <!-- Currency -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control">
                                    <option value="all">All</option>
                                    <option [value]="value.id" *ngFor="let value of currencyDropdown">
                                        {{ value.name }}
                                    </option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canCreateReferralSettings" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Currency</th>
                                    <th>Name</th>
                                    <th>Target Deposit Amount</th>
                                    <th>Target Referral Count</th>
                                    <th>Validity</th>
                                    <th>Referral Bonus</th>
                                    <th>Invitation Bonus</th>
                                    <th>Status</th>
                                    <th class="date-column">Updated By</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(referralSettings$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.id }}</td>
                                    <td>{{ row.currency_code }}</td>
                                    <td>{{ row.name === '' ? '-' : row.name }}</td>
                                    <td>{{ row.target_deposit_amount | number : '1.2-2' }}</td>
                                    <td>{{ row.target_referral_count }}</td>
                                    <td>{{ row.validity }}</td>
                                    <td>{{ row.referral_promotion_code === null ? '-' : row.referral_promotion_code }}</td>
                                    <td>{{ row.invitation_promotion_code === null ? '-' : row.invitation_promotion_code }}</td>
                                    <td class="text-center">
                                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge-referral-setting--' + row.status"> {{ row.status_name }}</span>
                                      </td>
                                    <td>
                                        {{ row.updated_by || 'System' }}<br>
                                        <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="text-ellipsis">{{ isValidDate(row.updated_at) ? (row.updated_at | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }}</span>
                                        <ng-template #updatedDateTime>
                                            <ng-container *ngIf="isValidDate(row.updated_at)">
                                                {{ row.updated_at  | timezoneDate: 'YYYY-MM-DD HH:mm:ss' }}
                                            </ng-container>
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        <button *ngIf="canEditReferralSettings" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-edit"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="pagination.total"
                        (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                            Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
      </div>
  </div>
</div>
