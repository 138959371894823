<div class="modal-dialog view-receipt-modal modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">View Document</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <ngb-carousel *ngIf="data.documents.length > 0">
        <ng-template ngbSlide *ngFor="let row of data.documents; let i = index">
          <div class="picsum-img-wrapper">
            <div style="min-height: inherit">
              <object
                [data]="row.value | safe : 'resourceUrl'"
                [ngStyle]="{
                  height: checkMedia(row.value) == 'pdf' ? '100%' : 'auto',
                  'max-height': '95%'
                }"
              ></object>
              <p class="document-desc">{{ row.created_at ? (row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm:ss') : '' }}</p>
            </div>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="onCloseDialog($event)"
      >
        <i class="fas fa-ban"></i>Close
      </button>
    </div>
  </div>
</div>
