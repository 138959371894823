<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body">
          <div class="kt-form kt-form--label-right  mb-1">
              <div class="row align-items-center">
                  <div class="col-xl-12 order-2 order-xl-1 pr-0">
                      <form class="row align-items-center" [formGroup]="form">
                          <div class="col-12 row mb-2 pr-0">
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Currency:</label>
                              </div>
                              <div class="col-md-2 kt-form__control ">
                                <select class="form-control" formControlName="currency_id">
                                    <option [value]="null" [disabled]="true">Please Select</option>
                                    <option value="all">All</option>
                                    <ng-container *ngFor="let value of dropdown.currencies">
                                        <option [value]="value.id">{{ value.name }}</option>
                                    </ng-container>
                                </select>
                              </div>
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Status:</label>
                              </div>
                              <div class="col-md-2 kt-form__control ">
                                <select class="form-control" formControlName="status">
                                    <option [value]="null" [disabled]="true">Please Select</option>
                                    <option value="all">All</option>
                                    <ng-container *ngFor="let item of statuses | keyvalue">
                                      <option [value]="item.key" *ngIf="+item.key >= 0">{{ item.value }}</option>
                                    </ng-container>
                                </select>
                              </div>
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Referrer:</label>
                              </div>
                              <div class="col-md-2 kt-form__control ">
                                <input type="text" formControlName="referrer_username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                              </div>
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Referee:</label>
                              </div>
                              <div class="col-md-2 kt-form__control ">
                                  <input type="text" formControlName="referree_username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                              </div>
                          </div>
                          <div class="col-12 row mb-2 pr-0">
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Referral Code:</label>
                              </div>
                              <div class="col-md-2 kt-form__control">
                                  <input type="text" formControlName="referral_code" placeholder="Search" class="form-control">
                              </div>
                              <div class="col-md-1 kt-form__label col-form-label">
                                  <label>Date/Time:</label>
                              </div>
                              <div class="col-md-5 kt-form__control">
                                  <div class="input-group date">
                                      <input type="text" class="form-control" placeholder="Search"
                                          (change)="onDateRange($event)" formControlName="defaultDate"
                                          [timePicker]="true" [timePickerSeconds]="true"
                                          [alwaysShowCalendars]="true" [ranges]="ranges"
                                          [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                          [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                      ngxDaterangepickerMd/>
                                      <span class="input-group-append">
                                          <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                      </span>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
          <div class="kt-section col-12 row mb-3 mt-1">
                <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
          </div>
          <div class="kt-section">
              <div class="kt-section__content">
                  <div class="table-responsive">
                      <table class="table table-bordered table-hover table-striped table-sortable">
                          <thead>
                              <tr>
                                  <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                  <th class="sort-enabled" (click)="onSortColumn('referrer')" [ngClass]="sortingConfig.referrer" [class.sort-selected]="sortingSelection.sort_by === 'referrer'">Referrer</th>
                                  <th class="sort-enabled" (click)="onSortColumn('referree')" [ngClass]="sortingConfig.referree" [class.sort-selected]="sortingSelection.sort_by === 'referree'">Referee</th>
                                  <th class="sort-enabled" (click)="onSortColumn('register_date')" [ngClass]="sortingConfig.register_date" [class.sort-selected]="sortingSelection.sort_by === 'register_date'">Register Date</th>
                                  <th class="sort-enabled" (click)="onSortColumn('expired_date')" [ngClass]="sortingConfig.expired_date" [class.sort-selected]="sortingSelection.sort_by === 'expired_date'">Expired Date</th>
                                  <th class="text-right sort-enabled" (click)="onSortColumn('total_deposit_amount')" [ngClass]="sortingConfig.total_deposit_amount" [class.sort-selected]="sortingSelection.sort_by === 'total_deposit_amount'">Total Deposit Amount</th>
                                  <th class="text-right sort-enabled" (click)="onSortColumn('referral_bonus')" [ngClass]="sortingConfig.referral_bonus" [class.sort-selected]="sortingSelection.sort_by === 'referral_bonus'">Referral Bonus</th>
                                  <th class="text-right sort-enabled" (click)="onSortColumn('invitation_bonus')" [ngClass]="sortingConfig.invitation_bonus" [class.sort-selected]="sortingSelection.sort_by === 'invitation_bonus'">Invitation Bonus</th>
                                  <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                              </tr>
                          </thead>
                          <tbody *ngIf="(referral$ | async) as rows">
                            <tr *ngFor="let row of rows">
                              <td> {{ row.id }} </td>
                              <td><a [ngClass]="row.referrer_dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog('member-information', row.referrer_id)">{{ row.referrer }}</a></td>
                              <td><a [ngClass]="row.referree_dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog('member-information', row.referree_id)"> {{ row.referree }}</a></td>
                              <td> {{ row.register_date | timezoneDate: 'YYYY-MM-DD HH:mm' }} </td>
                              <td> {{ row.expired_date | timezoneDate: 'YYYY-MM-DD HH:mm' }} </td>
                              <td class="text-right"> {{ +(extractAmounts(row.total_deposit_amount)[0]) | number : '1.2-2' }} / {{ +(extractAmounts(row.total_deposit_amount)[1]) | number : '1.2-2' }}  </td>
                              <td class="text-right"> {{ row.referral_bonus | number : '1.2-2' }}</td>
                              <td class="text-right"> {{ row.invitation_bonus  | number : '1.2-2' }}  </td>
                              <td class="text-center">
                                <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ row.status_name }}</span>
                              </td>
                            </tr>
                          </tbody>
                          <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                      </table>
                  </div>
                  <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                      <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="pagination.total"
                        (pageChange)="onViewPageBy(page)">
                      </ngb-pagination>
                      <div class="kt-pagination__toolbar">
                          <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                              <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                          </select>
                          <span class="pagination__desc">
                            Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                          </span>
                      </div>
                    </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- message for permission error -->
<kt-swal-alert [message]="messages2$ | async" [icon]="'error'"></kt-swal-alert>