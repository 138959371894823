<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Remark History ({{ data.id }})</h5>
            <span class="modal-x-button" (click)="onCloseDialog()">
                <i class="fa fa-times" aria-hidden="true"></i>
            </span>
        </div>
        <div class="modal-body">
            <div class="pt-0 pb-0">
                <div id="kt_table_users_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive padding">
                        <table class="table align-middle fs-6 dataTable no-footer table-bordered table-hover table-striped">
                            <thead>
                                <tr class="fw-bolder fs-7 gs-0">
                                    <th class="text-center" tabindex="0" width="70">ID</th>
                                    <th class="" tabindex="0" width="400">Remark</th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center" tabindex="0">Created By</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="remarks as rows" class="text-gray-600 fw-bold">
                                <tr *ngFor="let row of rows; let i = index" [class]="i % 2 == 0 ? 'odd' : 'even'">
                                    <td class="text-center">{{ row.id }}</td>
                                    <td>
                                        {{ row.remarks }}<br />
                                        {{ row.ext_remarks || '' }}
                                    </td>
                                    <td class="text-center">
                                        <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                                    </td>
                                    <td class="text-center">
                                        {{ row.created_by ? row.created_by : '-' }}<br />
                                        {{ (row.created_at | timezoneDate:'YYYY-MM-DD HH:mm') }}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="remarkLength" class="text-gray-600 fw-bold">
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog($event)"><i class="fas fa-ban"></i>Close</button>
        </div>
    </div>
</div>