<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <form class="align-items-center" [formGroup]="form">
                            <div class="row mb-2">
                                <!-- Username -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search"
                                        class="form-control">
                                </div>
                                <!-- Name -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                                <!-- Contact -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Contact: </label>
                                </div>
                                <div class="col-md-4 kt-form__control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <select class="form-control" (change)="onContactType($event.target.value)">
                                                <option value="Mobile" selected>Mobile</option>
                                                <option value="Email">Email</option>
                                            </select>
                                        </div>
                                        <input type="text" (change)="onContact($event)" placeholder="Search"
                                            class="form-control"
                                            [disabled]="(selectedContactType === '' && selectedContactType === null)"
                                            #contactRef>
                                        <input type="hidden" formControlName="mobile" />
                                        <input type="hidden" formControlName="email" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <!-- Currency -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <!-- Telemarketer -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Telemarketer:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="telemarketer" class="form-control">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <option [value]="value.username" *ngFor="let value of dropdown.telemarketer| async">
                                            {{ value.username }}
                                        </option>
                                    </select>
                                </div>

                                <!-- Date Type & Date Range -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date: </label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <select class="form-control" formControlName="date_type">
                                                <option value="registration_date" selected>Registration Date</option>
                                                <option value="assigned_date">Assigned Date</option>
                                                <option value="created_at">{{ 'Created Date' }}</option>
                                                <option value="leads_expiry_date" selected>Leads Expiry Date</option>
                                                <option value="last_deposit_date" selected>Last Deposit Date</option>
                                                <option value="last_follow_up_date" selected>Last Follow Up Date</option>
                                                <option value="last_updated_date">Last Updated Date</option>
                                            </select>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true"
                                            [alwaysShowCalendars]="true" [ranges]="ranges"
                                            [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                            [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
                                        ngxDaterangepickerMd/>
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-2">
                                <!-- Source Type -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Source Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="source_type" class="form-control" (change)="onSelectSourceType($event)">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.sourceType">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>

                                <!-- Source Account -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Source Account:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="source_account" placeholder="Search" class="form-control">
                                </div>

                                <!-- Campaign Name -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Campaign Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="campaign_name" placeholder="Search" class="form-control">
                                </div>

                                <!-- Campaign Code -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Campaign Code:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="campaign_code" placeholder="Search" class="form-control">
                                </div>
                            </div>

                            <div class="row mb-2">
                                <!-- Lead Type -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Lead Type:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="lead_type" class="form-control">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.leadType">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="lead_category" class="form-control" [attr.disabled]="selectedLeadType == 1 || selectedLeadType == 2 ? null : ''">
                                        <option [value]="null" [disabled]="true">{{ 'Please Select' | translate }}</option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.leadTypeCategory">
                                            {{ value.name | translate }}
                                        </option>
                                    </select>
                                </div>

                                <!-- Status -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="d-flex flex-wrap status-filter-pt">
                                        <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.statuses ; let i = index">
                                            <input type="checkbox" [checked]="item.checked" (change)="onChangeStatus($event, item.value, i)" class="custom-control-input" [id]="item.name">
                                            <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()" (click)="contactRef.value = ''"></kt-clear-button>
                </div>
                <div class="dropdown dropdown-inline">
                    <!-- <button *ngIf="access.export && affiliateSettings == true" class="btn btn-setting btn-icon-sm mr-2" [disabled]="loading" (click)="onOpenDialog('affiliateSettings')"><i class="fas fa-users-cog"></i>Affiliate Settings</button>
                    <button id="button" name="button" value="Undo" class="btn btn-undo btn-icon-sm mr-2" [disabled]="loading" (click)="onOpenDialog('undoLead')"><i class="fas fa-undo"></i>Undo</button>
                    <button *ngIf="access.export" id="button" name="button" value="Upload" class="btn btn-import btn-icon-sm mr-2" [disabled]="loading" (click)="onOpenDialog('importLead')"><i class="fas fa-file-import"></i>Import</button>
                    <button *ngIf="access.export" class="btn btn-warning btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="buttonLoading"> -->
                    <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                    <!-- <button *ngIf="allLeadsUseNewPermissions ? canViewAffiliateSettings : ((access$ | async).export && (userPermissions$ | async).affiliate_settings)" class="btn btn-setting btn-icon-sm mr-2" [disabled]="loading" (click)="onOpenDialog('affiliateSettings')"><i class="fas fa-users-cog"></i>Affiliate Settings</button>
                    <button *ngIf="allLeadsUseNewPermissions ? canImportLeads : (access$ | async).export" id="button" name="button" value="Upload" class="btn btn-import btn-icon-sm mr-2" [disabled]="loading" (click)="onOpenDialog('importLead')"><i class="fas fa-file-import"></i>Import</button>
                    <button *ngIf="allLeadsUseNewPermissions ? canViewAffiliateSettings : (access$ | async).export" class="btn btn-warning btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="buttonLoading"> -->

                    <button *ngIf="canUndoLeads" id="button" name="button" value="Undo" class="btn btn-undo btn-icon-sm mr-2" [disabled]="loading" (click)="onOpenDialog('undoLead')"><i class="fas fa-undo"></i>Undo</button>
                    <button *ngIf="canReassignLeads" id="button" name="button" value="Reassign" class="btn btn-reassign btn-icon-sm mr-2" [disabled]="loading" (click)="onOpenDialog('reassignLead')"><i class="fas fa-random"></i>Reassign</button>
                    <button *ngIf="canImportLeads" id="button" name="button" value="Upload" class="btn btn-import btn-icon-sm mr-2" [disabled]="loading" (click)="onOpenDialog('importLead')"><i class="fas fa-file-import"></i>Import</button>
                    <button *ngIf="canExportLeads" class="btn btn-warning btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="buttonLoading">

                        <i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export
                    </button>
                    <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                    <!-- <button *ngIf="allLeadsUseNewPermissions ? canAssignLeads : (access$ | async).create" class="btn btn-brand btn-icon-sm" [disabled]="loading" (click)="onOpenDialog('assignLead')"><i class="fas fa-plus"></i>Assign</button> -->

                    <button *ngIf="canAssignLeads" class="btn btn-brand btn-icon-sm" [disabled]="loading" (click)="onOpenDialog('assignLead')"><i class="fas fa-plus"></i>Assign</button>
                </div>
            </div>
            <div class="kt-section">
                <!-- Summary -->
                <div class="col-xl-12 summary-wrapper">
                    <div class="row">
                        <div class="col-md-6 pl-0 pr-0">
                            <label>No. of Assigned Leads: </label><span class="ml-1">{{ totalAssignedLeads | number: '1.0-0' }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 pl-0 pr-0">
                            <label>No. of Unassigned Fresh Leads: </label><span class="ml-1">{{ totalUnassignedLeads | number: '1.0-0' }}</span>
                        </div>
                    </div>
                </div>

                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('lead_id')"
                                        [ngClass]="sortingConfig.lead_id"
                                        [class.sort-selected]="sortingSelection.sort_by === 'lead_id'">ID
                                    </th>
                                    <th rowspan="2" class="date-column sort-enabled" (click)="onSortColumn('lead_assigned_date')"
                                        [ngClass]="sortingConfig.lead_assigned_date"
                                        [class.sort-selected]="sortingSelection.sort_by === 'lead_assigned_date'">Lead Assigned Date
                                    </th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('telemarketer')"
                                        [ngClass]="sortingConfig.telemarketer"
                                        [class.sort-selected]="sortingSelection.sort_by === 'telemarketer'">Telemarketer
                                    </th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('username')"
                                        [ngClass]="sortingConfig.username"
                                        [class.sort-selected]="sortingSelection.sort_by === 'username'">Username
                                    </th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('name')"
                                        [ngClass]="sortingConfig.name"
                                        [class.sort-selected]="sortingSelection.sort_by === 'name'">Name
                                    </th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('mobile')"
                                        [ngClass]="sortingConfig.mobile"
                                        [class.sort-selected]="sortingSelection.sort_by === 'mobile'">Mobile
                                    </th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('password')"
                                        [ngClass]="sortingConfig.password"
                                        [class.sort-selected]="sortingSelection.sort_by === 'password'">Password
                                    </th>
                                    <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('currency')"
                                        [ngClass]="sortingConfig.currency"
                                        [class.sort-selected]="sortingSelection.sort_by === 'currency'">Currency
                                    </th>
                                    <th colspan="3" class="text-center">Traffic Source</th>
                                    <th rowspan="2" class="date-column sort-enabled" (click)="onSortColumn('last_call_attempt')"
                                        [ngClass]="sortingConfig.last_call_attempt"
                                        [class.sort-selected]="sortingSelection.sort_by === 'last_call_attempt'">Last Call Attempt
                                    </th>
                                    <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('status')"
                                        [ngClass]="sortingConfig.status"
                                        [class.sort-selected]="sortingSelection.sort_by === 'status'">Status
                                    </th>
                                    <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('lead_type')"
                                        [ngClass]="sortingConfig.lead_type"
                                        [class.sort-selected]="sortingSelection.sort_by === 'lead_type'">Lead Type
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('registration_created_at')"
                                        [ngClass]="sortingConfig.registration_created_at"
                                        [class.sort-selected]="sortingSelection.sort_by === 'registration_created_at'">Registration Info
                                    </th>
                                    <th rowspan="2" class="date-column sort-enabled" (click)="onSortColumn('last_login')"
                                        [ngClass]="sortingConfig.last_login"
                                        [class.sort-selected]="sortingSelection.sort_by === 'last_login'">Last Login
                                    </th>
                                    <th rowspan="2" class="unsortable">Progress</th>
                                    <th rowspan="2" class="date-column sort-enabled" (click)="onSortColumn('lead_expired_date')"
                                        [ngClass]="sortingConfig.lead_expired_date"
                                        [class.sort-selected]="sortingSelection.sort_by === 'lead_expired_date'">Leads Expiry Date
                                    </th>
                                    <th rowspan="2" class="date-column unsortable">Last Deposit Date</th>
                                    <th rowspan="2" class="date-column sort-enabled" (click)="onSortColumn('last_follow_up_date')"
                                        [ngClass]="sortingConfig.last_follow_up_date"
                                        [class.sort-selected]="sortingSelection.sort_by === 'last_follow_up_date'">Last Follow Up Date
                                    </th>
                                    <th rowspan="2" class="date-column sort-enabled" (click)="onSortColumn('remarks')"
                                        [ngClass]="sortingConfig.remarks"
                                        [class.sort-selected]="sortingSelection.sort_by === 'remarks'">Remark
                                    </th>
                                    <th rowspan="2" class="date-column sort-enabled" (click)="onSortColumn('created_at')"
                                        [ngClass]="sortingConfig.created_at"
                                        [class.sort-selected]="sortingSelection.sort_by === 'created_at'">{{ 'Created Date' }}
                                    </th>
                                    <th rowspan="2" class="date-column sort-enabled" (click)="onSortColumn('updated_at')"
                                        [ngClass]="sortingConfig.updated_at"
                                        [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">{{ 'Last Updated Date' }}
                                    </th>
                                    <th rowspan="2" class="date-column text-center">Action</th>
                                </tr>
                                <tr>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('source_type')"
                                        [ngClass]="sortingConfig.source_type"
                                        [class.sort-selected]="sortingSelection.sort_by === 'source_type'">Source Type
                                    </th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('source_account')"
                                        [ngClass]="sortingConfig.source_account"
                                        [class.sort-selected]="sortingSelection.sort_by === 'source_account'">Source Account
                                    </th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('campaign')"
                                        [ngClass]="sortingConfig.campaign"
                                        [class.sort-selected]="sortingSelection.sort_by === 'campaign'">Campaign
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(AllLeads$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.lead_id ? row.lead_id : '-'}}</td>
                                    <td [ngClass]="row.lead_assigned_date ? 'text-left' : 'text-center'">{{ row.lead_assigned_date ? (row.lead_assigned_date | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</td>
                                    <td>{{ row.telemarketer ? row.telemarketer : '-' }}</td>
                                    <td [ngClass]="row.username ? 'text-left' : 'text-center'">
                                        <ng-container *ngIf="(row.username); else nullTemplate">
                                            <ng-container *ngIf="canViewMemberDialog else otherRole">
                                                <a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold"
                                                    (click)="onOpenDialog('member-information', row.id)">
                                                    {{ row.username }}
                                                </a>
                                            </ng-container>
                                            <ng-template [ngClass]="row.dummy == 1 ? 'text-dummy-unclickable' : 'text-primary'" #otherRole>
                                                {{ row.username }}
                                            </ng-template>
                                            <i *ngIf="row.suspicious" class="fa fa-exclamation text-danger"></i>
                                        </ng-container>
                                        <ng-template #nullTemplate>
                                            -
                                        </ng-template>
                                    </td>
                                    <td [ngClass]="row.name ? 'text-left' : 'text-center'">{{ row.name ? row.name : '-'}}</td>
                                    <td [ngClass]="row.mobile ? 'text-left' : 'text-center'">{{ row.mobile ? row.mobile : '-'}}</td>
                                    <td [ngClass]="row.password ? 'text-left' : 'text-center'">{{ row.password ? row.password : '-'}}</td>
                                    <td class="text-center">{{ row.currency ? row.currency : '-'}}</td>
                                    <td>{{ row.source_type }}</td>
                                    <td>{{ row.source_account }}</td>
                                    <td>{{ row.campaign }}</td>
                                    <td [ngClass]="row.last_call_attempt ? 'text-left' : 'text-center'">{{ row.last_call_attempt ? (row.last_call_attempt | timezoneDate: 'YYYY-MM-DD HH:mm') : '-'}}</td>
                                    <td class="text-center">
                                        <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ row.status_name ? row.status_name : '-' }}</span>
                                    </td>
                                    <td class="text-center">
                                        {{ row.lead_type }}
                                    </td>
                                    <td class="width-170">
                                        <ng-container *ngIf="(row.registration_ip !== null || row.registration_created_at !== null || row.registration_site !== null); else nullTemplate3">
                                            <span class="no-wrap">{{ (row.registration_created_at | timezoneDate: 'YYYY-MM-DD HH:mm')}}</span><br>
                                            <span placement="top" [ngbTooltip]="row.registration_ip" container="body" class="text-ellipsis"> {{ row.registration_ip }}</span>
                                            <ng-container *ngIf="urlIsValid(row.registration_site); else invalidRegistrationSite">
                                                <a [href]="row.registration_site" target="_blank"> {{ getUrl(row.registration_site) }}</a><br>
                                            </ng-container>
                                            <ng-template #invalidRegistrationSite>
                                                <a> {{ row.registration_site }}</a><br>
                                            </ng-template>
                                            {{ row.registration_locale_code }}
                                        </ng-container>
                                        <ng-template #nullTemplate3>
                                            - <br>
                                        </ng-template>
                                    </td>
                                    <td class="width-170">
                                        <ng-container *ngIf="(row.last_login !== null); else nullTemplate4">
                                            {{ (row.last_login | timezoneDate: 'YYYY-MM-DD HH:mm') }}
                                        </ng-container>
                                        <ng-template #nullTemplate4>
                                            - <br>
                                        </ng-template>
                                    </td>
                                    <td class="text-right">{{ row.total_deposit ? row.total_deposit : '-' }}</td>
                                    <td>{{ row.lead_expired_date ? (row.lead_expired_date | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</td>
                                    <td>{{ row.last_deposit_date ? (row.last_deposit_date | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</td>
                                    <td>{{ row.last_follow_up_date ? (row.last_follow_up_date | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</td>
                                    <td [ngClass]="row.remarks ? 'text-left' : 'text-center'">
                                        <ng-container *ngIf="(row.remarks); else nullTemplate">
                                            <a class="text-primary font-weight-bold"
                                                (click)="onOpenDialog('show-remarks', null, row.lead_id)">
                                                {{ row.remarks }}
                                            </a>
                                            <!-- <i *ngIf="row.suspicious" class="fa fa-exclamation text-danger"></i> -->
                                        </ng-container>
                                        <ng-template #nullTemplate>
                                            -
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        {{ row.created_by ? row.created_by : '-' }}<br/>
                                        {{ (row.created_at | timezoneDate:'YYYY-MM-DD HH:mm') }}
                                    </td>
                                    <td class="text-center">
                                        {{ row.updated_by ? row.updated_by : '-' }}<br/>
                                        {{ (row.updated_at | timezoneDate:'YYYY-MM-DD HH:mm') }}
                                    </td>
                                    <td class="text-center">
                                        <button *ngIf="canViewCallLogsAttempts && (row.call_logs !== null);" matTooltip="View Call Logs/Attempts" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('callLog', null, row.call_logs)" [ngClass]="{'disabled-button': row.call_logs === null}">
                                            <span class="text-collapsed"><i class="fa fa-receipt"></i></span>
                                        </button>
                                        <button *ngIf="canEditRemark" matTooltip="Edit Remark" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('remarks', null, row.lead_id)" >
                                            <span class="text-collapsed"><i class="fa fa-edit"></i></span>
                                        </button>
                                        <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                        <!-- <button *ngIf="(allLeadsUseNewPermissions ? canViewLeadJustification : (userPermissions$ | async).lead_justification) && row.status_name == 'Pending Justification'" matTooltip="Lead Justification" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('show-remarks', null, row, 'lead_justification')"> -->

                                        <button *ngIf="canViewLeadJustification && row.status_name == 'Pending Justification'" matTooltip="Lead Justification" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('show-remarks', null, row, 'lead_justification')">
                                            <span class="text-collapsed"><i class="fa fa-balance-scale"></i></span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
                            [directionLinks]="true" [boundaryLinks]="true" [rotate]="true"
                            [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                                class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0'
                                }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<kt-swal-alert [message]="messages$ | async" [data]="data$ | async" (confirmed)="resetData()"></kt-swal-alert>
