import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';


@Pipe({
  name: 'dateTimeConversion',
})
export class DateTimeConversionPipe implements PipeTransform {
  transform(value: string, currency: string): string {
    let timeOffSet = '';
    switch (currency) {
      case 'MYR': timeOffSet = '+0800'; break;
      case 'THB': timeOffSet = '+0700'; break;
      case 'SGB': timeOffSet = '+0800'; break;
      default: timeOffSet = '+0000'; break;
    }
    return moment(value).utcOffset(timeOffSet).format('YYYY-MM-DD HH:mm') + ` (${timeOffSet})`;
  }
}
