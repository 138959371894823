import { PredictionStatusHttpService } from './prediction-status-http.service';
import { MessageTemplateDataService } from './../../views/pages/apps/superuser/message-template/services/message-template-data.service';
import { FaqDataService } from './../../views/pages/apps/settings/faq-management/services/faq-data.service';
import { GameTypeReportDataService } from './../../views/pages/apps/reports/gametype-report/services/gametype-report-data.service';
import { TransactionDataService } from './../../views/pages/apps/general/transactions/services/transaction-data.service';
import { AnnouncementDataService } from './../../views/pages/apps/settings/announcements/services/announcement-data.service';
import { AllEventsDataService } from '@views/pages/apps/general/all-events/services/all-events-data.service';
import { SmsHttpService } from './sms-http.service';
import { CampaignHttpService } from './campaign-http.service';
import { PromotionSettingHttpService } from './promotion-setting-http.service';
import { GameProviderHttpService } from './game-provider-http.service';
import { MemberHttpService } from '@core/services/member-http.service';
import { BankHttpService } from './bank-http.service';
import { GroupHttpService } from './group-http.service';
import { RoleHttpService } from './role-http.service';
import { MerchantBankHttpService } from './merchant-bank-http.service';
import { CurrencyHttpService } from './currency-http.service';
import { PurposeHttpService } from '@core/services/purpose-http.service';
import { Injectable } from '@angular/core';
import { ContactHttpService } from './contact-http.service';
import { BannerHttpService } from './banner-http.service';
import { LocaleHttpService } from './locale-http.service';
import { FunctionSectionHttpService } from './function-section-http.service';
import { TransactionHttpService } from './transaction-http.service';
import { MemberBankAccountHttpService } from './member-bank-account-http.service';
import { RegistrationParamsHttpService } from './registration-params.service';
import { CacheHttpService } from './cache-http.service';
import { AllLeadsDataService } from '@views/pages/apps/general/all-leads/services/all-leads-data.service';
import { LanguageHttpService } from '@core/services/language-http.service';
import { MerchantSitesHttpService } from './merchant-sites-http.service';
import { SampleMessageHttpService } from './sample-message-http.service';
import { WhatsappCurrencyHttpService } from './whatsapp-currency-http.service';
import { MemberLabelHttpService } from './member-label-http.service';
import { FooterSectionDataService } from '@views/pages/apps/settings/footer-settings/section-settings/footer-section/services/footer-section-data.service';
import { FooterSectionHttpService } from './footer-section-http.service';
import { LinkWithHttpService } from './linkwith-http.service';
import { MiniGameDataService } from '@views/pages/apps/games/mini-games/service/mini-game-data.service';
import { SeoHttpService } from './seo-http.service';
import { TimezoneHttpService } from './timezone-http.service';
import { LiveChatDataService } from '../../views/pages/apps/general/live-chat/services/live-chat-data.service';
import { ApplicationHttpService } from './application-http.service';
import { MenuBarDataService } from '@views/pages/apps/settings/menu-bar/services/menu-bar-data.service';
import { CryptoTokenHttpService } from './crypto-token-http.service';

@Injectable()
export class DropdownHttpService {

  constructor(
    private purposeHttpService: PurposeHttpService,
    private currencyHttpService: CurrencyHttpService,
    private merchantBankHttpService: MerchantBankHttpService,
    private contactHttpService: ContactHttpService,
    private bannerHttpService: BannerHttpService,
    private languageHttpService: LanguageHttpService,
    private localeHttpService: LocaleHttpService,
    private roleHttpService: RoleHttpService,
    private applicationHttpService: ApplicationHttpService,
    private groupHttpService: GroupHttpService,
    private functionSectionHttpService: FunctionSectionHttpService,
    private transactionHttpService: TransactionHttpService,
    private bankHttpService: BankHttpService,
    private memberHttpService: MemberHttpService,
    private gameProviderHttpService: GameProviderHttpService,
    private promotionSettingHttpService: PromotionSettingHttpService,
    private campaignHttpService: CampaignHttpService,
    private memberBankAccountHttpService: MemberBankAccountHttpService,
    private smsHttpService: SmsHttpService,
    private registrationParamsHttpService: RegistrationParamsHttpService,
    private transactionDataService: TransactionDataService,
    private announcementDataService: AnnouncementDataService,
    private cacheHttpService: CacheHttpService,
    private gameTypeReportDataService: GameTypeReportDataService,
    private faqDataService: FaqDataService,
    private messageTemplateDataService: MessageTemplateDataService,
    private predictionStatusHttpService: PredictionStatusHttpService,
    private allEventsDataService: AllEventsDataService,
    private allLeadstsDataService: AllLeadsDataService,
    private merchantSitesHttpService: MerchantSitesHttpService,
    private sampleMessageHttpServiceHttpService: SampleMessageHttpService,
    private whatsappCurrencyHttpService: WhatsappCurrencyHttpService,
    private memberLabelHttpService: MemberLabelHttpService,
    private footerSectionDataService: FooterSectionDataService,
    private footerSectionHttpService: FooterSectionHttpService,
    private linkWithHttpService: LinkWithHttpService,
    private miniGameDataService: MiniGameDataService,
    private seoHttpService: SeoHttpService,
    private timezoneHttpService: TimezoneHttpService,
    private livechatDataService: LiveChatDataService,
    private menuBarDataService: MenuBarDataService,
    private cryptoTokenService: CryptoTokenHttpService,
  ) { }

  statuses = [
    'Inactive', // Zero index
    'Active'
  ];

  member_visibility_types = [
    { name: 'All Members', value: 0, checked: true },
    { name: 'Eligible Members Only', value: 1, checked: true },
  ];

  kyc_types = [
    'KYC Status',
    'Manual'
  ];

  kyc_statuses = [
    { id: 1, name: 'Basic' },
    { id: 2, name: 'Advanced' },
    { id: 3, name: 'Pro' }
  ];

  // Search filter
  memberStatuses = [
    ...this.statuses,
    'Suspended'
  ];

  // Footer Section
  display_titles = [
    'Off', // Zero index
    'On'
  ];

  rebateHistoryStatuses = [
    'Processing', // Zero index
    'Pending',
    'Releasing',
    'Released',
    'Rejected',
  ]

  abledDisabled = [
    'Disable', // Zero index
    'Enable'
  ];

  perPage = [10, 15, 30, 50, 100];

  verificationStatuses = [
    { name: 'Pending', value: 0, checked: true },
    { name: 'Approved', value: 1, checked: true },
    { name: 'Rejected', value: 2, checked: false }
  ];

  memberBankAccountVerificationStatuses = [
    { name: 'Pending', value: 0, checked: true },
    { name: 'In Progress', value: 3, checked: true },
    { name: 'Approved', value: 1, checked: false },
    { name: 'Rejected', value: 2, checked: false },
  ];

  affiliateStatuses = [
    { value: 1, name: 'Active', checked: true },
    { value: 2, name: 'Suspended', checked: true }
  ];

  affiliateGroupTypeStatuses = [
    { id: 1, name: 'Rev Share' },
    { id: 2, name: 'FTD' }
  ];

  affiliatePartnerNetwork = [
    { id: 1, name: 'Internal' },
    { id: 2, name: 'External' }
  ];

  reason = [
    { id: 1, name: 'Upgrade' },
    { id: 2, name: 'Downgrade' }
  ];

  // TODO: Refine!
  transactionStatuses = [
    { id: 1, name: 'Success' },
    { id: 2, name: 'Failed' }
  ];

  // Start : Bank Receipt
  bankReceiptStatuses = [
    { name: 'All', value: 'all'},
    { name: 'Available', value: 1 },
    { name: 'Claimed', value: 3 },
    { name: 'Rejected', value: 4 },
    { name: 'Read Only', value: 5 }
  ];

  bankReceiptTypes = [
    { name: 'All', value: 'all'},
    { name: 'Bot', value: 1 },
    { name: 'Manual', value: 2 }
  ];

  bankReceiptTransactionTypes = [
    { name: 'All', value: 'all'},
    { name: 'Deposit', value: 1 },
    { name: 'Withdrawal', value: 2 }
  ];

  bankReceiptDateTimeTypes = [
    { name: 'Transaction Timestamp', value: 1 },
    { name: 'Scanned / Created Time', value: 2 },
    { name: 'Last Updated Time', value: 3 }
  ]
  // End : Bank Receipt

  smsLogsStatuses = ['Pending', 'Delivered', 'Failed', 'Others', 'Sent'];

  smsProvidersStatuses = ['Disabled', 'Enabled'];

  walletEventTypes = [
    { id: 1, name: 'Deposit' },
    { id: 2, name: 'Withdrawal' },
    { id: 3, name: 'Transfer In/Out' }
  ];

  displayTypes = [
    { id: 1, name: 'desktop', label: 'Desktop' },
    { id: 2, name: 'mobile', label: 'Mobile' }
    // TODO: Pullout collection from DB once ready
  ];

  memberGroupTypes = [
    { id: 0, name: 'Default' },
    { id: 1, name: 'Trial' },
  ];

  language = this.languageHttpService.getLanguage();
  locales = this.localeHttpService.getLocales();
  regions = this.localeHttpService.getRegions();
  userLevels = [
    null,
    'VIP1',
    'VIP2',
    'Black List'
  ];

  messageTemplateType = [
    { id: 1, name: 'Message', checked: true },
    { id: 2, name: 'SMS', checked: true },
    { id: 3, name: 'Web Push ', checked: true },
  ];

  bonusConditions = [
    { name: 'Auto Approve', value: 'auto_approve', checked: false },
    { name: 'Auto Unlock', value: 'auto_unlock', checked: false },
    { name: 'Limit Provider Transfer In', value: 'limit_transfer_in', checked: false},
    { name: 'Limit Provider Transfer Out', value: 'limit_transfer_out', checked: false},
    { name: 'Allow Cancel Promotion', value: 'allow_cancel', checked: false },
  ];

  bankType = [
    { id: 1, name: 'Bank' },
    { id: 3, name: 'Ewallet' }
  ];

  marqueeSession = [
    { id: 1, name: 'All' },
    { id: 2, name: 'Before Login' },
    { id: 3, name: 'After Login' }
  ];

  dialogPlatform = [
    { id: 1, name: 'All' },
    { id: 2, name: 'Mobile' },
    { id: 3, name: 'Desktop' }
  ];

  dialogLocation = [
    { id:1  , name : 'All' },
    { id:2  , name : 'Home' },
    { id:3  , name : 'Deposit' },
    { id:4  , name : 'Withdraw' },
    { id:5  , name : 'Transfer' },
    { id:6  , name : 'Slots' },
    { id:7  , name : 'Casino' },
    { id:8  , name : 'Sports' },
    { id:9  , name : 'Fishing' },
    { id:10 , name : 'Promotion' },
    { id:11 , name : 'Vip' },
    { id:12 , name : 'Contact' }
  ];

  sourceTypes = [
    { id: 0, name: 'Affiliate' },
    { id: 1, name: 'Telemarketer' },
    { id: 2, name: 'Player Referral' },
    { id: 3, name: 'Organic' }
  ];

  sourceTypesList = [
    { id: 1, name: 'Affiliate' ,key: 0 },
    { id: 2, name: 'Telemarketer',key: 1  },
    { id: 3, name: 'Player Referral',key: 2  },
    { id: 4, name: 'Organic',key: 3  }
  ];
  
  linkType = [
    { id: 1, name: 'Internal Link'},
    { id: 2, name: 'External Link' },
    { id: 3, name: 'Launch Game Link' },
  ]

  transactionTypes = this.transactionDataService.getTransactionType();
  banks = this.bankHttpService.getBanks();
  members = this.memberHttpService.getAllDropdown();
  currencies = this.currencyHttpService.getCurrency();
  bannerTypes = this.bannerHttpService.getBannerTypes();
  merchantBanks = this.merchantBankHttpService.getMerchantBanks();
  merchantBanksCrypto = this.merchantBankHttpService.getMerchantBanks(null, 5);
  merchantBankTypes = this.merchantBankHttpService.getMerchantBankTypes();
  merchantBankAccounts = this.merchantBankHttpService.getMerchantBankAccounts();
  memberBankAccount = this.memberBankAccountHttpService.getMemberBanks();
  groups = this.groupHttpService.getGroups();
  bankPurposes = this.purposeHttpService.getPurposes();
  transactionType = this.transactionHttpService.getTransactionType();
  contactTypes = this.contactHttpService.getContactTypes();
  contactPlatforms = this.contactHttpService.getPlatforms();
  activeContactPlatforms = this.contactHttpService.getActivePlatforms();
  roles = this.roleHttpService.getRoles();
  applicationRoles = this.applicationHttpService.getRolesDropdown(); // get all app roles without filters
  paymentMethods = this.transactionHttpService.getPaymentMethods(2,0);
  paymentMethodsDeposit = this.transactionHttpService.getPaymentMethods(1,0);
  paymentMethodsDepositCrypto = this.transactionHttpService.getPaymentMethods(1,1);
  functionSections = this.functionSectionHttpService.getFunctionSections();
  gameProviders = this.gameProviderHttpService.getGameProviders();
  gameCategories = this.gameProviderHttpService.getGameCategories();
  disabledApplicationPermissions = this.applicationHttpService.getDisabledAppPermissions();

  targetTypes = this.promotionSettingHttpService.getTargetTypes();
  promoTypes = this.promotionSettingHttpService.getPromoTypes();
  promoCategories = this.promotionSettingHttpService.getPromoCategories();
  promoContentCategories = this.promotionSettingHttpService.getPromoContentCategories();
  promoApplyActions = this.promotionSettingHttpService.getApplyActions();
  fillInForms = this.promotionSettingHttpService.getFillInForm();
  campaigns = this.campaignHttpService.getUnpaginatedCampaign();
  smsPurposes = this.smsHttpService.getSmsPurpose();
  registrationParams = this.registrationParamsHttpService.getParams();
  locations = this.announcementDataService.getAnnouncementLocations();
  cache = this.cacheHttpService.getCacheList();
  bankTransactionTypes = this.bankHttpService.getTransactionTypes();
  createBankTransactionTypes = this.bankHttpService.getCreateTransactionTypes();
  options = this.gameTypeReportDataService.getOptions();
  faqSections = this.faqDataService.getFaqSection();
  predictionStatus = this.predictionStatusHttpService.getPredictionStatus();
  predictionTypes = this.allEventsDataService.getPredictionTypes();
  eventGroupTypes = this.allEventsDataService.getGroupTypes();
  affiliateGroups = this.groupHttpService.getAffiliateGroups();
  merchantSites = this.merchantSitesHttpService.getMerchantSites();
  sampleMessage = this.sampleMessageHttpServiceHttpService.getSampleMessage();
  whatsappCurrencies = this.whatsappCurrencyHttpService.getWhatsappCurrency();
  telemarketer = this.roleHttpService.getTelemarketer();
  accountManager = this.roleHttpService.getAccountManager();
  normalAccountManager = this.roleHttpService.getNormalAccountManager();
  vipAccountManager = this.roleHttpService.getVipAccountManager();
  memberLabels = this.memberLabelHttpService.getLabelDropdown();
  footerTypes = this.footerSectionDataService.getFooterType();
  contact = this.contactHttpService.getContact();
  footerSection = this.footerSectionHttpService.getFooterSection();
  linkWith = this.linkWithHttpService.getLinkWith();
  smsTypes = this.smsHttpService.getSmsTypes();
  assignGameTags = this.miniGameDataService.getAssignGameTag();
  gameTags = this.miniGameDataService.getGameTag();
  seoDomains = this.seoHttpService.getSeoDomains();
  timezones = this.timezoneHttpService.getTimezone();
  liveChatPlatform = this.livechatDataService.getLiveChatPlatform();
  svgIcons = this.menuBarDataService.getSVGIcon();
  cryptoTokens = this.cryptoTokenService.getTokens();

  depositDateTypes = [
    { name: 'Created At', value: 'created_at'},
    { name: 'Processing Time', value: 'updated_at'},
    { name: 'Approved Time', value: 'approved_at'},
  ];

  // Note: No DB hanlder for now...
  uploadTypes = [
    null,
    'banners',
    'receipts',
    'promotions',
    'uploads'
  ];

  announcementTypes = [
    { id: 1, name: 'Marquee' },
    { id: 2, name: ' Popup' },
  ];

  countries = [
    'MY',
    'SG',
    'TH',
    'ID'
  ]

  messageTemplateSection(action: 'create' | 'edit' = 'edit') {
    return this.messageTemplateDataService.getMessageTemplateSection(action);
  }

  ipTypes = [
    { id: 0, name: 'Static' },
    { id: 1, name: 'Dynamic' },
  ]

  sitePlatforms = [
    { id: 1, name: 'User Portal' },
    { id: 2, name: 'Affiliate Portal' }
  ]

  sitePlatformsContact = [
    { id: 1, name: 'User Portal' },
    { id: 2, name: 'Affiliate Portal' },
    { id: 3, name: 'Offer Page' }
  ]

  merchantBanksType = [
    { id: 1, name: 'Member' },
    { id: 2, name: 'Affiliate' }
  ]

  memberGameLogType = [
    'Normal',
    'BG',
    'EVO'
  ]

  miniGameStatus = [
    { id: 0, name: 'Inactive'},
    { id: 1, name: 'Active'},
    { id: 2, name: 'New'},
    { id: 3, name: 'Disable'},
  ]

  filterLeadType = [
    { id: 1, name: 'Member Lead' },
    { id: 2, name: 'Converted Member Lead' },
    { id: 3, name: 'Non-Member Lead' }
  ]

  filterLeadTypeCategory = [
    { id: 1, name: 'Depositor' },
    { id: 2, name: 'Non-Depositor' },
  ]

  contentType = [
    { id: 1, name: "Website" , code: "web" },
    { id: 2, name: "Mobile" ,  code: "mobile"}
  ]

  developmentStage = [
    { id: 1, name: 'Normal' },
    { id: 2, name: 'VIP' }
  ]

  normalLeadStage = [
    { id: 1, name: "1 (Member Recovery)" },
    { id: 2, name: "2 (Member Development)" }
  ]

  vipLeadStage = [
    { id: 1, name: "1 & 2 (VIP Member Development)" }
  ]

  normalMemberRecoveryType = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Unsuccessful on Initial Try' },
  ]

  vipMemberRecoveryType = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Unsuccessful on Initial Try' },
    { id: 3, name: 'Both' }
  ]

  memberRecoveryInactiveDays = [
    { value: 1, name: 'Past 1 Day' },
    { value: 2, name: 'Past 2 Days' },
    { value: 3, name: 'Past 3 Days' },
    { value: 7, name: 'Past 7 Days' },
    { value: 15, name: 'Past 15 Days' },
    { value: 30, name: 'Past 1 Month' },
  ]

  telemarketerRewardType = [
    { value: 'telemarketer_reward', name: 'Telemarketer' },
    { value: 'account_manager_reward', name: 'Account Manager' },
    { value: 'vip_account_manager_reward', name: 'Vip Account Manager' },
  ]

  allCountries = [
    { code: "AF",  name: "Afghanistan" },
    { code: "AL",  name: "Albania" },
    { code: "DZ",  name: "Algeria" },
    { code: "AS",  name: "American Samoa" },
    { code: "AD",  name: "Andorra" },
    { code: "AO",  name: "Angola" },
    { code: "AI",  name: "Anguilla" },
    { code: "AQ",  name: "Antarctica" },
    { code: "AG",  name: "Antigua and Barbuda" },
    { code: "AR",  name: "Argentina" },
    { code: "AM",  name: "Armenia" },
    { code: "AW",  name: "Aruba" },
    { code: "AU",  name: "Australia" },
    { code: "AT",  name: "Austria" },
    { code: "AZ",  name: "Azerbaijan" },
    { code: "BS",  name: "Bahamas" },
    { code: "BH",  name: "Bahrain" },
    { code: "BD",  name: "Bangladesh" },
    { code: "BB",  name: "Barbados" },
    { code: "BY",  name: "Belarus" },
    { code: "BE",  name: "Belgium" },
    { code: "BZ",  name: "Belize" },
    { code: "BJ",  name: "Benin" },
    { code: "BM",  name: "Bermuda" },
    { code: "BT",  name: "Bhutan" },
    { code: "BO",  name: "Bolivia" },
    { code: "BQ",  name: "Bonaire" },
    { code: "BA",  name: "Bosnia and Herzegovina" },
    { code: "BW",  name: "Botswana" },
    { code: "BV",  name: "Bouvet Island" },
    { code: "BR",  name: "Brazil" },
    { code: "IO",  name: "British Indian Ocean Territory" },
    { code: "BN",  name: "Brunei" },
    { code: "BG",  name: "Bulgaria" },
    { code: "BF",  name: "Burkina Faso" },
    { code: "BI",  name: "Burundi" },
    { code: "CV",  name: "Cabo Verde" },
    { code: "KH",  name: "Cambodia" },
    { code: "CM",  name: "Cameroon" },
    { code: "CA",  name: "Canada" },
    { code: "KY",  name: "Cayman Islands" },
    { code: "CF",  name: "Central African Republic" },
    { code: "TD",  name: "Chad" },
    { code: "CL",  name: "Chile" },
    { code: "CN",  name: "China" },
    { code: "CX",  name: "Christmas Island" },
    { code: "CC",  name: "Cocos (Keeling) Islands" },
    { code: "CO",  name: "Colombia" },
    { code: "KM",  name: "Comoros" },
    { code: "CD",  name: "Democratic Republic of the Congo" },
    { code: "CG",  name: "Republic of the Congo" },
    { code: "CK",  name: "Cook Islands" },
    { code: "CR",  name: "Costa Rica" },
    { code: "HR",  name: "Croatia" },
    { code: "CU",  name: "Cuba" },
    { code: "CW",  name: "Curaçao" },
    { code: "CY",  name: "Cyprus" },
    { code: "CZ",  name: "Czechia" },
    { code: "CI",  name: "Côte d'Ivoire" },
    { code: "DK",  name: "Denmark" },
    { code: "DJ",  name: "Djibouti" },
    { code: "DM",  name: "Dominica" },
    { code: "DO",  name: "Dominican Republic" },
    { code: "EC",  name: "Ecuador" },
    { code: "EG",  name: "Egypt" },
    { code: "SV",  name: "El Salvador" },
    { code: "GB-ENG",  name: "England" },
    { code: "GQ",  name: "Equatorial Guinea" },
    { code: "ER",  name: "Eritrea" },
    { code: "EE",  name: "Estonia" },
    { code: "SZ",  name: "Eswatini" },
    { code: "ET",  name: "Ethiopia" },
    { code: "FK",  name: "Falkland Islands" },
    { code: "FO",  name: "Faroe Islands" },
    { code: "FJ",  name: "Fiji" },
    { code: "FI",  name: "Finland" },
    { code: "FR",  name: "France" },
    { code: "GF",  name: "French Guiana" },
    { code: "PF",  name: "French Polynesia" },
    { code: "TF",  name: "French Southern Territories" },
    { code: "GA",  name: "Gabon" },
    { code: "GM",  name: "Gambia" },
    { code: "GE",  name: "Georgia" },
    { code: "DE",  name: "Germany" },
    { code: "GH",  name: "Ghana" },
    { code: "GI",  name: "Gibraltar" },
    { code: "GR",  name: "Greece" },
    { code: "GL",  name: "Greenland" },
    { code: "GD",  name: "Grenada" },
    { code: "GP",  name: "Guadeloupe" },
    { code: "GU",  name: "Guam" },
    { code: "GT",  name: "Guatemala" },
    { code: "GG",  name: "Guernsey" },
    { code: "GN",  name: "Guinea" },
    { code: "GW",  name: "Guinea-Bissau" },
    { code: "GY",  name: "Guyana" },
    { code: "HT",  name: "Haiti" },
    { code: "HM",  name: "Heard Island and McDonald Islands" },
    { code: "VA",  name: "Holy See" },
    { code: "HN",  name: "Honduras" },
    { code: "HK",  name: "Hong Kong" },
    { code: "HU",  name: "Hungary" },
    { code: "IS",  name: "Iceland" },
    { code: "IN",  name: "India" },
    { code: "ID",  name: "Indonesia" },
    { code: "IR",  name: "Iran" },
    { code: "IQ",  name: "Iraq" },
    { code: "IE",  name: "Ireland" },
    { code: "IM",  name: "Isle of Man" },
    { code: "IL",  name: "Israel" },
    { code: "IT",  name: "Italy" },
    { code: "JM",  name: "Jamaica" },
    { code: "JP",  name: "Japan" },
    { code: "JE",  name: "Jersey" },
    { code: "JO",  name: "Jordan" },
    { code: "KZ",  name: "Kazakhstan" },
    { code: "KE",  name: "Kenya" },
    { code: "KI",  name: "Kiribati" },
    { code: "KP",  name: "North Korea" },
    { code: "KR",  name: "South Korea" },
    { code: "XK",  name: "Kosovo" },
    { code: "KW",  name: "Kuwait" },
    { code: "KG",  name: "Kyrgyzstan" },
    { code: "LA",  name: "Lao People's Democratic Republic" },
    { code: "LV",  name: "Latvia" },
    { code: "LB",  name: "Lebanon" },
    { code: "LS",  name: "Lesotho" },
    { code: "LR",  name: "Liberia" },
    { code: "LY",  name: "Libya" },
    { code: "LI",  name: "Liechtenstein" },
    { code: "LT",  name: "Lithuania" },
    { code: "LU",  name: "Luxembourg" },
    { code: "MO",  name: "Macao" },
    { code: "MG",  name: "Madagascar" },
    { code: "MW",  name: "Malawi" },
    { code: "MY",  name: "Malaysia" },
    { code: "MV",  name: "Maldives" },
    { code: "ML",  name: "Mali" },
    { code: "MT",  name: "Malta" },
    { code: "MH",  name: "Marshall Islands" },
    { code: "MQ",  name: "Martinique" },
    { code: "MR",  name: "Mauritania" },
    { code: "MU",  name: "Mauritius" },
    { code: "YT",  name: "Mayotte" },
    { code: "MX",  name: "Mexico" },
    { code: "FM",  name: "Micronesia" },
    { code: "MD",  name: "Moldova" },
    { code: "MC",  name: "Monaco" },
    { code: "MN",  name: "Mongolia" },
    { code: "ME",  name: "Montenegro" },
    { code: "MS",  name: "Montserrat" },
    { code: "MA",  name: "Morocco" },
    { code: "MZ",  name: "Mozambique" },
    { code: "MM",  name: "Myanmar" },
    { code: "NA",  name: "Namibia" },
    { code: "NR",  name: "Nauru" },
    { code: "NP",  name: "Nepal" },
    { code: "NL",  name: "Netherlands" },
    { code: "NC",  name: "New Caledonia" },
    { code: "NZ",  name: "New Zealand" },
    { code: "NI",  name: "Nicaragua" },
    { code: "NE",  name: "Niger" },
    { code: "NG",  name: "Nigeria" },
    { code: "NU",  name: "Niue" },
    { code: "NF",  name: "Norfolk Island" },
    { code: "GB-NIR",  name: "Northern Ireland" },
    { code: "MP",  name: "Northern Mariana Islands" },
    { code: "NO",  name: "Norway" },
    { code: "OM",  name: "Oman" },
    { code: "PK",  name: "Pakistan" },
    { code: "PW",  name: "Palau" },
    { code: "PS",  name: "Palestine" },
    { code: "PA",  name: "Panama" },
    { code: "PG",  name: "Papua New Guinea" },
    { code: "PY",  name: "Paraguay" },
    { code: "PE",  name: "Peru" },
    { code: "PH",  name: "Philippines" },
    { code: "PN",  name: "Pitcairn" },
    { code: "PL",  name: "Poland" },
    { code: "PT",  name: "Portugal" },
    { code: "PR",  name: "Puerto Rico" },
    { code: "QA",  name: "Qatar" },
    { code: "MK",  name: "Republic of North Macedonia" },
    { code: "RO",  name: "Romania" },
    { code: "RU",  name: "Russia" },
    { code: "RW",  name: "Rwanda" },
    { code: "RE",  name: "Réunion" },
    { code: "BL",  name: "Saint Barthélemy" },
    { code: "SH",  name: "Saint Helena" },
    { code: "KN",  name: "Saint Kitts and Nevis" },
    { code: "LC",  name: "Saint Lucia" },
    { code: "MF",  name: "Saint Martin" },
    { code: "PM",  name: "Saint Pierre and Miquelon" },
    { code: "VC",  name: "Saint Vincent and the Grenadines" },
    { code: "WS",  name: "Samoa" },
    { code: "SM",  name: "San Marino" },
    { code: "ST",  name: "Sao Tome and Principe" },
    { code: "SA",  name: "Saudi Arabia" },
    { code: "GB-SCT",  name: "Scotland" },
    { code: "SN",  name: "Senegal" },
    { code: "RS",  name: "Serbia" },
    { code: "SC",  name: "Seychelles" },
    { code: "SL",  name: "Sierra Leone" },
    { code: "SG",  name: "Singapore" },
    { code: "SX",  name: "Sint Maarten" },
    { code: "SK",  name: "Slovakia" },
    { code: "SI",  name: "Slovenia" },
    { code: "SB",  name: "Solomon Islands" },
    { code: "SO",  name: "Somalia" },
    { code: "ZA",  name: "South Africa" },
    { code: "GS",  name: "South Georgia and the South Sandwich Islands" },
    { code: "SS",  name: "South Sudan" },
    { code: "ES",  name: "Spain" },
    { code: "LK",  name: "Sri Lanka" },
    { code: "SD",  name: "Sudan" },
    { code: "SR",  name: "Suriname" },
    { code: "SJ",  name: "Svalbard and Jan Mayen" },
    { code: "SE",  name: "Sweden" },
    { code: "CH",  name: "Switzerland" },
    { code: "SY",  name: "Syrian Arab Republic" },
    { code: "TW",  name: "Taiwan" },
    { code: "TJ",  name: "Tajikistan" },
    { code: "TZ",  name: "Tanzania" },
    { code: "TH",  name: "Thailand" },
    { code: "TL",  name: "Timor-Leste" },
    { code: "TG",  name: "Togo" },
    { code: "TK",  name: "Tokelau" },
    { code: "TO",  name: "Tonga" },
    { code: "TT",  name: "Trinidad and Tobago" },
    { code: "TN",  name: "Tunisia" },
    { code: "TR",  name: "Turkey" },
    { code: "TM",  name: "Turkmenistan" },
    { code: "TC",  name: "Turks and Caicos Islands" },
    { code: "TV",  name: "Tuvalu" },
    { code: "UG",  name: "Uganda" },
    { code: "UA",  name: "Ukraine" },
    { code: "AE",  name: "United Arab Emirates)" },
    { code: "GB",  name: "United Kingdom" },
    { code: "UM",  name: "United States Minor Outlying Islands" },
    { code: "US",  name: "United States of America" },
    { code: "UY",  name: "Uruguay" },
    { code: "UZ",  name: "Uzbekistan" },
    { code: "VU",  name: "Vanuatu" },
    { code: "VE",  name: "Venezuela" },
    { code: "VN",  name: "Viet Nam" },
    { code: "VG",  name: "Virgin Islands (British)" },
    { code: "VI",  name: "Virgin Islands (U.S.)" },
    { code: "GB-WLS",  name: "Wales" },
    { code: "WF",  name: "Wallis and Futuna" },
    { code: "EH",  name: "Western Sahara" },
    { code: "YE",  name: "Yemen" },
    { code: "ZM",  name: "Zambia" },
    { code: "ZW",  name: "Zimbabwe" },
    { code: "AX",  name: "Åland Islands" }
  ];

  processingFeeType = [
    { id: 1, name: 'Fixed Amount' },
    { id: 2, name: 'Percentage' },
    { id: 3, name: 'Fixed Amount + Percentage' }
  ];

  absorbProcessingFeeType = [
    { id: 1, name: 'Company' },
    { id: 2, name: 'Player' },
    { id: 3, name: 'Company + Player' }
  ]

  openGraphType = [
    { id: 1, name: 'Website' },
    { id: 2, name: 'Article' },
  ];

  promotionContentType = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Desktop' },
    { id: 2, name: 'Mobile' }
  ]

  siteDomainTypes = [
    { id: 1, name: 'Clear Cache' },
    { id: 2, name: 'Redirect' },
    { id: 3, name: 'Game Launch' },
    { id: 4, name: 'User Portal' },
    { id: 5, name: 'App Download URL' },
    { id: 6, name: 'Members Only' },
    { id: 7, name: 'Registration API' },
  ]

  deviceTypes = [
    { id: 1, name: 'Desktop' },
    { id: 2, name: 'Mobile' },
  ]

  campaignRewardStatus = [
    { id: 0, name: 'Please Select'},
    { id: 1, name: 'Active'},
    { id: 2, name: 'Pending Approval'}
  ]

  merchantBankStatus = [
    { name: 'Inactive', value: 0, checked: true },
    { name: 'Active', value: 1, checked: true },
    { name: 'On Hold', value: 2, checked: true },
  ];

  supportedTargetType = [
    { id: 2, name: 'Turnover'},
    { id: 3, name: 'Winover' },
  ]

  authorizedRoleVersion = [
    { id: 1, name: 'Role (Version 1.0)' },
    { id: 2, name: 'New Role (Version 2.0)' },
    { id: 3, name: 'Mixed Mode (Version 1.0 & 2.0)' },
  ];

  memberReportType = [
    'General',
    'Daily',
    // 'Weekly',
  ];
}
