<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div  class="kt-portlet kt-portlet--mobile">
      <div  class="kt-portlet__body">

          <div class="kt-section col-12 d-flex flex-row-reverse mb-3">
              <button *ngIf="canCreateRecoveryPromotion" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
          </div>
          <div  class="kt-section">
              <div  class="kt-section__content">
                  <div  class="table-responsive">
                      <table  class="table table-bordered table-hover table-striped">
                          <thead>
                            <tr class="text-top">
                                <th rowspan="2">ID</th>
                                <th rowspan="2">Name</th>
                                <th rowspan="2" class="text-center">Days of Inactivity</th>
                                <th rowspan="2" class="text-center">Promotion Code</th>
                                <th rowspan="2" class="text-center">Reward Name</th>
                                <th rowspan="2" class="text-center">Status</th>
                                <th rowspan="2" class="text-center">Depositor</th>
                                <th rowspan="2" class="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let row of recoveryPromotions$ | async">
                              <td>{{ row.id }} </td>
                              <td>{{ row.name }}</td>
                              <td class="text-center">{{ row.inactivity }}</td>
                              <td class="text-center">{{ row.promotion_code }}</td>
                              <td>{{ row.description }}</td>
                              <td class="text-center">
                                  <div class="custom-control custom-switch">
                                      <input type="checkbox" class="custom-control-input" (click)="onToggleStatus(row, $event)" [id]="row.id" [checked]="row.status === 1 ? true : false">
                                      <label class="custom-control-label" [for]="row.id"></label>
                                  </div>
                              </td>
                              <td class="text-center"><span class="fas" [ngClass]="row.depositor > 0 ? 'fa-check kt-font-info' : 'fa-times kt-font-danger'"></span></td>
                              <td class="text-center">
                                <button *ngIf="canDuplicateRecoveryPromotion" matTooltip="Duplicate" [disabled]="buttonLoading" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onDuplicate(row)"><i class="fas fa-copy"></i></button>
                                <button *ngIf="canEditRecoveryPromotion" matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row)"><i class="fas fa-edit"></i></button>
                              </td>
                            </tr>
                            <kt-fallback-row [collection]="recoveryPromotions$"></kt-fallback-row>
                          </tbody>
                      </table>
                  </div>
                  <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                    <ngb-pagination
                      [pageSize]="pageSize"
                      [(page)]="page"
                      [maxSize]="maxSize"
                      [directionLinks]="true"
                      [boundaryLinks]="true"
                      [rotate]="true"
                      [collectionSize]="pagination.total"
                      (pageChange)="onViewPageBy(page)">
                    </ngb-pagination>
                    <div class="kt-pagination__toolbar">
                        <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                            <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                        </select>
                        <span class="pagination__desc">
                          Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                        </span>
                    </div>
                  </div>
              </div>
          </div>
          <!-- table ends -->
      </div>
  </div>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onReload()"></kt-swal-alert>
