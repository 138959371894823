import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';
import { Transfer } from '@core/models/transfer.model';

@Injectable()
export class TransferDataService extends DefaultDataService<Transfer>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Transfer', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<Transfer[]> {
    return this.http.get<ApiResponse>('/transfer?status=0').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<Transfer[]>{
    return this.http.get<ApiResponse>(`/transfer${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  getPromotion(pageParam: string){
    return this.http.get<ApiResponse>(`/transfer/promotion${pageParam}`).pipe(
      map(res => res.data)
    )
  }

  getById(id: number): Observable<Transfer>{
    return this.http.get<ApiResponse>(`/transfer/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  add(transfer: Transfer, emptyWallet?: boolean): Observable<Transfer>{
    return this.http.post<ApiResponse>(`/transfer`, transfer).pipe(
      //not displaying message when successfully empty game wallet
      tap(res => {
        if(!res.success || !emptyWallet){
          this.messages$.next(res.message)
        }
      }),
      map(res => res.data.rows)
    )
  }

  updateTransfer(transfer: Transfer) {
    return this.http.put<ApiResponse>(`/transfer/statusupdate?id=${transfer.id}&status=${transfer.status}`, transfer).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  refund(transfer: any) {
    return this.http.put<ApiResponse>(`/transfer/refund/${transfer.id}`, transfer).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  retry(transfer: any) {
    return this.http.put<ApiResponse>(`/transfer/retry/${transfer.id}`, transfer).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    );
  }

  transferCheck(id: any) {
    return this.http.post<ApiResponse>(`/transfer/check?id=${id}`, null).pipe(
      map(res => res)
    );
  }


  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }
}
