import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RecoveryMessage } from '@core/models/recovery-message.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { forkJoin, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { RecoveryMessageDataService } from '../recovery-messages/services/recovery-message-data.service';
import { RecoveryMessageEntityService } from '../recovery-messages/services/recovery-message-entity.service';
import { MessageTemplateDataService } from '../../../superuser/message-template/services/message-template-data.service';
import { LocaleHttpService } from '@core/services/locale-http.service';

@Component({
  selector: 'kt-recovery-message-details',
  templateUrl: './recovery-message-details.component.html',
  styleUrls: ['./recovery-message-details.component.scss']
})
export class RecoveryMessageDetailsComponent implements OnInit {

  form: FormGroup;
  messages$ = this.recoveryMessageDataservice.messages$;
  currency_id = this.data.mode === 'edit' ? this.data.recoveryMessage.currency_id : 0;
  settings_locale_id = this.data.mode === 'edit' ? this.data.recoveryMessage.locale_id : 0;

  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    locales: this.LocaleHttpService.getLocales(this.currency_id),
    smstemplates: this.messageTemplateDataService.getMessageTemplateList(2, 8), // Get sms templates with type = 2 (SMS) and section = 8 (Promotions)
    messagetemplates: this.messageTemplateDataService.getMessageTemplateList(1, 8) // Get message templates with type = 1 (Message) and section = 8 (Promotions)
  };

  MessageTemplatesSettings = {};
  smsTemplateList = [];
  smsTemplateSelectedItem = [];
  messageTemplateList = [];
  messageTemplateSelectedItem = [];

  buttonLoading = false;
  private subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { recoveryMessage: RecoveryMessage, mode: string, recoveryId: number },
    public dialogRef: MatDialogRef<RecoveryMessageDetailsComponent>,
    private dropdownHttpService: DropdownHttpService,
    private recoveryMessageDataservice: RecoveryMessageDataService,
    private messageTemplateDataService: MessageTemplateDataService,
    private LocaleHttpService: LocaleHttpService
    ) { }

  ngOnInit() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
      });
    }
    this.smsTemplateSelectedItem = [];
    this.messageTemplateSelectedItem = [];

    // Dropdown list for SMS templates
    this.dropdown.smstemplates.subscribe(res => {
      this.smsTemplateList = res;
      if (this.data.mode === 'edit' && this.data.recoveryMessage.sms_template_id !== null) {
        this.smsTemplateSelectedItem = [this.smsTemplateList.find(x => x.id === this.data.recoveryMessage.sms_template_id) ? this.smsTemplateList.find(x => x.id === this.data.recoveryMessage.sms_template_id) : []];
      }
    })

    // Dropdown list for Message templates
    this.dropdown.messagetemplates.subscribe(res => {
      this.messageTemplateList = res;
      if (this.data.mode === 'edit' && this.data.recoveryMessage.message_template_id !== null) {
        this.messageTemplateSelectedItem = [this.messageTemplateList.find(x => x.id === this.data.recoveryMessage.message_template_id) ? this.messageTemplateList.find(x => x.id === this.data.recoveryMessage.message_template_id) : []];
      }
    })

    // Message templates Dropdown Settings
    this.MessageTemplatesSettings = {
      autoPosition: false,
      maxHeight: 200,
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'code',
      noDataLabel: '',
      showCheckbox: false
    };
    this.formInit();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave(recoveryMessage: RecoveryMessage, mode: string) {
    this.buttonLoading = true;

    const formData = this.form.getRawValue();
    const data = {
      id: recoveryMessage ? recoveryMessage.id : null,
      recovery_promotion_setting_id: this.data.recoveryId,
      ...formData
    };
    Object.keys(data).forEach((key) => (data[key] == null || (data[key] === '')) && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.recoveryMessageDataservice.updatePromotionMessage(data).pipe(
          tap((res: any) => {
            this.buttonLoading = false;
            this.onCloseDialog();
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.recoveryMessageDataservice.add(data).pipe(
            tap((res: any) => {
              this.form.setErrors(null);
              this.buttonLoading = false;
              this.onCloseDialog();
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.recoveryMessageDataservice.messages$
        ]).subscribe();
        break;
    }
  }

  private formInit() {
    let currency = null;
    let locale_id = null;
    let sms_template_id = null;
    let message_template_id = null;

    if (this.data.mode === 'edit') {
      currency = this.data.recoveryMessage.currency_id;
      locale_id = this.data.recoveryMessage.locale_id;
      sms_template_id = this.data.recoveryMessage.sms_template_id;
      message_template_id = this.data.recoveryMessage.message_template_id;
    }

    this.form = new FormGroup({
      currency_id: new FormControl({ value: currency, disabled: this.data.mode === 'edit' ? true : false }, [Validators.required]),
      locale_id: new FormControl(locale_id, [Validators.required]),
      sms_template_id: new FormControl(sms_template_id),
      message_template_id: new FormControl(message_template_id)
  });
  }

  // Change locale list based on currency selected if the currency is changed
  onCurrencyChange() {
    if (this.form.value.currency_id !== undefined) {
      this.dropdown.locales = this.LocaleHttpService.getLocales(this.form.value.currency_id);
    }
  }

  // Change dropdown list based on locale selected and reset the selected SMS and message if the locale is changed
  onLocaleChange() {
    if (this.form.value.locale_id !== undefined) {
      this.dropdown.smstemplates = this.messageTemplateDataService.getMessageTemplateList(2, 8), // Get sms templates with type = 2 (SMS) and section = 8 (Promotions)
      this.dropdown.messagetemplates = this.messageTemplateDataService.getMessageTemplateList(1, 8) // Get message templates with type = 1 (Message) and section = 8 (Promotions)

      // Dropdown list for SMS templates
      this.dropdown.smstemplates.subscribe(res => {
        this.smsTemplateList = res;
        this.smsTemplateSelectedItem = [];
        this.form.patchValue({ sms_template_id: null });
      })

      // Dropdown list for Message templates
      this.dropdown.messagetemplates.subscribe(res => {
        this.messageTemplateList = res;
        this.messageTemplateSelectedItem = [];
        this.form.patchValue({ message_template_id: null });
      })
    }
  }
}
