import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IpWhitelisting } from '@core/models/ip-whitelisting.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { IpWhitelistingDataService } from '../services/ip-whitelisting-data.service';
import { catchError, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-ip-whitelisting-edit',
  templateUrl: './ip-whitelisting-edit.component.html',
  styleUrls: ['./ip-whitelisting-edit.component.scss']
})
export class IpWhitelistingEditComponent implements OnInit, OnDestroy {

  form: FormGroup;
  buttonLoading = false;
  messages$ = this.ipWhitelistingDataService.messages$;
  dropdown = {
    merchant: this.dropdownHttpService.merchantSites,
    type: this.dropdownHttpService.ipTypes
  }

  // permissions
  canCreateIpWhitelisting: boolean;
  canEditIpWhitelisting: boolean;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, ip: IpWhitelisting },
    public dialogRef: MatDialogRef<IpWhitelistingEditComponent>,
    private dropdownHttpService: DropdownHttpService,
    private ipWhitelistingDataService: IpWhitelistingDataService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateIpWhitelisting = appPermissions.create_ip_whitelist;
      this.canEditIpWhitelisting = appPermissions.edit_ip_whitelist;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  onSave() {
    this.buttonLoading = true;
    // To set "Save" button to disable (To prevent call API in multiple times when double click)
    this.form.setErrors({ 'invalid': true });
    const data = {
      ...this.form.value,
      merchant_id: +this.form.value.merchant_id,
      type: +this.form.value.type
    }
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    if (this.data.mode === 'create') {
      this.ipWhitelistingDataService.add(data).pipe(
        tap((res: any) => {
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    } else if (this.data.mode === 'edit') {
      this.ipWhitelistingDataService.update(this.data.ip.id, data).pipe(
        tap((res: any) => {
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }
  }

  private formInit() {
    let merchant = this.data.mode === 'edit' ? this.data.ip.site_id : null;
    let ip = this.data.mode === 'edit' ? this.data.ip.ip_address : null;
    let type = this.data.mode === 'edit' ? this.data.ip.type : null;
    let status = this.data.mode === 'edit' ? this.data.ip.status : null;

    if (this.data.mode === 'create') {
      this.form = new FormGroup({
        merchant_id: new FormControl(merchant, [Validators.required]),
        ip: new FormControl(ip, [Validators.required]),
        type: new FormControl(type, [Validators.required]),
      });
    } else {
      this.form = new FormGroup({
        merchant_id: new FormControl(merchant, [Validators.required]),
        ip: new FormControl(ip, [Validators.required]),
        type: new FormControl(type, [Validators.required]),
        status: new FormControl(status, [Validators.required])
      });
    }
  }
}
