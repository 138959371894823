<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form" *ngIf="data.mode === 'edit'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"> Withdrawal Details</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <div class="p-0 m-0">
          <div class="row">
            <section class="col-lg-6 card border-0">
              <div class="col-12 p-0 card rounded-0">
                  <div class="card-header">
                    Member Info
                  </div>
                  <div class="col-12 border-top-0 p-4">
                    <section class="row border-top border-left">
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                        <span class="kt-font-bold">Member ID</span>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                        {{ withdraw.member_id }}
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                        <span class="kt-font-bold">Username</span>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                        {{ withdraw.member_username }}
                      </div>
                    </section>
                    <section class="row border-left">
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                        <span class="kt-font-bold">Name</span>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                        {{ withdraw.name }}
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                        <span class="kt-font-bold">Member Group</span>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                        {{ withdraw.member_group }}
                      </div>
                    </section>
                    <section class="row border-left">
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3 no-break">
                        <span class="kt-font-bold">Remarks</span>
                      </div>
                      <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 border-right border-bottom p-3 d-flex justify-content-between">
                        <span>{{ withdraw.member_remarks ? withdraw.member_remarks : "-" }}</span>
                        <i (click)="onViewMoreRemarks(data.withdraw)" *ngIf="data.withdraw.remarks_count > 1" class="fas fa-stream d-flex align-items-center pointer" matTooltip="More"></i>
                      </div>
                    </section>
                  </div>
              </div>
            </section>
            <section class="col-lg-6 card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  {{ data.withdrawalBankType == 'crypto' ? 'Crypto Wallet Info' : 'Bank Info' }}
                </div>
                <div class="col-12 p-4">
                  <section class="row border-top border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">{{ data.withdrawalBankType == 'crypto' ? 'Token' : 'Bank Name' }}</span>
                    </div>
                    <ng-container *ngIf="data.withdrawalBankType == 'banking'">
                      <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 border-right border-bottom p-3">
                        {{ withdraw.member_bank_name }}
                      </div>
                    </ng-container>
                    <ng-container *ngIf="data.withdrawalBankType == 'crypto'">
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                        {{ withdraw.token }} - {{ withdraw.network }}
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                        <span class="kt-font-bold">Wallet Nickname</span>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                        {{ withdraw.crypto_wallet_nickname || '-' }}
                      </div>
                    </ng-container>
                  </section>
                  <section class="row border-left">
                    <ng-container *ngIf="data.withdrawalBankType == 'banking'">
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                        <span class="kt-font-bold">Account Name</span>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                        {{ withdraw.member_account_name }}
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                        <span class="kt-font-bold">Account Number</span>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                        <ng-container *ngIf="withdraw.member_bank_name === 'PayNow'; else elseOtherNumber">
                          {{ onPayNowBankNumber(withdraw) }}
                        </ng-container>
                        <ng-template #elseOtherNumber>
                          {{ withdraw.member_account_number }}
                        </ng-template>
                        <!-- <button matTooltip="CCID Lookup" *ngIf="withdraw.currency === 'MYR'" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onLookup(withdraw.member_account_number)"><i class="fas fa-search"></i></button> -->
                      </div>
                    </ng-container>
                    <ng-container *ngIf="data.withdrawalBankType == 'crypto'">
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                        <span class="kt-font-bold">Wallet Address</span>
                      </div>
                      <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                        {{ withdraw.crypto_wallet_address }}
                      </div>
                    </ng-container>
                  </section>
                  <section class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Remarks</span>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      {{ withdraw.ext_remarks ? withdraw.ext_remarks : '-' }}
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
          <div class="row mt-2">
            <section class="col-12 card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Transaction Info
                </div>
                <div class="col-12 p-4">
                  <section class="row border-top border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">ID</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ withdraw.id }}
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Created At</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ withdraw.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                    </div>
                  </section>
                  <section class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right p-3">
                      <span class="kt-font-bold">Status</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3 d-flex align-items-center">
                      <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="withdrawStatus === 4 ? 'kt-badge--10' : 'kt-badge--' + withdrawStatus"> {{ withdraw.status_name }}</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Confirmed Amount ({{withdraw.currency}})</span>
                    </div>
                    <div *ngIf="data.withdrawalBankType == 'banking'" class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom flex-row-reverse p-3">
                      {{ withdraw.confirmed_amount  | number : '1.2-2'}}
                    </div>
                    <div *ngIf="data.withdrawalBankType == 'crypto'"  class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom flex-row-reverse p-0">
                      <div class="row m-0">
                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 border-right border-bottom flex-row-reverse p-3">
                          {{withdraw.currency}}
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12 border-right border-bottom flex-row-reverse p-3">
                          {{ withdraw.confirmed_amount  | number : '1.2-2'}}
                        </div>
                      </div>
                      <div class="row m-0">
                        <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 border-right border-bottom flex-row-reverse p-3">
                          {{withdraw.token}}
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12 border-right border-bottom flex-row-reverse p-3">
                          {{ withdraw.cr_confirmed_amount  | number : '1.6-6'}}
                        </div>
                      </div>
                    </div>
                  </section>
                  <section class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Processing Date</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ withdraw.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Handler</span>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 border-right border-bottom p-3">
                      {{ data.newHandler ? data.newHandler : withdraw.handler }}
                    </div>

                  </section>
                  <section class="row border-left">
                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 card-header border-right border-bottom p-3">
                      <span class="kt-font-bold">Remarks</span>
                    </div>
                    <div class="input-group col-lg-9 col-md-9 col-sm-6 col-xs-12 align-items-center border-right border-bottom p-3">
                      <input type="text" formControlName="remarks" class="form-control" [ngClass]="{ 'is-invalid': this.form.value.errors }" #focusfield>
                      <span class="input-group-append"  *ngIf="data.withdraw.status === 1">
                        <button type="button" class="btn btn-success" (click)="onUpdateRemarks()">Update</button>
                      </span>
                      <label *ngIf="!isValidRemarks" class="col-12 col-form-label">
                        <mat-error>
                          <strong>Remarks required to reject</strong>
                        </mat-error>
                      </label>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="p-0 m-0">
          <div class="row mt-2">
            <section class="col-lg-12 card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  {{ data.withdrawalBankType == 'crypto' ? 'Crypto Transaction' : 'Bank Transaction' }}
                </div>
                <section class="col-12 p-4">
                  <div class="row" style="overflow-x: scroll;">

                    <!-- TODO: Might need to change the selector and move to shared if this will be re-used to Deposits! -->
                    <kt-withdrawal-multi
                      [id]="data.withdraw.id"
                      [purposeId]="purposeId"
                      [withdrawalDetails]="data.withdraw"
                      [currencyId]="data.withdraw.currency_id"
                      [confirmedAmount]="data.withdraw.confirmed_amount"
                      (changed)="bankTransactionsChanged($event)"
                      [bankTransactions]="data.withdraw.bank_transactions"
                      [status]="withdrawStatus"
                      [withdrawalBankType]="data.withdrawalBankType"
                      (update)="onUpdateBankTransactions($event)"
                      (payButtonExist)="handlePayButtonChange($event)"
                      class="w-100">
                    </kt-withdrawal-multi>

                  </div>
                </section>
              </div>
           </section>
          </div>

          <div class="row mt-2" *ngIf="transactionCallbackLogs.length > 0">
            <section class="col-lg-12 card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Payment Gateway Callback Log
                </div>
                <section class="col-12 p-4">
                  <div class="row">

                    <table class="table table-bordered table-hover table-striped table-layout-fixed">
                      <thead>
                        <tr>
                          <th class="text-center" style="width: 10%;">Callback ID</th>
                          <th class="text-center" style="width: 20%;">Transaction ID</th>
                          <th class="text-center" style="width: 50%;">Callback Details</th>
                          <th class="text-center" style="width: 20%;">Callback Date/Time</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="transactionCallbackLogs as rows">
                          <tr *ngFor="let row of rows">
                              <td class="text-center">{{row.id}}</td>
                              <td class="text-center">{{row.reference}}</td>
                              <td class="text-center">{{row.details}}</td>
                              <td class="text-center">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</td>
                          </tr>
                          <kt-fallback-row [collection]="rows"></kt-fallback-row>
                      </tbody>
                    </table>
                    <div class="kt-pagination kt-pagination--brand mt-2 col-12" *ngIf="transactionCallbackLogsPagination !== undefined">
                      <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="transactionCallbackLogPage"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="transactionCallbackLogsPagination.total"
                        (pageChange)="onGetTransactionCallbackLogs(transactionCallbackLogPage)">
                      </ngb-pagination>
                      <div class="kt-pagination__toolbar">
                          <span class="pagination__desc">
                            Showing {{transactionCallbackLogsPagination.from}} to {{transactionCallbackLogsPagination.to}} of {{ transactionCallbackLogsPagination.total | number : '1.0' }} records
                          </span>
                      </div>
                    </div>

                  </div>
                </section>
              </div>
           </section>
          </div>

          <div class="row mt-2">
            <section class="col-lg-12 card border-0">
              <div class="col-12 p-0 card rounded-0">
                <div class="card-header">
                  Past Transactions
                </div>
                <section class="col-12 p-4">
                  <div class="row">

                    <table class="table table-bordered table-hover table-striped">
                      <thead>
                        <tr>
                          <th>Refer ID</th>
                          <th>Transaction Type</th>
                          <th class="text-center">Amount</th>
                          <th class="text-center">Status</th>
                          <th>References</th>
                          <th>Remarks</th>
                          <th class="date-column">Created By</th>
                          <th class="date-column">Updated By</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="(transactions$ | async) as rows">
                          <tr *ngFor="let row of rows">
                              <td>{{row.refer_id}}</td>
                              <td>{{row.transaction_type}} {{ row.transaction_type == 'Withdrawal' || row.transaction_type == 'Reject-withdrawal' || row.transaction_type == 'Void-withdrawal' ? row.crypto_token_id ? ' (Crypto)' : ' (Banking)' : ''}}</td>
                              <td class="text-right">
                                <span [ngClass]="row.amount >= 0 ? 'text-success' : 'text-danger'">{{  row.amount | number : '1.2-2' }}</span>
                              </td>
                              <td class="text-center">
                                <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="row.status === 4 ? 'kt-badge--10' : 'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                              </td>
                              <td [innerHTML]="row.pg_reference_id">
                              </td>
                              <td style="max-width: 15vw;">{{row.remarks}}</td>
                              <td>
                                {{ row.created_by ? row.created_by : '-' }}<br>
                                {{ row.created_at | timezoneDate:'YYYY-MM-DD HH:mm' }}
                              </td>
                              <td>
                                {{ row.updated_by ? row.updated_by : '-' }}<br>
                                <!-- 2: void-deposit, 4: void-withdrawal, 7: reject-withdrawal -->
                                <!-- {{ (![2, 4, 7].includes(row.transaction_type_id) ? row.approved_at : row.rejected_at) | timezoneDate:'YYYY-MM-DD HH:mm' }} -->
                                {{ row.updated_at | timezoneDate:'YYYY-MM-DD HH:mm' }}
                              </td>
                          </tr>
                          <kt-fallback-row [collection]="rows"></kt-fallback-row>
                      </tbody>
                    </table>
                    <div class="kt-pagination kt-pagination--brand mt-2 col-12" *ngIf="transactionPagination !== undefined">
                      <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="transactionPagination.total"
                        (pageChange)="onGetTransactions(page)">
                      </ngb-pagination>
                      <div class="kt-pagination__toolbar">
                          <span class="pagination__desc">
                            Showing {{transactionPagination.from}} to {{transactionPagination.to}} of {{ transactionPagination.total | number : '1.0' }} records
                          </span>
                      </div>
                    </div>

                  </div>
                </section>
              </div>
           </section>
          </div>

        </div>
      </div>
      <div *ngIf="canEditWithdrawals" class="modal-footer" [ngClass]="withdrawStatus === 5 && data.withdraw.allow_reject !== 1? 'd-flex justify-content-end' : 'justify-content-between'">
        <button [type]="withdrawStatus === 3 ? 'button' : 'submit'" *ngIf="withdrawStatus === 3 || withdrawStatus === 0 || (withdrawStatus === 4 && (canProcessRiskyWithdrawals || isAdmin)) || (data.withdraw.allow_reject === 1 && withdrawStatus===5)" class="btn btn-danger" [disabled]="!form.valid" (click)="onTransactionAction(data.withdraw, 'reject')"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fa fa-ban'"></i>Reject</button>
        <button [type]="withdrawStatus === 3 ? 'button' : 'submit'" *ngIf="canVoidWithdrawals && withdrawStatus === 1 && ((userPermissions$ | async).void_deposit_withdrawal || isAdmin)" class="btn btn-danger" [disabled]="!form.valid" (click)="onTransactionAction(data.withdraw, 'reject')"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fa fa-ban'"></i>Void</button>
        <button [type]="withdrawStatus === 3 ? 'submit' : 'button'" *ngIf="withdrawStatus === 3" class="btn btn-success" [disabled]="!form.valid || processing_fee_valid || pgPayButton" (click)="onTransactionAction(data.withdraw, 'approve')"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Approve</button>
        <button [type]="withdrawStatus === 5 ? 'submit' : 'button'" *ngIf="withdrawStatus === 5" class="btn btn-success" [disabled]="!form.valid || processing_fee_valid || pgPayButton" (click)="onTransactionAction(data.withdraw, 'update incomplete')"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Update</button>
        <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
        <!-- <button *ngIf="withdrawStatus === 4 && ((withdrawalsUseNewPermissions ? canProcessRiskyWithdrawals : (userPermissions$ | async).process_risky_withdrawals) || isAdmin)" class="btn btn-pending" (click)="onTransactionAction(data.withdraw, 'pending')"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-hourglass-half'"></i>Pending</button> -->
        <button *ngIf="withdrawStatus === 4 && (canProcessRiskyWithdrawals || isAdmin)" class="btn btn-pending" (click)="onTransactionAction(data.withdraw, 'pending')"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-hourglass-half'"></i>Pending</button>

      </div>
    </div>
  </form>

  <form class="kt-form w-webkit-fill-available" [formGroup]="form" *ngIf="data.mode === 'create'">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Create Withdrawal ({{ data.withdrawalBankType | titlecase }})</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
          <div class="col-12 form-group row pr-0">
              <label class="col-4 col-form-label">Member <span class="text-danger">*</span></label>
              <kt-member-dropdown class="dropdown-maxheight col-8" style="padding:0" [form]="form" [dropdownSettings]='dropdownSettings'
              (selectedItemsChanged)="onSelectMember($event)"
              [formName]="'member_account_id'" [selectionAttributes]="'id'" [ngClass]="{'is-invalid': checkValidation && form.controls.member_account_id.errors }"></kt-member-dropdown>
          </div>
          <div class="col-12 form-group row pr-0">
              <label class="col-4 col-form-label">{{ data.withdrawalBankType == 'banking' ? 'Member Bank' : 'Member Crypto Wallet' }} <span class="text-danger">*</span> </label>
              <kt-dropdown-wo-lazyload
                class="col-8"
                style="padding:0"
                [form] = 'form'
                [dropdownList] = 'memberBankDropdownList'
                [dropdownSettings] = 'memberBankDropdownSettings'
                [formName] = "'bank_account_details'"
                [selectionAttributes] = "'value'"
                [selectedItems] = 'selectedMemberBank'
                [dataLoading]="memberBankLoading"
                [ngClass]="{'is-invalid': checkValidation && form.controls.bank_account_id.errors }">
              </kt-dropdown-wo-lazyload>
          </div>
          <ng-container *ngIf="data.withdrawalBankType == 'banking'">
            <div class="col-12 form-group row pr-0">
              <label class="col-4 col-form-label">Member Account Name</label>
              <fieldset class="col-8" [disabled]="data.mode === 'create' ? true : false">
                <input type="text" formControlName="merchant_account_name" class="form-control">
              </fieldset>
            </div>
            <div class="col-12 form-group row pr-0">
                <label class="col-4 col-form-label">Member Account Number</label>
                <fieldset class="col-8" [disabled]="data.mode === 'create' ? true : false">
                  <input type="text" formControlName="merchant_account_number" class="form-control">
                </fieldset>
            </div>
            <div class="col-12 form-group row pr-0">
                <label class="col-4 col-form-label">Amount <span class="text-danger">*</span> </label>
                <input type="number" (wheel)="false" formControlName="amount" class="col-8 form-control" [ngClass]="{'is-invalid': checkValidation && form.controls.amount.errors }">
            </div>
          </ng-container>
          <ng-container *ngIf="data.withdrawalBankType == 'crypto'">
            <div class="col-12 form-group row pr-0">
              <label class="col-4 col-form-label">Token</label>
              <fieldset class="col-8" [disabled]="data.mode === 'create' ? true : false">
                <input type="text" formControlName="token" class="form-control">
              </fieldset>
            </div>
            <div class="col-12 form-group row pr-0">
                <label class="col-4 col-form-label">Wallet Address</label>
                <fieldset class="col-8" [disabled]="data.mode === 'create' ? true : false">
                  <input type="text" formControlName="wallet_address" class="form-control">
                </fieldset>
            </div>
            <div class="col-12 form-group row pr-0">
                <label class="col-4 col-form-label">Nickname</label>
                <fieldset class="col-8" [disabled]="data.mode === 'create' ? true : false">
                  <input type="text" formControlName="wallet_nickname" class="form-control">
                </fieldset>
            </div>
            <div class="col-12 form-group row pr-0">
              <label class="col-4 col-form-label">Amount <span class="text-danger">*</span> </label>
              <div class="col-8 p-0">
                  <div class="row">
                      <div class="col-5">
                          <label class="col-form-label">{{ currencyCode || '-' }}</label>
                          <div class="input-group">
                              <input type="number" (wheel)="false" formControlName="amount" class="form-control" [ngClass]="{'is-invalid': checkValidation && form.controls.amount.errors }">
                          </div>
                      </div>
                      <div class="col-1 d-flex align-items-center justify-content-center pt-5">
                        <i class="fas fa-exchange-alt"></i>
                      </div>
                      <div class="col-5">
                          <label class="col-form-label">{{ selectedToken || '-'}}</label>
                          <div class="input-group">
                              <input type="number" (wheel)="false" formControlName="crypto_amount" class="form-control" [ngClass]="{'is-invalid': checkValidation && form.controls.crypto_amount.errors }">
                          </div>
                      </div>
                  </div>
                  <div class="d-flex pt-3" *ngIf="currencyCode && selectedToken">
                    <i class="fas fa-exclamation-circle align-self-center pr-2"></i>
                    Current Estimated Exchange Rate: {{ selectedToken }} 1 ≈ {{ currencyCode }} {{ selectedExchangeRate }}
                  </div>
              </div>
            </div>
          </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [buttonLoading]="buttonLoading" (confirmed)="onSave(data.withdraw)"></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
