import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { RecoveryCurrency } from '@core/models/recovery-currency.model';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { RecoveryCurrencyEntityService } from './services/recovery-currency-entity.service';
import { RecoveryCurrencyDataService } from './services/recovery-currency-data.service';
import { Pagination } from '@core/models/pagination.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Status } from '@core/enums/status.enum';
import { RecoveryCurrencyDetailsComponent } from './dialogs/recovery-currency-details.component';
import { RecoveryPromotionDataService } from '../services/recovery-promotion-data.service';
import { RecoveryPromotionEditDialogComponent } from '../dialogs/recovery-promotion-edit.component';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-recovery-currency',
  templateUrl: './recovery-currency.component.html',
  styleUrls: ['./recovery-currency.component.scss']
})
export class RecoveryCurrencyComponent implements OnInit, OnDestroy {

  @ViewChild(RecoveryPromotionEditDialogComponent) child;
  status = Status;
  recoveryCurrency$: Observable<RecoveryCurrency[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = `recovery_promotion_setting_id=${this.data.recoverySetting.id}`;
  catchPromotionId: any;
  perPageDropdown = this.dropdownHttpService.perPage;

  // permissions
  canAddRecoveryCurrency: boolean;
  canEditRecoveryCurrency: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { recoveryCurrency: RecoveryCurrency, recoverySetting: any },
    private loadingBar: LoadingBarService,
    private recoveryCurrencyEntityService: RecoveryCurrencyEntityService,
    private recoveryCurrencyDataService: RecoveryCurrencyDataService,
    private recoveryPromotionDataService: RecoveryPromotionDataService,
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RecoveryCurrencyComponent>,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.reload();
    this.pagination = this.recoveryCurrencyDataService.pagination;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canAddRecoveryCurrency = appPermissions.add_recovery_currency;
      this.canEditRecoveryCurrency = appPermissions.edit_recovery_currency;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.recoveryCurrency$ = this.recoveryCurrencyDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.recoveryCurrencyDataService.pagination;

        this.loadingBar.complete();
      })
    );
  }
  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private reload() {
    this.onViewPageBy(this.page).subscribe();
  }

  onOpenDialog(type: string, recoveryCurrencyId?: number) {
    if(recoveryCurrencyId) {
      const promotionCurrency = this.recoveryCurrencyEntityService.getByKey(recoveryCurrencyId);
      this.subscription = promotionCurrency.pipe(
        tap( (res) => {
        switch (type) {
          case 'edit':
            this.openDialogBy(RecoveryCurrencyDetailsComponent, { recoveryCurrency: res, mode: 'edit' });
            break;
        }
      })
      )
      .subscribe();
    } else {
      this.openDialogBy(RecoveryCurrencyDetailsComponent, { mode: 'create' });
    }
  }

  private openDialogBy(componentRef: any, data?: { recoveryCurrency?: any, mode?: any}) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        recoveryCurrency: data.recoveryCurrency,
        mode: data.mode,
        recoveryId: this.data.recoverySetting.id
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result === true){
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

}
