// Angular
import {
  ChangeDetectorRef,
  ElementRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
// Service
import { catchError, map, tap } from "rxjs/operators";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { DepositLog } from "@core/models/deposit-log.model";
import { DepositDataService } from "../../services/deposit-data.service";
import { TransactionStatus } from "@core/enums/transaction-status.enum";
import { WithdrawDataService } from "../../../withdrawals/services/withdraw-data.service";

@Component({
  selector: "app-deposit-show-remark-history",
  templateUrl: "./show-remark-history.component.html",
  styleUrls: ["./show-remark-history.component.scss"],
})
export class ShowRemarkHistoryComponent implements OnInit, OnDestroy {
  @ViewChildren("focusfield") focusfield: QueryList<ElementRef>;

  private subscription = new Subscription();

  messages$ = this.depositDataService.messages$;

  remarks: DepositLog[];
  loading = false;
  remarkLength = 0;
  status = TransactionStatus;

  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: any, remarkType: any },
    public dialogRef: MatDialogRef<ShowRemarkHistoryComponent>,
    public depositDataService: DepositDataService,
    public withdrawDataService: WithdrawDataService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    if (this.data.remarkType == 'withdraw') {
      this.fetchRemarksHistory(this.withdrawDataService);
    } else {
      this.fetchRemarksHistory(this.depositDataService);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  private fetchRemarksHistory(dataService: WithdrawDataService | DepositDataService) {
    dataService
      .getRemarksHistory(this.data.id)
      .pipe(
        tap((res) => {
          this.loading = false;
          this.remarks = res.data;
          this.remarkLength = res.data.length;
        }),
        catchError((error) => {
          this.loading = false;
          this.remarkLength = 0;
          // This detect changes is needed to update the table handler
          this.cdr.detectChanges();
          throw error;
        })
      )
      .subscribe();
  }
}
