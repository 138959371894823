<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Recovery Currency</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <div class="col-12">
        <div class="kt-section col-12 row d-flex flex-row-reverse mb-3 mt-1">
          <button *ngIf="canAddRecoveryCurrency" class="btn btn-brand btn-icon-sm float-right" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Add</button>
        </div>
        <div class="kt-section mb-0">
            <div class="kt-section__content">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Currency</th>
                                <th>Min</th>
                                <th>Max</th>
                                <th class="text-center">Status</th>
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="(recoveryCurrency$ | async) as rows">
                          <tr *ngFor="let row of rows">
                            <td class="align-middle">{{ row.currency_code }}</td>
                            <td class="text-right align-middle">{{ row.min | number : '1.2-2'}}</td>
                            <td class="text-right align-middle">{{ row.max !== 'Unlimited' ? (row.max | number : '1.2-2') : row.max}}</td>
                            <td class="text-center align-middle">
                              <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                            </td>
                            <td class="text-center">
                              <button *ngIf="canEditRecoveryCurrency" (click)="onOpenDialog('edit', row.id)" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"><i class="fas fa-edit"></i></button>
                            </td>
                        </tr>
                        <kt-fallback-row [collection]="rows"></kt-fallback-row>
                      </tbody>
                    </table>
                </div>
                <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                  <ngb-pagination
                    [pageSize]="pageSize"
                    [(page)]="page"
                    [maxSize]="maxSize"
                    [directionLinks]="true"
                    [boundaryLinks]="true"
                    [rotate]="true"
                    [collectionSize]="pagination.total"
                    (pageChange)="onViewPageBy(page)">
                  </ngb-pagination>
                  <div class="kt-pagination__toolbar">
                    <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                        <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
                    </select>
                    <span class="pagination__desc">
                      Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                    </span>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
  </div>
</div>
