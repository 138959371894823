<div id="kt_content" class="kt-content kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <div class="kt-form kt-form--label-right kt-margin-b-10">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <div class="col-12 row pr-0">
                <!-- Name -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Name:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="name" placeholder="Search" class="form-control" />
                </div>
                <!-- Status -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select class="form-control" formControlName="status">
                    <option [value]="null" [disabled]="true">Please Select</option>
                    <option value="all">All</option>
                    <ng-container *ngFor="let value of dropdown.statuses; let i = index">
                      <option [value]="i">{{ value }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <button *ngIf="canCreateApplicationRole" class="btn btn-brand btn-icon-sm" [disabled]="createBtnLoading" (click)="onOpenDialog()"><i [ngClass]="createBtnLoading ? 'spinner-border spinner-border-sm align-middle mr-2' : 'fas fa-plus'"></i>Create</button>
      </div>
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Remarks</th>
                  <th class="text-center">Status</th>
                  <th>Updated By</th>
                  <th class="date-column">Last modified date</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody *ngIf="roles.length > 0">
                <tr *ngFor="let row of roles">
                  <td>{{ row.id }}</td>
                  <td>{{ row.name }}</td>
                  <td>{{ row.remarks }}</td>
                  <td class="text-center">
                    <span class="kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ status[row.status] }}</span>
                  </td>
                  <td>{{ row.updated_by || "-" }}</td>
                  <td>{{ row.updated_at | timezoneDate : "YYYY-MM-DD HH:mm" }}</td>
                  <td class="kt-datatable__cell" class="text-center">
                    <button *ngIf="canEditApplicationRole" matTooltip="Edit Role" [disabled]="editBtnLoading[row.id]" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog(row.id)"><i [class]="editBtnLoading[row.id] ? 'fas fa-circle-notch fa-spin' : 'fas fa-edit'"></i></button>
                  </td>
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)"> </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc"> Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total | number : "1.0" }} records </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
