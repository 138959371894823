import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { SubheaderService } from '@core/_base/layout';
import { Breadcrumb } from '@core/_base/layout/services/subheader.service';
import { CreditLimitHttpService } from '@core/services/credit-limit-http.service';
import Echo from 'laravel-echo';

@Component({
  selector: 'kt-subheader1',
  templateUrl: './subheader1.component.html',
  styleUrls: ['./subheader1.component.scss']
})
export class Subheader1Component implements OnInit, OnDestroy, AfterViewInit {
  @Input() fluid: boolean;
  @Input() clear: boolean;

  today: number = Date.now();
  title = '';
  desc = '';
  breadcrumbs: Breadcrumb[] = [];
  creditLimit: string;

  private subscriptions: Subscription[] = [];

  echo: Echo;

  constructor(
    public subheaderService: SubheaderService,
    private creditLimitService: CreditLimitHttpService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  ngAfterViewInit() {
    const title = this.subheaderService.title$.subscribe(bt => {
      if (bt) {
        Promise.resolve(null).then(() => {
          if (window.location.pathname === '/download-area') {
            this.title = 'Download Area';
          } else if (window.location.pathname === '/import-history') {
            this.title = 'Import History Log';
          } else if (window.location.pathname === '/settings/website-display') {
            this.title = '14.7.1 Website Display';
          } else if (window.location.pathname === '/settings/mobile-display') {
            this.title = '14.7.2 Mobile Display';
          } else if (window.location.pathname === '/settings/section-settings') {
            this.title = '14.7.3 Section Settings';
          } else if (window.location.pathname === '/settings/terms-and-conditions') {
            this.title = '14.4.1 T&C';
          } else if (window.location.pathname === '/settings/faq-management') {
            this.title = '14.4.2 FAQ';
          } else if (window.location.pathname === '/settings/about-us') {
            this.title = '14.4.3 About Us';
          } else if (window.location.pathname === '/settings/privacy-policy') {
            this.title = '14.4.4 Privacy Policy';
          } else if (window.location.pathname === '/settings/responsible-gaming') {
            this.title = '14.4.5 Responsible Gaming';
          } else if (window.location.pathname === '/settings/dialog') {
            this.title = '14.1.2 Dialog';
          } else if (window.location.pathname === '/settings/live-chat-script') {
            this.title = '14.3.1 Livechat Script';
          } else if (window.location.pathname === '/settings/contacts') {
            this.title = '14.3.2 Contacts';
          } else if (window.location.pathname === '/general/member-verification'){
            this.title = '1.3.1 Verification List'; 
          } else {
            this.title = bt.title;
          }
          this.desc = bt.desc;
        });
      }
    });
    const breadcrumbs = this.subheaderService.breadcrumbs$.subscribe(bc => {
      Promise.resolve(null).then(() => {
        this.breadcrumbs = bc;
      });
    });

    this.subscriptions.push(
      title,
      breadcrumbs
    );
  }

  private getCreditLimitBalance() {
    // Call getbalance API to get credit limit balance for the first time
    this.creditLimitService.getBalance().subscribe((res) => {
      this.creditLimit = res.balance;
    });

    // Connect to store credit limit event via websocket to update credit limit balance everytime credit limit balance is updated
    this.echo.channel('credit.limit').listen('.StoreCreditLimitEvent', (data) => {
      this.creditLimit = data.balance;
      this.cdr.detectChanges();
    });
  }
}
