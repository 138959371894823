// Services
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { VipMemberLogsDataService } from './services/vip-member-logs-data.service';
import { MemberInformationDialogComponent } from '@views/pages/apps/general/members/dialogs/member-information/member-information.component';
import { MemberDataService } from '@views/pages/apps/general/members/services/member-data.service';
import { TransactionHttpService } from "@core/services/transaction-http.service";
import { EventEmitterService } from '@core/services/event-emitter.service';
import { AppPermissionService } from '@core/services/app-permission.service';
// Angular
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { VipMemberVerification } from "@core/models/vip-member-verification.model";
// RXJS & Other
import { tap, catchError, exhaustMap, map } from 'rxjs/operators';
import { Observable, Subscription, of } from 'rxjs';
import * as moment from 'moment-timezone';
import { AuthHttpService } from '@core/services/auth-http.service';
import Swal from 'sweetalert2';

@Component({
  templateUrl: './vip-member-logs.component.html',
  styleUrls: ['./vip-member-logs.component.scss']
})
export class VipMemberLogsComponent implements OnInit {

  members$: Observable<VipMemberVerification[]>;
  messages$ = this.vipMemberLogsDataService.messages$;

  // General params
  form: FormGroup;
  page = 1;
  pageSize = 30;
  params = '';
  loading = false;
  loadmore = true;
  pagedata = [];
  clearBtnLoading = false;
  searchBtnLoading = false;
  exportBtnLoading = false;
  access$ = this.authHttpService.getUserAccess(8, 'VIP Member Logs');

  // Dropdowns
  dropdown = {
    groups: this.dropdownHttpService.groups,
    memberGroupTypes: this.dropdownHttpService.memberGroupTypes,
  };

  // Sorting related
  sortingStorageName = 'sortingConfig';
  sortingStorageGroup = '8.3';
  sortingConfig = {
    'id': 'desc',
    'username': 'desc',
    'member_group': 'desc',
    'member_group_type': 'desc',
    'created_at': 'desc',
  };
  sortingSelection = {
    'sort_by': 'id',
    'sort_order': 'desc',
  };

  // Date time picker related
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  ranges: any;
  from = this.transactionHttpService.getLastHours().from;
  to = this.transactionHttpService.getLastHours().to;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  // permissions
  // vipMemberLogsUseNewPermissions: boolean;
  canViewMemberDialog: boolean;
  canExportVipMemberLogs: boolean;

  constructor(
      public dialog: MatDialog,
      private cdr: ChangeDetectorRef,
      private dropdownHttpService: DropdownHttpService,
      private vipMemberLogsDataService: VipMemberLogsDataService,
      private authHttpService: AuthHttpService,
      private memberDataService: MemberDataService,
      private transactionHttpService: TransactionHttpService,
      private eventEmitterService: EventEmitterService,
      private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.ranges = this.transactionHttpService.ranges;

    // Get sorting from local storage
    localStorage.setItem('sortingConfig', JSON.stringify({ [this.sortingStorageGroup]: this.sortingConfig }));

    // Init form control
    this.formInit();

    // Auto search if redirected from notification module
    this.checkAutoLoad();

    // Handle notification redirect clicking on same page with event emitter
    this.subscription = this.eventEmitterService.notificationRedirect.subscribe((res) => {
      this.autoLoad(res.autoLoadData);
    });

    // this.vipMemberLogsUseNewPermissions = this.appPermissionService.isSectionUsingNewPermissions('vip_member_logs');
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewMemberDialog = appPermissions.vip_member_logs_view_member_dialog;
      this.canExportVipMemberLogs = appPermissions.export_vip_member_logs;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    const documentHeight = Math.max(
      document.body.scrollHeight || 0,
      document.body.offsetHeight || 0,
      document.documentElement.scrollHeight || 0,
      document.documentElement.offsetHeight || 0
    );
    const scrollbottom = (window.innerHeight + window.scrollY) * 1.1 >= documentHeight;

    if (this.loadmore && !this.loading && scrollbottom) {
      this.onSubmit(true);
    }
  }

  onClear() {
    this.form.patchValue({ 
      id: null,
      username: null,
      member_group_id: 'all',
      member_group_type: '1',
      start_date: this.form,
      end_date: this.to,
      defaultDate: {
        startDate: this.from,
        endDate: this.to
      }
    });
    
    this.onSubmit(false, true);
  }

  onSubmit(scrollload?: boolean, clearSearch?: boolean) {

    if( clearSearch ) {
      this.clearBtnLoading = true;
    } else {
      this.searchBtnLoading = true;
    }
    this.loading = true;
    this.page++;

    if (!scrollload) {
      this.page = 1;
      this.params = this.getSearchParams();
      this.loadmore = true;
      this.pagedata = [];
    }

    let queryparam = `page=${this.page}&perPage=${this.pageSize}&${this.params}`;
    this.vipMemberLogsDataService.getWithQuery(queryparam)
    .pipe(
      catchError((error) => {
        this.loading = false;
        this.loadmore = false;
        this.searchBtnLoading = false;
        this.clearBtnLoading = false;

        // Throw the error as usual
        throw error;
      })
    )
    .subscribe((res: any) => {
      this.loading = false;
      this.searchBtnLoading = false;
      this.clearBtnLoading = false;
      if (res) {
        this.pagedata = [...this.pagedata, ...res];
        this.cdr.detectChanges();

        if (res.length >= this.pageSize) {
          return;
        }
      }

      this.loadmore = false;
    });
  }

  private getSearchParams(isExport?: boolean): string {
    const formValues = {
      ...this.form.value,
      ...this.sortingSelection
    };

    if (formValues['start_date']) {
      formValues['start_date'] = moment(formValues['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
      formValues['end_date'] = moment(formValues['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
    }

    if( isExport ) {
      formValues['paginate'] = false;
      formValues['timezone'] = this.timezone;
    }

    const filteredFormValues = this.filterFormFields(formValues);
    const searchParams = new URLSearchParams(filteredFormValues);

    return searchParams.toString();
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== undefined && key !== 'defaultDate' && formData[key] !== 'all') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  onExport() {
    this.exportBtnLoading = true;

    this.params = this.getSearchParams(true);
    let queryparam = `page=${this.page}&perPage=${this.pageSize}&${this.params}`;
    this.vipMemberLogsDataService.export(queryparam)
    .pipe(
      catchError((error) => {
        this.exportBtnLoading = false;
        // Throw the error as usual
        throw error;
      })
    )
    .subscribe((res: any) => {
      this.exportBtnLoading = false;
    });
  }

  onOpenDialog(type: string, row?: any ) {
    if (type === 'member-information') {
      if (!this.canViewMemberDialog) {
        Swal.fire('Permission Error', 'You do not have permission to view member information', 'error');
        return;
      }

      const member = this.memberDataService.getById(row, `?start_date=${moment(this.transactionHttpService.getYesterday().from).utc().format('YYYY-MM-DD HH:mm:ss')}&end_date=${moment(this.transactionHttpService.getLast24Hours().to).utc().format('YYYY-MM-DD HH:mm:ss')}&additional_info=1`);
      this.subscription = member.pipe(
        tap((res) => {
          if (res) {
            this.openDialogBy(MemberInformationDialogComponent, { member: res });
          }
        })
      )
        .subscribe();
    }
  }

  private openDialogBy(componentRef: any, data?: { member?: any}) {
    this.dialog.open(componentRef, {
      width: '1500px',
      height:'80vh',
      data: {
        member: data.member,
      },
    });
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if(key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }

    // User selection
    this.sortingSelection.sort_by = property;

    if(this.sortingSelection.sort_by === property) {
      // Same column
      this.sortingSelection.sort_order = this.sortingConfig[property];
    } else {
      // Switch to other column
      this.sortingConfig[property] = 'asc';
      this.sortingSelection.sort_order = 'asc';
    }

    // Reload data
    this.onSubmit(false);
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onClearDate() {
    if (this.form.value.start_date !== null) {
      this.form.patchValue({ defaultDate: null });
    }
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  private checkAutoLoad() {
    let searchUsername = localStorage.getItem('vip_trial_search');
    if( searchUsername ) {
      this.autoLoad(searchUsername);
      localStorage.removeItem('vip_trial_search');
    }
  }

  private autoLoad(username: string) {
    if( !this.loading ) {
      this.form.patchValue({
        username: username,
        member_group_type: 'all',
        start_date: null,
        end_date: null,
        defaultDate: null,
      });
      this.onSubmit();
    }
  }

  private formInit() {
    this.form = new FormGroup({
      id: new FormControl(null),
      username: new FormControl(null),
      member_group_id: new FormControl('all'),
      member_group_type: new FormControl('1'),
      start_date: new FormControl(this.from),
      end_date: new FormControl(this.to),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to
      })
    });
  }
}
