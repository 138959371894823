import { EditorService } from './core/services/editor.service';
import { AllAffiliateMemberReportHttpService } from './core/services/all-affiliate-member-report-http.service';
import { BehaviorSubject } from 'rxjs';
import { PredictionStatusHttpService } from './core/services/prediction-status-http.service';
import { MemberTrace } from './core/models/member-trace.model';
import { EventEmitterService } from './core/services/event-emitter.service';
import { DashboardHttpService } from './core/services/dashboard-http.service';
import { SendSMSHttpService } from './core/services/send-sms-http.service';
import { WalletEventHttpService } from './core/services/wallet-event-http.service';
import { SmsHttpService } from './core/services/sms-http.service';
import { CampaignHttpService } from './core/services/campaign-http.service';

import { MemberBankAccountHttpService } from '@core/services/member-bank-account-http.service';
import { NotificationHttpService } from './core/services/notification-http.service';
import { PromotionSettingHttpService } from './core/services/promotion-setting-http.service';
// Angular
import { BankHttpService } from './core/services/bank-http.service';
import { GameProviderHttpService } from './core/services/game-provider-http.service';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import 'hammerjs';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, DefaultRouterStateSerializer } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, reducers } from './store/reducers';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ThemeModule } from './views/theme/theme.module';
import { PartialsModule } from './views/partials/partials.module';
import { TwoFAGuard } from '@core/guards/twofa.guard';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';

import {
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
  SubheaderService,
  DownloadHttpService
} from './core/_base/layout';
import { AuthModule } from './views/pages/auth/auth.module';
import { AuthHttpService } from './core/services/auth-http.service';
import { LayoutConfig } from './core/_config/layout.config';
// Highlight JS
// import { HIGHLIGHT_OPTIONS, HighlightLanguage } from 'ngx-highlightjs';
// import * as typescript from 'highlight.js/lib/languages/typescript';
// import * as scss from 'highlight.js/lib/languages/scss';
// import * as xml from 'highlight.js/lib/languages/xml';
// import * as json from 'highlight.js/lib/languages/json';
import { ApiInterceptor } from '@core/interceptors/api.interceptor';
import { EntityDataModule, EntityMetadataMap } from '@ngrx/data';
import { ApiErrorInterceptor } from './core/interceptors/api-error.interceptor';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { UploadHttpService } from '@core/services/upload-http.service';
import { GroupHttpService } from './core/services/group-http.service';
import { DatePipe } from '@angular/common';
import { LocaleHttpService } from '@core/services/locale-http.service';
import { BannerHttpService } from '@core/services/banner-http.service';
import { RoleHttpService } from '@core/services/role-http.service';
import { FunctionSectionHttpService } from '@core/services/function-section-http.service';
import { PurposeHttpService } from '@core/services/purpose-http.service';
import { ContactHttpService } from '@core/services/contact-http.service';
import { VersionHttpService } from '@core/services/version-http.service';
import { MiniGame } from '@core/models/mini-games.model';
import { GameProviderReport } from '@core/models/game-provider-report.model';
import { CampaignAdCostReport } from '@core/models/campaign-ad-cost-report.model';
import { PromotionStatistic } from '@core/models/promotion-statistic.model';
import { SMSRecoveryReport } from '@core/models/sms-recovery-report.model';
import { RegistrationReport } from '@core/models/registration-report.model';
import { RewardsHttpService } from '@core/services/rewards-http.service';
import { TreeViewModule, AccordionModule, ToolbarModule, ContextMenuModule, TabModule, SidebarModule, MenuModule } from '@syncfusion/ej2-angular-navigations';
import { TelemarketerReport } from '@core/models/telemarketer-report.model';
import { AllTickets } from '@core/models/all-tickets.model';
import { AllPredictions } from '@core/models/all-predictions.model';
import { LightboxModule } from 'ngx-lightbox';
import { LanguageHttpService } from '@core/services/language-http.service';
import { MerchantSitesHttpService } from '@core/services/merchant-sites-http.service';
import { LocationHttpService } from '@core/services/location-http.service';
import { SampleMessageHttpService } from './core/services/sample-message-http.service';
import { BonusHunterAnalysisHttpService } from '@core/services/bonus-hunter-analysis-http.service';
import { PanicModeHttpService } from '@core/services/panic-mode-http.service';
import { FooterSectionHttpService } from '@core/services/footer-section-http.service';
import { LinkWithHttpService } from '@core/services/linkwith-http.service';
import { AvailableLocaleHttpService } from '@core/services/available-locale-http.service';
import { TelemarketingPlayerSummaryReport } from '@core/models/telemarketing-player-summary-report.model';
import { CryptoTokenHttpService } from '@core/services/crypto-token-http.service';


// Do not remove the import below, some version of node js need import below only can success build
import "@angular/compiler";
import { SeoHttpService } from '@core/services/seo-http.service';
import { TimezoneHttpService } from '@core/services/timezone-http.service';
import { MemberCryptoWalletHttpService } from '@core/services/member-crypto-wallet-http.service';
import { ApplicationHttpService } from '@core/services/application-http.service';
import { AppPermissionService } from '@core/services/app-permission.service';
import { TwoFAFirstSetUpGuard } from '@core/guards/twofafirstsetup.guard';

export const REFRESH_RATE_INTERVAL_IN_SECONDS = 30;

export var
  // Global variable to count number of request is pending
  requestCount: number = 0,
  // Global variable to store client ip address
  ip_address = null;

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}

export function getIpAddress(locationHttpService: LocationHttpService) {
  return (): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
      locationHttpService.getIpAddress().subscribe(res => {
        ip_address = res.ip;
        resolve();
      });
    });
  }
}

// export const EnvServiceFactory = () => {
//   // Create env
//   const env = new EnvService();
//   // Read environment variables from browser window
//   const browserWindow = window || {};
//   const browserWindowEnv = browserWindow['__env'] || {};
//   // Assign environment variables from browser window to env
//   for (const key in browserWindowEnv) {
//     if (browserWindowEnv.hasOwnProperty(key)) {
//       env[key] = window['__env'][key];
//     }
//   }
//   return env;
// };

// export function hljsLanguages(): HighlightLanguage[] {
//   return [
//   {name: 'typescript', func: typescript},
//   {name: 'scss', func: scss},
//   {name: 'xml', func: xml},
//   {name: 'json', func: json}
//   ];
// }

export const entityMetadata: EntityMetadataMap = {
  // Superuser
  OperatorLog: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Operator: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Role: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  MemberLog: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  MessageTemplate: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },

  // General
  MemberGroup: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Member: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  MemberTrace: { selectId: (memberTrace: MemberTrace) => memberTrace.username },
  Agent: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AgentTreeView: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AgentReport: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AllAgentReport: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Transaction: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Withdraw: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Deposit: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Transfer: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  PromotionContent: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  MemberPromotion: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Promotion: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  PromotionCurrency: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  PromotionStatistic: { selectId: (promotionStatistic: PromotionStatistic) => promotionStatistic.code, entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  MemberBankAccountVerification: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  MemberVerification: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Message: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  RecoveryPromotion: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  RecoveryPromotionCurrency: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  RecoveryPromotionMessages: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  PromotionCategory: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  PromotionCategoryM7: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  PromotionMessages: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Reward: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  LuckyDrawSettings: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  ReferralSetting: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Referral: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  PromotionApplication: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AllLeads: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } }, //selectId: (allLeads: AllLeads) => allLeads.username,
  MyLeads: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } }, //selectId: (allLeads: AllLeads) => allLeads.username,
  AffiliateWithdrawals: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  CommissionReleases: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AffiliateDeposits: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AffiliateApproval: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AffiliateTransfers: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AffiliateAdjustment: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AffiliateGroups: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AffiliateGroupDialogPopups: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  SampleMessage: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AccountManagement: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AccountManagementLog: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },

  // Settings
  MerchantBank: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Banner: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Contact: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Announcement: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  BankReceipt: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  BankTransaction: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  BotStatus: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  TransactionReconciliation: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Faq: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  TermsAndConditions: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  BankSetting: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  WhatsappNumber: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  WhatsappBot: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Marquees: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },

  // Game Providers
  GameProviders: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  MiniGame: {
    selectId: (miniGame: MiniGame) => miniGame.game_provider_code
  },
  MaintenanceHours: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  GameProviderPosition: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  GameTags: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },

  // Rebates
  RebateRelease: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  RebateHistory: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  Rebate: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  // New Rebates
  NewRebateRelease: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },

  // Reports
  GameProviderDailyReport: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  GameProviderReport: {
    selectId: (gameProviderReport: GameProviderReport) => gameProviderReport.game_provider
  },
  MemberReport: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  TransactionReport: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AllMemberReport: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  MarketingReport: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  GameTypeReport: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  DailyNewDepositReport: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  SMSRecoveryReport: {
    selectId: (smsRecoveryReport: SMSRecoveryReport) => smsRecoveryReport.campaign_name, entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false }
  },
  SMSRecoveryReportDetail: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  CompanyReport: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  RegistrationReport: {
    selectId: (registrationReport: RegistrationReport) => registrationReport.member_account_id, entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false }
  },
  WeeklyMonthlyReport: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  TelemarketerReport: {
    selectId: (telemarketerReport: TelemarketerReport) => telemarketerReport.telemarketer, entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false }
  },
  AllAffiliateReport: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AllSubAffiliateReport: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AffiliateFirstDepositorReport: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },

  // Marketeer
  CampaignAdCost: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  CampaignAdCostReport: {
    selectId: (campaignAdCostReport: CampaignAdCostReport) => campaignAdCostReport.campaign_id, entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false }
  },
  Campaign: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },

  // SMS
  SmsLog: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  SmsProvider: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AllSmsMessages: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  WebPushMessages: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },

  // 15
  AllTickets: { selectId: (allTickets: AllTickets) => allTickets.username, entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AllPredictions: { selectId: (allPredictions: AllPredictions) => allPredictions.event_ticket_id, entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },
  AllEvents: { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } },

  // Telemarketing Player Summary Report
  TelemarketingPlayerSummaryReport:  { entityDispatcherOptions: { optimisticSaveEntities: false, optimisticAdd: false } }
};

const pluralNames = {};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PartialsModule,
    CoreModule,
    OverlayModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot(pluralNames),
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer, stateKey: 'router' }),
    StoreDevtoolsModule.instrument(),
    AuthModule.forRoot(),
    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    ThemeModule,
    TreeViewModule,
    AccordionModule, ToolbarModule, ContextMenuModule, TabModule, SidebarModule, MenuModule,
    LightboxModule,
    MatTabsModule,
    MatIconModule,
    MatBadgeModule
  ],
  exports: [],
  providers: [
    // { provide: EnvService, useFactory: EnvServiceFactory, deps: [] },
    AuthHttpService,
    DropdownHttpService,
    EditorService,
    PurposeHttpService,
    ContactHttpService,
    UploadHttpService,
    BannerHttpService,
    GroupHttpService,
    LanguageHttpService,
    LocaleHttpService,
    PromotionSettingHttpService,
    GameProviderHttpService,
    BankHttpService,
    AllAffiliateMemberReportHttpService,
    RoleHttpService,
    NotificationHttpService,
    FunctionSectionHttpService,
    DatePipe,
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    SplashScreenService,
    MemberBankAccountHttpService,
    CampaignHttpService,
    DashboardHttpService,
    EventEmitterService,
    { provide: APP_INITIALIZER, useFactory: initializeLayoutConfig, deps: [LayoutConfigService], multi: true },
    { provide: APP_INITIALIZER, useFactory: getIpAddress, deps: [LocationHttpService], multi: true },
    // { provide: HIGHLIGHT_OPTIONS, useValue: {languages: hljsLanguages} },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    SmsHttpService,
    WalletEventHttpService,
    SendSMSHttpService,
    DownloadHttpService,
    RewardsHttpService,
    VersionHttpService,
    PredictionStatusHttpService,
    MerchantSitesHttpService,
    SampleMessageHttpService,
    BonusHunterAnalysisHttpService,
    PanicModeHttpService,
    FooterSectionHttpService,
    LinkWithHttpService,
    TimezoneHttpService,
    AvailableLocaleHttpService,
    SeoHttpService,
    CryptoTokenHttpService,
    MemberCryptoWalletHttpService,
    ApplicationHttpService,
    AppPermissionService,
    TwoFAGuard,
    TwoFAFirstSetUpGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
