import { Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { UploadHttpService } from '@core/services/upload-http.service';
import { MemberBankAccountHttpService } from '@core/services/member-bank-account-http.service';
import { ShowDocumentComponent } from '../../../../member-bank-account-verification/dialogs/show-document/show-document.component';

@Component({
  templateUrl: './reupload-document.component.html',
  styleUrls: ['./reupload-document.component.scss']
})
export class ReuploadDocumentDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;

  form: FormGroup;
  private subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  messages$ = this.memberBankAccountHttpService.messages$;

  fileName: Array<String> = [];
  isFileUploading: Array<boolean> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bankAccount: any },
    public dialogRef: MatDialogRef<ReuploadDocumentDialogComponent>,
    private memberBankAccountHttpService: MemberBankAccountHttpService,
    private uploadService: UploadHttpService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });

    const formData = {
      ...this.form.value
    };
    Object.keys(formData).forEach((key) => (formData[key] == null || formData[key] === '' || formData[key] < 1) && delete formData[key]);
    this.subscription = this.memberBankAccountHttpService.reuploadDocument(formData, this.data.bankAccount.id).pipe(
      tap((res) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        this.dialogRef.close(true);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  get document() {
    return this.form.controls["document"] as FormArray;
  }

  addDocument() {
    const document = new FormControl('', [Validators.required]);
    this.document.push(document)
  }

  removeDocument(index: number) {
    this.document.removeAt(index);
    this.fileName.splice(index, 1)
  }

  onUploadFile(event: any, index) {
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('files', file, file.name);
    formData.append('type', 'uploads');

    this.isFileUploading[index] = true;
    this.uploadService.upload(formData).pipe(
      tap(res => {
        (this.form.get("document") as FormArray).controls[index].patchValue(res[0])
        this.fileName[index] = event.target.files[0].name;
        this.isFileUploading[index] = false;
      }),
      catchError((error) => {
        this.isFileUploading[index] = false;
        throw error;
      })
    ).subscribe();
  }

  onOpenDialog(mode: string, data?: any) {
    if (mode == 'show-document') {
      if (data.value != '') {
        const documentData = {
          value: data.value
        };
        const documentArray = [documentData];
  
        this.openDialogBy(ShowDocumentComponent, { mode: mode, documents: documentArray });
      }
    }
  }

  private openDialogBy(componentRef: any, data?: { mode?: any, documents?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '800px',
      data: {
        documents: data.documents,
        mode: data.mode
      },
      autoFocus: false
    });
  }

  private formInit() {
    this.form = new FormGroup({
      document: new FormArray([new FormControl('', [Validators.required])], [Validators.required])
    });
  }

}
