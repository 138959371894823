import { ProviderEditDialogComponent } from './providers/dialogs/provider-edit-dialog.component';
import { SendComponent } from './send/send.component';
import { LogsComponent } from './logs/logs.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProvidersComponent } from './providers/providers.component';
import { AllSmsMessagesComponent } from './all-sms-messages/all-sms-messages.component';
import { AllSmsMessagesDialogComponent } from './all-sms-messages/dialogs/all-sms-messages-dialog.component';
import { WebPushMessagesComponent } from './web-push-messages/web-push-messages.component';
import { ProviderCredentialsEditDialogComponent } from './providers/dialogs/provider-credentials-edit/provider-credentials-edit.component';
import { ProviderPermissionsEditDialogComponent } from './providers/dialogs/provider-permissions-edit/provider-permissions-edit.component';


const routes: Routes = [
  {
    path: 'all-messages',
    component: AllSmsMessagesComponent
  },
  {
    path: 'logs',
    component: LogsComponent
  },
  {
    path: 'send',
    component: SendComponent
  },
  {
    path: 'web-push',
    component: WebPushMessagesComponent
  },
  {
    path: 'providers',
    component: ProvidersComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  entryComponents: [
    ProviderEditDialogComponent,
    AllSmsMessagesDialogComponent,
    ProviderCredentialsEditDialogComponent,
    ProviderPermissionsEditDialogComponent,
  ]
})
export class SmsRoutingModule { }
