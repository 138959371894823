import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import moment from 'moment';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { SalesConversionReportDataService } from './services/sales-conversion-report-data.service';
import { SalesConversionReport } from '@core/models/sales-conversion-report.model';
import { TranslateService } from '@ngx-translate/core';
import { SalesConversionReportDetailsDialogComponent } from './sales-conversion-report-details/sales-conversion-report-details.component';
@Component({
  selector: 'sales-conversion-report',
  templateUrl: './sales-conversion-report.component.html',
  styleUrls: ['./sales-conversion-report.component.scss'],
})
export class SalesConversionReportComponent implements OnInit, OnDestroy {
  @ViewChildren('filterInput') filterInput: QueryList<ElementRef>;
  form: FormGroup;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  pagination: Pagination;
  from = null;
  to = null;

  dropdown = {
    telemarketer: this.dropdownHttpService.telemarketer,
    currencies: this.dropdownHttpService.currencies,
    timezones: JSON.parse(localStorage.getItem('timezones')),
    perPage: this.dropdownHttpService.perPage,
  };
  buttonLoading = false;
  messages$ = this.salesConversionReportDataService.messages$;

  salesConversionReport$: Observable<SalesConversionReport[]>;
  params = ``;
  onSearch = false;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  timezoneSelectedItems: any;
  selectedTimezone: any;
  timezoneDropdownSettings = {
    singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown clear-all-disabled',
      primaryKey: 'offset',
      labelKey: 'offset',
      noDataLabel: '',
      showCheckbox: false
  };

  private subscription = new Subscription();
  private refreshSubcription = new Subscription();
  datePickerSubscription: Subscription;

  default_sort_by = 'country_code';
  default_sort_order = 'asc';

  sortingConfig = {
    'headcount': 'desc',
    'total_leads_assgined': 'desc',
    'fresh_leads': 'desc',
    'old_leads': 'desc',
    'non_member_leads': 'desc',
    'total_calls': 'desc',
    'avg_calls': 'desc',
    'max_calls': 'desc',
    'min_calls': 'desc',
    'total_deposits': 'desc',
    'fresh_leads_deposits': 'desc',
    'old_leads_deposits': 'desc',
    'non_member_leads_deposits': 'desc',
    'commissionable_depositors': 'desc',
    'comm_conversion_rate': 'desc',
    'deposit_value': 'desc'
  };

  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order,
  };

  access$ = this.authHttpService.getUserAccess(13, 'Sales Conversion Report');
  isAdmin: boolean = JSON.parse(localStorage.getItem('user_data')).is_admin;

  telemarketerDropdownSettings = {};
  telemarketerDropdownList = [];
  telemarketerSelectedItems = [];

  constructor(
    private salesConversionReportDataService: SalesConversionReportDataService,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog,
    private authHttpService: AuthHttpService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.formInit();
    this.selectedTimezone = this.timezone;
    this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.timezone);

    this.dropdownHttpService.telemarketer.subscribe(res => {
      this.telemarketerDropdownList = res;;
    });

    this.telemarketerDropdownSettings = {
      singleSelection: false,
      text: this.translateService.instant('Please Select'),
      maxHeight: 200,
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.refreshSubcription.unsubscribe();
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }

      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onClear() {
    this.clearBtnLoading = true;
    //this.eventEmitterService.onClearMemberSearch(); // Cannot clear selected Telemarketer without this
    this.telemarketerSelectedItems = [];
    this.form.patchValue({
      telemarketer_id: null,
      currency_id: null,
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.searchBtnLoading = clearSearch ? false : true;
    of(this.form.value)
      .pipe(
        map(this.filterFormFields),
        tap((data) => {
          this.params = Object.keys(data)
            .map((key) => key + "=" + data[key])
            .join("&");
          this.sortingConfig[this.sortingSelection.sort_by] = 'desc';
          this.sortingSelection.sort_by = this.default_sort_by;
          this.sortingSelection.sort_order = this.default_sort_order;
          this.sortingConfig[this.default_sort_by] = this.default_sort_order;
          this.onViewPageBy();
        })
      )
      .subscribe();
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : "";
    this.loadingBar.start();
    return (this.salesConversionReport$ = this.salesConversionReportDataService
      .getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`)
      .pipe(
        tap((res) => {
          this.dataLength = res.length;
          this.pagination = this.salesConversionReportDataService.pagination;
          this.loadingBar.complete();
          this.onSearch = true;
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
        }),
        catchError((err) => {
          this.loading = false;
          this.clearBtnLoading = false;
          this.searchBtnLoading = false;
          this.loadingBar.complete();
          return throwError(err);
        })
      ));
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onExport() {
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        var pageSize = this.pageSize;
        var page = this.page;
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : "";
        this.loadingBar.start();
        return this.salesConversionReportDataService.exportReport(`?page=${page}&perPage=${pageSize}${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.loadingBar.complete();
            this.loading = false;
          }),
          catchError((err) => {
            this.loadingBar.complete();
            this.loading = false;
            return throwError(err);
          })
        );
      }),
    ).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      telemarketer_id: new FormControl(null),
      currency_id: new FormControl(null),
      year:new FormControl(new Date().getFullYear(),[Validators.required]),
      month:new FormControl(new Date().getMonth(),[Validators.required,Validators.min(1),Validators.max(12)]),
      timezone: new FormControl(this.timezone),
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach((key) => {
      if (
        formData[key] !== '' &&
        formData[key] !== null &&
        formData[key] !== undefined &&
        key !== 'defaultDate' &&
        formData[key] !== 'all'
      ) {
        if (key === 'start_date_time' || key === 'end_date_time') {
          fields[key] = moment(formData[key])
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');
        } else {
          fields[key] = formData[key];
        }
      }
    });
    return fields;
  }
  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }

  onOpenDialog(data: any) {
    this.openDialogBy(SalesConversionReportDetailsDialogComponent, { mode: 'sales-conversion-details', details: data });
  }

  private openDialogBy(componentRef: any, data?: {mode?: any, details?: any },) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '1500px',
      autoFocus: false,
      data: {
        telemarketer_id: this.form.value.telemarketer_id,
        currency_id: data.details.settings_currency_id,
        site_id: data.details.site_id,
        month: this.form.value.month,
        year: this.form.value.year, 
        timezone: this.selectedTimezone
      }
    });
    
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.onViewPageBy(this.page);
      }
    });
  }

  private showCommConversionRate(rate:number){
    if (rate == 0){
      return "N/A";
    }else{
      return rate + "%"
    }
  }

  onChangeTimezone(item: any) {
    if (item.length) {
      this.selectedTimezone = item[0]['timezone'];
    }
    else {
      this.timezoneSelectedItems = this.dropdown.timezones.filter(x => x.timezone === this.selectedTimezone);
      this.form.patchValue({
        timezone: this.selectedTimezone
      });
    }
  }
}
