import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { TelemarketerSettingsDataService } from '../../services/telemarketer-settings-data.service';
import { catchError, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './telemarketer-settings-edit.component.html',
    styleUrls: ['./telemarketer-settings-edit.component.scss']
})
export class TelemarketerSettingsEditDialogComponent implements OnInit {

    form: FormGroup;

    messages$ = this.telemarketerSettingsDataService.messages$;
    buttonLoading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { telemarketerSettings: any, mode: string },
        public dialogRef: MatDialogRef<TelemarketerSettingsEditDialogComponent>,
        private telemarketerSettingsDataService: TelemarketerSettingsDataService,
    ) { }

    ngOnInit() {
        this.formInit();
    }

    onCloseDialog(event?: Event, refresh = false) {
        this.dialogRef.close(refresh);
    }

    onSave() {
        this.buttonLoading = true;
        // To set "Save" button to disable (To prevent call API in multiple times when double click)
        this.form.setErrors({ 'invalid': true });

        const data = {
            ...this.form.value,
        };
        Object.keys(data).forEach((key) => (data[key] === null || data[key] === '') && delete data[key]);
        switch (this.data.mode) {
            case 'edit':
                this.telemarketerSettingsDataService.update(this.data.telemarketerSettings.id, data).pipe(
                    tap((res: any) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                    }),
                    catchError((error) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                        throw error;
                    })
                ).subscribe();
                break;
            case 'create':
                this.telemarketerSettingsDataService.add(data).pipe(
                    tap((res: any) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                    }),
                    catchError((error) => {
                        this.buttonLoading = false;
                        // To enable "Save" button after get response
                        this.form.setErrors(null);
                        throw error;
                    })
                ).subscribe();
                break;
        }
    }


    private formInit() {
        let follow_up_day = this.data.mode=='edit'?(this.data.telemarketerSettings.follow_up_day):null;
        let validity_day = this.data.mode=='edit'?(this.data.telemarketerSettings.validity_day):null;
        let ftd_target_day = this.data.mode=='edit'?(this.data.telemarketerSettings.ftd_target_day):null;

        this.form = new FormGroup({
            follow_up_day: new FormControl(follow_up_day, [Validators.required]),
            validity_day: new FormControl(validity_day, [Validators.required]),
            ftd_target_day: new FormControl(ftd_target_day, [Validators.required])
        });
    }

}
