import { TransactionHttpService } from '@core/services/transaction-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MemberDataService } from './../../services/member-data.service';
import { MemberEntityService } from './../../services/member-entity.service';
import { Pagination } from '@core/models/pagination.model';
import { Status } from '@core/enums/status.enum';
import { Member } from '@core/models/member.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef, AfterViewInit, ViewChild } from '@angular/core';
import { Wallet } from '@core/models/wallet.model';
import { Observable, Subscription, BehaviorSubject, of } from 'rxjs';
import { GameAccountMember } from '@core/models/game-account-member.model';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { GameProvider } from '@core/models/game-provider.model';
import { map, tap, debounceTime, concatMap, exhaustMap, catchError } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { MemberGameAccountDialogComponent } from './member-game-account/member-game-account.component';
import { MemberRemarksHistoryComponent } from '../member-remarks-history/member-remarks-history.component';
import { MemberGameLogDialogComponent } from '../member-game-log/member-game-log.component';
import { SitePrefix } from '@core/enums/site-prefix.enum';
import { environment } from '@env/environment';
import { now } from 'moment';
import { AppPermissionService } from '@core/services/app-permission.service';
import { MatTabGroup } from '@angular/material/tabs';
import { EventEmitterService } from '@core/services/event-emitter.service';
@Component({
  selector: 'kt-member-information',
  templateUrl: './member-information.component.html',
  styleUrls: ['./member-information.component.scss'],
})
export class MemberInformationDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  form: FormGroup;

  member: Member;
  mainWallet: Wallet;
  mainWallet$: Observable<Wallet>;
  gameProviders: GameProvider[];
  gameProviders$ = new BehaviorSubject<GameProvider[]>(null);
  gameAccountList$ = new BehaviorSubject<GameAccountMember[]>(null);
  gameAccountBalance$ = new Observable<number>(null);
  totalGameBalance = 0;
  showLoading = false;
  currentGameCode = null;
  resetPasswordGameProvider = null;
  resetAccountGameProvider = null;
  walletInfoSub = new Subscription();
  gamesAccountSub = new Subscription();
  count: number;
  messages$ = this.memberDataService.messages$;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  status = Status;
  loginEvents$: Observable<Member[]>;

  pagination: Pagination;
  currentPage = 1;
  pageSize = 30;
  params = `member_account_id=${this.data.member.id}`;
  perPageDropdown = this.dropdownHttpService.perPage;

  from = this.transactionHttpService.getYesterday().from;
  to = this.transactionHttpService.getLast24Hours().to;
  ranges: any;

  loading = false;
  button_loading = false;
  searchBtnLoading = false;
  selectedGame: string;
  new_username: string;
  new_password: string;
  existing_game_account = [];
  memberBetInfo: any;
  dateLimit: any;

  sitePrefix: string = environment.sitePrefix;
  // hasPermission: boolean = JSON.parse(localStorage.getItem('user_access'))[1][1]['edit'];
  sqsEnable: boolean = environment.sqsEnable;

  // permissions
  canViewBasicInfo: boolean;
  canViewWalletInfo: boolean;
  canCreateGameAccount: boolean;
  canSyncGameWalletBalance: boolean;
  canResetPassword: boolean;
  canResetAccount: boolean;
  canViewWalletEvents: boolean;
  canViewHistoryMessages: boolean;
  canViewLoginEvents: boolean;
  canViewBonusHunterAnalysis: boolean;
  canViewMemberBankAccounts: boolean;
  canViewPromotionHistory: boolean;

  private subscriptions = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<MemberInformationDialogComponent>,
    private transactionHttpService: TransactionHttpService,
    private memberService: MemberEntityService,
    private memberDataService: MemberDataService,
    @Inject(MAT_DIALOG_DATA) public data: { member: Member, mode: string },
    private gameProvider: GameProviderHttpService,
    private dropdownHttpService: DropdownHttpService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private appPermissionService: AppPermissionService,
    private eventEmitter: EventEmitterService
  ) { }

  ngOnInit(): void {
    this.member = this.data.member;
    this.ranges = this.transactionHttpService.ranges;
    this.formInit();
    this.mainWallet$ = this.getMainWallet(this.member.wallets);
    this.onReload(true);
    this.onViewPageBy();
    this.pagination = this.memberDataService.pagination;
    this.eventEmitter.memberInformationChangeTabVar = this.eventEmitter.memberInformationChangeTab.subscribe(val => this.tabGroup.selectedIndex = val);
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewBasicInfo = appPermissions.view_member_info || appPermissions.view_member_statistics;
      this.canViewWalletInfo = appPermissions.view_wallet_info;
      this.canCreateGameAccount = appPermissions.create_game_account;
      this.canSyncGameWalletBalance = appPermissions.sync_game_wallet_balance;
      this.canResetPassword = appPermissions.wallet_info_reset_password;
      this.canResetAccount = appPermissions.reset_game_account;
      this.canViewWalletEvents = appPermissions.view_wallet_events;
      this.canViewHistoryMessages = appPermissions.view_history_messages;
      this.canViewLoginEvents = appPermissions.view_login_events;
      this.canViewBonusHunterAnalysis = appPermissions.view_bonus_hunter_analysis;
      this.canViewMemberBankAccounts = appPermissions.view_member_bank_accounts;
      this.canViewPromotionHistory = appPermissions.view_promotion_history;
      this.cdr.detectChanges();
    });

    this.subscriptions.add(apSub);
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.walletInfoSub.unsubscribe();
    this.gamesAccountSub.unsubscribe();
    this.subscriptions.unsubscribe();
    this.eventEmitter.memberInformationChangeTabVar.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onReload(isAll = true) {
    this.gamesAccountSub = this.gameProvider.getGameAccountsOfMember(this.member.username, this.member.settings_currency_id)
      .subscribe((res: any) => {
        const rows = res.rows;
        this.gameAccountList$.next(rows);
        this.gameProviders$.next(rows)
        if (this.gameAccountList$) {
          rows.map((game, rowIndex) => {
            this.gameAccountList$.value[rowIndex] = {
              ...this.gameAccountList$.value[rowIndex],
              status: game.status,
            };
          });
        }
        this.totalGameBalance = res.total_game_balance;
        this.existing_game_account = [];
        rows.forEach((item) => {
          this.existing_game_account.push(item.game_provider_code);
        });
      });
  }

  onGetGeoLocation(ip: string) {
    const apiBase: string = environment.apiBase;
    window.open(apiBase + `/geoLocation?ip_address=${ip}`, '_blank');
  }

  onResetPassword(gameProvider: any, rowIndex: number) {
    this.loading = true;
    this.resetPasswordGameProvider = this.selectedGame = gameProvider.game_provider_code;
    const data = {
      game_provider_code: gameProvider.game_provider_code,
      member_account_id: this.data.member.id
    };

    this.memberDataService.resetPassword(data).pipe(
      tap((res: any) => {
        this.gameAccountList$.value[rowIndex] = {
          ...this.gameAccountList$.value[rowIndex],
          ga_password: res.data.new_password,
        };
        this.new_password = res.data.new_password;
        this.resetPasswordGameProvider = null;
        this.loading = false;
      }),
      catchError((error) => {
        this.loading = false;
        this.resetPasswordGameProvider = null;
        throw error;
      })
    ).subscribe();
  }

  onResetAccount(gameProvider: any, rowIndex: number) {
    this.loading = true;
    this.resetAccountGameProvider = gameProvider.game_provider_code;
    Swal.fire({
      text: 'Are you sure you want to reset ' + gameProvider.username + ' - ' + gameProvider.game_provider_code + ' account?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const data = {
          game_provider_id: gameProvider.game_provider_id,
          member_account_id: this.data.member.id
        }
        this.selectedGame = gameProvider.game_provider_code;
        this.memberDataService.resetGameAccount(data).pipe(
          tap((res:any) => {
            this.new_username = res.data.new_username;
            this.new_password = res.data.new_password;
            this.loading = false;
            this.resetAccountGameProvider = null;
          }),
          catchError((error) => {
            this.loading = false;
            this.resetAccountGameProvider = null;
            throw error;
          })
        ).subscribe();
      } else {
        this.loading = false;
        this.resetAccountGameProvider = null;
      }
    })
  }

  onSyncPerGame(
    gameProviderCode: string,
    memberId: number,
    rowIndex: number,
    count: number
  ) {
    // TODO: v2 will be used below once game_provider_id is available in /accounts/ endpoint
    this.count = count ? count : 1;
    this.walletInfoSub = this.gameProviders$.pipe(
      map((res: any) =>
        res.find((element: GameProvider) => element.game_provider_code === gameProviderCode)
      ),
      tap((res) => {
        this.showLoading = true;
        if (!count) {
          this.currentGameCode = res.game_provider_code;
        }
      }),
      debounceTime(250),
      concatMap((_) => {
        if (_.status !== 1) {
          this.count--;
          if (this.count === 0) {
            this.showLoading = false;
          }
          return _;
        }
        return this.gameProvider.getGameDataBalanceOfMember(memberId, _.game_provider_id).pipe(
          tap((res) => {
            this.currentGameCode = res.game_provider_code;
            this.gameAccountList$.value[rowIndex] = {
              ...this.gameAccountList$.value[rowIndex],
              balance: +res.balance,
            };
          }),
          catchError((error) => {
            this.showLoading = false;
            throw error;
          })
        ).subscribe(() => {
          this.count--;
          if (this.count === 0) {
            this.showLoading = false;
          }
        })
      }
      )
    ).subscribe();
  }

  onSyncPerGame_v2_HOLD(
    gameProviderCode: string,
    memberId: number,
    rowIndex: number
  ) {
    this.gameAccountList$
      .pipe(
        map((res) =>
          res.find(
            (element: GameAccountMember) =>
              element.game_provider_code === gameProviderCode
          )
        ),
        debounceTime(250),
        concatMap((game) =>
          this.gameProvider.getGameDataBalanceOfMember(memberId, game.id).pipe(
            tap((res) => {
              this.showLoading = false;
              this.gameAccountList$.value[rowIndex] = {
                ...this.gameAccountList$.value[rowIndex],
                balance: +res.balance,
              };
            })
          )
        )
      )
      .subscribe();
  }

  onSyncAll() {
    this.memberService.getByKey(this.member.id).pipe(
      map((res) => of(res.wallets.find((wallet) => wallet.is_main === 1)))
    ).subscribe((res) => {
      this.mainWallet$ = res;
    });

    // this.gameProvider.getGameProviders().pipe(
    //   map((res) => {
    //     this.gameProviders$ = of(res);
    //   })
    // ).subscribe();

    this.gameAccountList$.pipe(
      tap((game) => {
        this.count = game.length;
        game.map((row: GameAccountMember, index) => {
          this.onSyncPerGame(
            row.game_provider_code,
            this.member.id,
            index,
            this.count
          );
        });
      })
    ).subscribe();
  }

  onPreviousPage() {
    if (this.currentPage <= 1) {
      return;
    }
    this.currentPage--;
    this.onViewPageBy(this.currentPage);
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    return (this.loginEvents$ = this.memberDataService
      .getLogInEvents(`?page=${page}&perPage=${pageSize}${params}`)
      .pipe(
        tap((res) => {
          this.currentPage = page;
          this.pagination = this.memberDataService.pagination;
        })
      ));
  }

  onNextPage() {
    if (this.currentPage >= this.pagination.last_page) {
      return;
    }
    this.currentPage++;
    this.onViewPageBy(this.currentPage);
  }

  onPerPage(size: Event) {
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.currentPage, this.pageSize, this.params);
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  selectStartDate(event: any) { // get the total days within 6 months from selected start date
    if (event) {
      const minDate = moment(event);
      const maxDate = moment(event).add(6,'months');
      this.dateLimit = maxDate.diff(minDate, 'days');
    }
  }

  onEllipsis(value: string, maxlength = 15) {
    let result = '';
    if (value.length > maxlength) {
      for (let i = 0; i < 7; i++) {
        result += value.split('')[i];
      }
      result += '...';
    } else {
      result = value;
    }
    return result;
  }

  hasActiveLabel(member) {
    return member.labels.some(label => label.status == 1);
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  private getMainWallet(wallets: Wallet[]) {
    return of(wallets.find((row: any) => row.is_main === 1));
  }

  private formInit() {
    this.form = new FormGroup({
      start_date: new FormControl(this.from),
      end_date: new FormControl(this.to),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to,
      }),
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach((key) => {
      if (
        formData[key] !== '' &&
        formData[key] !== null &&
        formData[key] !== undefined &&
        key !== 'defaultDate' &&
        formData[key] !== 'all'
      ) {
         fields[key] = formData[key];
      }
    });
    return fields;
  }

  onOpenDialog(member: any, type: String, game?: any) {
    // set latest username to display after reset account
    if(this.new_username !== undefined && this.new_username !== ' ' && game !== undefined  && game !== '' && this.selectedGame === game.game_provider_code){
      game.ga_username = this.new_username;
    }

    if(this.new_password !== undefined && this.new_password !== '' && game !== undefined  && game !== '' && this.selectedGame === game.game_provider_code){
      game.ga_password = this.new_password;
    }

    if(type === 'memberRemarksHistory'){
      this.openDialogBy(MemberRemarksHistoryComponent, { member: member, mode: 'memberRemarksHistory' })
    }else if(type === 'game-log'){
      this.openDialogBy(MemberGameLogDialogComponent, { member: member, mode: 'game-log', existing_game_account: game})
    }else {
      this.openDialogBy(MemberGameAccountDialogComponent, { member: member });
    }
  }

  private openDialogBy(componentRef: any, data?: { member?: any, mode?: any, existing_game_account?: any }) {
    if(data.mode === 'memberRemarksHistory'){
      this.dialog.open(componentRef, {
        width: '800px',
        data: {
          memberId: data.member.id
        }
      });
    }else if(data.mode === 'game-log') {
      this.dialog.open(componentRef, {
        width: '1000px',
        data: {
          member: data.member,
          existing_game_account: data.existing_game_account
        }
      });
    }else {
      const dialogRef = this.dialog.open(componentRef, {
        width: '800px',
        data: {
          member: data.member,
          existing_game_account: this.existing_game_account
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.onReload(true);
      });
    }

  }

  onEllipsisWord(word: string){
    let newRemark = '';
    if (word.length > 51){
      for (let i = 0; i < 51; i++ ){
        newRemark += word.split('')[i];
      }
      newRemark += '...';
    }else{
      newRemark = word;
    }
    return newRemark;
  }

  onClearDate() {
      this.form.patchValue({ defaultDate: null });
  }

}
