
import { tap, catchError, map, finalize } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import { ApplicationRoleEditDialogComponent } from './dialogs/application-role-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Pagination } from '@core/models/pagination.model';
import { Status } from '@core/enums/status.enum';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ApplicationHttpService } from '@core/services/application-http.service';
import { ApplicationRole } from '@core/models/application-role.model';
import { Subscription, of } from 'rxjs';
import { ApplicationSectionPermission } from '@core/models/application-section-permission.model';
import { ApplicationRoleDetails } from '@core/models/application-role-details.model';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  templateUrl: './application-role.component.html',
  styleUrls: ['./application-role.component.scss']
})
export class ApplicationRoleComponent implements OnInit, OnDestroy {

  form: FormGroup;

  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    perPage: this.dropdownHttpService.perPage
  };
  status = Status;
  roles: ApplicationRole[] = [];

  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  editBtnLoading = {};
  createBtnLoading = false;

  disabledPermissions = [];

  params = '';

  dataLength: number;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;

  // permissions
  canCreateApplicationRole: boolean;
  canEditApplicationRole: boolean;

  private subscriptions = new Subscription();

  constructor(
    public dialog: MatDialog,
    private loadingBar: LoadingBarService,
    private dropdownHttpService: DropdownHttpService,
    private applicationHttpService: ApplicationHttpService,
    private cdr: ChangeDetectorRef,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();

    this.dropdownHttpService.disabledApplicationPermissions.pipe(
      tap(disabledPermissions => {
        this.disabledPermissions = disabledPermissions;
      })
    ).subscribe();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateApplicationRole = appPermissions.app_role_create_role;
      this.canEditApplicationRole = appPermissions.app_role_edit_role;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onViewPageBy() {
    this.loading = true;
    this.loadingBar.start();
    this.roles = [];

    this.applicationHttpService.getRoles(`page=${this.page}&perPage=${this.pageSize}${this.params}`).pipe(
      tap(res => {
        this.roles = res;
        this.roles.forEach(role => {
          this.editBtnLoading[role.id] = false;
        });
        this.loading = false;
        this.loadingBar.complete();
        this.dataLength = res.length;
        this.pagination = this.applicationHttpService.rolePagination;
      }),
      catchError(err => {
        this.loading = false;
        this.loadingBar.complete();
        throw err;
      })
    ).subscribe();
  }

  onPerPage(size: Event) {
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy();
  }

  onOpenDialog(id?: number) {
    if (id) {
      this.editBtnLoading[id] = true;
      this.applicationHttpService.getRoleDetails(id)
        .pipe(
          finalize(() => {
            this.editBtnLoading[id] = false;
            this.cdr.detectChanges();
          }),
        )
        .subscribe(roleDetails => {
          this.openDialogBy(ApplicationRoleEditDialogComponent, {
            mode: 'edit',
            sectionPermissions: roleDetails.sections,
            roleDetails: roleDetails,
            disabledPermissions: this.disabledPermissions,
          });
        });

        return;
    } 

    this.createBtnLoading = true;
    this.applicationHttpService.getSectionPermissions()
      .pipe(
        finalize(() => {
          this.createBtnLoading = false;
          this.cdr.detectChanges();
        }),
      )
      .subscribe(sectionPermissions => {
        this.openDialogBy(ApplicationRoleEditDialogComponent, {
          mode: 'create',
          sectionPermissions: sectionPermissions,
          disabledPermissions: this.disabledPermissions,
        });
      });

    // TODO: remove this if logic above has no issue
    // this.editBtnLoading[id] = true;
    // this.applicationHttpService.getSectionPermissions()
    //   .pipe(
    //     finalize(() => this.editBtnLoading[id] = false)
    //   )
    //   .subscribe(sectionPermissions => {
    //     if (id) {
    //       this.applicationHttpService.getRoleDetails(id).subscribe(roleDetails => {
    //         this.openDialogBy(ApplicationRoleEditDialogComponent, { mode: 'edit', sectionPermissions: sectionPermissions, roleDetails: roleDetails });
    //       });
    //     } else {
    //       this.openDialogBy(ApplicationRoleEditDialogComponent, { mode: 'create', sectionPermissions: sectionPermissions });
    //     }
    //   });
  }

  onClear() {
    this.clearBtnLoading = true;
    this.formInit();
    this.onSubmit();
  }

  onSubmit() {
    this.loading = true;
    this.loadingBar.start();
    this.searchBtnLoading = true;
    this.page = 1;
    this.roles = [];

    of(this.form.value).pipe(
      map(this.filterFormFields),
      tap(data => {
        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        if (this.params.length > 0) {
          this.params = '&' + this.params;
        }
        this.applicationHttpService.getRoles(`page=${this.page}&perPage=${this.pageSize}${this.params}`).pipe(
          tap(res => {
            this.roles = res;
            this.roles.forEach(role => {
              this.editBtnLoading[role.id] = false;
            });
            this.loading = false;
            this.loadingBar.complete();
            this.searchBtnLoading = false;
            this.clearBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.applicationHttpService.rolePagination;
          }),
          catchError(err => {
            this.loading = false;
            this.loadingBar.complete();
            this.searchBtnLoading = false;
            this.clearBtnLoading = false;
            throw err;
          })
        ).subscribe();
      })
    ).subscribe();
  }

  private formInit() {
    this.form = new FormGroup({
      name: new FormControl(null),
      status: new FormControl('all')
    });
  }

  private openDialogBy(componentRef: any, data?: { mode: 'create' | 'edit', sectionPermissions: ApplicationSectionPermission[], roleDetails?: ApplicationRoleDetails, disabledPermissions: any[] }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '85vw', data: {
        mode: data.mode,
        sectionPermissions: data.sectionPermissions,
        roleDetails: data.roleDetails,
        disabledPermissions: data.disabledPermissions,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.onViewPageBy();
      }
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }
}
