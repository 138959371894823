<div class="kt-form w-webkit-fill-available" *ngIf="dropdown.locales.length > 0">
    <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body pt-12 pb-0">
                <div class="kt-section">
                    <div class="kt-section__content">
                        <div class="table-responsive">
                            <table class="table table-bordered table-hover table-striped table-sortable">
                                <thead>
                                    <tr>
                                        <th class="pl-1 pr-3 table-header">
                                            <div class="pr-1">{{ 'Display Locale' | uppercase }}</div>
                                            <div class="dropdown selected-category-container ml-1" *ngIf="displayLocale != null">
                                                <button class="dropdown-toggle d-flex justify-content-center dropdown-box" type="button" data-toggle="dropdown" style="margin: 0 auto">
                                                    <p class="mr-1">{{ displayLocale['code'] | uppercase }} </p>
                                                    <i class="fa fa-sort-down"></i>
                                                </button>
                                                <ul class="dropdown-menu dropdown-box-open">
                                                    <li *ngFor="let value of dropdown.locales" class="selected-category-container2 ml-0" (click)="changeLocale(value)">
                                                        {{ value.code | uppercase }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th class="pr-0 pl-0"></th>
                                    </tr>
                                </thead>

                                <tbody *ngIf="referralContent$ as rows">
                                    <tr>
                                        <td colspan="6" class="custom-padding"></td>
                                    </tr>

                                    <tr class="border-bottom-remove">
                                        <td class="title" [style]="'border-bottom: 0 !important;'">
                                            <div class="title">{{ displayLocaleContent(rows) | uppercase }}</div>
                                        </td>
                                        <td class="d-flex align-content-center justify-content-end" [style]="mode == 'edit' && referralContent && referralContent.id == rows.id ? 'border-bottom: 0 !important;' : ''">
                                            <button *ngIf="canViewReferralContentDetails" matTooltip="View" class="btn btn-elevate btn-sm btn-icon btn-icon-sm" (click)="expandRowEdit('edit', rows)"><i class="fas fa-cog"></i></button>
                                        </td>
                                    </tr>
                                    <tr class="border-top-remove" *ngIf="!mode || !referralContent || (mode == 'edit' && referralContent && referralContent.id !== rows.id)" [ngClass]="(mode == 'edit' && referralContent && referralContent.id == rows.id) ? 'border-bottom-remove' : ''">
                                        <td class="w-100 pr-0 pl-0 custom-top-border">
                                            <div class="row col-12 p-0 m-0">
                                                <div class="col-12 font-weight-normal ml-2">
                                                    <div id="content" class="limittext" [ngClass]="showMain ? 'showContent' : '' ">
                                                        <div [innerHTML]="displayLocaleContent(rows, 'description')"></div>
                                                    </div>
                                                    <button class="read-button" *ngIf="!showMain && showReadMoreButton('content')" (click)="showMain = true">[ {{ 'Read More' }} ]</button>
                                                    <button class="read-button" *ngIf="showMain && !showReadMoreButton('content')" (click)="showMain = false">[ {{ 'Read Less'}} ]</button>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="custom-top-border"></td>
                                    </tr>
                                    <tr *ngIf="mode == 'edit' && referralContent" class="border-top-remove">
                                        <td colspan="2" class="pr-0 pl-0 custom-top-border" style="border-bottom: 0 !important;">
                                            <ng-container *ngIf="!mode else contents"></ng-container>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold table-handler"></tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #contents>
    <form [formGroup]="formContent">
        <div class="create-content-container" *ngIf="dropdown.locales.length > 0">
            <hr>
            <ng-container *ngIf="checkLoadingPage else loadingPage">
                <mat-tab-group animationDuration="0ms" class="form-group mb-0 mat-tab-header-pagination-controls-enabled" class="form-group m-0" formGroupName="contents" [selectedIndex]="localeIndex()">
                    <ng-container *ngIf="dropdown.locales.length > 0">
                        <mat-tab *ngFor="let value of dropdown.locales; let i = index" [formGroupName]="value.id">
                            <ng-template mat-tab-label>
                                <span class="mat-tab">
                                    {{ value.code }}
                                    <div *ngIf="checkContent(value)" class="icon" (click)="clearContent(value)">
                                        <i class="fas fa-times"></i>
                                    </div>
                                </span>
                            </ng-template>
                            <div class="row col-12 p-0 m-0">
                                <div class="form-group col-12 mb-0 p-0">
                                    <label>Title: </label>
                                    <input type="text" formControlName="title" class="form-control">
                                </div>
                            </div>
                            <div class="col-12 p-0 mb-3">
                                <span class="header-hint">Usable variables:
                                    <div *ngFor="let usableVariable of usableVariables; let last = last" class="p-inline">
                                        <p class="p-inline" (click)="addTitleVariable(usableVariable, value.id)">{{ usableVariable }}</p>
                                        <div *ngIf="!last" class="p-inline">
                                            <p class="p-inline p-display">, </p>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div class="row col-12 p-0 m-0">
                                <div class="form-group col-12 mb-0 p-0">
                                    <label>Description: </label>
                                    <ckeditor #editors [config]="editorConfig" [editor]="editor" formControlName="description" class="ck-style"></ckeditor>
                                </div>
                            </div>
                            <div class="col-12 p-0 mb-3">
                                <span class="header-hint">Usable variables:
                                    <div *ngFor="let usableVariable of usableVariables; let last = last" class="p-inline">
                                        <p class="p-inline" (click)="addDescriptionVariable(usableVariable, value.id)">{{ usableVariable }}</p>
                                        <div *ngIf="!last" class="p-inline">
                                            <p class="p-inline p-display">, </p>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </mat-tab>
                    </ng-container>
                </mat-tab-group>
            </ng-container>
            <div class="modal-footer">
                <table class="info mr-3" *ngIf="mode == 'edit'">
                    <tr class="border-bottom-remove">
                        <td class="header-hint">
                            Updated by: {{ updated_by != null ? updated_by + '&nbsp;' : 'System&nbsp;' }}
                        </td>
                        <td class="header-hint">
                            <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="tooltip-class">{{ updated_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
                            <ng-template #updatedDateTime>
                                {{ updated_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
                            </ng-template>
                        </td>
                    </tr>
                    <tr class="border-bottom-remove">
                        <td class="header-hint">
                            Created by: {{ created_by != null ? created_by + '&nbsp;' : 'System&nbsp;' }}
                        </td>
                        <td class="header-hint">
                            <span placement="top" [ngbTooltip]="createdDateTime" container="body" class="tooltip-class">{{ created_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
                            <ng-template #createdDateTime>
                                {{ created_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
                            </ng-template>
                        </td>
                    </tr>
                </table>
                <button type="button" class="btn btn-danger m-0 mr-2" (click)="expandRowEdit('', null, true)">Cancel</button>
                <button *ngIf="canEditReferralContent" type="button" class="btn btn-success m-0" [disabled]="!formContent.valid" (click)="onSave()">Update</button>
            </div>
        </div>
    </form>
    <ng-template #loadingPage>
        <div style="margin: auto;width: fit-content;">
            <div class="spinner-wrapper">
                <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
            </div>
        </div>
    </ng-template>
</ng-template>

<kt-swal-alert [message]="messages$ | async" (confirmed)="reload()"></kt-swal-alert>