<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <form class="align-items-center" [formGroup]="form">
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control" [attr.disabled] = "interactiveInput.disableUsername ? 'disabled': null" (input)="onInteractiveSearchFilter($event)">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control" [attr.disabled] = "interactiveInput.disableName ? 'disabled': null" (input)="onInteractiveSearchFilter($event)">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Bank Acct No.:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="bank_account_number" placeholder="Search" class="form-control" (input)="onInteractiveSearchFilter($event)" [attr.disabled] = "interactiveInput.disableBankAcc ? 'disabled': null">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>ID:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="number" (wheel)="false" formControlName="id" placeholder="Search" class="form-control" (input)="onInteractiveSearchFilter($event)" [attr.disabled] = "interactiveInput.disableID ? 'disabled': null">
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control" [attr.disabled] = "interactiveInput.disableCurrency ? 'disabled': null">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="status" class="form-control" [attr.disabled] = "interactiveInput.disableStatus ? 'disabled': null">
                                        <option value="all"> All </option>
                                        <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                                            {{ value }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Group:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="group_id" class="form-control" [attr.disabled] = "interactiveInput.disableGroup ? 'disabled': null">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of memberGroup">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Agent:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="agent" placeholder="Search" class="form-control" (input)="onInteractiveSearchFilter($event)" [attr.disabled] = "interactiveInput.disableAgent ? 'disabled': null">
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Contact: </label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <select class="form-control" (change)="onContactType($event.target.value)" [attr.disabled] = "interactiveInput.disableContact ? 'disabled': null">
                                                <option value="Mobile" selected>Mobile</option>
                                                <option value="Email">Email</option>
                                            </select>
                                        </div>
                                        <input type="text" (change)="onContact($event)" placeholder="Search" class="form-control" [disabled]="(selectedContactType === '' && selectedContactType === null)" #contactRef [attr.disabled] = "interactiveInput.disableContact ? 'disabled': null" id="contactRefValue">
                                        <input type="hidden" formControlName="mobile" />
                                        <input type="hidden" formControlName="email" />
                                    </div>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Type: </label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <!-- <select formControlName="suspicious" class="form-control">
                                        <option value="all"> All </option>
                                        <ng-container *ngFor="let item of memberType | keyvalue">
                                            <option [value]="item.key" *ngIf="+item.key >= 0">{{ memberType[item.key] }}</option>
                                        </ng-container>
                                    </select> -->
                                    <ngx-dropdown-treeview [config]="config" [items]="treeviewItems" [buttonClass]="'treeview-outline-color'"
                                        (selectedChange)="onTreeviewChange($event)">
                                    </ngx-dropdown-treeview>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group date">

                                        <select formControlName="datetime_type" class="form-control" style="width: 30%;">
                                            <option [value]="item.key" *ngFor="let item of dateTimeFilterType">{{ item.label }}</option>
                                        </select>

                                        <input type="hidden" formControlName="start_datetime" >
                                        <input type="hidden" formControlName="end_datetime" >

                                        <div class="input-group date" style="width: 70%;">
                                            <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true"
                                            [alwaysShowCalendars]="true" [ranges]="ranges"
                                            [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                            [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()"
                                            ngxDaterangepickerMd
                                            [attr.disabled] = "interactiveInput.disableDate ? 'disabled': null"/>
                                            <span class="input-group-append">
                                                <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Game Username: </label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <textarea class="form-control" placeholder="Search" formControlName="ga_username" rows="1" (keydown)="autoGrow()" #textArea [attr.disabled] = "interactiveInput.disableGA_username ? 'disabled': null" (input)="onInteractiveSearchFilter($event)"></textarea>
                                </div>

                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Affiliate:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="affiliate_username" placeholder="Search" class="form-control" (input)="onInteractiveSearchFilter($event)" [attr.disabled] = "interactiveInput.disableAffiliate ? 'disabled': null">
                                </div>
                                <div class="col-md-1 kt-form__control col-form-label">
                                    <label>IP:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="ip" placeholder="Search" class="form-control" (input)="onInteractiveSearchFilter($event)" [attr.disabled] = "interactiveInput.disableIp ? 'disabled': null">
                                </div>
                                <ng-container *ngIf="sqsEnable">
                                    <div class="col-md-1 kt-form__label col-form-label">
                                        <label>Label:</label>
                                    </div>
                                    <div class="col-md-2 kt-form__control">
                                        <select formControlName="label" class="form-control">
                                            <option value="all"> All </option>
                                            <option [value]="item.name" *ngFor="let item of dropdown.label | async">{{ item.name }}</option>
                                            <option value="-"> Remarks Only </option>
                                        </select>
                                    </div>
                                </ng-container>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()" (click)="contactRef.value = ''"></kt-clear-button>
                </div>
                <div class="dropdown dropdown-inline">
                    <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                    <!-- <button *ngIf="allMembersUseNewPermissions ? canExportMemberList : (access$ | async).export" class="btn btn-warning btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="buttonLoading"> -->

                    <button *ngIf="canExportMemberList" class="btn btn-warning btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="buttonLoading">
                        <i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export
                    </button>
                    <button *ngIf="canCreateMember" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('create')"><i class="fas fa-plus"></i>Create</button>
                </div>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                    <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                                    <th class="sort-enabled" (click)="onSortColumn('name')" [ngClass]="sortingConfig.name" [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name</th>
                                    <th class="sort-enabled" (click)="onSortColumn('affiliate_username')" [ngClass]="sortingConfig.affiliate_username" [ngClass]="sortingConfig.affiliate_username" [class.sort-selected]="sortingSelection.sort_by === 'affiliate_username'">Affiliate</th>
                                    <th class="sort-enabled" (click)="onSortColumn('agents_username')" [ngClass]="sortingConfig.agents_username" [ngClass]="sortingConfig.agents_username" [class.sort-selected]="sortingSelection.sort_by === 'agents_username'">Agent</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('currency')" [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">Currency</th>
                                    <th class="sort-enabled" (click)="onSortColumn('group')" [ngClass]="sortingConfig.group" [class.sort-selected]="sortingSelection.sort_by === 'group'">Group</th>
                                    <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                                    <th class="sort-enabled" (click)="onSortColumn('registration_created_at')"[ngClass]="sortingConfig.registration_created_at" [class.sort-selected]="sortingSelection.sort_by === 'registration_created_at'">Registration Info</th>
                                    <th class="date-column sort-enabled" (click)="onSortColumn('last_login')" [ngClass]="sortingConfig.last_login" [class.sort-selected]="sortingSelection.sort_by === 'last_login'">Last Login</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(members$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.id }}</td>
                                    <td class="username-col">
                                        <a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold username-content" (click)="onOpenDialog('member-information', row.id)">{{ row.username }}</a>
                                        <div class="suspicious-ribbon" *ngIf="sqsEnable ? (row.labels?.length > 0 && hasActiveLabel(row)) : (row.suspicious)">
                                            <i  class="fa fa-exclamation"></i>
                                        </div>
                                        <i (click)="onViewMoreRemarks(row, sqsEnable)" *ngIf="sqsEnable ? (row.mal_remark == 1) : row.remarks !== null" class="far fa-comment-dots d-flex align-items-center pointer remark-icon" matTooltip="Remarks"></i>
                                        <br>
                                        <span class="bonus-hunter">
                                            <i class="fa fa-font" matTooltip="Same Name" *ngIf="row.name_trace === 1"></i>
                                            <i class="fa fa-key" matTooltip="Same Password" *ngIf="row.password_trace === 1"></i>
                                            <i class="fa fa-envelope" matTooltip="Same Email" *ngIf="row.email_trace === 1"></i>
                                            <i class="fa fa-map-marker-alt" matTooltip="Same IP" *ngIf="row.ip_trace === 1"></i>
                                            <i class="fa fa-fingerprint" matTooltip="Same Fingerprint" *ngIf="row.fingerprint_trace === 1"></i>
                                        </span>

                                    </td>
                                    <td>{{ row.name }}</td>
                                    <td>{{ row.affiliate_username }}</td>
                                    <td>{{ row.agents_username }}</td>
                                    <td class="no-break">
                                        {{ row.email }}
                                        <i *ngIf="row.email_status === 1" class="fas fa-check-circle"></i>
                                    </td>
                                    <td class="no-break">
                                        {{ row.mobile }}
                                        <i *ngIf="row.mobile_status === 1" class="fas fa-check-circle"></i>
                                    </td>
                                    <td class="text-center">{{ row.currency }}</td>
                                    <td>{{ getMemberGroupName(row.member_group_id) }}</td>
                                    <td class="text-center">
                                        <div class="btn-group dropdown">
                                            <button type="button" class="btn btn-pill btn-sm dropdown-base"  [ngClass]="'kt-badge--' + row.status"> {{ row.status === 2 ? 'Suspended' : status[row.status] }} </button>
                                            <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                            <!-- <ng-container *ngIf="allMembersUseNewPermissions ? (canEditMemberDetails || canUpdateMemberStatus) : (access$ | async).edit"> -->

                                            <ng-container *ngIf="(canEditMemberDetails || canUpdateMemberStatus)">
                                                <button type="button" class="btn btn-pill btn-sm dropdown-toggle" [ngClass]="'kt-badge--' + row.status" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fa fa-angle-down"></i>
                                                </button>
                                                <div class="dropdown-menu">
                                                    <ng-container *ngFor="let item of status | keyvalue">
                                                        <button class="dropdown-item" type="button" (click)="onChangeStatus(item.key, row.id)" *ngIf="+item.key >= 0">{{ status[item.key] }}</button>
                                                    </ng-container>
                                                        <!-- <button class="dropdown-item" type="button" (click)="onChangeStatus(2, row.id)">Suspended</button> -->
                                                </div>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td class="width-170">
                                        <span class="no-wrap">{{ row.registration_created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</span><br>
                                        <span placement="top" [ngbTooltip]="row.registration_ip" container="body" *ngIf="row.registration_ip" class="text-ellipsis"> {{ row.registration_ip }}</span>
                                        <span placement="top" [ngbTooltip]="row.registration_pseudo_ip" container="body" *ngIf="row.registration_pseudo_ip" class="text-ellipsis"> {{ row.registration_pseudo_ip }}</span>
                                        <ng-container *ngIf="urlIsValid(row.registration_site); else invalidRegistrationSite">
                                            <a [href]="row.registration_site" target="_blank"> {{ row.registration_site ? getUrl(row.registration_site) : ''}}</a><br>
                                        </ng-container>
                                        <ng-template #invalidRegistrationSite>
                                            <a> {{ row.registration_site }}</a><br>
                                        </ng-template>
                                        {{ row.registration_locale_code }}
                                    </td>
                                    <td class="width-170">
                                        {{ row.last_login  | timezoneDate: 'YYYY-MM-DD HH:mm' }} <br>
                                        <span placement="top" [ngbTooltip]="row.last_ip_address" container="body" *ngIf="row.last_ip_address" class="text-ellipsis"> {{ row.last_ip_address }}</span>
                                        <span placement="top" [ngbTooltip]="row.last_pseudo_ip" container="body" *ngIf="row.last_pseudo_ip" class="text-ellipsis"> {{ row.last_pseudo_ip }}</span>
                                        <ng-container *ngIf="urlIsValid(row.last_domain); else invalidLastDomain">
                                            <a [href]="row.last_domain" target="_blank">{{ row.last_domain ? getUrl(row.last_domain) : ''}}</a>
                                        </ng-container>
                                        <ng-template #invalidLastDomain>
                                            <a>{{ row.last_domain }}</a>
                                        </ng-template>
                                    </td>
                                    <td class="text-center actions-column">
                                        <!-- <button matTooltip="Topup" data-target="#VTopup" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('topup', row.id)"><i class="fas fa-plus-square kt-font-success"></i></button>
                                        <button class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('deduct', row.id)"><i class="fas fa-minus-square kt-font-danger"></i></button>
                                        <button matTooltip="Blacklist" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('blacklist', row.id)"><i class="fas fa-ban"></i></button> -->

                                        <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
                                        <!-- <button matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)" *ngIf="allMembersUseNewPermissions ? canEditMemberDetails : (access$ | async).edit"><i class="fas fa-edit"></i></button>
                                        <button matTooltip="Reset Password" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('resetPassword', row.id)" *ngIf="allMembersUseNewPermissions ? canResetPassword : (loggedUserPermission$ | async).reset_password || (loggedUser$ | async).is_admin"><i class="fas fa-key"></i></button>
                                        <button matTooltip="Adjust Wallet" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('adjust', row.id)" *ngIf="allMembersUseNewPermissions ? canAdjustWallet : (loggedUserPermission$ | async).adjust_wallet || (loggedUser$ | async).is_admin"><i class="fas fa-wallet"></i></button><br>
                                        <button matTooltip="Assign Rewards" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('assignRewards', row.id)" *ngIf="canAssignRewards"><i class="fas fa-gift"></i></button>
                                        <button matTooltip="Shadow Login" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onShadowLogin(row.id, row.username)" *ngIf="allMembersUseNewPermissions ? canShadowLogin : (loggedUserPermission$ | async).shadow_login || (loggedUser$ | async).is_admin"><i class="fas fa-sign-in-alt"></i></button>
                                        <button matTooltip="Force Logout" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onForceLogout(row.id, row.username)" *ngIf="allMembersUseNewPermissions ? canForceLogout : (loggedUserPermission$ | async).force_logout || (loggedUser$ | async).is_admin"><img src="/assets/img/force-logout.webp" alt="Force Logout" width="20"></button> -->

                                        <button matTooltip="Edit" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('edit', row.id)" *ngIf="canEditMemberDetailsRestricted || canEditMemberDetails"><i class="fas fa-edit"></i></button>
                                        <button matTooltip="Reset Password" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('resetPassword', row.id)" *ngIf="canResetPassword"><i class="fas fa-key"></i></button>
                                        <button matTooltip="Adjust Wallet" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('adjust', row.id)" *ngIf="canAdjustWallet"><i class="fas fa-wallet"></i></button><br> <!-- TODO disabled if inactive -->
                                        <button matTooltip="Assign Rewards" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('assignRewards', row.id)" *ngIf="canAssignRewards"><i class="fas fa-gift"></i></button>
                                        <button matTooltip="Shadow Login" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onShadowLogin(row.id, row.username)" *ngIf="canShadowLogin"><i class="fas fa-sign-in-alt"></i></button>
                                        <button matTooltip="Force Logout" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onForceLogout(row.id, row.username)" *ngIf="canForceLogout"><img src="/assets/img/force-logout.webp" alt="Force Logout" width="20"></button>
                                        <button matTooltip="Check Promotion Eligible" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('promoEligible', row.id)" *ngIf="canCheckPromotionEligibility"><i class="fas fa-stethoscope"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                            <ngb-pagination
                            [pageSize]="pageSize"
                            [(page)]="page"
                            [maxSize]="maxSize"
                            [directionLinks]="true"
                            [boundaryLinks]="true"
                            [rotate]="true"
                            [collectionSize]="pagination.total"
                            (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<kt-swal-alert [message]="messages$ | async" (confirmed)="loadingBar.complete()"></kt-swal-alert>

<!-- message for permission error -->
<kt-swal-alert [message]="messages2$ | async" [icon]="'error'"></kt-swal-alert>
