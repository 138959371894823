<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header" style="display: inline">
      <div>
        <h5 class="modal-title" style="display: inline">Info - {{ member.username }} ({{ member.id }})</h5>
        <ng-container *ngIf="sqsEnable"
          ><span *ngIf="member.labels.length > 0 && hasActiveLabel(member)" class="pl-1 text-red"><i class="fas fa-user-tag" placement="right" container="body" [ngbTooltip]="labelRemarks" tooltipClass="label-tooltip"></i></span
        ></ng-container>
        <ng-container *ngIf="!sqsEnable"
          ><span *ngIf="member.suspicious == 1" class="pl-1 text-danger"><i class="fas fa-exclamation-circle fa-sm"></i> SUSPICIOUS</span></ng-container>
        <div>
          <i class="fa fa-shield-alt" [matTooltip]="member.kyc_progression" [ngClass]="{ 'basic-unverified': member.kyc_progression === 'Basic', 'advance-user': member.kyc_progression === 'Advanced', 'verified-pro': member.kyc_progression === 'Pro' }"></i>
          <i class="fa fa-envelope ml-2" [matTooltip]="(member.email_status === 1 ? 'Verified' : 'Unverified') + ' Email'" [ngClass]="member.email_status === 0 ? 'basic-unverified' : 'verified-pro'"></i>
          <i class="fa fa-mobile-alt ml-2" [matTooltip]="(member.mobile_status === 1 ? 'Verified' : 'Unverified') + ' Mobile Number'" [ngClass]="member.mobile_status === 0 ? 'basic-unverified' : 'verified-pro'"></i>
          <span class="bonus-hunter ml-3">
            <i class="fa fa-font" matTooltip="Same Name" *ngIf="member.name_trace === 1"></i>
            <i class="fa fa-key" matTooltip="Same Password" *ngIf="member.password_trace === 1"></i>
            <i class="fa fa-envelope" matTooltip="Same Email" *ngIf="member.email_trace === 1"></i>
            <i class="fa fa-map-marker-alt" matTooltip="Same IP" *ngIf="member.ip_trace === 1"></i>
            <i class="fa fa-fingerprint" matTooltip="Same Fingerprint" *ngIf="member.fingerprint_trace === 1"></i>
          </span>
          <label *ngIf="member.dummy == 1" class="dummy-tag">DUMMY</label>
        </div>
      </div>
      <span class="modal-x-button" (click)="onCloseDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <mat-tab-group animationDuration="0ms" #tabGroup>
        <ng-container>
          <mat-tab *ngIf="canViewBasicInfo" label="Basic Info">
            <basic-info [member]="member" [mainWallet$]="mainWallet$" [gameWallet]="totalGameBalance"></basic-info>
          </mat-tab>
          <mat-tab *ngIf="canViewWalletInfo" label="Wallet Info">
            <p class="wallet-info__main-wallet">
              <span *ngIf="mainWallet$ | async; let mainWallet"
                ><strong>Main Wallet:</strong>
                {{ mainWallet.balance | number : "1.2-2" }}
              </span>
              <span class="dropdown dropdown-inline">
                <button *ngIf="canCreateGameAccount" type="button" class="btn btn-sm btn-brand mr-2" (click)="onOpenDialog(member, '')">Create Account</button>
                <button *ngIf="canSyncGameWalletBalance" type="button" class="btn btn-sm btn-success" (click)="onSyncAll()">Sync All</button>
              </span>
            </p>
            <div class="table-responsive mb-3">
              <table class="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th>Game Provider</th>
                    <th>Username</th>
                    <th>Password</th>
                    <th class="text-center">Currency</th>
                    <th class="text-center">Balance</th>
                    <th width="180" class="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody *ngIf="gameAccountList$ | async as rows">
                  <tr *ngFor="let game of rows; let i = index">
                    <td>{{ game.game_provider_code }}</td>
                    <td>
                      <div *ngIf="game.provider_type == 2 || game.game_provider_code == 'MEGA'; then thenBlock; else elseBlock"></div>
                      <ng-container>
                        <ng-template #thenBlock>
                          <ng-container *ngIf="new_username !== undefined && new_username !== ''">
                            <a class="text-primary font-weight-bold" (click)="onOpenDialog(member, 'game-log', game)" *ngIf="selectedGame === game.game_provider_code">{{ new_username }} </a>
                            <a class="text-primary font-weight-bold" (click)="onOpenDialog(member, 'game-log', game)" *ngIf="selectedGame !== game.game_provider_code">{{ game.ga_username }} </a>
                          </ng-container>
                          <ng-container *ngIf="new_username === undefined || new_username === ''">
                            <a class="text-primary font-weight-bold" (click)="onOpenDialog(member, 'game-log', game)">{{ game.ga_username }} </a>
                          </ng-container>
                        </ng-template>
                        <ng-template #elseBlock>
                          <ng-container *ngIf="new_username !== undefined && new_username !== ''">
                            <span *ngIf="selectedGame === game.game_provider_code">{{ new_username }}</span>
                            <span *ngIf="selectedGame !== game.game_provider_code">{{ game.ga_username }}</span>
                          </ng-container>
                          <ng-container *ngIf="new_username === undefined || new_username === ''">
                            <span>{{ game.ga_username }}</span>
                          </ng-container>
                        </ng-template>
                      </ng-container>
                    </td>
                    <td>
                      <ng-container *ngIf="new_password !== undefined && new_password !== ''">
                        <span *ngIf="selectedGame === game.game_provider_code">{{ new_password }}</span>
                        <span *ngIf="selectedGame !== game.game_provider_code">{{ game.ga_password }}</span>
                      </ng-container>
                      <ng-container *ngIf="new_password === undefined || new_password === ''">
                        <span>{{ game.ga_password }} </span>
                      </ng-container>
                    </td>
                    <td class="text-center">{{ game.currency_code }}</td>
                    <td class="text-right">
                      <ng-container *ngIf="showLoading">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="game.game_provider_code === currentGameCode"></span>
                      </ng-container>

                      {{ game.balance | number : "1.2-2" }}
                    </td>
                    <td class="text-center">
                      <div class="btn-group dropdown">
                        <button class="btn btn-sm btn-success" type="button" [disabled]="game.status === 2 || game.game_provider_code === resetPasswordGameProvider || game.game_provider_code === resetAccountGameProvider ? true : false" (click)="onSyncPerGame(game.game_provider_code, member.id, i)">Sync Balance</button>
                        <button type="button" [disabled]="game.status === 2 || game.game_provider_code === resetPasswordGameProvider || game.game_provider_code === resetAccountGameProvider ? true : false" class="btn btn-sm btn-success dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <ng-container *ngIf="loading == true && (game.game_provider_code === resetPasswordGameProvider || game.game_provider_code === resetAccountGameProvider)"> <span class="spinner-border spinner-border-sm" aria-hidden="true"></span> &nbsp; </ng-container>
                          <ng-container *ngIf="game.game_provider_code !== resetPasswordGameProvider && game.game_provider_code !== resetAccountGameProvider">
                            <i class="fa fa-angle-down"></i>
                          </ng-container>
                        </button>
                        <div class="dropdown-menu">
                          <button *ngIf="canResetPassword" class="dropdown-item" [disabled]="!game.reset_password" (click)="onResetPassword(game, i)" type="button">Reset Password</button>
                          <button *ngIf="canResetAccount" class="dropdown-item" (click)="onResetAccount(game, i)" type="button">Reset Account</button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <kt-fallback-row [collection]="rows"></kt-fallback-row>
                </tbody>
              </table>
            </div>
          </mat-tab>
          <mat-tab *ngIf="canViewWalletEvents" label="Wallet Events">
            <kt-member-wallet-event [member]="member" [totalGameBalance]="totalGameBalance"></kt-member-wallet-event>
          </mat-tab>
          <mat-tab *ngIf="canViewHistoryMessages" label="History Messages">
            <kt-member-message-history [member]="member"></kt-member-message-history>
          </mat-tab>
          <mat-tab *ngIf="canViewLoginEvents" label="Login Events">
            <div class="row form-group ml-0 mr-0 mb-2">
              <div class="table-responsive">
                <table class="table table-bordered table-hover table-striped">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Login IP</th>
                      <th class="date-column">Login Date</th>
                      <th>Login Domain</th>
                      <th>Status</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="loginEvents$ | async as rows">
                    <tr *ngFor="let row of rows">
                      <td>{{ row.id }}</td>
                      <td>
                        <div class="row">
                          <div class="col d-flex justify-content-between">
                            <span class="pt-2">{{ row.ip }}</span>
                            <button matTooltip="Find Location" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onGetGeoLocation(row.ip)">
                              <i class="fas fa-search"></i>
                            </button>
                          </div>
                        </div>
                        <ng-container *ngIf="row.pseudo_ip">
                          <div class="row border-top">
                            <div class="col d-flex justify-content-between">
                              <span class="pt-2">{{ row.pseudo_ip }}</span>
                              <button matTooltip="Find Location" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onGetGeoLocation(row.pseudo_ip)">
                                <i class="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </ng-container>
                      </td>
                      <td>{{ row.date_time | timezoneDate : "YYYY-MM-DD HH:mm" }}</td>
                      <td>{{ row.domain }}</td>
                      <td>{{ row.success === 1 ? "Success" : "Failed" }}</td>
                      <td>{{ row.remarks }}</td>
                    </tr>
                    <kt-fallback-row [collection]="rows"></kt-fallback-row>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="kt-pagination kt-pagination--brand mb-4" *ngIf="pagination">
              <ul class="kt-pagination__links">
                <li class="kt-pagination__link--first">
                  <a (click)="onViewPageBy(pagination.first_page)"><i class="fa fa-angle-double-left kt-font-brand"></i></a>
                </li>
                <li class="kt-pagination__link--prev">
                  <a (click)="onPreviousPage()"><i class="fa fa-angle-left kt-font-info"></i></a>
                </li>
                <ng-container *ngFor="let page of [].constructor(pagination.last_page); let i = index">
                  <li [ngClass]="i + 1 == currentPage ? 'kt-pagination__link--active' : ''">
                    <a (click)="onViewPageBy(i + 1)">{{ i + 1 }}</a>
                  </li>
                </ng-container>
                <li class="kt-pagination__link--next">
                  <a (click)="onNextPage()"><i class="fa fa-angle-right kt-font-info"></i></a>
                </li>
                <li class="kt-pagination__link--last">
                  <a (click)="onViewPageBy(pagination.last_page)"><i class="fa fa-angle-double-right kt-font-brand"></i></a>
                </li>
              </ul>
              <div class="kt-pagination__toolbar">
                <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                  <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
                </select>
                <span class="pagination__desc"> Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total | number : "1.0" }} records </span>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="canViewBonusHunterAnalysis" label="Bonus Hunter Analysis">
            <kt-bonus-hunter-analysis [member]="member"></kt-bonus-hunter-analysis>
          </mat-tab>
          <mat-tab *ngIf="canViewMemberBankAccounts" label="Member Bank Accounts">
            <kt-member-bank-account [member]="member" [isNeedPagination]="true"></kt-member-bank-account>
          </mat-tab>
          <mat-tab *ngIf="canViewPromotionHistory" label="Promotion History">
            <kt-promotion-history [member]="member"></kt-promotion-history>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
    </div>
  </div>
</div>

<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>

<ng-template #labelRemarks>
  <div *ngIf="member.labels.length > 0">
    <ul>
      <ng-container *ngFor="let item of member.labels">
        <li *ngIf="item.status != 0">{{ item.name }} ( {{ item.default_remark }} )</li>
      </ng-container>
    </ul>
  </div>
</ng-template>
