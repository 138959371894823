import { MemberRemarksListComponent } from '../../../../shared/member-remarks-list/member-remarks-list.component';
import { CurrencySettingsDialogComponent } from './affiliate-groups/dialogs/currency-settings/currency-settings.component';
import { SettlementSettingsComponent } from './affiliate-groups/dialogs/settlement-settings/settlement-settings.component';
import { AllAffiliateMemberDialogComponent } from './../../../../shared/all-affiliate-member-dialog/all-affiliate-member-dialog.component';
import { PdfDialogComponent } from './../../../../shared/pdf-dialog/pdf-dialog.component';
import { AffiliateAdjustmentDialogComponent } from './affiliate-adjustments/dialogs/affiliate-adjustments-edit.component';
import { AffiliateAdjustmentsComponent } from './affiliate-adjustments/affiliate-adjustments.component';
import { AdjustGroupDialogComponent } from './all-affiliates/dialogs/adjust-group/adjust-group.component';
import { AffiliateApprovalComponent } from './affiliate-approval/affiliate-approval.component';
import { AffiliateApprovalEditDialogComponent } from './affiliate-approval/dialog/affiliate-approval-edit.component';
import { AffiliateDepositDetailsComponent } from './affiliate-deposits/dialog/deposit-details.component';
import { AffiliateDepositsComponent } from './affiliate-deposits/affiliate-deposits.component';
import { AffiliateInformationComponent } from './all-affiliates/dialogs/affiliate-information/affiliate-information.component';
import { AffiliateMemberComponent } from './all-affiliates/dialogs/affiliate-member/affiliate-member.component';
import { AffiliateWithdrawalsComponent } from './affiliate-withdrawals/affiliate-withdrawals.component';
import { AffiliateWithdrawEditDialogComponent } from './affiliate-withdrawals/dialog/affiliate-withdraw-edit.component';
import { AgentComponent } from './agent/agent.component';
import { AgentEditDialogComponent } from './agent/dialogs/agent-edit.component';
import { AgentTreeViewComponent } from './agent-tree-view/agent-tree-view.component';
import { AllAffiliatesComponent } from './all-affiliates/all-affiliates.component';
import { AllAffiliatesEditDialogComponent } from './all-affiliates/dialogs/all-affiliates-edit/all-affiliates-edit.component';
import { AllAffiliatesPasswordDialogComponent } from './all-affiliates/dialogs/all-affiliates-password/all-affiliates-password.component';
import { AllEventsComponent } from './all-events/all-events.component';
import { AllEventsEditDialogComponent } from './all-events/dialogs/all-events-edit/all-events-edit.component';
import { AllLeadsAssignDialogComponent } from './all-leads/dialogs/all-leads-assign/all-leads-assign.component';
import { AllLeadsImportDialogComponent } from './all-leads/dialogs/all-leads-import/all-leads-import.component';
import { AffiliateSettingsDialogComponent } from './all-leads/dialogs/affiliate-settings/affiliate-settings.component';
import { AffiliateSettingsAddDialogComponent } from './all-leads/dialogs/affiliate-settings-add/affiliate-settings-add.component';
import { AllLeadsComponent } from './all-leads/all-leads.component';
import { AllPredictionsComponent } from './all-predictions/all-predictions.component';
import { AllTicketsComponent } from './all-tickets/all-tickets.component';
import { BankReceiptBrowserDialogComponent } from '../settings/bank-receipts/dialogs/bank-receipt-browser/bank-receipt-browser.component';
import { BetLimitDialogComponent } from './groups/dialogs/bet-limit/bet-limit.component';
import { CallLogDialogComponent } from './my-leads/dialogs/call-logs/call-log.component';
import { CCIDLookupDialogComponent } from './ccid-lookup/ccid-lookup.component';
import { CommissionReleasesComponent } from './commission-releases/commission-releases.component';
import { DepositComponent } from './deposits/deposit.component';
import { DepositDetailsComponent } from './deposits/dialogs/deposit-details/deposit-details.component';
import { DepositLimitDialogComponent } from './groups/dialogs/deposit-limit/deposit-limit.component';
import { GameProviderBlacklistComponent } from './promotion-codes/dialogs/game-provider-blacklist/game-provider-blacklist.component';
import { GameProviderNewBlacklistComponent } from './promotion-codes/dialogs/game-provider-new-blacklist/game-provider-new-blacklist.component';
import { GameProviderEditDialogComponent } from '../games/game-providers/dialogs/game-provider-edit.component';
import { GenerateCommissionComponent } from './commission-releases/dialogs/generate-commission/generate-commission.component';
import { GroupComponent } from './groups/group.component';
import { GroupEditDialogComponent } from './groups/dialogs/group-edit.component';
import { LuckyDrawDialogComponent } from './lucky-draw-settings/dialogs/lucky-draw-edit.component';
import { LuckyDrawSettingsComponent } from './lucky-draw-settings/lucky-draw-settings.component';
import { MemberBankAccountEditDialogComponent } from './members/dialogs/member-bank-account/member-bank-account-edit/member-bank-account-edit.component';
import { MemberBlacklistDialogComponent } from './members/dialogs/member-blacklist/member-blacklist.component';
import { MemberComponent } from './members/member.component';
import { MemberDeductDialogComponent } from './members/dialogs/member-deduct/member-deduct.component';
import { MemberGameAccountDialogComponent } from './members/dialogs/member-information/member-game-account/member-game-account.component';
import { MemberGameLogDialogComponent } from './members/dialogs/member-game-log/member-game-log.component';
import { MemberGameLogDetailsDialogComponent } from './members/dialogs/member-game-log-details/member-game-log-details.component';
import { MemberInformationDialogComponent } from './members/dialogs/member-information/member-information.component';
import { MemberOnlineComponent } from './member-online/member-online.component';
import { MemberPasswordDialogComponent } from './members/dialogs/member-password/member-password.component';
import { MemberProfileDialogComponent } from './members/dialogs/member-profile/member-profile.component';
import { MemberPromotionComponent } from './member-promotions/member-promotion.component';
import { MemberPromotionEditDialogComponent } from './member-promotions/dialogs/member-promotion-edit.component';
import { MemberRemarksHistoryComponent } from './members/dialogs/member-remarks-history/member-remarks-history.component';
import { MemberRewardsDialogComponent } from './members/dialogs/member-rewards/member-rewards.component';
import { MemberTopupDialogComponent } from './members/dialogs/member-topup/member-topup.component';
import { MemberTraceComponent } from './member-trace/member-trace.component';
import { MemberVerificationComponent } from './member-verification/member-verification.component';
import { MemberVerificationDetailsComponent } from './member-verification/dialogs/member-verification-details/member-verification-details.component';
import { MerchantBanksDialogComponent } from '../settings/merchant-banks/dialogs/merchant-banks/merchant-banks.component';
import { MyLeadsComponent } from './my-leads/my-leads.component';
import { NgModule } from '@angular/core';
import { PromotionApplicationComponent } from './promotion-application/dialogs/promotion-application.component';
import { PromotionApplicationWrapperComponent } from './promotion-application/promotion-application-wrapper.component';
import { PromotionCategoryComponent } from './promotion-content-category/promotion-category.component';
import { PromotionCategoryM7Component } from './promotion-content-category-m7/promotion-category-m7.component';
import { PromotionCategoryEditDialogComponent } from './promotion-content-category/dialogs/promotion-category-edit.component';
import { PromotionCategoryM7EditDialogComponent } from './promotion-content-category-m7/dialogs/promotion-category-m7-edit.component';
import { PromotionCodeComponent } from './promotion-codes/promotion-code.component';
import { PromotionCodeDetailsDialogComponent } from './promotion-codes/dialogs/promotion-code-details/promotion-code-details';
import { PromotionContentComponent } from './promotion-contents/promotion-content.component';
import { PromotionContentEditDialogComponent } from './promotion-contents/dialogs/promotion-content-edit.component';
import { PromotionCurrencyComponent } from './promotion-currency/promotion-currency.component';
import { PromotionCurrencyDetailsComponent } from './promotion-currency/dialogs/promotion-currency-details.component';
import { PromotionMemberListComponent } from './promotion-member-list/promotion-member-list.component';
import { PromotionMemberDetailsComponent } from './promotion-member-list/dialogs/promotion-member-list-details.component';
import { PromotionMessageDetailsComponent } from './promotion-codes/dialogs/promotion-message-details/promotion-message-details.component';
import { PromotionMessagesComponent } from './promotion-codes/dialogs/promotion-messages/promotion-messages.component';
import { PromotionStatisticComponent } from './promotion-statistic/promotion-statistic.component';
import { RebateSettingsDialogComponent } from './groups/dialogs/rebate-settings/rebate-settings.component';
import { RecoveryCurrencyComponent } from './recovery-promotion/recovery-currency/recovery-currency.component';
import { RecoveryCurrencyDetailsComponent } from './recovery-promotion/recovery-currency/dialogs/recovery-currency-details.component';
import { RecoveryMessageDetailsComponent } from './recovery-promotion/recovery-message-details/recovery-message-details.component';
import { RecoveryMessagesComponent } from './recovery-promotion/recovery-messages/recovery-messages.component';
import { RecoveryPromotionComponent } from './recovery-promotion/recovery-promotion.component';
import { RecoveryPromotionEditDialogComponent } from './recovery-promotion/dialogs/recovery-promotion-edit.component';
import { ReferralsComponent } from './referrals/referrals.component';
import { ReferralSettingsComponent } from './referral-settings/referral-settings.component';
import { ReferralSettingsEditDialogComponent } from './referral-settings/dialogs/referral-settings-edit.component';
import { ReleaseCommissionsDialogComponent } from './commission-releases/dialogs/release-commissions/release-commissions.component';
import { RemarksHistoryComponent } from '../settings/merchant-banks/dialogs/remarks-history/remarks-history.component';
import { ReuploadDocumentDialogComponent } from './members/dialogs/member-bank-account/reupload-document/reupload-document.component';
import { RewardDetailsDialogComponent } from './rewards/dialogs/reward-details/reward-details.component';
import { RewardsComponent } from './rewards/rewards.component';
import { RewardsSettingsDialogComponent } from './lucky-draw-settings/dialogs/rewards-settings-edit/rewards-settings-edit.component';
import { Routes, RouterModule } from '@angular/router';
import { StatusRemarksComponent } from './../settings/merchant-banks/dialogs/status-remarks/status-remarks.component';
import { TelemarketerRewardsComponent } from './telemarketer-rewards/telemarketer-rewards.component';
import { TelemarketerRewardDetailsDialogComponent } from './telemarketer-rewards/dialogs/telemarketer-reward-details/telemarketer-reward-details.component';
import { TelemarketerSettingsComponent } from './telemarketer-settings/telemarketer-settings.component';
import { TelemarketerSettingsEditDialogComponent } from './telemarketer-settings/dialogs/telemarketer-settings-edit/telemarketer-settings-edit.component';
import { TelemarketerSettingsLogComponent } from './telemarketer-settings/dialogs/telemarketer-settings-log/telemarketer-settings-log.component';
import { TransactionComponent } from './transactions/transaction.component';
import { TransactionWalletDialogComponent } from './transactions/dialogs/transaction-wallet/transaction-wallet.component';
import { TransferComponent } from './transfers/transfer.component';
import { TransferEditDialogComponent } from './transfers/dialogs/transfer-edit.component';
import { UpdateResultDialogComponent } from './all-events/dialogs/update-result/update-result.component';
import { WithdrawalComponent } from './withdrawals/withdrawal.component';
import { WithdrawalEditDialogComponent } from './withdrawals/dialogs/withdrawal-single/withdrawal-edit.component';
import { WithdrawalLimitDialogComponent } from './groups/dialogs/withdrawal-limit/withdrawal-limit.component';
import { AffiliateTransfersComponent } from './affiliate-transfers/affiliate-transfers.component';
import { AffiliateGroupsEditDialogComponent } from './affiliate-groups/dialogs/affiliate-groups-edit/affiliate-groups-edit.component';
import { AffiliateGroupsComponent } from './affiliate-groups/affiliate-groups.component';
import { TierRateSettingsDialogComponent } from './affiliate-groups/dialogs/tier-rate-settings/tier-rate-settings.component';
import { CommissionSettingsDialogComponent } from './affiliate-groups/dialogs/commission-settings/commission-settings.component';
import { AllLeadsCallLogComponent } from './all-leads/dialogs/all-leads-call-log/all-leads-call-log.component';
import { LiveChatComponent } from './live-chat/live-chat.component';
import { SampleMessageComponent } from './sample-messages/sample-message.component';
import { SampleMessageEditDialogComponent } from './sample-messages/dialogs/sample-message-edit.component';
import { SubAffiliateComponent } from './all-affiliates/dialogs/sub-affiliate/sub-affiliate.component';
import { RemarkDialogComponent } from './all-leads/dialogs/remark/remark.component';
import { ShowRemarkComponent } from './all-leads/dialogs/show-remark/show-remark.component';
import { EventTeamsDialogComponent } from './all-events/dialogs/event-teams/event-teams.component';
import { EventTeamsEditDialogComponent } from './all-events/dialogs/event-teams-edit/event-teams-edit.component';
import { MemberLabelComponent } from './members/dialogs/member-label/member-label.component';
import { MemberLabelEditComponent } from './members/dialogs/member-label/member-label-edit/member-label-edit.component';
import { ImportRewardsDialogComponent } from './rewards/dialogs/import-rewards/import-rewards.component';
import { LeadRemarksComponent } from './lead-remarks/lead-remarks.component';
import { ShowEvidenceComponent } from './my-leads/dialogs/show-evidence/show-evidence.component';
import { RequestJustificationDialogComponent } from './all-leads/dialogs/request-justification/request-justification.component';
import { RequestReceiptDialogComponent } from '@shared/request-receipt-dialog/request-receipt-dialog.component';
import { UrlTestFireDialogComponent } from './all-affiliates/dialogs/url-test-fire/url-test-fire.component';
import { ShowRemarkHistoryComponent } from './deposits/dialogs/show-remark-history/show-remark-history.component';
import { ViewReceiptComponent } from './deposits/dialogs/view-receipt/view-receipt.component';
import { StatusCheckDialogComponent } from './transfers/dialogs/status-check/status-check.component';
import { CreateMemberDialogComponent } from './my-leads/dialogs/create-member/create-member.component';
import { UploadReceiptComponent } from './deposits/dialogs/upload-receipt/upload-receipt.component';
import { MemberPromotionHistoryComponent } from './members/dialogs/member-promotion-history/member-promotion-history.component';
import { TestFirePostbackLogsComponent } from './all-affiliates/dialogs/test-fire-postback-logs/test-fire-postback-logs.component';
import { MemberCryptoAccountEditDialogComponent } from './members/dialogs/member-bank-account/member-crypto-account-edit/member-crypto-account-edit.component';
import { WithdrawalWrapperComponent } from './withdrawals/withdrawal-wrapper/withdrawal-wrapper.component';
import { MemberPromotionEligibleDialogComponent } from './members/dialogs/member-promotion-eligible/member-promotion-eligible.component';
import { PromotionCodeMemberEligibleDialogComponent } from './promotion-codes/dialogs/promotion-code-member-eligible/promotion-code-member-eligible.component';
import { PromotionContentMemberEligibleDialogComponent } from './promotion-contents/dialogs/promotion-content-member-eligible/promotion-content-member-eligible.component';
import { RewardMemberEligibleDialogComponent } from './rewards/dialogs/reward-member-eligible/reward-member-eligible.component';
import { DepositWrapperComponent } from './deposits/deposit-wrapper/deposit-wrapper.component';
import { UndoLeadsDialogComponent } from './all-leads/dialogs/undo-lead/undo-lead.component';
import { ReassignLeadsDialogComponent } from './all-leads/dialogs/reassign-lead/reassign-lead.component';
import { AffiliateGroupsDialogBulkAddComponent } from './affiliate-groups/dialogs/affiliate-groups-edit/dialogs/affiliate-groups-dialog-bulk-add.component';
import { AccountManagementComponent } from './account-management/account-management.component';
import { AccountManagementLogDialogComponent } from './account-management/dialogs/account-managment-log/account-management-log-dialog.component';
import { AccountManagementKpiSettingsDialogComponent } from './account-management/dialogs/kpi-settings/kpi-settings-dialog.component';
import { AssignLeadDialogComponent } from './account-management/dialogs/assign-lead/assign-lead-dialog.component';
import { AccountManagementRemarkComponent } from './account-management/dialogs/account-management-remark/account-management-remark.component';
import { ViewAttachmentComponent } from './account-management/dialogs/view-attachment/view-attachment.component';
import { MemberVerificationWrapperComponent } from './member-verification/member-verification-wrapper/member-verification-wrapper.component';
import { ShowDocumentComponent } from './member-bank-account-verification/dialogs/show-document/show-document.component';
import { MemberBankAccountVerificationDetailsComponent } from './member-bank-account-verification/dialogs/member-bank-account-verification-details/member-bank-account-verification-details.component';
import { MemberBankAccountVerificationRemarkComponent } from './member-bank-account-verification/dialogs/member-bank-account-verification-remark/member-bank-account-verification-remark.component';

const routes: Routes = [
  {
    path: 'members',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: MemberComponent
      },
    ]
  },
  {
    path: 'member-groups',
    component: GroupComponent
  },
  {
    path: 'member-verification',
    component: MemberVerificationWrapperComponent
  },

  // AIO-6758 -- Hide per request
  // {
  //   path: 'vip-verification',
  //   component: VipVerificationComponent
  // },
  {
    path: 'member-online',
    component: MemberOnlineComponent
  },
  {
    path: 'member-trace',
    component: MemberTraceComponent
  },
  {
    path: 'referrals',
    component: ReferralsComponent
  },
  {
    path: 'deposits',
    component: DepositWrapperComponent
  },
  {
    path: 'withdrawals',
    component: WithdrawalWrapperComponent
  },
  {
    path: 'transfers',
    component: TransferComponent
  },
  {
    path: 'transactions',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: TransactionComponent
      },
    ]
  },
  {
    path: 'member-promotions',
    component: MemberPromotionComponent
  },
  {
    path: 'promotion-codes',
    component: PromotionCodeComponent
  },
  {
    path: 'promotion-contents',
    component: PromotionContentComponent
  },
  {
    path: 'promotion-statistics',
    component: PromotionStatisticComponent
  },
  {
    path: 'recovery-promotions',
    component: RecoveryPromotionComponent
  },
  {
    path: 'promotion-category',
    component: PromotionCategoryComponent
  },
  {
    path: 'promotion-content-category',
    component: PromotionCategoryM7Component
  },
  {
    path: 'rewards',
    component: RewardsComponent
  },
  {
    path: 'referral-settings',
    component: ReferralSettingsComponent
  },
  {
    path: 'lucky-draw-settings',
    component: LuckyDrawSettingsComponent
  },
  {
    path: 'promotion-applications',
    component: PromotionApplicationWrapperComponent
  },
  {
    path: 'agents',
    component: AgentComponent
  },
  {
    path: 'agent-tree-view',
    component: AgentTreeViewComponent
  },
  {
    path: 'all-tickets',
    component: AllTicketsComponent
  },
  {
    path: 'all-predictions',
    component: AllPredictionsComponent
  },
  {
    path: 'all-events',
    component: AllEventsComponent
  },
  {
    path: 'all-leads',
    component: AllLeadsComponent
  },
  {
    path: 'account-management',
    component: AccountManagementComponent
  },
  {
    path: 'commission-releases',
    component: CommissionReleasesComponent
  },
  {
    path: 'all-affiliates',
    component: AllAffiliatesComponent
  },
  {
    path: 'affiliate-withdrawals',
    component: AffiliateWithdrawalsComponent
  },
  {
    path: 'affiliate-deposits',
    component: AffiliateDepositsComponent
  },
  {
    path: 'affiliate-approval',
    component: AffiliateApprovalComponent
  },
  {
    path: 'affiliate-transfers',
    component: AffiliateTransfersComponent
  },
  {
    path: 'affiliate-adjustments',
    component: AffiliateAdjustmentsComponent
  },
  {
    path: 'affiliate-groups',
    component: AffiliateGroupsComponent
  },
  {
    path: 'live-chat',
    component: LiveChatComponent
  },
  {
    path: 'sample-messages',
    component: SampleMessageComponent
  },
  {
    path: 'my-leads',
    component: MyLeadsComponent
  },
  {
    path: 'lead-remarks',
    component:  LeadRemarksComponent
  },
  {
    path: 'telemarketer-rewards',
    component: TelemarketerRewardsComponent
  },
  {
    path: 'telemarketer-settings',
    component: TelemarketerSettingsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  entryComponents: [
    AffiliateAdjustmentDialogComponent,
    AdjustGroupDialogComponent,
    AffiliateApprovalEditDialogComponent,
    AffiliateDepositDetailsComponent,
    AffiliateInformationComponent,
    AffiliateMemberComponent,
    AffiliateWithdrawEditDialogComponent,
    AgentEditDialogComponent,
    AllAffiliatesEditDialogComponent,
    AllAffiliatesPasswordDialogComponent,
    AllEventsEditDialogComponent,
    AllLeadsAssignDialogComponent,
    AllLeadsImportDialogComponent,
    AffiliateSettingsDialogComponent,
    AffiliateSettingsAddDialogComponent,
    BankReceiptBrowserDialogComponent,
    BetLimitDialogComponent,
    CallLogDialogComponent,
    CCIDLookupDialogComponent,
    DepositDetailsComponent,
    DepositLimitDialogComponent,
    GameProviderBlacklistComponent,
    GameProviderNewBlacklistComponent,
    GameProviderEditDialogComponent,
    GenerateCommissionComponent,
    GroupEditDialogComponent,
    LuckyDrawDialogComponent,
    LiveChatComponent,
    SampleMessageComponent,
    // SampleMessageDetailsComponent,
    MemberBankAccountEditDialogComponent,
    MemberCryptoAccountEditDialogComponent,
    MemberBlacklistDialogComponent,
    MemberDeductDialogComponent,
    MemberGameAccountDialogComponent,
    MemberGameLogDialogComponent,
    MemberGameLogDetailsDialogComponent,
    MemberInformationDialogComponent,
    MemberPasswordDialogComponent,
    MemberProfileDialogComponent,
    MemberPromotionEditDialogComponent,
    MemberRemarksHistoryComponent,
    MemberRewardsDialogComponent,
    MemberRewardsDialogComponent,
    MemberTopupDialogComponent,
    MemberVerificationDetailsComponent,
    MerchantBanksDialogComponent,
    PromotionApplicationComponent,
    PromotionCategoryEditDialogComponent,
    PromotionCategoryM7EditDialogComponent,
    PromotionCodeDetailsDialogComponent,
    PromotionContentEditDialogComponent,
    PromotionCurrencyComponent,
    PromotionCurrencyDetailsComponent,
    PromotionMemberListComponent,
    PromotionMemberDetailsComponent,
    PromotionMessageDetailsComponent,
    PromotionMessagesComponent,
    RebateSettingsDialogComponent,
    RecoveryCurrencyComponent,
    RecoveryCurrencyDetailsComponent,
    RecoveryMessageDetailsComponent,
    RecoveryMessagesComponent,
    RecoveryPromotionEditDialogComponent,
    ReferralSettingsEditDialogComponent,
    ReleaseCommissionsDialogComponent,
    RemarkDialogComponent,
    AccountManagementLogDialogComponent,
    AccountManagementKpiSettingsDialogComponent,
    AssignLeadDialogComponent,
    AccountManagementRemarkComponent,
    ViewAttachmentComponent,
    RequestJustificationDialogComponent,
    RemarksHistoryComponent,
    ReuploadDocumentDialogComponent,
    RewardDetailsDialogComponent,
    RewardsComponent,
    RewardsSettingsDialogComponent,
    StatusRemarksComponent,
    ShowRemarkComponent,
    ShowRemarkHistoryComponent,
    TransactionWalletDialogComponent,
    TransferEditDialogComponent,
    UpdateResultDialogComponent,
    WithdrawalEditDialogComponent,
    WithdrawalLimitDialogComponent,
    PdfDialogComponent,
    AllAffiliateMemberDialogComponent,
    AffiliateGroupsEditDialogComponent,
    CommissionSettingsDialogComponent,
    TierRateSettingsDialogComponent,
    CurrencySettingsDialogComponent,
    AllLeadsCallLogComponent,
    SampleMessageEditDialogComponent,
    RequestReceiptDialogComponent,
    SettlementSettingsComponent,
    SubAffiliateComponent,
    MemberRemarksListComponent,
    EventTeamsDialogComponent,
    EventTeamsEditDialogComponent,
    MemberLabelComponent,
    MemberLabelEditComponent,
    ImportRewardsDialogComponent,
    ShowEvidenceComponent,
    CreateMemberDialogComponent,
    UrlTestFireDialogComponent,
    ShowEvidenceComponent,
    ViewReceiptComponent,
    UploadReceiptComponent,
    StatusCheckDialogComponent,
    MemberPromotionHistoryComponent,
    TestFirePostbackLogsComponent,
    MemberPromotionEligibleDialogComponent,
    PromotionCodeMemberEligibleDialogComponent,
    PromotionContentMemberEligibleDialogComponent,
    RewardMemberEligibleDialogComponent,
    TelemarketerRewardsComponent,
    TelemarketerRewardDetailsDialogComponent,
    UndoLeadsDialogComponent,
    ReassignLeadsDialogComponent,
    TelemarketerSettingsComponent,
    TelemarketerSettingsEditDialogComponent,
    TelemarketerSettingsLogComponent,
    AffiliateGroupsDialogBulkAddComponent,
    ShowDocumentComponent,
    MemberBankAccountVerificationDetailsComponent,
    MemberBankAccountVerificationRemarkComponent,
  ]
})
export class GeneralRoutingModule { }
