import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { RecoveryMessage } from '@core/models/recovery-message.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RecoveryMessageDetailsComponent } from '../recovery-message-details/recovery-message-details.component';
import { RecoveryMessageDataService } from './services/recovery-message-data.service';
import { RecoveryMessageEntityService } from './services/recovery-message-entity.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-recovery-messages',
  templateUrl: './recovery-messages.component.html',
  styleUrls: ['./recovery-messages.component.scss']
})
export class RecoveryMessagesComponent implements OnInit, OnDestroy {

  dropdown = {
    perPage: this.dropdownHttpService.perPage
  };
  recoveryMessages$: Observable<RecoveryMessage[]>;
  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = `recovery_promotion_setting_id=${this.data.recoveryId}`;

  // permissions
  canAddRecoveryMessage: boolean;
  canEditRecoveryMessage: boolean;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { recoveryId: number },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RecoveryMessageDetailsComponent>,
    private recoveryMessageSerivce: RecoveryMessageEntityService,
    private recoveryMessageDataService: RecoveryMessageDataService,
    private dropdownHttpService: DropdownHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.reload();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canAddRecoveryMessage = appPermissions.add_recovery_messages;
      this.canEditRecoveryMessage = appPermissions.edit_recovery_messages;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onOpenDialog(mode: string, recoveryMessage?: RecoveryMessage) {
    this.dialog.open(RecoveryMessageDetailsComponent, {
      width: '1300px',
      data: {
        recoveryMessage,
        mode,
        recoveryId: this.data.recoveryId
      }
    }).afterClosed().subscribe(res => this.reload());
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    return this.recoveryMessages$ = this.recoveryMessageDataService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.pagination = this.recoveryMessageDataService.pagination;
      })
    );
  }

  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }


  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key =>  (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all') ? fields[key] = formData[key] : key);
    return fields;
  }

  private reload() {
    this.onViewPageBy(this.page);
  }

}
