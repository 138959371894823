<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog tooltip-container">
    <form class="kt-form w-webkit-fill-available" [formGroup]="form">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">{{ data.mode | titlecase }} Credentials</h5>
              <span class="modal-x-button" (click)="onCloseDialog()">
                  <i class="fa fa-times" aria-hidden="true"></i>
              </span>
          </div>
          <div class="modal-body">
            <!-- <div class="col-md-12 form-group row">
                <label class="col-4 col-form-label control-label">Code </label>
                <input class="col-8 form-control" type="text" formControlName="code" disabled />
            </div> -->
            <div class="col-md-12 form-group row">
                <!-- custom code but labelled as code -->
                <label class="col-4 col-form-label control-label">Code <span class="text-danger"> * </span> </label>
                <input class="col-8 form-control" type="text" formControlName="custom_code" [attr.disabled]="data.credentials.internal == 1 ? '' : null" />
            </div>
            <div class="col-md-12 form-group row">
                <label class="col-4 col-form-label control-label">Name <span class="text-danger"> * </span> </label>
                <input class="col-8 form-control" type="text" formControlName="name" />
            </div>
            <div class="col-md-12 form-group row">
                <label class="col-4 col-form-label control-label">Region <span class="text-danger"> * </span> </label>
                <div class="col-8 p-0">
                    <kt-dropdown-wo-lazyload
                        [form] = 'form'
                        [dropdownList] = 'dropdown.region'
                        [dropdownSettings] = 'regionDropdownSettings'
                        [formName] = "'regions'"
                        [selectionAttributes] = "'country_code'"
                        [selectedItems] = 'regionSelectedItems'>
                    </kt-dropdown-wo-lazyload>
                </div>
            </div>
            <div class="col-md-12 form-group row">
                <label class="col-4 col-form-label control-label">Credentials <span class="text-danger"> * </span>
                    <i class="mx-2 fas fa-info-circle" placement="right" [ngbTooltip]="description" tooltipClass="credential-tooltip" container=".tooltip-container"></i>
                </label>
                <div class="col-8 p-0">
                    <textarea class="form-control" formControlName="credentials"></textarea>
                    <span class="p-1"*ngIf="formatAlert">Invalid Credentials format.</span>
                </div>
            </div>
            <div class="col-md-12 form-group row">
                <label class="col-4 col-form-label control-label">Merchant ID </label>
                <input class="col-8 form-control" type="text" formControlName="merchant_id" />
            </div>
            <!-- <div class="col-md-12 form-group row">
                <label class="col-4 col-form-label control-label">Min Balance </label>
                <input class="col-8 form-control" type="number" formControlName="balance_limit" />
            </div> -->
            <div class="col-md-12 form-group row">
                <label class="col-4 col-form-label control-label">Status</label>
                <select class="col-8 form-control" formControlName="status">
                    <option *ngFor="let value of dropdown.status; let i = index;" [value]="i">{{ value }}</option>
                </select>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
            <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave()"></kt-submit-button>
          </div>
      </div>
    </form>
  </div>
  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog(true)"></kt-swal-alert>
  
  <!-- Description for Tooltip Icon on Credentials Field -->
  <ng-template #description>
    <div class="text-left">
        <u class="font-weight-bold">Example</u> <br>
        <span class="font-weight-bold">API Key:</span> 12345678 <br>
        <span class="font-weight-bold">API Secret:</span> abcd123xyz 
        <br><br>
        If the text body displays: {{ '{' }} "api_key":"", "api_secret":"" {{ '}' }}, input the API Key and API Secret into the corresponding empty field: 
        <br><br>
        <ul class="mb-0">
            <li>Input "12345678" into the empty field that corresponds to "api_key".</li>
            <li>Input "abcd123xyz" into the empty field that corresponds to "api_secret".</li>
        </ul>
        <br>
        The resulting text in the text box should be: <br>
        {{ '{' }} "api_key":"12345678", "api_secret":"abcd123xyz" {{ '}' }}.
        <br><br>
        <span class="text-red font-weight-bold">* Please note that it is important to input the text exactly as shown, including the use of quotation marks and colons.</span>    
    </div>
  </ng-template>