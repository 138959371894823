<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
  <div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__body">
      <div class="kt-form kt-form--label-right  mb-2">
        <div class="row align-items-center">
          <div class="col-xl-12 order-2 order-xl-1 pr-0">
            <form class="row align-items-center" [formGroup]="form">
              <!-- FIRST ROW -->
              <div class="col-12 row mb-2 pr-0">
                <!-- USERNAME -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Username:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                </div>
                <!-- STATUS -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Status:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select class="form-control" formControlName="status">
                    <option value="all"> All </option>
                    <ng-container *ngFor="let item of messageStatus | keyvalue">
                      <option [value]="item.key" *ngIf="+item.key >= 0">{{ item.value }}</option>
                    </ng-container>
                  </select>
                </div>
                <!-- DATE TIME -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Date/Time:</label>
                </div>
                <div class="col-md-5 kt-form__control">
                  <div class="input-group date">
                    <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [opens]="'left'" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                    <span class="input-group-append">
                      <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                    </span>
                  </div>
                </div>
              </div>
              <!-- SECOND ROW -->
              <div class="col-12 row mb-2 pr-0">
                <!-- KEYWORD -->
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>Keyword:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="keyword" placeholder="Search" class="form-control">
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-3">
        <div class="dropdown dropdown-inline">
          <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
          <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
        </div>
        <!-- TODO: old FE UseNewPermissions logic, remove if BE version below is stable -->
        <!-- <a [routerLink]="['/sms/send']" *ngIf="allSMSUseNewPermissions ? canSendMessages : sendMessagePermission"><button class="btn btn-brand btn-icon-sm"><i class="fa fa-paper-plane"></i>Send Message</button></a> -->
        <a [routerLink]="['/sms/send']" *ngIf="canSendMessages"><button class="btn btn-brand btn-icon-sm"><i class="fa fa-paper-plane"></i>Send Message</button></a>
      </div>
      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped table-sortable">
              <thead>
                <tr>
                  <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                  <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                  <th class="sort-enabled" (click)="onSortColumn('title')" [ngClass]="sortingConfig.title" [class.sort-selected]="sortingSelection.sort_by === 'title'">Title</th>
                  <th class="sort-enabled" (click)="onSortColumn('content')" [ngClass]="sortingConfig.content" [class.sort-selected]="sortingSelection.sort_by === 'content'">Content</th>
                  <th class="text-center sort-enabled" (click)="onSortColumn('status')" [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status</th>
                  <th class="date-column sort-enabled" (click)="onSortColumn('created_at')" [ngClass]="sortingConfig.created_at" [class.sort-selected]="sortingSelection.sort_by === 'created_at'">Created At</th>
                  <th class="date-column sort-enabled" (click)="onSortColumn('updated_at')" [ngClass]="sortingConfig.updated_at" [class.sort-selected]="sortingSelection.sort_by === 'updated_at'">Updated At</th>
                  <!-- <th class="text-center">Actions</th> -->
                </tr>
              </thead>
              <tbody *ngIf="(allSmsMessages$ | async) as rows">
                <tr *ngFor="let row of rows">
                  <td>{{ row.id }}</td>
                  <td><a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog('member-information', { id: row.member_account_id })">{{ row.username }}</a></td>
                  <td>{{ row.title }}</td>
                  <td>
                    <div class="content-element" [@panelState]="row.id == expandedElement || stripHTMLTags(row.content).length < 300 ? 'open' : 'closed'" [innerHTML]="onContent(row.content)"></div>
                    <span class="content-span" *ngIf="stripHTMLTags(row.content).length >= 300" (click)="expandedElement = expandedElement === row.id ? null : row.id; isReadMore = expandedElement === row.id ? '[Read Less]' : '[Read More...]'">
                        {{ row.id == expandedElement ? '[Read Less]' : '[Read More...]' }}
                    </span>
                  </td>
                  <td class="text-center">
                    <ng-container *ngIf="row.status === 1; else elseTemplate">
                      <i class="fas fa-check-double text-success" matTooltip="Seen"></i>
                    </ng-container>
                    <ng-template #elseTemplate>
                      <i class="fas fa-check text-secondary" matTooltip="Sent"></i>
                    </ng-template>
                  </td>
                  <td>
                    <span *ngIf="row.created_by !== null">{{ row.created_by }}<br /></span>
                    {{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}
                  </td>
                  <td>{{ row.status === 1 ? (row.updated_at | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</td>
                  <!-- <td class="text-center">
                    <button matTooltip="Send Message" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('send-message', row.id, { id: row.member_account_id, username: row.username })"><i class="fas fa-envelope"></i></button>
                  </td> -->
                </tr>
              </tbody>
              <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
            </table>
          </div>
          <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
              <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
              </select>
              <span class="pagination__desc">
                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
