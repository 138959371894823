<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1 pr-0">
                        <form class="row align-items-center" [formGroup]="form">
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Campaign Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="campaign_name" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Country:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="country" placeholder="Search" class="form-control">
                                </div>
                            </div>
                            <div class="col-12 row mb-2 pr-0">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group date">
                                        <input type="text" class="form-control" placeholder="Search"
                                            (change)="onDateRange($event)" formControlName="defaultDate"
                                            [timePicker]="true" [timePickerSeconds]="true"
                                            [alwaysShowCalendars]="true" [ranges]="ranges"
                                            [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
                                            [timePicker24Hour]="true" [showDropdowns]="true" [opens]="'left'" (click)="updateDateRange()"
                                        ngxDaterangepickerMd/>
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>{{ 'Timezone' }}:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <kt-dropdown-wo-lazyload
                                        #timezone
                                        (click)="onChangeTimezone(timezone.selectedItems)"
                                        [form] = 'form'
                                        [dropdownList] = 'dropdown.timezones'
                                        [dropdownSettings] = 'timezoneDropdownSettings'
                                        [formName] = "'timezone'"
                                        [selectionAttributes] = "'timezone'"
                                        [selectedItems] = 'timezoneSelectedItems'>
                                    </kt-dropdown-wo-lazyload>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                <div>
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canExportAdCost" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="button_loading">
                    <i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export
                </button>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>Country</th>
                                    <th>Campaign ID</th>
                                    <th class="campaign-column">Campaign Name</th>
                                    <th class="text-center">Registration Count</th>
                                    <th class="text-center">Clicks Count</th>
                                    <th class="text-center">Deposit (Local CCY)</th>
                                    <th class="text-center">Deposit Count</th>
                                    <th class="text-center">New Deposit (Local CCY)</th>
                                    <th class="text-center">New Deposit Count</th>
                                    <th class="text-center">Average Deposit Size</th>
                                    <th class="text-center">Ad Cost (USD)</th>
                                    <th class="text-center">Ad Cost (Local CCY)</th>
                                    <th class="text-center">Cost Per Lead/Registration (Local CCY)</th>
                                    <th class="text-center">Cost Per Acquisition/Deposit (Local CCY)</th>
                                    <th class="text-center">Cost Per Acquisition/Deposit (USD)</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(campaignAdCostReport$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td> {{ row.target_country }}</td>
                                    <td class="text-right"> {{ row.campaign_id }}</td>
                                    <td class="campaign-column"> {{ row.campaign_name }}</td>
                                    <td class="text-center" *ngIf="row.total_registration_count > 0">
                                        <a class="text-primary" (click)="onOpenDialog('campaign-members', row.campaign_id)">{{ row.total_registration_count }}</a>
                                    </td>
                                    <td class="text-center" *ngIf="row.total_registration_count <= 0">
                                        {{ row.total_registration_count | number : '1.0-0'}}
                                    </td>
                                    <td class="text-right">
                                        <ng-container *ngIf="row.clicks_count != 0 else noClickCount">
                                            <a class="text-primary" (click)="onOpenDialog('campaign-click', row.campaign_id, row.campaign_name)">
                                                {{ row.clicks_count | number : '1.0-0' }}
                                            </a>
                                        </ng-container>
                                        <ng-template #noClickCount>
                                            {{ row.clicks_count | number : '1.0-0' }}
                                        </ng-template>
                                    </td>
                                    <td class="text-right">{{ row.total_deposit | number : '1.2-2'}}</td>
                                    <td class="text-right">{{ row.total_deposit_count | number : '1.0-0'}}</td>
                                    <td class="text-right">{{ row.new_total_deposit | number : '1.2-2'}}</td>
                                    <td class="text-right">{{ row.new_total_deposit_count | number : '1.0-0'}}</td>
                                    <td class="text-right">{{ row.average_deposit_size | number : '1.2-2'}}</td>
                                    <td class="text-right">{{ row.ad_cost_usd | number : '1.2-2'}}</td>
                                    <td class="text-right no-break">{{row.currency_code}} {{ row.ad_cost_local | number : '1.2-2'}}</td>
                                    <td class="text-right">{{row.currency_code}} {{ row.cost_per_registration_local | number : '1.2-2'}}</td>
                                    <td class="text-right">{{row.currency_code}} {{ row.cost_per_deposit_local | number : '1.2-2'}}</td>
                                    <td class="text-right">{{ row.cost_per_deposit_usd | number : '1.2-2'}}</td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination
                        [pageSize]="pageSize"
                        [(page)]="page"
                        [maxSize]="maxSize"
                        [directionLinks]="true"
                        [boundaryLinks]="true"
                        [rotate]="true"
                        [collectionSize]="pagination.total"
                        (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of perPageDropdown">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                            Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
