<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">{{ 'Telemarketer Setting History' | translate }}</h5>
      <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
      </span>
    </div>
    <div class="modal-body">
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-striped table-sortable">
          <thead>
            <tr>
              <th class="text-center sort-enabled" (click)="onSortColumn('ftd_target_day')" [ngClass]="sortingConfig.ftd_target_day" [class.sort-selected]="sortingSelection.sort_by === 'ftd_target_day'">{{ 'FTD Conversion Period (Days)' | translate }}</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('validity_day')" [ngClass]="sortingConfig.validity_day" [class.sort-selected]="sortingSelection.sort_by === 'validity_day'">{{ 'Lead Validity (Days)' | translate }}</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('follow_up_day')" [ngClass]="sortingConfig.follow_up_day" [class.sort-selected]="sortingSelection.sort_by === 'follow_up_day'">{{ 'Follow Up Extension (Days)' | translate }}</th>
              <th class="text-center sort-enabled" (click)="onSortColumn('last_updated_at')" [ngClass]="sortingConfig.last_updated_at" [class.sort-selected]="sortingSelection.sort_by === 'last_updated_at'">{{ 'Updated By' | translate }}</th>
            </tr>
          </thead>
          <tbody *ngIf="(telemarketerSettingLog$ | async) as rows">
            <tr *ngFor="let row of rows">
              <td class="text-center"> {{ row.ftd_target_day }} </td>
              <td class="text-center"> {{ row.validity_day }} </td>
              <td class="text-center"> {{ row.follow_up_day }} </td>
              <td class="text-center"> {{ row.last_updated_by }} <br *ngIf="row.last_updated_by !== '' && row.last_updated_by !== null"> {{ isValidDate(row.last_updated_at) ? (row.last_updated_at | timezoneDate:'YYYY-MM-DD HH:mm') : '-' }} </td>
            </tr>
          </tbody>
          <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
        </table>
      </div>
      <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize"
            [directionLinks]="true" [boundaryLinks]="true" [rotate]="true"
            [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
        </ngb-pagination>
        <div class="kt-pagination__toolbar">
            <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                class="form-control kt-font-brand page-size">
                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
            </select>
            <span class="pagination__desc">
                {{ 'Showing' | translate }} {{pagination.from}} {{ 'to' | translate }} {{pagination.to}} {{ 'of' | translate }} {{ pagination.total | number : '1.0'
                }} {{ 'records' | translate }}
            </span>
        </div>
      </div>
    </div>
  </div>
</div>
