<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body">
        <!-- Filfer -->
        <div class="kt-form kt-form--label-right mb-3">
            <div class="row align-items-center">
                <div class="col-xl-12 order-2 order-xl-1 pr-0">
                    <form class="row align-items-center" [formGroup]="form">
                        <div class="col-12 row mb-1 pr-0">
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>Username: <span class="text-danger">*</span></label>
                            </div>
                            <div class="col-md-2 kt-form__control">
                                <input type="text" formControlName="parent_username" (input)="toLowerCaseInput('parent_username', $event)" placeholder="Search" class="form-control">
                            </div>
                        </div>
                        <div class="col-12 row mb-1 pr-0">
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>Date/Time:</label>
                            </div>
                            <div class="col-md-5 kt-form__control">
                                <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                            </div>
                            <div class="col-md-1 kt-form__label col-form-label">
                                <label>{{ 'Timezone' }}:</label>
                            </div>
                            <div class="col-md-5 kt-form__control">
                                <kt-dropdown-wo-lazyload
                                    #timezone
                                    (click)="onChangeTimezone(timezone.selectedItems)"
                                    [form] = 'form'
                                    [dropdownList] = 'dropdown.timezones'
                                    [dropdownSettings] = 'timezoneDropdownSettings'
                                    [formName] = "'timezone'"
                                    [selectionAttributes] = "'timezone'"
                                    [selectedItems] = 'timezoneSelectedItems'>
                                </kt-dropdown-wo-lazyload>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Action Button -->
        <div class="kt-section col-12 row mb-3 mt-2 d-flex justify-content-between">
            <div class="dropdown dropdown-inline">
                <kt-search-button [loading]="loading" (search)="onSubmit()" [disabledButton]="form.value.parent_username === null || form.value.parent_username === '' ? true : false"></kt-search-button>
                <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
            </div>
            <button *ngIf="canExportAllSubAffiliatesReport" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="button_loading">
                <i [class]="button_loading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>{{ 'Export' }}
            </button>
        </div>

        <div class="col-xl-12 summary-wrapper">
            <div class="row">
                <div class="col-12 pl-0 pr-0">
                    <label>All monetary values are shown in USD unless specified</label>
                </div>
            </div>
        </div>

        <div class="kt-section col-12 row d-flex mb-3">
            Last Bet Log Sync At <span class="font-weight-bold">{{ lastBetLogSyncAt ? lastBetLogSyncAt : '-'}}</span>
        </div>
        
        <!-- Content -->
        <div class="table-responsive">
          <table class="table table-bordered table-hover table-striped">
            <!-- Header -->
            <thead>
                <tr class="text-top">
                    <th> Username </th>
                    <th> Name </th>
                    <th> Aff. Group </th>
                    <th class="date-column"> Register Date </th>
                    <th class="text-center"> Currency </th>
                    <th class="text-center"> Clicks </th>
                    <th class="text-center"> Members </th>
                    <th class="text-center"> Total Deposits </th>
                    <th class="text-center"> Total Withdrawal </th>
                    <th class="text-center"> Valid Turnover </th>
                    <th class="text-center"> Player W/L </th>
                    <th class="text-center"> Jackpot Contribution </th>
                    <th class="text-center"> Jackpot Win </th>
                    <th class="text-center"> Nett Jackpot </th>
                    <th class="text-center"> Game Bet Amount </th>
                    <th class="text-center"> Gross Game Revenue (GGR) </th>
                    <th class="text-center"> Total Bonus </th>
                    <th class="text-center"> Total Cancelled Bonus </th>
                    <th class="text-center"> Total Rebate </th>
                    <th class="text-center"> Total W/L </th>
                </tr>
            </thead>
            <!-- Body -->
            <tbody *ngIf="(allSubAff$ | async) as rows">
                <tr *ngFor="let row of rows">
                    <td>
                        <a class="text-primary font-weight-bold" (click)="onViewInfo('information', row)">
                            {{ row.username }}
                        </a>
                    </td>
                    <td> {{ row.name }} </td>
                    <td> {{ row.affiliate_group }} </td>
                    <td> {{ timezoneDateWithTimezone(row.register_date, form.value.timezone, 'YYYY-MM-DD HH:mm') }} </td>
                    <td class="text-center"> {{ row.currency }} </td>
                    <td class="text-right"> {{ row.clicks }} </td>
                    <td class="text-right"><a class="text-primary" (click)="onOpenDialog(row)">{{ row.member_count }}</a></td>
                    <td class="text-right"> {{ row.total_deposit | number : '1.2-2' }} </td>
                    <td class="text-right"> {{ row.total_withdrawal | number : '1.2-2' }} </td>
                    <td class="text-right"> {{ row.valid_turnover | number : '1.2-2' }} </td>
                    <td class="text-right"> {{ row.player_win_lose | number : '1.2-2' }} </td>
                    <td class="text-right"> {{ row.jackpot_contribution | number : '1.2-2' }} </td>
                    <td class="text-right"> {{ row.jackpot_win | number : '1.2-2' }} </td>
                    <td class="text-right"> {{ row.nett_jackpot | number : '1.2-2' }} </td>
                    <td class="text-right"> {{ row.game_bet_amount | number : '1.2-2' }} </td>
                    <td class="text-right"> {{ row.gross_game_revenue | number : '1.2-2' }} </td>
                    <td class="text-right"> {{ row.total_bonus | number : '1.2-2' }} </td>
                    <td class="text-right"> {{ row.total_cancelled_bonus | number : '1.2-2' }} </td>
                    <td class="text-right"> {{ row.total_rebate | number : '1.2-2' }} </td>
                    <td class="text-right"> {{ row.total_win_lose | number : '1.2-2' }} </td>
                </tr>
            </tbody>
            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
          </table>
        </div>

        <!-- Pagination -->
        <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
            <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
            </ngb-pagination>
            <div class="kt-pagination__toolbar">
                <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                    <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                </select>
                <span class="pagination__desc">
                    Showing {{pagination.from}} to {{pagination.to}} of
                    {{ pagination.total | number : '1.0' }} records
                </span>
            </div>
        </div>
      </div>
    </div>
  </div>
<kt-swal-alert [message]="messages$ | async"></kt-swal-alert>
