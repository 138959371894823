import { EventEmitterService } from '@core/services/event-emitter.service';
import { Component, OnInit, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventEmitter, SimpleChanges } from '@angular/core';
import { MessageTemplateDataService } from '@views/pages/apps/superuser/message-template/services/message-template-data.service';

declare var $: any;
@Component({
  selector: 'kt-dropdown-wo-lazyload',
  templateUrl: './dropdown-wo-lazyload.component.html',
  styleUrls: ['./dropdown-wo-lazyload.component.scss']
})
export class DropdownWoLazyloadComponent implements OnInit {

  data: any = [];

  @Input()
  isMessageTemplate: any;

  @Input()
  isSMSMessageTemplate: any;

  @Input()
  selectedItems: any;

  @Input()
  form: FormGroup;

  @Input()
  dropdownList: any;

  @Input()
  formName: any;

  @Input()
  selectionAttributes: any;

  @Input()
  dropdownSettings: any;

  @Input()
  dataLoading: boolean = false;

  @Input()
  currency:any;

  @Output()
  selectedItemsChanged = new EventEmitter();

  @Output()
  openTriggered = new EventEmitter<boolean>();

  @Output()
  closeTriggered = new EventEmitter();

  @Output()
  deSelectAll = new EventEmitter();

  pageNumber = 1;
  pageSize = 30;
  loading = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private eventEmitterService: EventEmitterService,
    private msessageTemplateDataService: MessageTemplateDataService
  ) { }

  ngOnInit() {
    this.eventEmitterService.merchantAccountSubsVar = this.eventEmitterService.invokeClearMerchantAccountSearch.subscribe(() => {
      if(this.formName !=='source_type') this.selectedItems = [];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.currency) {
      this.selectedItems = [];
    }
  }

  fetchMore(item: any) {
    if (this.loading === false && item.endIndex === this.dropdownList.length - 1 && this.dropdownList.length % this.pageSize === 0 && this.dropdownList.length !== 0) {
      this.pageNumber++;
      if (this.isMessageTemplate) {
        this.setMessageTemplateDropdown();
      }
      if (this.isSMSMessageTemplate) {
        this.setSMSMessageTemplateDropdown();
      }
    }
  }

  onOpen(items: any) {
    // start for send sms campaign template dropdown
    var smsCampaignTemplate = document.getElementById("campaign-dropdown-template");
    if (smsCampaignTemplate !== null) {
      smsCampaignTemplate.getElementsByClassName('dropdown-list')[0]['classList'].add('dropdown-list-top');
      smsCampaignTemplate.getElementsByClassName('arrow-2')[0]['classList'].remove('arrow-up');
      smsCampaignTemplate.getElementsByClassName('arrow-2')[0]['classList'].add('arrow-down');
      smsCampaignTemplate.getElementsByClassName('dropdown-list')[0].getElementsByClassName('arrow-up')[0]['classList'].add('arrow-down')
    }
    // end for send sms campaign template dropdown

    if (document.querySelectorAll('#selectAll')) {
      document.querySelectorAll('#selectAll').forEach(ele => ele.removeAttribute('id'));
    }
    this.openTriggered.emit(true);
  }

  onSelectAll(items: any) {
    this.data = [];
    if (this.dropdownSettings.singleSelection === true) {
      this.onItemSelectedChange();
      this.form.controls[this.formName].patchValue(items[this.selectionAttributes]);
    }
    if (this.dropdownSettings.singleSelection !== true && this.selectedItems !== null) {
      Object.keys(this.selectedItems).forEach((key) =>
        this.data.push(this.selectedItems[key][this.selectionAttributes]));
      this.onItemSelectedChange();
      this.form.controls[this.formName].patchValue(this.data);
    }
  }

  onDeSelectAll(items: any) {
    this.data = [];
    if (this.dropdownSettings.singleSelection === true) {
      this.onItemSelectedChange();
      this.form.controls[this.formName].patchValue(null);
    } else {
      this.onItemSelectedChange();
      this.form.controls[this.formName].patchValue(this.data);
    }
    this.deSelectAll.emit();
  }

  OnItemDeSelect(item: any) {
    this.data = [];
    if (this.dropdownSettings.singleSelection === true) {
      if (this.selectedItems.length > 0) {
        this.onItemSelectedChange();
        this.form.controls[this.formName].patchValue(item[this.selectionAttributes]);
      } else {
        this.onItemSelectedChange();
        this.form.controls[this.formName].patchValue(null);
      }
    }
    if (this.dropdownSettings.singleSelection !== true) {
      Object.keys(this.selectedItems).forEach((key) =>
        this.data.push(this.selectedItems[key][this.selectionAttributes]));
      this.onItemSelectedChange();
      this.form.controls[this.formName].patchValue(this.data);
    }
  }

  onItemSelect(item: any) {
    this.data = [];
    if (this.dropdownSettings.singleSelection === true) {
      this.onItemSelectedChange();
      this.form.controls[this.formName].patchValue(item[this.selectionAttributes]);
    }
    if (this.dropdownSettings.singleSelection !== true && this.selectedItems !== null) {
      Object.keys(this.selectedItems).forEach((key) =>
        this.data.push(this.selectedItems[key][this.selectionAttributes]));
      this.onItemSelectedChange();
      this.form.controls[this.formName].patchValue(this.data);
    }
  }

  onItemSelectedChange() {
    this.selectedItemsChanged.emit(this.selectedItems);
  };

  async onClose(item: any) {
    this.data = [];

    const processData = () => {
      return new Promise<void>((resolve, reject) => {
        if (this.dropdownSettings.singleSelection !== true && (this.selectedItems !== null || this.selectedItems !== undefined)) {
          if (!!this.selectedItems) {
            Object.keys(this.selectedItems).forEach((key) =>
              this.data.push(this.selectedItems[key][this.selectionAttributes])
            );
            this.form.controls[this.formName].patchValue(this.data);
            resolve();
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      })
    }

    await processData();
    this.closeTriggered.emit(true);
  }

  private setMessageTemplateDropdown() {
    this.loading = true;
    this.msessageTemplateDataService.getWithQuery(`?type[]=1&page=${this.pageNumber}&perPage=${this.pageSize}`).subscribe(res => {
      this.dropdownList = this.dropdownList.concat(res);
      this.loading = false;
      this.cdr.detectChanges();
    })
  }

  private setSMSMessageTemplateDropdown() {
    this.loading = true;
    this.msessageTemplateDataService.getWithQuery(`?type[]=2&page=${this.pageNumber}&perPage=${this.pageSize}`).subscribe(res => {
      this.dropdownList = this.dropdownList.concat(res);
      this.loading = false;
      this.cdr.detectChanges();
    })
  }

}
