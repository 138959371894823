<div class="kt-form w-webkit-fill-available" *ngIf="dropdown.locales.length > 0">
  <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">

    <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body">
        <form [formGroup]="form">
          <div class="row align-items-center">
            <div class="col-xl-12 order-2 order-xl-1">
              <div class="row mb-3">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Status' | translate }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="status" class="form-control">
                    <option value="all"> {{'All' | translate }} </option>
                    <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">
                      {{ value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Locale' | translate }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="settings_locale_id" class="form-control">
                    <option value="all"> {{'All' | translate }} </option>
                    <option *ngFor="let value of dropdown.locales" [value]="value.id">{{ value.code }}</option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Keywords' | translate }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="keyword" placeholder="{{ 'Search' | translate }}" class="form-control">
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="kt-section col-12 row d-flex justify-content-between mb-0 mt-1">
          <div class="dropdown dropdown-inline">
            <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
            <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onSubmit(true)"></kt-clear-button>
          </div>
        </div>
      </div>
    </div>

    <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body pt-12 pb-0">
          <div class="kt-section">
            <div class="kt-section__content">
              <div class="table-responsive">
                <table class="table table-bordered table-hover table-striped table-sortable">
                  <thead>
                      <tr>
                          <th class="pl-1 pr-3 table-header">
                            <div class="pr-1">{{ 'Display Locale' | translate | uppercase }}</div>
                            <div class="dropdown selected-category-container ml-1" *ngIf="displayLocale != null">
                              <button class="dropdown-toggle d-flex justify-content-center dropdown-box" type="button" data-toggle="dropdown" style="margin: 0 auto">
                                <p class="mr-1">{{ displayLocale['code'] | uppercase }} </p>
                                <i class="fa fa-sort-down"></i>
                              </button>
                              <ul class="dropdown-menu dropdown-box-open">
                                <li *ngFor="let value of dropdown.locales" class="selected-category-container2 ml-0" (click)="changeLocale(value)">
                                  {{ value.code | uppercase }}
                                </li>
                              </ul>
                            </div>
                            </th>
                          <th class="pr-0 pl-0"></th>
                     </tr>
                  </thead>
               
                  <tbody *ngIf="privacyPolicy$ as rows">
                    <tr> <td colspan="6" class="custom-padding"></td></tr>
                    <ng-container *ngIf="privacyPolicy$ as rows">
                      <ng-container *ngIf="rows.main_descriptions[0] as row">
                       <tr class="border-bottom-remove">
                         <td class="title" [style]="mode == 'edit' && privacyPolicy.id == row.id ? 'border-bottom: 0 !important;' : ''"><div class="main_description">{{ 'Main Description' | translate| uppercase }}</div></td>
                         <td class="d-flex align-content-center justify-content-end" [style]="mode == 'edit' && privacyPolicy && privacyPolicy.id == row.id ? 'border-bottom: 0 !important;' : ''">
                           <label class="switch mr-3">
                             <input [disabled]="!canEditPrivacyPolicy && !canUpdatePrivacyPolicyStatus" type="checkbox" id="togBtn" [checked]="row.status == 1" (change)="onChangeStatus($event, row)">
                             <div class="slider round">
                               <span class="on">ON</span>
                               <span class="off">OFF</span>
                             </div>
                           </label>
                           <button *ngIf="canViewPrivacyPolicyDetails" matTooltip="{{ 'View' | translate }}" class="btn btn-elevate btn-sm btn-icon btn-icon-sm" (click)="expandRowEdit('edit', row)"><i class="fas fa-cog"></i></button>
                         </td>
                       </tr>
                       <tr class="border-top-remove" *ngIf="!mode || mode == 'create' || !privacyPolicy || ( mode == 'edit' && privacyPolicy && privacyPolicy.id !== row.id)" [ngClass]=" (mode == 'edit' && privacyPolicy && privacyPolicy.id == row.id) ? 'border-bottom-remove' : ''">
                        <td colspan="2" class="w-100 pr-0 pl-0 custom-top-border">
                          <div class="row col-12 p-0 m-0">
                            <div class="col-12 font-weight-normal ml-2">
                              <div id="content" class="limittext" [ngClass]="showMain ? 'showContent' : '' " ><div [innerHTML]="displayLocaleContent(row, 'description','mainDescription')"></div></div>
                              <button class="read-button" *ngIf="!showMain && showReadMoreButton('content')" (click)="showMain = true">[ {{ 'Read More' }} ]</button>
                              <button class="read-button" *ngIf="showMain && !showReadMoreButton('content')" (click)="showMain = false">[ {{ 'Read Less'}} ]</button>
                            </div>
                          </div>
                        </td>
                      </tr>
                       <tr *ngIf="mode == 'edit' && privacyPolicy && privacyPolicy.id == row.id" class="border-top-remove">
                         <td colspan="2" class="pr-0 pl-0 custom-top-border" style="border-bottom: 0 !important;">
                           <ng-container *ngIf="!mode else contents"></ng-container>
                         </td>
                       </tr>
                     </ng-container>
                   </ng-container>
                   <tr *ngIf="canCreatePrivacyPolicy && (!mode || mode != 'create')" class="border-top-remove">
                     <td colspan="2" class="p-0 custom-top-border">
                       <div class="content-container text-center" (click)="expandRowCreate('create')">
                         <span><i class="fas fa-plus mr-2"></i>Create New Content</span>
                       </div>
                     </td>
                   </tr>                       
                   <tr *ngIf="mode == 'create'">
                     <td colspan="2" class="pr-0 pl-0">
                       <ng-container *ngIf="!mode else contents"></ng-container>
                     </td>
                   </tr>
                    <ng-container *ngFor="let row of rows.contents.rows">
                      <tr [ngClass]=" (viewMode && viewPrivacyPolicy.id == row.id) || (mode == 'edit' && privacyPolicy.id == row.id) ? 'border-bottom-remove' : '' " >
                        <td class="pr-0" [style]="mode == 'edit' && privacyPolicy.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          <div class="d-flex align-content-center">
                            <div class="title d-inline-block pl-2">{{ displayLocaleContent(row) }}</div>
                          </div>
                        </td>
                        <td [style]="mode == 'edit' && privacyPolicy.id == row.id ? 'border-bottom: 0 !important;' : ''" class="text-center actions-column">
                          <div class="d-flex align-content-center justify-content-end">
                            <button *ngIf="canViewPrivacyPolicyDetails" matTooltip="{{ 'View' | translate }}" class="btn btn-elevate btn-sm btn-icon btn-icon-sm pr-3" (click)="expandRowView(row)">
                              <i [ngClass]="viewMode && viewPrivacyPolicy.id == row.id ? 'fas fa-minus view-bottom' : 'fas fa-plus view-bottom'"></i>                            
                            </button>
                            <label class="switch mr-3">
                              <input [disabled]="!canEditPrivacyPolicy && !canUpdatePrivacyPolicyStatus" type="checkbox" id="togBtn" [checked]="row.status == 1" (change)="onChangeStatus($event, row)">
                              <div class="slider round">
                                <span class="on">ON</span>
                                <span class="off">OFF</span>
                              </div>
                            </label>
                            <button *ngIf="canViewPrivacyPolicyDetails" matTooltip="{{ 'View' | translate }}" class="btn btn-elevate btn-sm btn-icon btn-icon-sm" (click)="expandRowEdit('edit', row)"><i class="fas fa-cog"></i></button>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="viewMode && viewPrivacyPolicy.id == row.id" class="border-top-remove" [ngClass]="(mode == 'edit' && privacyPolicy.id == row.id) || mode == 'create' ? 'border-bottom-remove' : ''">
                        <td colspan="2" class="pr-0 pl-0 custom-top-border">
                          <ng-container *ngIf="!viewMode else viewContents"></ng-container>
                        </td>
                      </tr>
                      <tr *ngIf="mode == 'edit' && privacyPolicy.id == row.id" class="border-top-remove">
                        <td colspan="2" class="pr-0 pl-0 custom-top-border" style="border-bottom: 0 !important;">
                          <ng-container *ngIf="!mode else contents"></ng-container>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                  <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold table-handler"></tbody>
                </table>
              </div>
              <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                </ngb-pagination>
                <div class="kt-pagination__toolbar">
                  <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                    <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                  </select>
                  <span class="pagination__desc">
                    Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #viewContents>
    <div class="row col-12 p-0 m-0">
      <div class="col-12 ml-2">
        <div id="content2"  class="limittext" [ngClass]="show ? 'showContent' : '' " ><div [innerHTML]="displayLocaleContent(this.viewPrivacyPolicy, 'description')"></div></div>
        <button class="read-button" *ngIf="!show && showReadMoreButton('content2')" (click)="show = true">[ {{ 'Read More' }} ]</button>
        <button class="read-button" *ngIf="show && !showReadMoreButton('content2')" (click)="show = false">[ {{ 'Read Less'}} ]</button>
      </div>
    </div>
</ng-template>

<ng-template #contents>
  <form [formGroup]="formContent">
    <div class="create-content-container" *ngIf="dropdown.locales.length > 0">
      <div class="row col-12 p-0 m-0">
        <div class="col-12 mb-2" *ngIf="mode == 'create'">
          <span class="header">Create New Privacy Policy Content</span>
        </div>
      </div>
      <div class="row col-12 p-0 m-0" *ngIf="formContent.get('content_type') && formContent.get('content_type').value !== 1 ">
        <div class="form-group col-3 mb-3" >
          <label>{{ 'Position' | translate }}: <span class="text-danger">*</span></label>
          <input type="number" min="1" (wheel)="false" step="1" formControlName="position" class="form-control">
        </div>
        <div class="form-group col-3 mb-3" *ngIf="mode == 'create'">
          <label>{{ 'Status' | translate }}: <span class="text-danger">*</span></label>
          <select formControlName="status" class="form-control">
            <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">
              {{ value }}
            </option>
          </select>
        </div>
      </div>
      <hr>
      <ng-container *ngIf="checkLoadingPage else loadingPage" >
        <mat-tab-group animationDuration="0ms" class="form-group mb-0 mat-tab-header-pagination-controls-enabled"  class="form-group m-0" formGroupName="contents" [selectedIndex]="localeIndex()">
          <ng-container *ngIf="dropdown.locales.length > 0">
            <mat-tab *ngFor="let value of dropdown.locales; let i = index" [formGroupName]="value.id">
              <ng-template mat-tab-label>
                <span class="mat-tab">
                  {{ value.code }}
                  <div *ngIf="checkContent(value)" class="icon" (click)="clearContent(value)">
                    <i class="fas fa-times"></i>
                  </div>
                </span>
              </ng-template>
              <div class="row col-12 p-0 m-0" *ngIf="formContent.get('content_type') && formContent.get('content_type').value !== 1 ">
                <div class="form-group col-12 mb-0 p-0">
                  <label>{{ 'Title' | translate }}: <span class="text-danger">*</span></label>
                  <input type="text" formControlName="title" class="form-control">
                </div>
              </div>
              <div class="col-12 p-0 mb-3" *ngIf="formContent.get('content_type') && formContent.get('content_type').value !== 1 ">
                <span class="header-hint">Usable variables:
                  <div *ngFor="let usableVariable of usableVariables; let last = last" class="p-inline">
                    <p class="p-inline" (click)="addTitleVariable(usableVariable, value.id)">{{ usableVariable }}</p>
                    <div *ngIf="!last" class="p-inline">
                      <p class="p-inline p-display">, </p>
                    </div>
                  </div>
                </span>
              </div>
              <div class="row col-12 p-0 m-0">
                <div class="form-group col-12 mb-0 p-0">
                  <label *ngIf="formContent.get('content_type') && formContent.get('content_type').value !== 1 ">{{ 'Description' | translate }}: <span class="text-danger">*</span></label>
                  <label *ngIf="formContent.get('content_type') && formContent.get('content_type').value == 1 ">{{ 'Main Description' | translate }}:</label>
                  <ckeditor #editors [config]="editorConfig" [editor]="editor" formControlName="description" class="ck-style"></ckeditor>
                </div>
              </div>
              <div class="col-12 p-0 mb-3">
                <span class="header-hint">Usable variables:
                  <div *ngFor="let usableVariable of usableVariables; let last = last" class="p-inline">
                    <p class="p-inline" (click)="addDescriptionVariable(usableVariable, value.id)">{{ usableVariable }}</p>
                    <div *ngIf="!last" class="p-inline">
                      <p class="p-inline p-display">, </p>
                    </div>
                  </div>
                </span>
              </div>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
      </ng-container>
      <div class="modal-footer">
        <table class="info mr-3" *ngIf="mode == 'edit'">
          <tr class="border-bottom-remove">
            <td class="header-hint">
              Updated by: {{ privacyPolicy.updated_by ? privacyPolicy.updated_by + '&nbsp;' : '-&nbsp;' }}
            </td>
            <td class="header-hint">
              {{ privacyPolicy.updated_at | timezoneDate:'YYYY-MM-DD HH:mm'}}
            </td>
          </tr>
          <tr class="border-bottom-remove">
            <td class="header-hint">
              Created by: {{ privacyPolicy.created_by ? privacyPolicy.created_by + '&nbsp;' : '-&nbsp;' }}
            </td>
            <td class="header-hint">
              {{ privacyPolicy.created_at | timezoneDate:'YYYY-MM-DD HH:mm'}}
            </td>
          </tr>
        </table>
        <button type="button" class="btn btn-danger m-0 mr-2" (click)="mode == 'create' ? expandRowCreate('', true) : expandRowEdit('', null, true)">{{ 'Cancel' | translate }}</button>
        <button *ngIf="(mode == 'create' && canCreatePrivacyPolicy) || (mode == 'edit' && canEditPrivacyPolicy)" type="button" class="btn btn-success m-0" [disabled]="!formContent.valid" (click)="onSave()">{{ mode != null && mode == 'create' ? 'Create' : 'Update' | translate }}</button>
      </div>
    </div>
  </form>
  <ng-template #loadingPage>
    <div style="margin: auto;width: fit-content;">
      <div class="spinner-wrapper">
        <mat-progress-spinner mode="indeterminate" diameter="25"></mat-progress-spinner>
      </div>
    </div>  
  </ng-template>
</ng-template>

<kt-swal-alert [message]="messages$ | async" (confirmed)="reload()"></kt-swal-alert>
