<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <!-- Filter -->
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <form class="align-items-center" [formGroup]="form">
                            <div class="row mb-2">
                                <!-- Username -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>
                                <!-- Name -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                                <!-- Contact -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Contact: </label>
                                </div>
                                <div class="col-md-4 kt-form__control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <select class="form-control" (change)="onContactType($event.target.value)">
                                                <option value="Mobile" selected>Mobile</option>
                                                <option value="Email">Email</option>
                                            </select>
                                        </div>
                                        <input type="text" (change)="onContact($event)" placeholder="Search" class="form-control" [disabled]="(selectedContactType === '' && selectedContactType === null)" #contactRef>
                                        <input type="hidden" formControlName="mobile" />
                                        <input type="hidden" formControlName="email" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <!-- Currency -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <!-- Account Manager -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Account Manager:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="account_manager" class="form-control">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <option [value]="value.username" *ngFor="let value of dropdown.accountManager| async">
                                            {{ value.username }}
                                        </option>
                                    </select>
                                </div>

                                <!-- Date Type & Date Range -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date: </label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <select class="form-control" formControlName="date_type">
                                                <option value="lead_assigned_datetime" selected>Lead Assigned Date</option>
                                                <option value="last_activity" selected>Last Activity Date</option>
                                                <option value="last_deposit" selected>Last Deposit Date</option>
                                                <option value="lead_validity_datetime" selected>Lead Validity Date</option>
                                            </select>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-2">

                                <!-- Development Stage -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label class="mb-0">Development Stage:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="development_stage" class="form-control">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.developmentStage">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>

                                <!-- Lead Stage -->
                                <div class="col-md-1 kt-form__label col-form-label" *ngIf="form.value.development_stage != null">
                                    <label class="mb-0">Lead Stage:</label>
                                </div>
                                <!--  Normal Development Stage -->
                                <div class="col-md-2 kt-form__control" *ngIf="form.value.development_stage == 1">
                                    <select formControlName="lead_stage" class="form-control">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.normalLeadStage">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>
                                <!--  Vip Development Stage -->
                                <div class="col-md-2 kt-form__control" *ngIf="form.value.development_stage == 2">
                                    <select formControlName="lead_stage" class="form-control">
                                        <option [value]="null" [disabled]="true">Please Select</option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.vipLeadStage">
                                            {{ value.name }}
                                        </option>
                                    </select>
                                </div>

                                <!-- Status -->
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Status:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="d-flex flex-wrap status-filter-pt">
                                        <div class="custom-control custom-checkbox mr-3" *ngFor="let item of dropdown.statuses ; let i = index">
                                            <input type="checkbox" [checked]="item.checked" (change)="onChangeStatus($event, item.value, i)" class="custom-control-input" [id]="item.name">
                                            <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-1">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()" (click)="contactRef.value = ''"></kt-clear-button>
                </div>
                <div class="dropdown dropdown-inline">
                    <button *ngIf="canViewKPISetting" class="btn btn-setting btn-icon-sm mr-2" [disabled]="loading" (click)="onOpenDialog('kpi-settings')"><i class="fas fa-chart-line"></i>KPI Settings</button>
                    <button *ngIf="canAssignLead" class="btn btn-brand btn-icon-sm mr-2" [disabled]="loading" (click)="onOpenDialog('assign-lead')"><i class="fas fa-plus"></i>Assign</button>
                    <button *ngIf="canExportAccountManagement" class="btn btn-warning btn-icon-sm" (click)="onExport()" type="button" [disabled]="buttonLoading"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-file-download'"></i>Export</button>
                </div>
            </div>
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('id')" 
                                        [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID
                                    </th>
                                    <th rowspan="2" class="date-column sort-enabled" (click)="onSortColumn('development_stage')" 
                                        [ngClass]="sortingConfig.development_stage" [class.sort-selected]="sortingSelection.sort_by === 'development_stage'">Development Stage
                                    </th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('lead_stage')" 
                                        [ngClass]="sortingConfig.lead_stage" [class.sort-selected]="sortingSelection.sort_by === 'lead_stage'">Lead Stage
                                    </th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('lead_assigned_datetime')" 
                                        [ngClass]="sortingConfig.lead_assigned_datetime" [class.sort-selected]="sortingSelection.sort_by === 'lead_assigned_datetime'">Lead Assigned Date
                                    </th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('username')" 
                                        [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username
                                    </th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('name')" 
                                        [ngClass]="sortingConfig.name" [class.sort-selected]="sortingSelection.sort_by === 'name'">Name
                                    </th>
                                    <th rowspan="2" class="sort-enabled" (click)="onSortColumn('member_group')" 
                                        [ngClass]="sortingConfig.member_group" [class.sort-selected]="sortingSelection.sort_by === 'member_group'">Member Group
                                    </th>
                                    <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('mobile')" 
                                        [ngClass]="sortingConfig.mobile" [class.sort-selected]="sortingSelection.sort_by === 'mobile'">Mobile
                                    </th>
                                    <th rowspan="2" class="date-column sort-enabled" (click)="onSortColumn('date_of_birth')" 
                                        [ngClass]="sortingConfig.date_of_birth" [class.sort-selected]="sortingSelection.sort_by === 'date_of_birth'">DOB
                                    </th>
                                    <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('locale')" 
                                        [ngClass]="sortingConfig.locale" [class.sort-selected]="sortingSelection.sort_by === 'locale'">Language
                                    </th>
                                    <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('currency')" 
                                        [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">Currency
                                    </th>
                                    <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('account_manager')" 
                                        [ngClass]="sortingConfig.account_manager" [class.sort-selected]="sortingSelection.sort_by === 'account_manager'">Account Manager
                                    </th>
                                    <th rowspan="2" class="text-center unsortable">Average Deposit Count / Amount (LCC)
                                    </th>
                                    <th rowspan="2" class="text-center unsortable">KPI (Count / Amount)
                                    </th>
                                    <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('fav_game_category')" 
                                        [ngClass]="sortingConfig.fav_game_category" [class.sort-selected]="sortingSelection.sort_by === 'fav_game_category'">Favourite Category Last Two Weeks
                                    </th>
                                    <th rowspan="2" class="text-center sort-enabled" (click)="onSortColumn('last_activity')" 
                                        [ngClass]="sortingConfig.last_activity" [class.sort-selected]="sortingSelection.sort_by === 'last_activity'">Last Activity Date
                                    </th>
                                    <th rowspan="2" class="text-center date-column sort-enabled" (click)="onSortColumn('last_login')" 
                                        [ngClass]="sortingConfig.last_login" [class.sort-selected]="sortingSelection.sort_by === 'last_login'">Last Login
                                    </th>
                                    <th rowspan="2" class="text-center date-column sort-enabled" (click)="onSortColumn('last_deposit')" 
                                        [ngClass]="sortingConfig.last_deposit" [class.sort-selected]="sortingSelection.sort_by === 'last_deposit'">Last Deposit
                                    </th>
                                    <th rowspan="2" class="text-center date-column sort-enabled" (click)="onSortColumn('lead_validity_datetime')" 
                                        [ngClass]="sortingConfig.lead_validity_datetime" [class.sort-selected]="sortingSelection.sort_by === 'lead_validity_datetime'">Lead Validity
                                    </th>
                                    <th rowspan="2" class="text-center unsortable">Progress</th>
                                    <th rowspan="2" class="text-center text-center sort-enabled" (click)="onSortColumn('status')" 
                                        [ngClass]="sortingConfig.status" [class.sort-selected]="sortingSelection.sort_by === 'status'">Status
                                    </th>
                                    <th rowspan="2" class="text-center unsortable">Remark</th>
                                    <th rowspan="2" class="text-center unsortable">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(AccountManagement$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{ row.id}}</td>
                                    <td class="text-center">{{ row.development_stage }}</td>
                                    <td class="text-center">{{ row.lead_stage }}</td>
                                    <td [ngClass]="row.lead_assigned_datetime ? 'text-left' : 'text-center'">{{ row.lead_assigned_datetime ? (row.lead_assigned_datetime | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</td>
                                    <td [ngClass]="row.username ? 'text-left' : 'text-center'">
                                        <ng-container *ngIf="canViewMemberDialog else otherRole">
                                            <a class="text-primary font-weight-bold" (click)="onOpenDialog('member-information', row.member_account_id)">
                                                {{ row.username }}
                                            </a>
                                        </ng-container>
                                        <ng-template #otherRole>
                                            {{ row.username }}
                                        </ng-template>
                                        <i *ngIf="row.suspicious" class="fa fa-exclamation text-danger"></i>

                                    </td>
                                    <td [ngClass]="row.name ? 'text-left' : 'text-center'">{{ row.name ? row.name : '-'}}</td>
                                    <td>{{ row.member_group }}</td>
                                    <td [ngClass]="row.mobile ? 'text-left' : 'text-center'">{{ row.mobile ? row.mobile : '-'}}</td>
                                    <td>{{ row.date_of_birth }}</td>
                                    <td class="text-center">{{ row.locale }}</td>
                                    <td class="text-center">{{ row.currency }}</td>
                                    <td [ngClass]="row.account_manager ? 'text-left' : 'text-center'">
                                        <ng-container *ngIf="canViewAccountManagerHistory else noViewHistory">
                                            <a class="text-primary font-weight-bold" (click)="onOpenDialog('account-management-logs', '', row)">
                                                {{ row.account_manager }}
                                            </a>
                                        </ng-container>
                                        <ng-template #noViewHistory>
                                            {{ row.account_manager }}
                                        </ng-template>
                                    </td>
                                    <td class="text-right">#: {{ row.average_deposit_count > 0 ? row.average_deposit_count : '-' }} <br> $: {{ row.average_deposit_amount ? row.average_deposit_amount : '-' }}</td>
                                    <td class="text-right">#: {{ row.kpi_deposit_count > 0 ? row.kpi_deposit_count : '-' }} <br> $: {{ row.kpi_deposit_amount ? row.kpi_deposit_amount : '-' }}</td>
                                    <td class="text-center">{{ row.fav_game_category ? row.fav_game_category : '-' }}</td>
                                    <td class="text-center">{{ row.last_activity ? (row.last_activity | timezoneDate: 'YYYY-MM-DD (ddd)') : '-' }}</td>
                                    <td class="text-center">{{ row.last_login ? (row.last_login | timezoneDate: 'YYYY-MM-DD (ddd)') : '-' }}</td>
                                    <td class="text-center">{{ row.last_deposit ? (row.last_deposit | timezoneDate: 'YYYY-MM-DD HH:mm:ss (ddd)') : '-' }}</td>
                                    <td class="min-width-200" style="position: relative;">
                                        <ng-container *ngIf="(row.lead_validity_datetime !== null); else nullTemplate5">
                                            <div class="td-wrapper">
                                                <div class="text-center">{{ (row.lead_validity_datetime | timezoneDate:'YYYY-MM-DD HH:mm:ss (ddd)':true) }}</div>
                                                <div class="count-down-day" *ngIf="row.status == 1">
                                                    <i class="fas fa-hourglass-half pr-1"></i>
                                                    <i>D-{{ calculateDayLeft(row.lead_validity_datetime) }}</i>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #nullTemplate5>
                                            - <br>
                                        </ng-template>
                                    </td>
                                    <td class="text-right">
                                        <span [ngClass]="isKpiAchieve(row.kpi_deposit_count, row.progress_deposit_count) ? 'kpi-success' : 'kpi-failed'">#: {{ row.progress_deposit_count > 0 ? row.progress_deposit_count : '-' }}</span> <br> 
                                        <span [ngClass]="isKpiAchieve(row.kpi_deposit_amount, row.progress_deposit_amount) ? 'kpi-success' : 'kpi-failed'">$: {{ row.progress_deposit_amount ? row.progress_deposit_amount : '-' }}</span>
                                    </td>
                                    <td class="text-center">
                                        <span class="mt-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + row.status"> {{ row.status_name ? row.status_name : '-' }}</span>
                                    </td>
                                    <td [ngClass]="row.value ? 'text-left' : 'text-center'">
                                        <ng-container *ngIf="(row.value); else nullTemplate">
                                            <ng-container *ngIf="canViewRemarkHistory; else noViewRemarkHistory">
                                                <a class="text-primary font-weight-bold" (click)="onOpenDialog('show-remarks', '', row)">
                                                    {{ row.value }}
                                                </a>
                                            </ng-container>
                                            <ng-template #noViewRemarkHistory>
                                                {{ row.value }}
                                            </ng-template>
                                        </ng-container>
                                        <ng-template #nullTemplate>
                                            -
                                        </ng-template>
                                    </td>
                                    <td class="text-center">
                                        <button *ngIf="row.member_account_id && ((userData.authorized_role_version == 1 && (userPermissions$ | async).account_management_assign_reward) || (userData.authorized_role_version == 2 && canAssignReward) || (userData.authorized_role_version == 3 && (canAssignReward || (userPermissions$ | async).account_management_assign_reward)))" matTooltip="{{ 'Assign Rewards' | translate }}" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('assign-rewards', row.member_account_id)"><i class="fas fa-gift"></i></button>
                                        <button *ngIf="canEditRemark" matTooltip="Edit Remark" class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm" (click)="onOpenDialog('remarks', '', row)"><i class="fas fa-edit"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0'}} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<kt-swal-alert [message]="messages$ | async" [data]="data$ | async"></kt-swal-alert>