<div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="kt-form kt-form--label-right">
                <div class="row align-items-center">
                    <div class="col-xl-12 order-2 order-xl-1">
                        <form class="align-items-center" [formGroup]="form">
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>ID:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="id" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Username:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="username" (input)="toLowerCaseInput('username', $event)" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Name:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="name" placeholder="Search" class="form-control">
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Refer ID:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="text" formControlName="refer_id" placeholder="Search" class="form-control">
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Date/Time:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="input-group date">
                                        <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
                                        <span class="input-group-append">
                                            <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Transaction Type:</label>
                                </div>
                                <div class="col-md-5 kt-form__control">
                                    <div class="d-flex flex-wrap status-filter-pt">
                                        <div class="custom-control custom-checkbox mr-3" *ngFor="let item of transactionTypes">
                                            <input type="checkbox" [checked]="item.checked" (change)="onChangeType($event, item.id)" class="custom-control-input" [id]="item.name">
                                            <label class="custom-control-label text-nowrap" [for]="item.name">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Currency:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <select formControlName="currency_id" class="form-control">
                                        <option value="all"> All </option>
                                        <option [value]="value.id" *ngFor="let value of dropdown.currencies"> {{ value.name }} </option>
                                    </select>
                                </div>
                                <div class="col-md-1 kt-form__label col-form-label">
                                    <label>Amount:</label>
                                </div>
                                <div class="col-md-2 kt-form__control">
                                    <input type="number" formControlName="amount" placeholder="Search" class="form-control">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="kt-section col-12 row d-flex justify-content-between mb-3 mt-2">
                <div class="dropdown dropdown-inline">
                    <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
                    <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading" (clear)="onClear()"></kt-clear-button>
                </div>
                <button *ngIf="canAdjustWallet" class="btn btn-brand btn-icon-sm" (click)="onOpenDialog('transaction')"><i class="fas fa-plus"></i>Create</button>
            </div>

            <!-- table begins-->
            <div class="kt-section">
                <div class="kt-section__content">
                    <div class="table-responsive">
                        <table class="table table-bordered table-hover table-striped table-sortable">
                            <thead>
                                <tr>
                                    <th class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id" [class.sort-selected]="sortingSelection.sort_by === 'id'">ID</th>
                                    <th class="sort-enabled" (click)="onSortColumn('transaction_type_id')" [ngClass]="sortingConfig.transaction_type_id" [class.sort-selected]="sortingSelection.sort_by === 'transaction_type_id'">Transaction Type</th>
                                    <th class="sort-enabled" (click)="onSortColumn('username')" [ngClass]="sortingConfig.username" [class.sort-selected]="sortingSelection.sort_by === 'username'">Username</th>
                                    <th class="sort-enabled text-center" (click)="onSortColumn('currency')" [ngClass]="sortingConfig.currency" [class.sort-selected]="sortingSelection.sort_by === 'currency'">Currency</th>
                                    <th class="sort-enabled text-right" (click)="onSortColumn('amount')" [ngClass]="sortingConfig.amount" [class.sort-selected]="sortingSelection.sort_by === 'amount'">Amount</th>
                                    <th class="sort-enabled text-right" (click)="onSortColumn('balance')" [ngClass]="sortingConfig.balance" [class.sort-selected]="sortingSelection.sort_by === 'balance'">Balance</th>
                                    <th class="sort-enabled" (click)="onSortColumn('refer_id')" [ngClass]="sortingConfig.refer_id" [class.sort-selected]="sortingSelection.sort_by === 'refer_id'">Refer ID</th>
                                    <th class="sort-enabled" (click)="onSortColumn('remarks')" [ngClass]="sortingConfig.remarks" [class.sort-selected]="sortingSelection.sort_by === 'remarks'">Remarks</th>
                                    <th class="sort-enabled" (click)="onSortColumn('operator')" [ngClass]="sortingConfig.operator" [class.sort-selected]="sortingSelection.sort_by === 'operator'">Created By</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="(transactions$ | async) as rows">
                                <tr *ngFor="let row of rows">
                                    <td>{{row.id}}</td>
                                    <td>{{row.transaction_type}}</td>
                                    <td>
                                        <a [ngClass]="row.dummy == 1 ? 'text-dummy-clickable' : 'text-primary'" class="font-weight-bold" (click)="onOpenDialog('member-information', row.member_account_id)">{{ row.username }}</a>
                                    </td>
                                    <td class="text-center">{{ row.currency }}</td>
                                    <td class="text-right text-success">
                                        <span [ngClass]="row.amount >= 0 ? 'text-success' : 'text-danger'">{{  row.amount | number : '1.2-2' }}</span>
                                    </td>
                                    <td class="text-right">{{row.balance | number : '1.2-2'}}</td>
                                    <td>{{row.refer_id}}</td>
                                    <td>{{row.remarks}}</td>
                                    <td>{{row.operator }}<br *ngIf="row.created_by">{{ row.created_at | timezoneDate: 'YYYY-MM-DD HH:mm' }}</td>
                                </tr>
                            </tbody>
                            <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength" class="text-gray-600 fw-bold"></tbody>
                        </table>
                    </div>
                    <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                        <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true" [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total" (pageChange)="onViewPageBy(page)">
                        </ngb-pagination>
                        <div class="kt-pagination__toolbar">
                            <select (change)="onPerPage($event)" [(ngModel)]="pageSize" class="form-control kt-font-brand page-size">
                                <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                            </select>
                            <span class="pagination__desc">
                                Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- table ends -->
        </div>
    </div>
</div>
