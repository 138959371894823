import { Pagination } from './../models/pagination.model';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { MemberBankAccount } from '@core/models/member-bank-account.model';
import { MerchantBank } from '@core/models/merchant-bank.model';
import { CCID } from '@core/models/ccid.model';

@Injectable()
export class MemberBankAccountHttpService {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  constructor(private http: HttpClient) { }

  getWithQuery(pageParam: string): Observable<MemberBankAccount[]>{
    return this.http.get<ApiResponse>(`/bankaccount${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  getMemberBanks(params?: string): Observable<MerchantBank[]> {
    return this.http.get<ApiResponse>(`/member?username=${params}`).pipe(
      map(res => res.data.rows)
    );
  }

  add(memberBankAccount: any): Observable<MemberBankAccount>{
    return this.http.post<ApiResponse>(`/bankaccount`, memberBankAccount).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.rows)
    )
  }

  update(memberBankAccount: any) {
    return this.http.put<ApiResponse>(`/bankaccount/${memberBankAccount.id}`, memberBankAccount);
  }

  getById(id: number): Observable<MemberBankAccount>{
    return this.http.get<ApiResponse>(`/bankaccount/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  lookupCCID(memberBankAccount: any): Observable<CCID>{
    return this.http.post<ApiResponse>(`/bankaccount/ccid`, memberBankAccount).pipe(
      map(res => res.data.rows)
    )
  }

  reuploadDocument(data, id) {
    return this.http.put<ApiResponse>(`/bankaccount/reupload-document/${id}`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if(res) {
      this.pagination = res.data.paginations;
    }
  }

}
