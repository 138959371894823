<!-- begin:: Header Topbar -->
<div class="kt-header__topbar">
  <div class="notification-container">
    <!-- ACTION -->
    <ng-container *ngIf="isAdmin || userPermissions$ != null">
      <!-- CLEAR CACHE -->
      <a *ngIf="canClearCache || isAdmin" matTooltip="Clear Cache" class="icon-cache" (click)="onOpenDialog()">
        <i class="notif-icon fas fa-trash-alt "></i>
      </a>
      <!-- MANUAL SYNC -->
      <a *ngIf="canManualSync || isAdmin" matTooltip="Manual Sync" class="icon-cache" (click)="onOpenDialog('sync')">
        <i class="notif-icon fas fa-sync"></i>
      </a>
      <!-- SEPARATOR -->
      <a [matTooltip]="'Notification Sound' + notificationSound" class="icon-cache sound-icon" (click)="onNotificationOnOff()">
        <i class="fas fa-volume-mute" [ngClass]="notificationSound === 'on' ? 'fa-volume-up' : 'fa-volume-mute'"></i>
      </a>
      <!-- SEPARATOR -->
      <span *ngIf="canClearCache || canManualSync || isAdmin" class="icon-separator"></span>
    </ng-container>

    <!-- NOTIFICATION -->
    <ng-container *ngIf="(notification$ | async) as notification; else elseIconOnly">
      <!-- AFFILIATE NOTIFICATION -->
      <span class="kt-ml-5">
        Affiliate
      </span>
      <!-- NEW AFFILIATE / AFFILIATE APPROVALS -->
      <a *ngIf="canUseAffiliateApprovalsShortcut" matTooltip="New Affiliate" routerLink="/general/affiliate-approval">
        <i class="notif-icon fas fa-user-check"></i>
        <span class="rounded-circle text-light position-fixed notification-badge text-center badge-header" [ngClass]="notification.affiliateAccount == 0 ? 'invisible' : ''">{{ notification.affiliateAccount }}</span>
      </a>
      <!-- AFFILIATE DEPOSITS -->
      <a *ngIf="canUseAffiliateDepositShortcut" matTooltip="Deposit" routerLink="/general/affiliate-deposits" class="kt-ml-5">
        <i class="notif-icon fas fa-download"></i>
        <span class="rounded-circle text-light position-fixed notification-badge text-center badge-header" [ngClass]="notification.affiliateDeposit == 0 ? 'invisible' : ''">{{ notification.affiliateDeposit }}</span>
      </a>
      <!-- AFFILIATE WITHDRAWALS -->
      <a *ngIf="canUseAffiliateWithdrawalShortcut" matTooltip="Withdraw" routerLink="/general/affiliate-withdrawals">
        <i class="notif-icon fas fa-upload"></i>
        <span class="rounded-circle text-light position-fixed notification-badge text-center badge-header" [ngClass]="notification.affiliateWithdraw == 0 ? 'invisible' : ''">{{ notification.affiliateWithdraw }}</span>
      </a>
      <!-- SEPARATOR -->
      <span class="icon-separator kt-ml-5"></span>
      <!-- MEMBER NOTIFICATION -->
      <span class="kt-ml-5">
        Member
      </span>
      <!-- DEPOSITS -->
      <a *ngIf="canUseDepositShortcut" matTooltip="Deposit" routerLink="/general/deposits">
        <i class="notif-icon fas fa-download"></i>
        <span class="rounded-circle text-light position-fixed notification-badge text-center badge-header" [ngClass]="notification.deposit == 0 ? 'invisible' : ''">{{ notification.deposit }}</span>
      </a>
      <!-- WITHDRAWALS -->
      <a *ngIf="canUseWithdrawalShortcut" matTooltip="Withdraw" routerLink="/general/withdrawals">
        <i class="notif-icon fas fa-upload"></i>
        <span class="rounded-circle text-light position-fixed notification-badge text-center badge-header" [ngClass]="notification.withdraw == 0 ? 'invisible' : ''">{{ notification.withdraw }}</span>
      </a>
      <!-- TRANSFER -->
      <a matTooltip="Transfers" (click)="onRedirectToTransferPage()" *ngIf="hasTransfers">
        <i class="fas fa-exchange-alt"></i>
        <span class="rounded-circle text-light position-fixed notification-badge text-center badge-header" [ngClass]="notification.transfer == 0 ? 'invisible' : ''">{{ notification.transfer }}</span>
      </a>      
      <!-- MEMBER PROMOTION -->
      <a *ngIf="canUseMemberPromotionShortcut" matTooltip="Member Promotions" routerLink="/general/member-promotions">
        <i class="notif-icon flaticon2-shopping-cart"></i>
        <span class="rounded-circle text-light position-fixed notification-badge text-center badge-header" [ngClass]="notification.memberPromotion == 0 ? 'invisible' : ''">{{ notification.memberPromotion }}</span>
      </a>
      <!-- NOTIFICATION COUNT -->
      <a (click)="openNotificationMenu()">
        <span class="rounded-circle text-light position-fixed notification-badge text-center badge-header" [ngClass]="notification.notification_count == 0 ? 'invisible' : ''">{{ notification.notification_count }}</span>
        <div class="kt-header__topbar-item" style="margin-right: 0 !important;">
          <div class="kt-header__topbar-wrapper">
            <span class="kt-header__topbar-icon kt-header__topbar-icon--success" [ngClass]="{'kt-pulse kt-pulse--brand': (notification.notification_count > 0) }" style="width: 17px; height: 16px;">
              <i class="notif-icon fa fa-bell"></i>
              <span class="kt-pulse__ring" [hidden]="notification.notification_count >= 1 ? false : true"></span>
            </span>
            <span class="kt-badge kt-badge--dot kt-badge--notify kt-badge--sm kt-badge--brand" [hidden]="true"></span>
          </div>
        </div>
      </a>
      <!-- DOWNLOADS -->
      <a *ngIf="canViewOwnDownloadsHistory || canViewAllDownloadsHistory" matTooltip="Downloads" routerLink="/download-area">
        <i class="notif-icon fas fa-file-download"></i>
        <span class="rounded-circle text-light position-fixed notification-badge text-center badge-header" [ngClass]="notification.downloads == 0 ? 'invisible' : ''">{{ notification.downloads }}</span>
      </a>
      <!-- IMPORT HISTORY -->
      <a *ngIf="canViewOwnImportHistory || canViewAllImportHistory" matTooltip="Import History" routerLink="/import-history">
        <i class="notif-icon fas fa-file-upload"></i>
        <span class="rounded-circle text-light position-fixed notification-badge text-center badge-header" [ngClass]="notification.imports == 0 ? 'invisible' : ''">{{ notification.imports }}</span>
      </a>
    </ng-container>
    <ng-template #elseIconOnly>
      <span class="kt-ml-5">
        Affiliate
      </span>
      <!-- NEW AFFILIATE / AFFILIATE APPROVALS -->
      <a *ngIf="canUseAffiliateApprovalsShortcut" matTooltip="New Affiliate" routerLink="/general/affiliate-approval">
        <i class="notif-icon fas fa-user-check"></i>
      </a>
      <!-- AFFILIATE DEPOSITS -->
      <a *ngIf="canUseAffiliateDepositShortcut" matTooltip="Deposit" routerLink="/general/affiliate-deposits" class="kt-ml-5">
        <i class="notif-icon fas fa-download"></i>
      </a>
      <!-- AFFILIATE WITHDRAWALS -->
      <a *ngIf="canUseAffiliateWithdrawalShortcut" matTooltip="Withdraw" routerLink="/general/affiliate-withdrawals">
        <i class="notif-icon fas fa-upload"></i>
      </a>
      <!-- SEPARATOR -->
      <span class="icon-separator kt-ml-5"></span>
      <span class="kt-ml-5">
        Member
      </span>
      <!-- DEPOSITS -->
      <a *ngIf="canUseDepositShortcut" matTooltip="Deposit" routerLink="/general/deposits">
        <i class="notif-icon fas fa-download"></i>
      </a>
      <!-- WITHDRAWALS -->
      <a *ngIf="canUseWithdrawalShortcut" matTooltip="Withdraw" routerLink="/general/withdrawals">
        <i class="notif-icon fas fa-upload"></i>
      </a>
      <!-- MEMBER PROMOTION -->
      <a *ngIf="canUseMemberPromotionShortcut" matTooltip="Member Promotions" routerLink="/general/member-promotions">
        <i class="notif-icon flaticon2-shopping-cart"></i>
      </a>
      <!-- NOTIFICATION COUNT -->
      <a>
        <div class="kt-header__topbar-item" style="margin-right: 0 !important;">
          <div class="kt-header__topbar-wrapper">
              <i class="notif-icon fa fa-bell"></i>
          </div>
        </div>
      </a>
      <!-- DOWNLOADS -->
      <a *ngIf="canViewOwnDownloadsHistory || canViewAllDownloadsHistory" matTooltip="Downloads" routerLink="/download-area">
        <i class="notif-icon fas fa-file-download"></i>
      </a>
    </ng-template>
  </div>

  <kt-language-selector></kt-language-selector>
  <kt-user-profile></kt-user-profile>
  <h5><i class="date-icon far fa-clock"></i>{{ todayDate }}</h5>

</div>

<div class="d-flex justify-content-center" *ngIf="notificationMessages">
  <kt-notification [skin]="'dark'" [isOpen]="isOpenNotification" (redirected)="onRedirect($event)">
  </kt-notification>
</div>
<!-- end:: Header Topbar -->
