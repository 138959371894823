import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Status } from '@core/enums/status.enum';
import { Campaign } from '@core/models/campaign.model';
import { Pagination } from '@core/models/pagination.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { environment } from '@env/environment';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription } from 'rxjs';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { CampaignAdCostDialogComponent } from './../campaign-ad-cost/dialogs/campaign-ad-cost-details.component';
import { CampaignAdCostEntityService } from './../campaign-ad-cost/service/campaign-ad-cost-entity.service';
import { CampaignEditDialogComponent } from './dialogs/campaign-edit.component';
import { CampaignDataService } from './services/campaign-data.service';
import { CampaignEntityService } from './services/campaign-entity.service';
import { SiteDomainDataService } from './../../superuser/site-domain/services/site-domain-data.service';
import { CurrencyHttpService } from '@core/services/currency-http.service';
import { CampaignMemberListComponent } from './campaign-member-list/campaign-member-list.component';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('tooltipCode') tooltipCode: MatTooltip;

  form: FormGroup;
  status = Status;
  campaign$: Observable<Campaign[]>;

  pagination: Pagination;
  pageSize = 30;
  page = 1;
  maxSize = 5;
  params = '';
  recentParams = JSON.parse(sessionStorage.getItem('campaign_params'));
  messages$ = this.campaignDataService.messages$;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    promoTypes: this.dropdownHttpService.promoTypes,
    perPage: this.dropdownHttpService.perPage
  };
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  signUpUrl = environment.signUpUrl;
  loading = false;
  clearBtnLoading = false;
  searchBtnLoading = false;
  dataLength: number;
  siteDomain: any;

  default_sort_by = 'id';
  default_sort_order = 'desc';

  sortingConfig = {
    'id': 'desc',
    'name': 'desc',
    'currency_code': 'desc',
    'bonus_type': 'desc',
    'promotion_code': 'desc',
    'code': 'desc',
    'total_members': 'desc',
    'total_clicks': 'desc',
    'remarks': 'desc',
    'created_at': 'desc',
    'updated_at': 'desc',
  };

  sortingSelection = {
    'sort_by': this.default_sort_by,
    'sort_order': this.default_sort_order,
  };

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();

  // permissions
  canViewCampaignMembersList: boolean;
  canCreateCampaign: boolean;
  canEditCampaign: boolean;
  canViewAdCost: boolean;
  canViewAdCostList: boolean;
  canCreateAdCostCampaigns: boolean;
  canCreateAdCost: boolean;

  constructor(
    private loadingBar: LoadingBarService,
    private campaignDataService: CampaignDataService,
    private campaignEntityService: CampaignEntityService,
    private transactionHttpService: TransactionHttpService,
    private dropdownHttpService: DropdownHttpService,
    public dialog: MatDialog,
    private campaignAdCostEntityService: CampaignAdCostEntityService,
    private router: Router,
    private siteDomainDataService: SiteDomainDataService,
    private currencyHttpService: CurrencyHttpService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.setCurrencyDropdown();
    this.siteDomainDataService.getWithQuery(`?paginate=0&status=1&type=4`).subscribe(res => {
      this.siteDomain = res;
    })
    this.formInit();
    this.pagination = this.campaignDataService.pagination;
    this.ranges = this.transactionHttpService.ranges;

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewCampaignMembersList = appPermissions.view_campaign_members_list;
      this.canCreateCampaign = appPermissions.create_campaign;
      this.canEditCampaign = appPermissions.edit_campaign;
      this.canViewAdCost = appPermissions.campaigns_view_ad_cost;
      this.canViewAdCostList = appPermissions.view_ad_costs_list;
      this.canCreateAdCostCampaigns = appPermissions.campaigns_create_ad_cost;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
    sessionStorage.setItem('recent_page', null);
  }

  ngAfterViewInit() {
    if (JSON.parse(sessionStorage.getItem('recent_page')) === 'campaignUrl'){
      this.setRecentParams();
    }
    sessionStorage.setItem('recent_page', null);
  }

  onClearDate() {
    if (this.form.value.start_date !== null){
      this.form.patchValue({defaultDate: null});
    }
  }

  onDateRange(event: any) {
    if (event){
      this.form.patchValue({
        start_date: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        end_date: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    this.loading = true;
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    this.loadingBar.start();
    return this.campaign$ = this.campaignEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}&${this.generateSortingParam()}`).pipe(
      tap(res => {
        this.loading = false;
        this.dataLength = res.length;
        this.pagination = this.campaignDataService.pagination;
        this.loadingBar.complete();
      })
    );
  }

  onPerPage(size: Event){
    this.page = 1;
    this.pageSize = +(size.target as HTMLSelectElement).value;
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  onOpenDialog(type: string, campaignId?: number) {
    if (campaignId) {
      if (type === 'edit') {
          const campaign = this.campaignEntityService.getByKey(campaignId);
          this.subscription = campaign.pipe(
            tap( (res) => {
              this.openDialogBy(CampaignEditDialogComponent, { campaign: res, mode: 'edit' });
          })).subscribe();
      }else if ( type === 'viewAdCost'){
          const adCostData = this.campaignAdCostEntityService.getWithQuery(`?campaign_id=${campaignId}`);
          this.subscription = adCostData.pipe(
            tap( (res) => {
              this.openDialogBy(CampaignAdCostDialogComponent, { adCost: res, mode: type });
          })).subscribe();
      } else if(type === 'campaign-members'){
        this.openDialogBy(CampaignMemberListComponent, { mode: type, campaignID: campaignId });
      }else{
        this.openDialogBy(CampaignAdCostDialogComponent, { mode: type, campaignID: campaignId });
      }
    } else {
      this.openDialogBy(CampaignEditDialogComponent, { mode: 'create' });
    }
  }

  onClear() {
    this.clearBtnLoading = true;
    this.form.patchValue({
      status: 'all',
      start_date: null,
      end_date: null,
      code: null,
      name: null,
      currency_id: 'all',
      defaultDate: null,
      promo_type: 'all',
      promotion_code: null,
    });
    this.onSubmit(true);
  }

  onSubmit(clearSearch?: boolean) {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    this.searchBtnLoading = clearSearch ? false : true;
    this.loading = true;
    of(this.form.value).pipe(
      map(this.filterFormFields),
      exhaustMap((data) => {
        if (data['start_date']) {
          data['start_date'] = moment(data['start_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
          data['end_date'] = moment(data['end_date']).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
        }

        this.params = Object.keys(data).map(key => key + '=' + data[key]).join('&');
        const parameters = this.params ? `&${this.params}` : '';
        this.loadingBar.start();
        return this.campaign$ = this.campaignEntityService.getWithQuery(`?perPage=${this.pageSize}${parameters}&${this.generateSortingParam()}`).pipe(
          tap(res => {
            this.page = 1;
            this.loading = false;
            this.clearBtnLoading = false;
            this.searchBtnLoading = false;
            this.dataLength = res.length;
            this.pagination = this.campaignDataService.pagination;
            this.loadingBar.complete();
          })
        );
      }),
    ).subscribe();
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onSortColumn(property: string) {
    // Reset other columns
    for (const key in this.sortingConfig) {
      if (Object.prototype.hasOwnProperty.call(this.sortingConfig, key)) {
        if (key == property) {
          this.sortingConfig[key] = this.sortingConfig[key] === 'asc' ? 'desc' : 'asc';
        } else {
          this.sortingConfig[key] = 'desc';
        }
      }
    }
    this.sortingSelection.sort_by = property;
    this.sortingSelection.sort_order = this.sortingConfig[property];
    this.onViewPageBy(this.page, this.pageSize, this.params);
  }

  private setRecentParams(){
    this.form.patchValue(this.recentParams);
  }

  private openDialogBy(componentRef: any, data?: { campaign?: any, mode?: any, adCost?: any, campaignID?: number }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: data.mode === 'campaign-members'? '1500px': '800px',
      height: 'auto',
      data: {
        campaign: data.campaign,
        mode: data.mode,
        adCost: data.adCost,
        campaignID: data.campaignID
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true){
        this.onViewPageBy(this.page).subscribe();
      }
    });
  }

  private filterFormFields(formData: any) {
    const fields = {};
    Object.keys(formData).forEach(key => {
      if (formData[key] !== '' && formData[key] !== null && formData[key] !== 'all' && key !== 'defaultDate') {
        fields[key] = formData[key];
      }
    });
    return fields;
  }

  private formInit() {
    this.form = new FormGroup({
      status: new FormControl('all'),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
      code: new FormControl(null),
      name: new FormControl(null),
      currency_id: new FormControl('all'),
      defaultDate: new FormControl(null), // Do not remove: For Clearing The Range
      promo_type: new FormControl('all'),
      promotion_code: new FormControl(null)
    });
  }

  openAdCost(name: string, id: string){
    this.router.navigate(['marketeer/ad-cost'], {state: { campaignName: name, campaignId: id}});
  }

  getLink(campaign: any) {
    let dialogParam = '';
    if (campaign.dialog_pop_name) {
      dialogParam = '&dialog=' + campaign.dialog_pop_name.substring(0,5);
    }
    
    let siteDomain = this.siteDomain.filter(x => x.site_id === campaign.site_id && x.device_type == 1);
    if (siteDomain.length > 0) {
      return 'https://' + siteDomain[0].domain + '/signup?campaign=' + campaign.code + dialogParam;
    }
    else {
      return null;
    }
  }

  private setCurrencyDropdown() {
    if (this.dropdown.currencies.length === 0) {
      // set interval to get currencies from sessionStorage, to prevent returning empty currency dropdown
      if (sessionStorage.getItem('currencies') != undefined && sessionStorage.getItem('currencies') != null && sessionStorage.getItem('currencies') !== '') {
        var interval = setInterval(() => {
          this.dropdown.currencies = JSON.parse(sessionStorage.getItem('currencies'));
          if (this.dropdown.currencies != null) {
            clearInterval(interval);
          }
        }, 100);
      } else {
        this.currencyHttpService.setCurrency().subscribe(res => {
          this.dropdown.currencies = res;
        });
      }
    }
  }

  private generateSortingParam() {
    const sortingParams = Object.keys(this.sortingSelection).map(key => key + '=' + this.sortingSelection[key]).join('&');
    return sortingParams;
  }
  
  checkDialogValidityDate(row: any) {
    var today = new Date();
    var start_date = new Date(row.dialog_pop_start_date);

    if (row.dialog_pop_end_date != null) {
      var end_date = new Date(row.dialog_pop_end_date);
      if (today > end_date || today < start_date) {
        return true;
      }
      else {
        return false;
      }
    }
    else if (today < start_date) {
      return true;
    }
    else {
      return false;
    }
  }
}
