<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Assign Leads</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
        <!-- Currency -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label">Currency <span class="text-danger">*</span> </label>
          <select formControlName="settings_currency_id" class="col-8 form-control"  (change)="onChangeCurrency($event.target.value)">
            <option value="null" disabled hidden> Please Select </option>
            <option [value]="value.id" *ngFor="let value of currencies"> {{ value.name }} </option>
          </select>
        </div>
        <!-- Telemarketer -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label">Telemarketer <span class="text-danger">*</span></label>
          <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8" style="padding:0"
            [form] = 'form'
            [dropdownList] = 'telemarketerDropdownList'
            [dropdownSettings] = 'telemarketerDropdownSettings'
            [formName] = "'telemarketer_id'"
            [selectionAttributes] = "'id'"
            [selectedItems] = 'telemarketerSelectedItems'
            (deSelectAll) = "onTelemarketerIdChanges()"
            (deSelectItem) = "onTelemarketerIdChanges()"
            (closeTriggered) = "onTelemarketerIdChanges()">
          </kt-dropdown-wo-lazyload>
        </div>
        <!-- Source Type -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label">{{ 'Source Type' | translate }} <span class="text-danger">*</span></label>
          <kt-dropdown-wo-lazyload class="dropdown-maxheight col-8" style="padding:0"
            [form] = 'form'
            [dropdownList] = 'soureTypeDropdownList'
            [dropdownSettings] = 'soureTypeDropdownSettings'
            [formName] = "'source_type'"
            [selectionAttributes] = "'key'"
            [selectedItems] = 'soureTypeSelectedItems'
            (deSelectAll) = "onTelemarketerIdChanges()"
            (deSelectItem) = "onTelemarketerIdChanges()"
            (closeTriggered) = "onTelemarketerIdChanges()">
          </kt-dropdown-wo-lazyload>
          <div *ngIf="form.get('source_type').invalid && form.get('source_type').errors.required" class="col-4">
          </div>
          <div *ngIf="form.get('source_type').invalid" class="text-danger col-8">
            <div *ngIf="form.get('source_type').errors.required">Source Type is required.</div>
          </div>
        </div>
        <!-- No. of Fresh Leads -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label">No. of Fresh Leads<i class="fa fa-info-circle lead-tooltip" placement="top" matTooltip="The leads that are imported through CSV file and have not been assigned to a telemarketer."></i></label>
          <input *ngIf="freshLeads == 0" class="col-8 form-control" disabled>
          <input *ngIf="freshLeads > 0" type="number" (wheel)="false" min="1" formControlName="fresh_leads" class="col-8 form-control">
          <!-- Available leads -->
          <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
          <small id="passwordHelpBlock" class="form-text text-muted col-8">
            Available leads:
            <ng-container *ngIf="getLeadsList"> {{ freshLeads | number:'1.0-2'}}</ng-container>
            <ng-container *ngIf="!getLeadsList">-</ng-container>
          </small>
        </div>
        <!-- No. of Member Leads, Registration Date -->
        <div class="col-12 form-group row pr-0 mb-0">
          <label class="col-4 col-form-label">No. of Member Leads <i class="fa fa-info-circle lead-tooltip" placement="top" matTooltip="The leads associated with active member accounts that have not made any deposits, have not been assigned to a telemarketer and were registered within the designated time frame."></i></label>
          <input *ngIf="buttonLoading || form.get('telemarketer_id').value == null" class="col-8 form-control" [value]="form.get('registration_start_datetime').value+' - '+form.get('registration_end_datetime').value" disabled>
          <input
              *ngIf="!buttonLoading && form.get('telemarketer_id').value != null"
              type="text" class="col-8 form-control" placeholder="Search"
              (change)="onDateRange($event)" formControlName="defaultDate"
              [timePicker]="true" [timePickerSeconds]="true"
              [alwaysShowCalendars]="true" [ranges]="ranges"
              [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale"
              [timePicker24Hour]="true" [showDropdowns]="true" (click)="updateDateRange()"
          ngxDaterangepickerMd/>
          <span class="input-group-append">
              <span class="input-group-text pointer-calendar clear-button" (click)="onClearDate()">X</span>
          </span>
          <!-- <label class="col-3 col-form-label">Inactivity Period<span class="text-danger">*</span></label> -->
          <!-- <input type="number" (wheel)="false" min="1" formControlName="inactivity_period" class="col-4 form-control"> -->
          <!-- Available leads -->
          <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
          <small id="passwordHelpBlock" class="form-text text-muted col-8 mb-3">
            Registration Date Time
          </small>
          <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
        </div>
        <!-- No. of Member Leads -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label"></label>
          <input *ngIf="inactiveLeads == 0" class="col-8 form-control" disabled>
          <input *ngIf="inactiveLeads > 0" type="number" (wheel)="false" min="1" formControlName="inactive_leads" class="col-8 form-control">
          <!-- <label class="col-3 col-form-label">Inactivity Period<span class="text-danger">*</span></label> -->
          <!-- <input type="number" (wheel)="false" min="1" formControlName="inactivity_period" class="col-4 form-control"> -->
          <!-- Available leads -->
          <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
          <small id="passwordHelpBlock" class="form-text text-muted col-8">
            Available leads: 
            <ng-container *ngIf="getLeadsList">{{ inactiveLeads | number:'1.0-2' }}</ng-container>
            <ng-container *ngIf="!getLeadsList">-</ng-container>
          </small>
          <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
        </div>
        <!-- No. of Cold Leads -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label">No. of Cold Leads<i class="fa fa-info-circle lead-tooltip" placement="top" matTooltip="The leads associated with active member accounts that have not made any deposits, have not been assigned to a telemarketer and were created more than 3 months ago."></i></label>
          <input *ngIf="coldLeads == 0" class="col-8 form-control" disabled>
          <input *ngIf="coldLeads > 0" type="number" (wheel)="false" min="1" formControlName="old_leads" class="col-8 form-control">
          <!-- Available leads -->
          <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
          <small id="passwordHelpBlock" class="form-text text-muted col-8">
            Available leads:
            <ng-container *ngIf="getLeadsList"> {{ coldLeads | number:'1.0-2'}}</ng-container>
            <ng-container *ngIf="!getLeadsList">-</ng-container>
          </small>
        </div>
        <!-- No. of Retarget Leads -->
        <div class="col-12 form-group row pr-0">
          <label class="col-4 col-form-label">No. of Retarget Leads<i class="fa fa-info-circle lead-tooltip" placement="top" matTooltip="The leads associated with member accounts that meet specific conditions, including not having made any deposits, are not assigned to certain telemarketers and the lead status is expired."></i></label>
          <input *ngIf="retargetLeads == 0" class="col-8 form-control" disabled>
          <input *ngIf="retargetLeads > 0" type="number" (wheel)="false" min="1" formControlName="retarget_leads" class="col-8 form-control">
          <!-- Available leads -->
          <small id="passwordHelpBlock" class="form-text text-muted col-4"> </small>
          <small id="passwordHelpBlock" class="form-text text-muted col-8">
            Available leads:
            <ng-container *ngIf="getLeadsList"> {{ retargetLeads | number:'1.0-2'}}</ng-container>
            <ng-container *ngIf="!getLeadsList">-</ng-container>
          </small>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <button *ngIf="(access$ | async).export" class="btn btn-primary btn-icon-sm mr-2" (click)="onExport()" type="button" [disabled]="buttonLoading || !form.valid || !checkAssignLead()"><i [class]="buttonLoading? 'fas fa-circle-notch fa-spin p-0 mr-2' : 'fas fa-save'"></i>Submit</button>
      </div>
    </div>
  </form>
</div>
