import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { AppPermissionService } from '@core/services/app-permission.service';
import { environment } from '@env/environment';
import Echo from 'laravel-echo';
import { DepositDataService } from '../services/deposit-data.service';

@Component({
  selector: 'deposit-wrapper',
  templateUrl: './deposit-wrapper.component.html',
  styleUrls: ['./deposit-wrapper.component.scss'],
})
export class DepositWrapperComponent implements OnInit {

  totalBanking = 0;
  totalCrypto = 0;
  echo: Echo;

  // permissions
  canViewBankingTab: boolean;
  canViewCryptoTab: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private appPermissionService: AppPermissionService,
    private depositDataService: DepositDataService,
  ) {
    const SocketIoClient = require('socket.io-client');
    const socketHost: string = environment.socketHost;
    const user_token = JSON.parse(localStorage.getItem('user_token'));
    try {
      this.echo = new Echo({
        broadcaster: 'socket.io',
        client: SocketIoClient,
        host: socketHost,
        auth: {
          headers: {
              'access-token': user_token.access_token,
              'token-selector': user_token.plaintext_token,
              'X-User-Model': 'bo'
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  ngOnInit() {

    // Permissions
    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewBankingTab = appPermissions.view_banking_deposits;
      this.canViewCryptoTab = appPermissions.view_crypto_deposits;

      this.cdr.detectChanges();
    });

    // Deposit count event listener
    const listener = (res) => {
      this.totalBanking = res.banking;
      this.totalCrypto = res.crypto;
      this.cdr.detectChanges();
    };
    this.echo.channel('deposit-count-channel').listen('.DepositCountEvent', listener);

    this.depositDataService.triggerDepositCount().subscribe();

  }
}