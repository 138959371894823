import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pagination } from '@core/models/pagination.model';
import { RecoveryCurrency } from '@core/models/recovery-currency.model';
import { RecoveryPromotion } from '@core/models/recovery-promotion.model';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PromotionCodeDataService } from '../../promotion-codes/services/promotion-code-data.service';
import { RecoveryCurrencyDataService } from '../recovery-currency/services/recovery-currency-data.service';
import { RecoveryCurrencyEntityService } from '../recovery-currency/services/recovery-currency-entity.service';
import { RecoveryCurrencyComponent } from './../recovery-currency/recovery-currency.component';
import { RecoveryMessagesComponent } from './../recovery-messages/recovery-messages.component';
import { RecoveryPromotionDataService } from './../services/recovery-promotion-data.service';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-recovery-promotion-edit',
  templateUrl: './recovery-promotion-edit.component.html',
  styleUrls: ['./recovery-promotion-edit.component.scss']
})
export class RecoveryPromotionEditDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  messages$ = this.recoveryPromotionDataService.messages$;
  refreshStatus: boolean;
  buttonLoading = false;
  pagination: Pagination;
  currentPage = 1;
  pageSize = 30;
  params = '';
  recoveryCurrency$: Observable<RecoveryCurrency[]>;

  // @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  pageNumber = 1;
  username = '';
  count = 0;
  promotionCodeDropdownSettings = {};
  promotionCodeDropdownList = [];
  promotionCodeSelectedItems = [];

  // permissions
  canViewRecoveryCurrency: boolean;
  canViewRecoveryMessages: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { row: RecoveryPromotion, mode: string },
    private recoveryPromotionDataService: RecoveryPromotionDataService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RecoveryPromotionEditDialogComponent>,
    private recoveryCurrencyEntityService: RecoveryCurrencyEntityService,
    private recoveryCurrencyDataService: RecoveryCurrencyDataService,
    private promotionCodeDataService: PromotionCodeDataService,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit(){
    this.formInit();
    this.promotionCodeDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 150, //'auto',
      primaryKey: 'id',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };
    this.promotionCodeDataService.getWithQuery(`?paginate=false`).subscribe(res => {
      this.promotionCodeDropdownList = res;
      this.promotionCodeDropdownList.map(function (elm) {
        elm['name'] = elm.code + ' - ' + elm.name;
      });
    });

    if(this.data.mode === 'edit'){
      if(this.data.row.promotion_id !== 0){
      this.promotionCodeSelectedItems.push({
        id: this.data.row.promotion_id,
        name: this.data.row.promotion_code + ' - ' + this.data.row.promotion_name
      });
    }}

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canViewRecoveryCurrency = appPermissions.view_recovery_currency;
      this.canViewRecoveryMessages = appPermissions.view_recovery_messages;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close();
  }

  onSave() {
    this.buttonLoading = true;

    this.form.setErrors({ 'invalid': true });

    const data = {
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    switch (this.data.mode) {
      case 'edit':
        this.subscription = forkJoin([
          this.recoveryPromotionDataService.updateCategory(this.data.row.id, data).pipe(
            tap(() => {
              this.buttonLoading = false;
              this.form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.recoveryPromotionDataService.messages$
        ]).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.recoveryPromotionDataService.add(data).pipe(
            tap(() => {
              this.buttonLoading = false;
              this.form.setErrors(null);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ),
          this.recoveryPromotionDataService.messages$
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  onDepositor(event: Event) {
    this.form.patchValue({
      depositor: (event.target as HTMLInputElement).checked ? 1 : 0
    });
  }

  onOpenDialog(type: string, recoveryCurrencyId?: number) {
    if (recoveryCurrencyId) {
      const recoveryCurr = this.recoveryCurrencyEntityService.getByKey(this.data.row.id);
      this.subscription = recoveryCurr.pipe(
        tap( (res) => {
          this.openDialogBy(RecoveryCurrencyComponent, { recoveryCurrency: res, recoverSetting: this.data.row });
      })
      )
      .subscribe();
    } else {
      this.openDialogBy(RecoveryCurrencyComponent, { recoveryCurrency: null, recoverSetting: this.data.row });
    }
  }

  onViewPageBy(page = 1, pageSize?: number, params?: string) {
    pageSize = this.pageSize;
    params = this.params ? `&${this.params}` : '';
    return this.recoveryCurrency$ = this.recoveryCurrencyEntityService.getWithQuery(`?page=${page}&perPage=${pageSize}${params}`).pipe(
      tap(res => {
        this.currentPage = page;
        this.pagination = this.recoveryCurrencyDataService.pagination;

      })
    );
  }

  onRecoveryMessagesDialog() {
    this.dialog.open(RecoveryMessagesComponent, {
      width: '1300px',
      data: {
        recoveryId: this.data.row.id
      }
    });
  }

  // onFocusField() {
  //   of(null).pipe(
  //       delay(0), tap(() => this.focusfield.first.nativeElement.focus()
  //   )).subscribe();
  // }


  private openDialogBy(componentRef: any, data?: { recoveryCurrency?: any, recoverSetting?: any }) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '1300px',
      data: {
        recoveryCurrency: data.recoveryCurrency,
        recoverySetting: data.recoverSetting
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      //this.onFocusField();
      if (result === true){
        this.onViewPageBy(this.currentPage).subscribe();
      }
    });
  }

  private formInit() {
    let name = null;
    let inactivity = null;
    let description = null;
    let depositor = 1;
    let status = 1;
    let promotion_id = null;

    if (this.data.mode === 'edit'){
      name = this.data.row.name;
      inactivity = this.data.row.inactivity;
      description =  this.data.row.description;
      depositor =  this.data.row.depositor;
      status =  this.data.row.status;
      promotion_id =  this.data.row.promotion_id;
    }
    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      inactivity: new FormControl(inactivity, Validators.compose([Validators.required, Validators.min(1)])),
      description: new FormControl(description),
      depositor: new FormControl(depositor, [Validators.required]),
      status: new FormControl(status),
      promotion_id: new FormControl(promotion_id)
    });
  }

}
