import { Subscription } from 'rxjs/internal/Subscription';
import { BankSettingsDataService } from './../services/bank-settings-data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadHttpService } from '@core/services/upload-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  selector: 'kt-bank-settings-edit',
  templateUrl: './bank-settings-edit.component.html',
  styleUrls: ['./bank-settings-edit.component.scss']
})
export class BankSettingsDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: this.dropdownHttpService.statuses,
    bankTypes: this.dropdownHttpService.merchantBankTypes,
    cryptoTokens: this.dropdownHttpService.cryptoTokens,
    types: [
      { id: 3, name: 'All' },
      { id: 1, name: 'Member' },
      { id: 2, name: 'Merchant' },
    ]
  };
  desktopLoading = false;
  mobileLoading = false;
  desktopImagePreview = [];
  mobileImagePreview = [];
  messages$ = this.bankSettingsDataService.messages$;
  refreshStatus: boolean;
  buttonLoading = false;
  currencySelectedItems = [];
  selectedCurrencies = [];
  selectedRequireReceipts = [];
  selectedBankType;
  selectedCryptoToken = [];
  selectedCryptoTokenForm = [];

  currencyDropdownSettings = {
    autoPosition: true,
    maxHeight: 150,
    singleSelection: false,
    text: 'Please Select',
    enableFilterSelectAll: true,
    enableSearchFilter: false,
    classes: 'dropdown',
    primaryKey: 'id',
    labelKey: 'name',
    lazyLoading: true,
    noDataLabel: '',
    showCheckbox: false
  };

  // permissions
  canCreateBankSettings: boolean;
  canUpdateBankSettings: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, bankSetting?: any},
    private dropdownHttpService: DropdownHttpService,
    private uploadService: UploadHttpService,
    private bankSettingsDataService: BankSettingsDataService,
    public dialogRef: MatDialogRef<BankSettingsDialogComponent>,
    private appPermissionService: AppPermissionService,
  ) { }

  ngOnInit() {
    this.formInit();
    this.setCurrency();
    this.setRequireReceipt();
    this.setCryptoToken();

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateBankSettings = appPermissions.create_bank_settings;
      this.canUpdateBankSettings = appPermissions.update_bank_settings;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  onSave() {
    this.buttonLoading = true;
    const data = {
      id: this.data.mode === 'edit' ? this.data.bankSetting.id : null,
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || (data[key] === '')) && delete data[key]);
    if (this.data.mode === 'create'){
      this.subscription = forkJoin([
        this.bankSettingsDataService.add(data).pipe(
          tap((res: any) => {
            this.form.setErrors(null);
            this.buttonLoading = false;
            this.refreshStatus = true;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ),
        this.bankSettingsDataService.messages$
      ]).subscribe();
    }else{
      this.subscription = forkJoin([
        this.bankSettingsDataService.updateBank(data).pipe(
          tap((res: any) => {
            this.form.setErrors(null);
            this.buttonLoading = false;
            this.refreshStatus = true;
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
        ),
        this.bankSettingsDataService.messages$
      ]).subscribe();
    }
  }

  onUploadFile(event: any, type: string) {
    if (type === 'desktop'){
      this.desktopLoading = true;
    }else{
      this.mobileLoading = true;
    }
    const file: File = event.target.files[0];
    const formData = new FormData();
    if (file !== undefined){
      formData.append('files', file, file.name);
      formData.append('type', 'bank');
      this.uploadService.upload(formData).subscribe(res => {
        if (type === 'desktop'){
          this.desktopLoading = false;
          this.desktopImagePreview = res;
          this.form.patchValue({
            desktop_image: this.desktopImagePreview[0]
          });
        }else{
          this.mobileLoading = false;
          this.mobileImagePreview = res;
          this.form.patchValue({
            mobile_image: this.mobileImagePreview[0]
          });
        }
      });
    }else{
      this.mobileLoading = false;
      this.desktopLoading = false;
    }
  }

  showRequireReceiptUpload() {
    let available_bank_type = [1,3];
    return this.form.value.bank_type && available_bank_type.includes(this.form.value.bank_type);
  }

  getCurrencyName(settings_currency_id) {
    return this.dropdown.currencies?.find(c => c.id === settings_currency_id)?.name;
  }

  onSelectedCurrencyChanged(currencies: Array<{id: number, name: string}>) {
    this.selectedRequireReceipts = currencies.map(
      (c) =>
        this.selectedRequireReceipts.find(
          (r) => c.id === r.settings_currency_id
        ) || {
          settings_currency_id: c.id,
          require_receipt: 1,
          name: this.getCurrencyName(c.id),
        }
    );

    this.form.patchValue({
      require_receipts: this.selectedRequireReceipts,
    });
  }

  onRequireReceiptChanged(event, settings_currency_id: number) {
    this.selectedRequireReceipts = this.selectedRequireReceipts.map((receipt) => {
      if (receipt.settings_currency_id != settings_currency_id) {
        return receipt;
      }

      return {
        ...receipt,
        require_receipt: +event.currentTarget.checked,
      };
    });

    this.form.patchValue({
      require_receipts: this.selectedRequireReceipts,
    });
  }

  private setCurrency(){
    const selectCurrency = () => {
      if (this.data.mode === 'edit'){
        this.data.bankSetting.currencies.map( bank => {
          this.currencySelectedItems.push(this.dropdown.currencies.find(v => v.id === bank.settings_currency_id));
          this.selectedCurrencies.push((this.dropdown.currencies.find(v => v.id === bank.settings_currency_id))['id']);
        });
        this.form.patchValue({
          currency: this.selectedCurrencies
        })
      }
    };
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
          this.dropdown.currencies = res;
          selectCurrency();
      });
    }else{
        selectCurrency();
    }
  } 

  private setRequireReceipt() {
    const formatRequireReceipt = () => {
      if (this.data.mode === 'edit') {
        this.selectedRequireReceipts = this.data.bankSetting.currencies.map(
          ({ settings_currency_id, require_receipt }) => ({
            settings_currency_id,
            require_receipt,
            name: this.getCurrencyName(settings_currency_id),
          })
        );
        this.form.patchValue({
          require_receipts: this.selectedRequireReceipts
        });
      }
    };

    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
          this.dropdown.currencies = res;
          formatRequireReceipt();
      });
      return;
    }

    formatRequireReceipt();
  }

  private formInit() {
    let code = null;
    let name = null;
    let bank_type = null;
    let type = null;
    let status = 1;
    let desktop_image = null;
    let mobile_image = null;
    let token = null;
    let crypto_network = null;
    let crypto_position = null;
    if (this.data.mode === 'edit'){
      code = this.data.bankSetting.code;
      name = this.data.bankSetting.name;
      bank_type = this.data.bankSetting.bank_type;
      type = this.data.bankSetting.currencies[0].type;
      status = this.data.bankSetting.status;
      desktop_image = this.data.bankSetting.desktop_image;
      mobile_image = this.data.bankSetting.mobile_image;
      crypto_network = this.data.bankSetting.crypto_network;
      crypto_position = this.data.bankSetting.crypto_position;
    }
    this.form = new FormGroup({
      currency: new FormControl(this.selectedCurrencies, [Validators.required]),
      require_receipts: new FormControl(this.selectedRequireReceipts, [Validators.required]),
      code: new FormControl(code, [Validators.required]),
      name: new FormControl(name, [Validators.required]),
      bank_type: new FormControl(bank_type, [Validators.required]),
      type: new FormControl(type, [Validators.required]),
      status: new FormControl(status, [Validators.required]),
      desktop_image: new FormControl(desktop_image),
      mobile_image: new FormControl(mobile_image),
      token: new FormControl(token),
      crypto_network: new FormControl(crypto_network),
      crypto_position: new FormControl(crypto_position),
    });

    this.onSelectBankType();
  }

  onSelectBankType() {
    this.selectedBankType = this.form.value.bank_type;
    if( this.selectedBankType == 5 ) {
      this.form.get('token').setValidators(Validators.required);
    } else {
      this.form.get('token').clearValidators();
    }
    this.form.get('token').updateValueAndValidity();
  }

  async setCryptoToken() {
    if (this.data.mode === 'edit') {
      const tokenList = await this.dropdown.cryptoTokens.toPromise();
      for( const cryptoToken of tokenList ) {
        if( this.data.bankSetting.crypto_tokens.some(item => item.crypto_token_id === cryptoToken.id) ) {
          this.selectedCryptoToken.push(cryptoToken);
          this.selectedCryptoTokenForm.push(cryptoToken.id);
        }
      }
      this.form.patchValue({
        token: this.selectedCryptoTokenForm
      });
    }
  }

  onSelectedCryptoTokenChanged(event) {
    this.selectedCryptoToken = event;
    this.selectedCryptoTokenForm = [];
    event.map(r => {
      this.selectedCryptoTokenForm.push(r.id);
    });
    this.form.patchValue({
      token: this.selectedCryptoTokenForm
    });
  }

}
