import { Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Withdraw } from '@core/models/withdraw.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AllLeadsDataService } from '../../services/all-leads-data.service';
import { Lightbox } from 'ngx-lightbox';

@Component({
  templateUrl: './all-leads-import.component.html',
  styleUrls: ['./all-leads-import.component.scss']
})
export class AllLeadsImportDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  form: FormGroup;
  dropdownSettings = {};
  private subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  params = '';
  loading = false;
  sample = [];
  fileName: any;
  file: any;
  data$ = this.AllLeadsDataService.data$;
  telemarketerDropdownSettings = {};
  telemarketerDropdownList = [];
  telemarketerSelectedItems = [];

  currencies = JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies'));

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { withdraw: Withdraw, mode: string, newHandler: string },
    public dialogRef: MatDialogRef<AllLeadsImportDialogComponent>,
    private AllLeadsDataService: AllLeadsDataService,
    public dialog: MatDialog,
    private lightbox : Lightbox, 
    private dropdownService: DropdownHttpService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if(this.currencies.length === 0){
      this.dropdownService.currencies.subscribe( res => {
        this.currencies = res;
      });
    }
    this.formInit();

    this.dropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      maxHeight: 200,
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.AllLeadsDataService.getTelemarketerList(``).subscribe(res => {
      this.telemarketerDropdownList = res;
    });

    this.telemarketerDropdownSettings = {
      singleSelection: true,
      text: 'Please Select',
      maxHeight: 100,
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog(confirm = false) {
    if (!this.loading) {
      this.dialogRef.close(confirm);
    }
  }

  onFileUpload(event: any){
    const file: File = event.target.files[0];
    this.file = event.target;
    const formData = new FormData();
    formData.append('attached_file', file);
    formData.append('currency_id', this.form.value.currency_id);
    this.loading = true;
    this.dialogRef.disableClose = true;
    this.AllLeadsDataService.importValidate(formData).pipe(
      tap((_) => {
        this.loading = false;
        this.dialogRef.disableClose = false;
        this.fileName = file.name;
      }),
      catchError((error) => {
        this.loading = false;
        this.dialogRef.disableClose = false;
        this.fileName = null;
        throw error;
      })
    ).subscribe();
  }

  onSave() {
    const file: File = this.file.files[0];
    const formData = new FormData();
    formData.append('attached_file', file);
    formData.append('currency_id', this.form.value.currency_id);
    if (this.form.value.telemarketer_id != null) {
      formData.append('telemarketer_id', this.form.value.telemarketer_id);
    }

    this.buttonLoading = true;
    this.dialogRef.disableClose = true;
    this.AllLeadsDataService.import(formData).pipe(
      tap((_) => {
        this.buttonLoading = false;
        this.dialogRef.disableClose = false;
        this.dialogRef.close(true);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.dialogRef.disableClose = false;
        this.file = null;
        this.cdr.detectChanges();
        throw error;
      })
    ).subscribe();

    this.file = null;
  }

  resetFileUpload(value: any) {
    this.file = null;
    this.fileName = null;

    this.cdr.detectChanges();
    this.onChangeCurrency(value);
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onOpenSample() {
    this.sample = [{
      src: '/assets/img/import_sample.png',
      caption: 'SAMPLE',
      thumb: '/assets/img/import_sample.png'
    }];

    this.lightbox.open(this.sample, 0, { centerVertically: true, disableScrolling: true, fitImageInViewPort: true, fadeDuration: 0.01, resizeDuration: 0.01 });
  }

  onChangeCurrency(value: any) {
    this.AllLeadsDataService.getTelemarketerList(`?settings_currency_id=${value}`).subscribe(res => {
      this.telemarketerDropdownList = res;
      this.cdr.detectChanges();
    });
  }

  private formInit() {
    this.form = new FormGroup({
      currency_id: new FormControl(null, [Validators.required]),
      telemarketer_id: new FormControl(null)
    });
  }

}
