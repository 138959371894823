import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { of, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AllLeadsDataService } from '../../services/all-leads-data.service';
import { AuthHttpService } from '@core/services/auth-http.service';
import { TransactionHttpService } from '@core/services/transaction-http.service';
import * as moment from 'moment-timezone';

@Component({
  templateUrl: './all-leads-assign.component.html',
  styleUrls: ['./all-leads-assign.component.scss']
})
export class AllLeadsAssignDialogComponent implements OnInit, OnDestroy {

  @ViewChildren('focusfield') focusfield: QueryList<ElementRef>;
  form: FormGroup;
  availableLead = 0;
  telemarketerDropdownSettings = {};
  telemarketerDropdownList = [];
  telemarketerSelectedItems = [];
  private subscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;
  freshLeads = 0;
  inactiveLeads = 0;
  coldLeads = 0;
  retargetLeads = 0;
  getLeadsList = false;

  access$ = this.authHttpService.getUserAccess(13, 'All Leads');
  params = '';
  ranges: any;
  dateTimePickerLocale = this.transactionHttpService.dateTimePickerLocale;
  timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
  from = this.transactionHttpService.getLast7Days().from;
  to = this.transactionHttpService.getToday().to;

  currencies = JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies'));

  soureTypeDropdownSettings = {};
  soureTypeDropdownList =  [];
  soureTypeSelectedItems = []; // default select all item

  sourceTypesLists = [
    { id: 1, name: 'Affiliate' ,key: 0 },
    { id: 2, name: 'Telemarketer',key: 1  },
    { id: 3, name: 'Player Referral',key: 2  },
    { id: 4, name: 'Organic',key: 3  }
  ];
  constructor(
    public dialogRef: MatDialogRef<AllLeadsAssignDialogComponent>,
    private allLeadsDataService: AllLeadsDataService,
    private dropdownService: DropdownHttpService,
    private cdr: ChangeDetectorRef,
    private eventEmitterService: EventEmitterService,
    private authHttpService: AuthHttpService,
    private transactionHttpService: TransactionHttpService,
  ) { }

  ngOnInit() {
    this.soureTypeDropdownList = this.sourceTypesLists;
    this.soureTypeSelectedItems = this.sourceTypesLists;
    if (this.currencies.length === 0) {
      this.dropdownService.currencies.subscribe(res => {
        this.currencies = res;
      });
    }
    this.formInit();
    this.allLeadsDataService.getTelemarketerList(``).subscribe(res => {
      this.telemarketerDropdownList = res;
    });

    this.telemarketerDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      maxHeight: 200,
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'username',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };

    this.soureTypeDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      maxHeight: 'auto',
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      lazyLoading: false,
      noDataLabel: '',
      showCheckbox: false
    };

    this.ranges = this.transactionHttpService.ranges;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onRefresh();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  onExport() {
    this.buttonLoading = true;
    this.form.setErrors({ 'invalid': true });
    delete this.form.value.defaultDate;
    const data = {
      ...this.form.value
    };
    Object.keys(data).forEach((key) => ( key !== 'source_type' && (data[key] == null || data[key] === '' || data[key] < 1 )) && delete data[key]);
    if (this.form.controls.registration_start_datetime.value !== null && this.form.controls.registration_end_datetime.value !== null) {
      data['registration_start_datetime'] = moment(this.form.controls.registration_start_datetime.value).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
      data['registration_end_datetime'] = moment(this.form.controls.registration_end_datetime.value).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
    }

    if (data['inactive_leads'] === undefined) delete data['inactivity_period'];

    if(data['source_type']){
      data['source_type'] = this.form.controls['source_type'].value.toString();
    }

    this.subscription = this.allLeadsDataService.export(data).pipe(
      tap(() => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        this.dialogRef.close(true);
      }),
      catchError((error) => {
        this.buttonLoading = false;
        this.form.setErrors(null);
        throw error;
      })
    ).subscribe();
    this.refreshStatus = true;
  }

  onRefresh() {
    if (this.refreshStatus === true) {
      this.dialogRef.close(true);
    }
  }

  onChangeCurrency(value: any) {
    this.form.patchValue({
      settings_currency_id: value,
      telemarketer_id: null,
      fresh_leads: null,
      inactive_leads: null,
      // inactivity_period: 7,
      old_leads: null,
      retarget_leads: null,
    });
    this.freshLeads = 0;
    this.inactiveLeads = 0;
    this.coldLeads = 0;
    this.retargetLeads = 0;
    this.getLeadsList = false;
    this.eventEmitterService.onClearMerchantAccountSearch();
    this.allLeadsDataService.getTelemarketerList(`?settings_currency_id=${value}`).subscribe(res => {
      this.telemarketerDropdownList = res;
      this.cdr.detectChanges();
    });
  }

  getLeadStatistic() {
    this.timezone = JSON.parse(localStorage.getItem('user_data')).timezone;
    let from = null;
    let to = null;
    if (this.form.controls.registration_start_datetime.value !== null && this.form.controls.registration_end_datetime.value !== null) {
      from = moment(this.form.controls.registration_start_datetime.value).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
      to = moment(this.form.controls.registration_end_datetime.value).tz(this.timezone, true).utc().format('YYYY-MM-DD HH:mm:ss');
    }

    let telemarketerIdList = this.form.controls['telemarketer_id'].value;
    let telemarketerID = '';
    telemarketerIdList.forEach(function (value, index) {
      telemarketerID = telemarketerID + `telemarketer_id[${index}]=${value}&`
    })

    let sourceTypesList = this.form.controls['source_type'].value.toString();
    
    this.params = `?` + telemarketerID + `settings_currency_id=${this.form.controls['settings_currency_id'].value}&source_type=`+sourceTypesList;

    if (from !== null && to !== null) {
      this.params += `&registration_start_datetime=${from}&registration_end_datetime=${to}`;
    }

    this.buttonLoading = true;

    this.allLeadsDataService.getLeadStatistic(this.params
    ).subscribe(res => {

      this.form.patchValue({
        fresh_leads: null,
        inactive_leads: null,
        old_leads: null,
        retarget_leads: null,
      });
      
      this.freshLeads = res.fresh_leads;
      this.inactiveLeads = res.inactive_leads;
      this.coldLeads = res.cold_leads;
      this.retargetLeads = res.retarget_leads;
      this.getLeadsList = true;
      this.buttonLoading = false;
    });
  }

  onTelemarketerIdChanges() {
    const list = this.dropdownService.sourceTypesList;
    this.soureTypeDropdownList =  list;
    const val = this.form.get('telemarketer_id').value;
    const source_type =this.form.get('source_type').value;

    if (val !== null && source_type !== null) {
      if (val.length > 0 && source_type.length > 0) {
        this.getLeadStatistic();
      }
      if (val.length == 0 || source_type.length == 0) {
        this.freshLeads = 0;
        this.inactiveLeads = 0;
        this.coldLeads = 0;
        this.retargetLeads = 0;
        this.getLeadsList = false;
        this.cdr.detectChanges();
      }
    }else{
      this.freshLeads = 0;
      this.inactiveLeads = 0;
      this.coldLeads = 0;
      this.retargetLeads = 0;
      this.getLeadsList = false;
      this.cdr.detectChanges();
    }
  }

  private formInit() {
    this.form = new FormGroup({
      settings_currency_id: new FormControl(null),
      telemarketer_id: new FormControl(null, [Validators.required]),
      fresh_leads: new FormControl(null, [Validators.min(1)]),
      inactive_leads: new FormControl(null, [Validators.min(1)]),
      // inactivity_period: new FormControl(7, [Validators.min(1)]),
      old_leads: new FormControl(null, [Validators.min(1)]),
      retarget_leads: new FormControl(null, [Validators.min(1)]),
      registration_start_datetime: new FormControl(this.from),
      registration_end_datetime: new FormControl(this.to),
      defaultDate: new FormControl({
        startDate: this.from,
        endDate: this.to,
      }),
      source_type: new FormControl([0, 1, 2, 3], [Validators.required])
    });

    // this.form.controls['inactivity_period'].valueChanges.subscribe((val) => {
    //   if (val !== null && this.form.controls['telemarketer_id'].value !== null) {
    //     this.getLeadStatistic();
    //   }
    // });

    this.form.controls['telemarketer_id'].valueChanges.subscribe((val) => {
      if (val == null) {
        this.freshLeads = 0;
        this.inactiveLeads = 0;
        this.coldLeads = 0;
        this.retargetLeads = 0;
        this.getLeadsList = false;
        this.cdr.detectChanges();
      }
    });
  }

  onDateRange(event: any) {
    if (event) {
      this.form.patchValue({
        registration_start_datetime: event.startDate !== null && event.startDate !== undefined ? event.startDate : null,
        registration_end_datetime: event.endDate !== null && event.endDate !== undefined ? event.endDate : null
      });

      if (event.startDate !== null && event.startDate !== undefined && this.form.controls['telemarketer_id'].value !== null && this.form.controls['source_type'].value !== null) {
        this.getLeadStatistic();
      }
      
      this.form.patchValue({ 
        inactive_leads: null,
      });
    }
  }

  updateDateRange() {
    this.ranges = this.transactionHttpService.updateDateRange();
  }

  onClearDate() {
    this.form.patchValue({ 
      defaultDate: null,
      registration_start_datetime: null,
      registration_end_datetime: null,
    });

    this.getLeadStatistic();
  }

  checkAssignLead(){
    var selectedFreshLeads = false;
    var selectedInactiveleads = false;
    var selectedColdLeads = false;
    var selectedRetargetLeads = false;
    
    if(this.freshLeads > 0){
      if(this.form.value.fresh_leads > 0){
        selectedFreshLeads = true;
      }
    }

    if(this.inactiveLeads > 0){
      if(this.form.value.inactive_leads > 0){
        selectedInactiveleads = true;
      }
    }

    if(this.coldLeads > 0){
      if(this.form.value.old_leads > 0){
        selectedColdLeads = true;
      }
    }

    if(this.retargetLeads > 0){
      if(this.form.value.retarget_leads > 0){
        selectedRetargetLeads = true;
      }
    }

    return selectedFreshLeads == true || selectedInactiveleads == true  || selectedColdLeads == true || selectedRetargetLeads == true ? true : false;
  }
}
