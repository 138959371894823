<!-- First Layer -->
<ng-container>
  <form [formGroup]="form">
    <mat-accordion *ngFor="let section of sectionPermissions" formGroupName="permissions" togglePosition="before">
      <mat-expansion-panel
        [id]="'arp-section-' + section.code"
        [expanded]="sectionPanelOpenState[section.code]"
        class="mt-3"
        [attr.data-desc]="section.name"
        [attr.data-section]="section.code"
      >
        <!-- First Layer Header, e.g. Dashboard -->
        <!-- pr-0 + margin-right: 24px below to fix ripple cutoff issue on mat checkbox -->
        <mat-expansion-panel-header class="pr-0">
          <mat-panel-title class="parent-section-title-div">
            {{ section.name }}
            <ng-container *ngIf="isSectionHasPermissions(section.code)" formGroupName="sectionCheckAll">
              <!-- stopPropagation on (click) below is to prevent panel being toggled when pressing checkbox -->
              <mat-checkbox
                class="ml-auto"
                style="margin-right: 24px;"
                [formControlName]="section.code"
                [indeterminate]="somePermissionsComplete(section.code)"
                (click)="$event.stopPropagation()"
                (change)="onPermissionChangeAll($event.checked, section, section.code)"
              ></mat-checkbox>
            </ng-container>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- First Layer Permissions -->
        <div class="row m-0">
          <ng-container *ngFor="let permission of section.role_permissions" [formGroupName]="permission.code">
            <mat-checkbox
              class="col-6 p-0"
              matTooltip="{{ permission.description }}"
              formControlName="is_granted"
              (change)="onPermissionChange($event.checked, permission.code, section.code)"
            >
              {{ permission.display_title }}
            </mat-checkbox>
          </ng-container>
        </div>

        <!-- Second Layer, e.g. Members -> All Members -->
        <ng-container *ngFor="let children of section.children; let i = index">
          <!-- Second Layer Header -->
          <div class="d-flex justify-content-between" [ngClass]="{ 'pt-3': i > 0 }">
            <div class="alert alert-secondary px-2 py-1 mb-0" role="alert">
              {{ children.name }}
            </div>
            <ng-container *ngIf="isSectionHasPermissions(children.code)" formGroupName="sectionCheckAll">
              <mat-checkbox
                [formControlName]="children.code"
                (change)="onPermissionChangeAll($event.checked, children, section.code)"
                [indeterminate]="somePermissionsComplete(children.code)"
              ></mat-checkbox>
            </ng-container>
          </div>
          <mat-divider class="my-3"></mat-divider>
          <!-- Second Layer Permissions -->
          <div class="row m-0">
            <ng-container *ngFor="let permission of children.role_permissions" [formGroupName]="permission.code">
              <mat-checkbox
                class="col-6 p-0"
                matTooltip="{{ permission.description }}"
                formControlName="is_granted"
                (change)="onPermissionChange($event.checked, permission.code, section.code)"
              >
                {{ permission.display_title }}
              </mat-checkbox>
            </ng-container>
          </div>

          <!-- Third Layer e.g. Message Center -> SMS Provider Settings -> SMS Provider -->
          <ng-container *ngFor="let subChildren of children.children; let i = index">
            <!-- Third Layer Header -->
            <div class="ml-5 mt-3">
              <div class="d-flex justify-content-between" [ngClass]="{ 'pt-3': i > 0 }">
                <div class="alert alert-secondary sub px-2 py-1 mb-0" role="alert">
                  {{ subChildren.name }}
                </div>
                <ng-container *ngIf="isSectionHasPermissions(subChildren.code)" formGroupName="sectionCheckAll">
                  <mat-checkbox
                    [formControlName]="subChildren.code"
                    (change)="onPermissionChangeAll($event.checked, subChildren, section.code)"
                    [indeterminate]="somePermissionsComplete(subChildren.code)"
                  ></mat-checkbox>
                </ng-container>
              </div>
              <mat-divider class="my-3"></mat-divider>
              <!-- Third Layer Permissions -->
              <div class="row m-0">
                <ng-container *ngFor="let permission of subChildren.role_permissions" [formGroupName]="permission.code">
                  <mat-checkbox
                    class="col-6 p-0"
                    matTooltip="{{ permission.description }}"
                    formControlName="is_granted"
                    (change)="onPermissionChange($event.checked, permission.code, section.code)"
                  >
                    {{ permission.display_title }}
                  </mat-checkbox>
                </ng-container>
              </div>

              <!-- Fourth Layer, e.g. CMS Settings -> Footer Settings -> Section Settings -> Footer Section -->
              <ng-container *ngFor="let grandChildren of subChildren.children; let i = index">
                <!-- Fourth Layer Header -->
                <div class="ml-5 mt-3">
                  <div class="d-flex justify-content-between" [ngClass]="{ 'pt-3': i > 0 }">
                    <div class="alert alert-secondary sub px-2 py-1 mb-0" role="alert">
                      {{ grandChildren.name }}
                    </div>
                    <ng-container *ngIf="isSectionHasPermissions(grandChildren.code)" formGroupName="sectionCheckAll">
                      <mat-checkbox
                        [formControlName]="grandChildren.code"
                        (change)="onPermissionChangeAll($event.checked, grandChildren, section.code)"
                        [indeterminate]="somePermissionsComplete(grandChildren.code)"
                      ></mat-checkbox>
                    </ng-container>
                  </div>
                  <mat-divider class="my-3"></mat-divider>
                  <!-- Fourth Layer Permissions -->
                  <div class="row m-0">
                    <ng-container *ngFor="let permission of grandChildren.role_permissions" [formGroupName]="permission.code">
                      <mat-checkbox
                        class="col-6 p-0"
                        matTooltip="{{ permission.description }}"
                        formControlName="is_granted"
                        (change)="onPermissionChange($event.checked, permission.code, section.code)"
                      >
                        {{ permission.display_title }}
                      </mat-checkbox>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</ng-container>
