import { tap, map, concatMap } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Withdraw } from '@core/models/withdraw.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Pagination } from '@core/models/pagination.model';
import { Injectable } from '@angular/core';
import { WithdrawMultiBankTransaction } from '@core/models/withdraw-multi.model';

@Injectable()
export class WithdrawDataService extends DefaultDataService<Withdraw>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  messages2$ = new Subject<any[]>();
  isSuccess: boolean;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('Withdraw', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<Withdraw[]> {
    return this.http.get<ApiResponse>('/withdraw').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  add(withdraw: Withdraw): Observable<Withdraw> {
    return this.http.post<ApiResponse>(`/withdraw`, withdraw).pipe(
      tap(res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/withdraw/${entity.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  updateCurrentWithdraw(withdraw: any) {
    return this.http.put<ApiResponse>(`/withdraw/${withdraw.id}`, withdraw).pipe(
      map(res => res.data.rows)
    );
  }

  updateRemarks(withdraw: any) {
    return this.http.put<ApiResponse>(`/withdraw/remarks/${withdraw.id}`, withdraw).pipe(
      tap(res => this.messages$.next(res.message)),
      concatMap((entity) => this.http.get(`/withdraw/${entity.data.rows.id}`).pipe(
        map((row: ApiResponse) => row.data.rows)
      ))
    );
  }

  getById(id: number): Observable<Withdraw> {
    return this.http.get<ApiResponse>(`/withdraw/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<Withdraw[]> {
    return this.http.get<ApiResponse>(`/withdraw${pageParam}`).pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    )
  }

  createBankTransaction(data: WithdrawMultiBankTransaction) {
    return this.http.post<ApiResponse>(`/withdraw/banktransaction`, data).pipe(
      tap(res => {
        this.paginationInit(res);
        this.messages$.next(res.message)
      }),
      map(res => res.data.rows)
    );
  }

  exportWithdraw(pageParam: string) {
    return this.http.get<ApiResponse>(`/withdraw/export${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  createPayout(data: any) {
    return this.http.post<ApiResponse>(`/withdraw/payout`, data).pipe(
      tap(res => this.messages2$.next(res.message),
      ),
      map(res => res.data)
    )
  }

  getPayoutStatus(transactionId: number) {
    return this.http.get<ApiResponse>(`/withdraw/payout/status?bank_transaction_id=${transactionId}`).pipe(
      tap(res => this.messages$.next(res.message)),
      map(res => res.data.bank_transaction)
    )
  }

  getActiveSession(id: number) {
    return this.http.get<ApiResponse>(`/withdraw/activesession/${id}`).pipe(
      map(res => res.data)
    );
  }

  setActiveSession(data: any) {
    return this.http.post<ApiResponse>(`/withdraw/activesession`, data).pipe(
      map(res => res)
    );
  }

  triggerWithdrawCount() {
    return this.http.post<ApiResponse>(`/withdraw/counter`, []).pipe(
      tap(
        // res => this.messages$.next(res.message)
      )
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }

  getRemarksHistory(deposit_id: number) {
    return this.http.get<ApiResponse>(`/withdraw/remarks/${deposit_id}`).pipe(
      tap(
        // res => this.messages$.next(res.message)
      )
    );
  }
}
