import { RebateRelease } from '@core/models/rebate-release-model';
import { tap, map } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class NewRebateReleaseDataService extends DefaultDataService<RebateRelease>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();

  currentPageTotal = 0;
  totalAmount = 0;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('NewRebateRelease', http, httpUrlGenerator, { root: '' });
  }

  getWithQuery(pageParam: string): Observable<RebateRelease[]> {
    return this.http.get<ApiResponse>(`/rebaterelease${pageParam}`).pipe(
      tap(res => {
        this.paginationInit(res);
        this.currentPageTotal = res.data.current_page_total;
        this.totalAmount = res.data.total_amount;
      }),
      map(res => res.data.rows)
    );
  }

  generateRebate(data: any) {
    return this.http.post<ApiResponse>(`/rebaterelease/generatenew`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  release(data: any) {
    return this.http.post<ApiResponse>(`/rebaterelease/release`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  reject(data: any) {
    return this.http.post<ApiResponse>(`/rebaterelease/reject`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }
  
  updateRebate(data: any) {
    return this.http.post<ApiResponse>(`/rebaterelease/updaterebate`, data).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  showRebateDetails(id: number) {
    return this.http.get<ApiResponse>(`/rebaterelease/${id}`).pipe(
      map(res => res.data.rows)
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
