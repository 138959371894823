<section class="group bg-white p-3">
  <!-- Header Part -->
  <div class="row form-group m-0 px-0 pb-3">
    <div class="col-6 offset-3 p-0 d-flex justify-content-center">
      <button *ngIf="canViewMemberInfo" type="button" class="btn btn-tab mx-3 btn-selected" (click)="onSelectBasicInfoTab('Member Info')" id="member-info-button" [ngClass]="{ 'btn-selected': basicInfoActiveTab == 'Member Info' }">Member Info</button>
      <button *ngIf="canViewMemberStatistics" type="button" class="btn btn-tab mx-3" (click)="onSelectBasicInfoTab('Member Statistics')" id="member-statistics-button" [ngClass]="{ 'btn-selected': basicInfoActiveTab == 'Member Statistics' }">Member Statistics</button>
    </div>
    <div class="col-3 p-0 input-group-append search-filter h-100 w-100">
      <kt-dropdown-wo-lazyload [form]="searchForm" [dropdownList]="searchElementsData" [dropdownSettings]="searchFilterDropdownSettings" [formName]="'search'" [selectionAttributes]="'id'" style="width: 100%"> </kt-dropdown-wo-lazyload>
      <button class="btn btn-brand btn-icon-sm btn-search" (click)="onSearch()"><i class="fas fa-search"></i></button>
    </div>
  </div>

  <!-- Body Part -->
  <!-- Member Info -->
  <div [ngClass]="{ 'd-none': basicInfoActiveTab != 'Member Info' }">
    <!-- Member Basic Info -->
    <mat-accordion>
      <mat-expansion-panel [expanded]="true" class="member-info-content" #memberBasicInfoExpansionPanel>
        <!-- Title -->
        <mat-expansion-panel-header>
          <mat-panel-title> Member Basic Info </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="mt-0" />
        <!-- Content -->
        <mat-grid-list cols="4" rowHeight="7rem">
          <mat-grid-tile>
            <!-- Merchant -->
            <div id="merchant" data-desc="Merchant" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Merchant
              <br />
              <label class="label my-2">{{ member.merchant | uppercase }}</label>
            </div>
          </mat-grid-tile>
          <!-- Currency -->
          <mat-grid-tile>
            <div id="currency" data-desc="Currency" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Currency
              <br />
              <label class="label my-2">{{ member.currency_code | uppercase }}</label>
            </div>
          </mat-grid-tile>
          <!-- KYC Status -->
          <mat-grid-tile>
            <div id="kyc_status" data-desc="KYC Status" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              KYC Status
              <br />
              <span class="my-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + (member.kyc_progression == 'Pro' ? 1 : member.kyc_progression == 'Advanced' ? 5 : 0)">
                {{ member.kyc_progression }}
              </span>
            </div>
          </mat-grid-tile>
          <!-- Status -->
          <mat-grid-tile>
            <div id="status" data-desc="Status" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Status
              <br />
              <span class="my-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide" [ngClass]="'kt-badge--' + member.status"> {{ status[member.status] }}</span>
            </div>
          </mat-grid-tile>
          <!-- Name -->
          <mat-grid-tile>
            <div id="name" data-desc="Name" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Name
              <br />
              <label class="label my-2">{{ member.name?.length == 0 ? "-" : member.name }}</label>
            </div>
          </mat-grid-tile>
          <!-- Date of Birth -->
          <mat-grid-tile>
            <div id="dob" data-desc="Date of Birth" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Date of Birth
              <br />
              <label class="label my-2">{{ member.date_of_birth == "0000-00-00" ? "-" : member.date_of_birth }}</label>
            </div>
          </mat-grid-tile>
          <!-- Mobile -->
          <mat-grid-tile>
            <div id="mobile" data-desc="Mobile" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Mobile
              <br />
              <label class="label my-2">{{ member.mobile?.length == 0 ? "-" : member.mobile }}</label>
            </div>
          </mat-grid-tile>
          <!-- Email -->
          <mat-grid-tile>
            <div id="email" data-desc="Email" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Email
              <br />
              <label class="label my-2">{{ member.email?.length == 0 ? "-" : member.email }}</label>
            </div>
          </mat-grid-tile>
          <!-- Member Group -->
          <mat-grid-tile>
            <div id="member_group" data-desc="Member Group" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Member Group
              <br />
              <label class="label my-2">{{ member.group | titlecase }}</label>
            </div>
          </mat-grid-tile>
          <!-- VIP Progress -->
          <mat-grid-tile [colspan]="3">
            <div id="vip_progress" data-desc="VIP Progress" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary row d-flex" role="alert">
              <!-- Progress Bar -->
              <div class="col-7 h-100 p-0">
                VIP Progress
                <br />
                <div class="my-2 w-100">
                  <div *ngIf="displayVIPProgressBar" class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [ngStyle]="{ width: member.vip_progress != 1 ? depositProgressBarWidth : '100%' }">{{ member.vip_progress != 1 ? (depositProgressPercentage + '%') : "100%" }}</div>
                  </div>
                  <ng-container *ngIf="!displayVIPProgressBar"> - </ng-container>
                </div>
              </div>
              <!-- Information -->
              <div class="col-5 h-100 p-0 vip-progress-details pl-5 d-flex">
                <div class="w-75 d-flex align-items-center">
                  <label class="m-0">
                    <!-- Deposit <span class="deposit-span"> MYR 12,500 </span> & Turnover <span class="turnover-span">MYR 12,500</span> to Unlock<br />
                    Deposit MYR 12,500/25,000<br />
                    Turnover MYR 12,500/25,000 -->
                    <ng-container *ngIf="displayVIPProgressBar">
                      <ng-container *ngIf="member.kyc_progression == 'Pro'; else noPro">
                        <ng-container *ngIf="member.ftd == 0; else ftd">
                          <ng-container *ngIf="member.vip_progress == 1; else inProgress">Pending Approval</ng-container>
                          <ng-template #inProgress>
                            Deposit <span class="deposit-span"> {{ member.currency_code }} {{ member.target_accumulated_deposit - member.total_deposits | number : "1.2-2" }} </span> to Unlock<br />
                            Deposit {{ member.currency_code }} {{ member.total_deposits | number : "1.2-2" }} / {{ member.target_accumulated_deposit | number : "1.2-2" }}
                          </ng-template>
                        </ng-container>
                        <ng-template #ftd>FTD to Unlock</ng-template>
                      </ng-container>
                      <ng-template #noPro>Pending KYC</ng-template>
                    </ng-container>
                  </label>
                </div>
                <!-- Navigate Icon -->
                <div *ngIf="displayVIPProgressBar && member.vip_progress == 1 && member.ftd != 1 && member.kyc_progression == 'Pro'" class="w-25 d-flex align-items-center justify-content-end">
                  <a class="btn" [routerLink]="['/vip/all-vips']" target="_blank" (click)="setVIPUsername()">
                    <i class="fas fa-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Register IP -->
          <mat-grid-tile>
            <div id="register_ip" data-desc="Register IP" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Register IP
              <br />
              <ng-container *ngIf="member.registration_ip">
                <label mat-raised-button #ipTooltip="matTooltip" class="label my-2 ipCLass text-ellipsis" matTooltip="Copied!" matTooltipPosition="above" (click)="ipTooltip.show()" [cdkCopyToClipboard]="member.registration_ip" (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation(); ipTooltip.hide(1000)">{{ member.registration_ip }}</label>
              </ng-container>
              <ng-container *ngIf="member.registration_pseudo_ip">
                <label mat-raised-button #ipPseudoTooltip="matTooltip" class="label my-2 ipCLass text-ellipsis" matTooltip="Copied!" matTooltipPosition="above" (click)="ipPseudoTooltip.show()" [cdkCopyToClipboard]="member.registration_pseudo_ip" (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation(); ipPseudoTooltip.hide(1000)">{{ member.registration_pseudo_ip }}</label>
              </ng-container>
              <ng-container *ngIf="!member.registration_ip && !member.registration_pseudo_ip">
                <label class="label my-2 ipCLass text-ellipsis">-</label>
              </ng-container>
            </div>
          </mat-grid-tile>
          <!-- Register Date -->
          <mat-grid-tile>
            <div id="register_date" data-desc="Register Date" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Register Date
              <br />
              <label class="label my-2">{{ member.registration_created_at == null ? "-" : (member.registration_created_at | timezoneDate : "YYYY-MM-DD HH:mm") }}</label>
            </div>
          </mat-grid-tile>
          <!-- Register Domain -->
          <mat-grid-tile>
            <div id="register_domain" data-desc="Register Domain" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Register Domain
              <br />
              <label class="label my-2 text-ellipsis" [matTooltip]="member.registration_site" matTooltipPosition="above">{{ member.registration_site == null ? "-" : member.registration_site }}</label>
            </div>
          </mat-grid-tile>
          <!-- Register Locales -->
          <mat-grid-tile>
            <div id="register_locales" data-desc="Register Locales" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Register Locales
              <br />
              <label class="label my-2">{{ member.registration_locale_code == null ? "-" : member.registration_locale_code }}</label>
            </div>
          </mat-grid-tile>
          <!-- Last Login IP -->
          <mat-grid-tile>
            <div id="last_login_ip" data-desc="Last Login IP" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Last Login IP
              <br />
              <ng-container *ngIf="member.last_login_ip">
                <label mat-raised-button #lastIpTooltip="matTooltip" matTooltip="Copied!" matTooltipPosition="above" class="label my-2 ipCLass text-ellipsis" (click)="lastIpTooltip.show()" [cdkCopyToClipboard]="member.last_login_ip" (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation(); lastIpTooltip.hide(1000)">{{ member.last_login_ip }}</label>
              </ng-container>
              <ng-container *ngIf="member.last_pseudo_ip">
                <label mat-raised-button #lastIpPseudoTooltip="matTooltip" matTooltip="Copied!" matTooltipPosition="above" class="label my-2 ipCLass text-ellipsis" (click)="lastIpPseudoTooltip.show()" [cdkCopyToClipboard]="member.last_pseudo_ip" (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation(); lastIpPseudoTooltip.hide(1000)">{{ member.last_pseudo_ip }}</label>
              </ng-container>
              <ng-container *ngIf="!member.last_login_ip && !member.last_pseudo_ip">
                <label class="label my-2 ipCLass text-ellipsis">-</label>
              </ng-container>
            </div>
          </mat-grid-tile>
          <!-- Last Login Date -->
          <mat-grid-tile>
            <div id="last_login_date" data-desc="Last Login Date" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Last Login Date
              <br />
              <label class="label my-2">{{ member.last_login == null ? "-" : (member.last_login | timezoneDate : "YYYY-MM-DD HH:mm") }}</label>
            </div>
          </mat-grid-tile>
          <!-- Last Login Domain -->
          <mat-grid-tile>
            <div id="last_login_domain" data-desc="Last Login Domain" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Last Login Domain
              <br />
              <label class="label my-2 text-ellipsis" [matTooltip]="member.last_domain" matTooltipPosition="above">{{ member.last_domain == null ? "-" : member.last_domain }}</label>
            </div>
          </mat-grid-tile>
          <!-- Last Login Locales -->
          <mat-grid-tile>
            <div id="last_login_locales" data-desc="Last Login Locales" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Last Login Locales
              <br />
              <label class="label my-2">{{ member.current_locale_code?.length == 0 ? "-" : member.current_locale_code }}</label>
            </div>
          </mat-grid-tile>
          <!-- Labels -->
          <mat-grid-tile [colspan]="2">
            <div id="labels" data-desc="Labels" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary" role="alert">
              Labels
              <br />
              <ng-container *ngFor="let label of member.labels">
                <span class="my-2 mr-2 kt-badge kt-badge--inline kt-badge--pill kt-badge--wide kt-badge--2">{{ label.name }}</span>
              </ng-container>
              <ng-container *ngIf="member.labels.length == 0">
                <label class="label my-2">-</label>
              </ng-container>
            </div>
          </mat-grid-tile>
          <!-- Remarks -->
          <mat-grid-tile [colspan]="2">
            <div id="remarks" data-desc="Remarks" data-section="memberBasicInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary row d-flex" role="alert">
              <div class="h-100 col-10 p-0">
                Remarks
                <br />
                <label class="label my-2">{{ member.remarks == null || member.remarks.length == 0 ? "-" : member.remarks }}</label>
              </div>
              <div class="h-100 col-2 p-0">
                <div class="d-flex align-items-center justify-content-end">
                  <button type="button" class="btn" (click)="onViewMoreRemarks()">
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-expansion-panel>
    </mat-accordion>
    <br />
    <!-- Wallet Info -->
    <mat-accordion>
      <mat-expansion-panel [expanded]="true" class="member-info-content" #walletInfoExpansionPanel>
        <!-- Title -->
        <mat-expansion-panel-header>
          <mat-panel-title> Wallet Info </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="mt-0" />
        <!-- Content -->
        <mat-grid-list cols="3" rowHeight="8rem" class="wallet-info">
          <!-- Main Wallet -->
          <mat-grid-tile>
            <div id="main-wallet" data-desc="Main Wallet" data-section="walletInfoExpansionPanel" data-subtab="Member Info" class="info alert-success-customized d-flex align-items-center" role="alert">
              <i class="fas fa-wallet pr-3"></i>
              <div class="d-flex align-items-center flex-column">
                <label class="label w-100" *ngIf="mainWallet$ | async; let mainWallet">{{ member.currency_code }} {{ mainWallet.balance | number : "1.2-2" }}</label>
                <label class="text-muted w-100 label-title">Main Wallet</label>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Game Wallet -->
          <mat-grid-tile>
            <div id="game-wallet" data-desc="Game Wallet" data-section="walletInfoExpansionPanel" data-subtab="Member Info" class="info alert-primary-customized d-flex align-items-center" role="alert">
              <i class="fas fa-gamepad pr-3"></i>
              <div class="d-flex align-items-center flex-column w-75">
                <label class="label w-100">{{ member.currency_code }} {{ gameWallet | number : "1.2-2" }}</label>
                <label class="text-muted w-100 label-title">Game Wallet</label>
              </div>
              <div class="d-flex align-items-center justify-content-end">
                <button type="button" class="btn" (click)="onNavigateToWalletTab()">
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Adjusted Amount -->
          <mat-grid-tile>
            <div id="adjusted-amount" data-desc="Adjusted Amount" data-section="walletInfoExpansionPanel" data-subtab="Member Info" class="info alert-warning-customized d-flex align-items-center" role="alert">
              <i class="fas fa-coins pr-3"></i>
              <div class="d-flex align-items-center flex-column">
                <label class="label w-100">{{ member.currency_code }} {{ member.total_adjusted_amount | number : "1.2-2" }}</label>
                <label class="text-muted w-100 label-title">Adjusted Amount</label>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-expansion-panel>
    </mat-accordion>
    <br />
    <!-- Account Info -->
    <mat-accordion>
      <mat-expansion-panel [expanded]="true" class="member-info-content" #accountInfoExpansionPanel>
        <!-- Title -->
        <mat-expansion-panel-header>
          <mat-panel-title> Account Info </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="mt-0" />
        <!-- Content -->
        <mat-grid-list cols="3" rowHeight="7rem" class="account-info">
          <!-- Campaign -->
          <mat-grid-tile [colspan]="3">
            <div id="campaign" data-desc="Campaign" data-section="accountInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary d-flex align-items-center campaign" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Campaign</label>
                <label class="label h-100" *ngIf="member.campaign_name != null && member.campaign_name.length > 0; else noCampaign">{{ member.campaign_name }} ({{ member.campaign_code }})</label>
                <ng-template #noCampaign>
                  <label class="label h-100">-</label>
                </ng-template>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Affiliate -->
          <mat-grid-tile>
            <div id="affiliate" data-desc="Affiliate" data-section="accountInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary d-flex align-items-center affiliate" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Affiliate{{ member?.partner_network_name ? ' - ' + member.partner_network_name : '' }}</label>
                <label class="label h-100" *ngIf="member.affiliate_name != null && member.affiliate_name.length > 0; else noAffiliate">{{ member.affiliate_name }} ({{ member.affiliate_code }})</label>
                <ng-template #noAffiliate>
                  <label class="label h-100">-</label>
                </ng-template>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Telemarketer -->
          <mat-grid-tile>
            <div id="telemarketer" data-desc="Telemarketer" data-section="accountInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary d-flex align-items-center telemarketer" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Telemarketer</label>
                <label class="label h-100" *ngIf="member.telemarketer != null && member.telemarketer.length > 0; else noTelemarketer">{{ member.telemarketer }}</label>
                <ng-template #noTelemarketer>
                  <label class="label h-100">-</label>
                </ng-template>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Agent -->
          <mat-grid-tile>
            <div id="agent" data-desc="Agent" data-section="accountInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary d-flex align-items-center agent" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Agent</label>
                <label class="label h-100" *ngIf="member.agent_name != null && member.agent_name.length > 0; else noAgent">{{ member.agent_name }}</label>
                <ng-template #noAgent>
                  <label class="label h-100">-</label>
                </ng-template>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Referrer -->
          <mat-grid-tile>
            <div id="referrer" data-desc="Referrer" data-section="accountInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary d-flex align-items-center referrer" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Referrer</label>
                <label class="label h-100" *ngIf="member.referrer != null && member.referrer.length > 0; else noReferrer">{{ member.referrer }}</label>
                <ng-template #noReferrer>
                  <label class="label h-100">-</label>
                </ng-template>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Referral Code -->
          <mat-grid-tile>
            <div id="referral_code" data-desc="Referral Code" data-section="accountInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary d-flex align-items-center referral-code" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Referral Code</label>
                <label class="label h-100">{{ member.referral_code }}</label>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Referral Count -->
          <mat-grid-tile>
            <div id="referral_count" data-desc="Referral Count" data-section="accountInfoExpansionPanel" data-subtab="Member Info" class="info alert-secondary d-flex align-items-center referral-count" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Referral Count</label>
                <label class="label h-100">{{ member.referral_count_successful + "/" + member.referral_count_all }}</label>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <!-- Member Statistics -->
  <div [ngClass]="{ 'd-none': basicInfoActiveTab != 'Member Statistics' }">
    <form [formGroup]="form">
      <!-- Date Time filter -->
      <div class="d-flex align-items-center justify-content-center my-3">
        <div class="col-1 w-100 text-right">
          <label>Date/Time:</label>
        </div>
        <div class="col-5">
          <div class="input-group date">
            <input type="hidden" formControlName="start_date" />
            <input type="hidden" formControlName="end_date" />

            <input type="text" class="form-control" placeholder="Search" (change)="onDateRange($event)" formControlName="defaultDate" [timePicker]="true" [timePickerSeconds]="true" [alwaysShowCalendars]="true" [ranges]="ranges" [showCustomRangeLabel]="true" [locale]="dateTimePickerLocale" [timePicker24Hour]="true" [opens]="'center'" [showDropdowns]="true" (click)="updateDateRange()" ngxDaterangepickerMd />
            <!-- <a class="ngx-daterangepicker-action" (click)="openDatepicker()"> Open </a> -->
            <span class="input-group-append">
              <span class="input-group-text pointer-calendar" (click)="onClearDate()">X</span>
            </span>
          </div>
        </div>
        <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading" (search)="onSubmit()"></kt-search-button>
        <!-- <kt-search-button></kt-search-button> -->
      </div>
      <!-- Date Time Filter Shortcut Button -->
      <div class="d-flex align-items-center justify-content-center mx-auto my-3 w-75">
        <ng-container *ngFor="let item of dateTimeShortcutBtns">
          <button type="button" class="date-filter btn btn-tab mx-1" [ngClass]="{ 'btn-selected': item.key == activeDateTimeShortcutBtn, 'ngx-daterangepicker-action': item.key == 'custom-range' }" (click)="onDateTimeShortcut(item.key)">{{ item.label }}</button>
        </ng-container>
      </div>
    </form>

    <!-- Deposit & Withdrawal & Transfer -->
    <div class="row">
      <!-- Deposit -->
      <div class="col-4">
        <mat-accordion>
          <mat-expansion-panel [expanded]="true" class="member-statistics-content" #depositExpansionPanel>
            <!-- Title -->
            <mat-expansion-panel-header>
              <mat-panel-title> Deposit </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="mt-0" />
            <!-- Total Deposit Count -->
            <div id="total-deposit-count" data-desc="Total Deposit Count" data-section="depositExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center deposit mx-2 my-3" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Total Deposit Count</label>
                <label class="label h-100">{{ memberBetInfo?.deposit_count  }}</label>
              </div>
            </div>
            <!-- Total Deposit Amount -->
            <div id="total-deposit-amount" data-desc="Total Deposit Amount" data-section="depositExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center deposit mx-2 my-3" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Total Deposit Amount</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_deposit | number : '1.2-2' }}</label>
              </div>
            </div>
            <!-- Total Pending Deposit -->
            <div id="total-pending-deposit" data-desc="Total Pending Deposit" data-section="depositExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center deposit mx-2 my-3" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Total Pending Deposit</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.pending_deposit | number : '1.2-2' }}</label>
              </div>
            </div>
            <!-- Recent Deposit Date/Time -->
            <div id="recent-deposit-datetime" data-desc="Recent Deposit Date/Time" data-section="depositExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center deposit mx-2 my-3" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Recent Deposit Date/Time</label>
                <label class="label h-100">{{ memberBetInfo?.recent_deposit != null ? (memberBetInfo?.recent_deposit | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</label>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <!-- Withdrawal -->
      <div class="col-4">
        <mat-accordion>
          <mat-expansion-panel [expanded]="true" class="member-statistics-content" #withdrawalExpansionPanel>
            <!-- Title -->
            <mat-expansion-panel-header>
              <mat-panel-title> Withdrawal </mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="mt-0" />
            <!-- Total Withdrawal Count -->
            <div id="total-withdrawal-count" data-desc="Total Withdrawal Count" data-section="withdrawalExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center withdrawal mx-2 my-3" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Total Withdrawal Count</label>
                <label class="label h-100">{{ memberBetInfo?.withdraw_count }}</label>
              </div>
            </div>
            <!-- Total Withdrawal Amount -->
            <div id="total-withdrawal-amount" data-desc="Total Withdrawal Amount" data-section="withdrawalExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center withdrawal mx-2 my-3" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Total Withdrawal Amount</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_withdraw | number : '1.2-2' }}</label>
              </div>
            </div>
            <!-- Total Pending Withdrawal -->
            <div id="total-pending-withdrawal" data-desc="Total Pending Withdrawal" data-section="withdrawalExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center withdrawal mx-2 my-3" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Total Pending Withdrawal</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.pending_withdraw | number : '1.2-2' }}</label>
              </div>
            </div>
            <!-- Recent Withdrawal Date/Time -->
            <div id="recent-withdrawal-datetime" data-desc="Recent Withdrawal Date/Time" data-section="withdrawalExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center withdrawal mx-2 my-3" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Recent Withdrawal Date/Time</label>
                <label class="label h-100">{{ memberBetInfo?.recent_withdraw != null ? (memberBetInfo?.recent_withdraw | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</label>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <!-- Transfer -->
      <div class="col-4">
        <mat-accordion>
          <mat-expansion-panel [expanded]="true" class="member-statistics-content" #transferExpansionPanel>
            <!-- Title -->
            <mat-expansion-panel-header>
              <mat-panel-title>Transfer</mat-panel-title>
            </mat-expansion-panel-header>
            <hr class="mt-0" />
            <!-- Total Transfer In Count -->
            <div id="total-transfer-in-count" data-desc="Total Transfer In Count" data-section="transferExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center transfer mx-2 my-3" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Total Transfer In Count</label>
                <label class="label h-100">{{ memberBetInfo?.total_transfer_in_count }}</label>
              </div>
            </div>
            <!-- Total Transfer In Amount -->
            <div id="total-transfer-in-amount" data-desc="Total Transfer In Amount" data-section="transferExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center transfer mx-2 my-3" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Total Transfer In Amount</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_transfer_in | number : '1.2-2' }}</label>
              </div>
            </div>
            <!-- Total Transfer Out Count -->
            <div id="total-transfer-out-count" data-desc="Total Transfer Out Count" data-section="transferExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center transfer mx-2 my-3" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Total Transfer Out Count</label>
                <label class="label h-100">{{ memberBetInfo?.total_transfer_out_count }}</label>
              </div>
            </div>
            <!-- Total Transfer Out Amount -->
            <div id="total-transfer-out-amount" data-desc="Total Transfer Out Amount" data-section="transferExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center transfer mx-2 my-3" role="alert">
              <span class="side-indicator"></span>
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Total Transfer Out Amount</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_transfer_out | number : '1.2-2' }}</label>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <br />

    <!-- Processing Fees -->
    <mat-accordion>
      <mat-expansion-panel [expanded]="false" class="member-statistics-content" #processingFeesExpansionPanel>
        <!-- Title -->
        <mat-expansion-panel-header>
          <mat-panel-title> Processing Fees </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="mt-0" />
        <!-- First Row : Total -->
        <mat-grid-list cols="3" rowHeight="6rem" class="processing-fees">
          <mat-grid-tile>
            <div id="pf-total-deposit" data-desc="Processing Fees - Total Deposit" data-section="processingFeesExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary mx-2 d-flex align-items-center" role="alert">
              <div class="d-flex align-items-center justify-content-center first-column">
                <i class="fas fa-money-check-alt"></i>
              </div>
              <label class="label-title muted ml-3 w-100">Deposit (Total)</label>
              <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_deposit_processing_fee | number : '1.2-2' }}</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div id="pf-total-withdrawal" data-desc="Processing Fees - Total Withdrawal" data-section="processingFeesExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary mx-2 d-flex align-items-center" role="alert">
              <div class="d-flex align-items-center justify-content-center second-column">
                <i class="fas fa-money-check-alt"></i>
              </div>
              <label class="label-title muted ml-3 w-100">Withdrawal (Total)</label>
              <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_withdraw_processing_fee | number : '1.2-2' }}</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div id="pf-total" data-desc="Processing Fees - Total" data-section="processingFeesExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary mx-2 d-flex align-items-center" role="alert">
              <div class="d-flex align-items-center justify-content-center third-column">
                <i class="fas fa-money-check-alt"></i>
              </div>
              <label class="label-title muted ml-3 w-100">Total</label>
              <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_processing_fee | number : '1.2-2' }}</label>
            </div>
          </mat-grid-tile>
          <!-- Second Row : Company -->
          <mat-grid-tile>
            <div id="pf-company-total-deposit" data-desc="Processing Fees - Company Total Deposit" data-section="processingFeesExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary mx-2 d-flex align-items-center" role="alert">
              <div class="d-flex align-items-center justify-content-center first-column">
                <i class="fas fa-building"></i>
              </div>
              <label class="label-title muted ml-3 w-100">Deposit (Company)</label>
              <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.company_deposit_processing_fee | number : '1.2-2' }}</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div id="pf-company-total-withdrawal" data-desc="Processing Fees - Company Total Withdrawal" data-section="processingFeesExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary mx-2 d-flex align-items-center" role="alert">
              <div class="d-flex align-items-center justify-content-center second-column">
                <i class="fas fa-building"></i>
              </div>
              <label class="label-title muted ml-3 w-100">Withdrawal (Company)</label>
              <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.company_withdraw_processing_fee | number : '1.2-2' }}</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div id="pf-company-total" data-desc="Processing Fees - Company Total" data-section="processingFeesExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary mx-2 d-flex align-items-center" role="alert">
              <div class="d-flex align-items-center justify-content-center third-column">
                <i class="fas fa-building"></i>
              </div>
              <label class="label-title muted ml-3 w-100">Total (Company)</label>
              <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_company_processing_fee | number : '1.2-2' }}</label>
            </div>
          </mat-grid-tile>
          <!-- Third Row : Player -->
          <mat-grid-tile>
            <div id="pf-player-total-deposit" data-desc="Processing Fees - Player Total Deposit" data-section="processingFeesExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary mx-2 d-flex align-items-center" role="alert">
              <div class="d-flex align-items-center justify-content-center first-column">
                <i class="fas fa-user"></i>
              </div>
              <label class="label-title muted ml-3 w-100">Deposit (Player)</label>
              <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.member_deposit_processing_fee | number : '1.2-2' }}</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div id="pf-player-total-withdrawal" data-desc="Processing Fees - Player Total Withdrawal" data-section="processingFeesExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary mx-2 d-flex align-items-center" role="alert">
              <div class="d-flex align-items-center justify-content-center second-column">
                <i class="fas fa-user"></i>
              </div>
              <label class="label-title muted ml-3 w-100">Withdrawal (Player)</label>
              <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.member_withdraw_processing_fee | number : '1.2-2' }}</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div id="pf-player-total" data-desc="Processing Fees - Player Total" data-section="processingFeesExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary mx-2 d-flex align-items-center" role="alert">
              <div class="d-flex align-items-center justify-content-center third-column">
                <i class="fas fa-user"></i>
              </div>
              <label class="label-title muted ml-3 w-100">Total (Player)</label>
              <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_member_processing_fee | number : '1.2-2' }}</label>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-expansion-panel>
    </mat-accordion>
    <br />

    <!-- Game & Jackpot -->
    <mat-accordion>
      <mat-expansion-panel [expanded]="false" class="member-statistics-content" #gameExpansionPanel>
        <!-- Title -->
        <mat-expansion-panel-header>
          <mat-panel-title> Game</mat-panel-title>
          <button matTooltip="{{ 'Last Bet Log Sync At ' + lastBetLogSyncAt }}"  class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"><i class="fas fa-history"></i></button>
        </mat-expansion-panel-header>
        <hr class="mt-0" />
        <mat-grid-list cols="3" rowHeight="7rem" class="game-jackpot">
          <!-- Total Bet Count -->
          <mat-grid-tile>
            <div id="total-bet-count" data-desc="Total Bet Count" data-section="gameExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center mx-2 my-3" role="alert">
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Total Bet Count</label>
                <label class="label h-100">{{ memberBetInfo?.total_bet_count }}</label>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Valid Bet -->
          <mat-grid-tile>
            <div id="valid-bet" data-desc="Valid Bet" data-section="gameExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center mx-2 my-3" role="alert">
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Valid Bet</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_valid_bet_amount | number : '1.2-2' }}</label>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Game Bet Amount -->
          <mat-grid-tile>
            <div id="game-bet-amount" data-desc="Game Bet Amount" data-section="gameExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center mx-2 my-3" role="alert">
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Game Bet Amount</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_turnover | number : '1.2-2' }}</label>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Player W/L -->
          <mat-grid-tile>
            <div id="player-win-loss" data-desc="Player W/L" data-section="gameExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center mx-2 my-3" role="alert">
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Player W/L</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_player_win_lose  | number : '1.2-2' }}</label>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Recent Played Game -->
          <mat-grid-tile>
            <div id="recent-played-game" data-desc="Game Type Preference" data-section="gameExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center mx-2 my-3" role="alert">
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Game Type Preference (Last 3 Months)</label>
                <label class="label h-100">{{ memberBetInfo?.game_type_preference }}</label>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>

        <!-- Jackpot -->
        <span class="mat-content my-4">
          <mat-panel-title class="mat-expansion-panel-header-title"> Jackpot </mat-panel-title>
        </span>
        <hr />
        <mat-grid-list cols="3" rowHeight="7rem" class="game-jackpot">
          <!-- Jackpot Contribution -->
          <mat-grid-tile>
            <div id="jackpot-contribution" data-desc="Jackpot Contribution" data-section="gameExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center mx-2 my-3" role="alert">
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Jackpot Contribution</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_jackpot_contribution | number : '1.2-2' }}</label>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Jackpot Win -->
          <mat-grid-tile>
            <div id="jackpot-win" data-desc="Jackpot Win" data-section="gameExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center mx-2 my-3" role="alert">
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Jackpot Win</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_jackpot_win | number : '1.2-2' }}</label>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Nett Jackpot -->
          <mat-grid-tile>
            <div id="nett-jackpot" data-desc="Nett Jackpot" data-section="gameExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center mx-2 my-3" role="alert">
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Nett Jackpot</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.jackpot_winlose | number : '1.2-2' }}</label>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-expansion-panel>
    </mat-accordion>
    <br />

    <!-- Bonus -->
    <mat-accordion>
      <mat-expansion-panel [expanded]="false" class="member-statistics-content" #bonusExpansionPanel>
        <!-- Title -->
        <mat-expansion-panel-header>
          <mat-panel-title> Bonus </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="mt-0" />
        <mat-grid-list cols="3" rowHeight="7rem" class="bonus">
          <!-- Total Bonus -->
          <mat-grid-tile>
            <div id="total-bonus" data-desc="Total Bonus" data-section="bonusExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center mx-2 my-3 justify-content-between" role="alert">
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Total Bonus</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_bonus | number : '1.2-2' }}</label>
              </div>
              <i class="fas fa-gift"></i>
            </div>
          </mat-grid-tile>
          <!-- Total Cancelled Bonus -->
          <mat-grid-tile>
            <div id="total-cancelled-bonus" data-desc="Total Cancelled Bonus" data-section="bonusExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center mx-2 my-3 justify-content-between" role="alert">
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Total Cancelled Bonus</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_cancelled_bonus | number : '1.2-2' }}</label>
              </div>
              <i class="fas fa-ban"></i>
            </div>
          </mat-grid-tile>
          <!-- Total Rebate -->
          <mat-grid-tile>
            <div id="total-rebate" data-desc="Total Rebate" data-section="bonusExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center mx-2 my-3 justify-content-between" role="alert">
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Total Rebate</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.total_rebate | number : '1.2-2' }}</label>
              </div>
              <i class="fas fa-hand-holding-usd"></i>
            </div>
          </mat-grid-tile>
          <!-- Recent Claimed Promotion -->
          <mat-grid-tile>
            <div id="recent-claimed-promotion" data-desc="Recent Claimed Promotion" data-section="bonusExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center mx-2 my-3 justify-content-between" role="alert">
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Recent Claimed Promotion</label>
                <label class="label h-100">{{ memberBetInfo?.recent_promotion_code }}</label>
              </div>
              <i class="fas fa-piggy-bank"></i>
            </div>
          </mat-grid-tile>
          <!-- Recent Claimed Amount -->
          <mat-grid-tile>
            <div id="recent-claimed-amount" data-desc="Recent Claimed Amount" data-section="bonusExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center mx-2 my-3 justify-content-between" role="alert">
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Recent Claimed Amount</label>
                <label class="label h-100">{{ member.currency_code }} {{ memberBetInfo?.recent_claimed_amount | number : '1.2-2' }}</label>
              </div>
              <i class="fas fa-coins"></i>
            </div>
          </mat-grid-tile>
          <!-- Recent Claimed Date/Time -->
          <mat-grid-tile>
            <div id="recent-claimed-datetime" data-desc="Recent Claimed Date/Time" data-section="bonusExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center mx-2 my-3 justify-content-between" role="alert">
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Recent Claimed Date/Time</label>
                <label class="label h-100">{{ memberBetInfo?.recent_claimed_date != null ? (memberBetInfo?.recent_claimed_date | timezoneDate: 'YYYY-MM-DD HH:mm') : '-' }}</label>              </div>
              <i class="fas fa-calendar-alt"></i>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div id="total-luckyspin-count" data-desc="Total Luckyspin Count" data-section="bonusExpansionPanel" data-subtab="Member Statistics" class="info alert-secondary d-flex align-items-center mx-2 my-3 justify-content-between" role="alert">
              <div class="d-flex flex-column pl-2 h-100 justify-content-center">
                <label class="label-title muted h-100">Total Luckyspin Count</label>
                <label class="label h-100">{{ memberBetInfo?.spin_count }}</label>              </div>
              <i class="fas fa-dharmachakra"></i>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-expansion-panel>
    </mat-accordion>
    <br />

    <!-- Revenue -->
    <mat-accordion>
      <mat-expansion-panel [expanded]="true" class="member-statistics-content" #revenueExpansionPanel>
        <!-- Title -->
        <mat-expansion-panel-header>
          <mat-panel-title>Revenue</mat-panel-title>
          <button matTooltip="{{ 'Last Bet Log Sync At ' + lastBetLogSyncAt }}"  class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm"><i class="fas fa-history"></i></button>
        </mat-expansion-panel-header>
        <hr class="mt-0" />
        <!-- Content -->
        <mat-grid-list cols="2" rowHeight="8rem" class="revenue">
          <!-- Gross Game Revenue (GGR) -->
          <mat-grid-tile>
            <div id="ggr" data-desc="Gross Game Revenue" data-section="revenueExpansionPanel" data-subtab="Member Statistics" class="info alert-warning-customized d-flex align-items-center" role="alert">
              <i class="fas fa-dollar-sign pr-3"></i>
              <div class="d-flex align-items-center flex-column">
                <label class="label w-100">{{ member.currency_code }} {{ memberBetInfo?.total_win_lose | number : '1.2-2' }}</label>
                <label class="text-muted w-100 label-title">Gross Game Revenue (GGR)</label>
              </div>
            </div>
          </mat-grid-tile>
          <!-- Net Game Revenue (NGR) -->
          <mat-grid-tile>
            <div id="ngr" data-desc="Net Game Revenue" data-section="revenueExpansionPanel" data-subtab="Member Statistics" class="info alert-success-customized d-flex align-items-center" role="alert">
              <i class="fas fa-hand-holding-usd pr-3"></i>
              <div class="d-flex align-items-center flex-column">
                <label class="label w-100">{{ member.currency_code }} {{ memberBetInfo?.company_revenue | number : '1.2-2' }}</label>
                <label class="text-muted w-100 label-title">Net Game Revenue (NGR)</label>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>
