<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Recovery Message</h5>
        <span class="modal-x-button" (click)="onCloseDialog()">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
      <div class="modal-body">
          <!-- Currency -->
          <div class="col-12 form-group row">
              <label class="col-4 col-form-label">Currency <span class="text-danger">*</span> </label>
              <select formControlName="currency_id" class="col-8 form-control" (change)="onCurrencyChange()">
                <option [value]="null" [disabled]="true">Please Select</option>
                <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                    {{ value.name }}
                </option>
              </select>
          </div>
          <!-- Currency -->
          <div class="col-12 form-group row">
            <label class="col-4 col-form-label">Locale <span class="text-danger">*</span> </label>
            <select *ngIf="dropdown.locales" formControlName="locale_id" class="col-8 form-control" (change)="onLocaleChange()">
              <option [value]="null" [disabled]="true">Please Select</option>
              <option *ngFor="let value of dropdown.locales | async" [value]="value.id">{{ value.code }}</option>
            </select>
          </div>
        <!-- SMS -->
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">SMS <span class="text-danger" *ngIf="form.value.sms_template_id == null && form.value.message_template_id == null">*</span> </label>
          <div class="col-md-8 p-0">
            <kt-dropdown-wo-lazyload
              [form] = 'form'
              [dropdownList] = 'smsTemplateList'
              [dropdownSettings] = 'MessageTemplatesSettings'
              [formName] = "'sms_template_id'"
              [selectionAttributes] = "'id'"
              [selectedItems] ='smsTemplateSelectedItem'>
            </kt-dropdown-wo-lazyload>
          </div>
        </div>
        <!-- Message -->
        <div class="col-12 form-group row">
          <label class="col-4 col-form-label">Message <span class="text-danger" *ngIf="form.value.sms_template_id == null && form.value.message_template_id == null">*</span> </label>
          <div class="col-md-8 p-0">
            <kt-dropdown-wo-lazyload
              [form] = 'form'
              [dropdownList] = 'messageTemplateList'
              [dropdownSettings] = 'MessageTemplatesSettings'
              [formName] = "'message_template_id'"
              [selectionAttributes] = "'id'"
              [selectedItems] ='messageTemplateSelectedItem'>
            </kt-dropdown-wo-lazyload>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
        <kt-submit-button [isDisabled]="!form.valid || (form.value.sms_template_id == null && form.value.message_template_id == null)" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.recoveryMessage, data.mode)" #focusfield></kt-submit-button>
      </div>
    </div>
  </form>
</div>

<kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
