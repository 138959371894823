<div class="modal-dialog modal-dialog-scrollable m-0 scrollable-dialog">
  <form class="kt-form w-webkit-fill-available" [formGroup]="form">
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ data.mode === 'edit' ? 'Edit' : 'Create' }} Recovery Currency</h5>
          <span class="modal-x-button" (click)="onCloseDialog()">
              <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div class="modal-body pl-5 pr-5">
          <div class="form-group row">
            <label class="col-4 col-form-label">Currency <span class="text-danger">*</span></label>
            <select class="ml-1 w-100 form-control" formControlName="currency_id" placeholder="Please Select">
              <option [value]="null" [disabled]="true">Please Select</option>
              <option [value]="value.id" *ngFor="let value of dropdown.currencies">
                {{ value.name }}
              </option>
            </select>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label">Max <span class="text-danger">*</span></label>
              <input type="number" (wheel)="false" formControlName="max" class="col-p-0 w-100 ml-1 form-control" />
          </div>

          <div class="form-group row">
            <label class="col-4 col-form-label">Min <span class="text-danger">*</span></label>
            <input type="number" (wheel)="false" formControlName="min" class="col-p-0 w-100 ml-1 form-control">
          </div>

          <div class="form-group row">
            <label class="col-4 col-form-label">Status <span class="text-danger">*</span></label>
            <select formControlName="status" class="form-control">
              <option [value]="i" *ngFor="let value of dropdown.statuses ; let i = index">
                  {{ value }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="onCloseDialog()"><i class="fas fa-ban"></i>Close</button>
          <kt-submit-button [isDisabled]="!form.valid" [buttonLoading]="buttonLoading" (confirmed)="onSave(data.recoveryCurrency, data.mode)"></kt-submit-button>
        </div>
    </div>
  </form>

</div>
  <kt-swal-alert [message]="messages$ | async" (confirmed)="onCloseDialog($event)"></kt-swal-alert>
