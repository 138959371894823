import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RecoveryCurrency } from '@core/models/recovery-currency.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription, forkJoin } from 'rxjs';
import { RecoveryCurrencyDataService } from '../services/recovery-currency-data.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { RecoveryCurrencyEntityService } from '../services/recovery-currency-entity.service';
import { tap, catchError } from 'rxjs/operators';
import { Status } from '@core/enums/status.enum';

@Component({
  selector: 'kt-recovery-currency',
  templateUrl: './recovery-currency-details.component.html',
  styleUrls: ['./recovery-currency-details.component.scss']
})
export class RecoveryCurrencyDetailsComponent implements OnInit, OnDestroy {

  status = Status;
  form: FormGroup;
  dropdown = {
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    statuses: this.dropdownHttpService.statuses,
  };
  messages$ = this.recoveryCurrencyDataService.messages$;
  private subscription = new Subscription();
  private messageSubscription = new Subscription();
  refreshStatus: boolean;
  buttonLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { recoveryCurrency: RecoveryCurrency, mode: string, recoveryId: number  },
    private recoveryCurrencyDataService: RecoveryCurrencyDataService,
    private recoveryCurrencyEntityService: RecoveryCurrencyEntityService,
    private dropdownHttpService: DropdownHttpService,
    public dialogRef: MatDialogRef<RecoveryCurrencyDetailsComponent>,
  ) { }

  ngOnInit() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
      });
    }
    this.formInit();
    this.form.getRawValue();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.messageSubscription.unsubscribe();
    this.onRefresh();
  }

 private formInit() {
  let currency_id = null;
  let max= null;
  let min = 0;
  let status = 1;
  max =  0;
  if (this.data.mode === 'edit') {
    currency_id = this.data.recoveryCurrency.currency_id;
    min = this.data.recoveryCurrency.min;
    if (this.data.recoveryCurrency.max == 'Unlimited') {
      max =  0;
    } else {
      max =  this.data.recoveryCurrency.max;
    }
    status = this.data.recoveryCurrency.status;
  }

  this.form = new FormGroup({
    currency_id: new FormControl({ value: currency_id, disabled: this.data.mode === 'edit' ? true : false }, [Validators.required]),
    max: new FormControl(max, [Validators.required]),
    min: new FormControl(min, [Validators.required]),
    status: new FormControl(status)
  });
 }

  onSave(recoveryCurrencyDetails: RecoveryCurrency, mode?: string) {
    this.buttonLoading = true;
    const data = {
      id: recoveryCurrencyDetails ? recoveryCurrencyDetails.id : null,
      recovery_promotion_setting_id: this.data.recoveryId,
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);

    switch (mode) {
      case 'edit':
        this.subscription = this.recoveryCurrencyEntityService.update(data).pipe(
          tap((res: any) => {
              this.messages$.next([...res.message]);
              this.buttonLoading = false;
              this.form.setErrors(null);
          }),
          catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
          })
        ).subscribe();
      break;
      case 'create':
        this.subscription = this.recoveryCurrencyDataService.add(data).pipe(
          tap((res: any) => {
              this.messages$.next([...res.message]);
              this.buttonLoading = false;
              this.form.setErrors(null);
          }),
          catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
          })
        ).subscribe();
      break;
    }
    this.refreshStatus = true;
  }

  onRefresh(){
    if(this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  onCloseDialog(event?: Event ) {
    this.dialogRef.close();
  }

}
