import { MessageTemplateEntityService } from './message-template/services/message-template-entity.service';
import { MessageTemplateDataService } from './message-template/services/message-template-data.service';
import { MemberLogEntityService } from './member-logs/services/member-log-entity.service';
import { MemberLogDataService } from './member-logs/services/member-log-data.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OperatorResolver } from '@core/resolvers/operator.resolver';
import { OperatorEntityService } from './operators/services/operator-entity.service';
import { entityMetadata } from 'app/app.module';
import { EntityDefinitionService, EntityDataService } from '@ngrx/data';
import { OperatorDataService } from './operators/services/operator-data.service';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { SuperuserRoutingModule } from './superuser-routing.module';
import { SuperuserReportsMemberinoutComponent } from './subcomponents/superuser-reports-memberinout/superuser-reports-memberinout.component';
import { SuperuserReportsMemberaccountComponent } from './subcomponents/superuser-reports-memberaccount/superuser-reports-memberaccount.component';
import { SuperuserCashflowComponent } from './subcomponents/superuser-cashflow/superuser-cashflow.component';
import { SuperuserReportsWinloseComponent } from './subcomponents/superuser-reports-winlose/superuser-reports-winlose.component';
import { SuperuserReportsWinloseFullComponent } from './subcomponents/superuser-reports-winlose-full/superuser-reports-winlose-full.component';
import { PartialsModule } from '@views/partials/partials.module';
import { OperatorComponent } from './operators/operator.component';
import { OperatorDialogComponent } from './operators/dialogs/operator-profile.component';
import { RoleComponent } from './roles/role.component';
import { RoleEditDialogComponent } from './roles/dialogs/role-edit.component';
import { RoleDataService } from './roles/services/role-data.service';
import { RoleEntityService } from './roles/services/role-entity.service';
import { RoleResolver } from '@core/resolvers/role.resolver';
import { SharedModule } from '@shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { OperatorLogsComponent } from './operator-logs/operator-logs.component';
import { OperatorLogsEntityService } from './operator-logs/services/operator-logs-entity.service';
import { OperatorLogsDataService } from './operator-logs/services/operator-logs-data.service';
import { OperatorLogResolver } from '@core/resolvers/operator-log.resolver';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SystemErrorLogComponent } from './system-error-logs/system-error-logs.component';
import { MemberLogsComponent } from './member-logs/member-logs.component';
import { SystemMaintenanceComponent } from './system-maintenance/system-maintenance.component';
import { SystemMaintenanceDataService } from './system-maintenance/services/system-maintenance-data.service';
import { SystemMaintenanceEditComponent } from './system-maintenance/dialog/system-maintenance-edit.component';
import { MessageTemplateComponent } from './message-template/message-template.component';
import { MessageTemplateEditComponent } from './message-template/dialogs/message-template-edit.component';
import { IpWhitelistingComponent } from './ip-whitelisting/ip-whitelisting.component';
import { IpWhitelistingDataService } from './ip-whitelisting/services/ip-whitelisting-data.service';
import { IpWhitelistingEditComponent } from './ip-whitelisting/dialog/ip-whitelisting-edit.component';
import { SystemSettingComponent } from './system-setting/system-setting.component';
import { SystemSettingDataService } from './system-setting/services/system-setting-data.service';
import { ApiKeySettingComponent } from './api-key-setting/api-key-setting.component';
import { ApiKeySettingEditComponent } from './api-key-setting/dialog/api-key-setting-edit.component';
import { SiteDomainComponent } from './site-domain/site-domain.component';
import { SiteDomainEditComponent } from './site-domain/dialog/site-domain-edit.component';
import { SiteDomainDataService } from './site-domain/services/site-domain-data.service';
import { AuditsComponent } from './audits/audits.component';
import { AuditsDataService } from './audits/services/audits-data.service';
import { ApplicationPermissionsComponent } from './application-permissions/application-permissions.component';
import { ApplicationPermissionEditDialogComponent } from './application-permissions/dialogs/application-permission-edit.component';
import { ApplicationRoleComponent } from './application-roles/application-role.component';
import { ApplicationRoleEditDialogComponent } from './application-roles/dialogs/application-role-edit.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { ApplicationRolePermissionLayerComponent } from './application-roles/permission-layer/application-role-permission-layer.component';

@NgModule({
  declarations: [
    SuperuserReportsMemberinoutComponent,
    SuperuserReportsMemberaccountComponent,
    SuperuserCashflowComponent,
    SuperuserReportsWinloseComponent,
    SuperuserReportsWinloseFullComponent,
    OperatorComponent,
    OperatorDialogComponent,
    RoleComponent,
    RoleEditDialogComponent,
    OperatorLogsComponent,
    SystemErrorLogComponent,
    MemberLogsComponent,
    SystemMaintenanceComponent,
    SystemMaintenanceEditComponent,
    MessageTemplateComponent,
    MessageTemplateEditComponent,
    IpWhitelistingComponent,
    IpWhitelistingEditComponent,
    SystemSettingComponent,
    ApiKeySettingComponent,
    ApiKeySettingEditComponent,
    SiteDomainComponent,
    SiteDomainEditComponent,
    AuditsComponent,
    ApplicationPermissionsComponent,
    ApplicationPermissionEditDialogComponent,
    ApplicationRoleComponent,
    ApplicationRoleEditDialogComponent,
    ApplicationRolePermissionLayerComponent,
  ],
  imports: [
    AngularMultiSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CommonModule,
    PartialsModule,
    SuperuserRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgbModule,
    MatIconModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    NgxDaterangepickerMd.forRoot(),
    MatExpansionModule,
    MatCheckboxModule,
    MatDividerModule
  ],
  providers: [
    OperatorDataService,
    OperatorEntityService,
    OperatorResolver,
    OperatorLogsDataService,
    OperatorLogsEntityService,
    OperatorLogResolver,
    MemberLogDataService,
    MemberLogEntityService,
    RoleDataService,
    RoleEntityService,
    RoleResolver,
    SystemMaintenanceDataService,
    SystemSettingDataService,
    MessageTemplateDataService,
    MessageTemplateEntityService,
    IpWhitelistingDataService,
    SiteDomainDataService,
    AuditsDataService
  ]
})
export class SuperuserModule {
  constructor(
    private eds: EntityDefinitionService,
    private entityDataService: EntityDataService,
    private operatorDataService: OperatorDataService,
    private roleDataService: RoleDataService,
    private operatorLogsDataService: OperatorLogsDataService,
    private memberLogDataService: MemberLogDataService,
    private messageTemplateDataService: MessageTemplateDataService
  ) {
    eds.registerMetadataMap(entityMetadata);
    entityDataService.registerServices({
      OperatorLog: operatorLogsDataService,
      Operator: operatorDataService,
      Role: roleDataService,
      MemberLog: memberLogDataService,
      MessageTemplate: messageTemplateDataService
    });
  }
}
