import { FormGroup } from "@angular/forms";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { MatExpansionPanel } from "@angular/material/expansion";

@Component({
  selector: "application-role-permission-layer",
  templateUrl: "./application-role-permission-layer.component.html",
  styleUrls: ["./application-role-permission-layer.component.scss"],
})
export class ApplicationRolePermissionLayerComponent implements OnInit {
  @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;

  data: any = [];

  @Input()
  form: FormGroup;

  @Input()
  sectionPermissions: any;

  @Input()
  permissionsBySection: any;

  @Input()
  sectionPanelOpenState: { [key: string]: boolean } = {};

  @Output()
  permissionChangeAll = new EventEmitter();

  @Output()
  permissionChange = new EventEmitter();

  @Output()
  sectionPanelOpenStateUpdated = new EventEmitter();

  panelOpenState: boolean = true;

  ngOnInit() {}

  // Method to expose the expansion panel to the parent component
  getExpansionPanels() {
    return this.expansionPanels;
  }

  /**
   * To trigger "indeterminate" state for mat-checkbox ("-" symbol), this function needs to
   * return true when [at least one of the checkboxes is checked].
   *
   * To achieve this, this func needs to return false when [all checkboxes are unchecked], and
   * [all checkboxes are checked], as shown in func below
   *
   * -----------------------------------------------------------------------------------------
   * NOTE that the loose equality operator (==) is used instead of strict (===) to compensate
   * for the possibility where is_granted can be boolean or number. Don't use ===
   *
   * @param sectionCode 
   * @returns 
   */
  somePermissionsComplete(sectionCode: string): boolean {
    const permissions = this.permissionsBySection[sectionCode] ?? null;
    const permissionsGranted = Object.values(permissions);

    if (!permissions || permissionsGranted.every(v => v == 0)) {
      return false;
    }

    return !permissionsGranted.every(v => v == 1);
  }

  /**
   * Check if a section has permissions (including all its children' permissions)
   *
   */
  isSectionHasPermissions(sectionCode: string) {
    return Object.values(this.permissionsBySection[sectionCode] ?? {}).length !== 0;
  }

  onPermissionChange(checked: boolean, permissionCode: string, mainSectionCode: string) {
    this.permissionChange.emit({ checked, permissionCode, mainSectionCode });
  }

  onPermissionChangeAll(checked: boolean, sectionPermissions, mainSectionCode: string) {
    this.permissionChangeAll.emit({ checked, sectionPermissions, mainSectionCode });
  }

  onPanelStateChange(sectionCode: string, isExpanded: boolean): void {
    this.sectionPanelOpenState[sectionCode] = isExpanded;
    this.sectionPanelOpenStateUpdated.emit(this.sectionPanelOpenState);
  }
}
