import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChildren, QueryList, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ApplicationHttpService } from '@core/services/application-http.service';
import { ApplicationSectionPermission } from '@core/models/application-section-permission.model';
import { catchError, tap } from 'rxjs/operators';
import { ApplicationRoleDetails } from '@core/models/application-role-details.model';
import { AppPermissions } from '@core/models/app-permissions.model';
import { ApplicationRolePermissionLayerComponent } from '../permission-layer/application-role-permission-layer.component';
import { Subscription } from 'rxjs';
import { AppPermissionService } from '@core/services/app-permission.service';

type PermissionCode = keyof AppPermissions;

@Component({
  templateUrl: './application-role-edit.component.html',
  styleUrls: ['./application-role-edit.component.scss']
})
export class ApplicationRoleEditDialogComponent implements OnInit, OnDestroy {

  @ViewChildren(ApplicationRolePermissionLayerComponent) appRolePermissionLayerComponents: QueryList<ApplicationRolePermissionLayerComponent>;

  form: FormGroup;
  searchForm: FormGroup;

  searchFilterDropdownSettings: any = {};
  searchElementsData: {
    id: string,
    description: string,
    section: string, // section's code
    index: number,
  }[] = [];
  clearBlinkTimeout: any;

  allExpansionPanels = [];

  buttonLoading: boolean = false;
  dropdown = {
    statuses: this.dropdownHttpService.statuses
  };
  messages$ = this.applicationHttpService.roleMessages$;

  panelOpenState: boolean = true;

  halfIndexOfSP: number;

  disabledPermissions: Array<PermissionCode> = [];

  formPermissionsSubscription: any;
  flattenedSectionPermissions: any = {};
  permissionsBySection: any;
  sectionPanelOpenState = {};

  // permissions
  canCreateApplicationRole: boolean;
  canEditApplicationRole: boolean;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      mode: 'create' | 'edit',
      sectionPermissions: ApplicationSectionPermission[],
      roleDetails: ApplicationRoleDetails,
      disabledPermissions: Array<PermissionCode>,
    },
    public dialogRef: MatDialogRef<ApplicationRoleEditDialogComponent>,
    private dropdownHttpService: DropdownHttpService,
    private applicationHttpService: ApplicationHttpService,
    private cdr: ChangeDetectorRef,
    private appPermissionService: AppPermissionService,
  ) {
    this.halfIndexOfSP = Math.ceil(data.sectionPermissions.length / 2);

    this.searchFilterDropdownSettings = {
      singleSelection: true,
      text: 'Search',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'description',
      noDataLabel: '',
      showCheckbox: false
    };
  }

  ngOnInit() {
    this.disabledPermissions = this.data.disabledPermissions;
    this.formInit();
    this.searchFormInit();

    // one-time setup of flattened section permissions
    this.flattenedSectionPermissions = this.flattenSectionsWithPermissions();

    // initial calculation of permissionsBySection
    this.updatePermissionsBySection();

    // add sectionCheckAll after permissions control has been initialized
    (this.form.get('permissions') as FormGroup).addControl('sectionCheckAll', this.buildSectionCheckAll());

    // open/close panel based on permissions checked/granted
    for (const section of this.data.sectionPermissions) {
      const sectionCode = section.code;
      const permissionsGranted = Object.values(this.permissionsBySection[sectionCode]);
      this.sectionPanelOpenState[sectionCode] = permissionsGranted.some(v => v == 1);
    }

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateApplicationRole = appPermissions.app_role_create_role;
      this.canEditApplicationRole = appPermissions.app_role_edit_role;
    });

    this.subscriptions.add(apSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.updateSearchElementsData();

    this.appRolePermissionLayerComponents.forEach(component => {
      const childExpansionPanels = component.getExpansionPanels().toArray();
      this.allExpansionPanels.push(...childExpansionPanels);
    });
  }

  updatePermissionsBySection() {
    this.permissionsBySection = this.getPermissionsBySection(this.flattenedSectionPermissions);
  }

  updateSearchElementsData() {
    document.querySelectorAll('[data-desc]').forEach((element: HTMLElement, idx) => {
      this.searchElementsData.push({
        id: element.id,
        description: element.dataset.desc,
        section: element.dataset.section,
        index: idx,
      });
    });
    this.cdr.detectChanges();
  }

  onSearch() {
    const searchValue = this.searchForm.value.search;
    const elementData = this.searchElementsData.find(x => x.id == searchValue);

    if (searchValue != null && searchValue.length > 0) {
      this.allExpansionPanels.find((x, idx) => idx == elementData.index)?.open();
      document.getElementById(searchValue).scrollIntoView({ behavior: 'smooth' });
    }
  }

  onCloseDialog(success: boolean = false) {
    this.dialogRef.close(success);
  }

  selectDeselectAllPermissions(checked) {
    for (const section of Object.keys(this.flattenedSectionPermissions)) {
      this.onPermissionChangeAll(checked, { code: section } as any, section);
    }

    // toggle expansion panel open/close
    for (const sectionCode of Object.keys(this.sectionPanelOpenState)) {
      this.sectionPanelOpenState[sectionCode] = checked;
    }
  }

  permissionChangeAllListener(
    { checked, sectionPermissions, mainSectionCode }: {
      checked: boolean, 
      sectionPermissions: ApplicationSectionPermission,
      mainSectionCode: string
    }
  ) {
    this.onPermissionChangeAll(checked, sectionPermissions, mainSectionCode);
  }

  permissionChangeListener(
    { checked, permissionCode, mainSectionCode }: {
      checked: boolean,
      permissionCode: string,
      mainSectionCode: string
    }
  ) {
    this.onPermissionChange(checked, permissionCode, mainSectionCode);
  }

  onPermissionChange(checked: boolean, permissionCode: string, mainSectionCode: string) {
    const childrens = this.form.get(`permissions.${permissionCode}.children`).value;
    const checkChildren = new Promise<void>((resolve, reject) => {
      this.updateCheckbox(childrens, checked);
      this.updatePermissionsBySection();
      resolve();
    });

    checkChildren.then(() => {
      this.patchSectionCheckAll(mainSectionCode);
    });
  } 

  onPermissionChangeAll(checked: boolean, acp: ApplicationSectionPermission, mainSectionCode: string) {
    const permissionCodes = this.flattenedSectionPermissions[acp.code];

    for (const permissionCode of permissionCodes) {
      if (this.isPermissionPermanentlyDisabled(permissionCode)) {
        continue;
      }

      this.form.get(`permissions.${permissionCode}`).patchValue({
        is_granted: Number(checked)
      });

      // this will take care of enabling/disabling child permissions checkbox
      this.updateCheckbox(this.form.get(`permissions.${permissionCode}.children`).value, checked);
    }

    // remember to update permissionsBySection since patchSectionCheckAll() func below heavily depends on it
    this.updatePermissionsBySection();

    // patch sectionCheckAll also for top right checkbox
    this.patchSectionCheckAll(mainSectionCode);
  }

  onSave() {
    this.buttonLoading = true;
    try {
      const rawData = this.form.getRawValue(),
        data = {
          name: rawData.name,
          status: rawData.status,
          remarks: rawData.remarks,
          permissions: []
        };

      Object.values(rawData.permissions).forEach((permission: any) => {
        if (permission.hasOwnProperty('application_permission_id') && permission.hasOwnProperty('is_granted')) {
          data.permissions.push({
            application_permission_id: permission.application_permission_id,
            is_granted: Number(permission.is_granted)
          });
        }
      });

      if (this.data.mode == 'create') {
        this.applicationHttpService.addRole(data).pipe(
          tap(res => this.buttonLoading = false),
          catchError(err => {
            this.buttonLoading = false
            throw err;
          })
        ).subscribe();
      } else {
        this.applicationHttpService.updateRole(this.data.roleDetails.id, data).pipe(
          tap(res => this.buttonLoading = false),
          catchError(err => {
            this.buttonLoading = false
            throw err;
          })
        ).subscribe();
      }
    } catch {
      this.buttonLoading = false;
    }

  }

  isPermissionPermanentlyDisabled(permissionCode: string | PermissionCode): boolean {
    return this.disabledPermissions.includes(permissionCode as PermissionCode);
  }

  private updateCheckbox = (children: string[], checked: boolean) => {
    children.forEach((permission, index) => {
      // Only disable permission is all of its dependencies is false
      const dependencies = this.form.get(`permissions.${permission}.dependencies`).value;
      dependencies.forEach((dependency_permissioncode) => {
        const control = this.form.get(`permissions.${dependency_permissioncode['code']}`);
        if (control) {
          const is_granted = this.form.get(`permissions.${dependency_permissioncode['code']}.is_granted`).value
          if (is_granted == true) {
            checked = true;
            return; // Exit the loop early since we found a granted dependency
          }
        }
      });

      this.updatePermissionFormControl(permission, checked);
      this.disableDescendants(permission, checked);

      if (children.length == (index + 1))
        return;
    });
  }

  private patchSectionCheckAll(sectionCode?: string) {
    this.getSectionCodesWithChildren(sectionCode).forEach(code => {
      if (!code) return;

      const sectionCheckAll = Object.values(this.permissionsBySection[code]).every(v => v);
      this.form.get(`permissions.sectionCheckAll`).patchValue({
        [code]: sectionCheckAll
      });
    });
  }

  private updatePermissionFormControl(permission: string, checked: boolean) {
    if (this.isPermissionPermanentlyDisabled(permission)) {
      // If the permission is in disabledPermissions, set is_granted to 0 and disable the form control
      this.form.get(`permissions.${permission}.is_granted`).setValue(0);
      this.form.get(`permissions.${permission}.is_granted`).disable();
    } else {
      if (!checked) {
        this.form.get(`permissions.${permission}.is_granted`).disable();
        this.form.get(`permissions.${permission}`).patchValue({
          is_granted: 0
        });
      } else {
        this.form.get(`permissions.${permission}.is_granted`).enable();
      }
    }
  }

  private disableDescendants(permission: string, checked: boolean) {
    // recursively disable all descendants only if parent is unchecked.
    // If parent is checked, only enable direct descendant (direct children)
    if (!checked) {
      const grandChildren = this.form.get(`permissions.${permission}.children`).value;
      if (grandChildren.length > 0) {
        grandChildren.forEach((grandChild) => {
          this.updatePermissionFormControl(grandChild, checked);
          this.disableDescendants(grandChild, checked);
        });
      }
    }
  }

  /**
   * This function will return an object with parent/main section code as key, and its children + self as value
   * 
   * {
   *   "dashboard": ["dashboard"],
   *   "members": ["members", "all_members", "member_groups", ...],
   *   ...
   * }
   *
   * @param sectionCode 
   * @returns 
   */
  private getSectionCodesWithChildren(sectionCode: string): string[] {
    const sectionCodes: string[] = [];

    // Find the parent section and its children
    const parentSection = this.data.sectionPermissions.find(section => section.code === sectionCode);
  
    if (parentSection) {
      sectionCodes.push(parentSection.code);
  
      // Recursive function to traverse the children sections
      function traverseSections(sections: any[]) {
        for (const section of sections) {
          sectionCodes.push(section.code);
          if (section.children && section.children.length > 0) {
            traverseSections(section.children);
          }
        }
      }
  
      // Traverse the children sections
      traverseSections(parentSection.children);
    }
  
    return sectionCodes;
  }

  /**
   * This function will return an object with the section code as the key and the permission codes array as the value.
   * 
   * {
   *    "dashboard": ["view_statistic_summary", "view_graph", ...],
   *    "members": ["view_member_list", "all_members_view_member_info", ...],
   *    ...
   * }
   *
   * @returns 
   */
  flattenSectionsWithPermissions() {
    const result = {};

    const processSection = (section) => {
      const sectionCode = section.code;
      const permissionCodes = section.role_permissions
          .filter((permission) => !this.disabledPermissions.includes(permission.code))
          .map((permission) => permission.code);

      if (section.children && section.children.length > 0) {
        section.children.forEach((child) => {
          const childPermissionCodes = processSection(child);
          permissionCodes.push(...childPermissionCodes);
        });
      }

      result[sectionCode] = permissionCodes;
      return permissionCodes;
    }

    this.data.sectionPermissions.forEach((section) => {
      processSection(section);
    });

    return result;
  }

  /**
   * This function will return an object with structure like below:
   * 
   * {
   *    "dashboard": {
   *      "view_statistic_summary": 0,
   *      "view_graph": 1,
   *      ...
   *    },
   *    "member_info_dialog": {
   *       "view_member_info": 0,
   *       "view_member_statistics": 1,
   *       ....
   *    },
   *    "member": {
   *      "view_member_list": 0,
   *      "view_member_dialog": 0,
   *      ....
   *    }
   * }
   * 
   * where the key is the section code, and value is an object with the code and is_granted values of all permissions
   * under this section
   *
   * @param flattenedSections 
   * @returns 
   */
  getPermissionsBySection(flattenedSections: any): { [key: string]: any } {
    const formValues: { [key: string]: any } = {};

    // Iterate over the sections in the flattenedSections object
    for (const sectionCode in flattenedSections) {
      if (flattenedSections.hasOwnProperty(sectionCode)) {
        const permissionCodes = flattenedSections[sectionCode];
        const sectionPermissions: { [key: string]: number } = {};
  
        for (const permissionCode of permissionCodes) {
          // Get the is_granted value for the current permission code from the form
          const isGranted = this.form.get('permissions')?.get(permissionCode)?.get('is_granted')?.value;
  
          // Check if the permission code exists in the form and add it to the sectionPermissions object
          if (isGranted !== undefined) {
            sectionPermissions[permissionCode] = isGranted;
          }
        }
  
        formValues[sectionCode] = sectionPermissions;
      }
    }

    return formValues;
  }

  private formInit() {
    var name: string = this.data.mode == 'edit' ? this.data.roleDetails.name : null,
      status: number = this.data.mode == 'edit' ? this.data.roleDetails.status : 1,
      remarks: string = this.data.mode == 'edit' ? this.data.roleDetails.remarks : null;

    const
      checkDependencies = (dependencies: ApplicationSectionPermission['role_permissions'][0]['dependencies'], permissions: any) => {
        if (dependencies.length === 0) {
          return false; // If dependencies is empty, disable should be false
        }
        
        let disable = true;
        dependencies.forEach(dp => {
          if (permissions.hasOwnProperty(dp.code) && Number(permissions[dp.code].controls['is_granted'].value) == 1) {
            disable = false; // If any dependencies is true, set disable to false
          }
        })
        return disable;
      },
      buildDependencies = (dependencies: ApplicationSectionPermission['role_permissions'][0]['dependencies']) => {
        var details: any = [];
        dependencies.forEach(dp => {
          details.push(new FormGroup({
            id: new FormControl(dp.id),
            code: new FormControl(dp.code)
          }))
        })
        return details;
      },
      buildPermissions = (sectionPermissions: ApplicationSectionPermission[], parentPermissions = {}) => {
        var permissions: any = parentPermissions;

        sectionPermissions.forEach(acp => {
          acp.role_permissions.forEach(permission => {
            const data = new FormGroup({
              application_permission_id: new FormControl(permission.application_permission_id),
              dependencies: new FormArray(buildDependencies(permission.dependencies)),
              children: new FormControl([]),
              is_granted: new FormControl({ value: Number(permission.is_granted), disabled: this.isPermissionPermanentlyDisabled(permission.code) || checkDependencies(permission.dependencies, permissions) }),
              section: new FormControl(acp.code)
            });

            permission.dependencies.forEach(dp => {
              if (permissions.hasOwnProperty(dp.code)) {
                permissions[dp.code].controls['children'].value.push(permission.code);
              }
            })
            permissions = { ...permissions, [permission.code]: data };
          });
          if (acp.children.length > 0) {
            permissions = { ...permissions, ...buildPermissions(acp.children, permissions) };
          }
        });

        return permissions;
      };

    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      status: new FormControl(status, [Validators.required]),
      remarks: new FormControl(remarks),
      permissions: new FormGroup(buildPermissions(this.data.mode == 'create' ? this.data.sectionPermissions : this.data.roleDetails.sections)),
    });
  }

  private searchFormInit() {
    this.searchForm = new FormGroup({
      search: new FormControl(null)
    });
  }

  buildSectionCheckAll() {
    let formValues = {};

    for (const sectionCode of Object.keys(this.permissionsBySection)) {
      const values = Object.values(this.permissionsBySection[sectionCode]);

      // if values is empty means this operator doesn't have (can't see) any permissions on this section
      formValues[sectionCode] = new FormControl(values.length === 0 ? false : values.every(v => v));
    }

    return new FormGroup(formValues);
  }
}
