import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChildren, QueryList, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ApplicationHttpService } from '@core/services/application-http.service';
import { ApplicationSectionPermission } from '@core/models/application-section-permission.model';
import { catchError, tap } from 'rxjs/operators';
import { ApplicationRoleDetails } from '@core/models/application-role-details.model';
import { AppPermissions } from '@core/models/app-permissions.model';
import { ApplicationRolePermissionLayerComponent } from '../permission-layer/application-role-permission-layer.component';
import { Subscription } from 'rxjs';
import { AppPermissionService } from '@core/services/app-permission.service';
import { ApplicationRole } from '@core/models/application-role.model';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

type PermissionCode = keyof AppPermissions;

type SearchBarType = 'main_section' | 'sub_section' | 'permission';
type SearchBarTypes = {
  id: number,
  code: SearchBarType,
  name: string
}[];
type SearchBarTypeElemIdPrefixes = {
  [key in SearchBarType]: string;
}

type SearchBarMainSection = {
  id: number;
  code: string;
  name: string;
};

type SearchBarSubSection = {
  id: number;
  code: string;
  name: string;
  main_section_code: string;
}

type SearchBarPermission = {
  id: number;
  code: string;
  name: string;
  main_section_code: string;
  sub_section_code: string;
}

@Component({
  templateUrl: './application-role-edit.component.html',
  styleUrls: ['./application-role-edit.component.scss']
})
export class ApplicationRoleEditDialogComponent implements OnInit, OnDestroy {

  @ViewChildren(ApplicationRolePermissionLayerComponent) appRolePermissionLayerComponents: QueryList<ApplicationRolePermissionLayerComponent>;

  form: FormGroup;
  searchForm: FormGroup;
  initialFormValues: any = {};

  parentRoleDropdownSettings: any = {};
  parentRoleSelectedItems: any = [];

  childRoleDropdownSettings: any = {};
  childRoleSelectedItems: any = [];
  constantChildRoleSelectedItems: any = []; // for edit

  searchBarType: SearchBarType;
  searchBarTypes: SearchBarTypes = [
    {
      id: 1,
      code: 'main_section',
      name: 'Main Section'
    },
    {
      id: 2,
      code: 'sub_section',
      name: 'Sub Section'
    },
    {
      id: 3,
      code: 'permission',
      name: 'Permission'
    }
  ];
  searchBarTypeElemIdPrefixes: SearchBarTypeElemIdPrefixes = {
    main_section: 'arp-section-',
    sub_section: 'arp-sub-section-',
    permission: 'arp-permission-',
  };
  searchBarBtnDisabled = true;
  searchFilterDropdownSettings: any = {};
  searchElementsData = [];
  searchBarSelectedItems = [];

  searchBarMainSectionItems: SearchBarMainSection[] = [];
  searchBarSubSectionItems: SearchBarSubSection[] = [];
  searchBarPermissionItems: SearchBarPermission[] = [];

  allExpansionPanels = [];

  buttonLoading: boolean = false;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,

    // for Parent Role & Child Role dropdown selection
    parents: [] as ApplicationRole[],
    children: [],
  };
  messages$ = this.applicationHttpService.roleMessages$;

  panelOpenState: boolean = true;

  halfIndexOfSP: number;

  disabledPermissions: Array<PermissionCode> = [];

  formPermissionsSubscription: any;
  flattenedSectionPermissions: any = {};
  permissionsBySection: any;
  sectionPanelOpenState = {};

  hasInvalidPermissions: boolean = false;

  // permissions
  canCreateApplicationRole: boolean;
  canEditApplicationRole: boolean;

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      mode: 'create' | 'edit' | 'duplicate',
      sectionPermissions: ApplicationSectionPermission[],
      roleDetails: ApplicationRoleDetails,
      disabledPermissions: Array<PermissionCode>,
      descendantsAndSelf: Array<ApplicationRole>, // current user role + its descendant roles
    },
    public dialogRef: MatDialogRef<ApplicationRoleEditDialogComponent>,
    private dropdownHttpService: DropdownHttpService,
    private applicationHttpService: ApplicationHttpService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private appPermissionService: AppPermissionService,
  ) {
    this.halfIndexOfSP = Math.ceil(data.sectionPermissions.length / 2);

    this.searchFilterDropdownSettings = {
      singleSelection: true,
      text: 'Search',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false,
      disabled: true,
    };

    this.parentRoleDropdownSettings = {
      singleSelection: true,
      text: 'Search',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      maxHeight: 200, //'auto',
      primaryKey: 'id',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false
    };

    this.childRoleDropdownSettings = {
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'name',
      noDataLabel: '',
      showCheckbox: false,
      disabled: true,
    };
  }

  ngOnInit() {
    this.disabledPermissions = this.data.disabledPermissions;

    // init forms
    this.formInit();
    this.searchFormInit();
    this.parentAndChildRoleDropdownInit();

    this.initialFormValues = this.form.value;

    // one-time setup of flattened section permissions
    this.flattenedSectionPermissions = this.flattenSectionsWithPermissions();

    // initial calculation of permissionsBySection
    this.updatePermissionsBySection();

    const { mainSections, subSections, rolePermissions } = Object.values(this.data.sectionPermissions).reduce((acc, mainSection) => {
      const sectionResults = this.generateSearchFormData(mainSection, mainSection);
      acc.mainSections = acc.mainSections.concat(sectionResults.mainSections);
      acc.subSections = acc.subSections.concat(sectionResults.subSections);
      acc.rolePermissions = acc.rolePermissions.concat(sectionResults.rolePermissions);
      return acc;
    }, { mainSections: [], subSections: [], rolePermissions: [] }); 

    this.searchBarMainSectionItems = mainSections;
    this.searchBarSubSectionItems = subSections;
    this.searchBarPermissionItems = rolePermissions;

    // add sectionCheckAll after permissions control has been initialized
    (this.form.get('permissions') as FormGroup).addControl('sectionCheckAll', this.buildSectionCheckAll());

    // open/close panel based on permissions checked/granted
    for (const section of this.data.sectionPermissions) {
      const sectionCode = section.code;
      const permissionsGranted = Object.values(this.permissionsBySection[sectionCode]);
      this.sectionPanelOpenState[sectionCode] = permissionsGranted.some(v => v == 1);
    }

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateApplicationRole = appPermissions.app_role_create_role;
      this.canEditApplicationRole = appPermissions.app_role_edit_role;
    });

    this.subscriptions.add(apSub);

    // prevent dialog closed without checking unsaved changes when clicking backdrop
    this.dialogRef.afterOpened().subscribe(() => {
      this.dialogRef.disableClose = true;
      this.dialogRef.backdropClick().subscribe(() => {
        this.unsavedChangesCloseDialog();
      });
    });
  }

  generateSearchFormData(section: any, mainSection: any): { mainSections: any[], subSections: any[], rolePermissions: any[] } {
    let mainSections = [];
    let subSections = [];
    let rolePermissions = [];

    if (section === mainSection) {
      mainSections.push({
        id: section.id,
        code: section.code,
        name: section.name
      });

      if (section.role_permissions && section.role_permissions.length > 0) {
        subSections.push({
          id: section.id,
          code: section.code,
          name: `${section.name} (${section.name})`,
          main_section_code: section.code,
        });
      }
    } else {
      subSections.push({
        id: section.id,
        code: section.code,
        name: `${section.name} (${mainSection.name})`,
        main_section_code: mainSection.code,
      });
    }

    rolePermissions = (section.role_permissions || []).map(permission => ({
      id: permission.id,
      code: permission.code,
      name: `${permission.display_title} (${section.name})`,
      sub_section_code: section.code,
      main_section_code: mainSection.code
    }));

    (section.children || []).forEach(child => {
      const childResults = this.generateSearchFormData(child, mainSection);
      mainSections = mainSections.concat(childResults.mainSections);
      subSections = subSections.concat(childResults.subSections);
      rolePermissions = rolePermissions.concat(childResults.rolePermissions);
    });

    return { mainSections, subSections, rolePermissions };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {}

  updatePermissionsBySection() {
    this.permissionsBySection = this.getPermissionsBySection(this.flattenedSectionPermissions);
  }

  onSelectSearchBarType(evt) {
    this.searchBarType = evt.target.value;
    this.searchElementsData = this.searchBarType == "main_section"
                            ? this.searchBarMainSectionItems
                            : this.searchBarType == "sub_section"
                            ? this.searchBarSubSectionItems
                            : this.searchBarPermissionItems;

    // clear search data in case user ady selected one
    this.searchForm.patchValue({
      search: null
    });
    this.searchBarSelectedItems = [];

    // to enable back search bar dropdown & btn if user selected bar type
    this.searchFilterDropdownSettings = {
      ...this.searchFilterDropdownSettings,
      disabled: false,
    };
    this.searchBarBtnDisabled = false;

    this.cdr.detectChanges();
  }

  /**
   * Note the difference between roleDetails "children" and parent's "descendants".
   * 
   * RoleDetails "children" is edited role own children and are unchangeable once saved.
   *
   * Meanwhile, parent "descendants" are all children/grandchildren of the parent role down the line
   *
   * @param selectedItem 
   */
  onParentRoleChange(selectedItems: ApplicationRole[]) {
    const selectedItem = selectedItems[0];

    this.childRoleSelectedItems = [...this.constantChildRoleSelectedItems]; // don't directly use = to set

    if (!selectedItem) {
      this.disableChildRoleDropdown(true);
      return;
    }

    this.adjustChildRoleDropdown(selectedItem);
  } 

  onChildRoleChange(selectedItems: ApplicationRole[]) {
    const missingItems = this.constantChildRoleSelectedItems.filter(constantItem => 
      !selectedItems.some(selectedItem => selectedItem.id === constantItem.id)
    );

    this.childRoleSelectedItems = [...missingItems, ...selectedItems];
  }

  onSearch() {
    const searchBarType = this.searchBarType;
    const searchValue = this.searchForm.value.search;
    const selectedItem = this.searchElementsData.find(x => x.id == searchValue);

    if (searchValue) {
      const mainSectionCode = searchBarType == "main_section" ? selectedItem.code : selectedItem.main_section_code;
      this.sectionPanelOpenState[mainSectionCode] = true;

      const elemId = this.searchBarTypeElemIdPrefixes[searchBarType] + selectedItem.code;

      // 200ms timeout to cater for expanding panel animation, sometimes it will interrupt the scroll
      setTimeout(() => {
        // for main section, the header will scroll to top
        // for the rest, element will scroll to middle (but slightly elevated)
        if (searchBarType === 'main_section') {
          document.getElementById(elemId)?.scrollIntoView({ behavior: 'smooth' });
        } else {
          this.scrollToMiddle(elemId);
        }
      }, 200);
    }
  }

  scrollToMiddle(elemId: string) {
    const element = document.getElementById(elemId);
    const dialogContainer = document.querySelector('.modal-body');

    if (element && dialogContainer) {
      const elementRect = element.getBoundingClientRect();
      const containerRect = dialogContainer.getBoundingClientRect();
      const relativeElementTop = elementRect.top - containerRect.top;
      const middle = relativeElementTop - (containerRect.height / 2) + 300; // slightly elevate the view to top

      dialogContainer.scrollTo({
        top: middle,
        behavior: 'smooth'
      });
    }
  }

  unsavedChangesCloseDialog() {
    if (this.hasUnsavedChanges()) {
      const adminName = this.form.get('name').value;
      const msg = 'Save changes ' + (adminName ? `to the role <b>"${adminName}"</b> ` : '') + 'before closing?';

      Swal.fire({
        title: '<div class="text-center font-weight-bold">This page contains unsaved changes</div>',
        html: '<div class="text-center">' + msg +'</div>',
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: true,
        showConfirmButton: true,
        reverseButtons: true,

        // let btn order here similar to customClass and if-else block in then()
        cancelButtonText: this.translateService.instant('Cancel'),
        denyButtonText: this.translateService.instant("Don't Save"),
        confirmButtonText: this.translateService.instant('Save All'),

        icon:'warning',
        buttonsStyling: false,
        customClass: {
          cancelButton: 'unsaved-changes-dialog-cancel-btn',
          denyButton: 'unsaved-changes-dialog-dont-save-btn',
          confirmButton: 'unsaved-changes-dialog-save-all-btn',
        }
      }).then(result => {
        // Cancel
        if (result.isDismissed) {
          return;
        }
        // Don't Save
        else if (result.isDenied) {
          this.onCloseDialog(false);
        }
        // Save All
        else if (result.isConfirmed) {
          this.onSave();
        }
      });
    } else {
      this.onCloseDialog(false);
    }
  }

  hasUnsavedChanges(): boolean {
    const formValueToStringify = this.form.value;

    // sectionCheckAll doesn't reflect in UI, and removing this field
    // make the form diff cater for user editable values only
    delete formValueToStringify.permissions.sectionCheckAll;

    return JSON.stringify(this.initialFormValues) !== JSON.stringify(formValueToStringify);
  }

  onCloseDialog(success: boolean = false) {
    this.dialogRef.close(success);
  }

  selectDeselectAllPermissions(checked) {
    for (const section of Object.keys(this.flattenedSectionPermissions)) {
      this.onPermissionChangeAll(checked, { code: section } as any, section);
    }

    this.toggleAllExpansionPanel(checked);
  }

  toggleAllExpansionPanel(open: boolean) {
    // toggle expansion panel open/close
    for (const sectionCode of Object.keys(this.sectionPanelOpenState)) {
      this.sectionPanelOpenState[sectionCode] = open;
    }
  }

  sectionPanelOpenStateUpdatedListener(newData: string) {
    this.sectionPanelOpenState = newData;
  }

  permissionChangeAllListener(
    { checked, sectionPermissions, mainSectionCode }: {
      checked: boolean, 
      sectionPermissions: ApplicationSectionPermission,
      mainSectionCode: string
    }
  ) {
    this.onPermissionChangeAll(checked, sectionPermissions, mainSectionCode);
  }

  permissionChangeListener(
    { checked, permissionCode, mainSectionCode }: {
      checked: boolean,
      permissionCode: string,
      mainSectionCode: string
    }
  ) {
    this.onPermissionChange(checked, permissionCode, mainSectionCode);
  }

  onPermissionChange(checked: boolean, permissionCode: string, mainSectionCode: string) {
    // patch own checkbox
    this.form.get(`permissions.${permissionCode}`).patchValue({
      is_granted: Number(checked),
    });

    const childrens = this.form.get(`permissions.${permissionCode}.children`).value;
    const checkChildren = new Promise<void>((resolve, reject) => {
      this.updateCheckbox(childrens, checked);
      this.updatePermissionsBySection();
      resolve();
    });

    checkChildren.then(() => {
      this.patchSectionCheckAll(mainSectionCode);
    });
  } 

  onPermissionChangeAll(checked: boolean, acp: ApplicationSectionPermission, mainSectionCode: string) {
    const permissionCodes = this.flattenedSectionPermissions[acp.code];

    for (const permissionCode of permissionCodes) {
      if (this.isPermissionPermanentlyDisabled(permissionCode)) {
        continue;
      }

      this.form.get(`permissions.${permissionCode}`).patchValue({
        is_granted: Number(checked)
      });

      // this will take care of enabling/disabling child permissions checkbox
      this.updateCheckbox(this.form.get(`permissions.${permissionCode}.children`).value, checked);
    }

    // remember to update permissionsBySection since patchSectionCheckAll() func below heavily depends on it
    this.updatePermissionsBySection();

    // patch sectionCheckAll also for top right checkbox
    this.patchSectionCheckAll(mainSectionCode);
  }

  onSave() {
    this.buttonLoading = true;
    try {
      const rawData = this.form.getRawValue(),
        data = {
          name: rawData.name,
          status: rawData.status,
          remarks: rawData.remarks,
          parent_id: rawData.parent_id,
          children_ids: [],
          permissions: []
        };

      data.children_ids = Object.values(rawData.children_ids);

      Object.values(rawData.permissions).forEach((permission: any) => {
        if (permission.hasOwnProperty('application_permission_id') && permission.hasOwnProperty('is_granted')) {
          data.permissions.push({
            application_permission_id: permission.application_permission_id,
            is_granted: Number(permission.is_granted)
          });
        }
      });

      const httpRequest = this.data.mode == 'create' || this.data.mode == 'duplicate'
                            ? this.applicationHttpService.addRole(data)
                            : this.applicationHttpService.updateRole(this.data.roleDetails.id, data);

      httpRequest.pipe(
        tap(res => {
          this.buttonLoading = false;
          this.hasInvalidPermissions = res.data?.hasInvalidPermissions || false;
        }),
        catchError(err => {
          this.buttonLoading = false;
          throw err;
        })
      ).subscribe();
    } catch {
      this.buttonLoading = false;
    }

  }

  isPermissionPermanentlyDisabled(permissionCode: string | PermissionCode): boolean {
    return this.disabledPermissions.includes(permissionCode as PermissionCode);
  }

  private updateCheckbox = (children: string[], checked: boolean) => {
    children.forEach((permission, index) => {
      // Only disable permission is all of its dependencies is false
      const dependencies = this.form.get(`permissions.${permission}.dependencies`).value;
      dependencies.forEach((dependency_permissioncode) => {
        const control = this.form.get(`permissions.${dependency_permissioncode['code']}`);
        if (control) {
          const is_granted = this.form.get(`permissions.${dependency_permissioncode['code']}.is_granted`).value
          if (is_granted == true) {
            checked = true;
            return; // Exit the loop early since we found a granted dependency
          }
        }
      });

      this.updatePermissionFormControl(permission, checked);
      this.disableDescendants(permission, checked);

      if (children.length == (index + 1))
        return;
    });
  }

  private patchSectionCheckAll(sectionCode?: string) {
    this.getSectionCodesWithChildren(sectionCode).forEach(code => {
      if (!code) return;

      const sectionCheckAll = Object.values(this.permissionsBySection[code]).every(v => v);
      this.form.get(`permissions.sectionCheckAll`).patchValue({
        [code]: sectionCheckAll
      });
    });
  }

  private updatePermissionFormControl(permission: string, checked: boolean) {
    if (this.isPermissionPermanentlyDisabled(permission)) {
      // If the permission is in disabledPermissions, set is_granted to 0 and disable the form control
      this.form.get(`permissions.${permission}.is_granted`).setValue(0);
      this.form.get(`permissions.${permission}.is_granted`).disable();
    } else {
      if (!checked) {
        this.form.get(`permissions.${permission}.is_granted`).disable();
        this.form.get(`permissions.${permission}`).patchValue({
          is_granted: 0
        });
      } else {
        this.form.get(`permissions.${permission}.is_granted`).enable();
      }
    }
  }

  private disableDescendants(permission: string, checked: boolean) {
    // recursively disable all descendants only if parent is unchecked.
    // If parent is checked, only enable direct descendant (direct children)
    if (!checked) {
      const grandChildren = this.form.get(`permissions.${permission}.children`).value;
      if (grandChildren.length > 0) {
        grandChildren.forEach((grandChild) => {
          this.updatePermissionFormControl(grandChild, checked);
          this.disableDescendants(grandChild, checked);
        });
      }
    }
  }

  /**
   * This function will return an object with parent/main section code as key, and its children + self as value
   * 
   * {
   *   "dashboard": ["dashboard"],
   *   "members": ["members", "all_members", "member_groups", ...],
   *   ...
   * }
   *
   * @param sectionCode 
   * @returns 
   */
  private getSectionCodesWithChildren(sectionCode: string): string[] {
    const sectionCodes: string[] = [];

    // Find the parent section and its children
    const parentSection = this.data.sectionPermissions.find(section => section.code === sectionCode);
  
    if (parentSection) {
      sectionCodes.push(parentSection.code);
  
      // Recursive function to traverse the children sections
      function traverseSections(sections: any[]) {
        for (const section of sections) {
          sectionCodes.push(section.code);
          if (section.children && section.children.length > 0) {
            traverseSections(section.children);
          }
        }
      }
  
      // Traverse the children sections
      traverseSections(parentSection.children);
    }
  
    return sectionCodes;
  }

  /**
   * This function will return an object with the section code as the key and the permission codes array as the value.
   * 
   * {
   *    "dashboard": ["view_statistic_summary", "view_graph", ...],
   *    "members": ["view_member_list", "all_members_view_member_info", ...],
   *    ...
   * }
   *
   * @returns 
   */
  flattenSectionsWithPermissions() {
    const result = {};

    const processSection = (section) => {
      const sectionCode = section.code;
      const permissionCodes = section.role_permissions
          .filter((permission) => !this.disabledPermissions.includes(permission.code))
          .map((permission) => permission.code);

      if (section.children && section.children.length > 0) {
        section.children.forEach((child) => {
          const childPermissionCodes = processSection(child);
          permissionCodes.push(...childPermissionCodes);
        });
      }

      result[sectionCode] = permissionCodes;
      return permissionCodes;
    }

    this.data.sectionPermissions.forEach((section) => {
      processSection(section);
    });

    return result;
  }

  /**
   * This function will return an object with structure like below:
   * 
   * {
   *    "dashboard": {
   *      "view_statistic_summary": 0,
   *      "view_graph": 1,
   *      ...
   *    },
   *    "member_info_dialog": {
   *       "view_member_info": 0,
   *       "view_member_statistics": 1,
   *       ....
   *    },
   *    "member": {
   *      "view_member_list": 0,
   *      "view_member_dialog": 0,
   *      ....
   *    }
   * }
   * 
   * where the key is the section code, and value is an object with the code and is_granted values of all permissions
   * under this section
   *
   * @param flattenedSections 
   * @returns 
   */
  getPermissionsBySection(flattenedSections: any): { [key: string]: any } {
    const formValues: { [key: string]: any } = {};

    // Iterate over the sections in the flattenedSections object
    for (const sectionCode in flattenedSections) {
      if (flattenedSections.hasOwnProperty(sectionCode)) {
        const permissionCodes = flattenedSections[sectionCode];
        const sectionPermissions: { [key: string]: number } = {};
  
        for (const permissionCode of permissionCodes) {
          // Get the is_granted value for the current permission code from the form
          const isGranted = this.form.get('permissions')?.get(permissionCode)?.get('is_granted')?.value;
  
          // Check if the permission code exists in the form and add it to the sectionPermissions object
          if (isGranted !== undefined) {
            sectionPermissions[permissionCode] = isGranted;
          }
        }
  
        formValues[sectionCode] = sectionPermissions;
      }
    }

    return formValues;
  }

  private formInit() {
    var name: string = this.data.mode == 'edit' ? this.data.roleDetails.name : '',
      status: number = this.data.mode == 'edit' ? this.data.roleDetails.status : 1,
      remarks: string = this.data.mode == 'edit' ? this.data.roleDetails.remarks : '';

    const
      checkDependencies = (dependencies: ApplicationSectionPermission['role_permissions'][0]['dependencies'], permissions: any) => {
        if (dependencies.length === 0) {
          return false; // If dependencies is empty, disable should be false
        }
        
        let disable = true;
        dependencies.forEach(dp => {
          if (permissions.hasOwnProperty(dp.code) && Number(permissions[dp.code].controls['is_granted'].value) == 1) {
            disable = false; // If any dependencies is true, set disable to false
          }
        })
        return disable;
      },
      buildDependencies = (dependencies: ApplicationSectionPermission['role_permissions'][0]['dependencies']) => {
        var details: any = [];
        dependencies.forEach(dp => {
          details.push(new FormGroup({
            id: new FormControl(dp.id),
            code: new FormControl(dp.code)
          }))
        })
        return details;
      },
      buildPermissions = (sectionPermissions: ApplicationSectionPermission[], parentPermissions = {}) => {
        var permissions: any = parentPermissions;

        sectionPermissions.forEach(acp => {
          acp.role_permissions.forEach(permission => {
            const data = new FormGroup({
              application_permission_id: new FormControl(permission.application_permission_id),
              dependencies: new FormArray(buildDependencies(permission.dependencies)),
              children: new FormControl([]),
              is_granted: new FormControl({ value: Number(permission.is_granted), disabled: this.isPermissionPermanentlyDisabled(permission.code) || checkDependencies(permission.dependencies, permissions) }),
              section: new FormControl(acp.code)
            });

            permission.dependencies.forEach(dp => {
              if (permissions.hasOwnProperty(dp.code)) {
                permissions[dp.code].controls['children'].value.push(permission.code);
              }
            })
            permissions = { ...permissions, [permission.code]: data };
          });
          if (acp.children.length > 0) {
            permissions = { ...permissions, ...buildPermissions(acp.children, permissions) };
          }
        });

        return permissions;
      };

    this.form = new FormGroup({
      name: new FormControl(name, [Validators.required]),
      status: new FormControl(status, [Validators.required]),
      remarks: new FormControl(remarks),
      parent_id: new FormControl(null, [Validators.required]),
      children_ids: new FormControl([]),
      permissions: new FormGroup(buildPermissions(this.data.mode == 'create' ? this.data.sectionPermissions : this.data.roleDetails.sections)),
    });
  }

  private searchFormInit() {
    this.searchForm = new FormGroup({
      search: new FormControl(null)
    });
  }

  private parentAndChildRoleDropdownInit() {
    // set parent role dropdown 
    this.dropdown.parents = this.data.descendantsAndSelf.filter(parent => this.data.mode === 'edit' ? parent.id !== this.data.roleDetails.id : true); 

    if (this.data.mode === 'create') {
      return;
    }

    // setup below only for edit/duplicate
    let parent_id = null;
    let children_ids = [];

    // const parent = this.data.roleDetails.parent;
    const parent = this.dropdown.parents.find(parent => parent.id === this.data.roleDetails.parent.id);

    this.parentRoleSelectedItems = [parent];
    parent_id = parent.id;
    this.adjustChildRoleDropdown(parent);
    this.disableChildRoleDropdown(false);

    if (this.data.mode === 'edit') {
      // constant children for edit, can be used to prevent previously
      // saved child roles being de-selected and become orphans
      this.constantChildRoleSelectedItems = [...(this.data.roleDetails?.children ?? [])];

      this.childRoleSelectedItems = [...this.constantChildRoleSelectedItems];
      children_ids = this.childRoleSelectedItems.map(child => child.id);

      // remove constant child roles from parent role dropdown also (if exists)
      this.dropdown.parents = this.dropdown.parents.filter(parent => !this.constantChildRoleSelectedItems.some(child => child.id === parent.id));
    }

    this.form.patchValue({
      parent_id: parent_id,
      children_ids: children_ids,
    });
  }

  buildSectionCheckAll() {
    let formValues = {};

    for (const sectionCode of Object.keys(this.permissionsBySection)) {
      const values = Object.values(this.permissionsBySection[sectionCode]);

      // if values is empty means this operator doesn't have (can't see) any permissions on this section
      formValues[sectionCode] = new FormControl(values.length === 0 ? false : values.every(v => v));
    }

    return new FormGroup(formValues);
  }

  adjustChildRoleDropdown(selectedParentRole: ApplicationRole) {
    const parent = this.dropdown.parents.find(x => x.id == selectedParentRole.id);
    const descendantsExcludeCurrentRole = parent?.descendants.filter(descendant => {
      return this.data.mode === 'edit' ? descendant.id !== this.data.roleDetails.id : true;
    })

    // this filter is to ensure children has unique items
    this.dropdown.children = [...this.constantChildRoleSelectedItems, ...descendantsExcludeCurrentRole].filter((item, index, self) => index === self.findIndex((t) => t.id === item.id));
    this.disableChildRoleDropdown(false);
  }

  disableChildRoleDropdown(disable: boolean) {
    this.childRoleDropdownSettings = {
      ...this.childRoleDropdownSettings,
      disabled: disable,
    };
  }
}
