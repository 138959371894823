import { BankTransaction } from '@core/models/bank-transaction.model';
import { tap, map, concatMap, filter } from 'rxjs/operators';
import { ApiResponse } from '@core/models/api-response.model';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '@core/models/pagination.model';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Injectable } from '@angular/core';

@Injectable()
export class BankTransactionDataService extends DefaultDataService<BankTransaction>  {

  pagination: Pagination;
  messages$ = new Subject<any[]>();
  summary: any;

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
    super('BankTransaction', http, httpUrlGenerator, { root: '' });
  }

  getAll(): Observable<BankTransaction[]> {
    return this.http.get<ApiResponse>('/banktransaction').pipe(
      tap(res => this.paginationInit(res)),
      map(res => res.data.rows)
    );
  }

  getWithQuery(pageParam: string): Observable<BankTransaction[]> {
    return this.http.get<ApiResponse>(`/banktransaction${pageParam}`).pipe(
      tap(res => {
        this.paginationInit(res);
        this.summary = res.data.current_search_total;
      }),
      map(res => res.data.rows)
    )
  }

  add(bankTransaction: BankTransaction): Observable<BankTransaction> {
    return this.http.post<ApiResponse>(`/banktransaction`, bankTransaction).pipe(
      tap(res => this.messages$.next(res.message)),
      concatMap((entity) => {
        if (entity.data.rows.id === undefined) {
          return;
        }
        return this.http.get(`/banktransaction/${entity.data.rows.id}`).pipe(
          map((row: ApiResponse) => row.data.rows)
        );
      })
    );
  }

  updateStatus(bankTransaction: any) {
    return this.http.put<ApiResponse>(`/banktransaction/statusupdate`, bankTransaction).pipe(
      tap(res => this.messages$.next(res.message))
    )
  }

  export(pageParam:string) {
    return this.http.get<ApiResponse>(`/banktransaction/export${pageParam}`).pipe(
      tap(res => this.messages$.next(res.message))
    );
  }

  private paginationInit(res: any) {
    if (res) {
      this.pagination = res.data.paginations;
    }
  }
}
