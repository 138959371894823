import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@core/models/api-response.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Dropdown } from '@core/models/dropdown.model';

@Injectable()
export class GroupHttpService {

  constructor(private http: HttpClient) { }

  getGroups(): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>('/membergroup?paginate=false').pipe(
      map(res => res.data.rows)
    );
  }

  getAffiliateGroups(param?: string): Observable<Dropdown[]> {
    return this.http.get<ApiResponse>(`/affiliategroup?paginate=false&${param}`).pipe(
      map(res => res.data.rows)
    );
  }
}
