import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RoleEnum } from '@core/enums/role.enum';
import { Member } from '@core/models/member.model';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { forkJoin, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MemberDataService } from '../../services/member-data.service';
import { MemberEntityService } from '../../services/member-entity.service';
import { MemberLabelComponent } from '../member-label/member-label.component';
import { environment } from '@env/environment';
import { AppState } from '@store/reducers';
import { Store, select } from '@ngrx/store';
import { specialPermissions } from '@core/store/auth/auth.selectors';
import { AppPermissionService } from '@core/services/app-permission.service';

@Component({
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.scss']
})
export class MemberProfileDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  username: Member;
  @ViewChild(OwlDateTimeInputDirective) datePicker: OwlDateTimeInputDirective<any>;
  dropdown = {
    statuses: this.dropdownHttpService.statuses,
    groups: this.dropdownHttpService.groups,
    currencies: JSON.parse(sessionStorage.getItem('currencies')) === null ? [] : JSON.parse(sessionStorage.getItem('currencies')),
    merchantBanks: this.dropdownHttpService.merchantBanks
  };
  role = RoleEnum;
  buttonLoading = false;
  dropdownSettings = {};

  userPermissions$ = this.store.pipe(select(specialPermissions));
  canCreateDummyAccount: boolean;
  canEditDummyAccount: boolean;

  // permissions
  canCreateNewMember: boolean;
  canEditMemberDetailsRestricted: boolean;
  canEditMemberDetails: boolean;

  private subscription = new Subscription();
  private subscriptions = new Subscription();
  private datePickerSubscription = new Subscription();
  messages$ = this.memberDataService.messages$;
  refreshStatus: boolean;
  dateTimeStack = null;

  selectedItems = [];
  modify_dob = false;
  sqsEnable: boolean = environment.sqsEnable;
  disabledDummy = false;

  constructor(
    public dialogRef: MatDialogRef<MemberProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { member: Member, mode: string, isAdmin: boolean },
    public dialog: MatDialog,
    private memberService: MemberEntityService,
    private memberDataService: MemberDataService,
    private dropdownHttpService: DropdownHttpService,
    private datePipe: DatePipe,
    private authHttpService: AuthHttpService,
    private store: Store<AppState>,
    private appPermissionService: AppPermissionService,
  ) {}

  ngOnInit() {
    if(this.dropdown.currencies.length === 0){
      this.dropdownHttpService.currencies.subscribe( res => {
        this.dropdown.currencies = res;
      });
    }
    this.dropdownSettings = {
      autoPosition: true,
      maxHeight: 150,
      singleSelection: true,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'id',
      labelKey: 'username',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false
    };
    if(this.data.mode === 'edit' && this.data.member.agent_id !== null){
      this.selectedItems.push({id:this.data.member.agent_id, username: this.data.member.agent_username});
    }

    this.formInit(); 

    const apSub = this.appPermissionService.getAppPermissions().subscribe(appPermissions => {
      this.canCreateNewMember = appPermissions.create_new_member;
      this.canEditMemberDetailsRestricted = appPermissions.edit_member_details_restricted;
      this.canEditMemberDetails = appPermissions.edit_member_details;
      this.canCreateDummyAccount = appPermissions.create_dummy_account;
      this.canEditDummyAccount = appPermissions.edit_dummy_account;

      if (this.data.mode == 'edit') {
        if (!this.canEditMemberDetails) {
          this.modify_dob = false;

          this.form.get('date_of_birth').disable();
          this.form.get('name').disable();
          this.form.get('email').disable();
          this.form.get('email_status').disable();
          this.form.get('mobile').disable();
          this.form.get('mobile_status').disable();
        } else {
          this.modify_dob = true;

          this.form.get('date_of_birth').enable();
          this.form.get('name').enable();
          this.form.get('email').enable();
          this.form.get('email_status').enable();
          this.form.get('mobile').enable();
          this.form.get('mobile_status').enable();
        }
      }
    });

    this.subscriptions.add(apSub);
    if ((this.data.mode == 'create' && !this.canCreateDummyAccount) || (this.data.mode == 'edit' && !this.canEditDummyAccount)) {
      this.disabledDummy = true;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptions.unsubscribe();
    this.datePickerSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    if ((this.data.isAdmin || this.canEditMemberDetails) || this.data.mode === 'create') {
      this.modify_dob = true;
      this.datePickerSubscription = forkJoin([
        this.buildDatePicker(0, 'date_of_birth'),
      ]).subscribe();
    }
  }

  onCloseDialog(event?: Event) {
    this.dialogRef.close(event);
  }

  onSave(member: Member, mode?: string) {
    this.buttonLoading = true;
    const data = {
      id: member ? member.id : null,
      ...this.form.value
    };
    Object.keys(data).forEach((key) => (data[key] == null || data[key] === '') && delete data[key]);
    switch (mode) {
      case 'edit':
        this.subscription = this.memberService.update(data).pipe(
          tap((res: any) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            this.messages$.next([...res.message]);
          }),
          catchError((error) => {
            this.buttonLoading = false;
            this.form.setErrors(null);
            throw error;
          })
      ).subscribe();
        break;
      case 'create':
        this.subscription = forkJoin([
          this.memberDataService.add(data).pipe(
            tap((res: any) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              this.messages$.next([...res.message]);
            }),
            catchError((error) => {
              this.buttonLoading = false;
              this.form.setErrors(null);
              throw error;
            })
          ),
        ]).subscribe();
        break;
    }
    this.refreshStatus = true;
  }

  onRefresh(){
    if (this.refreshStatus === true){
      this.dialogRef.close(true);
    }
  }

  onCheckbox(event: Event, formKey: string) {
    this.form.patchValue({
      [formKey]: (event.target as HTMLInputElement).checked ? 1 : 0
    });
  }

  onSmsSubscription(event: Event) {
    this.form.patchValue({
      sms_sub: (event.target as HTMLInputElement).checked ? 1 : 0
    });
  }

  toLowerCaseInput(controlName: string, event: Event) {
    this.authHttpService.forceLowerCaseInputControl(this.form, controlName, event);
  }

  onOpenDialog() {
    this.openDialogBy(MemberLabelComponent, { member: this.data.member});
  }

  private openDialogBy(componentRef: any, data?: { member?: any}) {
    const dialogRef = this.dialog.open(componentRef, {
      width: '1000px',
      data,
    });
  }

  private buildDatePicker(index: number, formKey: string) {
    return this.datePicker.valueChange.pipe(
      map(res => this.datePipe.transform(res, 'yyyy-MM-dd')),
      tap(date => {
        this.form.patchValue({ [formKey] : date });
      }),
    );
  }

  private formInit() {
    let username = null;
    let currency = null;
    let dateOfBirth = null;
    let name = null;
    let memberGroup = 1; // Normal by default
    let status = 1;
    let mobile = null;
    let mobile_status = 0;
    let email = null;
    let email_status = 0;
    let remarks = null;
    let agent_id = null;
    let referrer = null;
    let sms_sub = 0;
    let dummy = 0;

    let controls: any = {};

    if (this.data.mode === 'edit'){
      if (this.data.member.date_of_birth != null && this.data.member.date_of_birth != '') {
        this.dateTimeStack = new Date(this.data.member.date_of_birth);
      }
      username =  this.data.member.username;
      currency =  this.data.member.settings_currency_id;
      name =  this.data.member.name;
      memberGroup = this.data.member.member_group_id;
      status =  this.data.member.status;
      mobile = this.data.member.mobile;
      mobile_status = this.data.member.mobile_status;
      email = this.data.member.email;
      email_status = this.data.member.email_status;
      remarks =  this.data.member.remarks;
      dateOfBirth = this.data.member.date_of_birth;
      agent_id = this.data.member.agent_id;
      referrer = this.data.member.referrer;
      sms_sub = this.data.member.sms_sub;
      dummy = this.data.member.dummy;

      //Unable to change dummy account to normal account
      this.disabledDummy = dummy !== 0 ? true : false;

      controls = {
        ...controls,
        mobile_status: new FormControl(mobile_status),
        email_status: new FormControl(email_status),
        suspicious: new FormControl(this.data.member.suspicious),
      };
    }

    controls = {
      ...controls,
      username: new FormControl({ value: username, disabled: this.data.mode === 'create' ? false : true }, [Validators.required]),
      currency_id: new FormControl({ value: currency, disabled: this.data.mode === 'edit' ? true : false }, [Validators.required]),
      date_of_birth: new FormControl(dateOfBirth),
      member_group_id: new FormControl(memberGroup, [Validators.required]),
      status: new FormControl({ value: status, disabled: this.data.mode === 'create' ? true : false }),
      name: new FormControl(name, [Validators.required]),
      mobile: new FormControl(mobile, [Validators.required]),
      email: new FormControl(email, (this.data.mode === 'edit' ? [] : [Validators.email])),
      remarks: new FormControl(remarks),
      agent_id: new FormControl(agent_id),
      referrer: new FormControl(referrer),
      sms_sub: new FormControl(sms_sub),
      dummy: new FormControl(dummy)
    };

    this.form = new FormGroup(controls);

    if (this.data.mode === 'edit') {
      let emailChangesSubscriber = this.form.get('email').valueChanges.subscribe(value => {
        if (value !== this.data.member.email) {
          this.form.controls.email.setValidators(Validators.email);
          setTimeout(() => {
            this.form.controls.email.updateValueAndValidity();
          });
          emailChangesSubscriber.unsubscribe();
        }
      })
    }
  }
}
