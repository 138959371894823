<div class="kt-form w-webkit-fill-available" *ngIf="dropdown.locales.length > 0">
  <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet kt-portlet--mobile">
      <div class="kt-portlet__body">
        <form [formGroup]="form">
          <div class="row align-items-center">
            <div class="col-xl-12 order-2 order-xl-1">
              <div class="row mb-3">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Status' | translate }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="status" class="form-control">
                    <option value="all"> {{'All' | translate }} </option>
                    <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">
                      {{ value }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Locale' | translate }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="settings_locale_id" class="form-control">
                    <option value="all"> {{'All' | translate }} </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.locales">
                      {{ value.code }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Label' }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <input type="text" formControlName="label" placeholder="{{ 'Search' | translate }}"
                    class="form-control">
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Type'}}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="contact_type_id" class="form-control">
                    <option value="all">{{ 'All' | translate }}</option>
                    <option [value]="value.id" *ngFor="let value of dropdown.types; let i = index">
                      {{ value.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-xl-12 order-2 order-xl-1">
              <div class="row mb-3">
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Social Platform'}}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="contact_platform_id" class="form-control">
                    <option value="all">{{ 'All' | translate }}</option>
                    <option [value]="value.id" *ngFor="let value of dropdown.social_platforms; let i = index">
                      {{ value.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-1 kt-form__label col-form-label">
                  <label>{{ 'Platform Type' | translate }}:</label>
                </div>
                <div class="col-md-2 kt-form__control">
                  <select formControlName="platform_type_id" class="form-control">
                    <option value="all"> {{'All' | translate }} </option>
                    <option [value]="value.id" *ngFor="let value of dropdown.platforms; let i = index">
                      {{ value.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="kt-section col-12 row d-flex justify-content-between mb-0 mt-1">
          <div class="dropdown dropdown-inline">
            <kt-search-button [loading]="searchBtnLoading" [disabledButton]="loading"
              (search)="onSubmitClick()"></kt-search-button>
            <kt-clear-button [loading]="clearBtnLoading" [disabledButton]="loading"
              (clear)="onSubmitClick(true)"></kt-clear-button>
          </div>
        </div>
      </div>
    </div>
    <div id="kt_content" class="kt-content  kt-grid__item kt-grid__item--fluid">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body pt-12 pb-0">
          <div class="kt-section">
            <div class="kt-section__content">
              <div class="table-responsive">
                <table class="table table-bordered table-hover table-striped table-sortable">
                  <thead>
                    <tr>
                      <th width="5" class="sort-enabled" (click)="onSortColumn('id')" [ngClass]="sortingConfig.id"
                        [class.sort-selected]="sortingSelection.sort_by === 'id'">{{ 'Id' | translate | uppercase }}
                      </th>
                      <th width="5" class="pr-0">{{ 'Locale' | translate | uppercase }}
                      </th>
                      <th width="5" class="pl-1 pr-5">
                        <div class="dropdown selected-category-container ml-1" *ngIf="displayLocale != null">
                          <button class="dropdown-toggle d-flex justify-content-center dropdown-box" type="button"
                            data-toggle="dropdown" style="margin: 0 auto">
                            <p class="mr-1">{{ displayLocale['code'] | uppercase }} </p>
                            <i class="fa fa-sort-down"></i>
                          </button>
                          <ul class="dropdown-menu dropdown-box-open">
                            <li *ngFor="let value of dropdown.locales" class="selected-category-container2 ml-0"
                              (click)="changeLocale(value)">
                              {{ value.code | uppercase }}
                            </li>
                          </ul>
                        </div>
                      </th>
                      <th class="sort-enabled" (click)="onSortColumn('label')" [ngClass]="sortingConfig.label"
                        [class.sort-selected]="sortingSelection.sort_by === 'label'">{{ 'Label' | translate |
                        uppercase }}</th>
                      <th>{{ 'Social Platform' | translate | uppercase }}</th>
                      <th>{{ 'Type' | translate | uppercase }}</th>
                      <th>{{ 'Platform' | translate | uppercase }}</th>
                      <th>{{ 'Link' | translate | uppercase }}</th>
                      <th>{{ 'Contact' | translate | uppercase }}</th>
                      <th class="sort-enabled" (click)="onSortColumn('position')" [ngClass]="sortingConfig.position"
                        [class.sort-selected]="sortingSelection.sort_by === 'position'">{{ 'Position' | translate |
                        uppercase }}</th>
                      <th>{{ 'Status' | translate | uppercase }}</th>
                      <th class="text-center pr-13">{{ 'Actions' | translate | uppercase }}</th>
                    </tr>
                    <tr *ngIf="canCreateNewContact && (!mode || mode != 'create')">
                      <th colspan="12" class="pr-0 pl-0">
                        <div class="content-container text-center" (click)="expandRowCreate('create')">
                          <span><i class="fas fa-plus mr-2"></i>Create New Content</span>
                        </div>
                      </th>
                    </tr>
                    <tr *ngIf="mode == 'create'">
                      <th colspan="12" class="pr-0 pl-0">
                        <ng-container *ngIf="!mode else contents"></ng-container>
                      </th>
                    </tr>
                  </thead>
                  <tbody *ngIf="(contact$ | async) as rows">
                    <ng-container *ngFor="let row of rows">
                      <tr>
                        <td [style]="mode == 'edit' && contact.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          {{ row.id }}
                        </td>
                        <td [style]="mode == 'edit' && contact.id == row.id ? 'border-bottom: 0 !important;' : ''"
                          colspan="2" width="1" class="pr-5">
                          <div style="width: 100px;">
                            {{ row.locale_list }}
                          </div>
                        </td>
                        <td [style]="mode == 'edit' && contact.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          {{ row.label }}
                        </td>
                        <td [style]="mode == 'edit' && contact.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          {{ row.contact_platform }}
                        </td>
                        <td [style]="mode == 'edit' && contact.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          {{ row.contact_type }}
                        </td>
                        <td [style]="mode == 'edit' && contact.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          {{ row.platform_type }}
                        </td>
                        <td class="script-content" [style]="mode == 'edit' && contact.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          {{ displayLocaleLink(row) }}
                        </td>
                        <td class="script-content" [style]="mode == 'edit' && contact.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          {{ displayLocaleValue(row) }}
                        </td>
                        <td [style]="mode == 'edit' && contact.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          {{ row.position }}
                        </td>
                        <td [style]="mode == 'edit' && contact.id == row.id ? 'border-bottom: 0 !important;' : ''">
                          <label class="switch">
                            <input [disabled]="!canEditContact && !canUpdateContactStatus" type="checkbox" id="togBtn" [checked]="row.status == 1"
                              (change)="onChangeStatus($event, row)">
                            <div class="slider round">
                              <span class="on">ON</span>
                              <span class="off">OFF</span>
                            </div>
                          </label>
                        </td>
                        <td [style]="mode == 'edit' && contact.id == row.id ? 'border-bottom: 0 !important;' : ''"
                          class="text-center actions-column">
                          <button *ngIf="canViewContactDetails" matTooltip="{{ 'View' | translate }}"
                            class="btn btn-outline-hover-info btn-elevate btn-sm btn-icon btn-icon-sm btn-edit"
                            (click)="expandRowEdit('edit', row)"><i class="fas fa-cog"></i></button>
                        </td>
                      </tr>
                      <tr *ngIf="mode == 'edit' && contact.id == row.id">
                        <td colspan="12" class="p-0" style="border-bottom: 0 !important;">
                          <ng-container *ngIf="!mode else contents"></ng-container>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                  <tbody shared-table-handler [loading]="loading" [dataLength]="dataLength"
                    class="text-gray-600 fw-bold table-handler"></tbody>
                </table>
              </div>
              <div class="kt-pagination kt-pagination--brand mt-2" *ngIf="pagination !== undefined">
                <ngb-pagination [pageSize]="pageSize" [(page)]="page" [maxSize]="maxSize" [directionLinks]="true"
                  [boundaryLinks]="true" [rotate]="true" [collectionSize]="pagination.total"
                  (pageChange)="onViewPageBy(page)">
                </ngb-pagination>
                <div class="kt-pagination__toolbar">
                  <select (change)="onPerPage($event)" [(ngModel)]="pageSize"
                    class="form-control kt-font-brand page-size">
                    <option [value]="item" *ngFor="let item of dropdown.perPage">{{ item }}</option>
                  </select>
                  <span class="pagination__desc">
                    Showing {{pagination.from}} to {{pagination.to}} of {{ pagination.total | number : '1.0' }} records
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #contents>
  <form [formGroup]="formContent">
    <div class="create-content-container" *ngIf="dropdown.locales.length > 0">
      <div class="row col-12 p-0 m-0">
        <div class="col-12 mb-2" *ngIf="mode == 'create'">
          <span class="header">Create New Contact</span>
        </div>
        <div class="form-group col-3 mb-3">
          <label>{{ 'Label' | translate }}: <span class="text-danger">*</span></label>
          <input type="text" formControlName="label" class="form-control">
        </div>
        <div class="form-group col-3 mb-3">
          <label>{{ 'Social Platform' | translate }}: <span class="text-danger">*</span></label>
          <div class="kt-form__control">
            <select formControlName="contact_platform_id" class="form-control" (change)="updateLinkValidators()">
              <option value="null" disabled hidden>{{ 'Please Select' | translate }}</option>
              <option [value]="value.id" *ngFor="let value of dropdown.social_platforms; let i = index">
                {{ value.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group col-3 mb-3">
          <label>{{ 'Type' | translate }}: <span class="text-danger">*</span></label>
          <div class="kt-form__control">
            <select formControlName="contact_type_id" class="form-control">
              <option value="null" disabled hidden>{{ 'Please Select' | translate }}</option>
              <option [value]="value.id" *ngFor="let value of dropdown.types; let i = index">
                {{ value.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group col-3 mb-3">
          <label>{{ 'Platform Type' | translate }}: <span class="text-danger">*</span></label>
          <div class="kt-form__control">
            <select formControlName="platform_type_id" class="form-control">
              <option value="null" disabled hidden>{{ 'Please Select' | translate }}</option>
              <option [value]="value.id" *ngFor="let value of dropdown.platforms; let i = index">
                {{ value.name }}
              </option>
            </select>
          </div>
        </div> 
      </div>
      <div class="row col-12 p-0 m-0">
        <div class="form-group col-3 mb-3">
          <label>{{ 'Position' | translate }}:<span class="text-danger">*</span></label>
          <input type="number" min="1" max="99" (wheel)="false" step="1" formControlName="position" class="form-control">
        </div>
        <div class="form-group col-3 mb-3" *ngIf="mode == 'create'">
          <label>{{ 'Status' }}: <span class="text-danger">*</span></label>
          <select formControlName="status" class="form-control">
            <option [value]="i" *ngFor="let value of dropdown.statuses; let i = index">
              {{ value }}
            </option>
          </select>
        </div>
      </div>
      <hr>
      <mat-tab-group animationDuration="0ms" class="form-group mb-0 mat-tab-header-pagination-controls-enabled" formGroupName="locales"
        [selectedIndex]="localeIndex()">
        <ng-container *ngIf="dropdown.locales.length > 0">
          <mat-tab *ngFor="let value of dropdown.locales; let i = index" [formGroupName]="value.id">
            <ng-template mat-tab-label>
              <span class="mat-tab">
                {{ value.code }}
                <div *ngIf="checkContent(value)" class="icon" (click)="clearContent(value)">
                  <i class="fas fa-times"></i>
                </div>
              </span>
            </ng-template>
            <div class="row col-12 p-0 m-0 mb-3">
              <div class="form-group col-12 mb-0 p-0">
                <label>{{ 'Contact' | translate }}: <span class="text-danger">*</span> </label>
                <input type="text" formControlName="value" class="form-control">
              </div>
              <div class="form-group col-12 mb-0 mt-2 p-0">
                <label>{{ 'Link' | translate }}: <i class="fa fa-info-circle ml-1" placement="right" [ngbTooltip]="linkToolTip" tooltipClass="tooltip-class"></i></label>
                <input type="text" formControlName="link" class="form-control">
                <!-- <span class="header-hint">
                  <div *ngFor="let usableLink of usableLinks; let last = last" class="p-inline">
                    <p class="p-inline" (click)="addUsableLink(usableLink, value.id)">{{ usableLink }}</p>
                    <div *ngIf="!last" class="p-inline">
                      <p class="p-inline p-display">&nbsp;&nbsp;&nbsp;&nbsp;</p>
                    </div>
                  </div>
                </span> -->
                <div class="text-danger" *ngIf="getLinkControl(value.id)?.errors">
                  {{ linkValidationMsg }}
                </div>
              </div>
              <ng-template #linkToolTip>
                <div class="text-left">
                  Viber: A hyperlink that links to your Viber Chat. <br>
                  Examples:<br>
                  viber://chat?number=%2B601234567890<br>
                  viber://chat?number=601234567890<br>
                </div>
              </ng-template>
            </div>

          </mat-tab>
        </ng-container>
      </mat-tab-group>
      <div class="modal-footer">
        <table class="info mr-3" *ngIf="mode == 'edit'">
          <tr>
            <td class="header-hint">
              Updated by: {{ contact.updated_by ? contact.updated_by + '&nbsp;' : 'System&nbsp;' }}
            </td>
            <td class="header-hint">
              <span placement="top" [ngbTooltip]="updatedDateTime" container="body" class="tooltip-class">{{ contact.updated_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #updatedDateTime>
                {{ contact.updated_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
            </td>
          </tr>
          <tr>
            <td class="header-hint">
              Created by: {{ contact.created_by ? contact.created_by + '&nbsp;' : 'System&nbsp;' }}
            </td>
            <td class="header-hint">
              <span placement="top" [ngbTooltip]="createdDateTime" container="body" class="tooltip-class">{{ contact.created_at | timezoneDate:'YYYY-MM-DD HH:mm' }}</span>
              <ng-template #createdDateTime>
                {{ contact.created_at | timezoneDate:'YYYY-MM-DD HH:mm:ss' }}
              </ng-template>
            </td>
          </tr>
        </table>
        <button type="button" class="btn btn-danger m-0 mr-2"
          (click)="mode == 'create' ? expandRowCreate('', true) : expandRowEdit('', null, true)">{{ 'Cancel' | translate
          }}</button>
        <button *ngIf="(mode == 'create' && canCreateNewContact) || (mode == 'edit' && canEditContact)" type="button" class="btn btn-success" [disabled]="isEmptyContent||!formContent.valid"
          (click)="onSave()">{{ mode != null && mode == 'create' ? 'Create' : 'Update' | translate }}</button>
      </div>
    </div>
  </form>
</ng-template>
<kt-swal-alert [message]="messages$ | async" (confirmed)="reload()"></kt-swal-alert>
