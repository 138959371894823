import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { smsProviderCredentials } from '@core/models/sms-provider-credentials.model';
import { DropdownHttpService } from '@core/services/dropdown-http.service'
import { ProviderCredentialsDataService } from '../../service/provider-credentials-data.service';
import { catchError, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
@Component({
  selector: 'kt-provider-credentials-edit',
  templateUrl: './provider-credentials-edit.component.html',
  styleUrls: ['./provider-credentials-edit.component.scss']
})
export class ProviderCredentialsEditDialogComponent implements OnInit {

  form: FormGroup;
  dropdown = {
    region: [],
    status: this.dropdownHttpService.statuses
  }
  regionDropdownSettings = {};
  regionSelectedItems = [];
  messages$ = this.providerCredentialsDataService.messages$;
  buttonLoading = false;
  formatAlert = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mode: string, credentials: smsProviderCredentials },
    public dialogRef: MatDialogRef<ProviderCredentialsEditDialogComponent>,
    private providerCredentialsDataService: ProviderCredentialsDataService,
    private dropdownHttpService: DropdownHttpService
  ) { }

  ngOnInit() {
    this.formInit();

    this.regionDropdownSettings = {
      singleSelection: false,
      text: 'Please Select',
      enableFilterSelectAll: false,
      enableSearchFilter: true,
      classes: 'dropdown',
      primaryKey: 'country_code',
      labelKey: 'country_code',
      lazyLoading: true,
      noDataLabel: '',
      showCheckbox: false,
      disabled: true
    }

    this.dropdownHttpService.regions.subscribe(res => {
      this.dropdown.region = res;
      if (this.data.credentials) {
        this.regionSelectedItems = this.dropdown.region.filter(x => this.data.credentials.region.includes(x.country_code));
      }
    });
  }

  onCloseDialog(value = false) {
    this.dialogRef.close(value);
  }

  onSave() {
    let credentials = '';
    try {
      if (this.form.value.credentials) {
        credentials = JSON.parse(this.form.value.credentials);
      }
      this.formatAlert = false;
    } catch (e) {
      this.formatAlert = true;
      return;
    }

    if (this.data.credentials.status == 1 && this.form.value.status != this.data.credentials.status) {
      const text = 'This action will unbound the SMS Provider from the corresponding SMS Type.';
      Swal.fire({
        title: 'The SMS Provider is currently in use!',
        html: '<div class="text-center">Do you want to save the changes? <br> <div class="my-3 text-red">' + text + '<div></div>',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Yes',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.submit(credentials);
        }
      });
    } else {
      this.submit(credentials);
    }
  }

  private submit(credentials: string) {
    this.buttonLoading = true;
    const data = {
      ...this.form.value,
      smsdetail: this.data.credentials.id,
      credentials: credentials,
    };
    Object.keys(data).forEach((key) => (data[key] == null) && delete data[key]);
    if (this.data.mode === 'edit') {
      if (data?.internal == 1) {
        delete data.custom_code;
      }

      this.providerCredentialsDataService.updateCredentials(data).pipe(
        tap((res: any) => {
          this.buttonLoading = false;
        }),
        catchError((error) => {
          this.buttonLoading = false;
          this.form.setErrors(null);
          throw error;
        })
      ).subscribe();
    }
  }

  private formInit() {
    this.form = new FormGroup({
      code: new FormControl(this.data.mode === 'edit' ? this.data.credentials.code : null),
      custom_code: new FormControl(this.data.mode === 'edit' ? this.data.credentials.custom_code : null),
      name: new FormControl(this.data.mode === 'edit' ? this.data.credentials.name : null, [Validators.required]),
      region: new FormControl(this.data.mode === 'edit' ? this.data.credentials.region : null),
      credentials: new FormControl(this.data.mode === 'edit' ? JSON.stringify(this.data.credentials.credentials) : null, [Validators.required]),
      merchant_id: new FormControl(this.data.mode === 'edit' ? this.data.credentials.merchant : null),
      balance_limit: new FormControl(this.data.mode === 'edit' ? (+this.data.credentials.balance_limit).toFixed(2) : null),
      status: new FormControl(this.data.mode === 'edit' ? this.data.credentials.status : null, [Validators.required]),
      internal: new FormControl(this.data.mode === 'edit' ? this.data.credentials.internal : null),
    });
  }
}
